import qs from 'qs';
import { qsOption } from '@/services/constants';
import { axiosAPI } from '@/plugins/axios';
import type { Filter, ListApiResponse } from '@/types/Axios.type';
import { Project } from '@/modules/shared/types/project.type';

export const loadDeletedProjects = (filter?: Filter) => {
  const query = qs.stringify({ ...filter, isDeleted: true }, qsOption);
  return axiosAPI.get<ListApiResponse<Project[]>>(`/projects${query}`);
};

export const restoreProjectWithVideos = (projectKey: string) => (
  axiosAPI.put(`/projects/${projectKey}/restore`)
);

export const restoreProjectOnly = (projectKey: string) => (
  axiosAPI.put(`/projects/${projectKey}/restore?projectOnly=true`)
);
