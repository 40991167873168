import { useStore as baseUseStore } from 'vuex';
import { storeKey } from '@/store';
import type { TeamFeaturesState } from '@/modules/shared/types/teamFeatures.type';
import type { VideoUploadState } from '@/modules/videoUpload';
import type { UserState } from '@/modules/user';
import type { FormUploadState } from '../types';

export const useStore = () => baseUseStore<{
  formUpload: FormUploadState;
  teamFeatures: TeamFeaturesState;
  user: UserState;
  videoUpload: VideoUploadState;
}>(storeKey);
