<template>
  <SelectOption
    :placeholder="t('webhook.test.video.placeholder')"
    :options="options"
    :modelValue="modelValue"
    label="title"
    @update:modelValue="onChanged"
  />
</template>

<script lang="ts" setup>
import {
  computed, onMounted, ref, toRefs,
} from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';
import { Video } from '@/modules/shared/types/video.type';
import { loadVideosAPI } from '@/modules/projectVideoList/services';
import SelectOption from '@/modules/shared/components/atoms/selectOption/SelectOption.vue';
import { useStore } from '@/store';
import { getQueryAsString } from '@/modules/shared/utils/query';

interface VideoSelectorProps {
  modelValue: Video | null;
}

const { t } = useI18n();
const route = useRoute();
const store = useStore();

const props = defineProps<VideoSelectorProps>();
const { modelValue } = toRefs(props);

const emit = defineEmits<{'update:modelValue': [value: Video]}>();

const options = ref<Video[]>([]);

const projectKey = computed(() => getQueryAsString(route.params, 'projectKey'));
const projectVideoList = computed(() => store.state.projectVideoList.projectVideoList);

async function loadVideos() {
  try {
    const response = await loadVideosAPI({ projectKey: projectKey.value, limit: '100' });
    options.value = response.data.data;
    options.value = options.value.map((item) => ({
      ...item,
      subtitle: item.key,
    }));
  } catch (error) {
    console.error(error);
  }
}

function onChanged(value: Video) {
  emit('update:modelValue', value);
}

onMounted(() => {
  if (projectVideoList.value) {
    options.value = projectVideoList.value;
  } else {
    loadVideos();
  }
});
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/global-variables.scss';
</style>
