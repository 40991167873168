import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withCtx as _withCtx } from "vue"

const _hoisted_1 = ["disabled", "onClick"]
const _hoisted_2 = { class: "user-avatar" }
const _hoisted_3 = { class: "text-container" }
const _hoisted_4 = { class: "email" }
const _hoisted_5 = {
  key: 0,
  class: "autocomplete-notice-text"
}
const _hoisted_6 = {
  key: 1,
  class: "autocomplete-notice-text"
}
const _hoisted_7 = {
  key: 2,
  class: "autocomplete-notice-text"
}
const _hoisted_8 = {
  key: 3,
  class: "autocomplete-notice-text"
}

import type { InputMember } from '@/modules/projectMember/types';
import { emailRgeEx } from '@/modules/shared/utils/validator';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import {
  computed, onBeforeUnmount, onMounted, ref, toRefs, watch,
} from 'vue';
import { VueTagsInput } from '@vojtechlanka/vue-tags-input';
import Logo from '@/modules/shared/components/atoms/logo/Logo.vue';
import { useI18n } from 'vue-i18n';

interface InviteMemberTagsInputProps {
  membersThatAreNotInsideThisProject: Array<InputMember>;
  membersThatAreInsideThisProject: Array<InputMember>;
  modelValue: Array<InputMember>;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'InviteMemberTagsInput',
  props: {
    membersThatAreNotInsideThisProject: {},
    membersThatAreInsideThisProject: {},
    modelValue: {}
  },
  emits: ["update:modelValue", "scrollShadowChange"],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const {
  membersThatAreNotInsideThisProject,
  membersThatAreInsideThisProject,
  modelValue,
} = toRefs(props);

const emit = __emit;

const { t } = useI18n();

const modalBodyElement = ref<HTMLElement | null>(null);
const isShowScrollShadow = ref<boolean>(false);
const q = ref<string>('');

const getAccountField = (member: InputMember, field: 'name' | 'email'): string | undefined => {
  if (isEmpty(member.account)) {
    return undefined;
  }

  if (isNil(member.account[field])) {
    return undefined;
  }

  return member.account[field]!.toLowerCase();
};

const autocompleteItems = computed(() => {
  const searchedText = q.value.toLowerCase();
  let result = membersThatAreNotInsideThisProject.value.filter((member) => (
    Boolean(getAccountField(member, 'name')?.includes(searchedText) || getAccountField(member, 'email')?.includes(searchedText))
      && !member.isAlreadySelected
  )).map((member) => ({ ...member, text: member.account?.email }));

  const foundMember = membersThatAreInsideThisProject.value.find((member) => getAccountField(member, 'email') === searchedText);
  const foundSelectedMember = modelValue.value.find((member) => member.text?.toLowerCase() === searchedText);

  if (foundMember) {
    result = [{
      account: foundMember.account,
      text: searchedText,
      isAlreadyAMember: true,
    }];
  } else if (foundSelectedMember) {
    result = [{
      account: {
        name: searchedText,
        email: searchedText,
      },
      text: searchedText,
      isAlreadySelected: true,
    }];
  } else if (isEmpty(result) && emailRgeEx.test(searchedText)) {
    result = [{
      account: {
        name: searchedText,
        email: searchedText,
      },
      text: searchedText,
      notAWorkspaceMember: true,
    }];
  }

  return result;
});

const shouldShowScrollShadow = (): void => {
  const modalBody = document.getElementById('content-body');
  const form = document.getElementById('form');

  if (form && modalBody) {
    setTimeout(() => {
      isShowScrollShadow.value = form.scrollHeight > 440 && modalBody.scrollTop < 8;
    }, 100);
  }
};

const onClickInputBox = (): void => {
  const input = document.getElementById('text-input');
  if (input) {
    input.focus();
  }
  shouldShowScrollShadow();
};

watch(q, shouldShowScrollShadow);
watch(shouldShowScrollShadow, () => emit('scrollShadowChange', isShowScrollShadow.value));

onMounted(() => {
  modalBodyElement.value = document.getElementById('content-body');

  if (modalBodyElement.value) {
    modalBodyElement.value.addEventListener('scroll', shouldShowScrollShadow);
  }
});

onBeforeUnmount(() => {
  if (modalBodyElement.value) {
    modalBodyElement.value.removeEventListener('scroll', shouldShowScrollShadow);
  }
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "input-box",
    onClick: onClickInputBox
  }, [
    _createVNode(_unref(VueTagsInput), {
      id: "text-input",
      modelValue: q.value,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((q).value = $event)),
      tags: _unref(modelValue),
      onTagsChanged: _cache[1] || (_cache[1] = (members) => {
        // Filter out suspended users if were manually input
        const membersWithoutSuspended = members.filter((member) => (
          _unref(membersThatAreNotInsideThisProject).find((m) => m.account?.email === member.text)?.isSuspended !== true));

        // Filter out already-a-member users if were manually input
        const validMembers = membersWithoutSuspended.filter((member) => (
          _unref(membersThatAreInsideThisProject).find((m) => m.account?.email === member.text) === undefined));

        emit('update:modelValue', validMembers);

        q.value = '';
        shouldShowScrollShadow();
      }),
      onBlur: shouldShowScrollShadow,
      "add-on-key": [13, 188],
      "autocomplete-items": autocompleteItems.value,
      separators: [', ', ',', ' '],
      validation: [
        { classes: '', rule: _unref(emailRgeEx) },
        {
          classes: 'ti-invalid',
          rule: ({ text }) => _unref(membersThatAreInsideThisProject).find((member) => member.account?.email === text) !== undefined,
        },
      ],
      "is-draggable": true,
      placeholder: _unref(isEmpty)(_unref(modelValue)) ? _unref(t)('project.manage.members.emailPlaceholder') : '',
      "autocomplete-min-length": 0,
      "autocomplete-filter-duplicates": false,
      autocomplete: "off"
    }, {
      "tag-center": _withCtx(({ tag }) => [
        (tag.notAWorkspaceMember)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _createTextVNode(_toDisplayString(tag.text), 1)
            ], 64))
          : (tag.isAlreadyAMember)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                _createTextVNode(_toDisplayString(tag.account?.name ?? tag.text) + " (Already a project member) ", 1)
              ], 64))
            : (tag.account?.name)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                  _createTextVNode(_toDisplayString(tag.account.name), 1)
                ], 64))
              : (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [
                  _createTextVNode(_toDisplayString(tag.text), 1)
                ], 64))
      ]),
      "autocomplete-item": _withCtx(({ item, performAdd }) => [
        _createElementVNode("button", {
          type: "button",
          class: _normalizeClass(["autocomplete-item", { disabled: item.isAlreadyAMember || item.isAlreadySelected || item.isSuspended }]),
          disabled: item.isAlreadyAMember || item.isAlreadySelected || item.isSuspended,
          onClick: () => performAdd(item)
        }, [
          _createElementVNode("span", _hoisted_2, [
            _createVNode(Logo, {
              user: item.account
            }, null, 8, ["user"])
          ]),
          _createElementVNode("span", _hoisted_3, [
            _createElementVNode("span", {
              class: _normalizeClass(`name ${item.notAWorkspaceMember ? 'padding' : ''}`)
            }, _toDisplayString(item.account?.name), 3),
            _createElementVNode("span", _hoisted_4, _toDisplayString(item.account?.email), 1)
          ]),
          (item.notAWorkspaceMember)
            ? (_openBlock(), _createElementBlock("span", _hoisted_5, " Not a workspace member "))
            : _createCommentVNode("", true),
          (item.isAlreadyAMember)
            ? (_openBlock(), _createElementBlock("span", _hoisted_6, " Already a project member "))
            : _createCommentVNode("", true),
          (item.isAlreadySelected)
            ? (_openBlock(), _createElementBlock("span", _hoisted_7, " Already selected "))
            : _createCommentVNode("", true),
          (item.isSuspended)
            ? (_openBlock(), _createElementBlock("span", _hoisted_8, " Suspended member "))
            : _createCommentVNode("", true)
        ], 10, _hoisted_1)
      ]),
      _: 1
    }, 8, ["modelValue", "tags", "autocomplete-items", "validation", "placeholder"])
  ]))
}
}

})