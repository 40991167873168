<template>
  <Modal
    :title="t('video.subtitle.modal.add.typeManually.title')"
    class="set-subtitle-name-modal"
    :primaryButtonText="t('common:button.add')"
    :isDisabledPrimaryButton="!isEnableSave"
    :isSaving="createCaptionState.status === 'saving'"
    :onClickPrimaryButton="onSubmit"
    :secondaryButtonText="t('common:button.back')"
    :onClickSecondaryButton="onClickSecondaryButton"
    @modalClose="onClose"
  >
    <template v-slot:body>
      <Field title="Display Name">
        <Input
          type="text"
          class="form-control"
          v-model="customName"
          @keydown.enter="onSubmit"
          :placeholder="t('video.subtitle.displayName.placeholder')"
        />
      </Field>
      <Field :title="t('caption.upload.language')">
        <LanguageSelector
          @selected="onSelectLanguageCode"
          :value="languageCode"
          :languageCode
          :placeholder="t('video.subtitle.language.select')"
        />
      </Field>
    </template>
    <template v-slot:secondary-btn-prefix>
      <i class="fas fa-chevron-left"></i>
    </template>
    <template v-slot:footer>
      <Button @click="onClose" type="button" buttonStyle="text-secondary" v-if="createCaptionState.status !== 'error'">
        {{t('common:button.cancel')}}
      </Button>
      <div v-if="createCaptionState.status === 'error'" class="error-message">
        {{createCaptionState.error?.message}}
      </div>
    </template>
  </Modal>
</template>

<script lang="ts" setup>
import Field from '@/modules/shared/components/molecules/field/Field.vue';
import { createCaptionDraftTypeManually } from '@/modules/videoCaption/services';
import {
  computed, ref, toRefs,
} from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import type { Language } from '@/types/Language.type';
import type { StatusState } from '@/types/State.type';
import Modal from '@/modules/shared/components/organisms/modal/Modal.vue';
import Button from '@/modules/shared/components/atoms/button/Button.vue';
import Input from '@/modules/shared/components/atoms/input/Input.vue';
import { setStateSavingStatusByType } from '@/modules/shared/utils/stateManagement';
import { formatErrorObject } from '@/modules/shared/utils/errorFormatter';
import { useStore } from '../../../store/hooks';
import LanguageSelector from '../../molecules/languageSelector/LanguageSelector.vue';

interface TypeManuallyVideoCaptionModalProps {
  onClose: () => void;
}

const props = defineProps<TypeManuallyVideoCaptionModalProps>();
const { onClose } = toRefs(props);
const emit = defineEmits(['toInit']);

const store = useStore();
const route = useRoute();
const router = useRouter();
const { t } = useI18n();

const customName = ref<string>('');
const createCaptionState = ref<StatusState>(setStateSavingStatusByType());

const filter = computed(() => route.query);
const currentVideo = computed(() => store.state.video.currentVideo);
const languageList = computed(() => store.state.languageList.languageList);

function getDefaultSubtitleCode() {
  if (Array.isArray(languageList.value) && languageList.value[0]) {
    return languageList.value[0].code6392T;
  }

  return '';
}

const languageCode = ref<string>(getDefaultSubtitleCode());
const isEnableSave = computed(() => customName.value && languageCode.value && createCaptionState.value.status !== 'loading');

function onSelectLanguageCode(value: Language) {
  languageCode.value = value.code6392T;
}

async function onSubmit() {
  if (!isEnableSave.value) {
    return;
  }

  createCaptionState.value = setStateSavingStatusByType({ type: 'saving' });

  const videoKey = currentVideo.value?.key;
  const revisionKey = currentVideo.value?.vod?.revisionKey;

  if (!videoKey || !revisionKey) {
    return;
  }

  try {
    await createCaptionDraftTypeManually(videoKey, {
      publishStatusSetting: 'draft',
      autoGenerateCaptionSettingId: null,
      audioVariantKey: null,
      captionDrafts: [
        {
          languageCode: languageCode.value,
          customName: customName.value,
          originalFilename: null,
          captionProcessingType: {
            type: 'manual',
            service: null,
          },
        },
      ],
    });

    await store.dispatch('reloadCaptionList', {
      ...(filter.value || {}),
      videoKey: currentVideo.value.key,
    });

    createCaptionState.value = setStateSavingStatusByType({ type: 'success' });

    const { lastPage } = store.state.captionList;
    if (Number(filter.value.page) !== Number(lastPage)) {
      await router.push({ path: route.path, query: { page: lastPage } });
    }
    onClose.value();
  } catch (error) {
    createCaptionState.value = setStateSavingStatusByType({
      type: 'error',
      error: formatErrorObject(error, 'Caption'),
    });
  }
}

function onClickSecondaryButton() {
  emit('toInit');
}
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/global-variables';
@import '~@/assets/scss/themes/helpers';
@import '~@/assets/scss/breakpoint';

:deep(.modal-content) {
  min-height: 545px;
}

.form-control {
  margin-bottom: 0;
}
</style>
