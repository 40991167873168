import { GeoblockRuleListState } from '@/modules/geoblock';
import { ProjectState } from '@/modules/shared/types/project.type';
import { useStore as baseUseStore } from 'vuex';
import { storeKey } from '@/store';
import { VideoUploaderDraggableState, VideoUploadState } from '../types/videoUpload.type';

export const useStore = () => baseUseStore<{
  geoblockRuleList: GeoblockRuleListState,
  project: ProjectState,
  videoUpload: VideoUploadState,
  videoUploaderDraggable: VideoUploaderDraggableState
}>(storeKey);
