import type { ActionContext } from 'vuex';
import type { Filter } from '@/types/Axios.type';
import { getCaptionProfileList } from '@/modules/projectDefaultUploadSetting/services';
import { setStateLoadingStatusByType } from '@/modules/shared/utils/stateManagement';
import { formatErrorObject } from '@/modules/shared/utils/errorFormatter';
import type { LoadingState } from '@/types/State.type';
import type { CaptionProfile, CaptionProfileListState } from '@/modules/captionProfile/types';
import type { RootState } from '@/store/type';

type CaptionProfileListContext = ActionContext<CaptionProfileListState, RootState>;

// state
const initialState = (): CaptionProfileListState => ({
  captionProfileList: null,
  captionProfileListLastPage: 1,
  loadCaptionProfileListState: setStateLoadingStatusByType(),
});

const state = initialState();

const mutations = {
  setCaptionProfileList(state: CaptionProfileListState, captionProfileList: CaptionProfile[]) {
    state.captionProfileList = captionProfileList;
  },
  setLoadCaptionProfileListState(state: CaptionProfileListState, loadingState: LoadingState) {
    state.loadCaptionProfileListState = setStateLoadingStatusByType(loadingState);
  },
  setCaptionProfileListLastPage(state: CaptionProfileListState, number: number) {
    state.captionProfileListLastPage = number;
  },
  destroyCaptionProfileList(state: CaptionProfileListState) {
    Object.assign(state, initialState);
  },
};

// action
// load caption list
// destroy
const actions = {
  async loadCaptionProfileList({ commit }: CaptionProfileListContext, filter: Filter) {
    commit('setLoadCaptionProfileListState', { type: 'loading' });
    try {
      const response = await getCaptionProfileList(filter);
      const { data, lastPage } = response.data;
      commit('setCaptionProfileList', data);
      commit('setCaptionProfileListLastPage', lastPage);
      commit('setLoadCaptionProfileListState', { type: 'success' });
    } catch (error) {
      commit('setLoadBadWordListsState', {
        type: 'error',
        error: formatErrorObject(error, 'Collection List'),
      });
    }
  },
  destroyCaptionProfileList({ commit }: CaptionProfileListContext) {
    commit('destroyCaptionProfileList');
  },
};

export default {
  state,
  mutations,
  actions,
};
