<template>
  <div>
    <router-view v-slot="{ Component }" name="createURLSigningKey">
      <!-- <transition name="fade" mode="out-in"> -->
      <component :is="Component" />
      <!-- </transition> -->
    </router-view>
    <router-view />
  </div>
</template>

<script>
</script>
