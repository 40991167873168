import project from '@/modules/shared/store/project';
import { projectList } from '@/modules/projectList';
import teamFeatures from '@/modules/shared/store/teamFeatures';
import videoImport from '@/modules/videoImport/store/videoImport';
import videoImportBatchEntry from '@/modules/videoImport/store/videoImportBatchEntry';
import videoImportBatchList from '@/modules/videoImport/store/videoImportBatchList';
import videoSources from '@/modules/videoSources/store/videoSources';
import projectVideoList from '@/modules/projectVideoList/store/projectVideoList';
import store from '@/store';

export default function createVideoImportRoutes() {
  return [
    {
      name: 'videoImportChrome',
      path: 'projects/:projectKey/imports',
      component: () => import(/* webpackChunkName: "videoImportChrome" */ '@/modules/videoImport/layouts/VideoImportChrome.vue'),
      beforeEnter: (to: any, from: any, next: () => void) => {
        if (!store.hasModule('project')) {
          store.registerModule('project', project);
        }
        if (!store.hasModule('projectList')) {
          store.registerModule('projectList', projectList);
        }
        if (!store.hasModule('projectVideoList')) {
          store.registerModule('projectVideoList', projectVideoList);
        }
        if (!store.hasModule('teamFeatures')) {
          store.registerModule('teamFeatures', teamFeatures);
        }
        if (!store.hasModule('videoImport')) {
          store.registerModule('videoImport', videoImport);
        }
        if (!store.hasModule('videoImportBatchEntry')) {
          store.registerModule('videoImportBatchEntry', videoImportBatchEntry);
        }
        if (!store.hasModule('videoImportBatchList')) {
          store.registerModule('videoImportBatchList', videoImportBatchList);
        }
        if (!store.hasModule('videoSources')) {
          store.registerModule('videoSources', videoSources);
        }
        next();
      },
      children: [
        {
          name: 'videoImportBatchList',
          path: '',
          component: () => import(/* webpackChunkName: "videoImportList" */ '@/modules/videoImport/pages/VideoImportBatchList.vue'),
        },
        {
          name: 'videoImportBatchEntry',
          path: ':importId',
          component: () => import(/* webpackChunkName: "videoImportDetail" */ '@/modules/videoImport/pages/VideoImportBatchEntry.vue'),
        },
        {
          name: 'videoImport',
          path: 'import',
          component: () => import(/* webpackChunkName: "videoImport" */ '@/modules/videoImport/pages/VideoImport.vue'),
        },
      ],
    },
  ];
}
