import {
  createBumper,
  deleteBumperById,
  enableBumperDetail,
  loadBumperDetail,
  saveBumperDetail,
} from '@/modules/bumper/services';
import { ActionContext } from 'vuex';
import {
  setStateLoadingStatusByType,
  setStateSavingStatusByType,
} from '@/modules/shared/utils/stateManagement';
import { formatErrorObject } from '@/modules/shared/utils/errorFormatter';
import { RootState } from '@/store/type';
import { BumperState, Bumper } from '@/modules/bumper/types';
import { LoadingState } from '@/types/State.type';

type BumperActionContext = ActionContext<BumperState, RootState>;

const initialState = ():BumperState => ({
  bumperDetail: null,
  loadBumperDetailState: setStateLoadingStatusByType(),
  saveBumperState: setStateSavingStatusByType(),
});

const state = initialState();

const mutations = {
  setBumperDetail(state: BumperState, bumper: Bumper) {
    state.bumperDetail = bumper;
  },
  setLoadBumperDetailState(state: BumperState, loadingState: LoadingState) {
    state.loadBumperDetailState = setStateLoadingStatusByType(loadingState);
  },
  setSaveBumperState(state: BumperState, loadingState: LoadingState) {
    state.saveBumperState = setStateSavingStatusByType(loadingState);
  },
  clearBumperState() {
    Object.assign(state, initialState());
  },
};

const actions = {
  async loadBumperDetail({ commit }: BumperActionContext, bumperId: string) {
    try {
      commit('setLoadBumperDetailState', { type: 'loading' });
      const response = await loadBumperDetail(bumperId);
      commit('setBumperDetail', response.data);
      commit('setLoadBumperDetailState', { type: 'success' });
    } catch (error) {
      commit('setLoadBumperDetailState', { type: 'error', error: formatErrorObject(error, 'bumper') });
    }
  },
  async saveBumperDetail({ commit }: BumperActionContext, { bumperId, payload }: { bumperId: string, payload: Bumper }) {
    try {
      commit('setSaveBumperState', { type: 'saving' });
      const response = await saveBumperDetail(bumperId, payload);
      commit('setBumperDetail', response.data);
      commit('setSaveBumperState', { type: 'success' });
    } catch (error) {
      commit('setSaveBumperState', { type: 'error', error: formatErrorObject(error, 'bumper') });
    }
  },
  async createBumper({ commit }: BumperActionContext, payload: Bumper) {
    try {
      commit('setSaveBumperState', { type: 'saving' });
      const response = await createBumper(payload);
      commit('setBumperDetail', response.data);
      commit('setSaveBumperState', { type: 'success' });
    } catch (error) {
      commit('setSaveBumperState', { type: 'error', error: formatErrorObject(error, 'bumper') });
    }
  },
  clearBumperState({ commit }: BumperActionContext) {
    commit('clearBumperState');
  },
  async deleteBumperById({ commit }: BumperActionContext, bumperId: string) {
    try {
      commit('setSaveBumperState', { type: 'loading' });
      await deleteBumperById(bumperId);
      commit('clearBumperState');
      commit('setSaveBumperState', { type: 'success' });
    } catch (error) {
      commit('setSaveBumperState', { type: 'error', error: formatErrorObject(error, 'bumper') });
    }
  },
  async toggleEnableBumperById({ commit }: BumperActionContext, bumperId: string) {
    try {
      commit('setSaveBumperState', { type: 'loading' });
      await enableBumperDetail(bumperId);
      commit('clearBumperState');
      commit('setSaveBumperState', { type: 'success' });
    } catch (error) {
      commit('setSaveBumperState', { type: 'error', error: formatErrorObject(error, 'bumper') });
    }
  },
  setBumperDetail({ commit }: BumperActionContext, payload: Bumper) {
    commit('setBumperDetail', payload);
  },
};

export default {
  state,
  actions,
  mutations,
};
