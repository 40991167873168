<script setup lang="ts">
import { toRefs } from 'vue';

interface ModalHeaderProps {
  title?: string;
  description?: string;
  isShowInLineEdit?: boolean;
}

const props = defineProps<ModalHeaderProps>();

const {
  title,
  description,
  isShowInLineEdit,
} = toRefs(props);
</script>

<template>
  <div class="modal-header" data-test="modal-header">
    <div class="title-container">
      <div class="title" v-if="title && !isShowInLineEdit">{{title}}</div>
      <slot />
    </div>
    <div class="description" v-if="description">{{description}}</div>
  </div>
</template>

<style scoped lang="scss">
@import '~@/assets/scss/global-variables';

.modal-header {
  padding: $spacing-base $spacing-24;
  text-align: left;
  color: $grey-800;
  position: relative;
  display: flex;
  flex-direction: column;

  :slotted(.title) {
    line-height: 1.3;
    margin-bottom: $spacing-8;
  }
}

.title-container {
  display: flex;
  align-items: center;
  column-gap: $spacing-4;
  padding-right: $spacing-base;

  .title {
    color: $grey-800;
    font-size: $font-level-4;
    font-weight: $font-weight-x-bold;
    word-break: break-all;
  }
}

.description {
  color: $grey-600;
  font-size: $font-level-7;
  font-weight: $font-weight-base;
  padding-right: $spacing-base;
}
</style>
