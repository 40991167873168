import { GeoblockRule } from '@/modules/geoblock';
import { VideoUploadByKeyObject } from '@/modules/videoUpload/types/videoUpload.type';
import type { AudioTrack, VideoTrack } from '@/modules/preset';

export function isVideoTrack(track: unknown): track is VideoTrack {
  return (
    typeof track === 'object'
    && track !== null
    && ('audioTrack' in track || 'audioTrackKey' in track)
    && 'enabled' in track
    && 'encryption' in track
    && 'frameRateControl' in track
    && 'name' in track
    && 'resolutionControl' in track
    && 'resolutionType' in track
    && 'videoCodecOptions' in track
  );
}

export function isAudioTrack(track: unknown): track is AudioTrack {
  if (isVideoTrack(track)) {
    return false;
  }

  return (
    typeof track === 'object'
    && track !== null
    && 'audioCodecOptions' in track
    // For some reason, enabled is not existed when adding a new profile (e.g. after clicking "Add MP4 Profile" button).
    // && 'enabled' in track
    && 'encryption' in track
    && 'name' in track
  );
}

export function isGeoblockRule(obj: any): obj is GeoblockRule {
  return obj !== null && typeof obj === 'object'
    && 'id' in obj
    && 'name' in obj
    && 'teamNamespace' in obj
    && 'isTeamDefault' in obj
    && 'createdAt' in obj
    && 'updatedAt' in obj
    && 'allowCountryCodes' in obj
    && 'denyCountryCodes' in obj;
}

export function isVideoUploadByKeyObject(obj: any): obj is VideoUploadByKeyObject {
  return obj !== null && typeof obj === 'object'
    && 'file' in obj
    && 'uploadingInfo' in obj
    && 'currentRetryDelay' in obj
    && 'remainingRetryDelay' in obj
    && 'retryTimerInterval' in obj;
}
