<template>
  <div v-if="page" class="pagination text-center">
    <router-link class="page-link page-first link"
                 :to="{ path, query: { ...query, page: 1} }"
                 v-if="page > 2">
      <i class="fas fa-angles-left"></i>
    </router-link>
    <router-link class="page-link page-previous link"
                 :to="{ path, query: { ...query, page: previousPage } }"
                 v-if="page > 1">
      <i class="fas fa-angle-left"></i>
    </router-link>
    <!-- fix overflow -->
    <template v-for="n in lastPage" :key="n">
      <router-link
        :class="{ active: isActivePage(n) }"
        class="page-link link"
        :to="{ path, query: { ...query, page: n } }"
        v-if="showPaginate(n)"
      >
        <span>{{ n }}</span>
      </router-link>
    </template>
    <router-link class="page-link page-next link"
                 :to="{ path, query: { ...query, page: nextPage } }"
                 v-if="page < lastPage || !page">
      <i class="fas fa-angle-right"></i>
    </router-link>
    <router-link class="page-link page-last link"
                 :to="{ path, query: { ...query, page: lastPage } }"
                 v-if="page < (lastPage - 1) || !page">
      <i class="fas fa-angles-right"></i>
    </router-link>
  </div>
</template>

<script>
export default {
  props: {
    lastPage: {
      type: Number,
      required: true,
    },
  },
  computed: {
    page() {
      return this.$route?.query?.page || 1;
    },
    path() {
      return this.$route?.path;
    },
    query() {
      return this.$route?.query || {};
    },
    limitPage() {
      if (this.page) {
        return parseInt(this.page, 10) + 4;
      }
      return 1;
    },
    nextPage() {
      if (this.page) {
        return parseInt(this.page, 10) + 1;
      }
      return 1;
    },
    previousPage() {
      if (this.page) {
        return parseInt(this.page, 10) - 1;
      }
      return 1;
    },
  },
  methods: {
    showPaginate(n) {
      if ((n < this.limitPage && n >= this.page) || this.lastPage < 5) {
        return true;
      }
      if ((this.lastPage - n) < 4 && (this.lastPage - this.page) < 4) {
        return true;
      }
      return false;
    },
    isActivePage(n) {
      if (!this.page) {
        return n === 1;
      }
      return this.page?.toString() === n.toString();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/global-variables.scss';

.pagination {
  display: flex;
  column-gap: $spacing-12;
}

.page-link {
  background: transparent;
  border: none;
  /* margin: 0 $spacing-base * 0.25; */
  border-radius: 4px;
  color: #4d4d4c;
  display: flex;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  min-width: 36px;
  height: 36px;

  &:hover {
    background: #f1f2f3;
  }
}

.page-link:first-child {
  margin-left: 0;
}

.page-link:last-child {
  margin-right: 0;
}

.page-link.active {
  background-color: #E9F2FB;
  color: $ci-primary;
}

/* .page-previous {
  margin-right: $spacing-base;
}

.page-next {
  margin-left: $spacing-base;
} */

.page-first, .page-previous, .page-next, .page-last {
  background-color: $grey-100;
  padding: $spacing-8;
}
</style>
