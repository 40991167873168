import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "website-list"
}
const _hoisted_2 = { key: 1 }
const _hoisted_3 = {
  key: 0,
  class: "website-list"
}
const _hoisted_4 = { key: 1 }

import type { AccessControlPolicy } from '@/modules/playbackChannel/types/playbackChannel.type';
import _ from 'lodash';
import { toRefs } from 'vue';
import Modal from '@/modules/shared/components/organisms/modal/Modal.vue';
import SectionCollapse from '@/modules/shared/components/organisms/sectionCollapse/SectionCollapse.vue';
import { useI18n } from 'vue-i18n';

interface AccessControlPolicyModalProps {
  data: AccessControlPolicy | null | undefined;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'AccessControlPolicyModal',
  props: {
    data: {}
  },
  emits: ["close"],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const { data } = toRefs(props);

const emit = __emit;

const { t } = useI18n();

return (_ctx: any,_cache: any) => {
  return (!_unref(_).isNil(_unref(data)))
    ? (_openBlock(), _createBlock(Modal, {
        key: 0,
        size: "large",
        title: "Allowed / Blocked Website",
        onModalClose: _cache[0] || (_cache[0] = () => emit('close'))
      }, {
        body: _withCtx(() => [
          _createVNode(SectionCollapse, {
            title: `<i class='fas fa-circle-check icon-allow'></i> Allowed Website (${_unref(data).allowDomains.length})`
          }, {
            default: _withCtx(() => [
              (_unref(data).allowDomains && _unref(data).allowDomains.length)
                ? (_openBlock(), _createElementBlock("ul", _hoisted_1, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(data).allowDomains, (website, index) => {
                      return (_openBlock(), _createElementBlock("li", {
                        class: "website",
                        key: index
                      }, [
                        _cache[1] || (_cache[1] = _createElementVNode("i", { class: "fas fa-earth-asia" }, null, -1)),
                        _createTextVNode(" " + _toDisplayString(website), 1)
                      ]))
                    }), 128))
                  ]))
                : (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(_unref(t)('project.setup.playbackChannel.acp.emptyList')), 1))
            ]),
            _: 1
          }, 8, ["title"]),
          _createVNode(SectionCollapse, {
            title: `<i class='fas fa-ban icon-deny'></i> Blocked Website (${_unref(data).denyDomains.length})`
          }, {
            default: _withCtx(() => [
              (_unref(data).denyDomains && _unref(data).denyDomains.length)
                ? (_openBlock(), _createElementBlock("ul", _hoisted_3, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(data).denyDomains, (website, index) => {
                      return (_openBlock(), _createElementBlock("li", {
                        class: "website",
                        key: index
                      }, [
                        _cache[2] || (_cache[2] = _createElementVNode("i", { class: "fas fa-earth-asia" }, null, -1)),
                        _createTextVNode(" " + _toDisplayString(website), 1)
                      ]))
                    }), 128))
                  ]))
                : (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(_unref(t)('project.setup.playbackChannel.acp.emptyList')), 1))
            ]),
            _: 1
          }, 8, ["title"])
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}
}

})