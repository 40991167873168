import { loadTeamFeature } from '@/modules/shared';
import type { ActionContext } from 'vuex';
import type { RootState } from '@/store/type';
import type { TeamFeatures, TeamFeaturesState } from '../types/teamFeatures.type';

interface TeamFeaturesData {
  key: string;
  enabled: boolean;
}

type TeamFeaturesContext = ActionContext<TeamFeaturesState, RootState>;

const initialState = (): TeamFeaturesState => ({
  teamFeatures: null,
});

const state = initialState();

const mutations = {
  setTeamFeatures(state: TeamFeaturesState, teamFeatures: TeamFeatures) {
    state.teamFeatures = teamFeatures || {};
  },
  resetTeamFeatures(state: TeamFeaturesState) {
    Object.assign(state, initialState());
  },
};

const actions = {
  async loadTeamFeatures({ commit }: TeamFeaturesContext) {
    try {
      const { data } = await loadTeamFeature();
      const features = data.features.reduce(
        (prev: TeamFeatures, feature: TeamFeaturesData) => ({
          ...prev,
          [feature.key]: feature.enabled,
        }),
        {},
      );
      commit('setTeamFeatures', features);
    } catch (err) {
      // console.log(err);
    }
  },
};

export default {
  state,
  mutations,
  actions,
};
