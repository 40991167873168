import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, createTextVNode as _createTextVNode, createVNode as _createVNode, withModifiers as _withModifiers, withCtx as _withCtx, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "detail" }
const _hoisted_2 = { class: "title" }
const _hoisted_3 = {
  key: 0,
  class: "state-item state-queued"
}
const _hoisted_4 = {
  key: 1,
  class: "state-item state-uploading"
}
const _hoisted_5 = { class: "progress-container" }
const _hoisted_6 = { class: "progress-upload-container" }
const _hoisted_7 = { class: "size" }
const _hoisted_8 = { class: "percent" }
const _hoisted_9 = {
  key: 2,
  class: "state-item state-uploaded"
}
const _hoisted_10 = { key: 1 }
const _hoisted_11 = { key: 2 }
const _hoisted_12 = {
  key: 3,
  class: "state-item state-retry"
}
const _hoisted_13 = {
  key: 4,
  class: "state-item state-failed"
}
const _hoisted_14 = { class: "text-danger" }
const _hoisted_15 = { class: "video-info show-mobile" }
const _hoisted_16 = {
  key: 0,
  class: "fas fa-check"
}
const _hoisted_17 = {
  key: 1,
  class: "fas fa-link"
}
const _hoisted_18 = {
  key: 0,
  class: "fas fa-check"
}
const _hoisted_19 = {
  key: 1,
  class: "fas fa-code"
}
const _hoisted_20 = { class: "video-info show-desktop" }
const _hoisted_21 = {
  key: 0,
  class: "fas fa-check"
}
const _hoisted_22 = {
  key: 1,
  class: "fas fa-link"
}
const _hoisted_23 = {
  key: 0,
  class: "fas fa-check"
}
const _hoisted_24 = {
  key: 1,
  class: "fas fa-code"
}
const _hoisted_25 = { class: "actions" }
const _hoisted_26 = ["title"]
const _hoisted_27 = ["title"]
const _hoisted_28 = ["title"]
const _hoisted_29 = ["title"]

import { copyValue } from '@/modules/shared/utils/copy';
import { useI18n } from 'vue-i18n';
import _ from 'lodash';
import { computed, ref, toRefs } from 'vue';
import { VideoKeyCopy } from '@/modules/projectVideoList';
import Button from '@/modules/shared/components/atoms/button/Button.vue';
import Tooltip from '@/modules/shared/components/atoms/tooltip/Tooltip.vue';
import { getVideoURL, getEmbeddedCode } from '@/modules/shared/utils/video';
import { type VideoUploadByKeyObject } from '@/modules/videoUpload/types/videoUpload.type';
import { prettyFileSize } from '@/modules/shared/utils/unitFormatter';
import {
  isVideoProbeError,
  isVideoSourceUploading,
  isVideoProbeCompleted,
} from '@/modules/videoDetail/utils/videoManagement';
import { useStore } from '../../../store/hooks';
import ReUploadVideoSourceFileModal from '../../organisms/reUploadVideoSourceFileModal/ReUploadVideoSourceFileModal.vue';

interface ExtendedUploadItemProps {
  video: VideoUploadByKeyObject;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'ExtendedVideoUploadProgressItem',
  props: {
    video: {}
  },
  setup(__props: any) {

/* eslint-disable */
const props = __props;
const { video } = toRefs(props);

const isShowReuploadModal = ref<boolean>(false);
const isEmbedCopied = ref<boolean>(false);
const isVideoUrlCopied = ref<boolean>(false);
const probeStatus = ref<string>('');

const { t } = useI18n();
const store = useStore();

const isDisplayProbeStatus = computed(() => store.state.videoUpload.isDisplayProbeStatus);
const uploadingInfo = computed(() => video.value.uploadingInfo);
const uploadProgress = computed(() => video.value.uploadingInfo?.progress ?? 0);
const uploadedSize = computed(() => prettyFileSize(_.get(uploadingInfo.value, 'uploadedSize', 0)));
const totalSize = computed(() => prettyFileSize(_.get(uploadingInfo.value, 'totalSize', 0)));

const uploadSpeed = computed(() => {
  const size = _.get(uploadingInfo.value, 'uploadedSize', 0);
  const uploadTimeStamp = _.get(uploadingInfo.value, 'differenceTime', 0);

  if (size && uploadTimeStamp) {
    const uploadTimeSecond = ((uploadTimeStamp) / 1000);
    return prettyFileSize(size / uploadTimeSecond);
  }

  return '0';
});

const uploadingState = computed(() => uploadingInfo.value?.state ?? 'queued');
const isQueued = computed(() => uploadingState.value === 'queued');
const isUploading = computed(() => uploadingState.value === 'uploading');
const isUploaded = computed(() => uploadingState.value === 'uploaded');
const isRetry = computed(() => uploadingState.value === 'retry');
const isFailed = computed(() => uploadingState.value === 'failed');
const canRemoveFile = computed(() => isQueued.value);
const canShowCompleteState = computed(() => {
  if (!isDisplayProbeStatus.value) {
    return isUploaded.value;
  }

  return isUploaded.value && isVideoProbeCompleted(video.value) && !isVideoProbeError(video.value);
});

function getProbeStatus(): string {
  if (!isVideoProbeCompleted(video.value)) {
    probeStatus.value = 'checking';
    return t('video.upload.checkingFile');
  }

  if (isVideoProbeError(video.value)) {
    probeStatus.value = 'error';
    return t('video.upload.uploadedFileError.fullText');
  }

  probeStatus.value = 'complete';
  return t('video.upload.uploaded');
}

function onRetryUploadVideo() {
  const videoKey = video.value.key;
  store.dispatch('forceRetryUploadVideo', { videoKey });
}

function onReuploadVideoSourceFile() {
  isShowReuploadModal.value = !isShowReuploadModal.value;
}

function onCopy(key: string, value: string) {
  copyValue(value);

  if (key === 'embedded') {
    isEmbedCopied.value = true;
  } else {
    isVideoUrlCopied.value = true;
  }

  const timeout = setTimeout(() => {
    if (key === 'embedded') {
      isEmbedCopied.value = false;
    } else {
      isVideoUrlCopied.value = false;
    }

    clearTimeout(timeout);
  }, 1000);
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (isShowReuploadModal.value)
      ? (_openBlock(), _createBlock(ReUploadVideoSourceFileModal, {
          key: 0,
          video: _unref(video),
          onClose: onReuploadVideoSourceFile
        }, null, 8, ["video"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass(`list-group-item list-group-item-${uploadingState.value}`)
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(['icon-prefix', uploadingState.value, { 'is-failed': isFailed.value }])
      }, _cache[4] || (_cache[4] = [
        _createElementVNode("i", { class: "fa-solid fa-file-video" }, null, -1)
      ]), 2),
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, _toDisplayString(_unref(video).title), 1),
        (isQueued.value)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_unref(t)('video.uploader.enqueued')), 1))
          : _createCommentVNode("", true),
        (isUploading.value)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("div", {
                  class: "progress-bar",
                  style: _normalizeStyle({ 'width': `${uploadProgress.value}%` })
                }, null, 4)
              ]),
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("div", _hoisted_7, _toDisplayString(uploadedSize.value) + " / " + _toDisplayString(totalSize.value) + " (" + _toDisplayString(uploadSpeed.value) + "/s) ", 1),
                _createElementVNode("div", _hoisted_8, _toDisplayString(uploadProgress.value) + "% ", 1)
              ])
            ]))
          : _createCommentVNode("", true),
        (isUploaded.value)
          ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
              (isDisplayProbeStatus.value)
                ? (_openBlock(), _createElementBlock("span", {
                    key: 0,
                    class: _normalizeClass({
            'probe-status state-upload': true,
            'text-danger': _unref(isVideoProbeError)(_unref(video)),
            'warning-status': probeStatus.value === 'overtime',
          })
                  }, _toDisplayString(getProbeStatus()), 3))
                : (!isDisplayProbeStatus.value && uploadProgress.value !== 100)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_10, _toDisplayString(uploadProgress.value) + "% uploaded ", 1))
                  : (_openBlock(), _createElementBlock("span", _hoisted_11, _toDisplayString(_unref(t)('video.upload.uploaded')), 1))
            ]))
          : _createCommentVNode("", true),
        (isRetry.value)
          ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
              _cache[5] || (_cache[5] = _createElementVNode("div", { class: "spinner-border text-primary" }, [
                _createElementVNode("span", { class: "sr-only" })
              ], -1)),
              _createTextVNode(" " + _toDisplayString(_unref(t)('video.uploader.failed.retrying', {item: _unref(video).remainingRetryDelay})), 1)
            ]))
          : _createCommentVNode("", true),
        (isFailed.value)
          ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
              _createElementVNode("span", _hoisted_14, _toDisplayString(_unref(t)('video.uploader.failed.helpText')), 1)
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_15, [
          _createVNode(_unref(VideoKeyCopy), {
            videoKey: _unref(video).key
          }, null, 8, ["videoKey"]),
          (_unref(getVideoURL)(_unref(video)))
            ? (_openBlock(), _createBlock(Tooltip, {
                key: 0,
                class: "copy-url-button-container"
              }, {
                "tooltip-text": _withCtx(() => _cache[6] || (_cache[6] = [
                  _createTextVNode(" Copy Video URL")
                ])),
                default: _withCtx(() => [
                  _createVNode(Button, {
                    onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (onCopy('videoUrl', _unref(getVideoURL)(_unref(video)))), ["prevent"])),
                    type: "button",
                    buttonStyle: "text-secondary",
                    buttonClasses: "link-copy-button"
                  }, {
                    "icon-prefix": _withCtx(() => [
                      (isVideoUrlCopied.value)
                        ? (_openBlock(), _createElementBlock("i", _hoisted_16))
                        : (_openBlock(), _createElementBlock("i", _hoisted_17))
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (_unref(getEmbeddedCode)(_unref(video)))
            ? (_openBlock(), _createBlock(Tooltip, {
                key: 1,
                class: "copy-embed-button-container"
              }, {
                "tooltip-text": _withCtx(() => _cache[7] || (_cache[7] = [
                  _createTextVNode(" Copy Embed Code")
                ])),
                default: _withCtx(() => [
                  _createVNode(Button, {
                    onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (onCopy('embedded', _unref(getEmbeddedCode)(_unref(video)))), ["prevent"])),
                    type: "button",
                    buttonStyle: "text-secondary",
                    buttonClasses: "link-copy-button"
                  }, {
                    "icon-prefix": _withCtx(() => [
                      (isEmbedCopied.value)
                        ? (_openBlock(), _createElementBlock("i", _hoisted_18))
                        : (_openBlock(), _createElementBlock("i", _hoisted_19))
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ])
      ]),
      _createElementVNode("div", _hoisted_20, [
        _createVNode(_unref(VideoKeyCopy), {
          videoKey: _unref(video).key
        }, null, 8, ["videoKey"]),
        (_unref(getVideoURL)(_unref(video)))
          ? (_openBlock(), _createBlock(Tooltip, {
              key: 0,
              class: "copy-url-button-container"
            }, {
              "tooltip-text": _withCtx(() => _cache[8] || (_cache[8] = [
                _createTextVNode(" Copy Video URL")
              ])),
              default: _withCtx(() => [
                _createVNode(Button, {
                  onClick: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (onCopy('videoUrl', _unref(getVideoURL)(_unref(video)))), ["prevent"])),
                  type: "button",
                  buttonStyle: "text-secondary",
                  buttonClasses: "link-copy-button"
                }, {
                  "icon-prefix": _withCtx(() => [
                    (isVideoUrlCopied.value)
                      ? (_openBlock(), _createElementBlock("i", _hoisted_21))
                      : (_openBlock(), _createElementBlock("i", _hoisted_22))
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        (_unref(getEmbeddedCode)(_unref(video)))
          ? (_openBlock(), _createBlock(Tooltip, {
              key: 1,
              class: "copy-embed-button-container"
            }, {
              "tooltip-text": _withCtx(() => _cache[9] || (_cache[9] = [
                _createTextVNode(" Copy Embed Code")
              ])),
              default: _withCtx(() => [
                _createVNode(Button, {
                  onClick: _cache[3] || (_cache[3] = _withModifiers(($event: any) => (onCopy('embedded', _unref(getEmbeddedCode)(_unref(video)))), ["prevent"])),
                  type: "button",
                  buttonStyle: "text-secondary",
                  buttonClasses: "link-copy-button"
                }, {
                  "icon-prefix": _withCtx(() => [
                    (isEmbedCopied.value)
                      ? (_openBlock(), _createElementBlock("i", _hoisted_23))
                      : (_openBlock(), _createElementBlock("i", _hoisted_24))
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_25, [
        (canShowCompleteState.value)
          ? (_openBlock(), _createElementBlock("span", {
              key: 0,
              title: _unref(t)('video.uploader.success')
            }, _cache[10] || (_cache[10] = [
              _createElementVNode("i", { class: "fas fa-circle-check text-success" }, null, -1)
            ]), 8, _hoisted_26))
          : _createCommentVNode("", true),
        (probeStatus.value === 'checking')
          ? (_openBlock(), _createElementBlock("span", {
              key: 1,
              title: _unref(t)('video.uploader.success')
            }, _cache[11] || (_cache[11] = [
              _createElementVNode("i", { class: "fad fa-spinner-third fa-spin text-primary" }, null, -1)
            ]), 8, _hoisted_27))
          : _createCommentVNode("", true),
        (isFailed.value)
          ? (_openBlock(), _createElementBlock("span", {
              key: 2,
              class: "with-pointer",
              title: _unref(t)('video.uploader.failed.button'),
              onClick: onRetryUploadVideo
            }, _cache[12] || (_cache[12] = [
              _createElementVNode("i", { class: "fas fa-rotate-right" }, null, -1)
            ]), 8, _hoisted_28))
          : _createCommentVNode("", true),
        (_unref(isVideoProbeError)(_unref(video)) && !_unref(isVideoSourceUploading)(_unref(video)))
          ? (_openBlock(), _createElementBlock("span", {
              key: 3,
              title: _unref(t)('video.uploader.failed.button'),
              class: "with-pointer",
              onClick: onReuploadVideoSourceFile
            }, _cache[13] || (_cache[13] = [
              _createElementVNode("i", { class: "fas fa-upload" }, null, -1)
            ]), 8, _hoisted_29))
          : _createCommentVNode("", true)
      ])
    ], 2)
  ], 64))
}
}

})