import type { ActionContext } from 'vuex';
import { loadTeamSetting, updateTeamSettingItem } from '@/modules/teamSetting/services';
import { formatErrorObject } from '@/modules/shared/utils/errorFormatter';
import { setStateLoadingStatusByType, setStateSavingStatusByType } from '@/modules/shared/utils/stateManagement';
import type { TeamSetting, TeamSettingState } from '@/modules/teamSetting/types';
import type { SavingState, LoadingState } from '@/types/State.type';
import type { RootState } from '@/store/type';

type TeamSettingsContext = ActionContext<TeamSettingState, RootState>;

const initialState = () => ({
  teamSettingData: null,
  loadTeamSettingState: setStateLoadingStatusByType(),
  saveTeamSettingState: setStateSavingStatusByType(),
});

const state: TeamSettingState = initialState();

const mutations = {
  setTeamSetting(state: TeamSettingState, data: TeamSetting) {
    state.teamSettingData = { ...state.teamSettingData, ...data };
  },
  setLoadTeamSettingState(state: TeamSettingState, loadingState: LoadingState) {
    state.loadTeamSettingState = setStateLoadingStatusByType(loadingState);
  },
  setSaveTeamSettingState(state: TeamSettingState, savingState: SavingState) {
    state.saveTeamSettingState = setStateSavingStatusByType(savingState);
  },
  resetTeamSetting() {
    Object.assign(state, initialState());
  },
};

const actions = {
  async loadTeamSetting({ commit }: TeamSettingsContext) {
    commit('setLoadTeamSettingState', { type: 'loading' });
    try {
      const response = await loadTeamSetting();
      commit('setTeamSetting', response.data);
      commit('setLoadTeamSettingState', { type: 'success' });
    } catch (error) {
      commit('setLoadTeamSettingState', { type: 'error', error: formatErrorObject(error, 'Team Settings') });
    }
  },
  async updateTeamSettingItem({ commit }: TeamSettingsContext, data: { itemType: string; itemId: string }) {
    commit('setSaveTeamSettingState', { type: 'saving' });
    try {
      const { itemType, itemId } = data;
      const response = await updateTeamSettingItem(itemType, itemId);

      commit('setTeamSetting', response.data);
      commit('setSaveTeamSettingState', { type: 'success' });
    } catch (error) {
      commit('setSaveTeamSettingState', { type: 'error', error: formatErrorObject(error, 'Team Settings') });
    }
  },
  resetTeamSettingState({ commit }: TeamSettingsContext) {
    commit('resetTeamSetting');
  },
};

export default {
  state,
  mutations,
  actions,
};
