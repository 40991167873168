<template>
  <Field :title="t('video.subtitle.status.setting.choose')">
    <RadioButtonGroup
      :options="statusOptions"
      :modelValue="modelValue"
      :onChange="onChange"
      :disabled="isDisableRadioButton"
    ></RadioButtonGroup>
  </Field>
</template>

<script lang="ts" setup>
import { computed, reactive, toRefs } from 'vue';
import { useI18n } from 'vue-i18n';
import RadioButtonGroup from '@/modules/shared/components/molecules/radioButtonGroup/RadioButtonGroup.vue';
import Field from '@/modules/shared/components/molecules/field/Field.vue';

interface CaptionStatusRadioGroupProps {
  modelValue: string;
  onChange: () => void;
  isDisableRadioButton?: boolean;
}

const props = withDefaults(defineProps<CaptionStatusRadioGroupProps>(), {
  isDisableRadioButton: false,
});
const {
  modelValue,
  onChange,
  isDisableRadioButton,
} = toRefs(props);

const { t } = useI18n();

const statusOptions = reactive([
  {
    label: computed(() => t('video.subtitle.status.setting.draft.title')),
    description: computed(() => t('video.subtitle.status.setting.draft.description')),
    value: 'draft',
  },
  {
    label: computed(() => t('video.subtitle.status.setting.publish.title')),
    description: computed(() => t('video.subtitle.status.setting.publish.description')),
    value: 'publish',
  },
  // {
  //   label: computed(() => t('video.subtitle.status.setting.publishIfNoBadWord.title')),
  //   description: computed(() => t('video.subtitle.status.setting.publishIfNoBadWord.description')),
  //   value: 'publishIfNoBadWord',
  // },
]);
</script>
