<template>
  <div
    v-if="user"
    class="logo"
    :style="[
      options,
      { backgroundImage: generateBackgroundImage() },
      { backgroundColor: generateBackgroundColor() },
    ]"
  >
    <div v-if="user.name && !generateBackgroundImage()" class="name">
      {{user.name.charAt(0)}}
    </div>
    <slot class="name"></slot>
  </div>
</template>

<script lang="ts" setup>
import { StyleValue, toRefs } from 'vue';
import _ from 'lodash';
import { colorGeneratorFromString } from '@/modules/shared/utils/colorGenerator';
import type { User } from '@/modules/user';
import type { Nullable } from '@/types/index.type';

interface LogoProps {
  user: Nullable<User>;
  options?: StyleValue;
}

const props = withDefaults(defineProps<LogoProps>(), {
  options: () => ({
    width: '36px',
    height: '36px',
  }),
});

const { user, options } = toRefs(props);

function generateBackgroundColor() {
  if (!user.value) {
    return '';
  }

  return _.get(options.value, 'backgroundColor', colorGeneratorFromString(user.value.name));
}

function generateBackgroundImage() {
  const imageObject = _.get(user.value, 'displayImage.sizes.large.url', null);

  if (imageObject) {
    return `url(${imageObject})`;
  }

  return undefined;
}
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/global-variables.scss';

.logo {
  width: 36px;
  height: 36px;
  border: 1px solid $grey-200;
  border-radius: 50%;
  background-color: $grey-700;
  color: $grey-200;

  display: flex;
  align-items: center;
  justify-content: center;

  text-transform: uppercase;
  font-size: $font-size-base * 1.25;

  background-size: cover;
  background-position: center center;

  .name {
    line-height: 0;
  }
}

</style>
