import qs from 'qs';
import { axiosAPI } from '@/plugins/axios';
import type { Filter } from '@/types/Axios.type';

export const loadUrlSigningKeysAPI = (filter: Filter) => {
  const query = qs.stringify(filter, { addQueryPrefix: true, skipNulls: true });
  return axiosAPI.get(`/url-signing-keys${query}`);
};
export const loadUrlSigningKeyByIdAPI = (urlSigningKeyId: string) => axiosAPI.get(`/url-signing-keys/${urlSigningKeyId}`);
export const createUrlSigningKeyAPI = () => axiosAPI.post('/url-signing-keys');
export const deleteUrlSigningKeyAPI = (id: string) => axiosAPI.delete(`/url-signing-keys/${id}`);
