import type { RouteRecordRaw } from 'vue-router';
import store from '@/store';
import project from '@/modules/shared/store/project';
import { user } from '@/modules/user';
import bumper from '../store/bumper';
import bumperList from '../store/bumperList';

export function createBumperRoutes(): RouteRecordRaw[] {
  return [
    {
      name: 'manageBumpers',
      path: 'bumpers',
      component: () => import(/* webpackChunkName: "ManageBumperChrome" */ '@/modules/bumper/layouts/ManageBumperChrome.vue'),
      beforeEnter: (to, from, next) => {
        if (!store.hasModule('bumper')) {
          store.registerModule('bumper', bumper);
        }
        if (!store.hasModule('bumperList')) {
          store.registerModule('bumperList', bumperList);
        }
        if (!store.hasModule('project')) {
          store.registerModule('project', project);
        }
        if (!store.hasModule('user')) {
          store.registerModule('user', user);
        }
        next();
      },
      children: [
        {
          path: '',
          name: 'manageBumper',
          component: () => import(/* webpackChunkName: "ManageBumper" */ '@/modules/bumper/pages/ManageBumper.vue'),
        },
        {
          path: 'create',
          name: 'createBumper',
          components: {
            default: () => import(/* webpackChunkName: "ManageBumper" */ '@/modules/bumper/pages/ManageBumper.vue'),
            createBumper: () => import(/* webpackChunkName: "CreateBumperModal" */ '@/modules/bumper/components/organisms/createBumperModal/CreateBumperModal.vue'),
          },
        },
        {
          path: ':bumperId',
          name: 'editBumper',
          components: {
            default: () => import(/* webpackChunkName: "ManageBumperSchedule" */ '@/modules/bumper/pages/ManageBumper.vue'),
            createBumper: () => import(/* webpackChunkName: "EditBumperModal" */ '@/modules/bumper/components/organisms/editBumperSlideOut/EditBumperSlideOut.vue'),
          },
        },
      ],
    },
  ];
}
