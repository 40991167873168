import type { RouteRecordRaw } from 'vue-router';
import store from '@/store';
import { presetList } from '@/modules/preset';
import keyServer from '../store/keyServer';

export default function createClearKeyRoutes(): RouteRecordRaw[] {
  const beforeEnter = (to: any, from: any, next: () => void) => {
    if (!store.hasModule('keyServer')) {
      store.registerModule('keyServer', keyServer);
    }
    if (!store.hasModule('presetList')) {
      store.registerModule('presetList', presetList);
    }
    next();
  };

  return [
    {
      path: 'clear-key',
      name: 'clearKey',
      component: () => import(/* webpackChunkName: "ManageClearKey" */ '../pages/ManageClearKey.vue'),
      meta: { teamManagement: true },
      beforeEnter,
    },
    {
      name: 'keyServer',
      path: 'key-servers',
      meta: { teamManagement: true },
      redirect: { name: 'clearKey' },
      beforeEnter,
    },
    {
      name: 'keyServerEdit',
      path: 'key-servers/:id',
      meta: { teamManagement: true },
      component: () => import(/* webpackChunkName: "ManageKeyServersEdit" */ '@/modules/clearKey/pages/ManageKeyServersEdit.vue'),
      beforeEnter,
    },
  ];
}
