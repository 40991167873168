/* eslint no-cond-assign: ["off"] */

import router from '@/router';

const hasRouterHistory = () => window.history.length > 2;

const backToPage = (actionURL) => {
  if (hasRouterHistory()) {
    router.go(-1);
  } else {
    // when you double click too fast
    // to prevent Avoided redundant navigation to current location
    // ref : https://stackoverflow.com/questions/57837758/navigationduplicated-navigating-to-current-location-search-is-not-allowed
    router.push(actionURL).catch(() => { });
  }
};

const findParentElementByClass = (targetNode, cls) => {
  let el = targetNode;
  while ((el = el.parentElement) && !el.classList.contains(cls));
  return el;
};

const goToPageDetail = (url, index, event) => {
  const targetNode = event.target;
  const isElementExising = findParentElementByClass(targetNode, 'disable-click-row');
  if (!isElementExising) {
    // when you double click too fast
    // to prevent Avoided redundant navigation to current location
    // ref : https://stackoverflow.com/questions/57837758/navigationduplicated-navigating-to-current-location-search-is-not-allowed
    router.push(url).catch(() => { });
  }
};

export {
  backToPage,
  goToPageDetail,
};
