import { DeletedVideo } from '@/modules/shared/types/video.type';
import { setStateLoadingStatusByType } from '@/modules/shared/utils/stateManagement';
import { StatusState } from '@/types/State.type';
import { RootState } from '@/store/type';
import { ActionContext } from 'vuex';
import { formatErrorObject } from '@/modules/shared/utils/errorFormatter';
import type { ProjectDeletedVideoListState } from '../types/projectDeletedVideoList.type';
import { loadRecentlyDeleted } from '../services';

type ProjectDeletedVideoListActionContext = ActionContext<ProjectDeletedVideoListState, RootState>;

const initialState = (): ProjectDeletedVideoListState => ({
  projectDeletedVideoList: [],
  projectDeletedVideoTotal: 0,
  projectDeletedVideoListLastPage: 0,
  loadProjectDeletedVideoListState: setStateLoadingStatusByType(),
  recoveredProjectIds: [],
});

const state = initialState();

const mutations = {
  setProjectDeletedVideoList(state: ProjectDeletedVideoListState, videos: DeletedVideo[]) {
    state.projectDeletedVideoList = videos;
  },
  setProjectDeletedVideoTotal(state: ProjectDeletedVideoListState, total: number) {
    state.projectDeletedVideoTotal = total;
  },
  setProjectDeletedVideoListLastPage(state: ProjectDeletedVideoListState, lastPage: number) {
    state.projectDeletedVideoListLastPage = lastPage;
  },
  setLoadProjectDeletedVideoListState(state: ProjectDeletedVideoListState, loadingState: StatusState) {
    state.loadProjectDeletedVideoListState = loadingState;
  },
  setRecoveredProjectIds(state: ProjectDeletedVideoListState, projectIds: string[]) {
    state.recoveredProjectIds = projectIds;
  },
};

const actions = {
  async loadProjectDeletedVideoList({ commit }: ProjectDeletedVideoListActionContext, { filter }: { filter: { q: string } }) {
    commit('setLoadProjectDeletedVideoListState', setStateLoadingStatusByType({ type: 'loading' }));
    try {
      const response = await loadRecentlyDeleted(filter);
      commit('setProjectDeletedVideoList', response.data.data);
      commit('setProjectDeletedVideoTotal', response.data.total);
      commit('setProjectDeletedVideoListLastPage', response.data.lastPage);
      commit('setLoadProjectDeletedVideoListState', setStateLoadingStatusByType({ type: 'success' }));
    } catch (error) {
      commit('setLoadProjectDeletedVideoListState', setStateLoadingStatusByType({
        type: 'error',
        error: formatErrorObject(error, 'Deleted Videos'),
      }));
    }
  },
  async updateRecoveredProjectIds({ commit }: ProjectDeletedVideoListActionContext, projectIds: string[]) {
    commit('setRecoveredProjectIds', projectIds);
  },
};

export default {
  state,
  actions,
  mutations,
};
