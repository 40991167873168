<template>
  <div :class="`text-cell cell ${className}`" :data-test="dataTest">
    <div class="primary-text" v-if="primaryText">
      {{primaryText}}
    </div>
    <div class="sub-text" v-if="subText">
      {{subText}}
    </div>
    <template v-if="text">
      <div class="text" v-if="typeof text === 'string'">
        {{text}}
      </div>
      <div v-else-if="isArray(text)" class="text">
        <div v-for="line in text" :key="line">
          {{line}}
        </div>
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
import { toRefs } from 'vue';
import { isArray } from 'lodash';

interface TextCellProps {
  primaryText?: string;
  subText?: string;
  text?: string | string[];
  className?: string;
  dataTest?: string;
}

const props = defineProps<TextCellProps>();
const {
  primaryText,
  subText,
  text,
  dataTest,
} = toRefs(props);
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/global-variables.scss';

.text-cell {
  /* padding: $spacing-12 0; */

  > * {
    overflow: hidden;
    max-width: 100%;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    word-break: break-word;
  }
}

.primary-text {
  font-size: $font-level-7;
  font-weight: $font-weight-bold;
  color: $grey-800;
}

.sub-text {
  font-size: $font-level-8;
  margin-top: $spacing-4;
}

.text {
  font-size: $font-level-7;
}
</style>
