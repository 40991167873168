import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, Fragment as _Fragment, withCtx as _withCtx } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "preloader-container" }
const _hoisted_3 = { class: "preloader-container" }
const _hoisted_4 = { class: "preloader-container" }

import _ from 'lodash';
import { computed, onMounted, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';
import { useStore } from '@/modules/projectDefaultUploadSetting/store/hooks';
import Page from '@/layouts/Page.vue';
import PreLoaderSection from '@/modules/shared/components/atoms/preLoaderSection/PreLoaderSection.vue';
import ProjectManageUploadSettingPresetSection from '../components/organisms/projectManageUploadSettingPresetSection/ProjectManageUploadSettingPresetSection.vue';
import ProjectManageUploadSettingGeoblockSection from '../components/organisms/projectManageUploadSettingGeoblockSection/ProjectManageUploadSettingGeoblockSection.vue';
import ProjectManageUploadSettingPlaybackChannelSection from '../components/organisms/projectManageUploadSettingPlaybackChannelSection/ProjectManageUploadSettingPlaybackChannelSection.vue';
import ProjectManageUploadSettingSubtitleSection from '../components/organisms/projectManageUploadSettingSubtitleSection/ProjectManageUploadSettingSubtitleSection.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'ProjectManageUploadSetting',
  setup(__props) {

const { t } = useI18n();
const store = useStore();
const route = useRoute();

const loadProjectManagementState = computed(() => store.state.project.loadProjectManagementState);
const currentProject = computed(() => store.state.project.currentProject);
const canUpdateProject = computed(() => currentProject.value?.actions?.updateProject ?? false);
const isManagerOrAdmin = computed(() => store.getters.isManagerOrAdmin);

const routeHash = computed(() => route.hash);

function onChangeRouteHash(newValue: string) {
  if (newValue === '#playback') {
    const element = document.getElementById('playback');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }
  if (newValue === '#geoblocking') {
    const element = document.getElementById('geoblocking');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }
  if (newValue === '#subtitle') {
    const element = document.getElementById('subtitle');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }
  if (newValue === '#preset') {
    // const element = document.getElementById('preset');
    // if (element) {
    //   element.scrollIntoView({ behavior: 'smooth', block: 'center' });
    // }

    /* Element scrollIntoView not working with preset. Use window.scrollTo instead  */
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }
}

watch(routeHash, onChangeRouteHash);

onMounted(() => {
  onChangeRouteHash(routeHash.value);
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(Page, {
    title: _unref(t)('project.advanced.defaults.title'),
    "page-description": _unref(t)('project.advanced.defaults.subTitle'),
    projectName: currentProject.value?.name
  }, {
    default: _withCtx(() => [
      (loadProjectManagementState.value.status === 'loading')
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(PreLoaderSection, { options: { height: 200 } })
            ]),
            _createElementVNode("div", _hoisted_3, [
              _createVNode(PreLoaderSection, { options: { height: 350 } })
            ]),
            _createElementVNode("div", _hoisted_4, [
              _createVNode(PreLoaderSection, { options: { height: 350 } })
            ])
          ]))
        : _createCommentVNode("", true),
      (loadProjectManagementState.value.status === 'success')
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            _createVNode(ProjectManageUploadSettingPresetSection, {
              canUpdateProject: canUpdateProject.value,
              isManager: isManagerOrAdmin.value,
              id: "preset"
            }, null, 8, ["canUpdateProject", "isManager"]),
            _createVNode(ProjectManageUploadSettingGeoblockSection, {
              canUpdateProject: canUpdateProject.value,
              isManager: isManagerOrAdmin.value,
              id: "geoblocking"
            }, null, 8, ["canUpdateProject", "isManager"]),
            _createVNode(ProjectManageUploadSettingSubtitleSection, {
              canUpdateProject: canUpdateProject.value,
              isManager: isManagerOrAdmin.value,
              id: "subtitle"
            }, null, 8, ["canUpdateProject", "isManager"]),
            (!_unref(_).isNil(currentProject.value) && currentProject.value.playbackChannels)
              ? (_openBlock(), _createBlock(ProjectManageUploadSettingPlaybackChannelSection, {
                  key: 0,
                  canUpdateProject: canUpdateProject.value,
                  isManager: isManagerOrAdmin.value,
                  id: "playback"
                }, null, 8, ["canUpdateProject", "isManager"]))
              : _createCommentVNode("", true)
          ], 64))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["title", "page-description", "projectName"]))
}
}

})