<script setup lang="ts">
import type { InputMember } from '@/modules/projectMember/types';
import { emailRgeEx } from '@/modules/shared/utils/validator';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import {
  computed, onBeforeUnmount, onMounted, ref, toRefs, watch,
} from 'vue';
import { VueTagsInput } from '@vojtechlanka/vue-tags-input';
import Logo from '@/modules/shared/components/atoms/logo/Logo.vue';
import { useI18n } from 'vue-i18n';

interface InviteMemberTagsInputProps {
  membersThatAreNotInsideThisProject: Array<InputMember>;
  membersThatAreInsideThisProject: Array<InputMember>;
  modelValue: Array<InputMember>;
}

const props = defineProps<InviteMemberTagsInputProps>();
const {
  membersThatAreNotInsideThisProject,
  membersThatAreInsideThisProject,
  modelValue,
} = toRefs(props);

const emit = defineEmits<{
  'update:modelValue': [value: Array<InputMember>];
  'scrollShadowChange': [value: boolean];
}>();

const { t } = useI18n();

const modalBodyElement = ref<HTMLElement | null>(null);
const isShowScrollShadow = ref<boolean>(false);
const q = ref<string>('');

const getAccountField = (member: InputMember, field: 'name' | 'email'): string | undefined => {
  if (isEmpty(member.account)) {
    return undefined;
  }

  if (isNil(member.account[field])) {
    return undefined;
  }

  return member.account[field]!.toLowerCase();
};

const autocompleteItems = computed(() => {
  const searchedText = q.value.toLowerCase();
  let result = membersThatAreNotInsideThisProject.value.filter((member) => (
    Boolean(getAccountField(member, 'name')?.includes(searchedText) || getAccountField(member, 'email')?.includes(searchedText))
      && !member.isAlreadySelected
  )).map((member) => ({ ...member, text: member.account?.email }));

  const foundMember = membersThatAreInsideThisProject.value.find((member) => getAccountField(member, 'email') === searchedText);
  const foundSelectedMember = modelValue.value.find((member) => member.text?.toLowerCase() === searchedText);

  if (foundMember) {
    result = [{
      account: foundMember.account,
      text: searchedText,
      isAlreadyAMember: true,
    }];
  } else if (foundSelectedMember) {
    result = [{
      account: {
        name: searchedText,
        email: searchedText,
      },
      text: searchedText,
      isAlreadySelected: true,
    }];
  } else if (isEmpty(result) && emailRgeEx.test(searchedText)) {
    result = [{
      account: {
        name: searchedText,
        email: searchedText,
      },
      text: searchedText,
      notAWorkspaceMember: true,
    }];
  }

  return result;
});

const shouldShowScrollShadow = (): void => {
  const modalBody = document.getElementById('content-body');
  const form = document.getElementById('form');

  if (form && modalBody) {
    setTimeout(() => {
      isShowScrollShadow.value = form.scrollHeight > 440 && modalBody.scrollTop < 8;
    }, 100);
  }
};

const onClickInputBox = (): void => {
  const input = document.getElementById('text-input');
  if (input) {
    input.focus();
  }
  shouldShowScrollShadow();
};

watch(q, shouldShowScrollShadow);
watch(shouldShowScrollShadow, () => emit('scrollShadowChange', isShowScrollShadow.value));

onMounted(() => {
  modalBodyElement.value = document.getElementById('content-body');

  if (modalBodyElement.value) {
    modalBodyElement.value.addEventListener('scroll', shouldShowScrollShadow);
  }
});

onBeforeUnmount(() => {
  if (modalBodyElement.value) {
    modalBodyElement.value.removeEventListener('scroll', shouldShowScrollShadow);
  }
});
</script>

<template>
  <div class="input-box" @click="onClickInputBox">
    <vue-tags-input
      id="text-input"
      v-model="q"
      :tags="modelValue"
      @tags-changed="(members) => {
        // Filter out suspended users if were manually input
        const membersWithoutSuspended = members.filter((member) => (
          membersThatAreNotInsideThisProject.find((m) => m.account?.email === member.text)?.isSuspended !== true));

        // Filter out already-a-member users if were manually input
        const validMembers = membersWithoutSuspended.filter((member) => (
          membersThatAreInsideThisProject.find((m) => m.account?.email === member.text) === undefined));

        emit('update:modelValue', validMembers);

        q = '';
        shouldShowScrollShadow();
      }"
      @blur="shouldShowScrollShadow"
      :add-on-key="[13, 188]"
      :autocomplete-items="autocompleteItems"
      :separators="[', ', ',', ' ']"
      :validation="[
        { classes: '', rule: emailRgeEx },
        {
          classes: 'ti-invalid',
          rule: ({ text }) => membersThatAreInsideThisProject.find((member) => member.account?.email === text) !== undefined,
        },
      ]"
      :is-draggable="true"
      :placeholder="isEmpty(modelValue) ? t('project.manage.members.emailPlaceholder') : ''"
      :autocomplete-min-length="0"
      :autocomplete-filter-duplicates="false"
      autocomplete="off"
    >
      <template v-slot:tag-center="{ tag }">
        <template v-if="tag.notAWorkspaceMember">
          {{tag.text}}
        </template>
        <template v-else-if="tag.isAlreadyAMember">
          {{tag.account?.name ?? tag.text}} (Already a project member)
        </template>
        <template v-else-if="tag.account?.name">
          {{tag.account.name}}
        </template>
        <template v-else>
          {{tag.text}}
        </template>
      </template>
      <template v-slot:autocomplete-item="{ item, performAdd }">
        <button
          type="button"
          class="autocomplete-item"
          :class="{ disabled: item.isAlreadyAMember || item.isAlreadySelected || item.isSuspended }"
          :disabled="item.isAlreadyAMember || item.isAlreadySelected || item.isSuspended"
          @click="() => performAdd(item)"
        >
          <span class="user-avatar">
            <Logo :user="item.account"/>
          </span>
          <span class="text-container">
            <span :class="`name ${item.notAWorkspaceMember ? 'padding' : ''}`">
              {{item.account?.name}}
            </span>
            <span class="email">{{item.account?.email}}</span>
          </span>
          <span v-if="item.notAWorkspaceMember" class="autocomplete-notice-text">
            Not a workspace member
          </span>
          <span v-if="item.isAlreadyAMember" class="autocomplete-notice-text">
            Already a project member
          </span>
          <span v-if="item.isAlreadySelected" class="autocomplete-notice-text">
            Already selected
          </span>
          <span v-if="item.isSuspended" class="autocomplete-notice-text">
            Suspended member
          </span>
        </button>
      </template>
    </vue-tags-input>
  </div>
</template>

<style scoped lang="scss">
@import '~@/assets/scss/global-variables';
@import '~@/assets/scss/themes/helpers';

.input-box {
  cursor: text;
}

:deep(.vue-tags-input) {
  border: 1px solid #e8e8e8;
  border-radius: 3px;
  margin-top: $spacing-8;
  max-width: 100%;
  min-height: 240px;

  .ti-input {
    border: none;
  }

  .ti-tag {
    border-radius: 5px;
    padding: 5px 8px 4px;
    font-size: 13px;

    .ti-tag-center {
      word-break: break-all;
    }

    &.ti-valid {
      background-color: #EBEDEF;
      color: $grey-700;
    }

    &.ti-invalid {
      background-color: $danger-pale;
      color: #E14242;
    }
  }

  .ti-new-tag-input {
    color: $grey-800;
  }

  .ti-autocomplete {
    @extend .scrollbar;
    border: 1px solid #e8e8e8;
    border-radius: 3px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    height: auto;
    max-height: 238px;
    overflow-y: auto;
  }
}

.autocomplete-item {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border: none;
  box-shadow: none;
  background-color: #FFF;
  cursor: pointer;
  outline: none;
  padding: $spacing-8 $spacing-base;
  text-align: left;
  transition: background-color 150ms ease;
  width: 100%;

  &.disabled {
    cursor: not-allowed;

    &:hover {
      background-color: $grey-100;

      .name {
        color: $grey-700;
      }

      .email {
        color: $grey-600;
      }

      .autocomplete-notice-text {
        color: #E76F51;
      }
    }
  }

  .avatar-container {
  }

  .text-container {
    position: relative;
    margin-left: $spacing-8;
    width: 100%;
    overflow: hidden;
  }

  .name {
    display: block;
    color: $grey-800;
    font-weight: 400;
    font-size: 14px;
    margin-bottom: $spacing-4;
    word-break: break-all;

    &.padding {
      padding-right: 142px;
    }
  }

  .email {
    display: block;
    color: $grey-600;
    font-weight: 400;
    font-size: 12px;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .autocomplete-notice-text {
    position: absolute;
    top: 12px;
    right: 16px;
    color: $grey-400;
    font-size: 12px;
    font-style: italic;
  }

  &:hover {
    background-color: $ci-primary;

    .name {
      color: #FFF;
    }

    .email {
      color: #d0dff2;
    }
  }
}
</style>
