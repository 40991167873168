import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createSlots as _createSlots } from "vue"

const _hoisted_1 = { class: "list-video-badword" }
const _hoisted_2 = { class: "video-list" }
const _hoisted_3 = {
  key: 0,
  class: "subtitle-issue"
}
const _hoisted_4 = { class: "bad-word-count" }

import { getQueryAsString } from '@/modules/shared/utils/query';
import { computed, watch, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute, useRouter } from 'vue-router';
import Section from '@/modules/shared/components/organisms/section/Section.vue';
import DataTable from '@/modules/shared/components/organisms/dataTable/DataTable.vue';
import Row from '@/modules/shared/components/molecules/row/Row.vue';
import Column from '@/modules/shared/components/molecules/column/Column.vue';
import HeaderCell from '@/modules/shared/components/molecules/headerCell/HeaderCell.vue';
import TextCell from '@/modules/shared/components/molecules/textCell/TextCell.vue';
import Button from '@/modules/shared/components/atoms/button/Button.vue';
import ImageView from '@/modules/shared/components/atoms/imageView/ImageView.vue';
import PreLoaderSection from '@/modules/shared/components/atoms/preLoaderSection/PreLoaderSection.vue';
import ErrorMessage from '@/modules/shared/components/atoms/errorMessage/ErrorMessage.vue';
import Pagination from '@/modules/shared/components/atoms/pagination/Pagination.vue';
import EmptySearch from '@/modules/shared/components/molecules/emptySearch/EmptySearch.vue';
import MessageBox from '@/modules/shared/components/molecules/messageBox/MessageBox.vue';
import Alert from '@/modules/shared/components/atoms/alert/Alert.vue';
import { useStore } from '../store/hooks';


export default /*@__PURE__*/_defineComponent({
  __name: 'ProjectVideoBadWordList',
  setup(__props) {

const searchQuery = ref<{ q?: string; page?: number }>({});
const { t } = useI18n();
const store = useStore();
const route = useRoute();
const router = useRouter();

// store
const videoList = computed(
  () => store.state.projectVideoCaptionIssueList.projectVideoCaptionIssueList,
);
const loadProjectVideoCaptionIssueListState = computed(
  () => store.state.projectVideoCaptionIssueList.loadProjectVideoCaptionIssueListState,
);
const lastPage = computed(
  () => store.state.projectVideoCaptionIssueList.projectVideoCaptionIssueListLastPage,
);

const query = computed(() => route.query);
const teamName = computed(() => route.params.teamName);
const projectKey = computed(() => route.params.projectKey);
const q = computed(() => getQueryAsString(route.query, 'q') ?? '');
const isEmpty = computed(() => !(videoList.value && videoList.value.length));
const isEmptySearch = computed(() => isEmpty.value && query.value.q);

const onSearchVideo = (event: any) => {
  searchQuery.value.q = event.target.value;
  router.push({ query: searchQuery.value });
};

watch(
  query,
  () => {
    store.dispatch('loadProjectVideoCaptionIssueList', {
      ...query.value,
      projectKey: projectKey.value,
    });
  },
  { immediate: true },
);

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (loadProjectVideoCaptionIssueListState.value.status === 'success' && !isEmpty.value)
      ? (_openBlock(), _createBlock(Alert, {
          key: 0,
          alertText: _unref(t)('videoIssues.alert'),
          priority: "info"
        }, null, 8, ["alertText"]))
      : _createCommentVNode("", true),
    (loadProjectVideoCaptionIssueListState.value.status === 'success')
      ? (_openBlock(), _createBlock(Section, { key: 1 }, {
          default: _withCtx(() => [
            _createVNode(DataTable, {
              onSearchData: onSearchVideo,
              searchInputValue: q.value,
              searchInputPlaceholder: _unref(t)('videos.searchBy')
            }, _createSlots({ _: 2 }, [
              (!isEmpty.value)
                ? {
                    name: "table-header",
                    fn: _withCtx(() => [
                      _createVNode(Row, null, {
                        default: _withCtx(() => [
                          _createVNode(Column, { width: "120px" }),
                          _createVNode(Column, { minWidth: "200px" }, {
                            default: _withCtx(() => [
                              _createVNode(HeaderCell, {
                                text: _unref(t)('videoIssues.table.videoInfo')
                              }, null, 8, ["text"])
                            ]),
                            _: 1
                          }),
                          _createVNode(Column, { width: "140px" }, {
                            default: _withCtx(() => [
                              _createVNode(HeaderCell, { text: "Video Key" })
                            ]),
                            _: 1
                          }),
                          _createVNode(Column, null, {
                            default: _withCtx(() => [
                              _createVNode(HeaderCell, {
                                text: _unref(t)('videoIssues.table.summary')
                              }, null, 8, ["text"])
                            ]),
                            _: 1
                          }),
                          _createVNode(Column, { width: "120px" })
                        ]),
                        _: 1
                      })
                    ]),
                    key: "0"
                  }
                : undefined,
              (!isEmpty.value)
                ? {
                    name: "table-content",
                    fn: _withCtx(() => [
                      _createElementVNode("div", _hoisted_2, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(videoList.value, (video) => {
                          return (_openBlock(), _createBlock(_component_router_link, {
                            to: `/${teamName.value}/projects/${projectKey.value}/videos/${video.key}/subtitles`,
                            key: video.id
                          }, {
                            default: _withCtx(() => [
                              _createVNode(Row, { "vertical-align": "center" }, {
                                default: _withCtx(() => [
                                  _createVNode(Column, { width: "120px" }, {
                                    default: _withCtx(() => [
                                      _createVNode(ImageView, {
                                        source: video.coverImage?.size,
                                        imageSize: "small"
                                      }, null, 8, ["source"])
                                    ]),
                                    _: 2
                                  }, 1024),
                                  _createVNode(Column, { minWidth: "200px" }, {
                                    default: _withCtx(() => [
                                      _createVNode(TextCell, {
                                        primaryText: video.title
                                      }, null, 8, ["primaryText"])
                                    ]),
                                    _: 2
                                  }, 1024),
                                  _createVNode(Column, { width: "140px" }, {
                                    default: _withCtx(() => [
                                      _createVNode(TextCell, {
                                        text: video.key
                                      }, null, 8, ["text"])
                                    ]),
                                    _: 2
                                  }, 1024),
                                  _createVNode(Column, null, {
                                    default: _withCtx(() => [
                                      (video.issue?.captionsWithBadWordIssueCount)
                                        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                                            _createElementVNode("span", null, [
                                              _createTextVNode(_toDisplayString(_unref(t)('videoIssues.table.badWordsFound')) + " ", 1),
                                              _createElementVNode("span", _hoisted_4, [
                                                _createTextVNode(_toDisplayString(video.issue?.captionsWithBadWordIssueCount) + " " + _toDisplayString(video.issue?.captionsWithBadWordIssueCount > 1 ? 'Subtitles' : 'Subtitle') + " ", 1),
                                                _cache[0] || (_cache[0] = _createElementVNode("i", { class: "fa fa-warning" }, null, -1))
                                              ])
                                            ])
                                          ]))
                                        : _createCommentVNode("", true)
                                    ]),
                                    _: 2
                                  }, 1024),
                                  _createVNode(Column, {
                                    width: "120px",
                                    isActionColumn: ""
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(Button, { buttonStyle: "text-secondary" }, {
                                        "icon-suffix": _withCtx(() => _cache[1] || (_cache[1] = [
                                          _createElementVNode("i", { class: "fa fa-chevron-right" }, null, -1)
                                        ])),
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(_unref(t)('videoIssues.table.view')) + " ", 1)
                                        ]),
                                        _: 1
                                      })
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 2
                              }, 1024)
                            ]),
                            _: 2
                          }, 1032, ["to"]))
                        }), 128))
                      ])
                    ]),
                    key: "1"
                  }
                : undefined,
              (lastPage.value > 1)
                ? {
                    name: "table-footer",
                    fn: _withCtx(() => [
                      _createVNode(Pagination, {
                        lastPage: lastPage.value,
                        page: _unref(route).query.page || 1
                      }, null, 8, ["lastPage", "page"])
                    ]),
                    key: "2"
                  }
                : undefined
            ]), 1032, ["searchInputValue", "searchInputPlaceholder"]),
            (isEmptySearch.value)
              ? (_openBlock(), _createBlock(EmptySearch, { key: 0 }))
              : _createCommentVNode("", true),
            (isEmpty.value && !isEmptySearch.value)
              ? (_openBlock(), _createBlock(MessageBox, {
                  key: 1,
                  iconClassName: "fas fa-check-circle",
                  title: _unref(t)('videoIssues.noIssue')
                }, null, 8, ["title"]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (loadProjectVideoCaptionIssueListState.value.status === 'error')
      ? (_openBlock(), _createBlock(Section, { key: 2 }, {
          default: _withCtx(() => [
            _createVNode(ErrorMessage, {
              statusCode: loadProjectVideoCaptionIssueListState.value.error?.statusCode,
              title: loadProjectVideoCaptionIssueListState.value.error?.message
            }, null, 8, ["statusCode", "title"])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (loadProjectVideoCaptionIssueListState.value.status === 'loading')
      ? (_openBlock(), _createBlock(PreLoaderSection, { key: 3 }))
      : _createCommentVNode("", true)
  ]))
}
}

})