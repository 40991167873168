import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["data-test"]
const _hoisted_2 = { class: "option__desc" }
const _hoisted_3 = ["data-test"]
const _hoisted_4 = {
  key: 0,
  class: "rules"
}
const _hoisted_5 = {
  class: "rule-item",
  "data-test": "selected-option-deny-all-desc"
}
const _hoisted_6 = {
  key: 1,
  class: "rules"
}
const _hoisted_7 = {
  key: 0,
  class: "rule-item"
}
const _hoisted_8 = {
  class: "country",
  "data-test": "selected-option-allow-country-list"
}
const _hoisted_9 = {
  key: 1,
  class: "rule-item"
}
const _hoisted_10 = {
  class: "country",
  "data-test": "selected-option-deny-country-list"
}
const _hoisted_11 = {
  key: 2,
  class: "rules"
}
const _hoisted_12 = {
  class: "rule-item",
  "data-test": "selected-option-allow-all-desc"
}
const _hoisted_13 = ["data-test"]
const _hoisted_14 = { class: "option__desc" }
const _hoisted_15 = ["data-test"]
const _hoisted_16 = {
  key: 0,
  class: "rules"
}
const _hoisted_17 = {
  class: "rule-item",
  "data-test": "option-deny-all-desc"
}
const _hoisted_18 = {
  key: 1,
  class: "rules"
}
const _hoisted_19 = {
  key: 0,
  class: "rule-item"
}
const _hoisted_20 = { class: "country" }
const _hoisted_21 = {
  key: 1,
  class: "rule-item"
}
const _hoisted_22 = { class: "country" }
const _hoisted_23 = {
  key: 2,
  class: "rules"
}
const _hoisted_24 = {
  class: "rule-item",
  "data-test": "option-allow-all-desc"
}

import _ from 'lodash';
import { useI18n } from 'vue-i18n';
import { computed, onMounted, toRefs } from 'vue';
import SelectOption from '@/modules/shared/components/atoms/selectOption/SelectOption.vue';
import {
  getCountriesName,
} from '../../../utils/geoblockRuleManagement';
import { useStore } from '../../../store/hooks';
import type {
  BaseGeoblockRule,
  GeoblockFilterProps,
  GeoblockRule,
} from '../../../types/Geoblock.type';

interface GeoblockRuleSelectorProps {
  modelValue: GeoblockRule | BaseGeoblockRule | undefined | null;
  label?: string;
  disabled?: boolean;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'GeoblockRuleSelector',
  props: {
    modelValue: {},
    label: { default: 'name' },
    disabled: { type: Boolean, default: false }
  },
  emits: ["update:modelValue"],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const {
  modelValue,
  label,
  disabled,
} = toRefs(props);

const { t } = useI18n();

const emits = __emit;

// store
const store = useStore();
const countryList = computed(() => store.state.geoblockRuleList.countryList);
const geoblockRuleList = computed(() => store.state.geoblockRuleList.geoblockRules);

// methods
const loadGeoblockRuleList = async (filter?: GeoblockFilterProps) => {
  await store.dispatch('loadGeoblockRules', filter);
};
const onSearchGeoblockRule = (filter: GeoblockFilterProps) => {
  loadGeoblockRuleList(filter);
};
const onGeoblockRuleChanged = (newGeoblockRule: GeoblockRule) => {
  emits('update:modelValue', newGeoblockRule);
};
const isAllowCountryCodesEmpty = (geoblockRule: GeoblockRule) => _.isEmpty(geoblockRule.allowCountryCodes);
const isDenyCountryCodesEmpty = (geoblockRule: GeoblockRule) => _.isEmpty(geoblockRule.denyCountryCodes);
const isAllowAllCountries = (geoblockRule: GeoblockRule) => {
  if (isAllowCountryCodesEmpty(geoblockRule) && isDenyCountryCodesEmpty(geoblockRule)) {
    return true;
  }
  return false;
};
const isDisallowAllCountries = (geoblockRule: GeoblockRule) => {
  if (geoblockRule.allowCountryCodes && geoblockRule.allowCountryCodes[0] === 'XX') {
    return true;
  }
  return false;
};
const getGeoblockRuleAllowCountry = (geoblockRule: GeoblockRule) => getCountriesName(geoblockRule.allowCountryCodes, countryList.value);
const getGeoblockRuleDenyCountry = (geoblockRule: GeoblockRule) => getCountriesName(geoblockRule.denyCountryCodes, countryList.value);

onMounted(() => {
  loadGeoblockRuleList();
  if (!countryList.value?.length) {
    store.dispatch('loadCountryList');
  }
});

return (_ctx: any,_cache: any) => {
  return (geoblockRuleList.value && geoblockRuleList.value.length)
    ? (_openBlock(), _createBlock(SelectOption, {
        key: 0,
        modelValue: _unref(modelValue),
        label: _unref(label),
        "onUpdate:modelValue": onGeoblockRuleChanged,
        options: geoblockRuleList.value,
        placeholder: _unref(t)('geoBlock.create.namePlaceHolder'),
        onSearchChange: onSearchGeoblockRule,
        disabled: _unref(disabled)
      }, {
        singleLabel: _withCtx((props) => [
          _createElementVNode("div", {
            class: "option",
            "data-test": `selected-option-${props.option.name}`
          }, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("span", {
                class: "option__title",
                "data-test": `selected-option-title-${props.option.name}`
              }, _toDisplayString(props.option.name), 9, _hoisted_3),
              (isDisallowAllCountries(props.option))
                ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                    _createElementVNode("div", _hoisted_5, _toDisplayString(_unref(t)('geoBlock.disallowAll.subTitle')), 1)
                  ]))
                : (!isAllowAllCountries(props.option))
                  ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                      (getGeoblockRuleAllowCountry(props.option))
                        ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                            _cache[0] || (_cache[0] = _createElementVNode("div", { class: "icon" }, [
                              _createElementVNode("i", { class: "fas fa-earth-asia" })
                            ], -1)),
                            _createElementVNode("div", _hoisted_8, _toDisplayString(getGeoblockRuleAllowCountry(props.option)), 1)
                          ]))
                        : _createCommentVNode("", true),
                      (getGeoblockRuleDenyCountry(props.option))
                        ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                            _cache[1] || (_cache[1] = _createElementVNode("div", { class: "icon" }, [
                              _createElementVNode("i", { class: "fas fa-ban" })
                            ], -1)),
                            _createElementVNode("div", _hoisted_10, _toDisplayString(getGeoblockRuleDenyCountry(props.option)), 1)
                          ]))
                        : _createCommentVNode("", true)
                    ]))
                  : (_openBlock(), _createElementBlock("div", _hoisted_11, [
                      _createElementVNode("div", _hoisted_12, _toDisplayString(_unref(t)('geoBlock.allowAll.subTitle')), 1)
                    ]))
            ])
          ], 8, _hoisted_1)
        ]),
        option: _withCtx((props) => [
          props
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: "option",
                "data-test": `option-${props.option.name}`
              }, [
                _createElementVNode("div", _hoisted_14, [
                  _createElementVNode("span", {
                    class: "option__title",
                    "data-test": `option-title-${props.option.name}`
                  }, _toDisplayString(props.option.name), 9, _hoisted_15),
                  (isDisallowAllCountries(props.option))
                    ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                        _createElementVNode("div", _hoisted_17, _toDisplayString(_unref(t)('geoBlock.disallowAll.subTitle')), 1)
                      ]))
                    : (!isAllowAllCountries(props.option))
                      ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
                          (getGeoblockRuleAllowCountry(props.option))
                            ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                                _cache[2] || (_cache[2] = _createElementVNode("div", { class: "icon" }, [
                                  _createElementVNode("i", { class: "fas fa-earth-asia" })
                                ], -1)),
                                _createElementVNode("div", _hoisted_20, _toDisplayString(getGeoblockRuleAllowCountry(props.option)), 1)
                              ]))
                            : _createCommentVNode("", true),
                          (getGeoblockRuleDenyCountry(props.option))
                            ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
                                _cache[3] || (_cache[3] = _createElementVNode("div", { class: "icon" }, [
                                  _createElementVNode("i", { class: "fas fa-ban" })
                                ], -1)),
                                _createElementVNode("div", _hoisted_22, _toDisplayString(getGeoblockRuleDenyCountry(props.option)), 1)
                              ]))
                            : _createCommentVNode("", true)
                        ]))
                      : (_openBlock(), _createElementBlock("div", _hoisted_23, [
                          _createElementVNode("div", _hoisted_24, _toDisplayString(_unref(t)('geoBlock.allowAll.subTitle')), 1)
                        ]))
                ])
              ], 8, _hoisted_13))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["modelValue", "label", "options", "placeholder", "disabled"]))
    : _createCommentVNode("", true)
}
}

})