<template>
  <Modal
    size="medium"
    :title="t('project.defaultSettings.geoblock.change')"
    :primaryButtonText="t('common:button.save')"
    :secondaryButtonText="t('common:button.cancel')"
    :isDisabledPrimaryButton="!isValidToSaving"
    :onClickPrimaryButton="onSubmitGeoblock"
    :onClickSecondaryButton="() => $emit('close')"
    @modalClose="$emit('close')">
    <template v-slot:body>
      <Input
        type="text"
        @keydown.enter="onSearch"
        class="form-control search-input"
        :placeholder="t('project.manage.setting.geoblock.search')"
        v-model="searchKeyword"
        :onSearch="onSearch"
        isSearchInput />
      <div class="loading-container" v-if="loadGeoblockRulesState.status === 'loading'">
        <i class="fas fa-circle-notch fa-spin fa-4x" />
      </div>
      <MessageBox
        :title="t('geoBlock.notFound')"
        v-if="searchKeyword && !geoblockRules?.length && loadGeoblockRulesState.status ==='success'" />
      <div
        class="options-container"
        v-if="loadGeoblockRulesState.status === 'success' && geoblockRules?.length">
        <div class="form-radio" v-for="(geoblock, index) in geoblockRules" :key="index">
          <RadioButtonChoice
            :modelValue="isCurrentGeoblockRule(geoblock)"
            :data="geoblock.id"
            @update:data="onSelectedGeoblock">
            <div class="geoblock-name">
              {{geoblock.name}}
              <Badge
                v-if="isAllowAllCountries(geoblock) && geoblock.isGlobalDefault"
                :title="t('glossary:default')"
                size="small"
              />
            </div>
            <div class="geoblock-country-wrapper" v-if="!isAllowAllCountries(geoblock)">
              <div class="allow-country-container country-list" v-if="getAllowCountryCodes(geoblock).length">
                <!-- We will remove all isDisallowAllCountries method after we refactor allowCountryCodes conditions -->
                <div class="label" v-if="!isDisallowAllCountries(geoblock.allowCountryCodes)">Allowed ({{getAllowCountryCodes(geoblock).length}})</div>
                <div class="label" v-else>All countries are blocked</div>
                <template v-if="!isDisallowAllCountries(geoblock.allowCountryCodes)">
                  <div class="badge-container">
                    <template
                      v-for="(country, index) in getAllowCountryCodes(geoblock)"
                      :key="index"
                    >
                      <Badge :title="country"/>
                    </template>
                  </div>
                </template>
              </div>
              <div class="deny-country-container country-list" v-if="getDenyCountryCodes(geoblock).length">
                <div class="label">Blocked ({{getDenyCountryCodes(geoblock).length}})</div>
                <div class="badge-container">
                  <template
                    v-for="(country, index) in getDenyCountryCodes(geoblock)"
                    :key="index">
                    <Badge :title="country"/>
                  </template>
                </div>
              </div>
            </div>
            <div class="geoblock-country-wrapper" v-else>
              <div class="label">All countries are allowed</div>
            </div>
          </RadioButtonChoice>
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <div class="message-container">
        <span v-if="saveProjectState.status === 'saving'"><i class="fas fa-spinner fa-spin" />{{t('common:button.saving')}}</span>
        <span class="text-danger" v-if="saveProjectState.status === 'error' && saveErrorCode === 404"><i class="fas fa-xmark" />{{t('response:error404.title', { item: 'project'})}}</span>
        <span class="text-danger" v-if="saveProjectState.status === 'error' && saveErrorCode === 422"><i class="fas fa-xmark" />{{t('response:error422.title')}}</span>
        <span class="text-danger" v-if="saveProjectState.status === 'error' && saveErrorCode === 500"><i class="fas fa-xmark" />{{t('response:error500.title')}}</span>
      </div>
    </template>
  </Modal>
</template>

<script lang="ts" setup>
/* eslint-disable no-shadow */
import _ from 'lodash';
import {
  computed,
  onMounted,
  ref,
  toRefs,
} from 'vue';
import { useStore } from '@/store';
import Modal from '@/modules/shared/components/organisms/modal/Modal.vue';
import RadioButtonChoice from '@/modules/shared/components/atoms/radioButtonChoice/RadioButtonChoice.vue';
import Input from '@/modules/shared/components/atoms/input/Input.vue';
import Badge from '@/modules/shared/components/atoms/badge/Badge.vue';
import MessageBox from '@/modules/shared/components/molecules/messageBox/MessageBox.vue';
import { GeoblockRule } from '@/modules/geoblock';
import { useI18n } from 'vue-i18n';

interface ChangeGeoblockModalProps {
  geoblockRule: GeoblockRule | null | undefined;
}

const props = defineProps<ChangeGeoblockModalProps>();
const { geoblockRule } = toRefs(props);

const emit = defineEmits<{
  close: [];
  submit: [value: GeoblockRule | null | undefined];
}>();

const { t } = useI18n();
const store = useStore();

// data
const searchKeyword = ref<string>('');
const selectedGeoblockRule = ref<GeoblockRule | null | undefined>(_.cloneDeep(geoblockRule.value));

// store
const geoblockRules = computed(() => store.state.geoblockRuleList.geoblockRules);
const loadGeoblockRulesState = computed(() => store.state.geoblockRuleList.loadGeoblockRulesState);
const saveProjectState = computed(() => store.state.project.saveProjectState);
const saveErrorCode = computed(() => _.get(saveProjectState.value, 'error.statusCode', 0));
const isValidToSaving = computed(() => !(saveErrorCode.value || saveProjectState.value?.status === 'saving') && geoblockRules.value?.length);

// methods
const onSearch = () => {
  store.dispatch('loadGeoblockRules', { limit: 100, q: searchKeyword.value });
};
const isCurrentGeoblockRule = (geoblockRule: GeoblockRule): boolean => {
  const curentGeoblockId = _.get(selectedGeoblockRule.value, 'id', null);
  return geoblockRule.id === curentGeoblockId;
};
const getAllowCountryCodes = (geoblockRule: GeoblockRule) => _.get(geoblockRule, 'allowCountryCodes', []);
const getDenyCountryCodes = (geoblockRule: GeoblockRule) => _.get(geoblockRule, 'denyCountryCodes', []);
const isAllowAllCountries = (geoblockRule: GeoblockRule) => !getAllowCountryCodes(geoblockRule).length && !getDenyCountryCodes(geoblockRule).length;
const onSelectedGeoblock = (geoblockRuleId: string) => {
  selectedGeoblockRule.value = _.find(geoblockRules.value, { id: geoblockRuleId });
};
const onSubmitGeoblock = () => {
  emit('submit', selectedGeoblockRule.value);
};
// This method will be remove after we refactor allowCountryCodes conditions
const isDisallowAllCountries = (countryCodes: string[]) => {
  if (countryCodes && countryCodes[0] === 'XX') {
    return true;
  }
  return false;
};

onMounted(() => {
  store.dispatch('loadGeoblockRules', { limit: '100' });
});
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/global-variables';

.modal-body {
  padding-top: 0;
}

.input-container {
  position: relative;
  margin-bottom: 16px;

  .search-input {
    border-color: transparent;
    background-color: $grey-100;
  }

  .search-icon {
    cursor: pointer;
    color: $grey-400;
    position: absolute;
    top: 0;
    bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
    right: $spacing-base;
    display: flex;
    align-items: center;
  }
}

.loading-container, .empty-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.form-radio {
  margin-bottom: $spacing-base;
  .geoblock-name {
    color: $grey-700;
    font-weight: $font-weight-bold;
    display: flex;
    gap: $spacing-8;
  }
  .geoblock-country-wrapper {
    margin-top: $spacing-base * 0.5;
    .country-list {
      margin-top: $spacing-base;
      &:first-child {
        margin-top: 0;
      }
      .label {
        margin-bottom: $spacing-8;
      }
      .badge-container {
        display: flex;
        flex-wrap: wrap;
        gap: $spacing-8;
      }
    }
  }
}

.message-container {
  /* margin-left: auto; */
  /* margin-right: 0.5 * $spacing-base; */
  .fas {
    margin-right: $spacing-4;
  }
}

.save-button {
  width: 100px;
  margin-left: 0 !important;
}

</style>
