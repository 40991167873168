<template>
  <div class="form-group" :class="{'no-margin-bottom': noMarginBottom, 'disabled': disabledInput }">
    <div class="input-group input-group-sm">
      <Input
        :id="elementId"
        :modelValue="modelValue"
        :prefixText="prefixText"
        :inputType="inputType"
        :maxWidth="maxWidth"
        class="form-control"
        readonly
      />
      <div class="input-group-prepend input-copy">
        <slot name="input-secondary"></slot>
      </div>
      <div
        v-if="!disabledInput"
        class="input-group-prepend input-copy"
        :class="{'input-copied': isCopied}"
        @click="copyMessage(elementId)">
        <span class="input-group-text">
          <i class="far fa-copy icon" v-if="!isCopied"></i>
          <i class="fa fa-check icon" v-else></i>
          {{ isCopied ? $t('glossary:copied') : $t('glossary:copy') }}
        </span>
      </div>
      <div
        v-if="secondaryAction"
        class="input-group-prepend input-copy">
        <Button
          type="button"
          buttonStyle="warning"
          buttonClasses="secondary-btn-warning"
          @click.stop="$emit('onSecondaryActionButtonClicked')">
          <template v-slot:icon-prefix>
            <i :class="secondaryAction.iconClass"></i>
          </template>
          <template v-slot:content>
            {{ secondaryAction.text }}
          </template>
        </Button>
      </div>
    </div>
  </div>
</template>

<script>
import Button from '@/modules/shared/components/atoms/button/Button.vue';
import Input from '@/modules/shared/components/atoms/input/Input.vue';

export default {
  components: {
    Button,
    Input,
  },
  props: {
    noMarginBottom: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      type: String,
      default: '',
    },
    elementId: {
      type: String,
      required: true,
    },
    secondaryAction: {
      type: Object,
      default: null,
    },
    disabledInput: {
      type: Boolean,
      default: false,
    },
    prefixText: {
      type: String,
    },
    inputType: {
      type: String,
      default: 'text',
    },
    maxWidth: {
      type: String,
      default: undefined,
    },
  },
  emits: ['onSecondaryActionButtonClicked'],
  data() {
    return {
      isCopied: false,
    };
  },
  methods: {
    copyMessage(copyMessageKey) {
      this.isCopied = true;
      let timer;

      const copyText = document.getElementById(copyMessageKey);

      if (!copyText) {
        return;
      }

      copyText.focus();
      copyText.select();

      try {
        navigator.clipboard.writeText(copyText.value);
        timer = setTimeout(() => {
          this.isCopied = false;
          clearTimeout(timer);
        }, 1000);
      } catch (err) {
        timer = setTimeout(() => {
          this.isCopied = false;
          clearTimeout(timer);
        }, 1000);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/global-variables.scss';

.input-group {
  display: flex;
  flex: 1;

  :deep(.input-wrapper){
    flex: 1;

    .input-container {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;

      .input {
        background: $grey-100;
        color: $grey-600;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        /* margin-bottom: 0; */
      }
    }
  }

  .input-copy {
    cursor: pointer;
    display: flex;
    z-index: 2;
    .icon {
      margin-right: 4px;
    }

    .input-group-text {
      padding: 6px $spacing-12;
      text-transform: capitalize;
      background-color: $grey-200;
      color: $grey-700;
      white-space: nowrap;
      display: flex;
      align-items: center;
    }
  }
}

:deep(.secondary-btn-warning){
  border-radius: 0;
}

.disabled {
  pointer-events: none;
}

.no-margin-bottom {
  margin-bottom: 0;
}

.help-text {
  margin-top: $spacing-base * 0.5;
  display: flex;
  justify-content: flex-start;
  margin-left: auto;
  p {
    margin-bottom: 0;
  }
}

</style>
