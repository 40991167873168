import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "warning-text" }

import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import Modal from '@/modules/shared/components/organisms/modal/Modal.vue';
import DataTable from '@/modules/shared/components/organisms/dataTable/DataTable.vue';
import Row from '@/modules/shared/components/molecules/row/Row.vue';
import Column from '@/modules/shared/components/molecules/column/Column.vue';
import HeaderCell from '@/modules/shared/components/molecules/headerCell/HeaderCell.vue';
import TextCell from '@/modules/shared/components/molecules/textCell/TextCell.vue';
import { transformSecondsToDuration } from '@/modules/shared/utils/dateFormatter';


export default /*@__PURE__*/_defineComponent({
  __name: 'BadWordListModal',
  props: {
  caption: {
    type: Object,
    default: () => {},
  },
  onClose: {
    type: Function,
    default: () => {},
  },
},
  setup(__props) {

const { t } = useI18n();

const props = __props;

const badWordList = computed(() => props.caption.captionProofreading?.flaggedData);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(Modal, {
    title: `${_unref(t)('video.subtitle.modal.badWord.title')} ‘${__props.caption?.customName}’`,
    isShowPrimaryButton: false,
    secondaryButtonText: _ctx.$t('common:close'),
    onModalClose: (__props.onClose as () => void)
  }, {
    body: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createTextVNode(_toDisplayString(_ctx.$t('video.subtitle.modal.badWord.description.found')) + " ", 1),
        _createElementVNode("strong", null, _toDisplayString(badWordList.value.length), 1),
        _createTextVNode(" " + _toDisplayString(_ctx.$t('video.subtitle.modal.badWord.description.sentence')), 1)
      ]),
      _createVNode(DataTable, null, {
        "table-header": _withCtx(() => [
          _createVNode(Row, null, {
            default: _withCtx(() => [
              _createVNode(Column, { width: "30%" }, {
                default: _withCtx(() => [
                  _createVNode(HeaderCell, {
                    text: _ctx.$t('video.subtitle.modal.badWord.table.time')
                  }, null, 8, ["text"])
                ]),
                _: 1
              }),
              _createVNode(Column, null, {
                default: _withCtx(() => [
                  _createVNode(HeaderCell, {
                    text: _ctx.$t('video.subtitle.modal.badWord.table.subtitleText')
                  }, null, 8, ["text"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        "table-content": _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(badWordList.value, (badWord, index) => {
            return (_openBlock(), _createBlock(Row, { key: index }, {
              default: _withCtx(() => [
                _createVNode(Column, { width: "30%" }, {
                  default: _withCtx(() => [
                    _createVNode(TextCell, {
                      text: `${_unref(transformSecondsToDuration)(
                  badWord.startTime
                )} - ${_unref(transformSecondsToDuration)(badWord.endTime)}`
                    }, null, 8, ["text"])
                  ]),
                  _: 2
                }, 1024),
                _createVNode(Column, null, {
                  default: _withCtx(() => [
                    _createVNode(TextCell, {
                      text: badWord.originalText,
                      class: "bad-word"
                    }, null, 8, ["text"])
                  ]),
                  _: 2
                }, 1024)
              ]),
              _: 2
            }, 1024))
          }), 128))
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["title", "secondaryButtonText", "onModalClose"]))
}
}

})