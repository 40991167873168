<template>
  <div>
    <label class="created-by">{{ $t('common:form.createdBy') }}</label>
    <div class="project-creator">
      <div class="logo-container">
        <Logo
          :user="creator" />
      </div>
      <div class="creator">
        <div class="username">
          {{ creator.name }}
        </div>
        <div class="created-at">
          {{$t('common:form.on')}} {{formatVideoDateTime(createdAt)}}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { formatVideoDateTime } from '@/modules/shared/utils/dateFormatter';
import Logo from '@/modules/shared/components/atoms/logo/Logo.vue';

export default {
  props: {
    creator: {
      type: Object,
      required: true,
    },
    createdAt: {
      type: String,
      required: true,
    },
  },
  components: {
    Logo,
  },
  methods: {
    formatVideoDateTime,
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/global-variables';

.project-creator {
  font-size: $font-level-4 * 0.75;
  margin-top: $spacing-base * 0.5;
  display: flex;
  align-items: center;

  .logo-container {
    margin-right: $spacing-base * 0.75;
  }

  .creator {
    display: flex;
    flex-direction: column;
    justify-content: center;
    line-height: 1.3;
  }

  .created-at {
    font-size: $font-size-base;
  }
}
</style>
