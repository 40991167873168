<template>
  <div class="slide-out-container fade-in" id="slide-out" :data-test="dataTest">
    <form @submit.prevent="onClickPrimaryButton" class="slide-out-content" :style="slideOutWidth">
      <div class="slide-out-header">
        <div class="header-content">
          <Button
            type="button"
            @click.stop="onCloseSlideOut"
            buttonStyle="text-secondary"
            buttonClasses="btn-close-container">
            <template v-slot:icon-prefix>
              <i class="fas fa-xmark" />
            </template>
          </Button>
          <div class="text-container" :class="{'no-title-text': !title}">
            <div class="title-container">
              <div class="title" v-if="title && !isShowInLineEdit ">
                <i v-if="titleIconClassName" :class="titleIconClassName" />
                {{ title }}
              </div>
              <div v-if="hasInLineEdit" class="btn-edit-container">
                <Button
                  v-if="!isShowInLineEdit"
                  type="button"
                  @click.stop="isShowInLineEdit = true"
                  buttonStyle="link-secondary"
                  buttonClasses="btn-edit-title">
                  <template v-slot:icon-prefix>
                    <i class="fas fa-pen edit-icon"/>
                  </template>
                  <template v-slot:content>
                    Rename
                  </template>
                </Button>
                <InLineEdit
                  v-if="isShowInLineEdit"
                  :value="title"
                  :onSubmit="onEditName"
                  :onClose="onCancelEditName" />
              </div>
              <div v-if="!title">
                Close
              </div>
            </div>
            <div class="description" v-if="description">
              {{ description }}
            </div>
          </div>
          <div
            class="header-action"
            v-if="headerActionText && onClickHeaderAction && !isShowInLineEdit">
            <Button
              type="button"
              @click.stop="onClickHeaderAction"
              buttonStyle="link-secondary"
              buttonClasses="btn-close-container">
              <template v-slot:content>
                {{ headerActionText }}
              </template>
              <template v-slot:icon-suffix>
                <i class="fas fa-chevron-right" />
              </template>
            </Button>
          </div>
        </div>
      </div>
      <div class="slide-out-body" id="content-body">
        <slot></slot>
      </div>
      <div class="slide-out-footer" v-if="isShowModalFooter">
        <slot name="footer"></slot>
        <div class="slide-out-action">
          <Button
            v-if="isShowSecondaryButton"
            class="secondary-btn"
            type="button"
            @click.stop="secondaryButtonAction"
            :disabled="isDisabledSecondaryButton"
            :buttonStyle="secondaryButtonStyle">
            <template v-slot:content>
              {{secondaryButtonText || $t('common:button.cancel')}}
            </template>
            <template v-slot:icon-prefix v-if="secondaryButtonIconClassName">
              <i :class="secondaryButtonIconClassName"/>
            </template>
          </Button>
          <Button
            class="primary-btn"
            type="submit"
            :disabled="isDisabledPrimaryButton"
            :buttonStyle="primaryButtonStyle"
            :isSaving="isSaving">
            <template v-slot:content>
              {{primaryButtonText || $t('common:button.save')}}
            </template>
            <template v-slot:icon-prefix v-if="primaryButtonIconClassName">
              <i :class="primaryButtonIconClassName"/>
            </template>
          </Button>
        </div>
      </div>
    </form>
  </div>
</template>

<script setup>
import Button from '@/modules/shared/components/atoms/button/Button.vue';
import InLineEdit from '@/modules/shared/components/molecules/inlineEdit/InlineEdit.vue';
import {
  ref,
  computed,
  onMounted,
  onUnmounted,
} from 'vue';

// Props
// eslint-disable-next-line no-undef
const props = defineProps({
  width: {
    type: String,
    default: '450',
  },
  title: {
    type: String,
    default: '',
  },
  titleIconClassName: {
    type: String,
    default: '',
  },
  description: {
    type: String,
    default: '',
  },
  hasInLineEdit: {
    type: Boolean,
    default: false,
  },
  headerActionText: {
    type: String,
    default: '',
  },
  onClickHeaderAction: {
    type: Function,
  },
  isShowModalFooter: {
    type: Boolean,
    default: true,
  },
  primaryButtonText: {
    type: String,
  },
  primaryButtonIconClassName: {
    type: String,
  },
  primaryButtonStyle: {
    type: String,
    default: 'primary',
  },
  isDisabledPrimaryButton: {
    type: Boolean,
    default: false,
  },
  onClickPrimaryButton: {
    type: Function,
  },
  secondaryButtonText: {
    type: String,
  },
  secondaryButtonIconClassName: {
    type: String,
  },
  secondaryButtonStyle: {
    type: String,
    default: 'text-secondary',
  },
  isDisabledSecondaryButton: {
    type: Boolean,
    default: false,
  },
  onClickSecondaryButton: {
    type: Function,
  },
  isShowSecondaryButton: {
    type: Boolean,
    default: true,
  },
  isSaving: {
    type: Boolean,
    default: false,
  },
  dataTest: {
    type: String,
    required: false,
  },
});

// eslint-disable-next-line no-undef
const emits = defineEmits(['slideOutClose', 'update:title']);

// Data
const isShowInLineEdit = ref(false);

// Computed
const slideOutWidth = computed(() => {
  const styles = {};
  if (props.width) {
    styles.width = `${props.width}px`;
  }
  return styles;
});

// Methods
const onEditName = (name) => {
  emits('update:title', name);
};

const onCancelEditName = () => {
  isShowInLineEdit.value = false;
};

const onCloseSlideOut = () => {
  const slideOutElement = document.getElementById('slide-out');

  if (slideOutElement) {
    slideOutElement.classList.add('fade-out');
    setTimeout(() => {
      emits('slideOutClose');
    }, 200);
  } else {
    emits('slideOutClose');
  }
};

const secondaryButtonAction = () => {
  if (props.onClickSecondaryButton) {
    props.onClickSecondaryButton();
  } else {
    emits('slideOutClose');
  }
};

// mounted
onMounted(() => {
  // document.body.classList.add('sidebar-open');
  window.addEventListener('keydown', (e) => {
    if (e.code === 'Escape') {
      onCloseSlideOut();
    }
  });
});
onUnmounted(() => {
  // document.body.classList.remove('sidebar-open');
  window.removeEventListener('keydown', onCloseSlideOut, true);
});
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/global-variables';
@import '~@/assets/scss/themes/helpers';
@import '~@/assets/scss/breakpoint.scss';

.fade-in {
  animation: fade-in 0.3s ease;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fade-out {
  animation: fade-out 0.3s ease;
}

@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.slide-out-container {
  position: fixed;
  z-index: 20;
  top: 0;
  right: 0;
  bottom: 0;
  /* margin: auto; */
  /* overflow: visible; */

  /* display: flex; */
  /* align-items: center; */
  /* justify-content: center; */
}

.slide-out-content {
  background-color: $bg-light;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.16);
  height: 100vh;
  z-index: 20;
  display: flex;
  flex-direction: column;
  position: relative;

  @media screen and (max-width: $max-layout-sm) {
    width: 100vw !important;
  }
}

.slide-out-header {
  padding: $spacing-base;
  display: flex;
  flex-direction: column;
}
.header-content {
  display: flex;
  align-items: flex-start;
}
.text-container {
  display: flex;
  flex-direction: column;
  margin-left: $spacing-12;
  flex: 1 0 auto;
}
.title-container {
  display: flex;
}
.title {
  color: $grey-800;
  font-size: $font-level-4;
  font-weight: $font-weight-x-bold;
  margin-right: $spacing-8;
  padding-top: $spacing-4;
  line-height: $line-height-base;

  i {
    margin-right: $spacing-4;
  }
}
.no-title-text {
  align-self: center;
}
.btn-edit-container {
  align-self: center;
  padding-top: $spacing-8;
}
:deep(.btn-edit-title) {
  color: $grey-600;
  font-size: $font-level-8;
  .icon-prefix {
    margin-right: $spacing-4;
  }
}
.edit-icon {
  /* margin-right: $spacing-4; */
  font-size: $icon-size-12;
  color: $grey-500;
}
.description {
  margin-top: $spacing-8;
  color: $grey-600;
  font-size: $font-level-7;
  font-weight: $font-weight-base;
  line-height: $line-height-description;
}
.header-action {
  height: 36px;
  display: flex;
  align-items: center;
  column-gap: $spacing-8;
  font-size: $font-level-7;
  margin-left: $spacing-12;
  .fas {
    font-size: $icon-size-10;
  }
}

form {
  display: flex;
  flex-direction: column;
  align-items: space-between;
  /* height: 100%; */
}
.slide-out-body {
  @extend .scrollbar;
  padding: $spacing-base $spacing-24 $spacing-24 $spacing-24;
  flex: 1 1 auto;
  overflow: auto;
  > :slotted(p), > :slotted(div) {
    margin-bottom: $spacing-32;
  }
  :slotted(div.tab-container) {
    margin-bottom: $spacing-24;
  }

  > :slotted(:last-child) {
    /* margin: 0 auto; */
    margin-bottom: 0;
  }
}

.slide-out-footer {
  border-top: 1px solid $grey-200;
  padding: $spacing-base $spacing-24;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .slide-out-action {
    margin-left: auto;
    display: flex;
    column-gap: $spacing-base;
    :deep(button) {
      white-space: nowrap;
    }
  }
}
.slide-out-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1;
}
@media screen and (max-width: $max-layout-md) {
  .slide-out-content {
    width: 98vw;
  }
}
</style>
