import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "content-container"
}

import {
  computed, onBeforeUnmount, ref, watch,
} from 'vue';
import {
  onBeforeRouteLeave, onBeforeRouteUpdate, useRoute, useRouter,
} from 'vue-router';
import { useI18n } from 'vue-i18n';
import { getQueryAsString } from '@/modules/shared/utils/query';
import ErrorMessage from '@/modules/shared/components/atoms/errorMessage/ErrorMessage.vue';
import Page from '@/layouts/Page.vue';
import PreLoaderSection from '@/modules/shared/components/atoms/preLoaderSection/PreLoaderSection.vue';
import Section from '@/modules/shared/components/organisms/section/Section.vue';
import RestoreAllDeletedVideos
  from '@/modules/projectVideoList/components/molecules/restoreAllDeletedVideosButton/RestoreAllDeletedVideosButton.vue';
import RestoreAllDeletedVideosModal
  from '@/modules/projectVideoList/components/organisms/restoreAllDeletedVideosModal/RestoreAllDeletedVideosModal.vue';
import { useStore } from '../store/hooks';


export default /*@__PURE__*/_defineComponent({
  __name: 'ProjectVideoListChrome',
  setup(__props) {

const { t } = useI18n();
const store = useStore();
const route = useRoute();
const router = useRouter();

const isOpenRestoreAllDeletedVideosModal = ref<boolean>(false);

const currentProject = computed(() => store.state.project.currentProject);
const videoCount = computed(() => store.state.project.currentProject?.videoCount);
const projectVideoTotal = computed(() => store.state.projectVideoList.projectVideoTotal);
const collectionList = computed(() => store.state.collectionList?.collectionList);
const loadProjectState = computed(() => store.state.project.loadProjectState);
const projectListTotal = computed(() => store.state.projectList.projectListTotal);
const isPageLoading = computed(() => store.state.global.isPageLoading);

const isAllProjectsPage = route.path.includes('all-projects');

const projectKey = computed(() => getQueryAsString(route.params, 'projectKey'));
const teamName = computed(() => route.params.teamName);

const pageTitle = computed(() => {
  const pathName = route.name;

  if (pathName === 'allProjectVideoIssues' || pathName === 'projectVideoIssues') {
    return t('glossary:issue_plural');
  }

  if (pathName === 'allRecentlyDeleted' || pathName === 'recentlyDeleted') {
    return t('project.tabs.recentlyDelete.title');
  }

  return t('project.page.videoList.title');
});

const pageBadgeList = computed(() => {
  if (currentProject.value) {
    const basePath = `/${teamName.value}/projects/${projectKey.value}`;

    return [
      {
        iconClassName: 'fas fa-users',
        title: currentProject.value.memberships.length ?
          `${currentProject.value.memberships.length}
              ${currentProject.value.memberships.length > 1 ? t('glossary:member_plural') : t('glossary:member')}`
          : t('common:noItem', { item: t('glossary:member_plural') }),
        path: `${basePath}/manage/members`,
      },
      {
        iconClassName: 'fas fa-play-circle',
        title: videoCount.value ?
          `${videoCount.value.toLocaleString()}
               ${videoCount.value > 1 ? t('glossary:video_plural') : t('glossary:video')}`
          : t('common:noItem', { item: t('glossary:video_plural') }),
        path: `${basePath}`,
      },
      {
        iconClassName: 'fas fa-rectangle-history',
        title: collectionList.value?.length ?
          `${collectionList.value.length}
              ${collectionList.value.length > 1 ? 'Collections' : 'Collection'}`
          : t('common:noItem', { item: ' collection' }),
        path: `${basePath}/collections`,
      },
    ];
  }

  const basePath = `/${teamName.value}/all-projects`;

  return [
    {
      iconClassName: 'fas fa-play-circle',
      title: projectVideoTotal.value ?
        `${projectVideoTotal.value.toLocaleString()}
               ${projectVideoTotal.value > 1 ? t('glossary:video_plural') : t('glossary:video')}`
        : t('common:noItem', { item: t('glossary:video_plural') }),
      path: basePath,
    },
  ];
});

const isShowPageActionOn = (pageName: string[]) => {
  const pathName = route.name;
  return pageName.includes(pathName?.toString?.() ?? 'there is no route name');
};

async function loadProjectList() {
  await store.dispatch('loadProjectList');
  if (projectListTotal.value === 0) {
    await router.push(`/${teamName.value}/projects`);
  }
}

async function loadProject(key: string | null) {
  if (!key) {
    return;
  }

  store.commit('SET_PAGE_LOADING', true);
  await store.dispatch('loadProjectByKey', key);
  await store.dispatch('loadCollectionList', { projectKey: key });
  store.commit('SET_PAGE_LOADING', false);
}

async function loadProjectVideoList() {
  await store.dispatch('loadProjectVideoList', { filter: {} });
}

onBeforeRouteUpdate((to, from, next) => {
  const prjKey = getQueryAsString(to.params, 'projectKey');
  if (prjKey) {
    localStorage.setItem('projectKey', prjKey);
  }
  next();
});

onBeforeRouteLeave((to, from, next) => {
  const toPath = to.path;
  if (projectKey.value && toPath.includes(projectKey.value)) {
    // Navigate inside the project
    store.dispatch('setPageScrollPosition', window.scrollY);
    next();
  } else {
    // Leave the project page
    store.dispatch('destroyProjectVideoList');
    store.dispatch('destroyCurrentProject');
    store.dispatch('destroyVideoFiles');
    next();
  }
});

watch(projectKey, loadProject, { immediate: true });

onBeforeUnmount(() => {
  if (!projectListTotal.value) {
    loadProjectList();
  }
  if (projectListTotal.value === 0) {
    router.push(`/${teamName.value}/projects`);
  }

  if (!currentProject.value && projectKey.value) {
    loadProject(projectKey.value);
  } else if (!projectKey.value) {
    // load project video list for video count in case of all projects route
    loadProjectVideoList();
  }
});

return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(Page, {
      title: pageTitle.value,
      projectName: currentProject.value?.name,
      badgeList: pageBadgeList.value
    }, {
      "page-action": _withCtx(() => [
        (isShowPageActionOn(['recentlyDeleted']))
          ? (_openBlock(), _createBlock(RestoreAllDeletedVideos, {
              key: 0,
              onClickRestoreAllVideos: () => { isOpenRestoreAllDeletedVideosModal.value = true; }
            }, null, 8, ["onClickRestoreAllVideos"]))
          : _createCommentVNode("", true)
      ]),
      default: _withCtx(() => [
        (loadProjectState.value.error)
          ? (_openBlock(), _createBlock(Section, { key: 0 }, {
              default: _withCtx(() => [
                _createVNode(ErrorMessage, {
                  statusCode: loadProjectState.value.error?.statusCode,
                  title: loadProjectState.value.error?.message,
                  buttonPrefixIconClassName: "fas fa-chevron-left",
                  buttonUrl: `/${teamName.value}`,
                  buttonTitle: _unref(t)('response:backToHomepage')
                }, null, 8, ["statusCode", "title", "buttonUrl", "buttonTitle"])
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        _createVNode(_component_router_view, { name: "dialog" }),
        (!loadProjectState.value.error)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              (isPageLoading.value || (!_unref(isAllProjectsPage) && !currentProject.value))
                ? (_openBlock(), _createBlock(PreLoaderSection, { key: 0 }))
                : (_openBlock(), _createBlock(_component_router_view, { key: 1 }))
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["title", "projectName", "badgeList"]),
    (isOpenRestoreAllDeletedVideosModal.value)
      ? (_openBlock(), _createBlock(RestoreAllDeletedVideosModal, {
          key: 0,
          onClose: () => { isOpenRestoreAllDeletedVideosModal.value = false; }
        }, null, 8, ["onClose"]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})