<script setup lang="ts">
import { toRefs } from 'vue';
import Button from '@/modules/shared/components/atoms/button/Button.vue';
import { useI18n } from 'vue-i18n';

interface VideoUploadDefaultBoxProps {
  label?: string;
  isEditing?: boolean;
}

const props = defineProps<VideoUploadDefaultBoxProps>();
const {
  label,
  isEditing,
} = toRefs(props);

const { t } = useI18n();
</script>

<template>
  <div class="section">
    <div class="section-header" v-if="label">
      <div class="section-label" v-if="label">
        <span>{{ label }}</span>
        <span class="suffix" v-if="$slots['suffix-label']">
          <slot name="suffix-label"></slot>
        </span>
      </div>
      <div class="action-container">
        <slot name="action" />
        <Button
          type="button"
          buttonStyle="link-primary"
          buttonClasses="action"
          @click="$emit('onClickEdit')"
          v-if="!$slots['action']"
          dataTest="edit-upload-output-button"
        >
          <template v-slot:content>
            <span v-if="!isEditing">{{ t('common:multiselect.select') }}</span>
            <span v-if="isEditing">{{ t('common:button.hide') }}</span>
          </template>
        </Button>
      </div>
    </div>
    <div class="content" v-if="$slots['default']">
      <slot/>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '~@/assets/scss/global-variables.scss';

.section {
  background-color: $white;
  border-radius: $border-radius-5;
  margin-bottom: $spacing-24;
}
.section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: $spacing-base $spacing-24 $spacing-base $spacing-24;
  border-bottom: 1px solid $grey-200;
}

.section-label {
  font-size: $font-level-7;
  display: flex;
  align-items: center;
  text-transform: capitalize;
}

.suffix {
  margin-left: $spacing-8;
}

.content {
  padding: $spacing-24;
}
</style>
