<template>
  <div class="section-collapse">
    <div @click.stop="onToggleContentShowing" class="section-collapse-header">
      <div class="text">
        <h4 class="title" v-if="title" v-html="title" />
        <div class="description" v-html="description" />
      </div>
      <div class="action">
        <Button @click.stop="onToggleContentShowing" buttonStyle="text-secondary">
          <template v-slot:icon-prefix>
            <i v-if="isSectionShowing" class="fas fa-chevron-up"></i>
            <i v-if="!isSectionShowing" class="fas fa-chevron-down"></i>
          </template>
        </Button>
      </div>
    </div>
    <transition name="fade">
      <div v-if="isSectionShowing">
        <div class="flex-container" :class="{ 'no-padding': noPadding }">
          <div v-if="$slots['left-pane']" class="left-pane">
            <slot name="left-pane"></slot>
          </div>
          <div v-if="$slots['right-pane']" class="right-pane">
            <slot name="right-pane"></slot>
          </div>
          <div v-if="!$slots['left-pane'] && !$slots['right-pane']" class="full-pane">
            <slot></slot>
          </div>
        </div>
        <div v-if="withActions" class="action-container">
          <Button buttonStyle="secondary" @click="onCancel" type="button" buttonClasses="btn-cancel">
            <template v-slot:content>
              {{ $t('common:button.cancel') }}
            </template>
          </Button>
          <Button @click="onSubmit" type="button" buttonStyle="primary" buttonClasses="btn-select">
            <template v-slot:content>
              {{ $t('common:multiselect.select') }}
            </template>
          </Button>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import Button from '@/modules/shared/components/atoms/button/Button.vue';

export default {
  components: {
    Button,
  },
  props: {
    title: {
      type: String,
    },
    description: {
      type: String,
      required: false,
      default: () => '',
    },
    noPadding: {
      type: Boolean,
      default: false,
    },
    // Pass this prop if you want to use your own showing state.
    // Otherwise, this collapse section uses its own isContentShowing state.
    isShowing: {
      type: Boolean,
      required: false,
      default: undefined,
    },
    withActions: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ['toggle', 'submit', 'cancel'],
  data() {
    return {
      isContentShowing: true,
    };
  },
  computed: {
    isSectionShowing() {
      if (this.isShowing !== undefined) {
        return this.isShowing;
      }
      return this.isContentShowing;
    },
  },
  created() { },
  methods: {
    onToggleContentShowing() {
      if (this.isShowing !== undefined) {
        this.$emit('toggle', !this.isShowing);
      } else {
        this.isContentShowing = !this.isContentShowing;
      }
    },
    onSubmit() {
      this.$emit('submit');
    },
    onCancel() {
      this.$emit('cancel');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/global-variables.scss';
@import '~@/assets/scss/breakpoint.scss';

.section-collapse {
  position: relative;
  padding-top: $spacing-base * 0.8;
  padding-bottom: $spacing-base * 0.25;
  padding-left: $spacing-base * 1.25;
  padding-right: $spacing-base * 1.25;
  border-bottom: 1px solid $grey-200;
  border-radius: 5px;
  height: 100%;
  background-color: #FFF;
  margin-bottom: $spacing-base * 2;

  &:last-child {
    border-bottom: 0;
  }

  .section-collapse-header {
    display: flex;
    margin-bottom: $spacing-base * 0.5;
    cursor: pointer;

    .text {
      flex: 0 0 100%;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;

      .title {
        flex: 0 0 60%;
        font-size: $font-size-base * 1.5;
        margin-bottom: 0;
        color: $grey-800;
      }

      .description {
        flex: 0 0 40%;
        font-size: $font-size-base * 1.15;
        color: #273246;
        word-break: break-all;
        margin-top: 1px;
        padding-right: 32px;
      }
    }

    .action {
      margin-left: -24px;
      display: flex;
      align-items: center;
      :deep(.button) {
        padding: 0;
        font-size: $font-level-3;
        &:hover {
          background-color: #fff;
          border-color: transparent;
        }

        &:focus {
          outline: none;
          box-shadow: none;
        }
      }
    }
  }

  .section-collapse-body {
    margin-top: $spacing-base;
  }

  .no-padding {
    margin-left: -($spacing-base * 1.25);
    margin-right: -($spacing-base * 1.25);
  }

  .flex-container {
    position: relative;
    display: flex;
    flex-flow: row;
    padding-top: $spacing-base * 1.5;
    padding-bottom: $spacing-base;
    padding-left: 0;
    padding-right: 0;

    p {
      color: #6a7383;
    }

    &::before {
      content: ' ';
      display: block;
      position: absolute;
      top: 8px;
      left: -($spacing-base * 1.25);
      width: calc(100% + ($spacing-base * 1.25 * 2));
      height: 1px;
      background-color: #e5e7eb;
    }
  }

  .left-pane {
    padding-right: $spacing-base;
  }

  .left-pane,
  .right-pane {
    flex: 0 0 50%;
  }

  .full-pane {
    width: 100%;
  }

  .action-container {
    display: flex;
    justify-content: flex-end;
    margin-bottom: $spacing-base;

    .btn-cancel {
      width: 84px;
      margin-right: $spacing-base;
    }

    .btn-select {
      width: 130px;
    }
  }
}

@media screen and (max-width: 1024px) {
  .section-collapse {
    .section-collapse-header {
      .text {
        .title {
          font-size: $font-size-base * 1.3;
        }

        .description {
          font-size: $font-size-base * 1.1;
        }
      }
    }
  }
}

@media screen and (max-width: $max-layout-md) {
  .section-collapse {
    .section-collapse-header {
      margin-bottom: $spacing-base * 0.6;
      .text {
        height: fit-content;
        align-items: flex-start;
        flex-flow: column nowrap;
      }

      .action {
        display: flex;
        align-items: flex-start;

        :deep(.button) {
          font-size: $font-level-4;
        }
      }
    }

    .flex-container {
      display: flex;
      flex-flow: column wrap;
      padding-top: $spacing-base * 1.5;
      padding-bottom: $spacing-base;
      padding-left: 0;
      padding-right: 0;
    }

    .left-pane {
      padding-right: unset;
    }
  }
}

@media screen and (max-width: $max-layout-sm) {
  :deep(.hide-sm) {
    display: none;
  }
}
</style>
