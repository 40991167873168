<template>
  <Modal
    @modalClose="onClose"
    :isShowModalFooter="false">
    <template v-slot:body>
      <div class="content-group content-create-key"
           v-if="saveUrlSigningKeyState.status !== 'error'">
        <StepTextMessage
          v-if="step === 1"
          iconClass="fas fa-triangle-exclamation icon"
          :title="$t('urlSigningKey.information.develop')"
          :description="$t('urlSigningKey.information.useKey')"
          :primaryButtonTitle="$t('signedURL.urlSigningKey.notFound.create')"
          :primaryButtonAction="onCreate"
          :secondaryButtonTitle="$t('common:close')"
          :secondaryButtonAction="`/${teamName}/manage/signed-url`"
          :onClose="onClose" />
        <StepURLSigningKeyInformation
          v-if="step === 2"
          :urlSigningKey="currentUrlSigningKey"
          :title="$t('urlSigningKey.information.create')"
          :description="computedDescription"
          :onClose="`/${teamName}/manage/signed-url`" />
      </div>
      <!--- show error -->
      <div class="content-group content-error"
           v-if="saveUrlSigningKeyState.status === 'error'">
        <ErrorMessage
          :title="saveUrlSigningKeyState.error?.message"
          :statusCode="saveUrlSigningKeyState.error?.statusCode"/>
      </div>
    </template>
  </Modal>
</template>

<script>
import { mapState } from 'vuex';
import Modal from '@/modules/shared/components/organisms/modal/Modal.vue';
import ErrorMessage from '@/modules/shared/components/atoms/errorMessage/ErrorMessage.vue';
import StepTextMessage from '@/modules/shared/components/molecules/stepTextMessage/StepTextMessage.vue';
import StepURLSigningKeyInformation from '../../molecules/stepURLSigningKeyInformation/StepURLSigningKeyInformation.vue';

export default {
  props: {
    urlSigningKeys: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    Modal,
    ErrorMessage,
    StepTextMessage,
    StepURLSigningKeyInformation,
  },
  computed: {
    ...mapState({
      currentUrlSigningKey: (state) => state.urlSigningKey.currentUrlSigningKey,
      saveUrlSigningKeyState: (state) => state.urlSigningKey.saveUrlSigningKeyState,
    }),
    teamName() {
      return this.$route.params.teamName;
    },
    computedDescription() {
      return `${this.$t('urlSigningKey.information.useKey')} <strong>${this.$t('urlSigningKey.information.requireSignedURL')}`;
    },
  },
  data() {
    return {
      step: 1,
    };
  },
  methods: {
    async onCreate() {
      await this.$store.dispatch('createUrlSigningKey');
      this.step += 1;
    },
    onClose() {
      this.$router.push(`/${this.teamName}/manage/signed-url`);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/global-variables.scss';
@import '~@/assets/scss/themes/helpers.scss';

:deep(.modal-header), :deep(.modal-footer) {
  display: none !important;
}

:deep(.modal-body) {
  /* display: flex; */
}

.formgroup-container {
  text-align: left;
}

.content-group {
  text-align: center;
  width: 80%;
  margin: 0 auto;
  padding-top: $spacing-base * 2;
  .icon {
    font-size: $font-size-base * 5;
    color: $grey-400;
    margin-bottom: $spacing-base;
  }
}

.btn-close-container {
  position: absolute;
  top: $spacing-base;
  right: $spacing-base;
  font-size: $font-size-base * 1.5;
  cursor: pointer;
  z-index: 1;
}
</style>
