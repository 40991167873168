<template>
  <div class="card">
    <div class="card-header" v-if="$slots['card-header']">
      <slot name="card-header"></slot>
    </div>
    <slot></slot>
    <div class="card-footer" v-if="$slots['card-footer']">
      <slot name="card-footer"></slot>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/global-variables.scss';

.card {
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.2s ease-out;
  height: 100%;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.09);

  border: none;
  color: $grey-600;
  background: $white;
  display: flex;
  flex-direction: column;

  :deep(.card-header) {
    background: none;
    border: none;

    // padding: 0 $spacing-base;
    padding: $spacing-12 $spacing-base 0;

    display: flex;
    color: $grey-800;

    .title {
      font-size: $font-level-5;
      margin-bottom: 0;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
    }

    .action {
      flex: 0 0 20px;
      margin-left: auto;
      text-align: right;
      cursor: pointer;
    }
  }

  :deep(.card-body) {
    padding: $spacing-base * 0.5 $spacing-base;
    flex: 1;

    .description {
      height: 60px;
      overflow: hidden;
      margin-bottom: $spacing-base * 2.5;
      // margin-right: $spacing-base * 2.5;
      text-overflow: ellipsis;
      overflow-wrap: break-word;
      word-wrap: break-word;
    }

    .detail {
      display: flex;
      .detail-item {
        margin-right: $spacing-base * 0.5;
      }
    }
  }

  :deep(.card-footer) {
    background: none;
    font-size: $font-level-7;
    border: none;
    padding: 0 $spacing-base $spacing-base;

    display: flex;
    .last-update {
      margin-left: auto;
    }
  }

  &:hover {
    box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.1);
    text-decoration: none;
  }
}

</style>
