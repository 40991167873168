<template>
  <div class="selected-playback-channel-information">
    <h5 class="title">{{selectedPlaybackChannel?.name ?? ''}}</h5>
    <div class="information-group-wrapper">
      <div class="domain-container information-group">
        <span class="label">
          {{$t('hostings.domain')}} ({{getPlaybackDomains(selectedPlaybackChannel).length}})
        </span>
        <div class="value" v-if="!getPlaybackDomains(selectedPlaybackChannel).length">
          -
        </div>
        <div class="domains" v-if="getPlaybackDomains(selectedPlaybackChannel)">
          <div class="value"
               v-for="(domain, index) in getPlaybackDomains(selectedPlaybackChannel)"
               :key="index">
            {{domain}}
          </div>
        </div>
      </div>
      <div class="signed-url information-group">
        <span class="label">
          Signed URL
        </span>
        <div class="value">
          {{getSignedUrlValue(selectedPlaybackChannel) ? $t('domains.signedUrl.enabled') : $t('domains.signedUrl.disabled')}}
        </div>
      </div>
      <div class="access-control-policy information-group">
        <span class="label">
          Allowed / Blocked Website
        </span>
        <div class="value">
          <span v-if="isAllowAllDomains(selectedPlaybackChannel)">{{$t('accessControlPolicy.allowAllWebsite')}}</span>
          <span v-else>{{$t('accessControlPolicy.allowSomeWebsite')}}</span>
        </div>
      </div>
    </div>
    <div class="collapse-wrapper">
      <div class="access-control-policy-collapse" v-if="getAllowDomains(selectedPlaybackChannel).length">
        <div class="collapse-header" :class="{'showing' : isAllowDomainCollapseShowing }" @click="onClickAllowDomainCollapse">
          <span class="label"><i class="fa fa-circle-check text-success" />{{$t('accessControlPolicy.allowWebsite')}}</span>
          <i class="fas fa-chevron-down chevron-icon"></i>
        </div>
        <div class="collapse-body" v-if="isAllowDomainCollapseShowing">
          <div class="domain" v-for="(domain) in getAllowDomains(selectedPlaybackChannel)" :key="domain">
            <span><i class="fas fa-earth-asia" />{{domain}}</span>
          </div>
        </div>
      </div>
      <div class="access-control-policy-collapse" v-if="getDenyDomains(selectedPlaybackChannel).length">
        <div class="collapse-header" :class="{'showing' : isBlockDomainCollapseShowing }" @click="onClickBlockDomainCollapse">
          <span class="label"><i class="fa fa-ban text-danger" />{{$t('accessControlPolicy.blockWebsite')}}</span>
          <i class="fas fa-chevron-down chevron-icon"></i>
        </div>
        <div class="collapse-body" v-if="isBlockDomainCollapseShowing && getDenyDomains.length">
          <div class="domain" v-for="(domain) in getDenyDomains(selectedPlaybackChannel)" :key="domain">
            <span><i class="fas fa-earth-asia" />{{domain}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import _ from 'lodash';
import { ref, toRefs } from 'vue';
import type { PlaybackChannel } from '@/modules/playbackChannel';

interface PlaybackChannelSummaryProps {
  selectedPlaybackChannel: PlaybackChannel | null | undefined;
}

const props = defineProps<PlaybackChannelSummaryProps>();
const { selectedPlaybackChannel } = toRefs(props);

const isAllowDomainCollapseShowing = ref<boolean>(true);
const isBlockDomainCollapseShowing = ref<boolean>(true);

function isAllowAllDomains(playbackChannel: PlaybackChannel | null | undefined) {
  const allowDomains = _.get(playbackChannel, 'accessControlPolicy.allowDomains', []);
  const denyDomains = _.get(playbackChannel, 'accessControlPolicy.denyDomains', []);
  return !allowDomains.length && !denyDomains.length;
}

function getPlaybackDomains(playbackChannel: PlaybackChannel | null | undefined): string[] {
  if (_.isNil(playbackChannel)) {
    return [];
  }

  return [playbackChannel.defaultPlaybackDomain, ..._.get(playbackChannel, 'playbackDomains', [])];
}

function getSignedUrlValue(playbackChannel: PlaybackChannel | null | undefined) {
  return _.get(playbackChannel, 'signedUrl.required', false);
}

function getAllowDomains(playbackChannel: PlaybackChannel | null | undefined) {
  return _.get(playbackChannel, 'accessControlPolicy.allowDomains', []);
}

function getDenyDomains(playbackChannel: PlaybackChannel | null | undefined) {
  return _.get(playbackChannel, 'accessControlPolicy.denyDomains', []);
}

function onClickAllowDomainCollapse() {
  isAllowDomainCollapseShowing.value = !isAllowDomainCollapseShowing.value;
}

function onClickBlockDomainCollapse() {
  isBlockDomainCollapseShowing.value = !isBlockDomainCollapseShowing.value;
}

</script>

<style lang="scss" scoped>
@import '~@/assets/scss/global-variables';

.selected-playback-channel-information {
  .title {
    margin-bottom: $spacing-base * 1.5;
  }
  .label {
    color: #1B263A;
    font-size: $font-size-base * 1.1;
  }
  .value {
    margin-top: $spacing-base * 0.25;
    color: #6A7383;
  }
  .information-group {
    margin-bottom: $spacing-base * 1.5;
  }

  .access-control-policy-collapse {
    background-color: $grey-100;
    margin-bottom: $spacing-base;
    .collapse-header {
      padding: $spacing-base;
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      .chevron-icon {
        transition: all ease-in-out .2s;
      }

      &.showing {
        border-bottom: 1px solid $grey-200;
        .chevron-icon {
          transform:rotate(180deg);
        }
      }
    }
    .collapse-body {
      padding: $spacing-base;
      .domain {
        margin-bottom: $spacing-base * 0.5;
      }
      .domain:last-child {
        margin-bottom: 0;
      }
    }
    i {
      margin-right: $spacing-8;
    }
  }
}
</style>
