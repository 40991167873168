import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, unref as _unref, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createVNode as _createVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createSlots as _createSlots } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "button-text-short" }
const _hoisted_3 = { class: "information-row" }
const _hoisted_4 = { class: "information-group" }
const _hoisted_5 = { class: "label" }
const _hoisted_6 = { class: "service-list" }
const _hoisted_7 = { class: "information-group" }
const _hoisted_8 = { class: "value" }
const _hoisted_9 = { class: "information-row" }
const _hoisted_10 = { class: "information-group" }
const _hoisted_11 = { class: "label" }
const _hoisted_12 = {
  key: 0,
  class: "language-list"
}
const _hoisted_13 = { key: 1 }
const _hoisted_14 = { class: "preset-link" }
const _hoisted_15 = { class: "preset-link" }

import {
  computed,
  onMounted,
  ref,
  watch,
  useAttrs,
} from 'vue';
import _ from 'lodash';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';
import { useStore } from '@/modules/projectDefaultUploadSetting/store/hooks';
import type { CaptionProfile, CaptionProfilePublishStatusSetting } from '@/modules/captionProfile/types';
import { setStateLoadingStatusByType } from '@/modules/shared/utils/stateManagement';
import { formatErrorObject } from '@/modules/shared/utils/errorFormatter';
import Badge from '@/modules/shared/components/atoms/badge/Badge.vue';
import MessageBox from '@/modules/shared/components/molecules/messageBox/MessageBox.vue';
import Section from '@/modules/shared/components/organisms/section/Section.vue';
import ItemInformationCard from '@/modules/shared/components/atoms/itemInformationCard/ItemInformationCard.vue';
import Button from '@/modules/shared/components/atoms/button/Button.vue';
import PreLoaderSection from '@/modules/shared/components/atoms/preLoaderSection/PreLoaderSection.vue';
import ChooseProjectCaptionProfileModal from '../chooseProjectCaptionProfileModal/ChooseProjectCaptionProfileModal.vue';
import { getCaptionProfileByKey } from '../../../services';


export default /*@__PURE__*/_defineComponent({
  __name: 'ProjectManageUploadSettingSubtitleSection',
  setup(__props) {

const serviceNameText: Record<string, string> = {
  google: 'Google Speech-to-Text',
  aws: 'Amazon Transcribe',
};

const statusText: Record<CaptionProfilePublishStatusSetting, string> = {
  draft: 'Save as Draft',
  publish: 'Publish immediately',
  publishIfNoBadWord: 'Publish if the subtitle doesn’t contain bad words',
};

const { t } = useI18n();
const route = useRoute();
const store = useStore();

const captionProfile = ref<CaptionProfile | null>(null);
const loadProjectCaptionProfileState = ref(setStateLoadingStatusByType());
const isShowChooseSubtitleModal = ref(false);

const { canUpdateProject } = useAttrs();

// Store
const teamName = computed(() => route.params.teamName);
const currentProject = computed(() => store.state.project.currentProject);
const teamFeatures = computed(() => store.state.teamFeatures.teamFeatures);

// Computed
const languageList = computed(() => captionProfile.value?.translate?.languages);
const transcribeServiceList = computed(() => captionProfile.value?.transcribe?.services);
const autoGenerateCaptionSettingId = computed(() => currentProject.value?.autoGenerateCaptionSettingId);
const isEnableSubtitleFeature = computed(() => teamFeatures.value && teamFeatures.value['auto-generate-caption']);

const loadCaptionProfileData = async (captionProfileId: string | null | undefined) => {
  if (_.isNil(captionProfileId)) {
    return;
  }

  try {
    loadProjectCaptionProfileState.value = setStateLoadingStatusByType({ type: 'loading' });
    const response = await getCaptionProfileByKey(captionProfileId);
    captionProfile.value = response?.data;
    loadProjectCaptionProfileState.value = setStateLoadingStatusByType({ type: 'success' });
  } catch (error) {
    loadProjectCaptionProfileState.value = setStateLoadingStatusByType({ type: 'error', error: formatErrorObject(error, 'Subtitle Profile') });
    // handle
  }
};

watch(autoGenerateCaptionSettingId, () => {
  if (autoGenerateCaptionSettingId.value) {
    loadCaptionProfileData(autoGenerateCaptionSettingId.value);
  }
}, { immediate: true });

onMounted(() => {
  loadCaptionProfileData(autoGenerateCaptionSettingId.value);
});

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (isEnableSubtitleFeature.value)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (isShowChooseSubtitleModal.value)
          ? (_openBlock(), _createBlock(ChooseProjectCaptionProfileModal, {
              key: 0,
              onModalClose: _cache[0] || (_cache[0] = () => isShowChooseSubtitleModal.value = false)
            }))
          : _createCommentVNode("", true),
        (captionProfile.value)
          ? (_openBlock(), _createBlock(Section, {
              key: 1,
              title: "Subtitle Profile Setting"
            }, _createSlots({
              action: _withCtx(() => [
                (_unref(canUpdateProject))
                  ? (_openBlock(), _createBlock(Button, {
                      key: 0,
                      type: "button",
                      buttonStyle: "secondary",
                      buttonClasses: "change-preset-button",
                      onClick: _cache[1] || (_cache[1] = () => isShowChooseSubtitleModal.value = true)
                    }, {
                      content: _withCtx(() => [
                        _cache[2] || (_cache[2] = _createElementVNode("span", { class: "button-text-full" }, " Change Subtitle Profile ", -1)),
                        _createElementVNode("span", _hoisted_2, _toDisplayString(_unref(t)('common:button.change')), 1)
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true)
              ]),
              default: _withCtx(() => [
                (!_unref(_).isNil(transcribeServiceList.value) && !_unref(_).isNil(captionProfile.value))
                  ? (_openBlock(), _createBlock(ItemInformationCard, {
                      key: 0,
                      title: captionProfile.value.name
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_3, [
                          _createElementVNode("div", _hoisted_4, [
                            _createElementVNode("div", _hoisted_5, "Transcribe Service (" + _toDisplayString(transcribeServiceList.value?.length) + ")", 1),
                            _createElementVNode("div", _hoisted_6, [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(transcribeServiceList.value, (service, index) => {
                                return (_openBlock(), _createElementBlock("div", {
                                  class: "value",
                                  key: index
                                }, _toDisplayString(serviceNameText[service]), 1))
                              }), 128))
                            ])
                          ]),
                          _createElementVNode("div", _hoisted_7, [
                            _cache[3] || (_cache[3] = _createElementVNode("div", { class: "label" }, "Status", -1)),
                            _createElementVNode("div", _hoisted_8, _toDisplayString(statusText[captionProfile.value.publishStatusSetting]), 1)
                          ])
                        ]),
                        _createElementVNode("div", _hoisted_9, [
                          _createElementVNode("div", _hoisted_10, [
                            _createElementVNode("div", _hoisted_11, "Auto-translate " + _toDisplayString(languageList.value?.length ? `(${languageList.value?.length})` : ''), 1),
                            (languageList.value?.length)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(languageList.value, (language, index) => {
                                    return (_openBlock(), _createElementBlock("div", {
                                      class: "badge-list",
                                      key: index
                                    }, [
                                      _createVNode(Badge, {
                                        title: language.name
                                      }, null, 8, ["title"])
                                    ]))
                                  }), 128))
                                ]))
                              : (_openBlock(), _createElementBlock("div", _hoisted_13, " - "))
                          ])
                        ])
                      ]),
                      _: 1
                    }, 8, ["title"]))
                  : _createCommentVNode("", true)
              ]),
              _: 2
            }, [
              (_ctx.$attrs.isManager)
                ? {
                    name: "section-footer-with-border",
                    fn: _withCtx(() => [
                      _createElementVNode("div", _hoisted_14, [
                        _createVNode(_component_router_link, {
                          class: "link",
                          to: `/${teamName.value}/manage/subtitle-profiles`
                        }, {
                          default: _withCtx(() => _cache[4] || (_cache[4] = [
                            _createTextVNode(" Subtitle Profile Settings "),
                            _createElementVNode("i", { class: "fas fa-chevron-right" }, null, -1)
                          ])),
                          _: 1
                        }, 8, ["to"])
                      ])
                    ]),
                    key: "0"
                  }
                : undefined
            ]), 1024))
          : _createCommentVNode("", true),
        (!captionProfile.value)
          ? (_openBlock(), _createBlock(Section, {
              key: 2,
              title: "Subtitle Profile Setting"
            }, _createSlots({
              default: _withCtx(() => [
                _createVNode(MessageBox, {
                  title: _unref(t)('project.defaultSettings.subtitle.noSubtitle'),
                  description: _unref(t)('project.defaultSettings.subtitle.emptySuggestion'),
                  buttonTitle: _unref(canUpdateProject) ? _unref(t)('project.defaultSettings.subtitle.settingDefaultButton') : '',
                  buttonStyle: "secondary",
                  onClickButton: () => isShowChooseSubtitleModal.value = true
                }, null, 8, ["title", "description", "buttonTitle", "onClickButton"])
              ]),
              _: 2
            }, [
              (_ctx.$attrs.isManager)
                ? {
                    name: "section-footer-with-border",
                    fn: _withCtx(() => [
                      _createElementVNode("div", _hoisted_15, [
                        _createVNode(_component_router_link, {
                          class: "link",
                          to: `/${teamName.value}/manage/subtitle-profiles`
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_unref(t)('project.defaultSettings.subtitle.settingButton')) + " ", 1),
                            _cache[5] || (_cache[5] = _createElementVNode("i", { class: "fas fa-chevron-right" }, null, -1))
                          ]),
                          _: 1
                        }, 8, ["to"])
                      ])
                    ]),
                    key: "0"
                  }
                : undefined
            ]), 1024))
          : _createCommentVNode("", true),
        (loadProjectCaptionProfileState.value.status === 'loading')
          ? (_openBlock(), _createBlock(PreLoaderSection, {
              key: 3,
              options: { height: '300' }
            }))
          : _createCommentVNode("", true)
      ]))
    : _createCommentVNode("", true)
}
}

})