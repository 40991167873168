import type { ActionContext } from 'vuex';
import { editProject, loadProjectByKey } from '@/modules/shared/services/project';
import { formatErrorObject } from '@/modules/shared/utils/errorFormatter';
import { setStateSavingStatusByType, setStateLoadingStatusByType } from '@/modules/shared/utils/stateManagement';
import type { RootState } from '@/store/type';
import type { LoadingState, SavingState } from '@/types/State.type';
import type { Project, ProjectState } from '../types/project.type';

type ProjectActionContext = ActionContext<ProjectState, RootState>

const defaultProjectManagementFilter = {
  includes: [
    'videosCount',
    'memberships.account',
    'memberships.addedBy',
    'player',
    'preset',
    'geoblockRule',
    'hosting',
    'playbackChannels',
    'primaryPlaybackChannel',
    'bumperDisplay',
    'creator',
  ],
};

const initialState = (): ProjectState => ({
  currentProject: null,
  loadProjectState: setStateLoadingStatusByType(),
  saveProjectState: setStateSavingStatusByType(),
  loadProjectManagementState: setStateLoadingStatusByType(),
});

const state = initialState();

const mutations = {
  setCurrentProject(state: ProjectState, currentProject: Project) {
    state.currentProject = { ...state.currentProject, ...currentProject };
  },
  destroyCurrentProject(state: ProjectState) {
    state.currentProject = null;
  },
  setLoadProjectState(state: ProjectState, loadingState: LoadingState) {
    state.loadProjectState = setStateLoadingStatusByType(loadingState);
  },
  setLoadProjectManageState(state: ProjectState, loadingState: LoadingState) {
    state.loadProjectManagementState = setStateLoadingStatusByType(loadingState);
  },
  setUpdateProjectStateMessage(state: ProjectState, savingState: SavingState) {
    state.saveProjectState = setStateSavingStatusByType(savingState);
  },
};

const actions = {
  async loadProjectByKey({ commit }: ProjectActionContext, projectKey: string) {
    commit('setLoadProjectState', { type: 'loading' });
    try {
      const response = await loadProjectByKey(projectKey);
      commit('setCurrentProject', response.data);
      commit('setLoadProjectState', { type: 'success' });
    } catch (error) {
      commit('setLoadProjectState', { type: 'error', error: formatErrorObject(error, 'Project') });
    }
  },
  async loadProjectManagement({ commit }: ProjectActionContext, projectKey: string) {
    commit('setLoadProjectManageState', { type: 'loading' });
    try {
      const response = await loadProjectByKey(projectKey, defaultProjectManagementFilter);
      commit('setCurrentProject', response.data);
      commit('setLoadProjectManageState', { type: 'success' });
    } catch (error) {
      commit('setLoadProjectManageState', { type: 'error', error: formatErrorObject(error, 'Project') });
    }
  },
  async editProject({ commit }: ProjectActionContext, payload: {projectKey: string, data: Project}) {
    commit('setUpdateProjectStateMessage', { type: 'saving' });
    try {
      const response = await editProject(payload.projectKey, payload.data);
      commit('setCurrentProject', response.data);
      commit('setUpdateProjectStateMessage', { type: 'success' });
    } catch (error) {
      commit('setUpdateProjectStateMessage', { type: 'error', error: formatErrorObject(error, 'Project') });
    }
  },
  destroyCurrentProject({ commit }: ProjectActionContext) {
    commit('destroyCurrentProject');
    // clear state
    commit('setLoadProjectState', { type: 'idle' });
    commit('setUpdateProjectStateMessage', { type: 'idle' });
  },
  setCurrentProject({ commit }: ProjectActionContext, payload: Project) {
    commit('setCurrentProject', payload);
  },
};

export default {
  state,
  actions,
  mutations,
};
