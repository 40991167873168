/* eslint no-console: "off" */
/* eslint vars-on-top: "off" */

import * as tus from 'tus-js-client';

const baseURL = process.env.VUE_APP_ORIGIN || window.location.origin;

const tusUploader = new tus.Upload(undefined, {
  endpoint: `${baseURL}/api/upload/v1/tus/videos/`, // endpoint ที่จะอัปโหลดไฟล์
  chunkSize: 100000000,
  retryDelays: [7000, 7000, 11000, 11000, 15000, 15000, 20000, 20000, 30000, 30000],
  onBeforeRequest(req) {
    const xhr = req.getUnderlyingObject();
    xhr.withCredentials = true;
  },
  onError(error) {
    console.log('Uploading video failed', error);
  },
  onShouldRetry(err, retryAttempt) {
    console.log('Uploading video failed, checking if should retry or not', err, retryAttempt);
    const status = err.originalResponse ? err.originalResponse.getStatus() : 0;
    if (status === 403) {
      return false;
    }
    return true;
  },
});

export default tusUploader;
