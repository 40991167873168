import store from '@/store';
import project from '@/modules/shared/store/project';
import user from '@/modules/user/store/user';
import type { RouteRecordRaw } from 'vue-router';
import projectMember from '../store';

export function createProjectMemberRoutes(): RouteRecordRaw[] {
  return [
    {
      name: 'members',
      path: 'members',
      component: () => import(/* webpackChunkName: "ProjectManagementMembership" */ '../pages/ProjectManageMember.vue'),
      beforeEnter: (to, from, next) => {
        if (!store.hasModule('project')) {
          store.registerModule('project', project);
        }
        if (!store.hasModule('projectMember')) {
          // @ts-expect-error not overload matches this call
          store.registerModule('projectMember', projectMember);
        }
        if (!store.hasModule('user')) {
          store.registerModule('user', user);
        }
        next();
      },
    },
  ];
}
