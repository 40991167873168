import {
  format,
  differenceInMilliseconds,
  formatDistance,
  addSeconds,
  startOfDay,
  getTime,
} from 'date-fns';

const transformDateToDisplay = (date) => format(new Date(date), 'dd/MM/yyyy');

const transformDateToDisplayTime = (date) => format(new Date(date), 'HH:mm');

const formatDate = (date, formatString = 'yyyy-MM-dd') => format(new Date(date), formatString);

const formatDateTime = (date) => format(new Date(date), 'dd/MM/yyyy HH:mm.ss');

const formatVideoDateTime = (date) => format(new Date(date), 'dd/MM/yyyy - HH:mm');

const formatTimeDate = (date) => format(new Date(date), 'HH:mm.ss dd/MM/yyyy');

const formatLongDateTime = (date) => format(new Date(date), 'HH:mm.ss @ MMM d, yyyy');

const formatLongDateOnly = (date) => format(new Date(date), 'MMM d, yyyy');

const formatLongTime = (date) => format(new Date(date), 'HH:mm.ss');

const formatShortDate = (date) => format(new Date(date), 'd MMM');

const formatTime = (date) => format(new Date(date), 'HH:mm');

const getCurrentYear = () => format(new Date(), 'yyyy');

const transformTime = (seconds) => {
  let formattedSecond;
  switch (true) {
    case seconds < 60:
      formattedSecond = seconds;
      break;
    case seconds > 60 && seconds < 3600:
      formattedSecond = `${Math.floor(seconds / 60)} M.`;
      break;
    default:
      formattedSecond = '0 Second';
      break;
  }
  return formattedSecond;
};

const transformSecondsToDuration = (seconds) => {
  const newDate = addSeconds(startOfDay(new Date()), seconds);
  const hour = 60 * 60;
  if (seconds > hour) {
    return format(new Date(newDate), 'HH:mm:ss');
  }

  return format(new Date(newDate), 'mm:ss');
};

const getTimeFromDate = (date) => getTime(new Date(date));

export {
  transformDateToDisplay,
  transformDateToDisplayTime,
  formatDate,
  formatDateTime,
  formatVideoDateTime,
  formatTimeDate,
  formatLongDateTime,
  formatLongDateOnly,
  formatLongTime,
  formatShortDate,
  formatTime,
  differenceInMilliseconds,
  formatDistance,
  transformTime,
  transformSecondsToDuration,
  getCurrentYear,
  getTimeFromDate,
};
