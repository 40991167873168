<template>
  <div class="tab" :class="{ 'has-background': hasBackground, 'dark-theme': isDarkTheme }">
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    hasBackground: {
      type: Boolean,
      default: false,
    },
    isDarkTheme: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/global-variables.scss';

.tab {
  display: flex;
  border-bottom: 1px solid $grey-200;
  overflow-x: auto;
  gap: $spacing-32;

  :slotted(.tab-item) {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    color: $grey-600;
    // font-weight: $font-weight-bold;
    /* text-transform: capitalize; */
    font-weight: $font-weight-base;
    padding: 0 0 10px 0;
    white-space: nowrap;
    border-bottom: 2px solid transparent;

    &:hover {
      text-decoration: none;
      color: $ci-primary;
      transition: all ease-in-out .1s
    }

    &.active, &.router-link-exact-active {
      color: $ci-primary;
      border-bottom: 2px solid $ci-primary;
    }
  }
}

.dark-theme {
  border-bottom: 1px solid transparent;
  :slotted(.tab-item) {
    color: $grey-300;
    &:hover {
      color: $ci-primary-light;
      transition: all ease-in-out .1s
    }

    &.active, &.router-link-exact-active {
      color: $ci-primary-light;
      border-bottom: 2px solid $ci-primary-light;
    }
  }
}

.has-background {
  border: 0;
  background: $white;
  gap: 0;
  /* border-bottom: 1px solid transparent; */

  :slotted(.tab-item) {
    padding: $spacing-12 $spacing-base;
    /* white-space: nowrap; */
    border-bottom: 2px solid transparent;
    &:hover {
      background: $grey-100;
      color: $grey-600;
      transition: all ease-in-out .1s
    }

    &.active, &.router-link-exact-active {
      color: $ci-primary;
      border-bottom: 2px solid $ci-primary;
    }
  }
}
</style>
