import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, withCtx as _withCtx } from "vue"

import _ from 'lodash';
import {
  computed,
  onMounted,
  ref,
  Ref,
  toRefs,
} from 'vue';
import { useI18n } from 'vue-i18n';
import type { BaseGeoblockPolicy } from '@/modules/geoblock/types/Geoblock.type';
import { setStateLoadingStatusByType } from '@/modules/shared/utils/stateManagement';
import {
  GeoblockRule,
  GeoblockPolicy,
  BaseGeoblockRule,
  GeoblockRuleInformation,
  loadGeoblockRuleById,
  getGeoblockRuleInformation,
} from '@/modules/geoblock';
import { formatErrorObject } from '@/modules/shared/utils/errorFormatter';
import { StatusState } from '@/types/State.type';
import VideoGeoblockRuleOptionField from '../videoGeoblockRuleOptionField/VideoGeoblockRuleOptionField.vue';
import VideoUploadDefaultBox from '../../organisms/videoUploadDefaultBox/VideoUploadDefaultBox.vue';

interface UploadVideoGeoblockRuleSettingProps {
  defaultGeoblockRule?: Nullable<GeoblockRule | Omit<GeoblockRule, 'isTeamDefault'>>;
  geoblockPolicy?: Nullable<GeoblockPolicy | BaseGeoblockPolicy>;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'UploadVideoGeoblockRuleSetting',
  props: {
    defaultGeoblockRule: {},
    geoblockPolicy: {}
  },
  emits: ["update:geoblockPolicy"],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const {
  defaultGeoblockRule: defaultGeoblockRuleFromProps,
  geoblockPolicy,
} = toRefs(props);

// False positive: defaultGeoblockRule is just an interface property, not a duplicated key.
// eslint-disable-next-line vue/no-dupe-keys
const defaultGeoblockRule: BaseGeoblockRule | GeoblockRule = defaultGeoblockRuleFromProps.value ?? {
  allowCountryCodes: [],
  denyCountryCodes: [],
};

const { t } = useI18n();
const emits = __emit;

// data
const isAdvanceOptionShowing = ref(false);
const currentGeoblockRule: Ref<GeoblockRule | null> = ref(null);
const loadGeoblockRuleState = ref<StatusState>(setStateLoadingStatusByType());

// computed
const geoblockRule = computed(() => {
  const geoblockRuleData = currentGeoblockRule.value || defaultGeoblockRule;
  return getGeoblockRuleInformation({
    geoblockRule: geoblockRuleData,
    geoblockPolicy: geoblockPolicy.value,
  });
});

const geoblockPolicyRuleId = computed(() => _.get(geoblockPolicy.value, 'ruleId', null));

// methods
const loadGeoblockRuleItemById = async (geoblockRuleId: string) => {
  loadGeoblockRuleState.value = setStateLoadingStatusByType({ type: 'loading' });
  try {
    const response = await loadGeoblockRuleById(geoblockRuleId);
    currentGeoblockRule.value = response.data;
    loadGeoblockRuleState.value = setStateLoadingStatusByType({ type: 'success' });
  } catch (e) {
    loadGeoblockRuleState.value = setStateLoadingStatusByType({
      type: 'error',
      error: formatErrorObject(e, 'GeoblockRuleById'),
    });
  }
};

const onToggleAdvanceOption = () => {
  isAdvanceOptionShowing.value = !isAdvanceOptionShowing.value;
};

const onUpdatedGeoblockPolicy = (policy: GeoblockPolicy | BaseGeoblockPolicy) => {
  emits('update:geoblockPolicy', policy);
  if (policy?.ruleId) {
    loadGeoblockRuleItemById(policy?.ruleId);
  }
};

onMounted(() => {
  if (geoblockPolicyRuleId.value) {
    loadGeoblockRuleItemById(geoblockPolicyRuleId.value);
  }
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(VideoUploadDefaultBox, {
    label: _unref(t)('video.geoBlock.title'),
    class: _normalizeClass({ 'no-padding-content': isAdvanceOptionShowing.value }),
    isEditing: isAdvanceOptionShowing.value,
    onOnClickEdit: onToggleAdvanceOption
  }, {
    default: _withCtx(() => [
      (!isAdvanceOptionShowing.value)
        ? (_openBlock(), _createBlock(_unref(GeoblockRuleInformation), {
            key: 0,
            geoblockRule: geoblockRule.value
          }, null, 8, ["geoblockRule"]))
        : _createCommentVNode("", true),
      (isAdvanceOptionShowing.value && geoblockRule.value)
        ? (_openBlock(), _createBlock(VideoGeoblockRuleOptionField, {
            key: 1,
            geoblockRule: currentGeoblockRule.value,
            defaultGeoblockRule: _unref(defaultGeoblockRule),
            geoblockPolicy: _unref(geoblockPolicy),
            loadGeoblockRuleState: loadGeoblockRuleState.value,
            "onUpdate:geoblockPolicy": onUpdatedGeoblockPolicy
          }, null, 8, ["geoblockRule", "defaultGeoblockRule", "geoblockPolicy", "loadGeoblockRuleState"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["label", "class", "isEditing"]))
}
}

})