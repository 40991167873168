import isEmpty from 'lodash/isEmpty';
import {
  ActionContext, ActionTree, GetterTree, MutationTree,
} from 'vuex';
import { RootState } from '@/store/type';
import { setStateLoadingStatusByType } from '@/modules/shared/utils/stateManagement';
import { LoadingState } from '@/types/State.type';
import { formatErrorObject } from '@/modules/shared/utils/errorFormatter';
import { loadVideoCreatorList, loadVideoImportBatchList } from '@/services/api/videoImportBatch';
import {
  VideoImportBatch,
  LoadBatchListFilter,
  VideoImportBatchListState,
  VideoCreator,
} from '../types/VideoImportBatch.type';

type VideoImportBatchContext = ActionContext<VideoImportBatchListState, RootState>;

const state: VideoImportBatchListState = {
  isShowHiddenImportBatch: false,
  videoImportBatchList: [],
  videoCreatorList: [],
  filterBy: null,
  lastPage: 0,
  loadVideoImportBatchListState: setStateLoadingStatusByType(),
  loadVideoCreatorListState: setStateLoadingStatusByType(),
  isShowFilter: false,
};

const getters: GetterTree<VideoImportBatchListState, RootState> = {
  isEmptyBatchList: (state: VideoImportBatchListState): boolean => isEmpty(state.videoImportBatchList),
};

const mutations: MutationTree<VideoImportBatchListState> = {
  setIsShowHiddenImportBatch(state: VideoImportBatchListState, isShowHiddenImportBatch: boolean) {
    state.isShowHiddenImportBatch = isShowHiddenImportBatch;
  },
  setVideoImportBatchList(state: VideoImportBatchListState, importBatchList: VideoImportBatch[]) {
    state.videoImportBatchList = importBatchList;
  },
  setVideoCreatorList(state: VideoImportBatchListState, videoCreatorList: VideoCreator[]) {
    state.videoCreatorList = videoCreatorList;
  },
  setVideoImportBatchListLastPage(state: VideoImportBatchListState, lastPage: number) {
    state.lastPage = lastPage;
  },
  setFilterBy(state: VideoImportBatchListState, filterBy: VideoCreator | null) {
    state.filterBy = filterBy;
  },
  setVideoImportBatchListState(state: VideoImportBatchListState, loadingState: LoadingState) {
    state.loadVideoImportBatchListState = setStateLoadingStatusByType(loadingState);
  },
  setLoadVideoCreatorListState(state: VideoImportBatchListState, loadingState: LoadingState) {
    state.loadVideoCreatorListState = setStateLoadingStatusByType(loadingState);
  },
  setIsShowFilter(state: VideoImportBatchListState, isShowFilter: boolean) {
    state.isShowFilter = isShowFilter;
  },
};

const actions: ActionTree<VideoImportBatchListState, RootState> = {
  async loadBatchListAPI({ commit }: VideoImportBatchContext, filter: LoadBatchListFilter) {
    const result = await loadVideoImportBatchList(filter);
    commit('setVideoImportBatchList', result.data.data);
    commit('setVideoImportBatchListLastPage', result.data.lastPage);
  },
  async loadBatchList({ commit, dispatch }: VideoImportBatchContext, filter: LoadBatchListFilter) {
    commit('setVideoImportBatchListState', { type: 'loading' } as LoadingState);
    try {
      await dispatch('loadBatchListAPI', filter);
      commit('setVideoImportBatchListState', { type: 'success' } as LoadingState);
    } catch (error) {
      commit('setVideoImportBatchListState', {
        type: 'error',
        error: formatErrorObject(error, 'loadBatchList'),
      } as LoadingState);
    }
  },
  async reloadBatchList({ commit, dispatch }: VideoImportBatchContext, filter: LoadBatchListFilter) {
    try {
      await dispatch('loadBatchListAPI', filter);
    } catch (error) {
      commit('setVideoImportBatchListState', {
        type: 'error',
        error: formatErrorObject(error, 'loadBatchList'),
      } as LoadingState);
    }
  },

  async loadVideoCreatorList({ commit }: VideoImportBatchContext, projectKey: string) {
    commit('setLoadVideoCreatorListState', { type: 'loading' } as LoadingState);
    try {
      const result = await loadVideoCreatorList(projectKey);
      commit('setVideoCreatorList', result.data.memberships);
      commit('setLoadVideoCreatorListState', { type: 'success' } as LoadingState);
    } catch (error) {
      commit('setLoadVideoCreatorListState', {
        type: 'error',
        error: formatErrorObject(error, 'loadVideoCreatorList'),
      } as LoadingState);
    }
  },
  async setFilterByObject({ commit }: VideoImportBatchContext, filterBy: VideoCreator | null) {
    commit('setFilterBy', filterBy);
  },
  async setFilterById({ commit, state }: VideoImportBatchContext, filterById: string | null) {
    const result = state.videoCreatorList.find((item) => item.account.id === filterById);
    commit('setFilterBy', result);
  },
  setIsShowFilter({ commit }: VideoImportBatchContext, isShowFilter: boolean) {
    commit('setIsShowFilter', isShowFilter);
  },
  destroyState({ commit }: VideoImportBatchContext) {
    commit('setVideoImportBatchList', []);
    commit('setVideoImportBatchListLastPage', 0);
    commit('setVideoImportBatchListState', { type: 'idle' } as LoadingState);
    commit('setLoadVideoCreatorListState', { type: 'idle' } as LoadingState);
    commit('setFilterBy', null);
    commit('setIsShowFilter', false);
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
