<template>
  <SelectOption
    label="title"
    class="select-collection-visibility"
    placeholder="Select visibility"
    :disabled="disabled"
    @update:modelValue="onSelected"
    :modelValue="modelValue"
    :options="collectionVisibilities"
    :searchable="false">
    <template v-slot:singleLabel="props">
      <div class="option">
        <div class="icon">
          <i :class="`${props.option.icon}`"></i>
        </div>
        <div class="text">
          {{ props.option.title ? $t(props.option.title) : '' }}
        </div>
      </div>
    </template>
    <template v-slot:option="props">
      <div class="option">
        <div class="icon">
          <i :class="`${props.option.icon}`"></i>
        </div>
        <div class="text">
          {{ props.option.title ? $t(props.option.title) : '' }}
        </div>
      </div>
    </template>
  </SelectOption>
</template>

<script>
import { collectionVisibilities } from '@/modules/collection/utils';
import SelectOption from '@/modules/shared/components/atoms/selectOption/SelectOption.vue';

export default {
  props: {
    modelValue: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: () => false,
    },
  },
  components: {
    SelectOption,
  },
  emits: ['update:modelValue'],
  data() {
    return {
      collectionVisibilities,
    };
  },
  methods: {
    onSelected(selectedOption) {
      this.$emit('update:modelValue', selectedOption);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/global-variables';

:deep(.multiselect__option--selected.multiselect__option--highlight) {
  background: #8bbee1;
}

.select-collection-visibility {
  min-height: unset;
  height:33px;
}

:deep(.multiselect__option--highlight) {
  background-color: $ci-primary;
}

.option {
  display: flex;

  .icon {
    font-size: $font-level-7;
    color: $grey-600;
    margin-right: 8px;
  }

  .text {
    font-size: $font-level-7;
  }
}

:deep(.multiselect__tags) {
  border-radius: $border-radius-3;
  padding: 0;
  padding: 8px 12px;
  display: flex;
  align-items: center;
  position: relative;
}

:deep(.multiselect__tags .multiselect__single){
  margin: 0;
  padding: 0;
}

:deep(.multiselect__select) {
  z-index: 2;
  height: 100%;
}

:deep(.multiselect), :deep(.multiselect__tags) {
  min-height: unset;
  height: 33px;
}

:deep(.multiselect__option--selected.multiselect__option--highlight),
:deep(.multiselect__option--highlight) {
  .icon {
    color: #FFF;
  }
}

</style>
