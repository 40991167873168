<template>
  <Modal
    :title="$t('video.subtitle.modal.translate.title')"
    @modalClose="onClose"
    :primaryButtonText="primaryButtonText"
    :onClickPrimaryButton="onClickPrimaryButton"
    :isDisabledPrimaryButton="!selectedCaption || translateToLanguages.length === 0"
    :secondaryButtonText="$t('common:button.back')"
    :onClickSecondaryButton="onClickSecondaryButton"
    size="medium"
    :isSaving="saveTranslateCaptionState.status === 'saving'"
  >
    <template v-slot:body>
      <div class="modal-body">
        <div v-if="pageName[pageIndex] === 'translate'">
          <div class="heading">
            {{ $t('video.subtitle.modal.translate.description') }}
          </div>
          <Field :title="t('video.subtitle.modal.translate.from.title')" class="translate-from">
            <TranslateCaptionSelector
              v-if="translatableCaptions"
              :model-value="selectedCaption"
              :options="translatableCaptions"
              @update:model-value="onSelectCaption"
            />
          </Field>
          <FormMultiSelect
            :title="$t('video.subtitle.modal.translate.to.title')"
            class="translate-to"
            :options="languagesList"
            :modelValue="translateToLanguages"
            label="name"
            trackBy="code"
            @on-changed="onToLanguagesChange"
            :searchable="true"
            :placeholder="$t('video.subtitle.modal.translate.to.languages')"
          />
        </div>
        <div v-if="pageName[pageIndex] === 'status'">
          <CaptionStatusRadioGroup
            :modelValue="selectedStatusOption"
            :onChange="onStatusOptionChange"
          />
        </div>
      </div>
    </template>
    <template v-slot:secondary-btn-prefix>
      <i class="fas fa-chevron-left"></i>
    </template>
    <template v-slot:footer>
      <Button @click="onClose" type="button" buttonStyle="text-secondary" v-if="saveTranslateCaptionState.status !== 'error'">
        {{ $t('common:button.cancel') }}
      </Button>
      <div v-if="saveTranslateCaptionState.status === 'error'" class="danger">
        {{ saveTranslateCaptionState.error?.message }}
      </div>
    </template>
  </Modal>
</template>

<script setup>
import { ref, computed, onBeforeMount } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute, useRouter } from 'vue-router';
import { createCaptionDraft, getCaptionDraftList } from '@/modules/videoCaption/services';
import { getSupportedTranslateLanguages } from '@/modules/captionProfile/services';
import Button from '@/modules/shared/components/atoms/button/Button.vue';
import Modal from '@/modules/shared/components/organisms/modal/Modal.vue';
import TranslateCaptionSelector from '@/modules/videoCaption/components/organisms/translateCaptionSelector/TranslateCaptionSelector.vue';
import Field from '@/modules/shared/components/molecules/field/Field.vue';
import CaptionStatusRadioGroup from '@/modules/captionProfile/components/molecules/captionStatusRadioGroup/CaptionStatusRadioGroup.vue';
import FormMultiSelect from '@/modules/shared/components/molecules/formMultiSelect/FormMultiSelect.vue';
import { setStateSavingStatusByType } from '@/modules/shared/utils/stateManagement';
import { formatErrorObject } from '@/modules/shared/utils/errorFormatter';
import { useStore } from '../../../store/hooks';

const props = defineProps({
  onClose: {
    type: Function,
  },
  // prefill and fixed
  caption: {
    type: Object,
  },
});

const emit = defineEmits(['toInit']);

const { t } = useI18n();
const store = useStore();
const router = useRouter();
const route = useRoute();

const { videoKey } = route.params;
const filter = computed(() => route.query);
const total = computed(() => store.state.captionList.captionList?.total);
const revisionKey = computed(() => store.state.video.currentVideo?.vod?.revisionKey);
const currentVideo = computed(() => store.state.video?.currentVideo);

const allCaptions = ref([]);
const translatableCaptions = computed(() => {
  if (allCaptions.value) {
    return allCaptions.value.filter(
      (caption) => (caption.processingStatus === 'ready' || !caption.processingStatus),
    );
  }
  return [];
});

const selectedCaption = ref(props.caption);
const languagesList = ref([]);

// to language
const translateToLanguages = ref([]);
const onToLanguagesChange = (value) => {
  translateToLanguages.value = value;
};

// caption status
const selectedStatusOption = ref('draft');
const onStatusOptionChange = (value) => {
  selectedStatusOption.value = value;
};

// modal page change state
const pageName = ['translate', 'status'];
const pageIndex = ref(0);
const lastPageIndex = ref(pageName.length - 1);
const saveTranslateCaptionState = ref(setStateSavingStatusByType());

const onSelectCaption = (value) => {
  selectedCaption.value = value;
};

const onSubmit = async () => {
  try {
    saveTranslateCaptionState.value = setStateSavingStatusByType({ type: 'saving' });
    const captionDrafts = translateToLanguages.value.map((language) => ({
      languageCode: language.code,
      // no caption draft key means it is the draft, use its key
      sourceCaptionDraftKey: selectedCaption.value.captionDraftKey ?? selectedCaption.value.key,
      captionProcessingType: {
        type: 'translate',
        service: 'google',
      },
    }));
    const payload = {
      publishStatusSetting: selectedStatusOption.value,
      captionDrafts,
    };
    const response = await createCaptionDraft(videoKey, revisionKey.value, payload);
    if (response.status === 200) {
      // reload
      await store.dispatch('reloadCaptionList', {
        ...(filter.value || {}),
        videoKey: currentVideo.value?.key,
      });

      // go to last page if not on last page
      const { lastPage } = store.state.captionList;
      if (Number(filter.value.page) !== Number(lastPage)) {
        router.push({ path: route.path, query: { page: lastPage } });
      }
      props.onClose();
    }
    saveTranslateCaptionState.value = setStateSavingStatusByType({ type: 'success' });
  } catch (err) {
    saveTranslateCaptionState.value = setStateSavingStatusByType({
      type: 'success',
      error: formatErrorObject(err, 'Caption'),
    });
  }
};

const onNextPage = () => {
  if (pageIndex.value !== lastPageIndex.value) {
    pageIndex.value += 1;
  }
};
const onBack = () => {
  pageIndex.value -= 1;
};

const primaryButtonText = computed(() => {
  if (pageIndex.value !== lastPageIndex.value) {
    return t('common:button.next');
  }

  return t('video.subtitle.modal.translate.translate');
});
const onClickPrimaryButton = () => {
  if (pageIndex.value === lastPageIndex.value) {
    onSubmit();
  } else {
    onNextPage();
  }
};

const onClickSecondaryButton = () => {
  if (pageIndex.value === 0) {
    emit('toInit');
  } else {
    onBack();
  }
};

const getAllCaptions = async () => {
  try {
    const response = await getCaptionDraftList(videoKey, { limit: total.value });
    allCaptions.value = response.data.data;
  } catch (err) {
    // console.log(err);
  }
};

const getLanguagesList = async () => {
  try {
    const response = await getSupportedTranslateLanguages();
    languagesList.value = response.data;
  } catch (err) {
    // console.log(err);
  }
};

onBeforeMount(() => {
  getAllCaptions();
  getLanguagesList();
});
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/global-variables';

.modal-body {
  display: flex;
  flex-direction: column;
  font-size: $font-level-6;
  color: $grey-800;
}

.translate-from {
  width: 400px;
  font-size: $font-level-6;
  margin-bottom: 32px;
}

.translate-to {
  font-size: $font-level-6;
}

.heading {
  margin-bottom: 32px;
}

</style>
