import type { RouteRecordRaw } from 'vue-router';
import store from '@/store';
import project from '@/modules/shared/store/project';
import { user } from '@/modules/user';
import { bumperList } from '@/modules/bumper';
import bumperDisplay from '../store/bumperDisplay';
import bumperDisplayList from '../store/bumperDisplayList';

export function createBumperDisplayRoutes(): RouteRecordRaw[] {
  return [
    {
      path: 'bumper-insertion',
      component: () => import(/* webpackChunkName: "ManageBumperDisplayChrome" */ '../layouts/ManageBumperDisplayChrome.vue'),
      beforeEnter: (to, from, next) => {
        if (!store.hasModule('bumperDisplay')) {
          store.registerModule('bumperDisplay', bumperDisplay);
        }
        if (!store.hasModule('bumperList')) {
          store.registerModule('bumperList', bumperList);
        }
        if (!store.hasModule('bumperDisplayList')) {
          store.registerModule('bumperDisplayList', bumperDisplayList);
        }
        if (!store.hasModule('project')) {
          store.registerModule('project', project);
        }
        if (!store.hasModule('user')) {
          store.registerModule('user', user);
        }
        next();
      },
      children: [
        {
          path: '',
          name: 'manageBumperDisplay',
          component: () => import(/* webpackChunkName: "ManageBumperSchedule" */ '@/modules/bumperDisplay/pages/ManageBumperDisplay.vue'),
        },
        {
          path: 'create',
          name: 'createBumperDisplay',
          components: {
            default: () => import(/* webpackChunkName: "ManageBumperSchedule" */ '@/modules/bumperDisplay/pages/ManageBumperDisplay.vue'),
            createBumper: () => import(/* webpackChunkName: "CreateBumperScheduleModal" */ '@/modules/bumperDisplay/components/organisms/createBumperDisplayModal/CreateBumperDisplayModal.vue'),
          },
        },
        {
          path: 'detail/:scheduleId',
          name: 'bumperDisplayDetail',
          components: {
            default: () => import(/* webpackChunkName: "ManageBumperSchedule" */ '@/modules/bumperDisplay/pages/ManageBumperDisplay.vue'),
            editBumper: () => import(/* webpackChunkName: "CreateBumperScheduleModal" */ '@/modules/bumperDisplay/components/organisms/bumperDisplaySlideOut/BumperDisplaySlideOut.vue'),
          },
        },
        {
          path: ':scheduleId',
          name: 'editBumperDisplay',
          components: {
            default: () => import(/* webpackChunkName: "ManageBumperSchedule" */ '@/modules/bumperDisplay/pages/ManageBumperDisplay.vue'),
            editBumper: () => import(/* webpackChunkName: "SetBumperScheduleModal" */ '@/modules/bumperDisplay/components/organisms/setBumperDisplayModal/SetBumperDisplayModal.vue'),
          },
        },
      ],
    },
  ];
}
