import type { RouteRecordRaw } from 'vue-router';
import { user } from '@/modules/user';
import store from '@/store';
import teamFeatures from '@/modules/shared/store/teamFeatures';
import formUpload from '../store/formUpload';
import formUploadList from '../store/formUploadList';

export function createFormUploadRoutes(): RouteRecordRaw[] {
  return [
    {
      name: 'formUploadChrome',
      path: 'forms',
      component: () => import(/* webpackChunkName: "FormUploadChrome" */ '../layouts/FormUploadChrome.vue'),
      beforeEnter: (to, from, next) => {
        if (!store.hasModule('user')) {
          store.registerModule('user', user);
        }
        if (!store.hasModule('teamFeatures')) {
          store.registerModule('teamFeatures', teamFeatures);
        }
        if (!store.hasModule('formUpload')) {
          store.registerModule('formUpload', formUpload);
        }
        next();
      },
      children: [
        {
          name: 'formList',
          path: '',
          meta: { teamManagement: true },
          component: () => import(/* webpackChunkName: "ManageFormUpload" */ '@/modules/formUpload/pages/ManageFormUpload.vue'),
          beforeEnter: (to, from, next) => {
            if (!store.hasModule('formUploadList')) {
              store.registerModule('formUploadList', formUploadList);
            }
            next();
          },
        },
        {
          name: 'formEdit',
          path: ':id',
          meta: { teamManagement: true },
          component: () => import(/* webpackChunkName: "ManageFormEdit" */ '@/modules/formUpload/pages/ManageFormUploadEdit.vue'),
        },
      ],
    },
  ];
}
