<template>
  <Modal
    v-if="!isURLSigningKeyValidToDelete"
    size="small"
    :isShowModalFooter="false"
    @modalClose="onCloseModal">
    <template v-slot:body>
      <MessageBox
        iconClassName="fas fa-triangle-exclamation text-warning"
        :title="$t('urlSigningKey.removeInvalid.title')"
        :buttonTitle="$t('common:close')"
        :onClickButton="onCloseModal"
      >
        <template v-slot:description>
          <span v-html="getDescrptionInValidDelete()"></span>
        </template>
      </MessageBox>
    </template>
  </Modal>
  <!-- show confirm dialog -->
  <Modal
    v-if="isURLSigningKeyValidToDelete && deleteUrlSigningKeyState.status !== 'error'"
    size="small"
    :title="$t('urlSigningKey.remove.title') "
    :primaryButtonText="$t('common:button.delete')"
    primaryButtonStyle="danger"
    :onClickPrimaryButton="onDeleteUrlSigningKey"
    :secondaryButtonText="$t('common:button.cancel')"
    :onClickSecondaryButton="onCloseModal"
    @modalClose="onCloseModal"
  >
    <template v-slot:body>
      <form v-on:submit.prevent="onDeleteUrlSigningKey">
        <div class="warning-text" v-html="$t('urlSigningKey.remove.description')" />
        <p
          class="description-text"
          v-html="
            $t('urlSigningKey.remove.confirmDescription', {
              urlSigningKeyId: deleteUrlSigningKey.keyId,
            })
          "
        />
        <FormInput title="Key ID" v-model="confirmDeleteUrlSigningKeyId" />
      </form>
    </template>
  </Modal>
  <!-- error dialog -->
  <Modal
    v-if="isURLSigningKeyValidToDelete && deleteUrlSigningKeyState.status === 'error'"
    class="step-error"
    size="small"
    @modalClose="onCloseModal"
  >
    <template v-slot:body>
      <ErrorMessage
        :title="deleteUrlSigningKeyState.error?.title"
        :description="deleteUrlSigningKeyState.error?.description"
        :statusCode="deleteUrlSigningKeyState.error?.statusCode"
      />
    </template>
  </Modal>
</template>

<script>
import _ from 'lodash';
import { mapState } from 'vuex';
import Modal from '@/modules/shared/components/organisms/modal/Modal.vue';
import ErrorMessage from '@/modules/shared/components/atoms/errorMessage/ErrorMessage.vue';
import FormInput from '@/modules/shared/components/molecules/formInput/FormInput.vue';
import MessageBox from '@/modules/shared/components/molecules/messageBox/MessageBox.vue';

export default {
  props: {
    deleteUrlSigningKey: {
      required: true,
      type: Object,
    },
    onConfirmDeleted: {
      type: Function,
      default: () => {},
    },
    onCloseModal: {
      type: Function,
      default: () => {},
    },
  },
  components: {
    Modal,
    ErrorMessage,
    FormInput,
    MessageBox,
  },
  computed: {
    ...mapState({
      deleteUrlSigningKeyState: (state) => state.urlSigningKey.deleteUrlSigningKeyState,
    }),
    isValid() {
      if (this.isURLSigningKeyValidToDelete) {
        return this.confirmDeleteUrlSigningKeyId === this.deleteUrlSigningKey.keyId;
      }
      return false;
    },
    isURLSigningKeyValidToDelete() {
      if (this.deleteUrlSigningKey && this.deleteUrlSigningKey.domains) {
        return _.isEmpty(this.deleteUrlSigningKey.domains);
      }
      return false;
    },
  },
  data() {
    return {
      confirmDeleteUrlSigningKeyId: null,
    };
  },
  async created() {
    await this.$store.dispatch('destroyDeleteUrlSigningKey');
  },
  methods: {
    async onDeleteUrlSigningKey() {
      await this.$store.dispatch('deleteUrlSigningKey', this.deleteUrlSigningKey.id);
      if (!this.deleteUrlSigningKeyState.error) {
        this.onConfirmDeleted();
      }
    },
    getDescrptionInValidDelete() {
      const domains = _.get(this.deleteUrlSigningKey, 'domains', []);
      const domainList = _.map(domains, (domain) => domain.name);
      return this.$t('urlSigningKey.removeInvalid.description', {
        domainList: domainList.join(', '),
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/global-variables.scss';

.title {
  overflow: hidden;
  text-overflow: ellipsis;
}

.warning-text {
  /* padding-bottom: $spacing-12; */
}

.description-text {
  :deep(code) {
    color: #e83e8c;
  }
  margin-bottom: $spacing-base;
}

.btn-close-container {
  position: absolute;
  top: $spacing-base;
  right: $spacing-base;
  font-size: $font-level-4;
  cursor: pointer;
  z-index: 1;
}

:deep(.close-button) {
  margin-top: $spacing-24;
}

:deep(.modal-footer form) {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
</style>
