/* eslint-disable no-shadow */
import { formatErrorObject } from '@/modules/shared/utils/errorFormatter';
import { ActionContext } from 'vuex';
import { setStateLoadingStatusByType } from '@/modules/shared/utils/stateManagement';

// Types
import { CollectionType, CollectionTypeListState } from '@/modules/collection';
import { LoadingState } from '@/types/State.type';
import { RootState } from '@/store/type';
import { getCollectionTypeList } from '../services';

type CollectionTypeListContext = ActionContext<CollectionTypeListState, RootState>

const initialState = () => ({
  collectionTypeList: null,
  loadCollectionTypeListState: setStateLoadingStatusByType(),
});

const state: CollectionTypeListState = initialState();

const mutations = {
  setCollectionTypeList(state: CollectionTypeListState, collectionTypeList: CollectionType[]) {
    state.collectionTypeList = collectionTypeList;
  },
  setLoadCollectionTypeListState(state: CollectionTypeListState, loadingState: LoadingState) {
    state.loadCollectionTypeListState = setStateLoadingStatusByType(loadingState);
  },
  destroyCollectionTypeList(state: CollectionTypeListState) {
    Object.assign(state, initialState());
  },
};

const actions = {
  async loadCollectionTypeList({ commit }: CollectionTypeListContext) {
    try {
      commit('setLoadCollectionTypeListState', { type: 'loading' });
      const response = await getCollectionTypeList();
      commit('setCollectionTypeList', response.data?.data);
      commit('setLoadCollectionTypeListState', { type: 'success' });
    } catch (error) {
      commit('setLoadCollectionTypeListState', { type: 'error', error: formatErrorObject(error, 'Collection Type List') });
    }
  },
  destroyCollectionTypeList({ commit }: CollectionTypeListContext) {
    commit('destroyCollectionTypeList');
  },
};

export default {
  state,
  mutations,
  actions,
};
