import store from '@/store';
import type { RouteRecordRaw } from 'vue-router';
import collectionType from '../stores/collectionType';
import collectionTypeList from '../stores/collectionTypeList';

export default function createCollectionTypeRoutes(): RouteRecordRaw[] {
  return [{
    path: 'collection-type',
    component: () => import(/* webpackChunkName: "manageCollectionTypeChrome" */ '@/modules/collectionType/layouts/ManageCollectionTypeChrome.vue'),
    meta: { teamManagement: true },
    beforeEnter: (to, from, next) => {
      if (!store.hasModule('collectionType')) {
        store.registerModule('collectionType', collectionType);
      }
      if (!store.hasModule('collectionTypeList')) {
        store.registerModule('collectionTypeList', collectionTypeList);
      }
      next();
    },
    children: [
      {
        path: '',
        name: 'collectionType',
        component: () => import(/* webpackChunkName: "manageCollectionType" */ '@/modules/collectionType/pages/ManageCollectionType.vue'),
      },
      {
        path: 'create',
        name: 'createCollectionType',
        components: {
          default: () => import(/* webpackChunkName: "manageCollectionType" */ '@/modules/collectionType/pages/ManageCollectionType.vue'),
          modal: () => import(/* webpackChunkName: "createCollectionType" */ '@/modules/collectionType/components/organisms/createCollectionTypeModal/CreateCollectionTypeModal.vue'),
        },
      },
      {
        path: ':typeId',
        name: 'manageCollectionTypeDetails',
        component: () => import(/* webpackChunkName: "ManageCollectionTypeDetails.vue" */ '@/modules/collectionType/pages/ManageCollectionTypeDetails.vue'),
      },
      {
        path: ':typeId/field/:fieldId',
        name: 'editCollectionTypeField',
        components: {
          default: () => import(/* webpackChunkName: "ManageCollectionTypeDetails.vue" */ '@/modules/collectionType/pages/ManageCollectionTypeDetails.vue'),
          modal: () => import(/* webpackChunkName: "EditCollectionTypeFieldModal" */ '@/modules/collectionType/components/organisms/manageCollectionTypeFieldModal/ManageCollectionTypeFieldModal.vue'),
        },
      },
      {
        path: ':typeId/image-field/',
        name: 'manageCollectionTypeImageDetails',
        component: () => import(/* webpackChunkName: "ManageCollectionTypeDetails.vue" */ '@/modules/collectionType/pages/ManageCollectionTypeDetails.vue'),
      },
      {
        path: ':typeId/image-field/:fieldId',
        name: 'editCollectionTypeImageField',
        components: {
          default: () => import(/* webpackChunkName: "ManageCollectionTypeDetails.vue" */ '@/modules/collectionType/pages/ManageCollectionTypeDetails.vue'),
          modal: () => import(/* webpackChunkName: "ManageCustomImageFieldsModal" */ '@/modules/collectionType/components/organisms/manageCollectionTypeCustomImageFieldsModal/ManageCollectionTypeCustomImageFieldsModal.vue'),
        },
      },
    ],
  }];
}
