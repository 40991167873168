import qs from 'qs';
import { axiosAPI } from '@/plugins/axios';
import type { Filter, ListApiResponse } from '@/types/Axios.type';
import type { BumperDisplay } from '../types';

export const loadBumperDisplays = (filter: Filter) => {
  const query = qs.stringify(filter, { addQueryPrefix: true, skipNulls: true });
  return axiosAPI.get<ListApiResponse<BumperDisplay[]>>(`/bumper-displays${query}`);
};

export function loadProjectBumperDisplay(projectKey: string) {
  return axiosAPI.get(`/projects/${projectKey}/bumper-contents`);
}

export const createBumperDisplays = (payload: BumperDisplay) => axiosAPI.post('/bumper-displays', payload);
export const loadBumperDisplayDetail = (id: string) => axiosAPI.get<BumperDisplay>(`/bumper-displays/${id}`);
export const deleteBumperDisplayById = (id: string) => axiosAPI.delete(`/bumper-displays/${id}`);
export const saveBumperDisplay = (id: string, payload: BumperDisplay) => axiosAPI.put(`/bumper-displays/${id}`, payload);
