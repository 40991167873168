import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "description" }
const _hoisted_2 = { class: "highlight-name" }
const _hoisted_3 = { class: "description" }

import { useStore } from '@/modules/projectVideoList/store/hooks';
import RadioButtonGroup from '@/modules/shared/components/molecules/radioButtonGroup/RadioButtonGroup.vue';
import SelectOption from '@/modules/shared/components/atoms/selectOption/SelectOption.vue';
import {
  computed,
  ref, toRefs, watch,
} from 'vue';
import { useI18n } from 'vue-i18n';
import { Project } from '@/modules/shared/types/project.type';
import { useRoute } from 'vue-router';

interface RecoverVideoMethodSelectorProps {
  selectedMethod: string;
  onChangeSelectedMethod: (choice: string) => void;
  onSelectRestoreProject: (project: Project | null) => void;
  deletedProject: Project | null;
  disabled?: boolean;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'RecoverVideoMethodSelector',
  props: {
    selectedMethod: { default: 'restoreToCurrent' },
    onChangeSelectedMethod: { type: Function, default: () => {} },
    onSelectRestoreProject: {},
    deletedProject: { default: null },
    disabled: { type: Boolean, default: false }
  },
  setup(__props: any) {

const { t } = useI18n();

const recoverVideoMethodOptions = [
  {
    label: t('project.deleteVideos.recover.restoreCurrent.title'),
    value: 'restoreToCurrent',
    subFieldName: 'option1Sub',
  },
  {
    label: t('project.deleteVideos.recover.restoreAnother.title'),
    value: 'restoreToAnother',
    subFieldName: 'option2Sub',
  },
];

const store = useStore();
const route = useRoute();

const props = __props;

const {
  selectedMethod,
  onChangeSelectedMethod,
  onSelectRestoreProject,
  deletedProject,
  disabled,
} = toRefs(props);

const selectedProject = ref<Project | null>(null);
const projectList = computed(() => store.state.projectList.projectList);
const projectName = computed(() => deletedProject.value?.name);
const teamName = computed(() => route.params.teamName);

const projectListDropdownOptions = computed(() => {
  if (!projectList.value) return [];
  return projectList.value;
});

const onChangeChoice = (choice: string) => {
  onChangeSelectedMethod.value(choice);
};

watch(selectedProject, (newSelectedProject) => {
  onSelectRestoreProject.value(newSelectedProject);
});


return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(RadioButtonGroup, {
    modelValue: _unref(selectedMethod),
    defalutValue: _unref(selectedMethod),
    options: recoverVideoMethodOptions,
    disabled: _unref(disabled),
    "onUpdate:modelValue": onChangeChoice
  }, {
    option1Sub: _withCtx(() => [
      _createElementVNode("p", _hoisted_1, [
        _createTextVNode(_toDisplayString(_unref(t)('project.deleteVideos.recover.restoreCurrent.description_1')) + " ", 1),
        _createElementVNode("span", _hoisted_2, _toDisplayString(projectName.value), 1),
        _createTextVNode(" " + _toDisplayString(_unref(t)('project.deleteVideos.recover.restoreCurrent.description_2')) + " ", 1),
        _createVNode(_component_router_link, {
          to: `/${teamName.value}/projects/deleted`,
          class: "deleted-project"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_unref(t)('project.deleteVideos.recover.restoreCurrent.description_3')), 1)
          ]),
          _: 1
        }, 8, ["to"]),
        _createTextVNode(_toDisplayString(_unref(t)('project.deleteVideos.recover.restoreCurrent.description_4')), 1)
      ])
    ]),
    option2Sub: _withCtx(() => [
      _createElementVNode("p", _hoisted_3, _toDisplayString(_unref(t)('project.deleteVideos.recover.restoreAnother.description')), 1),
      _createVNode(SelectOption, {
        class: "select-project-dropdown",
        label: "name",
        modelValue: selectedProject.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((selectedProject).value = $event)),
        placeholder: _unref(t)('project.deleteVideos.recover.restoreAnother.placeholder'),
        options: projectListDropdownOptions.value,
        maxWidth: 'initial'
      }, null, 8, ["modelValue", "placeholder", "options"])
    ]),
    _: 1
  }, 8, ["modelValue", "defalutValue", "disabled"]))
}
}

})