<template>
  <Modal
    size="small"
    :title="t('project.manage.members.permissionEdit.title')"
    :primaryButtonText="!isError && !isSaving ? t('project.manage.members.permissionEdit.button') : t('common:button.editing')"
    :isDisabledPrimaryButton="isError || isSaving"
    :isSaving="isSaving"
    :onClickPrimaryButton="onSubmit"
    :secondaryButtonText="t('common:button.cancel')"
    :onClickSecondaryButton="onClose"
    @modalClose="onClose">
    <template v-slot:body class="overflow">
      <form v-on:submit.prevent="onSubmit" novalidate>
        <div v-if="!isError">
          <div class="select-option-container">
            <label>
              {{ t('project.manage.members.permissionEdit.subTitle') }}
              <span class="highlight-user">{{ memberName }}</span>
            </label>
            <SelectOption
              :placeholder="t('project.manage.members.permissionPlaceholder')"
              :modelValue="selectedRoleMember"
              :options="roleOptions"
              label="key"
              @update:modelValue="(newValue) => (selectedRoleMember = newValue)"
            />
          </div>
        </div>
        <ErrorMessage
          v-if="isError"
          :title="t('project.manage.members.permissionEdit.error')"
          :description="t('response:retry')"
          :statusCode="editProjectMembershipErrorCode"
        />
      </form>
    </template>
  </Modal>
</template>

<script lang="ts" setup>
import type { ProjectMember } from '@/modules/projectMember/types';
import { getMembershipRolesOptions } from '@/modules/projectMember/utils';
import _ from 'lodash';
import {
  computed, onMounted, ref, toRefs,
} from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';
import type { ObjectWithStringValue } from '@/types/Object.type';
import { getQueryAsString } from '@/modules/shared/utils/query';
import SelectOption from '@/modules/shared/components/atoms/selectOption/SelectOption.vue';
import Modal from '@/modules/shared/components/organisms/modal/Modal.vue';
import ErrorMessage from '@/modules/shared/components/atoms/errorMessage/ErrorMessage.vue';
import { useStore } from '../../../store/hooks';

interface ProjectMembershipChangeRoleModalProps {
  member: ProjectMember | null;
  onCloseModal: () => void;
}

const props = defineProps<ProjectMembershipChangeRoleModalProps>();
const { member, onCloseModal } = toRefs(props);

const emit = defineEmits<{success: []}>();

const roleOptions = getMembershipRolesOptions();

const { t } = useI18n();
const store = useStore();
const route = useRoute();

const selectedRoleMember = ref<ObjectWithStringValue | undefined>(undefined);

const projectKey = computed(() => getQueryAsString(route.params, 'projectKey'));
const editProjectMembershipState = computed(() => store.state.projectMember.editProjectMembershipState);
const isSaving = computed(() => editProjectMembershipState.value.status === 'saving');
const isError = computed(() => editProjectMembershipState.value.status === 'error');
const memberName = computed(() => _.get(member.value, 'account.name', ''));
const editProjectMembershipErrorCode = computed(() => {
  if (editProjectMembershipState.value.error) {
    return _.get(editProjectMembershipState.value, 'error.response.status', null);
  }
  return null;
});

async function onSubmit() {
  if (_.isNil(member.value) || _.isNil(selectedRoleMember.value)) {
    return;
  }

  const { accountId } = member.value;
  await store.dispatch('editProjectMembership', {
    projectKey: projectKey.value,
    accountId,
    role: {
      projectRole: selectedRoleMember.value.value,
    },
  });
  emit('success');
  onCloseModal.value();
}

async function onClose() {
  await store.dispatch('clearEditProjectMembershipState');
  onCloseModal.value();
}

onMounted(() => {
  if (!_.isNil(member.value)) {
    selectedRoleMember.value = roleOptions.find((option) => option.value === member.value?.projectRole);
  }
});
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/global-variables';

.select-option-container {
  margin-bottom: $spacing-base;
}

// remove after modal body spacing logic change
:deep(.select-option-wrapper) {
  margin-top: $spacing-8;
}
.highlight-user {
  font-weight: $font-weight-bold;
  color: $grey-700;
}

.button-container {
  display: flex;
  width: 100%;

  :deep(.button) {
    text-transform: capitalize;
  }
}
</style>
