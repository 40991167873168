<script setup lang="ts">
import { toRefs } from 'vue';
import Button from '@/modules/shared/components/atoms/button/Button.vue';

const props = defineProps<{
  onCloseModal:() => void;
}>();

const { onCloseModal } = toRefs(props);
</script>

<template>
  <div class="btn-close-container" data-test="modal-close-button">
    <Button
      dataTest="modal-close-btn"
      type="button"
      @click.stop="onCloseModal"
      buttonStyle="text-secondary"
    >
      <template v-slot:icon-prefix>
        <i class="fas fa-xmark"></i>
      </template>
    </Button>
  </div>
</template>

<style scoped lang="scss">
@import '~@/assets/scss/global-variables';

.btn-close-container {
  font-size: $icon-size-16;
  color: $grey-600;
  position: absolute;
  top: 12px;
  right: 12px;
  cursor: pointer;
  z-index: 2;

  :deep(.button).disabled,
  :deep(.button:disabled) {
    background-color: transparent;
    border-color: transparent;
    color: #bcbec0;
  }
}
</style>
