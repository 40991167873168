// @ts-nocheck
import _ from 'lodash';
import i18n from '@/plugins/lang/i18n';

import { IError } from '@/types/State.type';

const findKeyErrorByName = (errorResponse: any, fieldName: string) => _.some(errorResponse, (valueObject, key) => key.startsWith(fieldName) && _.some(valueObject, (value) => value));

/**
 * A function that return error object that include status code and message.
 * @param {*} error
 * @param {*} itemType a type of item that use to show on error message
 * @param {*} customErrorMessage is set of custom error messages (Example data: { 404: 'Project not found', 422: 'Project name is already in used' })
 */
const formatErrorObject = (error: any, itemType: string, customErrorMessage: any = {}): IError => {
  const statusCode = _.get(error, 'response.status', null);
  const errorResponseData = _.get(error, 'response.data', {});

  const defaultError: { [key: number]: string } = {
    403: i18n.global.t('response:error403.title'),
    404: i18n.global.t('response:error404.title', { item: itemType }),
    422: i18n.global.t('response:error422.title'),
    500: i18n.global.t('response:error500.title'),
  };

  return {
    statusCode,
    message: customErrorMessage[statusCode] || defaultError[statusCode] || i18n.global.t('response:loadingFailed.title'),
    errorFields: Object.keys(errorResponseData),
  };
};

export {
  findKeyErrorByName,
  formatErrorObject,
};
