<template>
  <div
    class="radio-choice"
    :class="{'checked' : modelValue, 'disabled': disabled, [size]: size}"
    @click="onUpdateValue"
    :data-test="dataTest"
  >
    <RadioButton
      :modelValue="modelValue"
      :disabled="disabled" />
    <div :class="`radio-content ${size}`">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import RadioButton from '@/modules/shared/components/atoms/radioButton/RadioButton.vue';

export default {
  components: {
    RadioButton,
  },
  emits: ['update:modelValue', 'update:data'],
  props: {
    size: {
      type: String, // 'big', 'small'
      required: false,
      default: () => 'big',
    },
    modelValue: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    // data such as presetData
    data: {
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    onChange: {
      type: Function,
      default: () => {},
    },
    dataTest: {
      type: String,
      required: false,
    },
  },
  methods: {
    onUpdateValue() {
      this.$emit('update:modelValue', !this.modelValue);
      this.$emit('update:data', this.data);
      this.onChange();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/global-variables.scss';

:slotted(.badge-group) {
  display: none;
}

.radio-choice {
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  flex: 1;
  margin-top: 4px;
  min-width: 0;
  padding: $spacing-base * 0.75 $spacing-base * 1.25;
  display: flex;
  justify-content: flex-start;
  background-color: transparent;
  border: 1px solid #C5CBD6;
  border-radius: 5px;
  transition: all .2s ease-in-out;

  &.checked {
    :slotted(.badge-group) {
      display: block;
    }
  }

  &:last-child {
    margin-bottom: 0;
  }

  &:hover, &.checked:not(.disabled) {
    background-color: rgba(0, 166, 237, 0.05);
    border-color: $ci-primary;
    cursor: pointer;
  }

  &.disabled {
    background-color: #F6F7F8;
    cursor: not-allowed;
    border: 1px solid #EBEDEF;
    :slotted(*) {
      color: #D5D8DD;
    }
  }

  .radio-btn-wrapper {
    margin-top: 2px;
  }
}

:slotted(.title) {
  color: $grey-800;
  //font-weight: $font-weight-bold;
  line-height: 1.2;
  //font-size: $font-size-base * 1.25;
  font-size: $font-level-5;
}

.radio-content {
  //margin-left: 26px;
  flex: 1 1 calc(100% - 26px);
  min-width: 0;
  align-items: center;

  .description {
    margin-bottom: 4px;
  }

  .badge-group {
    margin-top: $spacing-base * 0.75;
  }

  &.small {
    margin-left: 16px;

    :slotted(.title) {
      font-size: $font-size-base ;
    }
  }
}

:slotted(.options-container) {
  margin: $spacing-base * 0.5 0 $spacing-base * 0.75 0;

  .option {
    margin-bottom: $spacing-base * 0.5;
    max-width: 100%;
    &:last-child {
      margin-bottom: 0;
    }
  }
}
</style>
