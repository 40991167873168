import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

import type { InviteProjectMembershipsState } from '@/modules/projectMember/types';
import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';
import cloneDeep from 'lodash/cloneDeep';
import { computed, watch, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';
import AdvancedInfoToast from '@/modules/shared/components/molecules/toasts/AdvancedInfoToast.vue';
import ErrorToast from '@/modules/shared/components/molecules/toasts/ErrorToast.vue';
import InfoToast from '@/modules/shared/components/molecules/toasts/InfoToast.vue';
import { useStore } from '../../../store/hooks';

interface InvitationResult {
  invitedToWorkspaceEmails: Array<string>;
  addedToProjectEmails: Array<string>;
  errorEmails: Array<string>;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'ProjectManageMemberToasts',
  setup(__props) {

const defaultInvitationResult: Readonly<InvitationResult> = Object.freeze({
  invitedToWorkspaceEmails: [],
  addedToProjectEmails: [],
  errorEmails: [],
});

const invitationResult = ref<InvitationResult>(cloneDeep(defaultInvitationResult));
const isMultipleInvitationResults = ref<boolean>(false);

const { t } = useI18n();
const store = useStore();
const route = useRoute();
const inviteProjectMembershipsState = computed<InviteProjectMembershipsState>(() => store.state.projectMember.inviteProjectMembershipsState);
const projectKey = computed(() => route.params.projectKey);

watch(inviteProjectMembershipsState, () => {
  if (
    inviteProjectMembershipsState.value.status === 'success'
    && 'response' in inviteProjectMembershipsState.value
    && !isNil(inviteProjectMembershipsState.value.response)
  ) {
    const { response } = inviteProjectMembershipsState.value;

    let hasPassedResult = false;
    let hasAddedResult = false;
    let hasFailedResult = false;

    invitationResult.value = response.reduce((previousValue, currentValue) => {
      if (currentValue.status === 'passed' && currentValue.account?.email) {
        previousValue.invitedToWorkspaceEmails.push(currentValue.account.email);
        hasPassedResult = true;
      }

      if (currentValue.status === 'added' && currentValue?.email) {
        previousValue.addedToProjectEmails.push(currentValue.email);
        hasAddedResult = true;
      }

      if (currentValue.status === 'failed') {
        previousValue.errorEmails.push(currentValue.account?.email ?? currentValue.email ?? '');
        hasFailedResult = true;
      }

      return previousValue;
    }, cloneDeep(defaultInvitationResult));

    isMultipleInvitationResults.value = [hasPassedResult, hasAddedResult, hasFailedResult]
      .filter((boolean) => boolean).length >= 2;

    store.dispatch('loadProjectManagement', projectKey.value);
  } else {
    invitationResult.value = cloneDeep(defaultInvitationResult);
    isMultipleInvitationResults.value = false;
  }
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (isMultipleInvitationResults.value)
      ? (_openBlock(), _createBlock(AdvancedInfoToast, {
          key: 0,
          messages: [
        ...(!_unref(isEmpty)(invitationResult.value.invitedToWorkspaceEmails) ? [{
          title: _unref(t)('invite.response.invited.title', { count: invitationResult.value.invitedToWorkspaceEmails.length }),
          description: _unref(t)('invite.response.invited.description', { count: invitationResult.value.invitedToWorkspaceEmails.length }),
        }] : []),
        ...(!_unref(isEmpty)(invitationResult.value.addedToProjectEmails) ? [{
          title: _unref(t)('invite.response.addedToProject.title', { count: invitationResult.value.addedToProjectEmails.length }),
          description: invitationResult.value.addedToProjectEmails.join(', '),
        }] : []),
        ...(!_unref(isEmpty)(invitationResult.value.errorEmails) ? [{
          title: _unref(t)('invite.response.failed.title', { count: invitationResult.value.errorEmails.length }),
          description: invitationResult.value.errorEmails.join(', '),
        }] : []),
      ]
        }, null, 8, ["messages"]))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          (invitationResult.value.invitedToWorkspaceEmails.length)
            ? (_openBlock(), _createBlock(InfoToast, {
                key: 0,
                title: _unref(t)('invite.response.invited.title', { count: invitationResult.value.invitedToWorkspaceEmails.length }),
                description: _unref(t)('invite.response.invited.description', { count: invitationResult.value.invitedToWorkspaceEmails.length })
              }, null, 8, ["title", "description"]))
            : _createCommentVNode("", true),
          (invitationResult.value.addedToProjectEmails.length)
            ? (_openBlock(), _createBlock(InfoToast, {
                key: 1,
                title: _unref(t)('invite.response.addedToProject.title', { count: invitationResult.value.addedToProjectEmails.length }),
                description: invitationResult.value.addedToProjectEmails.join(', ')
              }, null, 8, ["title", "description"]))
            : _createCommentVNode("", true),
          (invitationResult.value.errorEmails.length)
            ? (_openBlock(), _createBlock(ErrorToast, {
                key: 2,
                title: _unref(t)('invite.response.failed.title', { count: invitationResult.value.errorEmails.length }),
                description: invitationResult.value.errorEmails.join(', ')
              }, null, 8, ["title", "description"]))
            : _createCommentVNode("", true)
        ], 64)),
    (inviteProjectMembershipsState.value.status === 'error')
      ? (_openBlock(), _createBlock(ErrorToast, {
          key: 2,
          title: _unref(t)('response:error500.title')
        }, null, 8, ["title"]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})