import type { RouteRecordRaw } from 'vue-router';
import store from '@/store';
import teamFeatures from '@/modules/shared/store/teamFeatures';
import { user } from '@/modules/user';
import badWordList from '../store/badWordList';
import badWordListItem from '../store/badWordListItem';

export function createBadWordListRoutes(): RouteRecordRaw[] {
  return [
    {
      path: 'badword-lists',
      component: () => import(/* webpackChunkName: "manageBadWordListsChrome" */ '../layouts/ManageBadWordListsChrome.vue'),
      meta: { teamManagement: true },
      beforeEnter: (to, from, next) => {
        if (!store.hasModule('badWordList')) {
          store.registerModule('badWordList', badWordList);
        }
        if (!store.hasModule('badWordListItem')) {
          store.registerModule('badWordListItem', badWordListItem);
        }
        if (!store.hasModule('user')) {
          store.registerModule('user', user);
        }
        if (!store.hasModule('teamFeatures')) {
          store.registerModule('teamFeatures', teamFeatures);
        }
        next();
      },
      children: [
        {
          path: '',
          name: 'badwordLists',
          component: () => import(/* webpackChunkName: "manageBadWordLists" */ '../pages/ManageBadWordLists.vue'),
        },
        {
          path: 'create',
          components: {
            default: () => import(/* webpackChunkName: "manageBadWordLists" */ '../pages/ManageBadWordLists.vue'),
            createBadWordListsItem: () => import(/* webpackChunkName: "createBadWordListsItem" */ '../components/organisms/createBadWordListsItemModal/CreateBadWordListsItemModal.vue'),
          },
        },
        {
          path: ':badwordListsItemId',
          name: 'badwordListsItemDetail',
          component: () => import(/* webpackChunkName: "manageBadWordListsItem" */ '@/modules/badWordList/pages/ManageBadWordListsItem.vue'),
        },
      ],
    },
  ];
}
