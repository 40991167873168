/* eslint no-shadow: ["error", { "allow": ["state"] }] */
/* eslint-disable object-curly-newline */
import _ from 'lodash';
import axios from 'axios';
import { uploadImageToStorage, saveCustomImage } from '@/modules/videoDetail/services';
import { updateCollectionCustomImages } from '@/modules/collection';
import { ActionContext } from 'vuex';
import { RootState } from '@/store/type';
import { CustomimageUploadInfo, CustomImageUploadPayload, CustomImageUploadState } from '@/modules/customImageField';

type CustomImageUploadActionContext = ActionContext<CustomImageUploadState, RootState>

const initialState = (): CustomImageUploadState => ({
  customImages: {},
});

const state = initialState();

const getters = {
  customImages: (state: CustomImageUploadState) => state.customImages,
};

const mutations = {
  updateCustomImageByKey(state: CustomImageUploadState, { imageId, uploadingInfo } : { imageId: string, uploadingInfo: CustomimageUploadInfo}) {
    state.customImages[imageId] = { ...state.customImages[imageId], ...uploadingInfo };
  },
  setUploadErrorStateByImageId(state: CustomImageUploadState, { imageId }: { imageId: string }) {
    state.customImages[imageId] = { ...state.customImages[imageId], state: 'error' };
  },
  cancelCustomImageUpload(state: CustomImageUploadState, { imageId }: { imageId: string}) {
    const { source } = state.customImages[imageId];
    source.cancel('Operation canceled by the user.');
  },
  clearUploadedImageEntryById(state: CustomImageUploadState, { imageId }: { imageId: string }) {
    delete state.customImages[imageId];
  },
  clearCustomImageUploadState(state: CustomImageUploadState) {
    Object.assign(state, initialState());
  },
};

const actions = {
  setUploadCustomImageProgress({ commit }: CustomImageUploadActionContext, { imageId, progressEvent }: { imageId: string, progressEvent: ProgressEvent}) {
    if (!progressEvent) {
      return;
    }

    const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
    if (progress === 100) {
      const uploadingInfo = {
        state: 'uploading',
        progress,
        uploadedSize: progressEvent.loaded,
        totalSize: progressEvent.total,
        uploadCompleteTime: new Date(),
      };
      commit('updateCustomImageByKey', { imageId, uploadingInfo });
    } else {
      const uploadingInfo = {
        state: 'uploading',
        progress,
        uploadedSize: progressEvent.loaded,
        totalSize: progressEvent.total,
      };
      commit('updateCustomImageByKey', { imageId, uploadingInfo });
    }
  },
  async retryUpload({ state, dispatch, commit }: CustomImageUploadActionContext, uploadProps: CustomImageUploadPayload) {
    const {
      projectKey, videoKey, imageId, imageType, contentCollectionId,
    } = uploadProps;

    const image = state.customImages[imageId];
    const uploadingInfo = {
      state: 'prepare',
      progress: 0,
    };
    commit('updateCustomImageByKey', { imageId, uploadingInfo });
    dispatch('uploadImage', {
      projectKey, videoKey, image, imageType, contentCollectionId,
    });
  },
  async uploadImage(storeObject: CustomImageUploadActionContext, uploadProps: CustomImageUploadPayload) {
    const { state, dispatch, rootState, commit } = storeObject;
    const {
      projectKey, videoKey, image, imageType, contentCollectionId,
    } = uploadProps;

    const { CancelToken } = axios;
    const source = CancelToken.source();
    state.customImages[image.id] = { ...image, state: 'prepare', source };
    const formData = new FormData();
    formData.append('imageFile', image.file);
    formData.append('customImageFieldId', image.id);
    formData.append('customImageFieldName', image.fieldName);
    formData.append('type', imageType);

    const onProgress = (progressEvent: ProgressEvent) => {
      dispatch('setUploadCustomImageProgress', { imageId: image.id, progressEvent });
    };

    try {
      const response = await uploadImageToStorage(projectKey, formData, onProgress, source.token);
      if (response.data) {
        let saveResponse;
        if (imageType === 'videos') {
          saveResponse = await saveCustomImage(videoKey, response.data);
        }
        if (imageType === 'collections') {
          saveResponse = await updateCollectionCustomImages(projectKey, contentCollectionId, response.data);
        }
        const newImageData = _.get(saveResponse, 'data', null);
        if (newImageData) {
          const customImagesData = _.cloneDeep(rootState.video?.customImages);
          if (typeof customImagesData === 'object') {
            customImagesData[image.fieldName] = newImageData;
            dispatch('setCustomImages', customImagesData);
          }
        }
        commit('updateCustomImageByKey', { imageId: image.id, uploadingInfo: { state: 'uploaded' } });
      }
    } catch (err) {
      commit('setUploadErrorStateByImageId', { imageId: image.id });
    }
  },
  cancelCustomImageUpload({ commit }: CustomImageUploadActionContext) {
    commit('cancelCustomImageUpload');
  },
  clearUploadedImageEntryById({ commit }: CustomImageUploadActionContext, { imageId }: { imageId: string }) {
    commit('clearUploadedImageEntryById', { imageId });
  },
  clearCustomImageUploadState({ commit }: CustomImageUploadActionContext) {
    commit('clearCustomImageUploadState');
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
