<template>
  <ErrorToast
    v-if="deleteProjectState.status === 'error' && deleteProjectState.error"
    :title="t('response:errorTitle')"
    :description="errorDescription"
    :duration="10000"
  />
  <Modal
    :title="t('project.deleteProject.title')"
    @modalClose="onClose"
    size="medium"
    :primaryButtonText
    :onClickPrimaryButton
    :onClickSecondaryButton
    :isShowSecondaryButton
    :secondaryButtonText="t('common:button.back')"
    primaryButtonStyle="danger"
    :isDisabledPrimaryButton
  >
    <template v-slot:body>
      <ChooseWhatToDoWithVideo
        v-show="step === 1"
        v-model="deleteProjectData"
      />
      <ConfirmDeletingProject
        v-show="step === 2"
        v-model="confirmDeleteText"
        :deleteProjectData
        :onConfirmDeleteProject
      />
    </template>
    <template v-slot:primary-btn-suffix v-if="step === 1">
      <i class="far fa-chevron-right" />
    </template>
    <template v-slot:secondary-btn-prefix v-if="step === 2">
      <i class="far fa-chevron-left" />
    </template>
    <template v-slot:footer v-if="step === 2">
      <Button
        type="button"
        buttonStyle="text-secondary"
        @click="onClose"
      >
        {{t('common:button.cancel')}}
      </Button>
    </template>
  </Modal>
</template>

<script setup lang="ts">
import ErrorToast from '@/modules/shared/components/molecules/toasts/ErrorToast.vue';
import {
  computed, onMounted, ref, toRefs,
} from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import isEmpty from 'lodash/isEmpty';
import { setStateLoadingStatusByType } from '@/modules/shared/utils/stateManagement';
import type { StatusState } from '@/types/State.type';
import Modal from '@/modules/shared/components/organisms/modal/Modal.vue';
import Button from '@/modules/shared/components/atoms/button/Button.vue';
import { formatErrorObject } from '@/modules/shared/utils/errorFormatter';
import { useStore } from '../../../store/hooks';
import ChooseWhatToDoWithVideo from '../../molecules/deleteProjectComponents/ChooseWhatToDoWithVideo.vue';
import ConfirmDeletingProject from '../../molecules/deleteProjectComponents/ConfirmDeletingProject.vue';
import type { DeleteProjectData } from '../../../types';
import { deleteProject, moveVideos } from '../../../services';

interface DeleteProjectModalProps {
  onClose: () => void;
}

const props = defineProps<DeleteProjectModalProps>();
const { onClose } = toRefs(props);

const store = useStore();
const router = useRouter();
const { t } = useI18n();

const step = ref<1 | 2>(1);
const confirmDeleteText = ref<string>('');
const deleteProjectData = ref<DeleteProjectData>({
  actionWithVideos: 'moveVideos',
  destinationProject: null,
});
const deleteProjectState = ref<StatusState>(setStateLoadingStatusByType());

const projectList = computed(() => store.state.projectList.projectList ?? []);
const projectName = computed(() => store.state.project.currentProject?.name ?? '');
const projectKey = computed(() => store.state.project.currentProject?.key ?? '');
const videoCount = computed(() => store.state.project.currentProject?.videoCount ?? 0);
const webhookList = computed(() => store.state.webhookList.webhookList);

const willMoveVideos = computed(() => deleteProjectData.value.actionWithVideos === 'moveVideos');
const destinationProjectKey = computed(() => deleteProjectData.value.destinationProject?.key ?? '');

const isDisabledPrimaryButton = computed(() => {
  if (deleteProjectState.value.status === 'loading') {
    return true;
  }

  if (step.value === 1 && deleteProjectData.value.actionWithVideos === 'moveVideos' && !deleteProjectData.value.destinationProject) {
    return true;
  }

  return step.value === 2 && confirmDeleteText.value !== `delete ${projectName.value}`;
});

const primaryButtonText = computed(() => {
  if (step.value === 1) {
    return t('common:button.next');
  }

  if (!videoCount.value) {
    return t('project.deleteProject.step2.button.deleteProjectNoVideos');
  }

  const tParams = { count: videoCount.value };

  if (deleteProjectData.value.actionWithVideos === 'moveVideos') {
    return t(`project.deleteProject.step2.button.deleteAndMoveVideos${videoCount.value > 1 ? '_plural' : ''}`, tParams);
  }

  return t(`project.deleteProject.step2.button.deleteProject${videoCount.value > 1 ? '_plural' : ''}`, tParams);
});

const errorDescription = computed(() => {
  const { error } = deleteProjectState.value;
  if (error?.statusCode === 409) {
    return t('response:deleteProjectError.delete-video-0001');
  }

  if (error) {
    return `${t('response:deleteProjectError.title')} (Error code: ${error.statusCode})`;
  }

  return '';
});

const isShowSecondaryButton = computed(() => {
  if (!videoCount.value) {
    return false;
  }

  return step.value === 2;
});

function onClickSecondaryButton() {
  if (!videoCount.value) {
    return undefined;
  }

  if (step.value === 2) {
    step.value = 1;
  }

  return undefined;
}

async function onConfirmDeleteProject() {
  if (isDisabledPrimaryButton.value) {
    return;
  }

  deleteProjectState.value = setStateLoadingStatusByType({ type: 'loading' });

  try {
    if (willMoveVideos.value) {
      await moveVideos({
        sourceProjectKey: projectKey.value,
        destinationProjectKey: destinationProjectKey.value,
      });
    }

    await deleteProject(projectKey.value);

    store.commit('setIsProjectDeleted', true);
    setTimeout(() => store.commit('setIsProjectDeleted', false), 5000);

    deleteProjectState.value = setStateLoadingStatusByType({ type: 'success' });
    await router.push({ name: 'projectList' });
  } catch (error) {
    console.error(error);
    deleteProjectState.value = setStateLoadingStatusByType({
      type: 'error',
      error: formatErrorObject(error, 'Delete Project'),
    });

    setTimeout(() => onClose.value(), 100);
  }
}

function onClickPrimaryButton() {
  if (step.value === 1) {
    step.value = 2;
  }

  onConfirmDeleteProject();
}

onMounted(() => {
  if (isEmpty(projectList.value)) {
    store.dispatch('loadProjectList');
  }

  if (isEmpty(webhookList.value)) {
    store.dispatch('loadWebhookList');
  }

  if (!videoCount.value) {
    step.value = 2;
    deleteProjectData.value = {
      actionWithVideos: 'deleteVideos',
      destinationProject: null,
    };
  }
});
</script>
