import { axiosAPI } from '@/plugins/axios';
import type { ImportHttpUrlsPayload, ImportS3UrlsPayload } from '@/modules/videoImport/types/VideoImport.type';
import type { ListApiResponse } from '@/types/Axios.type';
import type { AddVideoSourcePayload } from '@/modules/videoSources/types/VideoSources.type';
import type {
  ImportBatchDetails,
  ImportBatchEntry,
  UpdateHttpBatchResponse,
  UpdateVideoSourceBatchResponse,
  VideoSourceS3,
  LoadImportBatchPayload,
  LoadImportBatchEntryPayload,
  RetryImportBatchEntryPayload,
  UpdateHttpBatchPayload,
  UpdateVideoSourceBatchPayload,
  CancelImportBatchEntryPayload,
  UpdateImportBatchHiddenStatusPayload,
  DownloadMappingDataPayload,
  CancelImportBatchPayload,
  DeleteImportedVideosPayload,
} from '@/modules/videoImport/types/VideoImportBatchEntry.type';

export function loadImportBatch(payload: LoadImportBatchPayload) {
  const { projectKey, importBatchId } = payload;
  return axiosAPI.get<ImportBatchDetails>(`/projects/${projectKey}/import-batches/${importBatchId}`);
}

export function loadImportBatchEntry(payload: LoadImportBatchEntryPayload) {
  const {
    projectKey, importBatchId, page = 1, limit = 20, statusTab, statusTags, sourceUrl,
  } = payload;

  return axiosAPI.get<ListApiResponse<ImportBatchEntry>>(`/projects/${projectKey}/import-batches/${importBatchId}/import-batch-entries`, {
    params: {
      page,
      limit,
      sourceUrl,
      statusTab,
      statusTags: statusTags?.join(','),
    },
  });
}

export function retryImportBatchEntry(payload: RetryImportBatchEntryPayload) {
  const {
    projectKey, importBatchId, importBatchEntryIds, sourcePath,
  } = payload;

  if (importBatchEntryIds.length === 0) {
    return Promise.reject();
  }

  if (importBatchEntryIds.length === 1) {
    return axiosAPI.post(`/projects/${projectKey}/import-batches/${importBatchId}`
      + `/import-batch-entries/${importBatchEntryIds[0]}/retry`, {
      importBatchEntryIds,
      sourcePath,
    });
  }
  return axiosAPI.post(`/projects/${projectKey}/import-batches/${importBatchId}/import-batch-entries/retry`, {
    importBatchEntryIds,
  });
}

export function cancelImportBatchEntry(payload: CancelImportBatchEntryPayload) {
  const { projectKey, importBatchId, importBatchEntryIds } = payload;

  return axiosAPI.put(`/projects/${projectKey}/import-batches/${importBatchId}/import-batch-entries/cancel`, {
    importBatchEntryIds,
  });
}

export function deleteImportBatchEntry({ importBatchEntryIds }: { importBatchEntryIds: string[] }) {
  return axiosAPI.delete(`/import-batch-entry?importBatchEntryIds=${importBatchEntryIds.join(',')}`);
}

export function createS3StorageConnection(payload: AddVideoSourcePayload) {
  const {
    name, accessKeyId, secretAccessKey, region, endpoint, signatureVersion,
  } = payload;

  return axiosAPI.post<VideoSourceS3>('/external-storage-for-import-videos', {
    name,
    accessKeyId,
    secretAccessKey,
    region,
    endpoint,
    signatureVersion,
  });
}

export function loadS3StorageConnection({ storageId }: { storageId: string }) {
  return axiosAPI.get<VideoSourceS3>(`/external-storage-for-import-videos/${storageId}`);
}

export function loadVideoSourceList({ bucketName }: { bucketName: string }) {
  return axiosAPI.get<VideoSourceS3>(`external-storage-for-import-videos?q=${bucketName}`);
}

export function updateVideoSourceBatch(payload: UpdateVideoSourceBatchPayload) {
  const {
    projectKey, importBatchId, newS3ConnectionId, isRetry,
  } = payload;

  return axiosAPI.put<UpdateVideoSourceBatchResponse>(`/projects/${projectKey}/import-batches/${importBatchId}/update-s3-connection`, {
    connectionId: newS3ConnectionId,
    isRetry,
  });
}

export function updateHttpBatch(payload: UpdateHttpBatchPayload) {
  const {
    projectKey, importBatchId, newHttp, isRetry,
  } = payload;

  return axiosAPI.put<UpdateHttpBatchResponse>(`/projects/${projectKey}/import-batches/${importBatchId}/update-http-headers`, {
    httpHeaders: newHttp,
    isRetry,
  });
}

export function cancelImportBatch(payload: CancelImportBatchPayload) {
  const { projectKey, importBatchId } = payload;
  return axiosAPI.put(`/projects/${projectKey}/import-batches/${importBatchId}/cancel`);
}

export function deleteImportedVideos(payload: DeleteImportedVideosPayload) {
  const { projectKey, importBatchId } = payload;
  return axiosAPI.delete(`/projects/${projectKey}/import-batches/${importBatchId}/delete`);
}

export function updateImportBatchHiddenStatus(payload: UpdateImportBatchHiddenStatusPayload) {
  const { projectKey, importBatchId, isHidden } = payload;
  return axiosAPI.put(`/projects/${projectKey}/import-batches/${importBatchId}/hide`, {
    isHidden,
  });
}

export function downloadMappingData(payload: DownloadMappingDataPayload) {
  const { projectKey, importBatchId, type } = payload;
  return axiosAPI.get(`projects/${projectKey}/import-batches/${importBatchId}/download-mapping-data/?type=${type}`, {
    responseType: 'blob',
  });
}

export function importHttpUrls(payload: ImportHttpUrlsPayload) {
  return axiosAPI.post<ImportBatchDetails>('/import-batch-actions/import-http', payload);
}

export function importS3Urls(payload: ImportS3UrlsPayload) {
  return axiosAPI.post<ImportBatchDetails>('/import-batch-actions/import-s3', payload);
}
