import store from '@/store';
import type { RouteRecordRaw } from 'vue-router';
import customImageFieldList from '../store/customImageFieldList';
import customImageUpload from '../store/customImageUpload';

export default function createCustomImageFieldRoutes(): RouteRecordRaw[] {
  return [{
    path: 'custom-image-fields',
    component: () => import(/* webpackChunkName: "manageCustomImageFields" */ '@/modules/customImageField/layout/ManageCustomImageFieldsChrome.vue'),
    meta: { teamManagement: true },
    beforeEnter: (to, from, next) => {
      if (!store.hasModule('customImageFieldList')) {
        store.registerModule('customImageFieldList', customImageFieldList);
      }
      if (!store.hasModule('customImageUpload')) {
        store.registerModule('customImageUpload', customImageUpload);
      }
      next();
    },
    children: [
      {
        path: '',
        name: 'customImageFields',
        component: () => import(/* webpackChunkName: "manageCustomImageFields" */ '@/modules/customImageField/pages/ManageCustomImageFields.vue'),
      },
      {
        path: 'create',
        name: 'createCustomImageFields',
        components: {
          default: () => import(/* webpackChunkName: "manageCustomImageFields" */ '@/modules/customImageField/pages/ManageCustomImageFields.vue'),
          manageCustomImageFields: () => import(/* webpackChunkName: "manageCustomImageFields" */ '@/modules/customImageField/components/organisms/createCustomImageFieldModal/CreateCustomImageFieldModal.vue'),
        },
      },
      {
        path: ':customImageFieldId',
        name: 'editCustomImageFields',
        components: {
          default: () => import(/* webpackChunkName: "manageCustomImageFields" */ '@/modules/customImageField/pages/ManageCustomImageFields.vue'),
          manageCustomImageFields: () => import(/* webpackChunkName: "editCustomImageFields" */ '@/modules/customImageField/components/organisms/editCustomImageFieldModal/EditCustomImageFieldModal.vue'),
        },
      },
      {
        path: ':customImageFieldId/delete',
        name: 'deleteCustomImageFields',
        components: {
          default: () => import(/* webpackChunkName: "manageCustomImageFields" */ '@/modules/customImageField/pages/ManageCustomImageFields.vue'),
          manageCustomImageFields: () => import(/* webpackChunkName: "deleteCustomImageField" */ '@/modules/customImageField/components/organisms/deleteCustomImageFieldModal/DeleteCustomImageFieldModal.vue'),
        },
      },
    ],
  }];
}
