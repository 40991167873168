export const serviceNameDict = {
  transcribe: {
    aws: 'Amazon Transcribe',
    google: 'Google Speech-to-Text',
    autosub: 'ByteArk AI Subtitle Generator',
  },
  translate: {
    google: 'Google Translate',
  },
};
