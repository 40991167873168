<template>
  <SelectOption
    v-if="geoblockRuleList && geoblockRuleList.length"
    :modelValue="modelValue"
    :label="label"
    @update:modelValue="onGeoblockRuleChanged"
    :options="geoblockRuleList"
    :placeholder="t('geoBlock.create.namePlaceHolder')"
    :onSearchChange="onSearchGeoblockRule"
    :disabled="disabled"
  >
    <template v-slot:singleLabel="props">
      <div class="option" :data-test="`selected-option-${props.option.name}`">
        <div class="option__desc">
          <span class="option__title" :data-test="`selected-option-title-${props.option.name}`">{{ props.option.name }}</span>
          <template v-if="isDisallowAllCountries(props.option)">
            <div class="rules">
              <div class="rule-item" data-test="selected-option-deny-all-desc">{{t('geoBlock.disallowAll.subTitle')}}</div>
            </div>
          </template>
          <template v-else-if="!isAllowAllCountries(props.option)">
            <div class="rules">
              <div class="rule-item" v-if="getGeoblockRuleAllowCountry(props.option)">
                <div class="icon">
                  <i class="fas fa-earth-asia"></i>
                </div>
                <div class="country" data-test="selected-option-allow-country-list">{{getGeoblockRuleAllowCountry(props.option)}}</div>
              </div>
              <div class="rule-item" v-if="getGeoblockRuleDenyCountry(props.option)">
                <div class="icon">
                  <i class="fas fa-ban"></i>
                </div>
                <div class="country" data-test="selected-option-deny-country-list">{{getGeoblockRuleDenyCountry(props.option)}}</div>
              </div>
            </div>
          </template>
          <template v-else>
            <div class="rules">
              <div class="rule-item" data-test="selected-option-allow-all-desc">{{t('geoBlock.allowAll.subTitle')}}</div>
            </div>
          </template>
        </div>
      </div>
    </template>
    <template v-slot:option="props">
      <div class="option" v-if="props" :data-test="`option-${props.option.name}`">
        <div class="option__desc">
          <span class="option__title" :data-test="`option-title-${props.option.name}`">{{ props.option.name }}</span>
          <template v-if="isDisallowAllCountries(props.option)">
            <div class="rules">
              <div class="rule-item" data-test="option-deny-all-desc">{{t('geoBlock.disallowAll.subTitle')}}</div>
            </div>
          </template>
          <template v-else-if="!isAllowAllCountries(props.option)">
            <div class="rules">
              <div class="rule-item" v-if="getGeoblockRuleAllowCountry(props.option)">
                <div class="icon">
                  <i class="fas fa-earth-asia"></i>
                </div>
                <div class="country">{{getGeoblockRuleAllowCountry(props.option)}}</div>
              </div>
              <div class="rule-item" v-if="getGeoblockRuleDenyCountry(props.option)">
                <div class="icon">
                  <i class="fas fa-ban"></i>
                </div>
                <div class="country">{{getGeoblockRuleDenyCountry(props.option)}}</div>
              </div>
            </div>
          </template>
          <template v-else>
            <div class="rules">
              <div class="rule-item" data-test="option-allow-all-desc">{{t('geoBlock.allowAll.subTitle')}}</div>
            </div>
          </template>
        </div>
      </div>
    </template>
  </SelectOption>
</template>

<script setup lang="ts">
import _ from 'lodash';
import { useI18n } from 'vue-i18n';
import { computed, onMounted, toRefs } from 'vue';
import SelectOption from '@/modules/shared/components/atoms/selectOption/SelectOption.vue';
import {
  getCountriesName,
} from '../../../utils/geoblockRuleManagement';
import { useStore } from '../../../store/hooks';
import type {
  BaseGeoblockRule,
  GeoblockFilterProps,
  GeoblockRule,
} from '../../../types/Geoblock.type';

interface GeoblockRuleSelectorProps {
  modelValue: GeoblockRule | BaseGeoblockRule | undefined | null;
  label?: string;
  disabled?: boolean;
}

const props = withDefaults(defineProps<GeoblockRuleSelectorProps>(), {
  label: 'name',
  disabled: false,
});

const {
  modelValue,
  label,
  disabled,
} = toRefs(props);

const { t } = useI18n();

const emits = defineEmits<{(e: 'update:modelValue', value: GeoblockRule): void}>();

// store
const store = useStore();
const countryList = computed(() => store.state.geoblockRuleList.countryList);
const geoblockRuleList = computed(() => store.state.geoblockRuleList.geoblockRules);

// methods
const loadGeoblockRuleList = async (filter?: GeoblockFilterProps) => {
  await store.dispatch('loadGeoblockRules', filter);
};
const onSearchGeoblockRule = (filter: GeoblockFilterProps) => {
  loadGeoblockRuleList(filter);
};
const onGeoblockRuleChanged = (newGeoblockRule: GeoblockRule) => {
  emits('update:modelValue', newGeoblockRule);
};
const isAllowCountryCodesEmpty = (geoblockRule: GeoblockRule) => _.isEmpty(geoblockRule.allowCountryCodes);
const isDenyCountryCodesEmpty = (geoblockRule: GeoblockRule) => _.isEmpty(geoblockRule.denyCountryCodes);
const isAllowAllCountries = (geoblockRule: GeoblockRule) => {
  if (isAllowCountryCodesEmpty(geoblockRule) && isDenyCountryCodesEmpty(geoblockRule)) {
    return true;
  }
  return false;
};
const isDisallowAllCountries = (geoblockRule: GeoblockRule) => {
  if (geoblockRule.allowCountryCodes && geoblockRule.allowCountryCodes[0] === 'XX') {
    return true;
  }
  return false;
};
const getGeoblockRuleAllowCountry = (geoblockRule: GeoblockRule) => getCountriesName(geoblockRule.allowCountryCodes, countryList.value);
const getGeoblockRuleDenyCountry = (geoblockRule: GeoblockRule) => getCountriesName(geoblockRule.denyCountryCodes, countryList.value);

onMounted(() => {
  loadGeoblockRuleList();
  if (!countryList.value?.length) {
    store.dispatch('loadCountryList');
  }
});
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/global-variables.scss';

.option__title {
  color: #343A40;
  font-weight: $font-weight-bold;
  font-size: $font-level-7;
}

:deep(.multiselect__option--selected.multiselect__option--highlight) {
  background-color: $ci-primary;
  .option__title {
    color: #FFF;
  }
}
/* :deep(.multiselect__option) {
  padding: $spacing-8 $spacing-8 0 $spacing-8 + 5px; //
} */
:deep(.multiselect__option--highlight) {
  background-color: $ci-primary;
  .option__title, .option__desc .rules .rule-item {
    color: #FFF;
  }
}

:deep(.multiselect), :deep(.multiselect__tags) {
  min-height: 33px;
  height: auto;
}

:deep(.multiselect__single) {
  margin-bottom: 0;
  padding: 0;
}
:deep(.multiselect__tags) {
  cursor: pointer;
  padding: $spacing-12 40px $spacing-12 $spacing-12;
}
:deep(.multiselect__input) {
  margin-bottom: 0;
  padding-top: 10px;
  padding-bottom: 11px;
}
:deep(.multiselect__option--highlight:after) {
  background-color: $ci-primary;
}

.option__desc {
  .rules {
    margin-top: $spacing-base * 0.2;
    /* margin-bottom: $spacing-base * 0.5; */

    .rule-item {
      font-size: $font-level-8;
      align-items: baseline;
      /* margin-bottom: 4px; */
      max-width: 100%;
      color: #6C757D;

      display: flex;
      flex: 1;
      min-width: 0;

      .icon {
        margin-right: 4px;
        padding-top: 4px;
        width: 14px;
        height: 20px;
        display: flex;
        align-items: flex-start;
      }

      .country {
        flex: 1;
        min-width: 0;
        word-break: break-word;
      }

      &.rule-item-option {
        .icon {
          padding-top: 2px;
        }
      }
    }
  }
}

.option {
  max-width: 100%;
  white-space: initial;
}

.option__desc {
  max-width: 80%;
}
</style>
