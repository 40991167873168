import type { VideoState } from '@/modules/shared/types/video.type';
import { useStore as baseUseStore } from 'vuex';
import { storeKey } from '@/store';
import type { TeamFeaturesState } from '@/modules/shared/types/teamFeatures.type';
import type { VideoUploadState } from '@/modules/videoUpload';
import type { ProjectState } from '@/modules/shared/types/project.type';
import type { LanguageListState } from '@/types/Language.type';
import type { TeamSettingState } from '@/modules/teamSetting/types';
import type { CollectionListState } from '@/modules/collectionList/types';
import type { GeoblockRuleListState } from '@/modules/geoblock';
import type { PlaybackChannelListState } from '@/modules/playbackChannel';

export const useStore = () => baseUseStore<{
  collectionList: CollectionListState;
  geoblockRuleList: GeoblockRuleListState;
  video: VideoState;
  project: ProjectState;
  videoUpload: VideoUploadState;
  teamFeatures: TeamFeaturesState;
  teamSetting: TeamSettingState;
  languageList: LanguageListState;
  playbackChannelList: PlaybackChannelListState;
}>(storeKey);
