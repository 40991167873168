<template>
  <ConfirmModal
    size="small"
    :title="t('project.recentlyDeleted.restoreAll.title')"
    :buttonTitle="t('project.recentlyDeleted.restoreAll.button')"
    buttonStyle="primary"
    :onClose="onClose"
    :onConfirm="restoreAllVideos"
    :enableCancelButton="true"
    :disableConfirmButton="isPending"
  >
    <template v-slot:body>
      <div v-html="restoreAllDeletedVideosDescription"/>
    </template>
  </ConfirmModal>
</template>

<script lang="ts" setup>
import ConfirmModal from '@/modules/shared/components/organisms/confirmModal/ConfirmModal.vue';
import {
  computed, onMounted, ref, toRefs,
} from 'vue';
import { restoreAllDeletedVideos } from '@/modules/projectVideoList/services';
import { useRoute } from 'vue-router';
import { getQueryAsString } from '@/modules/shared/utils/query';
import { useStore } from '@/modules/projectVideoList/store/hooks';
import { useI18n } from 'vue-i18n';

interface RestoreAllDeletedVideosModalProps {
  onClose: () => void;
}

const props = defineProps<RestoreAllDeletedVideosModalProps>();
const { onClose } = toRefs(props);

const store = useStore();
const route = useRoute();
const { t } = useI18n();

const isPending = ref<boolean>(false);

const query = computed(() => route.query);
const projectKey = computed(() => getQueryAsString(route.params, 'projectKey'));
const projectDeletedVideoTotal = computed(() => store.state.projectDeletedVideoList.projectDeletedVideoTotal);

const restoreAllDeletedVideosDescription = computed(() => {
  const restoreAllDeletedVideosKey = 'project.recentlyDeleted.restoreAll.description';
  if (projectDeletedVideoTotal.value <= 1) {
    return t(restoreAllDeletedVideosKey, { count: projectDeletedVideoTotal.value });
  }
  return t(`${restoreAllDeletedVideosKey}_plural`, { count: projectDeletedVideoTotal.value });
});

async function load() {
  await store.dispatch('loadProjectDeletedVideoList', { filter: { ...query.value, projectKey: projectKey.value } });
}

const restoreAllVideos = async () => {
  isPending.value = true;
  if (projectKey.value) {
    try {
      const res = await restoreAllDeletedVideos(projectKey.value);
      if (res.status === 200) {
        onClose.value();
        await load();
      }
    } catch (error) {
      console.error(error);
    }
  }
  isPending.value = false;
};

onMounted(() => {
  if (projectKey.value) {
    load();
  }
});
</script>
