import qs from 'qs';
import { axiosAPI } from '@/plugins/axios';
import type { Filter, ListApiResponse } from '@/types/Axios.type';
import type { Bumper } from '../types';

export const loadBumpers = (filter?: Filter) => {
  const query = qs.stringify(filter, { addQueryPrefix: true, skipNulls: true });
  return axiosAPI.get<ListApiResponse<Bumper[]>>(`/bumpers${query}`);
};

export const createBumper = (payload: Bumper) => axiosAPI.post('/bumpers', payload);
export const deleteBumperById = (id: string) => axiosAPI.delete(`/bumpers/${id}`);
export const deleteBumperByBulkAction = (payload: { bumperIds: string[] }) => axiosAPI.post('/bumpers/bulk-delete', payload);
export const loadBumperDetail = (id: string) => axiosAPI.get<Bumper>(`/bumpers/${id}`);
export const saveBumperDetail = (id: string, payload: Bumper) => axiosAPI.put(`/bumpers/${id}`, payload);
export const enableBumperDetail = (id: string) => axiosAPI.put(`/bumpers/${id}/enabled`);
