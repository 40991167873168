<template>
  <VideoUploadDefaultBox
    :label="t('glossary:preset')"
    :isEditing="isEditing"
    @onClickEdit="onClickEdit">
    <template v-if="!_.isNil(preset)">
      <div class="setting-header">
        <div v-if="!isEditing" class="preset-information">
          <h4 data-test="video-output-preset-title" class="title">{{ preset.name }}</h4>
          <div class="profiles">
            <div
              class="preset-profile-wrapper"
              v-for="profile in preset.profiles"
              :key="profile.id"
            >
              <PresetSummaryInfo :profile="profile" />
            </div>
          </div>
        </div>
      </div>
      <VideoOutputSelector
        v-if="isEditing"
        @update:modelValue="onChangeOutput"
        :presetData="preset"
        :presetFilter="presetFilter"
      />
    </template>
  </VideoUploadDefaultBox>
</template>

<script lang="ts" setup>
import { ref, toRefs } from 'vue';
import _ from 'lodash';
import { useI18n } from 'vue-i18n';
import { type Preset, PresetSummaryInfo } from '@/modules/preset';
import { Filter } from '@/types/Axios.type';
import VideoUploadDefaultBox from '../../organisms/videoUploadDefaultBox/VideoUploadDefaultBox.vue';
import VideoOutputSelector from '../videoOutputSelector/VideoOutputSelector.vue';

interface VideoOutputFieldProps {
  preset: Preset | null | undefined;
  presetFilter?: Filter;
}

const props = defineProps<VideoOutputFieldProps>();
const { preset } = toRefs(props);
const { t } = useI18n();

const isEditing = ref<boolean>(false);

const emit = defineEmits<{
  'update:modelValue': [value: Preset];
}>();

function onClickEdit() {
  isEditing.value = !isEditing.value;
}

function onChangeOutput(newPreset: Preset | null) {
  if (_.isNil(newPreset)) {
    return;
  }

  isEditing.value = false;
  emit('update:modelValue', newPreset);
}
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/global-variables.scss';
.title {
  margin-bottom: $spacing-base;
  font-size: $font-level-5;
  font-weight: $font-weight-base;
  color: $grey-800;
  text-transform: capitalize;
}
</style>
