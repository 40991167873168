<template>
  <SelectOption
    :modelValue="modelValue"
    :options="options"
    :allow-empty="false"
    track-by="customName"
    label="customName"
    deselect-label=""
    @update:modelValue="onOptionChange"
    :disabled="isDisabled"
    :searchable="true"
    :placeholder="$t('video.subtitle.modal.translate.from.select')"
  >
    <template v-slot:singleLabel="props">
      <div class="option__desc">
        <span class="option__title option-title">{{ props.option.customName }}</span>
        <span class="option__small option-subtitle">
          {{props.option.publishStatus}} ･ {{getServiceName(props.option.captionProcessingType)}}
        </span>
      </div>
    </template>
    <template v-slot:option="props">
      <div class="option__desc">
        <span class="option__title option-title">{{ props.option.customName }}</span>
        <span class="option__small option-subtitle">
          {{props.option.publishStatus}} ･ {{getServiceName(props.option.captionProcessingType)}}
        </span>
      </div>
    </template>
  </SelectOption>
</template>

<script setup>
import { serviceNameDict } from '@/modules/captionProfile/utils';
import SelectOption from '@/modules/shared/components/atoms/selectOption/SelectOption.vue';

defineProps({
  modelValue: {
    type: Object,
    default: () => {},
  },
  options: {
    default: () => [],
  },
  onSelect: {
    type: Function,
    default: () => {},
  },
  isDisabled: {
    type: Boolean,
    default: false,
  },
});

const emits = defineEmits(['update:modelValue']);

const onOptionChange = (newValue) => {
  emits('update:modelValue', newValue);
};

const getServiceName = (captionProcessingType) => {
  const { type, service } = captionProcessingType;
  if (type === 'transcribe' || type === 'translate') {
    return serviceNameDict[type][service];
  }
  return captionProcessingType.service;
};

</script>

<style lang="scss" scoped>
@import '~@/assets/scss/global-variables';

:deep(.multiselect), :deep(.multiselect__tags) {
  min-height: 33px;
  height: auto;
}

.option-title {
  font-size: $font-level-6;
  font-weight: $font-weight-bold;
}
.option-subtitle {
  font-size: $font-level-7;
  display:flex;
  flex-direction: row;
  margin-top: 8px;
  text-transform: capitalize;
}

.multiselect--active {
  color: white;
  :deep(.multiselect__input) {
    margin-top: $spacing-4;
  }
}

:deep(.multiselect__select) {
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: calc(100% - 2px);
  color: white;

  &::before {
    margin-top: 0;
    margin-right: $spacing-8;
    position: static;
  }
}

:deep(.multiselect__tags-wrap) {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

:deep(.multiselect__tags) {
  border-radius: $border-radius-3;
  padding: 0;
  padding: 8px 12px;
  position: relative;
}

:deep(.multiselect__option),
:deep(.multiselect__tag),
:deep(.multiselect__input) {
  font-size: $font-size-base;
}

:deep(.multiselect__option--highlight) {
  background: $ci-primary;
  &:after {
    background: $ci-primary;
  }
}

:deep(.multiselect__content-wrapper) {
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
  border-radius: $border-radius-3;
}

:deep(.multiselect__input),
:deep(.multiselect__placeholder) {
  font-size: $font-size-base;
  padding: 0;
  margin: 0;
  min-height: unset;
  line-height: unset;
}

:deep(.multiselect__tag) {
  color: #495057;
  background-color: #dee2e6;
  margin: 0;
  font-size: $font-size-base;
}

:deep(.multiselect__option--selected.multiselect__option--highlight) {
  background: $danger;
}

:deep(.multiselect__option--selected.multiselect__option--highlight::after) {
  background: $danger;
}

:deep(.multiselect__tag-icon::after) {
  color: $grey-500;
}

.option {
  max-width: 100%;
  white-space: initial;
}

.option__desc {
  max-width: 80%;
}

@media screen and (max-height: 620px) {
  :deep(.multiselect__content-wrapper) {
    height: 150px !important;
  }
}
</style>
