<template>
  <Modal
    size="small"
    :title="t(`project.manage.member.${getIfIsUserActivated(member) ? 'remove' : 'removeNonMember'}`)"
    :primary-button-text="getIfIsUserActivated(member) ? t('project.manage.member.remove') : t('project.manage.member.cancelInvitation.button')"
    :secondary-button-text="getIfIsUserActivated(member) ? t('common:button.cancel') : t('common:close')"
    primaryButtonStyle="danger"
    :onClickPrimaryButton="onDeleteMemberShip"
    :onClickSecondaryButton="onClose"
    @modalClose="onClose">
    <template v-slot:body>
      <template v-if="getIfIsUserActivated(member)">
        <div v-if="!deleteProjectMembershipState.error">
          {{ t('project.manage.members.remove.title') }}:
          <span class="highlight-user">{{ getMemberName() }}.</span>
        </div>
        <p>{{ t('project.manage.members.remove.description') }}</p>
      </template>
      <template v-else>
        <div v-if="!deleteProjectMembershipState.error">
          <p>
            {{ t('project.manage.member.cancelInvitation.description1') }}
            <span class="highlight-user">{{ getMemberName() }}</span>
            {{ t('project.manage.member.cancelInvitation.description2') }}
          </p>
        </div>
      </template>
      <ErrorMessage
        v-if="deleteProjectMembershipState.error"
        :title="t('project.manage.members.remove.error')"
        :description="t('response:retry')"
        :statusCode="deleteProjectMembershipErrorCode"
      />
    </template>
  </Modal>
</template>

<script lang="ts" setup>
import { getIfIsUserActivated } from '@/modules/projectMember/utils';
import _ from 'lodash';
import { computed, toRefs } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';
import { getQueryAsString } from '@/modules/shared/utils/query';
import Modal from '@/modules/shared/components/organisms/modal/Modal.vue';
import ErrorMessage from '@/modules/shared/components/atoms/errorMessage/ErrorMessage.vue';
import type { ProjectMember } from '@/modules/projectMember/types';
import { useStore } from '../../../store/hooks';

interface DeleteProjectMembershipModalProps {
  member: ProjectMember | null;
  onCloseModal: () => void;
}

const props = defineProps<DeleteProjectMembershipModalProps>();
const { member, onCloseModal } = toRefs(props);

const emit = defineEmits<{success: []}>();

const { t } = useI18n();
const store = useStore();
const route = useRoute();

const projectKey = computed(() => getQueryAsString(route.params, 'projectKey'));
const deleteProjectMembershipState = computed(() => store.state.projectMember.deleteProjectMembershipState);
const deleteProjectMembershipErrorCode = computed(() => {
  if (deleteProjectMembershipState.value.error) {
    return _.get(deleteProjectMembershipState.value, 'error.response.status', '');
  }
  return '';
});

function getMemberName() {
  return _.get(member.value, 'account.name', null);
}

async function onDeleteMemberShip() {
  if (_.isNil(member.value)) {
    return;
  }

  const { accountId } = member.value;
  await store.dispatch('deleteProjectMembership', {
    projectKey: projectKey.value,
    accountId,
  });
  emit('success');
  onCloseModal.value();
}

async function onClose() {
  await store.dispatch('clearDeleteProjectMembershipState');
  onCloseModal.value();
}
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/global-variables';

.highlight-user {
  font-weight: $font-weight-bold;
  color: $grey-700;
}

:deep(.btn-close) {
  margin: 0;
}
</style>
