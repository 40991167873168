/* eslint-disable @typescript-eslint/no-unused-vars */
// comment for disable eslint unused vars addVideoSource, deleteVideoSource, editVideoSource
import {
  ActionContext, ActionTree, GetterTree, MutationTree,
} from 'vuex';
import _ from 'lodash';
import { RootState } from '@/store/type';
import { setStateLoadingStatusByType } from '@/modules/shared/utils/stateManagement';
import { LoadingState } from '@/types/State.type';
import { formatErrorObject } from '@/modules/shared/utils/errorFormatter';
import {
  createS3Connection,
  deleteS3Connection,
  editS3Connection,
  loadListS3Connections,
} from '../services';
import {
  AddVideoSourcePayload,
  EditVideoSourcePayload,
  LoadVideoSourceProps,
  VideoSource,
  VideoSourceState,
} from '../types/VideoSources.type';

export type VideoSourceContext = ActionContext<VideoSourceState, RootState>;

const state: VideoSourceState = {
  videoSourceList: [],
  loadVideoSourceListState: setStateLoadingStatusByType(),
  addVideoSourceState: setStateLoadingStatusByType(),
  deleteVideoSourceState: setStateLoadingStatusByType(),
  editVideoSourceState: setStateLoadingStatusByType(),
  isOpenAddModal: false,
  isOpenDeleteModal: false,
  isOpenEditModal: false,
};

const getters: GetterTree<VideoSourceState, RootState> = {
  isEmptyVideoSourceList(state: VideoSourceState): boolean {
    return _.isEmpty(state.videoSourceList);
  },
};

const mutations: MutationTree<VideoSourceState> = {
  setVideoSourceList(state: VideoSourceState, videoSourceList: VideoSource[]) {
    state.videoSourceList = videoSourceList;
  },
  setLoadVideoSourceListState(state: VideoSourceState, loadVideoSourceListState: LoadingState) {
    state.loadVideoSourceListState = setStateLoadingStatusByType(loadVideoSourceListState);
  },
  setAddVideoSourceState(state: VideoSourceState, addVideoSourceState: LoadingState) {
    state.addVideoSourceState = setStateLoadingStatusByType(addVideoSourceState);
  },
  setEditVideoSourceState(state: VideoSourceState, editVideoSourceState: LoadingState) {
    state.editVideoSourceState = setStateLoadingStatusByType(editVideoSourceState);
  },
  setDeleteVideoSourceState(state: VideoSourceState, deleteVideoSourceState: LoadingState) {
    state.deleteVideoSourceState = setStateLoadingStatusByType(deleteVideoSourceState);
  },
  setIsOpenAddModal(state: VideoSourceState, isOpenAddModal: boolean) {
    state.isOpenAddModal = isOpenAddModal;
  },
  setIsOpenEditModal(state: VideoSourceState, isOpenEditModal: boolean) {
    state.isOpenEditModal = isOpenEditModal;
  },
  setIsOpenDeleteModal(state: VideoSourceState, isOpenDeleteModal: boolean) {
    state.isOpenDeleteModal = isOpenDeleteModal;
  },
};

const actions: ActionTree<VideoSourceState, RootState> = {
  async loadVideoSourceList({ commit }: VideoSourceContext, filter: LoadVideoSourceProps | null) {
    commit('setLoadVideoSourceListState', { type: 'loading' } as LoadingState);
    try {
      const result = await loadListS3Connections(filter?.search);
      commit('setVideoSourceList', result.data as VideoSource[]);
      commit('setLoadVideoSourceListState', { type: 'success' } as LoadingState);
    } catch (error) {
      commit('setLoadVideoSourceListState', {
        type: 'error',
        error: formatErrorObject(error, 'loadVideoSourceList'),
      } as LoadingState);
    }
  },
  setIsOpenAddModal({ commit }: VideoSourceContext, isOpenAddModal: boolean) {
    commit('setIsOpenAddModal', isOpenAddModal);
  },
  setIsOpenEditModal({ commit }: VideoSourceContext, isOpenEditModal: boolean) {
    commit('setIsOpenEditModal', isOpenEditModal);
  },
  setIsOpenDeleteModal({ commit }: VideoSourceContext, isOpenDeleteModal: boolean) {
    commit('setIsOpenDeleteModal', isOpenDeleteModal);
  },
  async addVideoSource({ commit }: VideoSourceContext, videoSource: AddVideoSourcePayload) {
    commit('setAddVideoSourceState', { type: 'loading' } as LoadingState);
    try {
      await createS3Connection(videoSource);
      commit('setAddVideoSourceState', { type: 'success' } as LoadingState);
    } catch (error) {
      commit('setAddVideoSourceState', {
        type: 'error',
        error: formatErrorObject(error, 'addVideoSource'),
      } as LoadingState);
    }
  },
  async editVideoSource({ commit }: VideoSourceContext, videoSource: EditVideoSourcePayload) {
    commit('setEditVideoSourceState', { type: 'loading' } as LoadingState);
    try {
      await editS3Connection(videoSource.id, videoSource);
      commit('setEditVideoSourceState', { type: 'success' } as LoadingState);
    } catch (error) {
      commit('setEditVideoSourceState', {
        type: 'error',
        error: formatErrorObject(error, 'editVideoSource'),
      } as LoadingState);
    }
  },
  async deleteVideoSource({ commit }: VideoSourceContext, videoSourceId: string) {
    commit('setDeleteVideoSourceState', { type: 'loading' } as LoadingState);
    try {
      await deleteS3Connection(videoSourceId);
      commit('setDeleteVideoSourceState', { type: 'success' } as LoadingState);
    } catch (error) {
      commit('setDeleteVideoSourceState', {
        type: 'error',
        error: formatErrorObject(error, 'deleteVideoSource'),
      } as LoadingState);
    }
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
