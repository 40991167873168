/* eslint no-shadow: ["error", { "allow": ["state"] }] */
import { formatErrorObject } from '@/modules/shared/utils/errorFormatter';
import {
  setStateLoadingStatusByType,
} from '@/modules/shared/utils/stateManagement';
import type { Filter } from '@/types/Axios.type';
import type { ActionContext } from 'vuex';
import type { RootState } from '@/store/type';
import type { LoadingState } from '@/types/State.type';
import type { FormUpload, FormUploadListState } from '../types';
import { loadForms } from '../services';

type FormUploadListActionContext = ActionContext<FormUploadListState, RootState>

const initialState = (): FormUploadListState => ({
  formUploadList: [],
  loadFormUploadListState: setStateLoadingStatusByType(),
});

const state = initialState();

const mutations = {
  setFormUploadList(state: FormUploadListState, formUploadList: FormUpload[]) {
    state.formUploadList = formUploadList;
  },
  setFormUploadListState(state: FormUploadListState, loadingState: LoadingState) {
    state.loadFormUploadListState = setStateLoadingStatusByType(loadingState);
  },
  destroyFormUploadList(state: FormUploadListState) {
    Object.assign(state, initialState());
  },
};

const actions = {
  async loadForms({ commit }: FormUploadListActionContext, filter: Filter) {
    commit('destroyFormUploadList');
    commit('setFormUploadListState', { type: 'loading' });
    try {
      const response = await loadForms(filter);
      commit('setFormUploadList', response.data.data);
      commit('setFormUploadListState', { type: 'success' });
    } catch (error) {
      commit('setFormUploadListState', { type: 'error', error: formatErrorObject(error, 'Form Upload') });
    }
  },
  resetFormUploadState({ commit }: FormUploadListActionContext, state: FormUploadListState) {
    commit('destroyFormUploadList', state);
  },
};

export default {
  state,
  mutations,
  actions,
};
