<template>
  <MessageBox
    iconClassName="fas fa-search"
    :title="t('common:search.resultEmpty.title', { q: keyword || queryString })"
    :description="t('common:search.resultEmpty.description')"
    :buttonTitle="(queryString || onClearSearch) ? t('common:search.resultEmpty.buttonTitle') : ''"
    :onClickButton="onClickClearSearch"
    :dataTest="dataTest"
  />
</template>

<script lang="ts" setup>
import { computed, toRefs } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute, useRouter } from 'vue-router';
import type { Nullable } from '@/types/index.type';
import MessageBox from '@/modules/shared/components/molecules/messageBox/MessageBox.vue';

interface EmptySearchProps {
  keyword?: Nullable<string>;
  onClearSearch?: () => (void | Promise<void>);
  dataTest?: Nullable<string>;
}

const props = withDefaults(defineProps<EmptySearchProps>(), {
  dataTest: 'empty-search-container',
});

const {
  keyword,
  onClearSearch,
  dataTest,
} = toRefs(props);

const { t } = useI18n();
const route = useRoute();
const router = useRouter();

const queryString = computed(() => route.query.q);

const onClickClearSearch = () => {
  if (queryString.value) {
    router.push({ query: undefined });
  }

  if (onClearSearch.value) {
    onClearSearch.value();
  }
};
</script>
