import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = ["data-test"]
const _hoisted_2 = {
  key: 0,
  class: "icon-prefix-container"
}
const _hoisted_3 = {
  key: 1,
  class: "icon-suffix-container"
}

import { toRefs } from 'vue';
import { BadgeProps } from '@/types/Badge.type';


export default /*@__PURE__*/_defineComponent({
  __name: 'Badge',
  props: {
    title: {},
    theme: { default: 'white' },
    size: { default: 'small' },
    badgeStyle: { default: 'secondary' },
    dataTest: {}
  },
  setup(__props: any) {

const props = __props;

const {
  title,
  theme,
  size,
  badgeStyle,
  dataTest,
} = toRefs(props);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["badge", [{'dark': _unref(theme) === 'dark'}, _unref(badgeStyle), _unref(size)]]),
    "data-test": `${_unref(dataTest) ? _unref(dataTest) : `${_unref(title)}-badge`}`
  }, [
    (_ctx.$slots['icon-prefix'])
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _renderSlot(_ctx.$slots, "icon-prefix")
        ]))
      : _createCommentVNode("", true),
    _renderSlot(_ctx.$slots, "before-title"),
    _createElementVNode("span", {
      class: _normalizeClass(['title', _ctx.$slots['before-title'] ? 'with-margin' : ''])
    }, _toDisplayString(_unref(title)), 3),
    (_ctx.$slots['icon-suffix'])
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _renderSlot(_ctx.$slots, "icon-suffix")
        ]))
      : _createCommentVNode("", true)
  ], 10, _hoisted_1))
}
}

})