<template>
  <Modal
    :key="step"
    :title="t('project.manage.setting.playbackChannel.add')"
    :primaryButtonText="step === 1 ? t('common:button.next') : 'Add playback channel'"
    :isDisabledPrimaryButton="step === 2 ? saveProjectState.status === 'error' || saveProjectState.status === 'saving' : false"
    :onClickPrimaryButton="primaryButtonOnClick"
    :secondaryButtonText="step === 2 ? t('common:button.back') : ''"
    :onClickSecondaryButton="step === 2 ? onClickBack : undefined"
    @modalClose="onClose">
    <template v-slot:body>
      <div class="loading-container" v-if="loadPlaybackChannelListState.status === 'loading'">
        <i class="fas fa-circle-notch fa-spin fa-4x" />
      </div>
      <div class="content-container" v-if="loadPlaybackChannelListState.status === 'success'">
        <div class="options-container" v-if="step === 1">
          <div
            class="form-radio playback-channel"
            :class="{'disabled': isPlaybackChannelDisabled(playbackChannel)}"
            v-for="(playbackChannel) in playbackChannelList"
            :key="playbackChannel.id">
            <RadioButtonChoice
              :disabled="isPlaybackChannelDisabled(playbackChannel)"
              :modelValue="isSelectedPlaybackChannel(playbackChannel)"
              :data="playbackChannel"
              @update:data="onSelectedPlaybackChannel">
              <div class="header">
                <div class="title">{{playbackChannel.name}}</div>
                <Badge
                  v-if="isPlaybackChannelDisabled(playbackChannel)"
                  :title="t('glossary:selected')" />
              </div>
              <div class="domain-container">
                <span class="label">
                  {{t('hostings.domain')}} ({{getPlaybackDomains(playbackChannel).length}})
                </span>
                <div class="value" v-if="!getPlaybackDomains(playbackChannel).length">
                  -
                </div>
                <div class="domains" v-if="getPlaybackDomains(playbackChannel)">
                  <div class="value" v-for="(domain, index) in getPlaybackDomains(playbackChannel)" :key="index">
                    {{domain}}
                  </div>
                </div>
              </div>
              <div class="information-wrapper">
                <div class="signed-url">
                  <span class="label">
                    Signed URL
                  </span>
                  <div class="value">
                    {{getIsRequiredSignUrl(playbackChannel) ? t('project.setup.playbackChannel.signedURL.isEnabled') : t('project.setup.playbackChannel.signedURL.isDisabled')}}
                  </div>
                </div>
                <div class="access-control-policy">
                  <span class="label">
                    Allowed / Blocked Website
                  </span>
                  <div class="value">
                    <span v-if="isAllowAllDomains(playbackChannel)">{{t('accessControlPolicy.allowAllWebsite')}}</span>
                    <span v-else>{{t('accessControlPolicy.allowSomeWebsite')}}</span>
                  </div>
                </div>
              </div>
            </RadioButtonChoice>
          </div>
        </div>
        <PlaybackChannelSummary
          v-if="step === 2"
          :selectedPlaybackChannel="selectedPlaybackChannel"
        />
      </div>
    </template>
    <template v-slot:footer>
      <div class="second-step-button-container" v-if="step === 2">
        <div class="message-container">
          <span v-if="saveProjectState.status === 'saving'"><i class="fas fa-spinner fa-spin" />{{t('common:button.saving')}}</span>
          <span class="text-danger" v-if="saveErrorCode === 404"><i class="fa fa-xmark" />{{t('response:error404.title', { item: 'project'})}}</span>
          <span class="text-danger" v-if="saveErrorCode === 422"><i class="fa fa-xmark" />{{t('response:error422.title')}}</span>
          <span class="text-danger" v-if="saveErrorCode === 500"><i class="fa fa-xmark" />{{t('response:error500.title')}}</span>
        </div>
      </div>
    </template>
  </Modal>
</template>

<script lang="ts" setup>
import _ from 'lodash';
import {
  computed, onMounted, ref, toRefs,
} from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from '@/modules/projectDefaultUploadSetting/store/hooks';
import type { PlaybackChannel } from '@/modules/playbackChannel';
import Modal from '@/modules/shared/components/organisms/modal/Modal.vue';
import RadioButtonChoice from '@/modules/shared/components/atoms/radioButtonChoice/RadioButtonChoice.vue';
import PlaybackChannelSummary from '@/modules/projectDefaultUploadSetting/components/organisms/playbackChannelSummary/PlaybackChannelSummary.vue';
import Badge from '@/modules/shared/components/atoms/badge/Badge.vue';

interface AddPlaybackChannelModalProps {
  onClose: () => void;
}

const props = defineProps<AddPlaybackChannelModalProps>();
const { onClose } = toRefs(props);

const { t } = useI18n();
const store = useStore();

const selectedPlaybackChannel = ref<PlaybackChannel | null>(null);
const step = ref<number>(1);

const currentProject = computed(() => store.state.project.currentProject);
const saveProjectState = computed(() => store.state.project.saveProjectState);
const playbackChannelList = computed(() => store.state.playbackChannelList.playbackChannelList);
const loadPlaybackChannelListState = computed(() => store.state.playbackChannelList.loadPlaybackChannelListState);
const saveErrorCode = computed(() => _.get(saveProjectState.value, 'error.status', 500));

function isSelectedPlaybackChannel(playbackChannel: PlaybackChannel) {
  return selectedPlaybackChannel.value === playbackChannel;
}

function onSelectedPlaybackChannel(playbackChannel: PlaybackChannel) {
  if (selectedPlaybackChannel.value === playbackChannel) {
    selectedPlaybackChannel.value = null;
  } else {
    selectedPlaybackChannel.value = playbackChannel;
  }
}

function getIsRequiredSignUrl(playbackChannel: PlaybackChannel) {
  return _.get(playbackChannel, 'signedUrl.required', false);
}

function isAllowAllDomains(playbackChannel: PlaybackChannel) {
  const allowDomains = _.get(playbackChannel, 'accessControlPolicy.allowDomains', []);
  const denyDomains = _.get(playbackChannel, 'accessControlPolicy.denyDomains', []);
  return !allowDomains.length && !denyDomains.length;
}

function getPlaybackDomains(playbackChannel: PlaybackChannel) {
  return [playbackChannel.defaultPlaybackDomain, ..._.get(playbackChannel, 'playbackDomains', [])];
}

function isPlaybackChannelDisabled(playbackChannel: PlaybackChannel) {
  const projectPlaybackChannels = _.get(currentProject.value, 'playbackChannels', []);
  if (_.find(projectPlaybackChannels, { id: playbackChannel.id })) {
    return true;
  }
  return false;
}

function onClickNext() {
  step.value += 1;
}

function onClickBack() {
  step.value -= 1;
}

async function onAddPlaybackChannel(playbackChannelId: string) {
  await store.dispatch('addProjectPlaybackChannel', {
    projectKey: currentProject.value?.key,
    playbackChannelIds: [playbackChannelId],
  });

  if (saveProjectState.value.status === 'success') {
    onClose.value();
  }
}

const primaryButtonOnClick = computed(() => {
  if (step.value === 1) {
    return onClickNext;
  }

  if (!_.isNil(selectedPlaybackChannel.value) && !_.isNil(selectedPlaybackChannel.value?.id)) {
    return () => onAddPlaybackChannel(selectedPlaybackChannel.value!.id);
  }

  return undefined;
});

onMounted(() => store.dispatch('loadPlaybackChannelList'));
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/global-variables.scss';

.playback-channel {
  margin-bottom: $spacing-base;
  .label {
    color: #1B263A;
  }
  .value {
    margin-top: $spacing-base * 0.25;
    color: #6A7383;
  }
  .header {
    margin-bottom: $spacing-base * 0.5;
    display: flex;
    .title {
      margin-right: auto;
    }
    .already-choose-badge {
      border: 1px solid #D0D4D8;
      border-radius: 4px;
      padding: 4px 7px;
      color: #D0D4D8;
      font-size: $font-level-8;
    }
  }
  .information-wrapper {
    display: flex;
    margin-top: $spacing-base * 1.25;
    .signed-url {
      flex: 1 0 40%;
    }
    .access-control-policy {
      flex: 1 0 auto;
    }
  }
  .btn-link {
    padding: 0;
  }
  .fa-circle-info {
    cursor: pointer;
  }

  &.disabled {
    .title {
      color: #B1B9C0;
    }
    .label {
      color: #6A7383;
    }
    .value {
      color: #9BA4B4;
    }
  }
}

.loading-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.form-radio {
  margin-bottom: $spacing-base;
  .preset-name {
    color: $grey-700;
    margin-bottom: $spacing-base * 0.5;
    font-weight: $font-weight-bold;
  }
  .preset-profile-wrapper {
    margin-bottom: $spacing-base * 0.5;
    .gray-badge {
      //padding: 6px 8px;
      //margin-top: $spacing-base * 0.5;
      //margin-right: $spacing-base * 0.5;
      padding: 2px 8px;
      margin-top: $spacing-8;
      margin-right: $spacing-8;
      font-size: $font-size-base;
      font-weight: $font-weight-base;
      color: $grey-800;
      background-color: $grey-200;
    }
  }
}

.second-step-button-container {
  display: flex;
  width: 100%;
  .message-container {
    margin-right: $spacing-base * 0.5;
    i {
      margin-right: $spacing-4;
    }
  }
  :deep(.save-button) {
    margin-left: auto;
  }
}

</style>
