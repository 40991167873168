import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx, createTextVNode as _createTextVNode, createSlots as _createSlots } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "step-container"
}
const _hoisted_2 = { class: "step-title" }
const _hoisted_3 = { class: "step-title" }
const _hoisted_4 = {
  key: 1,
  class: "content-container"
}
const _hoisted_5 = { class: "section-title" }
const _hoisted_6 = { class: "search-box-container" }
const _hoisted_7 = {
  key: 0,
  class: "bumper-list"
}
const _hoisted_8 = { key: 1 }
const _hoisted_9 = {
  key: 2,
  class: "bumper-list"
}
const _hoisted_10 = { class: "video-image" }
const _hoisted_11 = {
  key: 0,
  class: "video-duration"
}
const _hoisted_12 = { class: "bumper-date" }
const _hoisted_13 = { class: "bumper-pad" }
const _hoisted_14 = { class: "bumper-pad" }
const _hoisted_15 = { class: "section" }
const _hoisted_16 = { class: "video-image" }
const _hoisted_17 = {
  key: 0,
  class: "video-duration"
}
const _hoisted_18 = { class: "bumper-date" }
const _hoisted_19 = { class: "dates" }
const _hoisted_20 = { class: "start-date" }
const _hoisted_21 = { class: "bumper-pad" }
const _hoisted_22 = { class: "end-date" }
const _hoisted_23 = { class: "bumper-pad" }
const _hoisted_24 = { class: "section" }
const _hoisted_25 = { class: "section-title" }

import _ from 'lodash';
import { Bumper } from '@/modules/bumper/types';
import { BumperDisplayPosition, ChooseBumperModalSubmitPayload, SelectedContent } from '@/modules/bumperDisplay/types';
import {
  computed, onMounted, ref, toRefs, watch,
} from 'vue';
import Modal from '@/modules/shared/components/organisms/modal/Modal.vue';
import RadioButtonGroup from '@/modules/shared/components/molecules/radioButtonGroup/RadioButtonGroup.vue';
import { formatVideoDateTime } from '@/modules/shared/utils/dateFormatter';
import { getVideoUploadCoverImage, getVideoDuration } from '@/modules/videoDetail/utils/videoManagement';
import { useBumper } from '@/modules/bumper/utils';
import Button from '@/modules/shared/components/atoms/button/Button.vue';
import Input from '@/modules/shared/components/atoms/input/Input.vue';
import ImageView from '@/modules/shared/components/atoms/imageView/ImageView.vue';
import Row from '@/modules/shared/components/molecules/row/Row.vue';
import Column from '@/modules/shared/components/molecules/column/Column.vue';
import TextCell from '@/modules/shared/components/molecules/textCell/TextCell.vue';
import PreLoaderSection from '@/modules/shared/components/atoms/preLoaderSection/PreLoaderSection.vue';
import Pagination from '@/modules/shared/components/atoms/pagination/Pagination.vue';
import Badge from '@/modules/shared/components/atoms/badge/Badge.vue';
import EmptySearch from '@/modules/shared/components/molecules/emptySearch/EmptySearch.vue';
import { useI18n } from 'vue-i18n';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from '../../../store/hooks';

interface ChooseBumperModalProps {
  bumper?: Bumper | null;
  categoryName?: string;
  isAlreadyChooseCategory?: boolean;
  disabledCategories?: string[];
}

interface SetAsOption {
  label: string;
  description: string;
  value: BumperDisplayPosition;
  disabled: boolean;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'ChooseBumperModal',
  props: {
    bumper: {},
    categoryName: {},
    isAlreadyChooseCategory: { type: Boolean, default: false },
    disabledCategories: { default: () => [] }
  },
  emits: ["modalClose", "submit"],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const {
  bumper, categoryName, isAlreadyChooseCategory, disabledCategories,
} = toRefs(props);

const emit = __emit;

const { t } = useI18n();
const store = useStore();
const route = useRoute();
const router = useRouter();

const currentStep = ref<number>(1);
const selectedBumper = ref<Bumper | null>(null);
const searchTerm = ref<string>('');
const selectedOption = ref<BumperDisplayPosition | null>(null);
const setAsOptions = ref<SetAsOption[]>([
  {
    label: 'Intro 1',
    description: disabledCategories.value.includes('intro1') ?
      t('bumperSchedule.position.changeToCustom', { position: 'Intro 1' })
      : `
          <li>${t('bumperSchedule.position.intro')}</li>
          <li>${t('bumperSchedule.position.nonAds')}</li>
          <li>${t('bumperSchedule.position.nonSkippable')}</li>
          `,
    value: 'intro1',
    disabled: false,
  },
  {
    label: 'Intro 2',
    description: disabledCategories.value.includes('intro2') ?
      t('bumperSchedule.position.changeToCustom', { position: 'Intro 2' })
      : `
          <li>${t('bumperSchedule.position.intro')}</li>
          <li>${t('bumperSchedule.position.ads')}</li>
          <li>${t('bumperSchedule.position.skippable')}</li>
      `,
    value: 'intro2',
    disabled: false,
  },
  {
    label: 'Outro 1',
    description: disabledCategories.value.includes('outro1') ?
      t('bumperSchedule.position.changeToCustom', { position: 'Outro 1' })
      : `
          <li>${t('bumperSchedule.position.outro')}</li>
          <li>${t('bumperSchedule.position.ads')}</li>
          <li>${t('bumperSchedule.position.skippable')}</li>
      `,
    value: 'outro1',
    disabled: false,
  },
  {
    label: 'Outro 2',
    description: disabledCategories.value.includes('outro2') ?
      t('bumperSchedule.position.changeToCustom', { position: 'Outro 2' })
      : `
          <li>${t('bumperSchedule.position.intro')}</li>
          <li>${t('bumperSchedule.position.nonAds')}</li>
          <li>${t('bumperSchedule.position.nonSkippable')}</li>
      `,
    value: 'outro2',
    disabled: false,
  },
]);

const currentUser = computed(() => store.state.user.currentUser);
const selectedContent = computed<SelectedContent>(() => store.state.bumperDisplay.selectedContent);
const loadBumpersState = computed(() => store.state.bumperList.loadBumperListState);
const saveBumperDisplayState = computed(() => store.state.bumperDisplay.saveBumperDisplayState);
const bumpers = computed<Bumper[]>(() => store.state.bumperList.bumperList);
const bumperLastPage = computed(() => store.state.bumperList.lastPage);
const query = computed(() => route.query);
const isSingleStep = computed(() => isAlreadyChooseCategory.value || bumper.value);

const isContentEmpty = computed(() => _.isEmpty(bumpers.value));
const contentTitle = computed(() => {
  let { content } = selectedContent.value;

  if (selectedContent.value.content?.content) {
    content = selectedContent.value.content.content;
  }

  return content.title || content.name;
});

const primaryButtonText = computed(() => {
  if (currentStep.value === 2 || isSingleStep.value) {
    return `${t('bumper.add')}`;
  }
  return t('common:button.next');
});

const secondaryButtonText = computed(() => {
  if (currentStep.value === 2) {
    if (isSingleStep.value) {
      return t('common:button.cancel');
    }
    return t('common:button.back');
  }
  return t('common:button.cancel');
});

const isDisabledPrimaryButton = computed(() => {
  if (currentStep.value === 1) {
    return !selectedBumper.value;
  }
  return !selectedBumper.value || !selectedOption.value;
});

function load(filter = {}) {
  store.dispatch('loadBumperList', { ...filter, status: 'active,scheduled,draft' });
}

function onClose() {
  emit('modalClose');
}

function onClickPrimaryButton() {
  if (currentStep.value === 1 && !isSingleStep.value) {
    currentStep.value = 2;
  } else if (selectedBumper.value && !_.isNil(selectedOption.value)) {
    emit('submit', {
      type: selectedOption.value ?? '',
      bumper: selectedBumper.value,
    });
  }
}

function onClickSecondaryButton() {
  if (currentStep.value !== 1 && !isSingleStep.value) {
    currentStep.value -= 1;
  } else {
    emit('modalClose');
  }
}

function onSearch() {
  router.push({ query: { q: searchTerm.value } });
}

function onClearSearch() {
  load();
  searchTerm.value = '';
}

function onClickBumper(selected: Bumper) {
  selectedBumper.value = selected;
}

function onClickRadio(selected: BumperDisplayPosition) {
  selectedOption.value = selected;
}

function calculateBumperPeriod(selected: Bumper | null): string {
  if (!selected) {
    return '';
  }

  const { calculatePeriod } = useBumper();
  const result = calculatePeriod(selected);

  if (result) {
    return `${result} ${t(`glossary:day${result > 1 ? '_plural' : ''}`)}`;
  }

  return '';
}

function getBadgeStyle(selected: Bumper) {
  const { getStatusBadgeStyle } = useBumper();
  return getStatusBadgeStyle(selected);
}

watch(saveBumperDisplayState, () => {
  if (saveBumperDisplayState.value.status === 'success') {
    router.push({ name: 'manageBumperDisplay' });
  }
}, { deep: true });

watch(disabledCategories, () => {
  setAsOptions.value = setAsOptions.value.map((option) => ({
    ...option,
    disabled: disabledCategories.value.includes(option.value),
  }));
}, { immediate: true });

watch(query, (value) => load(value), { deep: true });

onMounted(() => {
  load();

  if (bumper.value) {
    selectedBumper.value = bumper.value;
    currentStep.value = 2;
  }

  if (categoryName.value) {
    const found = setAsOptions.value.find((option) => option.label === categoryName.value);

    if (found) {
      selectedOption.value = found.value;
    }
  }
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(Modal, {
    class: "choose-bumper-modal",
    size: "large",
    primaryButtonText: primaryButtonText.value,
    onClickPrimaryButton: onClickPrimaryButton,
    isDisabledPrimaryButton: isDisabledPrimaryButton.value,
    secondaryButtonText: secondaryButtonText.value,
    onClickSecondaryButton: onClickSecondaryButton,
    onModalClose: onClose
  }, _createSlots({
    body: _withCtx(() => [
      (!isSingleStep.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("div", {
              class: _normalizeClass(["step-item", { current: currentStep.value === 1 }])
            }, [
              _cache[2] || (_cache[2] = _createElementVNode("div", { class: "step-number" }, "1", -1)),
              _createElementVNode("div", _hoisted_2, _toDisplayString(_unref(t)('bumperSchedule.chooseBumper.step.choose')), 1)
            ], 2),
            _createElementVNode("div", {
              class: _normalizeClass(["step-item", { current: currentStep.value === 2 }])
            }, [
              _cache[3] || (_cache[3] = _createElementVNode("div", { class: "step-number" }, "2", -1)),
              _createElementVNode("div", _hoisted_3, _toDisplayString(_unref(t)('bumperSchedule.chooseBumper.step.type')), 1)
            ], 2)
          ]))
        : _createCommentVNode("", true),
      (currentStep.value === 1)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createElementVNode("p", _hoisted_5, _toDisplayString(_unref(t)('bumperSchedule.chooseBumper.title', { name: contentTitle.value })), 1),
            _createElementVNode("div", _hoisted_6, [
              _createVNode(Input, {
                modelValue: searchTerm.value,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((searchTerm).value = $event)),
                onInput: onSearch,
                placeholder: _unref(t)('common:form.searchBy'),
                isSearchInput: ""
              }, null, 8, ["modelValue", "placeholder"])
            ]),
            (loadBumpersState.value?.status === 'loading')
              ? (_openBlock(), _createBlock(PreLoaderSection, {
                  key: 0,
                  options: { height: 350 }
                }))
              : _createCommentVNode("", true),
            (loadBumpersState.value?.status === 'success')
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                  (isContentEmpty.value && searchTerm.value)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                        _createVNode(EmptySearch, {
                          keyword: searchTerm.value,
                          onClearSearch: onClearSearch
                        }, null, 8, ["keyword"])
                      ]))
                    : (isContentEmpty.value)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_8))
                      : _createCommentVNode("", true),
                  (!isContentEmpty.value)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                        _createElementVNode("div", null, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(bumpers.value, (bumper) => {
                            return (_openBlock(), _createBlock(Row, {
                              "vertical-align": "center",
                              class: _normalizeClass(["bumper-item", { selected: selectedBumper.value?.id === bumper.id }]),
                              onClick: ($event: any) => (onClickBumper(bumper)),
                              key: bumper.id
                            }, {
                              default: _withCtx(() => [
                                _createVNode(Column, { width: "86px" }, {
                                  default: _withCtx(() => [
                                    _createElementVNode("div", _hoisted_10, [
                                      _createVNode(ImageView, {
                                        source: _unref(getVideoUploadCoverImage)({
                      video: bumper.video,
                      currentUser: currentUser.value
                    })
                                      }, null, 8, ["source"]),
                                      (bumper.video?.duration)
                                        ? (_openBlock(), _createElementBlock("div", _hoisted_11, _toDisplayString(_unref(getVideoDuration)(bumper.video)), 1))
                                        : _createCommentVNode("", true)
                                    ])
                                  ]),
                                  _: 2
                                }, 1024),
                                _createVNode(Column, { minWidth: "150px" }, {
                                  default: _withCtx(() => [
                                    _createVNode(TextCell, {
                                      text: bumper.name,
                                      class: "bumper-title"
                                    }, null, 8, ["text"])
                                  ]),
                                  _: 2
                                }, 1024),
                                _createVNode(Column, { width: "80px" }, {
                                  default: _withCtx(() => [
                                    _createVNode(Badge, {
                                      title: bumper.visibility?.status,
                                      badgeStyle: getBadgeStyle(bumper),
                                      class: "status-text"
                                    }, null, 8, ["title", "badgeStyle"])
                                  ]),
                                  _: 2
                                }, 1024),
                                _createVNode(Column, { width: "160px" }, {
                                  default: _withCtx(() => [
                                    _createElementVNode("div", _hoisted_12, [
                                      _createElementVNode("div", null, [
                                        _createElementVNode("p", null, [
                                          _createElementVNode("span", _hoisted_13, _toDisplayString(_unref(t)('bumper.start.title')), 1),
                                          _createTextVNode(": " + _toDisplayString(bumper.visibilitySetting?.startPublishAt ? _unref(formatVideoDateTime)(bumper.visibilitySetting?.startPublishAt) : ''), 1)
                                        ]),
                                        _createElementVNode("p", null, [
                                          _createElementVNode("span", _hoisted_14, _toDisplayString(_unref(t)('bumper.end.title')), 1),
                                          _createTextVNode(": " + _toDisplayString(bumper.visibilitySetting?.endPublishAt
                            ? _unref(formatVideoDateTime)(bumper.visibilitySetting?.endPublishAt)
                            : '-'), 1)
                                        ])
                                      ])
                                    ])
                                  ]),
                                  _: 2
                                }, 1024),
                                _createVNode(Column, {
                                  width: "100px",
                                  "horizontal-align": "right"
                                }, {
                                  default: _withCtx(() => [
                                    (calculateBumperPeriod(bumper))
                                      ? (_openBlock(), _createBlock(Badge, {
                                          key: 0,
                                          title: calculateBumperPeriod(bumper),
                                          theme: "dark"
                                        }, null, 8, ["title"]))
                                      : _createCommentVNode("", true)
                                  ]),
                                  _: 2
                                }, 1024)
                              ]),
                              _: 2
                            }, 1032, ["onClick", "class"]))
                          }), 128))
                        ])
                      ]))
                    : _createCommentVNode("", true)
                ], 64))
              : _createCommentVNode("", true),
            _cache[4] || (_cache[4] = _createElementVNode("div", null, null, -1)),
            (bumperLastPage.value > 1)
              ? (_openBlock(), _createBlock(Pagination, {
                  key: 2,
                  lastPage: bumperLastPage.value
                }, null, 8, ["lastPage"]))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true),
      (currentStep.value === 2 && selectedBumper.value)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
            _createElementVNode("div", _hoisted_15, [
              _cache[5] || (_cache[5] = _createElementVNode("p", { class: "section-title" }, "Bumper", -1)),
              _createVNode(Row, {
                "vertical-align": "center",
                class: "bumper-item no-hover-action"
              }, {
                default: _withCtx(() => [
                  _createVNode(Column, { width: "86px" }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_16, [
                        _createVNode(ImageView, {
                          source: _unref(getVideoUploadCoverImage)({
                  video: selectedBumper.value.video,
                  currentUser: currentUser.value
                })
                        }, null, 8, ["source"]),
                        (selectedBumper.value.video?.duration)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_17, _toDisplayString(_unref(getVideoDuration)(selectedBumper.value.video)), 1))
                          : _createCommentVNode("", true)
                      ])
                    ]),
                    _: 1
                  }),
                  _createVNode(Column, { minWidth: "150px" }, {
                    default: _withCtx(() => [
                      _createVNode(TextCell, {
                        text: selectedBumper.value.name,
                        class: "bumper-title"
                      }, null, 8, ["text"])
                    ]),
                    _: 1
                  }),
                  _createVNode(Column, { width: "80px" }, {
                    default: _withCtx(() => [
                      _createVNode(Badge, {
                        title: selectedBumper.value.visibility?.status,
                        badgeStyle: getBadgeStyle(selectedBumper.value),
                        class: "status-text"
                      }, null, 8, ["title", "badgeStyle"])
                    ]),
                    _: 1
                  }),
                  _createVNode(Column, { width: "160px" }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_18, [
                        _createElementVNode("div", _hoisted_19, [
                          _createElementVNode("p", _hoisted_20, [
                            _createElementVNode("span", _hoisted_21, _toDisplayString(_unref(t)('bumper.start.title')), 1),
                            _createTextVNode(": " + _toDisplayString(_unref(formatVideoDateTime)(selectedBumper.value.visibilitySetting?.startPublishAt)), 1)
                          ]),
                          _createElementVNode("p", _hoisted_22, [
                            _createElementVNode("span", _hoisted_23, _toDisplayString(_unref(t)('bumper.end.title')), 1),
                            _createTextVNode(": " + _toDisplayString(selectedBumper.value.visibilitySetting?.endPublishAt
                        ? _unref(formatVideoDateTime)(selectedBumper.value.visibilitySetting?.endPublishAt)
                        : '-'), 1)
                          ])
                        ])
                      ])
                    ]),
                    _: 1
                  }),
                  _createVNode(Column, {
                    width: "100px",
                    "horizontal-align": "right"
                  }, {
                    default: _withCtx(() => [
                      (calculateBumperPeriod(selectedBumper.value))
                        ? (_openBlock(), _createBlock(Badge, {
                            key: 0,
                            title: calculateBumperPeriod(selectedBumper.value),
                            theme: "dark"
                          }, null, 8, ["title"]))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _createElementVNode("div", _hoisted_24, [
              _createElementVNode("p", _hoisted_25, _toDisplayString(_unref(t)('bumperSchedule.chooseBumper.setAs')), 1),
              _createVNode(RadioButtonGroup, {
                options: setAsOptions.value,
                modelValue: selectedOption.value,
                "on-change": onClickRadio
              }, null, 8, ["options", "modelValue"])
            ])
          ], 64))
        : _createCommentVNode("", true)
    ]),
    footer: _withCtx(() => [
      (currentStep.value !== 1 && !isSingleStep.value)
        ? (_openBlock(), _createBlock(Button, {
            key: 0,
            buttonStyle: "text-secondary",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('modalClose')))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_unref(t)('common:button.cancel')), 1)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _: 2
  }, [
    (primaryButtonText.value === _unref(t)('common:button.next'))
      ? {
          name: "primary-btn-suffix",
          fn: _withCtx(() => [
            _cache[6] || (_cache[6] = _createElementVNode("i", { class: "fas fa-chevron-right" }, null, -1))
          ]),
          key: "0"
        }
      : undefined,
    (secondaryButtonText.value === _unref(t)('common:button.back'))
      ? {
          name: "secondary-btn-prefix",
          fn: _withCtx(() => [
            _cache[7] || (_cache[7] = _createElementVNode("i", { class: "fas fa-chevron-left" }, null, -1))
          ]),
          key: "1"
        }
      : undefined
  ]), 1032, ["primaryButtonText", "isDisabledPrimaryButton", "secondaryButtonText"]))
}
}

})