import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = {
  key: 2,
  class: "delete-video-warning-wrapper"
}
const _hoisted_4 = { class: "warning-title" }
const _hoisted_5 = {
  key: 0,
  class: "text-danger"
}
const _hoisted_6 = {
  key: 1,
  class: "text-danger"
}
const _hoisted_7 = {
  key: 2,
  class: "text-danger"
}

import _ from 'lodash';
import { computed, toRefs } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from '@/store';
import { getQueryAsString } from '@/modules/shared/utils/query';
import Modal from '@/modules/shared/components/organisms/modal/Modal.vue';
import Button from '@/modules/shared/components/atoms/button/Button.vue';
import { Video } from '@/modules/shared/types/video.type';

interface DeleteVideoModalProps {
  onClose: () => void;
  // Pass an object on VideoItem.vue for deleting outside VideoDetail
  videoObject?: Video | null;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'DeleteVideoModal',
  props: {
    onClose: { type: Function },
    videoObject: {}
  },
  setup(__props: any) {

const props = __props;
const { onClose, videoObject } = toRefs(props);

const { t } = useI18n();
const store = useStore();
const route = useRoute();
const router = useRouter();

const currentVideo = computed(() => store.state.video.currentVideo);
const teamName = computed(() => getQueryAsString(route.params, 'teamName'));
const projectKey = computed(() => getQueryAsString(route.params, 'projectKey'));
const videoKey = computed(() => getQueryAsString(route.params, 'videoKey') ?? currentVideo.value?.key);
const saveCurrentVideoState = computed(() => store.state.video.saveCurrentVideoState);
const saveErrorCode = computed<number | null>(() => {
  if (saveCurrentVideoState.value.status === 'error') {
    return _.get(saveCurrentVideoState.value, 'error.status', 500);
  }
  return null;
});

const currentVideoName = computed(() => {
  if (videoObject.value) {
    return _.get(videoObject.value, 'title', '');
  }
  return _.get(currentVideo.value, 'title', '');
});

async function deleteVideo() {
  let videoKeyInThisFunction = '';

  if (videoObject.value) {
    videoKeyInThisFunction = _.get(videoObject.value, 'key', '');
  } else if (videoKey.value) {
    videoKeyInThisFunction = videoKey.value;
  }

  await store.dispatch('deleteVideo', videoKeyInThisFunction);
  if (saveErrorCode.value) {
    return;
  }
  if (videoObject.value) {
    // If deleting on SectionVideoList, reload the list.
    const filter = { projectKey: projectKey.value };
    await store.dispatch('loadProjectVideoList', { filter });
  } else {
    // If deleting inside VideoDetail, go back to SectionVideoList.
    await router.push(`/${teamName.value}/projects/${projectKey.value}`);
  }
}

function onSeeAllRelatedBumpers() {
  router.push({
    path: `/${teamName.value}/manage/bumpers`,
    query: { q: videoKey.value },
  });
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(Modal, {
    size: "small",
    title: _unref(t)('video.delete.action', { videoName: currentVideoName.value} ),
    primaryButtonText: _unref(t)('common:button.delete'),
    primaryButtonStyle: "danger",
    isDisabledPrimaryButton: saveCurrentVideoState.value.status ==='saving',
    onClickPrimaryButton: deleteVideo,
    isShowModalHeader: saveErrorCode.value !== 409,
    isShowModalFooter: saveErrorCode.value !== 409,
    onModalClose: _unref(onClose)
  }, {
    body: _withCtx(() => [
      (saveErrorCode.value !== 409)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_unref(t)('video.delete.warning')), 1))
        : _createCommentVNode("", true),
      (saveErrorCode.value !== 409)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_unref(t)('video.delete.description')), 1))
        : _createCommentVNode("", true),
      (saveErrorCode.value === 409)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _cache[2] || (_cache[2] = _createElementVNode("i", { class: "fa-solid fa-exclamation-triangle" }, null, -1)),
            _createElementVNode("div", _hoisted_4, " Video '" + _toDisplayString(currentVideoName.value) + "' is currently in use! ", 1),
            _cache[3] || (_cache[3] = _createElementVNode("div", { class: "warning-description" }, " This video is currently used as a video source in Bumper(s). You need to change video source or remove it first. ", -1)),
            _createVNode(Button, {
              buttonStyle: "text-primary",
              onClick: _withModifiers(onSeeAllRelatedBumpers, ["stop"])
            }, {
              content: _withCtx(() => _cache[0] || (_cache[0] = [
                _createTextVNode(" See all related Bumpers ")
              ])),
              "icon-suffix": _withCtx(() => _cache[1] || (_cache[1] = [
                _createElementVNode("i", { class: "fa-solid fa-arrow-right" }, null, -1)
              ])),
              _: 1
            })
          ]))
        : _createCommentVNode("", true)
    ]),
    footer: _withCtx(() => [
      (saveErrorCode.value === 404)
        ? (_openBlock(), _createElementBlock("span", _hoisted_5, [
            _cache[4] || (_cache[4] = _createElementVNode("i", { class: "fa fa-xmark mr-1" }, null, -1)),
            _createTextVNode(_toDisplayString(_unref(t)('response:error404.title', { item: 'project'})), 1)
          ]))
        : _createCommentVNode("", true),
      (saveErrorCode.value === 422)
        ? (_openBlock(), _createElementBlock("span", _hoisted_6, [
            _cache[5] || (_cache[5] = _createElementVNode("i", { class: "fa fa-xmark mr-1" }, null, -1)),
            _createTextVNode(_toDisplayString(_unref(t)('response:error422.title')), 1)
          ]))
        : _createCommentVNode("", true),
      (saveErrorCode.value === 500)
        ? (_openBlock(), _createElementBlock("span", _hoisted_7, [
            _cache[6] || (_cache[6] = _createElementVNode("i", { class: "fa fa-xmark mr-1" }, null, -1)),
            _createTextVNode(_toDisplayString(_unref(t)('response:error500.title')), 1)
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["title", "primaryButtonText", "isDisabledPrimaryButton", "isShowModalHeader", "isShowModalFooter", "onModalClose"]))
}
}

})