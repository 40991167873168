/* eslint no-shadow: ["error", { "allow": ["state"] }] */
import { loadLanguageList } from '@/services/api/manage';
import { Language, LanguageListState } from '@/types/Language.type';
import { ActionContext } from 'vuex';
import { RootState } from '../type';

type LanguageListActionContext = ActionContext<LanguageListState, RootState>;

const initialState = (): LanguageListState => ({
  languageList: [],
  languageListError: null,
});

const state = initialState();

const mutations = {
  setLanguageList(state: LanguageListState, payload: Language[]) {
    state.languageList = payload;
  },
  setLanguageListError(state: LanguageListState, payload: any) {
    state.languageListError = payload;
  },
};

const actions = {
  async loadLanguageList({ commit }: LanguageListActionContext) {
    try {
      const response = await loadLanguageList();
      let languageArray = response.data.map((item: Language) => ({
        ...item,
        displayName: `${item.name} (${item.code6392T})`,
      }));

      const thai = languageArray.find((item: Language) => item.code6392T === 'tha');
      const english = languageArray.find((item: Language) => item.code6392T === 'eng');

      languageArray = languageArray.filter((item: Language) => item.code6392T !== 'tha' && item.code6392T !== 'eng');
      languageArray = [thai, english, ...languageArray];

      commit('setLanguageList', languageArray);
    } catch (error) {
      commit('setLanguageListError', error);
    }
  },
};

export default {
  state,
  actions,
  mutations,
};
