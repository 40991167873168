import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, withKeys as _withKeys, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createBlock as _createBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "collection-list-container"
}
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { class: "collection-image" }
const _hoisted_4 = { class: "collection-information" }
const _hoisted_5 = { class: "title" }
const _hoisted_6 = {
  key: 0,
  class: "subtitle"
}
const _hoisted_7 = { class: "collection-select-status" }

import _ from 'lodash';
import {
  computed, onMounted, ref, toRefs, watch,
} from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';
import PreLoaderSection from '@/modules/shared/components/atoms/preLoaderSection/PreLoaderSection.vue';
import {
  getCollectionCoverImage,
} from '@/modules/videoDetail/utils/videoManagement';
import { Video } from '@/modules/shared/types/video.type';
import { Collection } from '@/modules/collection/types';
import { getQueryAsString } from '@/modules/shared/utils/query';
import Modal from '@/modules/shared/components/organisms/modal/Modal.vue';
import ImageView from '@/modules/shared/components/atoms/imageView/ImageView.vue';
import Input from '@/modules/shared/components/atoms/input/Input.vue';
import MessageBox from '@/modules/shared/components/molecules/messageBox/MessageBox.vue';
import { useStore } from '../../../store/hooks';
import {
  addVideosToCollectionItemList,
  deleteVideosToCollectionItemList,
  getCollectionById,
} from '../../../services';

interface MoveToCollectionModalProps {
  itemToMove: Video | null | undefined;
  isAddMode?: boolean;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'MoveToCollectionModal',
  props: {
    itemToMove: {},
    isAddMode: { type: Boolean }
  },
  emits: ["modalClose", "success"],
  setup(__props: any, { emit: __emit }) {

const { t } = useI18n();
const store = useStore();
const route = useRoute();

const props = __props;
const { itemToMove, isAddMode } = toRefs(props);

const emit = __emit;

const searchTerm = ref<string>('');
const selectedCollection = ref<Collection | null>(null);
const orderedCollectionList = ref<Collection[]>([]);
const isMoving = ref<boolean>(false);

const teamName = computed(() => getQueryAsString(route.params, 'teamName'));
const projectKey = computed(() => getQueryAsString(route.params, 'projectKey'));

const collectionList = computed(() => store.state.collectionList.collectionList);
const loadCollectionListState = computed(() => store.state.collectionList.loadCollectionListState);
const currentCollection = computed(() => store.state.collection.currentCollection);
const isEmptyCollection = computed(() => _.isEmpty(collectionList.value));

const isEnableSave = computed(() => {
  if (!selectedCollection.value) {
    return false;
  }
  if (selectedCollection.value?.id === currentCollection.value?.id) {
    return false;
  }

  return !isMoving.value;
});

function onClose() {
  emit('modalClose');
}

function reorderCollectionList() {
  if (selectedCollection.value) {
    orderedCollectionList.value = [
      selectedCollection.value,
      ...collectionList.value.filter((item: Collection) => item.id !== selectedCollection.value?.id),
    ];
  } else {
    orderedCollectionList.value = collectionList.value;
  }
}

function onSearch() {
  store.dispatch('loadCollectionList', {
    projectKey: projectKey.value,
    filter: { q: searchTerm.value },
  });
}

function onClickCollection(collection: Collection) {
  selectedCollection.value = collection;
}

async function onMove() {
  const item = itemToMove.value;

  if (!isEnableSave.value || !selectedCollection.value || !item || !projectKey.value) {
    return;
  }

  const { id: targetId } = selectedCollection.value;
  // temperary fix collection list not contain itemlists
  const response = await getCollectionById(projectKey.value, targetId);
  const { itemLists: targetILF } = response.data;
  const videoIds = [item.id];
  isMoving.value = true;
  try {
    if (currentCollection.value) {
      const { id: sourceId, itemLists: sourceIL } = currentCollection.value;

      if (Array.isArray(sourceIL) && sourceIL[0]) {
        await deleteVideosToCollectionItemList(projectKey.value, sourceId, sourceIL[0].id, {
          videoIds,
        });
      }
    }

    if (Array.isArray(targetILF) && targetILF[0]) {
      await addVideosToCollectionItemList(projectKey.value, targetId, targetILF[0].id, { videoIds });
    }

    emit('success', targetId);
  } catch (addError) {
    console.error(addError);
  } finally {
    isMoving.value = false;
    onClose();
  }
}

watch(collectionList, reorderCollectionList, { deep: true });

onMounted(async () => {
  if (isAddMode.value) {
    await store.dispatch('destroyCurrentCollection');
  }
  if (!isAddMode.value && currentCollection.value) {
    selectedCollection.value = currentCollection.value;
  }
  if (!collectionList.value) {
    await store.dispatch('loadCollectionList', { projectKey: projectKey.value });
  } else {
    reorderCollectionList();
  }
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(Modal, {
    size: "medium",
    title: !_unref(isAddMode) ? _unref(t)('collection.move.title') : _unref(t)('collection.add.title'),
    primaryButtonText: !_unref(isAddMode) ? _unref(t)('common:move') : _unref(t)('common:button.add'),
    onClickPrimaryButton: () => onMove(),
    isDisabledPrimaryButton: !isEnableSave.value,
    secondaryButtonText: _unref(t)('common:button.cancel'),
    onClickSecondaryButton: () => onClose(),
    onModalClose: onClose
  }, {
    body: _withCtx(() => [
      _createVNode(Input, {
        onSearch: () => onSearch(),
        onKeyup: _cache[0] || (_cache[0] = _withKeys(() => onSearch(), ["enter"])),
        type: "search",
        class: "form-control search-box",
        placeholder: _unref(t)('collections.searchBy'),
        modelValue: searchTerm.value,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((searchTerm).value = $event)),
        isSearchInput: ""
      }, null, 8, ["onSearch", "placeholder", "modelValue"]),
      (loadCollectionListState.value.status !== 'loading')
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            (!isEmptyCollection.value)
              ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(orderedCollectionList.value, (collection) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: collection.id,
                    onClick: ($event: any) => (onClickCollection(collection)),
                    class: _normalizeClass([{ selected: selectedCollection.value?.id === collection.id }, "collection-item"])
                  }, [
                    _createElementVNode("div", _hoisted_3, [
                      _createVNode(ImageView, {
                        source: _unref(getCollectionCoverImage)({ collection }),
                        imageSize: "small"
                      }, null, 8, ["source"])
                    ]),
                    _createElementVNode("div", _hoisted_4, [
                      _createElementVNode("p", _hoisted_5, _toDisplayString(collection.name), 1),
                      (collection.excerpt)
                        ? (_openBlock(), _createElementBlock("p", _hoisted_6, _toDisplayString(collection.excerpt), 1))
                        : _createCommentVNode("", true)
                    ]),
                    _createElementVNode("div", _hoisted_7, _toDisplayString(selectedCollection.value?.id === collection.id ? _unref(t)('common:multiselect.selected') : ''), 1)
                  ], 10, _hoisted_2))
                }), 128))
              : _createCommentVNode("", true),
            (isEmptyCollection.value)
              ? (_openBlock(), _createBlock(MessageBox, {
                  key: 1,
                  iconClassName: "fas fa-rectangle-list",
                  title: _unref(t)('collectionList.empty.title'),
                  description: _unref(t)('collectionList.empty.description'),
                  onClickButton: () => _ctx.$router.push(`/${teamName.value}/projects/${projectKey.value}/collections`),
                  buttonPrefixIconClassName: "fas fa-plus",
                  buttonTitle: _unref(t)('collectionList.empty.button')
                }, null, 8, ["title", "description", "onClickButton", "buttonTitle"]))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true),
      (loadCollectionListState.value.status === 'loading')
        ? (_openBlock(), _createBlock(PreLoaderSection, {
            key: 1,
            options: { height: 200 }
          }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["title", "primaryButtonText", "onClickPrimaryButton", "isDisabledPrimaryButton", "secondaryButtonText", "onClickSecondaryButton"]))
}
}

})