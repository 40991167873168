import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  class: "modal-header",
  "data-test": "modal-header"
}
const _hoisted_2 = { class: "title-container" }
const _hoisted_3 = {
  key: 0,
  class: "title"
}
const _hoisted_4 = {
  key: 0,
  class: "description"
}

import { toRefs } from 'vue';

interface ModalHeaderProps {
  title?: string;
  description?: string;
  isShowInLineEdit?: boolean;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'ModalHeader',
  props: {
    title: {},
    description: {},
    isShowInLineEdit: { type: Boolean }
  },
  setup(__props: any) {

const props = __props;

const {
  title,
  description,
  isShowInLineEdit,
} = toRefs(props);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_unref(title) && !_unref(isShowInLineEdit))
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_unref(title)), 1))
        : _createCommentVNode("", true),
      _renderSlot(_ctx.$slots, "default")
    ]),
    (_unref(description))
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_unref(description)), 1))
      : _createCommentVNode("", true)
  ]))
}
}

})