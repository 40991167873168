<template>
  <!-- <div class="tooltip-container">
    <slot></slot>
    <span :class="`tooltiptext tooltip-${position}`">
      <slot name="tooltip-text"></slot>
    </span>
  </div> -->
  <Popper class="tooltip" arrow hover :placement="position" :offsetDistance locked>
    <slot></slot>
    <template v-slot:content>
      <slot name="tooltip-text"></slot>
    </template>
  </Popper>
</template>

<script>
import Popper from 'vue3-popper';

export default {
  components: {
    Popper,
  },
  props: {
    position: {
      type: String,
      required: false,
      default: () => 'top',
    },
    offsetDistance: {
      type: String,
      required: false,
      default: () => '5',
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/global-variables.scss";

.tooltip {
    --popper-theme-background-color: #2E3946;
    --popper-theme-background-color-hover: #2E3946;
    --popper-theme-text-color:  #F6F7F8;
    --popper-theme-border-radius: 5px;
    --popper-theme-padding: 8px 12px;
    --popper-theme-box-shadow: 0 0 6px rgba(0, 0, 0, 0.15);
}

:deep(.popper) {
  max-width: 240px;
  padding: 8px 12px;
  font-size: $font-level-8;
  background-color: $grey-800;
  color: $grey-100;
  text-align: center;
  border-radius: $border-radius-5;
  text-transform: none;
}

:deep(.popper #arrow::before) {
  background-color: $grey-800;
  left: 0;
}

:deep(.popper:hover),
:deep(.popper:hover > #arrow::before) {
  background-color: $grey-800;
}

/* .tooltip-container {
  position: relative;
  display: inline-block;
  cursor: pointer;
  &:hover {
    .tooltiptext {
      visibility: visible;
      opacity: 1;
      white-space: pre-wrap;
      width: max-content;
    }
  }
} */

/* .tooltiptext {
  position: absolute;
  max-width: 240px;
  width: 240px;
  padding: 8px 12px;
  z-index: 1;
  isolation: isolate;

  font-size: $font-level-8;
  background-color: $grey-800;
  color: $grey-100;
  text-align: center;
  border-radius: $border-radius-5;

  visibility: hidden;
  opacity: 0;
  transition: opacity 300ms;
} */

/* Show the tooltip text when you mouse over the tooltip container */
/* .tooltip-container:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
} */

/* .tooltip-top {
  bottom: 125%;
  left: 50%;
  transform: translateX(-50%);
  &::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: $grey-800 transparent transparent transparent;
  }
} */

/* .tooltip-right {
  top: -5px;
  left: 125%;
  &::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 100%;
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent $grey-800 transparent transparent;
  }
} */

/* .tooltip-bottom {
  top: 135%;
  left: 50%;
  transform: translateX(-50%);
  &::after {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent $grey-800 transparent;
  }
} */

/* .tooltip-left {
  top: -5px;
  bottom: auto;
  right: 128%;
  &::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 100%;
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent transparent $grey-800;
  }
} */
</style>
