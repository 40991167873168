import store from '@/store';
import user from '@/modules/user/store/user';
import type { RouteRecordRaw } from 'vue-router';
import projectList from '../store';

export function createProjectListRoutes(): RouteRecordRaw[] {
  return [
    {
      name: 'projects',
      path: 'projects',
      component: () => import(/* webpackChunkName: "ProjectListChrome" */ '../layouts/ProjectListChrome.vue'),
      beforeEnter: (to, from, next) => {
        if (!store.hasModule('projectList')) {
          store.registerModule('projectList', projectList);
        }
        if (!store.hasModule('user')) {
          store.registerModule('user', user);
        }
        next();
      },
      children: [
        {
          name: 'projectList',
          path: '',
          component: () => import(/* webpackChunkName: "ProjectList" */ '../pages/ProjectList.vue'),
        },
        {
          name: 'deletedProjects',
          path: 'deleted',
          component: () => import(/* webpackChunkName: "DeletedProjectList" */ '../pages/DeletedProjectList.vue'),
        },
      ],
    },
  ];
}
