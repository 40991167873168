<script setup lang="ts">
import type { InviteProjectMembershipsState } from '@/modules/projectMember/types';
import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';
import cloneDeep from 'lodash/cloneDeep';
import { computed, watch, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';
import AdvancedInfoToast from '@/modules/shared/components/molecules/toasts/AdvancedInfoToast.vue';
import ErrorToast from '@/modules/shared/components/molecules/toasts/ErrorToast.vue';
import InfoToast from '@/modules/shared/components/molecules/toasts/InfoToast.vue';
import { useStore } from '../../../store/hooks';

interface InvitationResult {
  invitedToWorkspaceEmails: Array<string>;
  addedToProjectEmails: Array<string>;
  errorEmails: Array<string>;
}

const defaultInvitationResult: Readonly<InvitationResult> = Object.freeze({
  invitedToWorkspaceEmails: [],
  addedToProjectEmails: [],
  errorEmails: [],
});

const invitationResult = ref<InvitationResult>(cloneDeep(defaultInvitationResult));
const isMultipleInvitationResults = ref<boolean>(false);

const { t } = useI18n();
const store = useStore();
const route = useRoute();
const inviteProjectMembershipsState = computed<InviteProjectMembershipsState>(() => store.state.projectMember.inviteProjectMembershipsState);
const projectKey = computed(() => route.params.projectKey);

watch(inviteProjectMembershipsState, () => {
  if (
    inviteProjectMembershipsState.value.status === 'success'
    && 'response' in inviteProjectMembershipsState.value
    && !isNil(inviteProjectMembershipsState.value.response)
  ) {
    const { response } = inviteProjectMembershipsState.value;

    let hasPassedResult = false;
    let hasAddedResult = false;
    let hasFailedResult = false;

    invitationResult.value = response.reduce((previousValue, currentValue) => {
      if (currentValue.status === 'passed' && currentValue.account?.email) {
        previousValue.invitedToWorkspaceEmails.push(currentValue.account.email);
        hasPassedResult = true;
      }

      if (currentValue.status === 'added' && currentValue?.email) {
        previousValue.addedToProjectEmails.push(currentValue.email);
        hasAddedResult = true;
      }

      if (currentValue.status === 'failed') {
        previousValue.errorEmails.push(currentValue.account?.email ?? currentValue.email ?? '');
        hasFailedResult = true;
      }

      return previousValue;
    }, cloneDeep(defaultInvitationResult));

    isMultipleInvitationResults.value = [hasPassedResult, hasAddedResult, hasFailedResult]
      .filter((boolean) => boolean).length >= 2;

    store.dispatch('loadProjectManagement', projectKey.value);
  } else {
    invitationResult.value = cloneDeep(defaultInvitationResult);
    isMultipleInvitationResults.value = false;
  }
});
</script>

<template>
  <template v-if="isMultipleInvitationResults">
    <AdvancedInfoToast
      :messages="[
        ...(!isEmpty(invitationResult.invitedToWorkspaceEmails) ? [{
          title: t('invite.response.invited.title', { count: invitationResult.invitedToWorkspaceEmails.length }),
          description: t('invite.response.invited.description', { count: invitationResult.invitedToWorkspaceEmails.length }),
        }] : []),
        ...(!isEmpty(invitationResult.addedToProjectEmails) ? [{
          title: t('invite.response.addedToProject.title', { count: invitationResult.addedToProjectEmails.length }),
          description: invitationResult.addedToProjectEmails.join(', '),
        }] : []),
        ...(!isEmpty(invitationResult.errorEmails) ? [{
          title: t('invite.response.failed.title', { count: invitationResult.errorEmails.length }),
          description: invitationResult.errorEmails.join(', '),
        }] : []),
      ]"
    />
  </template>
  <template v-else>
    <InfoToast
      v-if="invitationResult.invitedToWorkspaceEmails.length"
      :title="t('invite.response.invited.title', { count: invitationResult.invitedToWorkspaceEmails.length })"
      :description="t('invite.response.invited.description', { count: invitationResult.invitedToWorkspaceEmails.length })"
    />
    <InfoToast
      v-if="invitationResult.addedToProjectEmails.length"
      :title="t('invite.response.addedToProject.title', { count: invitationResult.addedToProjectEmails.length })"
      :description="invitationResult.addedToProjectEmails.join(', ')"
    />
    <ErrorToast
      v-if="invitationResult.errorEmails.length"
      :title="t('invite.response.failed.title', { count: invitationResult.errorEmails.length })"
      :description="invitationResult.errorEmails.join(', ')"
    />
  </template>
  <ErrorToast
    v-if="inviteProjectMembershipsState.status === 'error'"
    :title="t('response:error500.title')"
  />
</template>

<style scoped lang="scss">

</style>
