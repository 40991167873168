import { axiosAPI, axiosKeyServerAPI } from '@/plugins/axios';
import { ListApiResponse } from '@/types/Axios.type';
import {
  CreatePlaybackChannel,
  EnableMultiDrm, MultiDrm,
  WidevineJwtKey,
} from '@/modules/multiDrm/types';

export const getAllMultiDrmConfigs = () => axiosKeyServerAPI.get<ListApiResponse<MultiDrm[]>>('/drm-configs');

// Enable Step
export const enableMultiDrmConfig = (payload: EnableMultiDrm) => axiosKeyServerAPI.post<MultiDrm>('/drm-configs', payload);

// Step 1.1
export const uploadFairplayCertificate = (drmConfigId: string, payload: FormData) => axiosKeyServerAPI.post<MultiDrm>(`/drm-configs/${drmConfigId}/fairplay-ksm`, payload);
export const updateFairplayCertificate = (drmConfigId: string, payload: FormData) => axiosKeyServerAPI.put<MultiDrm>(`/drm-configs/${drmConfigId}/fairplay-ksm`, payload);

// Step 1.2
interface RegisterWidevineServiceProps {
  id: string;
  hdcpType: string;
  secLv: number;
}

export const registerWidevineService = (props: RegisterWidevineServiceProps) => {
  const { id, ...rest } = props;
  return axiosKeyServerAPI.post<MultiDrm>(`/drm-configs/${id}/widevine`, rest);
};

export const editWidevineService = (props: RegisterWidevineServiceProps) => {
  const { id, ...rest } = props;
  return axiosKeyServerAPI.put<MultiDrm>(`/drm-configs/${id}/widevine/hdcp`, rest);
};

// Step 2
export const getWidevineJwtKeysList = (drmConfigId: string) => axiosKeyServerAPI.get<WidevineJwtKey[]>(`/drm-configs/${drmConfigId}/jwtKeys`);
export const addWidevineJwtKey = (drmConfigId: string, payload: FormData) => axiosKeyServerAPI.post<MultiDrm>(`/drm-configs/${drmConfigId}/jwtKeys`, payload);
export const deleteWidevineJwtKey = (drmConfigId: string, publicDrmKeyId: string) => axiosKeyServerAPI.delete<MultiDrm>(`/drm-configs/${drmConfigId}/jwtKeys/${publicDrmKeyId}`);

// Step 3
export const registerMediaService = (drmConfigId: string) => axiosKeyServerAPI.post<MultiDrm>(`/drm-configs/${drmConfigId}/media-service`);

// Step 4
export const getPresetListForDrmConfig = () => axiosKeyServerAPI.get<MultiDrm>('/drm-configs/presets');

// Step 5
export const activatePlaybackChannel = (payload: CreatePlaybackChannel) => axiosAPI.post('/playback-channels/drm-configs/activate', payload);
