import { useStore as baseUseStore } from 'vuex';
import { storeKey } from '@/store';
import type { TeamFeaturesState } from '@/modules/shared/types/teamFeatures.type';
import type { ProjectState } from '@/modules/shared/types/project.type';
import type { UserState } from '@/modules/user';

export const useStore = () => baseUseStore<{
  project: ProjectState;
  teamFeatures: TeamFeaturesState;
  user: UserState;
}>(storeKey);
