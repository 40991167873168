<template>
  <div class="menu-item-container" :class="{ collapse: isCollapse }">
    <div
      class="menu-item-button"
      :class="{
        primary: isPrimary,
        secondary: !isPrimary,
        active: isActive && (!isSubMenuOpen || isCollapse),
        'disable-hover': Boolean(subMenus) && isSubMenuOpen,
      }"
      @click="onMenuItemClick"
    >
      <div :class="['menu-item-title', isCollapse ? 'is-collapse' : '']">
        <slot name="menu-item-prefix" v-if="$slots['menu-item-prefix']" />
        <div v-else :class="['menu-item-icon', isCollapse ? 'is-collapse' : '']">
          <i :class="iconClassName" />
        </div>
        <span class="menu-item-name">{{ title }}</span>
      </div>
      <div class="suffix">
        <div v-if="count || count === 0" class="circle-info">{{ count }}</div>
        <div v-else-if="$slots['menu-item-suffix']">
          <slot name="menu-item-suffix" />
        </div>
        <div v-else-if="subMenus" @click.stop="toggleSubMenu">
          <i v-if="!isSubMenuOpen" class="fas fa-chevron-down" />
          <i v-else class="fas fa-chevron-up" />
        </div>
        <div v-else-if="isExternalLink">
          <i class="fas fa-external-link" />
        </div>
        <div
          class="menu-items-border-right"
          v-if="isActive && (!isSubMenuOpen || isCollapse)" />
      </div>
    </div>
    <div class="menu-item-submenu" v-if="isSubMenuOpen">
      <router-link
        v-for="(menu, index) in subMenus"
        :key="index"
        :to="menu.path"
        :class="{ primary: isPrimary, secondary: !isPrimary, active: isSubMenuActive(menu.path) }"
      >
        {{ menu.title }}
      </router-link>
    </div>
  </div>
</template>

<script setup>
import { useRouter, useRoute } from 'vue-router';
import { ref, computed } from 'vue';

const route = useRoute();
const router = useRouter();

const props = defineProps({
  title: {
    type: String,
    required: true,
    default: '',
  },
  path: {
    type: [String, Object, Boolean],
    default: '',
  },
  iconClassName: {
    type: String,
    default: '',
  },
  isCollapse: {
    type: Boolean,
    default: false,
  },
  isPrimary: {
    type: Boolean,
    default: false,
  },
  count: {
    type: Number,
  },
  subMenus: {
    type: Array,
  },
  isMenuActive: {
    type: Boolean,
    default: false,
  },
  isExternalLink: {
    type: Boolean,
    default: false,
  },
});

const isSubMenuOpen = ref(props.subMenus);
const hasActiveSubMenu = ref();

const isActive = computed(
  () => {
    if (props.subMenus) {
      if (props.isCollapse) {
        return props.path === route?.path;
      }
      return props.path === route?.path && hasActiveSubMenu.value;
    }
    return props.path === route?.path || (props.path.name && props.path.name === route?.name) || props.isMenuActive;
  },
);

const toggleSubMenu = () => {
  isSubMenuOpen.value = !isSubMenuOpen.value;
};

const onMenuItemClick = () => {
  if (!isActive.value) {
    if (props.path) {
      router.push(props.path);
    }
    if (props.subMenus) {
      isSubMenuOpen.value = true;
    }
  } else if (props.subMenus?.length) {
    toggleSubMenu();
  }
};
const isSubMenuActive = (path) => {
  if (route.fullPath === path) {
    hasActiveSubMenu.value = true;
    return true;
  }
  return false;
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/global-variables.scss';
@import '~@/assets/scss/breakpoint.scss';

.menu-item-container {
  display: flex;
  flex-direction: column;
  width: 100%;

  .menu-item-button {
    width: 100%;
    padding: $spacing-base;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: $font-level-7;
    line-height: 120%;
    cursor: pointer;
    position: relative;

    .menu-item-title {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: $spacing-8;

      &.is-collapse {
        justify-content: center;
      }

      .menu-item-name {
        text-overflow: ellipsis;
        transition: all 0.2s ease;
        opacity: 1;

        @media screen and (max-width: $max-layout-lg) {
          font-size: 16px;
        }
      }

      .menu-item-icon {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        &.is-collapse {
          justify-content: center;
        }

        @media screen and (max-width: $max-layout-lg) {
          justify-content: flex-start;
        }
      }
    }

    .circle-info {
      background-color: $grey-200;
      color: $grey-600;
      font-size: $font-level-8;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .menu-items-border-right {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      width: 4px;
      background-color: $ci-primary;
    }

    &.primary {
      color: #ffffff;
      font-size: $font-level-7;

      .menu-item-icon {
        width: 22px;
        height: 22px;

        i {
          font-size: 20px;
        }
      }
      &.active {
        background-color: $grey-700;
      }

      &:not(.disable-hover) {
        &:hover {
          background-color: $grey-700;
        }
      }
    }

    &.secondary {
      color: $grey-800;

      .menu-item-icon {
        width: 15px;

        i {
          font-size: 14px;
        }
      }
      &.active {
        background-color: $bg-blue;
      }

      &:not(.disable-hover) {
        &:hover {
          background-color: $bg-blue;
        }
      }
    }
  }

  .menu-item-submenu {
    a {
      display: flex;
      width: 100%;
      color: $grey-600;

      padding: $spacing-base $spacing-base $spacing-base 40px;
      cursor: pointer;
      font-size: $font-level-7;
      line-height: 150%;

      &.primary {
        &.active {
          background-color: $grey-700;
        }

        &:hover {
          background-color: $grey-700;
        }
      }

      &.secondary {
        &.active {
          background-color: $bg-blue;
        }

        &:hover {
          background-color: $bg-blue;
        }
      }
    }
  }

  &.collapse {

    .menu-item-name,
    .suffix,
    .menu-item-submenu {
      opacity: 0 !important;
      display: none;
    }

    .menu-item-title {
      width: 100%;
    }
  }
}

.fa-external-link {
  font-size: 11px;
}
</style>
