<template>
  <div
    v-if="profile"
    class="preset-profile"
    :class="{ disabled: !profile.enabled }"
  >
    <div class="format-label label">
      {{t('common:videoFormat')}}:
      <span class="format" data-test="preset-profile-format-type">
        {{profile.formatType}}
      </span>
    </div>
    <div class="resolution-container" data-test="preset-profile-resolution-list">
      <Badge
        v-for="track in getEnabledResolutions(profile.videoTracks)"
        :key="track.name"
        :title="getBadgeTitle(track)"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { toRefs } from 'vue';
import { useI18n } from 'vue-i18n';
import {
  getEnabledResolutions,
  isTrackEnableVideoEncryption,
} from '@/modules/preset/utils/presetManagement';
import Badge from '@/modules/shared/components/atoms/badge/Badge.vue';
import { DefaultVideoTrack, PresetProfile, VideoTrack } from '@/modules/preset/types';

interface PresetSummaryInfoProps {
  profile: PresetProfile | null | undefined;
}

const props = defineProps<PresetSummaryInfoProps>();
const { profile } = toRefs(props);

const { t } = useI18n();

function getBadgeTitle(track: VideoTrack | DefaultVideoTrack): string {
  let result = track.name;

  if (isTrackEnableVideoEncryption(track)) {
    result = `${result} ${t('glossary:encrypted')}`;
  }

  return result;
}
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/global-variables';

.preset-profile {
  &:last-child {
    margin-top: $spacing-base;
  }
}
.resolution-container {
  margin-top: $spacing-8;
  display: flex;
  flex-wrap: wrap;
  gap: $spacing-8;
}
.gray-badge {
  padding: 2px 8px;
  margin-top: $spacing-8;
  margin-right: $spacing-8;
  font-size: $font-size-base;
  font-weight: $font-weight-base;
  color: $grey-800;
  background-color: $grey-200;
  display: inline-block;
  border-radius: $border-radius-5;
}

.label {
  text-transform: capitalize;
}

.format {
  text-transform: uppercase;
}

.encrypted-text {
  margin-left: 1px;
  text-transform: capitalize;
}
</style>
