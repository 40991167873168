import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["data-test"]
const _hoisted_2 = {
  key: 0,
  class: "primary-text"
}
const _hoisted_3 = {
  key: 1,
  class: "sub-text"
}
const _hoisted_4 = {
  key: 0,
  class: "text"
}
const _hoisted_5 = {
  key: 1,
  class: "text"
}

import { toRefs } from 'vue';
import { isArray } from 'lodash';

interface TextCellProps {
  primaryText?: string;
  subText?: string;
  text?: string | string[];
  className?: string;
  dataTest?: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'TextCell',
  props: {
    primaryText: {},
    subText: {},
    text: {},
    className: {},
    dataTest: {}
  },
  setup(__props: any) {

const props = __props;
const {
  primaryText,
  subText,
  text,
  dataTest,
} = toRefs(props);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(`text-cell cell ${_ctx.className}`),
    "data-test": _unref(dataTest)
  }, [
    (_unref(primaryText))
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_unref(primaryText)), 1))
      : _createCommentVNode("", true),
    (_unref(subText))
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_unref(subText)), 1))
      : _createCommentVNode("", true),
    (_unref(text))
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
          (typeof _unref(text) === 'string')
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_unref(text)), 1))
            : (_unref(isArray)(_unref(text)))
              ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(text), (line) => {
                    return (_openBlock(), _createElementBlock("div", { key: line }, _toDisplayString(line), 1))
                  }), 128))
                ]))
              : _createCommentVNode("", true)
        ], 64))
      : _createCommentVNode("", true)
  ], 10, _hoisted_1))
}
}

})