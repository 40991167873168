<template>
  <Modal
    v-if="caption"
    size="small"
    :title="t('video.subtitle.modal.status.title')"
    :primaryButtonText="t('video.subtitle.modal.status.change')"
    primaryButtonStyle="primary"
    :onClickPrimaryButton="onSave"
    :onClickSecondaryButton="onClose"
    :isSaving="isSaving"
    @modalClose="onClose"
  >
    <template v-slot:body>
      <div>
        <div class="radio-heading">
          {{ t('video.subtitle.status.choose') }}
          <b>'{{ caption.customName }}'</b>
        </div>
        <RadioButtonGroup :modelValue="selectedOption" :options="options" :onChange="onChange" />
      </div>
    </template>
    <template v-slot:footer>
      <div class="error-message" v-if="error">
        {{ _.get(error, 'response.data.message') }}
      </div>
    </template>
  </Modal>
</template>

<script lang="ts" setup>
import { getQueryAsString } from '@/modules/shared/utils/query';
import _ from 'lodash';
import { useI18n } from 'vue-i18n';
import { ref, computed, toRefs } from 'vue';
import { updateCaptionDraftStatus } from '@/modules/videoCaption/services';
import Modal from '@/modules/shared/components/organisms/modal/Modal.vue';
import RadioButtonGroup from '@/modules/shared/components/molecules/radioButtonGroup/RadioButtonGroup.vue';
import { useRoute } from 'vue-router';
import { useStore } from '../../../store/hooks';

const { t } = useI18n();
const store = useStore();

interface ChangeVideoCaptionStatusModalProps {
  captionIndex: number;
  onClose: () => void;
}

const props = defineProps<ChangeVideoCaptionStatusModalProps>();
const { captionIndex, onClose } = toRefs(props);

const route = useRoute();

const videoKey = computed(() => store.state.video.currentVideo?.key);
const caption = computed(() => store.state.captionList?.captionList?.[captionIndex.value]);
const currentSubtitlePage = computed(() => getQueryAsString(route.query, 'page') ?? '1');
const publishStatus = computed(() => caption.value?.publishStatus);
const options = computed(() => [
  {
    label: t('video.subtitle.status.draft'),
    value: 'draft',
  },
  {
    label: t('video.subtitle.status.publish'),
    value: 'published',
  },
]);

const selectedOption = ref<string>(publishStatus.value ?? 'draft');
const isSaving = ref<boolean>(false);
const error = ref<any>(null);

const onChange = (value: string) => {
  selectedOption.value = value;
};

const onSave = async () => {
  if (!caption.value) {
    return;
  }

  isSaving.value = true;

  try {
    if (publishStatus.value !== selectedOption.value) {
      await updateCaptionDraftStatus(caption.value.key, {
        ignoreSwapConstrain: true,
        publishStatus: selectedOption.value as 'published' | 'draft',
      });
      await store.dispatch('loadCaptionList', {
        videoKey: videoKey.value,
        page: currentSubtitlePage.value,
      });

      onClose.value();
    }
    isSaving.value = false;
  } catch (err) {
    error.value = err;
    isSaving.value = false;
  }
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/global-variables';

.radio-heading {
  margin-bottom: 8px;
}

.error-message {
  color: $danger;
}
</style>
