<template>
  <div>
    <Section v-if="loadPlaybackChannelListState.status === 'loading'">
      <PreLoaderSection :options="{ height: 350}" />
    </Section>
    <Section
      v-if="loadPlaybackChannelListState.status === 'success'"
      :title="$t('teamSettings.signUrl.section.title')"
      :description="$t('teamSettings.signUrl.section.description')">
      <SectionItem
        :disabledCollapseAction="true"
        :isDefaultContentShowing="false"
        :showHeaderBorder="true">
        <DataTable responsiveLayout="c">
          <template v-slot:table-header>
            <Row>
              <Column>
                <HeaderCell :text="$t('common:form.name')" />
              </Column>
              <Column>
                <HeaderCell :text="$t('common:form.status')" />
              </Column>
              <Column width="120px" />
            </Row>
          </template>
          <template v-slot:table-content>
            <Row
              @click="onClickPlaybackChannel(playbackChannel.id)"
              v-for="playbackChannel in playbackChannelList" :key="playbackChannel.id">
              <Column>
                <TextCell :primaryText="playbackChannel.name" />
              </Column>
              <Column>
                <Badge
                  :title="getSignedUrlStatusValues(playbackChannel)"
                  :badgeStyle="playbackChannel.signedUrl.required ? 'success' : 'secondary'"
                  size="small" />
              </Column>
              <Column width="120px" isActionColumn>
                <Button buttonStyle="text-secondary" :url="`/${teamName}/manage/playback-channels/${playbackChannel.id}`">
                  {{ isManagerOrAdmin ? "Edit" : "View" }}
                  <template v-slot:icon-suffix>
                    <i class="fa fa-chevron-right" />
                  </template>
                </Button>
              </Column>
              <template v-slot:responsive-c-button>
                <Button buttonStyle="text-secondary" isSquare>
                  <i class="fa fa-chevron-right" />
                </Button>
              </template>
            </Row>
          </template>
        </DataTable>
      </SectionItem>
      <ErrorMessage
        v-if="loadPlaybackChannelListState.status === 'error'"
        :title="loadPlaybackChannelListState.error?.message"
        :statusCode="loadPlaybackChannelListState.error?.statusCode" />
    </Section>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import ErrorMessage from '@/modules/shared/components/atoms/errorMessage/ErrorMessage.vue';
import Section from '@/modules/shared/components/organisms/section/Section.vue';
import SectionItem from '@/modules/shared/components/molecules/sectionItem/SectionItem.vue';
import Button from '@/modules/shared/components/atoms/button/Button.vue';
import PreLoaderSection from '@/modules/shared/components/atoms/preLoaderSection/PreLoaderSection.vue';
import DataTable from '@/modules/shared/components/organisms/dataTable/DataTable.vue';
import Column from '@/modules/shared/components/molecules/column/Column.vue';
import Row from '@/modules/shared/components/molecules/row/Row.vue';
import HeaderCell from '@/modules/shared/components/molecules/headerCell/HeaderCell.vue';
import TextCell from '@/modules/shared/components/molecules/textCell/TextCell.vue';
import Badge from '@/modules/shared/components/atoms/badge/Badge.vue';

export default {
  components: {
    ErrorMessage,
    Section,
    SectionItem,
    Button,
    PreLoaderSection,
    DataTable,
    Column,
    Row,
    HeaderCell,
    TextCell,
    Badge,
  },
  created() {
    this.$store.dispatch('loadPlaybackChannelList');
  },
  computed: {
    ...mapState({
      playbackChannelList: (state) => state.playbackChannelList.playbackChannelList,
      loadPlaybackChannelListState: (state) => state.playbackChannelList.loadPlaybackChannelListState,
    }),
    ...mapGetters(['isManagerOrAdmin']),
    teamName() {
      return this.$route.params.teamName;
    },
  },
  methods: {
    onClickPlaybackChannel(playbackChannelId) {
      this.$router.push(`/${this.teamName}/manage/playback-channels/${playbackChannelId}`);
    },
    getSignedUrlStatusValues(playbackChannel) {
      if (playbackChannel.signedUrl.required) {
        return this.$t('project.setup.playbackChannel.signedURL.isEnabled');
      }
      return this.$t('project.setup.playbackChannel.signedURL.isDisabled');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/global-variables.scss';

</style>
