import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, withModifiers as _withModifiers, withCtx as _withCtx } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "select-option-container" }
const _hoisted_3 = { class: "highlight-user" }

import type { ProjectMember } from '@/modules/projectMember/types';
import { getMembershipRolesOptions } from '@/modules/projectMember/utils';
import _ from 'lodash';
import {
  computed, onMounted, ref, toRefs,
} from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';
import type { ObjectWithStringValue } from '@/types/Object.type';
import { getQueryAsString } from '@/modules/shared/utils/query';
import SelectOption from '@/modules/shared/components/atoms/selectOption/SelectOption.vue';
import Modal from '@/modules/shared/components/organisms/modal/Modal.vue';
import ErrorMessage from '@/modules/shared/components/atoms/errorMessage/ErrorMessage.vue';
import { useStore } from '../../../store/hooks';

interface ProjectMembershipChangeRoleModalProps {
  member: ProjectMember | null;
  onCloseModal: () => void;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'ChangeProjectAccessLevelModal',
  props: {
    member: {},
    onCloseModal: { type: Function }
  },
  emits: ["success"],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const { member, onCloseModal } = toRefs(props);

const emit = __emit;

const roleOptions = getMembershipRolesOptions();

const { t } = useI18n();
const store = useStore();
const route = useRoute();

const selectedRoleMember = ref<ObjectWithStringValue | undefined>(undefined);

const projectKey = computed(() => getQueryAsString(route.params, 'projectKey'));
const editProjectMembershipState = computed(() => store.state.projectMember.editProjectMembershipState);
const isSaving = computed(() => editProjectMembershipState.value.status === 'saving');
const isError = computed(() => editProjectMembershipState.value.status === 'error');
const memberName = computed(() => _.get(member.value, 'account.name', ''));
const editProjectMembershipErrorCode = computed(() => {
  if (editProjectMembershipState.value.error) {
    return _.get(editProjectMembershipState.value, 'error.response.status', null);
  }
  return null;
});

async function onSubmit() {
  if (_.isNil(member.value) || _.isNil(selectedRoleMember.value)) {
    return;
  }

  const { accountId } = member.value;
  await store.dispatch('editProjectMembership', {
    projectKey: projectKey.value,
    accountId,
    role: {
      projectRole: selectedRoleMember.value.value,
    },
  });
  emit('success');
  onCloseModal.value();
}

async function onClose() {
  await store.dispatch('clearEditProjectMembershipState');
  onCloseModal.value();
}

onMounted(() => {
  if (!_.isNil(member.value)) {
    selectedRoleMember.value = roleOptions.find((option) => option.value === member.value?.projectRole);
  }
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(Modal, {
    size: "small",
    title: _unref(t)('project.manage.members.permissionEdit.title'),
    primaryButtonText: !isError.value && !isSaving.value ? _unref(t)('project.manage.members.permissionEdit.button') : _unref(t)('common:button.editing'),
    isDisabledPrimaryButton: isError.value || isSaving.value,
    isSaving: isSaving.value,
    onClickPrimaryButton: onSubmit,
    secondaryButtonText: _unref(t)('common:button.cancel'),
    onClickSecondaryButton: onClose,
    onModalClose: onClose
  }, {
    body: _withCtx(() => [
      _createElementVNode("form", {
        onSubmit: _withModifiers(onSubmit, ["prevent"]),
        novalidate: ""
      }, [
        (!isError.value)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("label", null, [
                  _createTextVNode(_toDisplayString(_unref(t)('project.manage.members.permissionEdit.subTitle')) + " ", 1),
                  _createElementVNode("span", _hoisted_3, _toDisplayString(memberName.value), 1)
                ]),
                _createVNode(SelectOption, {
                  placeholder: _unref(t)('project.manage.members.permissionPlaceholder'),
                  modelValue: selectedRoleMember.value,
                  options: _unref(roleOptions),
                  label: "key",
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = (newValue) => (selectedRoleMember.value = newValue))
                }, null, 8, ["placeholder", "modelValue", "options"])
              ])
            ]))
          : _createCommentVNode("", true),
        (isError.value)
          ? (_openBlock(), _createBlock(ErrorMessage, {
              key: 1,
              title: _unref(t)('project.manage.members.permissionEdit.error'),
              description: _unref(t)('response:retry'),
              statusCode: editProjectMembershipErrorCode.value
            }, null, 8, ["title", "description", "statusCode"]))
          : _createCommentVNode("", true)
      ], 32)
    ]),
    _: 1
  }, 8, ["title", "primaryButtonText", "isDisabledPrimaryButton", "isSaving", "secondaryButtonText"]))
}
}

})