import qs from 'qs';
import { axiosAPI } from '@/plugins/axios';
import type { Filter, ListApiResponse } from '@/types/Axios.type';
import type { CaptionProfile } from '@/modules/captionProfile/types';

export function getCaptionProfileList(filter?: Filter) {
  const query = qs.stringify(filter, { addQueryPrefix: true, skipNulls: true });
  return axiosAPI.get<ListApiResponse<CaptionProfile[]>>(`/auto-generate-caption-settings${query}`);
}

export function getCaptionProfileByKey(profileKey: string) {
  return axiosAPI.get<CaptionProfile>(`/auto-generate-caption-settings/${profileKey}`);
}

export function setProjectPrimaryPlaybackChannel(projectKey: string, payload: { primaryPlaybackChannelId: string }) {
  return axiosAPI.post(`/projects/${projectKey}/playback-channels/primary`, payload);
}

export function removeProjectPlaybackChannel(projectKey: string, payload: { playbackChannelIds: string }) {
  return axiosAPI.post(`/projects/${projectKey}/playback-channels/remove`, payload);
}

export function addProjectPlaybackChannel(projectKey: string, payload: { playbackChannelIds: string }) {
  return axiosAPI.post(`/projects/${projectKey}/playback-channels/add`, payload);
}
