import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "section" }
const _hoisted_2 = {
  key: 0,
  class: "section-header"
}
const _hoisted_3 = {
  key: 0,
  class: "section-label"
}
const _hoisted_4 = {
  key: 0,
  class: "suffix"
}
const _hoisted_5 = { class: "action-container" }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { key: 1 }
const _hoisted_8 = {
  key: 1,
  class: "content"
}

import { toRefs } from 'vue';
import Button from '@/modules/shared/components/atoms/button/Button.vue';
import { useI18n } from 'vue-i18n';

interface VideoUploadDefaultBoxProps {
  label?: string;
  isEditing?: boolean;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'VideoUploadDefaultBox',
  props: {
    label: {},
    isEditing: { type: Boolean }
  },
  setup(__props: any) {

const props = __props;
const {
  label,
  isEditing,
} = toRefs(props);

const { t } = useI18n();

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_unref(label))
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_unref(label))
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createElementVNode("span", null, _toDisplayString(_unref(label)), 1),
                (_ctx.$slots['suffix-label'])
                  ? (_openBlock(), _createElementBlock("span", _hoisted_4, [
                      _renderSlot(_ctx.$slots, "suffix-label")
                    ]))
                  : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_5, [
            _renderSlot(_ctx.$slots, "action"),
            (!_ctx.$slots['action'])
              ? (_openBlock(), _createBlock(Button, {
                  key: 0,
                  type: "button",
                  buttonStyle: "link-primary",
                  buttonClasses: "action",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('onClickEdit'))),
                  dataTest: "edit-upload-output-button"
                }, {
                  content: _withCtx(() => [
                    (!_unref(isEditing))
                      ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_unref(t)('common:multiselect.select')), 1))
                      : _createCommentVNode("", true),
                    (_unref(isEditing))
                      ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(_unref(t)('common:button.hide')), 1))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.$slots['default'])
      ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
          _renderSlot(_ctx.$slots, "default")
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})