import { defineComponent as _defineComponent } from 'vue'
import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "action-button-desktop",
  "data-test": "action-button-desktop"
}
const _hoisted_2 = {
  class: "action-button-mobile",
  "data-test": "action-button-mobile"
}

import DropdownItem from '@/modules/shared/components/atoms/dropdownItem/DropdownItem.vue';
import Dropdown from '@/modules/shared/components/atoms/dropdown/Dropdown.vue';
import Button from '@/modules/shared/components/atoms/button/Button.vue';
import { computed, toRefs } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from '@/modules/projectVideoList/store/hooks';

interface RestoreAllDeletedVideosProps {
  onClickRestoreAllVideos: () => void;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'RestoreAllDeletedVideosButton',
  props: {
    onClickRestoreAllVideos: { type: Function, default: () => {} }
  },
  setup(__props: any) {

const props = __props;
const { onClickRestoreAllVideos } = toRefs(props);

const store = useStore();
const { t } = useI18n();

const projectDeletedVideoTotal = computed(() => store.state.projectDeletedVideoList.projectDeletedVideoTotal);
const restoreAllVideosButton = computed(() => ({
  iconClassName: 'fa-solid fa-clock-rotate-left',
  text: t('project.recentlyDeleted.restoreAll.title'),
  disabled: (projectDeletedVideoTotal?.value ?? 0) <= 0,
  dataTest: 'restore-all-videos-button',
}));


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(Button, {
        dataTest: restoreAllVideosButton.value.dataTest,
        buttonStyle: "primary",
        onClick: _unref(onClickRestoreAllVideos),
        disabled: restoreAllVideosButton.value.disabled
      }, {
        "icon-prefix": _withCtx(() => [
          _createElementVNode("i", {
            class: _normalizeClass(restoreAllVideosButton.value.iconClassName)
          }, null, 2)
        ]),
        default: _withCtx(() => [
          _createTextVNode(" " + _toDisplayString(restoreAllVideosButton.value.text), 1)
        ]),
        _: 1
      }, 8, ["dataTest", "onClick", "disabled"])
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(Dropdown, {
        dataTest: "action-button-dropdown",
        buttonStyle: "text-secondary",
        buttonClassName: "btn-secondary-outline",
        withArrow: false,
        offsetSkid: "-64"
      }, {
        default: _withCtx(() => [
          _createVNode(DropdownItem, null, {
            default: _withCtx(() => [
              _createVNode(Button, {
                dataTest: restoreAllVideosButton.value.dataTest,
                buttonStyle: "text-secondary",
                onClick: _unref(onClickRestoreAllVideos),
                disabled: restoreAllVideosButton.value.disabled
              }, {
                "icon-prefix": _withCtx(() => [
                  _createElementVNode("i", {
                    class: _normalizeClass(restoreAllVideosButton.value.iconClassName)
                  }, null, 2)
                ]),
                default: _withCtx(() => [
                  _createTextVNode(" " + _toDisplayString(restoreAllVideosButton.value.text), 1)
                ]),
                _: 1
              }, 8, ["dataTest", "onClick", "disabled"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ])
  ], 64))
}
}

})