import type { RouteRecordRaw } from 'vue-router';
import { user } from '@/modules/user';
import store from '@/store';
import project from '@/modules/shared/store/project';
import teamFeatures from '@/modules/shared/store/teamFeatures';
import { videoUpload } from '@/modules/videoUpload';
import { projectList } from '@/modules/projectList';
import { projectVideoList } from '@/modules/projectVideoList';
import { collectionList } from '@/modules/collectionList';
import exportFileList from '../store';

export function createExportFileListRoutes(): RouteRecordRaw[] {
  return [
    {
      name: 'exportFileListChrome',
      path: 'projects/:projectKey/exported-files',
      component: () => import(/* webpackChunkName: "ExportFileListChrome" */ '../layouts/ExportFileListChrome.vue'),
      beforeEnter: (to, from, next) => {
        if (!store.hasModule('videoUpload')) {
          store.registerModule('videoUpload', videoUpload);
        }
        if (!store.hasModule('projectList')) {
          store.registerModule('projectList', projectList);
        }
        if (!store.hasModule('projectVideoList')) {
          store.registerModule('projectVideoList', projectVideoList);
        }
        if (!store.hasModule('collectionList')) {
          store.registerModule('collectionList', collectionList);
        }
        if (!store.hasModule('exportFileList')) {
          store.registerModule('exportFileList', exportFileList);
        }
        if (!store.hasModule('project')) {
          store.registerModule('project', project);
        }
        if (!store.hasModule('user')) {
          store.registerModule('user', user);
        }
        if (!store.hasModule('teamFeatures')) {
          store.registerModule('teamFeatures', teamFeatures);
        }
        next();
      },
      children: [
        {
          name: 'exportedFiles',
          path: '',
          component: () => import(/* webpackChunkName: "ExportFileList" */ '../pages/ExportFileList.vue'),
        },
      ],
    },
  ];
}
