<template>
  <div class="upload-container" v-if="isVideoUploadProgressPopupShowing">
    <div class="upload-header">
      <div class="title" @click="onToggleUploadVideosList">
        <span v-if="getAmountOfUploadingVideos(amountOfQueued, amountOfUploading)">
          {{ $t(`video.uploading.current${amountOfUploading > 1 ? '_plural' : ''}`, { count: amountOfUploading }) }}
        </span>
        <span v-if="!getAmountOfUploadingVideos(amountOfQueued, amountOfUploading)">
          <span v-if="amountOfUploaded !== amountOfAllVideos">
            {{
              $t(`video.uploading.uploaded${amountOfUploaded > 1 ? '_plural' : ''}`, { count: amountOfUploaded, total: amountOfAllVideos })
            }}
          </span>
          <span v-if="amountOfUploaded === amountOfAllVideos">
            {{ $t(`video.uploading.done${amountOfUploaded > 1 ? '_plural' : ''}`, { count: amountOfUploaded }) }}
          </span>
        </span>
      </div>
      <div class="actions">
        <Button
          @click="onToggleUploadVideosList"
          buttonStyle="text-secondary-light"
          buttonClasses="btn-chev"
          type="button"
        >
          <template v-slot:icon-prefix>
            <i
              class="fas"
              :class="[isShowingUploadVideos ? 'fa-chevron-down' : 'fa-chevron-up']"
            ></i>
          </template>
        </Button>
        <Button
          @click="onExpandSummaryModal"
          buttonStyle="text-secondary-light"
          buttonClasses="btn-chev"
          type="button"
        >
          <template v-slot:icon-prefix>
            <i class="fa-solid fa-expand"></i>
          </template>
        </Button>
        <Button
          @click.self="onCloseVideoUploadProgress"
          type="button"
          buttonStyle="text-secondary-light"
          buttonClasses="btn-close"
        >
          <template v-slot:icon-prefix>
            <i class="fas fa-xmark"></i>
          </template>
        </Button>
      </div>
    </div>
    <transition name="collapse">
      <div v-if="isShowingUploadVideos">
        <div v-if="alertObject" class="alert-container">
          <Alert :alertText="alertObject.text" :priority="alertObject.priority">
            <div
              v-if="this.isAllUploadVideosError"
              class="alert-actions"
              :title="$t('video.uploader.failed.button')"
              @click="onRetryUploadAllVideo"
            >
              <i class="fas fa-rotate-right"></i>
            </div>
          </Alert>
        </div>
        <div class="list-group" v-if="isShowingUploadVideos">
          <div v-for="(video, index) in videosUploadByKey" :key="index">
            <VideoUploadProgressItem :uploadItem="video" />
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapState, mapGetters } from 'vuex';
import {
  isVideoProbeNotComplete,
  isVideoProbeError,
  isVideoSourceUploading,
} from '@/modules/videoDetail/utils/videoManagement';
import Button from '@/modules/shared/components/atoms/button/Button.vue';
import Alert from '@/modules/shared/components/atoms/alert/Alert.vue';
import VideoUploadProgressItem from '../videoUploadProgressItem/VideoUploadProgressItem.vue';
import { getAmountOfUploadingVideos, getUploadVideoKeysByStatus } from '../../../utils';

export default {
  components: {
    VideoUploadProgressItem,
    Button,
    Alert,
  },
  data() {
    return {
      isVideoUploadProgressPopupShowing: true,
      isShowingUploadVideos: true,
    };
  },
  computed: {
    ...mapState({
      isDisplayProbeStatus: (state) => state.videoUpload.isDisplayProbeStatus,
      videoUploadKeys: (state) => state.videoUpload.videoUploadKeys,
      videosUploadByKey: (state) => state.videoUpload.videosUploadByKey,
      videoKeysGroupByState: (state) => state.videoUpload.videoKeysGroupByState,
    }),
    ...mapGetters([
      'isEmptyVideosUpload',
    ]),
    isAllUploadVideosError() {
      const uploadVideosFailed = this.getUploadVideoKeysByStatus(this.videoKeysGroupByState, 'failed');
      return uploadVideosFailed && uploadVideosFailed.length > 0;
    },
    amountOfUploaded() {
      const uploadedItemKeys = this.getUploadVideoKeysByStatus(this.videoKeysGroupByState, 'uploaded');
      if (uploadedItemKeys) {
        if (!this.isDisplayProbeStatus) {
          return parseInt(uploadedItemKeys.length, 10);
        }
        const probeComplete = _.filter(uploadedItemKeys, (videoKey) => {
          const video = this.videosUploadByKey[videoKey];
          if (!this.isVideoProbeNotComplete(video)) {
            return videoKey;
          }
          return false;
        });
        return parseInt(probeComplete.length, 10);
      }
      return 1;
    },
    amountOfQueued() {
      const queuedItems = this.getUploadVideoKeysByStatus(this.videoKeysGroupByState, 'queued');
      if (queuedItems) {
        return parseInt(queuedItems.length, 10);
      }
      return 0;
    },
    amountOfUploading() {
      const uploadingItems = this.getUploadVideoKeysByStatus(this.videoKeysGroupByState, 'uploading');
      if (uploadingItems) {
        return parseInt(uploadingItems.length, 10);
      }
      return 0;
    },
    amountOfAllVideos() {
      return this.videoUploadKeys.length;
    },
    sortProbeErrorVideos() {
      if (!this.isDisplayProbeStatus) {
        return this.videosUploadByKey;
      }
      return _.sortBy(this.videosUploadByKey, (video) => video.vod.source.probeErrorDetail);
    },
    alertObject() {
      if (this.isAllUploadVideosError) {
        return {
          text: this.$t('video.uploader.failed.helpText'),
          priority: 'danger',
        };
      }

      if (this.isVideoProbeError()) {
        return {
          text: this.$t('video.upload.probeFileError.fileNumber', { count: this.getProbeErrorVideo() }),
          priority: 'danger',
        };
      }

      if (this.amountOfUploaded !== this.amountOfAllVideos) {
        return {
          text: this.$t('video.upload.uploadingWarning'),
          priority: 'warning',
        };
      }

      return null;
    },
  },
  watch: {
    amountOfAllVideos(newValue, oldValue) {
      if (newValue !== oldValue && newValue > 0) {
        this.isVideoUploadProgressPopupShowing = true;
      }
    },
    amountOfUploaded() {
      if (this.amountOfUploaded !== this.amountOfAllVideos) {
        window.addEventListener('beforeunload', this.beforeUnloadHandler);
      } else {
        window.removeEventListener('beforeunload', this.beforeUnloadHandler);
      }
    },
  },
  methods: {
    getAmountOfUploadingVideos,
    getUploadVideoKeysByStatus,
    isVideoProbeNotComplete,
    isVideoProbeError,
    onToggleUploadVideosList() {
      this.isShowingUploadVideos = !this.isShowingUploadVideos;
    },
    onExpandSummaryModal() {
      this.$store.commit('setIsUploadSummaryMinimized', false);
    },
    onRetryUploadAllVideo() {
      this.$store.dispatch('forceRetryUploadAllVideo');
    },
    getProbeErrorVideo() {
      const probeErrorVideos = _.filter(this.videosUploadByKey, (video) => {
        if (isVideoProbeError(video) && !isVideoSourceUploading(video)) {
          return video;
        }
        return false;
      });
      return probeErrorVideos.length;
    },
    onCloseVideoUploadProgress() {
      this.isVideoUploadProgressPopupShowing = false;
    },
    beforeUnloadHandler(event) {
      event.preventDefault();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/global-variables.scss';
@import '~@/assets/scss/themes/helpers.scss';
@import '~@/assets/scss/breakpoint.scss';

:deep(.btn-chev) {
  border-radius: 0;
}

:deep(.btn-close) {
  border-radius: 0;
  &:hover {
    background-color: $danger !important;
  }
}

.upload-container {
  background-color: white;
  width: 380px;
  position: fixed;
  bottom: $spacing-base * 1.5;
  right: $spacing-base * 1.5;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.1);
  z-index: 3;

  .upload-header {
    background-color: $bg-navy;
    color: $white;
    display: flex;
    align-items: center;

    cursor: pointer;

    .title {
      padding-left: $spacing-base;
      flex: 1 0 auto;
    }

    .actions {
      margin-left: auto;
      display: flex;
      align-items: center;
      flex: 0 0 20px;
      font-size: $font-size-base;
      cursor: pointer;

      :deep(.button) {
        //border-radius: 0;
        //padding: 12px 16px;
        width: 48px;
        height: 48px;
        align-items: center;
      }
    }
  }

  .list-group {
    @extend .scrollbar;
    max-height: 250px;
    overflow-x: auto;
  }

  :deep(.list-group-item) {
    padding: 12px 12px 12px 20px;
    .detail .title {
      overflow-wrap: anywhere;
    }
  }
}

.alert-container {
  padding: 12px;
  margin-bottom: 0;
}

.alert-actions {
  margin-left: auto;
  cursor: pointer;
}

@media screen and (max-width: $max-layout-sm) {
  .upload-container {
    bottom: $spacing-base * 0.5;
    right: $spacing-base * 0.5;
    left: $spacing-base * 0.5;
    width: auto;
  }
}
</style>
