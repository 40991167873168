/* eslint no-shadow: ["error", { "allow": ["state"] }] */
import type { ActionContext } from 'vuex';
import { loadVideoCaptionIssues } from '@/modules/projectVideoList/services';
import { formatErrorObject } from '@/modules/shared/utils/errorFormatter';
import { setStateLoadingStatusByType } from '@/modules/shared/utils/stateManagement';
import type { VideoIssue } from '@/modules/videoCaption/types';
import type { RootState } from '@/store/type';
import type { LoadingState } from '@/types/State.type';
import type { ProjectVideoCaptionIssueListState } from '../types/projectVideoCaptionIssueList.type';

type ProjectVideoCaptionIssueListActionContext = ActionContext<ProjectVideoCaptionIssueListState, RootState>;

const initialState = (): ProjectVideoCaptionIssueListState => ({
  projectVideoCaptionIssueList: null,
  projectVideoCaptionIssueListTotal: 0,
  projectVideoCaptionIssueListLastPage: 0,
  loadProjectVideoCaptionIssueListState: setStateLoadingStatusByType(),
  pageScrollPosition: 0,
});

const state = initialState();

const mutations = {
  destroyProjectVideoCaptionIssueList(state: ProjectVideoCaptionIssueListState) {
    Object.assign(state, initialState());
  },
  setProjectVideoCaptionIssueList(state: ProjectVideoCaptionIssueListState, videos: VideoIssue[]) {
    state.projectVideoCaptionIssueList = videos;
  },
  setProjectVideoCaptionIssueListTotal(state: ProjectVideoCaptionIssueListState, total: number) {
    state.projectVideoCaptionIssueListTotal = total;
  },
  setProjectVideoCaptionIssueListLastPage(state: ProjectVideoCaptionIssueListState, lastPage: number) {
    state.projectVideoCaptionIssueListLastPage = lastPage;
  },
  setLoadProjectVideoCaptionIssueListState(state: ProjectVideoCaptionIssueListState, loadingState: LoadingState) {
    state.loadProjectVideoCaptionIssueListState = setStateLoadingStatusByType(loadingState);
  },
  setPageScrollPosition(state: ProjectVideoCaptionIssueListState, position: number) {
    state.pageScrollPosition = position;
  },
};

const actions = {
  async loadProjectVideoCaptionIssueList({ commit }: ProjectVideoCaptionIssueListActionContext, filter: { projectKey: string, q: string }) {
    commit('setLoadProjectVideoCaptionIssueListState', { type: 'loading' });
    try {
      const response = await loadVideoCaptionIssues(filter);
      commit('setProjectVideoCaptionIssueList', response.data?.data);
      commit('setProjectVideoCaptionIssueListLastPage', response.data?.lastPage);
      commit('setProjectVideoCaptionIssueListTotal', response.data.total);
      commit('setLoadProjectVideoCaptionIssueListState', { type: 'success' });
    } catch (error) {
      commit('setLoadProjectVideoCaptionIssueListState', { type: 'error', error: formatErrorObject(error, 'Videos') });
    }
  },
  destroyProjectVideoCaptionIssueList({ commit }: ProjectVideoCaptionIssueListActionContext) {
    commit('destroyProjectVideoCaptionIssueList');
  },
  setPageScrollPosition({ commit }: ProjectVideoCaptionIssueListActionContext, position: number) {
    commit('setPageScrollPosition', position);
  },
};

export default {
  state,
  actions,
  mutations,
};
