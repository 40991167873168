import { useStore as baseUseStore } from 'vuex';
import { storeKey } from '@/store';
import type { UserState } from '@/modules/user';
import type { ProjectState } from '@/modules/shared/types/project.type';
import type { BumperListState, BumperState } from '@/modules/bumper';
import type {
  BumperDisplayListState,
  BumperDisplayState,
} from '../types';

export const useStore = () => baseUseStore<{
  bumper: BumperState;
  bumperList: BumperListState;
  bumperDisplay: BumperDisplayState;
  bumperDisplayList: BumperDisplayListState;
  project: ProjectState;
  user: UserState;
}>(storeKey);
