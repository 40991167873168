import type { ActionContext } from 'vuex';
import { formatErrorObject } from '@/modules/shared/utils/errorFormatter';
import {
  setStateSavingStatusByType,
  setStateLoadingStatusByType,
} from '@/modules/shared/utils/stateManagement';
import type { RootState } from '@/store/type';
import type { ProjectDefaultUploadSettingState } from '@/modules/projectDefaultUploadSetting/types';
import {
  setProjectPrimaryPlaybackChannel,
  removeProjectPlaybackChannel,
  addProjectPlaybackChannel,
} from '../services';

type ProjectActionContext = ActionContext<ProjectDefaultUploadSettingState, RootState>

const initialState = (): ProjectDefaultUploadSettingState => ({
  loadProjectManagementState: setStateLoadingStatusByType(),
  saveProjectState: setStateSavingStatusByType(),
});

const state = initialState();

const mutations = {
};

const actions = {
  async setProjectPrimaryPlaybackChannel({ commit }: ProjectActionContext, payload: { primaryPlaybackChannelId: string, projectKey: string}) {
    commit('setUpdateProjectStateMessage', { type: 'saving' });
    try {
      const { projectKey, primaryPlaybackChannelId } = payload;
      const response = await setProjectPrimaryPlaybackChannel(projectKey, { primaryPlaybackChannelId });
      commit('setCurrentProject', response.data, { root: true });
      commit('setUpdateProjectStateMessage', { type: 'success' });
    } catch (error) {
      commit('setUpdateProjectStateMessage', { type: 'error', error: formatErrorObject(error, 'Project') });
    }
  },
  async deleteProjectPlaybackChannel({ commit }: ProjectActionContext, payload: { playbackChannelIds: string, projectKey: string}) {
    commit('setUpdateProjectStateMessage', { type: 'saving' });
    try {
      const { projectKey, playbackChannelIds } = payload;
      const response = await removeProjectPlaybackChannel(projectKey, { playbackChannelIds });
      commit('setCurrentProject', response.data, { root: true });
      commit('setUpdateProjectStateMessage', { type: 'success' });
    } catch (error) {
      commit('setUpdateProjectStateMessage', { type: 'error', error: formatErrorObject(error, 'Project') });
    }
  },
  async addProjectPlaybackChannel({ commit }: ProjectActionContext, payload: { playbackChannelIds: string, projectKey: string}) {
    commit('setUpdateProjectStateMessage', { type: 'saving' });
    try {
      const { projectKey, playbackChannelIds } = payload;
      const response = await addProjectPlaybackChannel(projectKey, { playbackChannelIds });
      commit('setCurrentProject', response.data, { root: true });
      commit('setUpdateProjectStateMessage', { type: 'success' });
    } catch (error) {
      commit('setUpdateProjectStateMessage', { type: 'error', error: formatErrorObject(error, 'Project') });
    }
  },
};

export default {
  state,
  actions,
  mutations,
};
