/* eslint no-shadow: ["error", { "allow": ["state"] }] */
import { createProject } from '@/modules/projectCreate/services';
import type {
  ProjectCreatePayload,
  ProjectCreateState,
  IValidateError,
} from '@/modules/projectCreate/types/projectCreate.type';
import { formatErrorObject } from '@/modules/shared/utils/errorFormatter';
import { setStateSavingStatusByType } from '@/modules/shared/utils/stateManagement';
import { ActionContext } from 'vuex';
import { RootState } from '@/store/type';
import { SavingState } from '@/types/State.type';

type CreateProjectActionContext = ActionContext<ProjectCreateState, RootState>;

const initialState = (): ProjectCreateState => ({
  project: {
    name: null,
    key: null,
    preset: {},
    geoblockRule: {},
    playbackChannel: {},
  },
  keyData: {
    randomSuffix: '',
  },
  createProjectState: setStateSavingStatusByType(),
  validateError: {
    key: null,
    name: null,
  },
  isPresetSectionShowing: true,
  isGeoblockSectionShowing: true,
  isPlaybackSectionShowing: true,
});

const state = initialState();

const mutations = {
  setCreateProjectData(state: ProjectCreateState, project: ProjectCreatePayload) {
    state.project = { ...state.project, ...project };
  },
  setCreateProjectValidateError(state: ProjectCreateState, error: IValidateError) {
    if (error) {
      state.validateError = { ...state.validateError, ...error };
    } else {
      state.validateError = {
        key: null,
        name: null,
      };
    }
  },
  setCreateProjectState(state: ProjectCreateState, status: SavingState) {
    state.createProjectState = setStateSavingStatusByType(status);
  },
  destroyCreateProjectData(state: ProjectCreateState) {
    Object.assign(state, initialState());
  },
  // Project Key
  setProjectKeyRandomSuffix(state: ProjectCreateState, randomSuffix: string) {
    state.keyData.randomSuffix = randomSuffix;
  },
  // Collapse Sections
  setPresetSectionShowing(state: ProjectCreateState, isPresetSectionShowing: boolean) {
    state.isPresetSectionShowing = isPresetSectionShowing;
  },
  setGeoblockSectionShowing(state: ProjectCreateState, isGeoblockSectionShowing: boolean) {
    state.isGeoblockSectionShowing = isGeoblockSectionShowing;
  },
  setPlaybackSectionShowing(state: ProjectCreateState, isPlaybackSectionShowing: boolean) {
    state.isPlaybackSectionShowing = isPlaybackSectionShowing;
  },
};

const actions = {
  setCreateProjectData({ commit }: CreateProjectActionContext, payload: ProjectCreatePayload) {
    commit('setCreateProjectData', payload);
  },
  setCreateProjectValidateError({ commit }: CreateProjectActionContext, error: IValidateError) {
    commit('setCreateProjectValidateError', error);
  },
  async onCreateProject({ state, commit }: CreateProjectActionContext, payload: ProjectCreatePayload) {
    commit('setCreateProjectValidateError', null);
    try {
      commit('setCreateProjectState', { type: 'saving' });
      const projectData = {
        ...payload,
        key: `${state.project.key}-${state.keyData.randomSuffix}`,
      };
      const response = await createProject(projectData);
      commit('setCreateProjectData', response.data);
      commit('setCreateProjectState', { type: 'success' });
    } catch (error: any) {
      commit('setCreateProjectState', { type: 'error', error: formatErrorObject(error, 'project') });
      commit('setCreateProjectValidateError', error.response?.data);
    }
  },
  destroyCreateProjectData({ commit }: CreateProjectActionContext) {
    commit('destroyCreateProjectData');
  },
  setCreateProjectState({ commit }: CreateProjectActionContext, status: SavingState) {
    commit('setCreateProjectState', status);
  },
  // Project Key
  setProjectKeyRandomSuffix({ commit }: CreateProjectActionContext, randomSuffix: string) {
    commit('setProjectKeyRandomSuffix', randomSuffix);
  },
};

export default {
  state,
  actions,
  mutations,
};
