/* eslint no-shadow: ["error", { "allow": ["state"] }] */
import { formatErrorObject } from '@/modules/shared/utils/errorFormatter';
import {
  setStateSavingStatusByType,
  setStateLoadingStatusByType,
} from '@/modules/shared/utils/stateManagement';
import type { ActionContext } from 'vuex';
import type { RootState } from '@/store/type';
import type { LoadingState, SavingState } from '@/types/State.type';
import type { User } from '@/modules/user';
import type { CreateFormPayload, FormUpload, FormUploadState } from '../types';
import {
  loadFormByKey,
  createForm,
  updateForm,
  deleteFormByKey,
  resetFormSecret,
} from '../services';

type FormUploadActionContext = ActionContext<FormUploadState, RootState>

const initialState = (): FormUploadState => ({
  formUploadActiveModal: null,
  formUpload: {},
  formCreatingStep: 1,
  createFormState: setStateSavingStatusByType(),
  loadFormUploadState: setStateLoadingStatusByType(),
  deleteFormState: setStateSavingStatusByType(),
  formUploadTitle: '',
  formUploadOwner: {
    name: '',
    email: '',
  },
  formUploadRestrictedOnlyAllowedDomains: false,
  formUploadAllowedDomains: '',
  formUploadToken: '',
  formResetSecretState: {
    isReset: false,
    error: null,
  },
});

const state = initialState();

const mutations = {
  setForm(state: FormUploadState, form: FormUpload) {
    state.formUpload = form;
    state.formUploadTitle = form.name;
    state.formUploadOwner = form.ownerAccount;
    state.formUploadRestrictedOnlyAllowedDomains = form.restrictedOnlyAllowedDomains;
    state.formUploadAllowedDomains = form.allowedDomains.join(', ');
  },
  setFormUploadToken(state: FormUploadState, token: string) {
    state.formUploadToken = token;
  },
  setLoadFormUploadState(state: FormUploadState, loadingState: LoadingState) {
    state.loadFormUploadState = setStateLoadingStatusByType(loadingState);
  },
  setDeleteFormState(state: FormUploadState, savingState: SavingState) {
    state.deleteFormState = setStateSavingStatusByType(savingState);
  },
  setCreateFormState(state: FormUploadState, savingState: SavingState) {
    state.createFormState = setStateSavingStatusByType(savingState);
  },
  setFormUploadActiveModal(state: FormUploadState, modalName:string) {
    state.formUploadActiveModal = modalName;
  },
  goToStep(state: FormUploadState, stepNumber: number) {
    state.createFormState.status = 'saving';
    if (stepNumber === 3) {
      if (!state.formUploadAllowedDomains.length) {
        state.formUploadRestrictedOnlyAllowedDomains = false;
      }

      const allowedDomains = state.formUploadAllowedDomains.split(',').map((item) => item.trim());
      const data: CreateFormPayload = {
        name: state.formUploadTitle,
        restrictedOnlyAllowedDomains: state.formUploadRestrictedOnlyAllowedDomains,
        allowedDomains,
        ownerAccountUser: state.formUploadOwner,
      };

      state.createFormState = setStateSavingStatusByType({ type: 'saving' });
      createForm(data)
        .then((response) => {
          state.formUpload = response.data;
          state.createFormState = setStateSavingStatusByType({ type: 'success' });
        })
        .catch((error) => {
          state.createFormState = setStateSavingStatusByType({
            type: 'error',
            error: error.response,
          });
        });
    }
    state.formCreatingStep = stepNumber;
  },
  setFormUploadTitle(state: FormUploadState, title: string) {
    state.formUploadTitle = title;
  },
  setFormUploadOwner(state: FormUploadState, ownerObject: User) {
    state.formUploadOwner = ownerObject;
  },
  setAllowDomains(state: FormUploadState, domains: string) {
    state.formUploadAllowedDomains = domains;
  },
  setFormUploadAllDomains(state: FormUploadState) {
    state.formUploadAllowedDomains = '';
  },
  setRestrictedDomainsOnly(state: FormUploadState, boolean: boolean) {
    state.formUploadRestrictedOnlyAllowedDomains = boolean;
  },
  resetFormUploadCreatingModal(state: FormUploadState) {
    state.formUploadActiveModal = null;
    state.formCreatingStep = 1;
  },
  resetCreateFormUploadState(state: FormUploadState) {
    state.createFormState = setStateSavingStatusByType();
  },
  setResetSecretSuccessfully(state: FormUploadState, data: FormUpload) {
    state.formUpload = data;
    state.formResetSecretState.isReset = true;
  },
  setResetSecretError(state: FormUploadState, error: any) {
    state.formResetSecretState.error = error;
  },
  resetFormUploadState(state: FormUploadState) {
    Object.assign(state, initialState());
  },
};

const actions = {
  async loadForm({ commit }: FormUploadActionContext, formKey: string) {
    commit('resetFormUploadState');
    commit('setLoadFormUploadState', { type: 'loading' });
    try {
      const response = await loadFormByKey(formKey);
      commit('setForm', response.data);
      commit('setLoadFormUploadState', { type: 'success' });
    } catch (error) {
      commit('setLoadFormUploadState', { type: 'error', error: formatErrorObject(error, 'Form Upload') });
    }
  },
  async updateFormUpload({ state, commit }: FormUploadActionContext, formData: FormUpload) {
    commit('setCreateFormState', { type: 'saving' });
    try {
      const response = await updateForm(state.formUpload?.key, formData);
      commit('setForm', response.data);
      commit('setCreateFormState', { type: 'success' });
    } catch (error) {
      commit('setCreateFormState', { type: 'error', error: formatErrorObject(error, 'Form Upload') });
    }
  },
  async deleteForm({ commit }: FormUploadActionContext, formKey: string) {
    commit('setDeleteFormState', { type: 'loading' });
    try {
      await deleteFormByKey(formKey);
      commit('setDeleteFormState', { type: 'success' });
      Object.assign(state, initialState);
    } catch (error) {
      commit('setDeleteFormState', { type: 'error', error: formatErrorObject(error, 'Form Upload') });
    }
  },
  async resetFormSecret({ commit }: FormUploadActionContext) {
    try {
      const response = await resetFormSecret(state.formUpload.key);
      commit('setResetSecretSuccessfully', response.data);
    } catch (error: any) {
      commit('setResetSecretError', error.response);
    }
  },
  setFormUploadActiveModal({ commit }: FormUploadActionContext, modalName: string) {
    commit('setFormUploadActiveModal', modalName);
  },
  dismissFormUploadModal({ commit }: FormUploadActionContext) {
    commit('resetFormUploadCreatingModal');
  },
  goToStep({ commit }: FormUploadActionContext, stepNumber: number) {
    commit('goToStep', stepNumber);
  },
  setFormUploadTitle({ commit }: FormUploadActionContext, title: string) {
    commit('setFormUploadTitle', title);
  },
  setFormUploadOwner({ commit }: FormUploadActionContext, ownerObject: User) {
    commit('setFormUploadOwner', ownerObject);
  },
  setFormUploadRestrictedDomains({ commit }: FormUploadActionContext, domains: string) {
    commit('setRestrictedDomainsOnly', true);
    commit('setAllowDomains', domains);
  },
  setFormUploadAllDomains({ commit }: FormUploadActionContext) {
    commit('setRestrictedDomainsOnly', false);
    commit('setAllowDomains', '');
  },
  setFormUploadToken({ commit }: FormUploadActionContext, token: string) {
    commit('setFormUploadToken', token);
  },
  resetCreateFormUploadState({ commit }: FormUploadActionContext) {
    commit('resetCreateFormUploadState');
  },
  resetFormUploadState({ commit }: FormUploadActionContext, state: FormUploadState) {
    commit('resetFormUploadState', state);
  },
};

export default {
  state,
  mutations,
  actions,
};
