<template>
  <Modal
    size="medium"
    :title="!isAddMode ? t('collection.move.title') : t('collection.add.title')"
    :primaryButtonText="!isAddMode ? t('common:move') : t('common:button.add')"
    :onClickPrimaryButton="() => onMove()"
    :isDisabledPrimaryButton="!isEnableSave"
    :secondaryButtonText="t('common:button.cancel')"
    :onClickSecondaryButton="() => onClose()"
    @modalClose="onClose"
  >
    <template v-slot:body>
      <Input
        :onSearch="() => onSearch()"
        @keyup.enter="() => onSearch()"
        type="search"
        class="form-control search-box"
        :placeholder="t('collections.searchBy')"
        v-model="searchTerm"
        isSearchInput
      />
      <div v-if="loadCollectionListState.status !== 'loading'" class="collection-list-container">
        <template v-if="!isEmptyCollection">
          <div
            v-for="collection in orderedCollectionList"
            :key="collection.id"
            @click="onClickCollection(collection)"
            :class="{ selected: selectedCollection?.id === collection.id }"
            class="collection-item"
          >
            <div class="collection-image">
              <ImageView :source="getCollectionCoverImage({ collection })" imageSize="small" />
            </div>
            <div class="collection-information">
              <p class="title">{{ collection.name }}</p>
              <p class="subtitle" v-if="collection.excerpt">{{ collection.excerpt }}</p>
            </div>
            <div class="collection-select-status">
              {{
                selectedCollection?.id === collection.id ? t('common:multiselect.selected') : ''
              }}
            </div>
          </div>
        </template>
        <MessageBox
          v-if="isEmptyCollection"
          iconClassName="fas fa-rectangle-list"
          :title="t('collectionList.empty.title')"
          :description="t('collectionList.empty.description')"
          :onClickButton="() => $router.push(`/${teamName}/projects/${projectKey}/collections`)"
          buttonPrefixIconClassName="fas fa-plus"
          :buttonTitle="t('collectionList.empty.button')"
        />
      </div>
      <PreLoaderSection
        v-if="loadCollectionListState.status === 'loading'"
        :options="{ height: 200 }"
      />
    </template>
  </Modal>
</template>

<script lang="ts" setup>
import _ from 'lodash';
import {
  computed, onMounted, ref, toRefs, watch,
} from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';
import PreLoaderSection from '@/modules/shared/components/atoms/preLoaderSection/PreLoaderSection.vue';
import {
  getCollectionCoverImage,
} from '@/modules/videoDetail/utils/videoManagement';
import { Video } from '@/modules/shared/types/video.type';
import { Collection } from '@/modules/collection/types';
import { getQueryAsString } from '@/modules/shared/utils/query';
import Modal from '@/modules/shared/components/organisms/modal/Modal.vue';
import ImageView from '@/modules/shared/components/atoms/imageView/ImageView.vue';
import Input from '@/modules/shared/components/atoms/input/Input.vue';
import MessageBox from '@/modules/shared/components/molecules/messageBox/MessageBox.vue';
import { useStore } from '../../../store/hooks';
import {
  addVideosToCollectionItemList,
  deleteVideosToCollectionItemList,
  getCollectionById,
} from '../../../services';

interface MoveToCollectionModalProps {
  itemToMove: Video | null | undefined;
  isAddMode?: boolean;
}

const { t } = useI18n();
const store = useStore();
const route = useRoute();

const props = defineProps<MoveToCollectionModalProps>();
const { itemToMove, isAddMode } = toRefs(props);

const emit = defineEmits<{
  modalClose: [];
  success: [value: string];
}>();

const searchTerm = ref<string>('');
const selectedCollection = ref<Collection | null>(null);
const orderedCollectionList = ref<Collection[]>([]);
const isMoving = ref<boolean>(false);

const teamName = computed(() => getQueryAsString(route.params, 'teamName'));
const projectKey = computed(() => getQueryAsString(route.params, 'projectKey'));

const collectionList = computed(() => store.state.collectionList.collectionList);
const loadCollectionListState = computed(() => store.state.collectionList.loadCollectionListState);
const currentCollection = computed(() => store.state.collection.currentCollection);
const isEmptyCollection = computed(() => _.isEmpty(collectionList.value));

const isEnableSave = computed(() => {
  if (!selectedCollection.value) {
    return false;
  }
  if (selectedCollection.value?.id === currentCollection.value?.id) {
    return false;
  }

  return !isMoving.value;
});

function onClose() {
  emit('modalClose');
}

function reorderCollectionList() {
  if (selectedCollection.value) {
    orderedCollectionList.value = [
      selectedCollection.value,
      ...collectionList.value.filter((item: Collection) => item.id !== selectedCollection.value?.id),
    ];
  } else {
    orderedCollectionList.value = collectionList.value;
  }
}

function onSearch() {
  store.dispatch('loadCollectionList', {
    projectKey: projectKey.value,
    filter: { q: searchTerm.value },
  });
}

function onClickCollection(collection: Collection) {
  selectedCollection.value = collection;
}

async function onMove() {
  const item = itemToMove.value;

  if (!isEnableSave.value || !selectedCollection.value || !item || !projectKey.value) {
    return;
  }

  const { id: targetId } = selectedCollection.value;
  // temperary fix collection list not contain itemlists
  const response = await getCollectionById(projectKey.value, targetId);
  const { itemLists: targetILF } = response.data;
  const videoIds = [item.id];
  isMoving.value = true;
  try {
    if (currentCollection.value) {
      const { id: sourceId, itemLists: sourceIL } = currentCollection.value;

      if (Array.isArray(sourceIL) && sourceIL[0]) {
        await deleteVideosToCollectionItemList(projectKey.value, sourceId, sourceIL[0].id, {
          videoIds,
        });
      }
    }

    if (Array.isArray(targetILF) && targetILF[0]) {
      await addVideosToCollectionItemList(projectKey.value, targetId, targetILF[0].id, { videoIds });
    }

    emit('success', targetId);
  } catch (addError) {
    console.error(addError);
  } finally {
    isMoving.value = false;
    onClose();
  }
}

watch(collectionList, reorderCollectionList, { deep: true });

onMounted(async () => {
  if (isAddMode.value) {
    await store.dispatch('destroyCurrentCollection');
  }
  if (!isAddMode.value && currentCollection.value) {
    selectedCollection.value = currentCollection.value;
  }
  if (!collectionList.value) {
    await store.dispatch('loadCollectionList', { projectKey: projectKey.value });
  } else {
    reorderCollectionList();
  }
});
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/global-variables';

/* :deep(.modal-body) {
  padding-bottom: 4px;
} */

.collection-list-container {
  max-height: 50vh;
  overflow-y: auto;
  margin-bottom: 16px;
}

.collection-item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  gap: 16px;
  padding: 16px;
  background-color: #fff;
  border: 1px solid transparent;
  border-radius: 5px;
  transition: background-color 150ms ease;

  &.selected {
    border-color: $ci-primary;
    background-color: #f0f5f8;
    padding-left: $spacing-base;
    padding-right: $spacing-base;
  }

  &:hover {
    background-color: #f8f9fa;
  }
}

.checkbox-container {
  width: 12px;
  .checkbox {
    position: relative;
    width: 12px;
    height: 12px;
    border: 2px solid #d0d4d8;
    border-radius: 2px;
  }
}

.collection-image {
  position: relative;
  width: 120px;
}

.collection-information {
  flex: auto;

  .title {
    font-weight: $font-weight-bold;
    margin-bottom: 0;
  }

  .subtitle {
    margin-top: 4px;
    margin-bottom: 0;
  }
}

.collection-select-status {
  color: $ci-primary;
  font-size: 12px;
  font-weight: $font-weight-bold;
}

.modal-footer {
  .btn {
    margin: 0;
  }
}
</style>
