import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "set-bumper-display-section"
}
const _hoisted_2 = { class: "content-container" }
const _hoisted_3 = { class: "title" }
const _hoisted_4 = { class: "section-container section-use-in-content" }
const _hoisted_5 = { class: "title-container" }
const _hoisted_6 = { class: "section-title" }
const _hoisted_7 = {
  key: 0,
  class: "video-item-empty"
}
const _hoisted_8 = {
  key: 1,
  class: "video-item"
}
const _hoisted_9 = { class: "video-info" }
const _hoisted_10 = { class: "video-image" }
const _hoisted_11 = {
  key: 0,
  class: "video-duration"
}
const _hoisted_12 = { class: "content-title-container" }
const _hoisted_13 = ["href"]
const _hoisted_14 = {
  key: 0,
  class: "count"
}
const _hoisted_15 = {
  key: 1,
  id: "schedules",
  class: "section-container"
}
const _hoisted_16 = { class: "title-container" }
const _hoisted_17 = { class: "tab-container" }
const _hoisted_18 = { class: "tab-item-menu" }
const _hoisted_19 = { class: "description" }
const _hoisted_20 = {
  key: 0,
  class: "bumper-group-container"
}
const _hoisted_21 = { class: "tooltip-text" }
const _hoisted_22 = { class: "bumper-feature" }
const _hoisted_23 = ["innerHTML"]
const _hoisted_24 = ["innerHTML"]
const _hoisted_25 = ["innerHTML"]
const _hoisted_26 = { class: "tooltip-text" }
const _hoisted_27 = { class: "bumper-feature" }
const _hoisted_28 = ["innerHTML"]
const _hoisted_29 = ["innerHTML"]
const _hoisted_30 = ["innerHTML"]
const _hoisted_31 = { class: "tooltip-text" }
const _hoisted_32 = { class: "bumper-feature" }
const _hoisted_33 = ["innerHTML"]
const _hoisted_34 = ["innerHTML"]
const _hoisted_35 = ["innerHTML"]
const _hoisted_36 = { class: "tooltip-text" }
const _hoisted_37 = { class: "bumper-feature" }
const _hoisted_38 = {
  key: 2,
  class: "footer-container"
}
const _hoisted_39 = {
  key: 0,
  class: "saving-status error"
}
const _hoisted_40 = {
  key: 1,
  class: "saving-status success"
}

import _ from 'lodash';
import {
  computed, onMounted, onUnmounted, ref, toRefs, watch,
} from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute, useRouter } from 'vue-router';
import { loadBumperDisplays } from '@/modules/bumperDisplay/services';
import type { Bumper } from '@/modules/bumper/types';
import type {
  BumperDisplay,
  BumperDisplayContentsPayload,
  BumperDisplayPayload,
  BumperSource, ChooseBumperModalSubmitPayload, SelectedContent,
} from '@/modules/bumperDisplay/types';
import { getBumperStatusTabs } from '@/modules/bumper';
import { getQueryAsString } from '@/modules/shared/utils/query';
import { useBumperDisplay } from '@/modules/bumperDisplay/utils';
import {
  getVideoDuration,
  getVideoUploadCoverImage,
} from '@/modules/videoDetail/utils/videoManagement';
import TabList from '@/modules/shared/components/molecules/tabList/TabList.vue';
import ImageView from '@/modules/shared/components/atoms/imageView/ImageView.vue';
import ChooseBumperModal from '@/modules/bumperDisplay/components/organisms/chooseBumperModal/ChooseBumperModal.vue';
import BumperDraggableGroup from '@/modules/bumperDisplay/components/organisms/bumperDraggableGroup/BumperDraggableGroup.vue';
import PreLoaderSection from '@/modules/shared/components/atoms/preLoaderSection/PreLoaderSection.vue';
import Section from '@/modules/shared/components/organisms/section/Section.vue';
import Badge from '@/modules/shared/components/atoms/badge/Badge.vue';
import Button from '@/modules/shared/components/atoms/button/Button.vue';
import SelectBumperContentModal from '@/modules/bumperDisplay/components/organisms/selectBumperContentModal/SelectBumperContentModal.vue';
import Tooltip from '@/modules/shared/components/atoms/tooltip/Tooltip.vue';
import { useStore } from '../../../store/hooks';

interface SetBumperDisplayModalProps {
  onClose?: () => void;
  idToEdit?: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'SetBumperDisplayModal',
  props: {
    onClose: { type: Function },
    idToEdit: {}
  },
  setup(__props: any) {

const props = __props;
const { onClose, idToEdit } = toRefs(props);

const { t } = useI18n();
const store = useStore();
const route = useRoute();
const router = useRouter();

const bumperStatusTabs = getBumperStatusTabs();

const filteredIntro1 = ref<Array<Bumper>>([]);
const filteredIntro2 = ref<Array<Bumper>>([]);
const filteredOutro1 = ref<Array<Bumper>>([]);
const filteredOutro2 = ref<Array<Bumper>>([]);
const isShowChooseBumperModal = ref<boolean>(false);
const isShowSelectBumperContentModal = ref<boolean>(false);
const addToCategoryName = ref<string>('');
const selectedProjectKey = ref<string | null>(null);
const isLoadFromParent = ref<boolean | null>(null);

const intro1 = computed(() => store.state.bumperDisplay.intro1);
const intro2 = computed(() => store.state.bumperDisplay.intro2);
const outro1 = computed(() => store.state.bumperDisplay.outro1);
const outro2 = computed(() => store.state.bumperDisplay.outro2);
const currentUser = computed(() => store.state.user.currentUser);
const selectedContent = computed(() => store.state.bumperDisplay.selectedContent);
const bumperDisplayDetail = computed(() => store.state.bumperDisplay.bumperDisplayDetail);
const loadBumperDisplayDetailState = computed(() => store.state.bumperDisplay.loadBumperDisplayDetailState);
const saveBumperDisplayState = computed(() => store.state.bumperDisplay.saveBumperDisplayState);
const parentBumperDisplay = computed(() => store.state.bumperDisplay.parentBumperDisplay);
const bumperSources = computed<BumperSource[]>(() => store.state.bumperDisplay.bumperSources);
const showSaveErrorAlertBox = computed(() => store.state.bumperDisplay.showSaveErrorAlertBox);
const newBumperDisplayId = computed(() => store.state.bumperDisplay.newBumperDisplayId);

const currentTab = computed(() => getQueryAsString(route.query, 'tab'));

function filterBumperByStatus(bumperList: Bumper[], tabKey: string | null): Bumper[] {
  if (!tabKey || tabKey === 'active,scheduled') {
    return bumperList.filter((item) => item.visibility?.status === 'active' || item.visibility?.status === 'scheduled');
  }
  return bumperList.filter((item) => item.visibility?.status === tabKey);
}

const teamName = computed(() => route.params.teamName);
const query = computed(() => route.query);
const hash = computed(() => route.hash);

const scheduleId = computed(() => getQueryAsString(route.params, 'scheduleId') || idToEdit.value);
const isEditMode = computed(() => Boolean(scheduleId.value) || Boolean(idToEdit.value));
const isEmpty = computed(() => _.isEmpty(intro1.value) && _.isEmpty(intro2.value) && _.isEmpty(outro1.value) && _.isEmpty(outro2.value));

const contentTitle = computed(() => {
  let { content } = selectedContent.value;

  if (selectedContent.value.content?.content) {
    content = selectedContent.value.content.content;
  }

  return content.title || content.name;
});

const disabledBumperPosition = computed(() => {
  const disabledBumperList: string[] = [];
  if (bumperSources.value.length) {
    bumperSources.value.forEach((bumper) => {
      if (bumper.sourceType === 'parent' || bumper.sourceType === 'disabled') {
        disabledBumperList.push(bumper.positionName);
      }
    });
  }
  return disabledBumperList;
});

function getContentURL({ content, projectKey, type }: SelectedContent): string {
  switch (type) {
    case 'project':
      return `/${teamName.value}/projects/${content.content.key}`;
    case 'contentCollection':
      return `/${teamName.value}/projects/${projectKey}/collections/${content.contentId}`;
    default:
      return `/${teamName.value}/projects/${projectKey}/videos/${content.content.key}/information`;
  }
}

function onClickChange() {
  isShowSelectBumperContentModal.value = true;
}

function onClickAddBumper(categoryName = '') {
  addToCategoryName.value = categoryName;
  isShowChooseBumperModal.value = true;
}

function setPositions() {
  const { categorizedBumpers } = useBumperDisplay(bumperDisplayDetail.value);

  store.commit('setDisplayIntro1', categorizedBumpers.intro1);
  store.commit('setDisplayIntro2', categorizedBumpers.intro2);
  store.commit('setDisplayOutro1', categorizedBumpers.outro1);
  store.commit('setDisplayOutro2', categorizedBumpers.outro2);
}

function onSelectedBumper(submitValue: ChooseBumperModalSubmitPayload) {
  if (submitValue.type === 'intro1') {
    store.commit('setDisplayIntro1', [...intro1.value, submitValue.bumper]);
  }
  if (submitValue.type === 'intro2') {
    store.commit('setDisplayIntro2', [...intro2.value, submitValue.bumper]);
  }
  if (submitValue.type === 'outro1') {
    store.commit('setDisplayOutro1', [...outro1.value, submitValue.bumper]);
  }
  if (submitValue.type === 'outro2') {
    store.commit('setDisplayOutro2', [...outro2.value, submitValue.bumper]);
  }
  isShowChooseBumperModal.value = false;
  // Switch to All tab to prevent confusing that the added bumper didn't appear on a certain tab.
  router.push({ query: { tab: 'all' } });
}

function getPositionArrayBySourceType(sourceType: 'custom' | 'parent') {
  return [
    { positionName: 'intro1', sourceType },
    { positionName: 'intro2', sourceType },
    { positionName: 'outro1', sourceType },
    { positionName: 'outro2', sourceType },
  ];
}

function validateBumperContents(payload: BumperDisplayPayload) {
  let hasError = false;
  bumperSources.value.forEach((bumperSource) => {
    if (bumperSource.sourceType === 'custom') {
      const hasBumperData = _.find(payload.bumperContents, { positionName: bumperSource.positionName });

      if (!hasBumperData) {
        store.dispatch('setShowSaveErrorAlertBox', { positionName: bumperSource.positionName, value: true });
        hasError = true;
      }
    }
  });
  return hasError;
}

async function onContentChange(selected: SelectedContent) {
  store.dispatch('setSelectedContent', selected);
  store.commit('setSaveBumperDisplayState', { type: 'idle' });

  store.dispatch('setBumperSources', getPositionArrayBySourceType('parent'));
  isShowSelectBumperContentModal.value = false;
}

function getBumperContentPayload(positionName: string, data: Bumper[] | BumperDisplay[]): BumperDisplayContentsPayload[] {
  const bumperSource = bumperSources.value?.find((source) => source.positionName === positionName);

  if (bumperSource && bumperSource.sourceType === 'custom') {
    return data.map((item, index) => (
      {
        positionName,
        bumperId: item.id,
        index,
      }
    ));
  }

  return [];
}

async function onConfirm() {
  const { content, type } = selectedContent.value;

  const intro1BumperContents = getBumperContentPayload('intro1', intro1.value);
  const intro2BumperContents = getBumperContentPayload('intro2', intro2.value);
  const outro1BumperContents = getBumperContentPayload('outro1', outro1.value);
  const outro2BumperContents = getBumperContentPayload('outro2', outro2.value);

  const payloadContent = content.name || content.title ? content : content.content; // content.content is Edit Mode
  const payload: BumperDisplayPayload = {
    name: payloadContent?.name || payloadContent?.title,
    shouldAppearOnContent: {
      type,
      contentId: content.contentId || payloadContent?.id,
    },
    bumperContents: [
      ...intro1BumperContents,
      ...intro2BumperContents,
      ...outro1BumperContents,
      ...outro2BumperContents,
    ],
    bumperSources: bumperSources.value,
    id: '',
  };

  validateBumperContents(payload);

  if (isEditMode.value && scheduleId.value) {
    payload.id = scheduleId.value;
    await store.dispatch('saveBumperDisplay', payload);
  } else {
    await store.dispatch('createBumperDisplay', payload);
  }
}

function formatErrorCode(error: unknown): string {
  const { content, type } = selectedContent.value;
  const errorPosition = Object.keys(showSaveErrorAlertBox.value).find((position) => showSaveErrorAlertBox.value[position]);

  if (_.isNil(error)) {
    return '';
  }

  if (_.isObject(error) && 'streamErrorCode' in error) {
    switch (error.streamErrorCode) {
      case 'upsert-bumper-display-0001':
        return t('bumperSchedule.schedule.error.content', { contentType: type === 'contentCollection' ? 'Collection' : type.charAt(0).toUpperCase() + type.slice(1), contentName: content.content.name || content.content.title });
      case 'upsert-bumper-display-0002':
        switch (errorPosition) {
          case 'intro1':
            return t('bumperSchedule.schedule.error.custom.empty', { position: 'Intro 1' });
          case 'intro2':
            return t('bumperSchedule.schedule.error.custom.empty', { position: 'Intro 2' });
          case 'outro1':
            return t('bumperSchedule.schedule.error.custom.empty', { position: 'Outro 1' });
          default:
            return t('bumperSchedule.schedule.error.custom.empty', { position: 'Outro 2' });
        }
      default:
        return '';
    }
  }

  return '';
}

function onCloseChooseBumperModal() {
  isShowChooseBumperModal.value = false;
}

function handleClose() {
  if (typeof onClose.value === 'function') {
    onClose.value();
  } else {
    store.dispatch('clearBumperDisplayData');
    store.dispatch('loadBumperDisplayList');
    router.push({ name: 'manageBumperDisplay' });
  }
}

async function loadFromParent() {
  const contentCollectionId = selectedContent.value?.content?.content?.contentCollectionId;
  const { projectKey } = selectedContent.value;

  if (!_.isNil(projectKey)) {
    const projectScheduleResponse = await loadBumperDisplays({ projectKey });
    const bumperDisplays = projectScheduleResponse?.data?.data;

    if (!_.isNil(bumperDisplays) && !_.isEmpty(bumperDisplays)) {
      await store.dispatch('loadBumperDisplayDetailFromParent', bumperDisplays[0].id);
      isLoadFromParent.value = true;
    }

    return;
  }

  if (!_.isNil(contentCollectionId)) {
    const collectionScheduleResponse = await loadBumperDisplays({ appearOnContentId: contentCollectionId });
    const bumperDisplays = collectionScheduleResponse?.data?.data;

    if (!_.isNil(bumperDisplays) && !_.isEmpty(bumperDisplays)) {
      await store.dispatch('loadBumperDisplayDetailFromParent', bumperDisplays[0].id);
      isLoadFromParent.value = true;
    }

    return;
  }

  await store.dispatch('setBumperSources', getPositionArrayBySourceType('custom'));
  isLoadFromParent.value = true;
}

function getParentBumpers(positionName: string): Bumper[] {
  if (!isLoadFromParent.value) {
    return bumperDisplayDetail.value?.parentBumperContents
      ?.filter((content) => content.positionName === positionName)
      ?.map((content) => content.bumper) ?? [];
  }

  return bumperDisplayDetail.value?.summaryBumperContents
    ?.filter((content) => content.positionName === positionName)
    ?.map((content) => content.bumper) ?? [];
}

function onChangeContentProject(projectKey: string) {
  selectedProjectKey.value = projectKey;
}

function onTabChanged() {
  if (currentTab.value === 'all') {
    filteredIntro1.value = [...intro1.value];
    filteredIntro2.value = [...intro2.value];
    filteredOutro1.value = [...outro1.value];
    filteredOutro2.value = [...outro2.value];
  } else {
    filteredIntro1.value = filterBumperByStatus(intro1.value, currentTab.value);
    filteredIntro2.value = filterBumperByStatus(intro2.value, currentTab.value);
    filteredOutro1.value = filterBumperByStatus(outro1.value, currentTab.value);
    filteredOutro2.value = filterBumperByStatus(outro2.value, currentTab.value);
  }
}

watch(intro1, () => {
  const tab = currentTab.value;

  if (tab === 'all') {
    filteredIntro1.value = [...intro1.value];
  } else {
    filteredIntro1.value = filterBumperByStatus(intro1.value, tab);
  }
}, { deep: true });

watch(intro2, () => {
  const tab = currentTab.value;

  if (tab === 'all') {
    filteredIntro2.value = [...intro2.value];
  } else {
    filteredIntro2.value = filterBumperByStatus(intro2.value, tab);
  }
}, { deep: true });

watch(outro1, () => {
  const tab = currentTab.value;

  if (tab === 'all') {
    filteredOutro1.value = [...outro1.value];
  } else {
    filteredOutro1.value = filterBumperByStatus(outro1.value, tab);
  }
}, { deep: true });

watch(outro2, () => {
  const tab = currentTab.value;

  if (tab === 'all') {
    filteredOutro2.value = [...outro2.value];
  } else {
    filteredOutro2.value = filterBumperByStatus(outro2.value, tab);
  }
}, { deep: true });

watch(bumperDisplayDetail, () => {
  if (loadBumperDisplayDetailState.value.status === 'success') {
    setPositions();
  }
});

watch(selectedContent, (value, oldValue) => {
  if (oldValue.content && value.content) {
    const prevProject = oldValue?.projectKey;
    const currentProject = value?.projectKey;

    const prevCollection = oldValue?.content?.content?.contentCollectionId;
    const currentCollection = value?.content?.content?.contentCollectionId;
    if (prevProject !== currentProject || (prevCollection && currentCollection && prevCollection !== currentCollection)) {
      loadFromParent();
    }
  }
});

watch(saveBumperDisplayState, () => {
  if (saveBumperDisplayState.value?.status === 'success' && newBumperDisplayId.value) {
    router.push(`/${teamName.value}/manage/bumper-insertion/${newBumperDisplayId.value}`);
  }
});

watch(currentTab, onTabChanged);

onMounted(async () => {
  document.body.style.overflowY = 'hidden';
  if (!isEditMode.value) {
    if (selectedContent.value.type === 'project') {
      store.dispatch('setBumperSources', getPositionArrayBySourceType('custom'));
    } else {
      // get scheduleDetail from parent on create mode
      await loadFromParent();
    }
  } else {
    await store.dispatch('loadBumperDisplayDetail', scheduleId.value);
  }

  if (!currentTab.value) {
    router.push({
      hash: hash.value,
      query: { ...query.value, tab: 'active,scheduled' },
      replace: true,
    });
  } else {
    onTabChanged();
  }

  if (hash.value) {
    const targetElement = document.getElementById(hash.value.substring(1));
    if (targetElement) {
      targetElement.scrollIntoView({
        behavior: 'smooth',
      });
    }
  }
});

onUnmounted(() => {
  document.body.style.overflowY = 'auto';
  store.dispatch('clearBumperDisplayData');
  store.commit('setSaveBumperDisplayState', { type: 'idle' });
});

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (selectedContent.value)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _cache[25] || (_cache[25] = _createElementVNode("div", { class: "background-mask" }, null, -1)),
        (isShowChooseBumperModal.value)
          ? (_openBlock(), _createBlock(ChooseBumperModal, {
              key: 0,
              onModalClose: onCloseChooseBumperModal,
              onSubmit: _cache[0] || (_cache[0] = (submitValue) => onSelectedBumper(submitValue)),
              "disabled-categories": disabledBumperPosition.value,
              "category-name": addToCategoryName.value,
              "is-already-choose-category": Boolean(addToCategoryName.value.length)
            }, null, 8, ["disabled-categories", "category-name", "is-already-choose-category"]))
          : _createCommentVNode("", true),
        (isShowSelectBumperContentModal.value && selectedContent.value.projectKey)
          ? (_openBlock(), _createBlock(SelectBumperContentModal, {
              key: 1,
              "selected-content-type": selectedContent.value.type,
              projectKey: selectedContent.value.projectKey,
              onModalClose: _cache[1] || (_cache[1] = () => isShowSelectBumperContentModal.value = false),
              "onUpdate:projectKey": _cache[2] || (_cache[2] = (projectKey) => onChangeContentProject(projectKey)),
              "submit-button-title": _unref(t)('bumperSchedule.create.selectContent'),
              onSubmit: onContentChange,
              secondaryButtonTitle: _unref(t)('common:button.cancel')
            }, null, 8, ["selected-content-type", "projectKey", "submit-button-title", "secondaryButtonTitle"]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(Button, {
            onClick: handleClose,
            buttonStyle: "link-secondary",
            buttonClasses: "back-link"
          }, {
            "icon-prefix": _withCtx(() => _cache[8] || (_cache[8] = [
              _createElementVNode("i", { class: "fas fa-chevron-left" }, null, -1)
            ])),
            default: _withCtx(() => [
              _createTextVNode(" " + _toDisplayString(_unref(t)('common:button.back')), 1)
            ]),
            _: 1
          }),
          _createElementVNode("h2", _hoisted_3, _toDisplayString(isEditMode.value ? _unref(t)('common:button.edit') : _unref(t)('bumperSchedule.schedule.set')) + " Bumper Insertion", 1),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, _toDisplayString(_unref(t)('bumperSchedule.schedule.useInContent')), 1)
            ]),
            (!selectedContent.value?.content)
              ? (_openBlock(), _createElementBlock("div", _hoisted_7))
              : _createCommentVNode("", true),
            (selectedContent.value?.content)
              ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                  _createElementVNode("div", _hoisted_9, [
                    _createElementVNode("div", _hoisted_10, [
                      _createVNode(ImageView, {
                        source: _unref(getVideoUploadCoverImage)({ video: selectedContent.value.content.content, currentUser: currentUser.value }),
                        imageSize: "small"
                      }, null, 8, ["source"]),
                      (selectedContent.value.content.content?.duration)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_11, _toDisplayString(_unref(getVideoDuration)(selectedContent.value.content.content)), 1))
                        : _createCommentVNode("", true)
                    ]),
                    _createElementVNode("div", _hoisted_12, [
                      _createElementVNode("a", {
                        href: getContentURL(selectedContent.value),
                        target: "_blank",
                        class: "content-title"
                      }, _toDisplayString(contentTitle.value), 9, _hoisted_13),
                      (
                  selectedContent.value.type === 'project' &&
                    Boolean(selectedContent.value.content?.content?.videoCount)
                )
                        ? (_openBlock(), _createElementBlock("span", _hoisted_14, " (" + _toDisplayString(selectedContent.value.content.content.videoCount) + " " + _toDisplayString(_unref(t)(
                    `glossary:video${
                      selectedContent.value.content.content.videoCount > 1 ? '_plural' : ''
                    }`
                  )) + ") ", 1))
                        : _createCommentVNode("", true),
                      _createVNode(Badge, {
                        title: 
                  selectedContent.value?.type !== 'contentCollection'
                    ? selectedContent.value?.type
                    : 'collection'
                ,
                        badgeStyle: "info"
                      }, null, 8, ["title"])
                    ])
                  ]),
                  _createVNode(Button, {
                    type: "button",
                    buttonStyle: "secondary",
                    onClick: onClickChange
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_unref(t)('common:button.change')), 1)
                    ]),
                    _: 1
                  })
                ]))
              : _createCommentVNode("", true)
          ]),
          (loadBumperDisplayDetailState.value?.status === 'loading')
            ? (_openBlock(), _createBlock(PreLoaderSection, { key: 0 }))
            : _createCommentVNode("", true),
          (loadBumperDisplayDetailState.value?.status !== 'loading')
            ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                _createElementVNode("div", _hoisted_16, [
                  _cache[10] || (_cache[10] = _createElementVNode("div", { class: "section-title" }, "Bumper Insertion", -1)),
                  (!isEmpty.value)
                    ? (_openBlock(), _createBlock(Button, {
                        key: 0,
                        onClick: _cache[3] || (_cache[3] = () => onClickAddBumper('')),
                        buttonStyle: "secondary"
                      }, {
                        "icon-prefix": _withCtx(() => _cache[9] || (_cache[9] = [
                          _createElementVNode("i", { class: "fas fa-plus" }, null, -1)
                        ])),
                        default: _withCtx(() => [
                          _createTextVNode(" " + _toDisplayString(_unref(t)('bumper.add')), 1)
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true)
                ]),
                _createVNode(Section, { class: "bumper-display-container" }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", null, [
                      _createElementVNode("div", _hoisted_17, [
                        _createVNode(TabList, null, {
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(bumperStatusTabs), (tab) => {
                              return (_openBlock(), _createBlock(_component_router_link, {
                                key: tab.key,
                                to: { query: { tab: tab.key } },
                                class: _normalizeClass([{
                    active:
                      tab.key === 'active,scheduled'
                        ? !query.value?.tab || query.value?.tab === tab.key
                        : query.value?.tab === tab.key,
                  }, "tab-item"]),
                                "active-class": "",
                                "exact-active-class": ""
                              }, {
                                default: _withCtx(() => [
                                  _createElementVNode("span", _hoisted_18, _toDisplayString(tab.title), 1)
                                ]),
                                _: 2
                              }, 1032, ["to", "class"]))
                            }), 128))
                          ]),
                          _: 1
                        })
                      ]),
                      _createElementVNode("div", _hoisted_19, _toDisplayString(_unref(t)('bumperSchedule.schedule.description')), 1),
                      (!isEditMode.value || loadBumperDisplayDetailState.value?.status === 'success')
                        ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
                            _createVNode(BumperDraggableGroup, {
                              name: "Intro 1",
                              onAdd: _cache[4] || (_cache[4] = ($event: any) => (onClickAddBumper('Intro 1'))),
                              parentBumper: parentBumperDisplay.value,
                              inheritedBumpers: getParentBumpers('intro1'),
                              value: filteredIntro1.value
                            }, {
                              default: _withCtx(() => [
                                _cache[13] || (_cache[13] = _createTextVNode(" Intro 1 ")),
                                _createVNode(Tooltip, null, {
                                  "tooltip-text": _withCtx(() => [
                                    _createElementVNode("div", _hoisted_21, [
                                      _cache[11] || (_cache[11] = _createTextVNode(" Intro 1: ")),
                                      _createElementVNode("ul", _hoisted_22, [
                                        _createElementVNode("li", {
                                          innerHTML: _unref(t)('bumperSchedule.position.intro')
                                        }, null, 8, _hoisted_23),
                                        _createElementVNode("li", {
                                          innerHTML: _unref(t)('bumperSchedule.position.nonAds')
                                        }, null, 8, _hoisted_24),
                                        _createElementVNode("li", {
                                          innerHTML: _unref(t)('bumperSchedule.position.nonSkippable')
                                        }, null, 8, _hoisted_25)
                                      ])
                                    ])
                                  ]),
                                  default: _withCtx(() => [
                                    _cache[12] || (_cache[12] = _createElementVNode("i", { class: "fas fa-info-circle" }, null, -1))
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            }, 8, ["parentBumper", "inheritedBumpers", "value"]),
                            _createVNode(BumperDraggableGroup, {
                              name: "Intro 2",
                              onAdd: _cache[5] || (_cache[5] = ($event: any) => (onClickAddBumper('Intro 2'))),
                              parentBumper: parentBumperDisplay.value,
                              inheritedBumpers: getParentBumpers('intro2'),
                              value: filteredIntro2.value
                            }, {
                              default: _withCtx(() => [
                                _cache[16] || (_cache[16] = _createTextVNode(" Intro 2 ")),
                                _createVNode(Tooltip, null, {
                                  "tooltip-text": _withCtx(() => [
                                    _createElementVNode("div", _hoisted_26, [
                                      _cache[14] || (_cache[14] = _createTextVNode(" Intro 2: ")),
                                      _createElementVNode("ul", _hoisted_27, [
                                        _createElementVNode("li", {
                                          innerHTML: _unref(t)('bumperSchedule.position.intro')
                                        }, null, 8, _hoisted_28),
                                        _createElementVNode("li", {
                                          innerHTML: _unref(t)('bumperSchedule.position.ads')
                                        }, null, 8, _hoisted_29),
                                        _createElementVNode("li", {
                                          innerHTML: _unref(t)('bumperSchedule.position.skippable')
                                        }, null, 8, _hoisted_30)
                                      ])
                                    ])
                                  ]),
                                  default: _withCtx(() => [
                                    _cache[15] || (_cache[15] = _createElementVNode("i", { class: "fas fa-info-circle" }, null, -1))
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            }, 8, ["parentBumper", "inheritedBumpers", "value"]),
                            _createVNode(BumperDraggableGroup, {
                              name: "Outro 1",
                              onAdd: _cache[6] || (_cache[6] = ($event: any) => (onClickAddBumper('Outro 1'))),
                              parentBumper: parentBumperDisplay.value,
                              inheritedBumpers: getParentBumpers('outro1'),
                              value: filteredOutro1.value
                            }, {
                              default: _withCtx(() => [
                                _cache[19] || (_cache[19] = _createTextVNode(" Outro 1 ")),
                                _createVNode(Tooltip, null, {
                                  "tooltip-text": _withCtx(() => [
                                    _createElementVNode("div", _hoisted_31, [
                                      _cache[17] || (_cache[17] = _createTextVNode(" Outro 1: ")),
                                      _createElementVNode("ul", _hoisted_32, [
                                        _createElementVNode("li", {
                                          innerHTML: _unref(t)('bumperSchedule.position.outro')
                                        }, null, 8, _hoisted_33),
                                        _createElementVNode("li", {
                                          innerHTML: _unref(t)('bumperSchedule.position.nonAds')
                                        }, null, 8, _hoisted_34),
                                        _createElementVNode("li", {
                                          innerHTML: _unref(t)('bumperSchedule.position.nonSkippable')
                                        }, null, 8, _hoisted_35)
                                      ])
                                    ])
                                  ]),
                                  default: _withCtx(() => [
                                    _cache[18] || (_cache[18] = _createElementVNode("i", { class: "fas fa-info-circle" }, null, -1))
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            }, 8, ["parentBumper", "inheritedBumpers", "value"]),
                            _createVNode(BumperDraggableGroup, {
                              name: "Outro 2",
                              onAdd: _cache[7] || (_cache[7] = ($event: any) => (onClickAddBumper('Outro 2'))),
                              parentBumper: parentBumperDisplay.value,
                              inheritedBumpers: getParentBumpers('outro2'),
                              value: filteredOutro2.value
                            }, {
                              default: _withCtx(() => [
                                _cache[22] || (_cache[22] = _createTextVNode(" Outro 2 ")),
                                _createVNode(Tooltip, null, {
                                  "tooltip-text": _withCtx(() => [
                                    _createElementVNode("div", _hoisted_36, [
                                      _cache[20] || (_cache[20] = _createTextVNode(" Outro 2: ")),
                                      _createElementVNode("ul", _hoisted_37, [
                                        _createElementVNode("li", null, _toDisplayString(_unref(t)('bumperSchedule.position.outro')), 1),
                                        _createElementVNode("li", null, _toDisplayString(_unref(t)('bumperSchedule.position.nonAds')), 1),
                                        _createElementVNode("li", null, _toDisplayString(_unref(t)('bumperSchedule.position.nonSkippable')), 1)
                                      ])
                                    ])
                                  ]),
                                  default: _withCtx(() => [
                                    _cache[21] || (_cache[21] = _createElementVNode("i", { class: "fas fa-info-circle" }, null, -1))
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            }, 8, ["parentBumper", "inheritedBumpers", "value"])
                          ]))
                        : _createCommentVNode("", true)
                    ])
                  ]),
                  _: 1
                })
              ]))
            : _createCommentVNode("", true),
          (loadBumperDisplayDetailState.value?.status !== 'loading')
            ? (_openBlock(), _createElementBlock("div", _hoisted_38, [
                (saveBumperDisplayState.value?.status == 'error')
                  ? (_openBlock(), _createElementBlock("span", _hoisted_39, [
                      _cache[23] || (_cache[23] = _createElementVNode("i", { class: "fa fa-xmark" }, null, -1)),
                      _createTextVNode(" " + _toDisplayString(formatErrorCode(saveBumperDisplayState.value.error)), 1)
                    ]))
                  : _createCommentVNode("", true),
                (saveBumperDisplayState.value?.status == 'success')
                  ? (_openBlock(), _createElementBlock("span", _hoisted_40, [
                      _cache[24] || (_cache[24] = _createElementVNode("i", { class: "fas fa-check" }, null, -1)),
                      _createTextVNode(" " + _toDisplayString(_unref(t)('common:button.saved')), 1)
                    ]))
                  : _createCommentVNode("", true),
                _createVNode(Button, {
                  buttonStyle: "text-secondary",
                  onClick: handleClose
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_unref(t)('common:button.cancel')), 1)
                  ]),
                  _: 1
                }),
                _createVNode(Button, {
                  disabled: isEmpty.value || saveBumperDisplayState.value?.status === 'saving',
                  isSaving: saveBumperDisplayState.value?.status === 'saving',
                  onClick: onConfirm
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(isEditMode.value ? _unref(t)('common:button.save') : _unref(t)('bumperSchedule.schedule.add')), 1)
                  ]),
                  _: 1
                }, 8, ["disabled", "isSaving"])
              ]))
            : _createCommentVNode("", true)
        ])
      ]))
    : _createCommentVNode("", true)
}
}

})