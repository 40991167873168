<template>
  <MultiSelect
    v-model="selectedCountries"
    :options="filteredCountryList"
    trackBy="countryCode"
    label="countryNameEN"
    :placeholder="t('common:multiselect.country.select')"
    :selectedLabel="t('common:multiselect.selected')"
    :disabled="disabled"
    id="country-code"
    searchable />
</template>

<script setup lang='ts'>
import MultiSelect from '@/modules/shared/components/atoms/multiSelect/MultiSelect.vue';
import { useStore } from '@/store';
import {
  onMounted,
  ref,
  watch,
  Ref,
  PropType,
  computed, WritableComputedRef, toRefs,
} from 'vue';

// Types
import { mapCountryCodesToObject } from '@/modules/geoblock';
import { Country } from '@/modules/shared/types/country.type';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
const props = defineProps({
  modelValue: {
    type: Array as PropType<string[]>,
  },
  disabledCountryCode: {
    type: Array as PropType<string[]>,
    required: false,
  },
  disabled: {
    type: Boolean,
  },
});

const emits = defineEmits<{(e: 'update:modelValue', value: string[]): void}>();
const { modelValue, disabledCountryCode, disabled } = toRefs(props);

const selectedCountriesData: Ref<string[]> = ref([]);

// store
const store = useStore();
const countryList = computed<Country[]>(() => store.state.geoblockRuleList.countryList);
const isEmptyCountryList = computed(() => store.getters.isEmptyCountryList);

// computed
const selectedCountries: WritableComputedRef<Country[]> = computed({
  get() {
    return mapCountryCodesToObject(selectedCountriesData.value, countryList.value);
  },
  set(newValue) {
    selectedCountriesData.value = newValue.map((item) => item.countryCode);
  },
});

const filteredCountryList = computed(() => {
  if (disabledCountryCode?.value && disabledCountryCode.value.length) {
    return countryList.value.filter((country) => !disabledCountryCode.value
      ?.includes(country?.countryCode ?? 'this country code is not available'));
  }
  return countryList.value;
});

// methods
const loadCountryList = async () => {
  if (isEmptyCountryList.value) {
    await store.dispatch('loadCountryList');
  }
};

watch(selectedCountriesData, () => {
  if (modelValue?.value !== selectedCountriesData.value) {
    emits('update:modelValue', selectedCountriesData.value);
  }
});

onMounted(() => {
  if (modelValue?.value) {
    selectedCountriesData.value = [...modelValue.value];
  }
  loadCountryList();
});
</script>

<style lang="scss" scoped>

</style>
