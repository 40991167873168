<template>
  <ReUploadVideoSourceFileModal
    v-if="isShowReuploadModal"
    :video
    :onClose="onReuploadVideoSourceFile"
  />
  <div :class="`list-group-item list-group-item-${uploadingState}`">
    <div :class="['icon-prefix', uploadingState, { 'is-failed': isFailed }]">
      <i class="fa-solid fa-file-video" />
    </div>
    <div class="detail">
      <div class="title">
        {{video.title}}
      </div>
      <div v-if="isQueued" class="state-item state-queued">
        {{t('video.uploader.enqueued')}}
      </div>
      <div v-if="isUploading" class="state-item state-uploading">
        <div class="progress-container">
          <div class="progress-bar" :style="{ 'width': `${uploadProgress}%` }"></div>
        </div>
        <div class="progress-upload-container">
          <div class="size">
            {{ uploadedSize}} / {{ totalSize }}
            ({{uploadSpeed}}/s)
          </div>
          <div class="percent">
            {{uploadProgress}}%
          </div>
        </div>
      </div>
      <div v-if="isUploaded" class="state-item state-uploaded">
        <span
          v-if="isDisplayProbeStatus"
          :class="{
            'probe-status state-upload': true,
            'text-danger': isVideoProbeError(video),
            'warning-status': probeStatus === 'overtime',
          }"
        >
          {{getProbeStatus()}}
        </span>
        <span v-else-if="!isDisplayProbeStatus && uploadProgress !== 100">
          {{uploadProgress}}% uploaded
        </span>
        <span v-else>
          {{ t('video.upload.uploaded') }}
        </span>
      </div>
      <div v-if="isRetry" class="state-item state-retry">
        <div class="spinner-border text-primary">
          <span class="sr-only"></span>
        </div>
        {{t('video.uploader.failed.retrying', {item: video.remainingRetryDelay})}}
      </div>
      <div class="state-item state-failed"
           v-if="isFailed">
        <span class="text-danger">{{t('video.uploader.failed.helpText')}}</span>
      </div>
      <div class="video-info show-mobile">
        <VideoKeyCopy :videoKey="video.key" />
        <Tooltip v-if="getVideoURL(video)" class="copy-url-button-container">
          <template v-slot:tooltip-text> Copy Video URL</template>
          <Button
            @click.prevent="onCopy('videoUrl', getVideoURL(video))"
            type="button"
            buttonStyle="text-secondary"
            buttonClasses="link-copy-button"
          >
            <template v-slot:icon-prefix>
              <i v-if="isVideoUrlCopied" class="fas fa-check"></i>
              <i v-else class="fas fa-link"></i>
            </template>
          </Button>
        </Tooltip>
        <Tooltip v-if="getEmbeddedCode(video)" class="copy-embed-button-container">
          <template v-slot:tooltip-text> Copy Embed Code</template>
          <Button
            @click.prevent="onCopy('embedded', getEmbeddedCode(video))"
            type="button"
            buttonStyle="text-secondary"
            buttonClasses="link-copy-button"
          >
            <template v-slot:icon-prefix>
              <i v-if="isEmbedCopied" class="fas fa-check"></i>
              <i v-else class="fas fa-code"></i>
            </template>
          </Button>
        </Tooltip>
      </div>
    </div>
    <div class="video-info show-desktop">
      <VideoKeyCopy :videoKey="video.key" />
      <Tooltip v-if="getVideoURL(video)" class="copy-url-button-container">
        <template v-slot:tooltip-text> Copy Video URL</template>
        <Button
          @click.prevent="onCopy('videoUrl', getVideoURL(video))"
          type="button"
          buttonStyle="text-secondary"
          buttonClasses="link-copy-button"
        >
          <template v-slot:icon-prefix>
            <i v-if="isVideoUrlCopied" class="fas fa-check"></i>
            <i v-else class="fas fa-link"></i>
          </template>
        </Button>
      </Tooltip>
      <Tooltip v-if="getEmbeddedCode(video)" class="copy-embed-button-container">
        <template v-slot:tooltip-text> Copy Embed Code</template>
        <Button
          @click.prevent="onCopy('embedded', getEmbeddedCode(video))"
          type="button"
          buttonStyle="text-secondary"
          buttonClasses="link-copy-button"
        >
          <template v-slot:icon-prefix>
            <i v-if="isEmbedCopied" class="fas fa-check"></i>
            <i v-else class="fas fa-code"></i>
          </template>
        </Button>
      </Tooltip>
    </div>
    <div class="actions">
      <span v-if="canShowCompleteState" :title="t('video.uploader.success')">
        <i class="fas fa-circle-check text-success" />
      </span>
      <span v-if="probeStatus === 'checking'" :title="t('video.uploader.success')">
        <i class="fad fa-spinner-third fa-spin text-primary" />
      </span>
      <span v-if="isFailed" class="with-pointer" :title="t('video.uploader.failed.button')" @click="onRetryUploadVideo">
        <i class="fas fa-rotate-right" />
      </span>
      <span
        v-if="isVideoProbeError(video) && !isVideoSourceUploading(video)"
        :title="t('video.uploader.failed.button')"
        class="with-pointer"
        @click="onReuploadVideoSourceFile"
      >
        <i class="fas fa-upload" />
      </span>
    </div>
  </div>
</template>

<script setup lang="ts">
/* eslint-disable */
import { copyValue } from '@/modules/shared/utils/copy';
import { useI18n } from 'vue-i18n';
import _ from 'lodash';
import { computed, ref, toRefs } from 'vue';
import { VideoKeyCopy } from '@/modules/projectVideoList';
import Button from '@/modules/shared/components/atoms/button/Button.vue';
import Tooltip from '@/modules/shared/components/atoms/tooltip/Tooltip.vue';
import { getVideoURL, getEmbeddedCode } from '@/modules/shared/utils/video';
import { type VideoUploadByKeyObject } from '@/modules/videoUpload/types/videoUpload.type';
import { prettyFileSize } from '@/modules/shared/utils/unitFormatter';
import {
  isVideoProbeError,
  isVideoSourceUploading,
  isVideoProbeCompleted,
} from '@/modules/videoDetail/utils/videoManagement';
import { useStore } from '../../../store/hooks';
import ReUploadVideoSourceFileModal from '../../organisms/reUploadVideoSourceFileModal/ReUploadVideoSourceFileModal.vue';

interface ExtendedUploadItemProps {
  video: VideoUploadByKeyObject;
}

const props = defineProps<ExtendedUploadItemProps>();
const { video } = toRefs(props);

const isShowReuploadModal = ref<boolean>(false);
const isEmbedCopied = ref<boolean>(false);
const isVideoUrlCopied = ref<boolean>(false);
const probeStatus = ref<string>('');

const { t } = useI18n();
const store = useStore();

const isDisplayProbeStatus = computed(() => store.state.videoUpload.isDisplayProbeStatus);
const uploadingInfo = computed(() => video.value.uploadingInfo);
const uploadProgress = computed(() => video.value.uploadingInfo?.progress ?? 0);
const uploadedSize = computed(() => prettyFileSize(_.get(uploadingInfo.value, 'uploadedSize', 0)));
const totalSize = computed(() => prettyFileSize(_.get(uploadingInfo.value, 'totalSize', 0)));

const uploadSpeed = computed(() => {
  const size = _.get(uploadingInfo.value, 'uploadedSize', 0);
  const uploadTimeStamp = _.get(uploadingInfo.value, 'differenceTime', 0);

  if (size && uploadTimeStamp) {
    const uploadTimeSecond = ((uploadTimeStamp) / 1000);
    return prettyFileSize(size / uploadTimeSecond);
  }

  return '0';
});

const uploadingState = computed(() => uploadingInfo.value?.state ?? 'queued');
const isQueued = computed(() => uploadingState.value === 'queued');
const isUploading = computed(() => uploadingState.value === 'uploading');
const isUploaded = computed(() => uploadingState.value === 'uploaded');
const isRetry = computed(() => uploadingState.value === 'retry');
const isFailed = computed(() => uploadingState.value === 'failed');
const canRemoveFile = computed(() => isQueued.value);
const canShowCompleteState = computed(() => {
  if (!isDisplayProbeStatus.value) {
    return isUploaded.value;
  }

  return isUploaded.value && isVideoProbeCompleted(video.value) && !isVideoProbeError(video.value);
});

function getProbeStatus(): string {
  if (!isVideoProbeCompleted(video.value)) {
    probeStatus.value = 'checking';
    return t('video.upload.checkingFile');
  }

  if (isVideoProbeError(video.value)) {
    probeStatus.value = 'error';
    return t('video.upload.uploadedFileError.fullText');
  }

  probeStatus.value = 'complete';
  return t('video.upload.uploaded');
}

function onRetryUploadVideo() {
  const videoKey = video.value.key;
  store.dispatch('forceRetryUploadVideo', { videoKey });
}

function onReuploadVideoSourceFile() {
  isShowReuploadModal.value = !isShowReuploadModal.value;
}

function onCopy(key: string, value: string) {
  copyValue(value);

  if (key === 'embedded') {
    isEmbedCopied.value = true;
  } else {
    isVideoUrlCopied.value = true;
  }

  const timeout = setTimeout(() => {
    if (key === 'embedded') {
      isEmbedCopied.value = false;
    } else {
      isVideoUrlCopied.value = false;
    }

    clearTimeout(timeout);
  }, 1000);
}
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/global-variables.scss';
@import '~@/assets/scss/breakpoint.scss';

.icon-prefix {
  font-size: 34px;
  margin-right: $spacing-24;

  &.uploading {
    color: $grey-600;
  }

  &.completed {
    color: $grey-800;
  }

  &.failed {
    color: $danger;
  }
}

:deep(.video-key-copy) {
  margin-right: $spacing-24;
}

:deep(.link-copy-button) {
  color: $grey-600;
}

.video-info {
  display: flex;
  margin-left: $spacing-32;
  gap: 24px;
}

.list-group-item {
  position: relative;
  display: flex;
  align-items: center;
  border-radius: $border-radius-5;
  color: $grey-600;
  border: 1px solid $grey-200;
  background-color: #FFF;
  padding: $spacing-base;

  .actions {
    margin-left: auto;
    flex: 0 0 20px;
    text-align: center;
    font-size: $font-size-base * 1.25;
    color: $grey-600;

    .with-pointer {
      cursor: pointer;
      transition: color 150ms ease-out;

      &:hover {
        color: $grey-800;
      }
    }
  }

  &:hover {
    text-decoration: none;
  }

  &.list-group-item-retrying {
    .actions {

      .spinner-border {
        width: 20px;
        height: 20px;
        border-width: 2px;
        transition: all 0.5s ease-out;
      }

      .remove {
        display: none;
        transition: all 0.5s ease-out;
      }

      &:hover {
        .spinner-border {
          display: none;
        }

        .remove {
          display: flex;
        }
      }
    }
  }
}

.detail {
  flex: 0 0 300px;
  // flex: 1 0 auto;

  .title {
    margin-bottom: $spacing-8;
    color: $grey-800;
    font-weight: $font-weight-bold;
    line-height: 1.15;
  }

  .state-item {

    &.state-retry {
      display: flex;
      .spinner-border {
        width: 14px;
        height: 14px;
        border-width: 2px;
        margin-right: 4px;
      }
    }

    &:not(.state-uploaded) {
      font-size: $font-size-base * 0.75;
    }

    &.state-uploaded {
      font-size: $font-size-base * 0.875;

      .warning-status {
        color: darken($warning, 3%);
      }
    }

  }

  .progress-container {
    margin-top: $spacing-base * 0.25;
    height: 4px;
    width: 100%;
    border-radius: 2px;
    background-color: $grey-200;
    position: relative;

    .progress-bar {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      background-color: $success;
      transition: all 0.2s ease-out;
    }
  }

  .progress-upload-container {
    margin-top: $spacing-8;
    display: flex;

    .percent {
      margin-left: auto;
    }
  }

  .error {
    margin-top: $spacing-base * 0.25;
    font-size: $font-level-8;
  }
}

.show-mobile {
  display: none;
}

@media screen and (max-width: $max-layout-lg) {
  .video-info {
    gap: $spacing-base;
  }

  :deep(.video-key-copy) {
    margin-right: $spacing-8;
  }
}

@media screen and (max-width: $max-layout-md) {
  .show-mobile {
    display: flex;
  }

  .show-desktop {
    display: none;
  }

  .list-group-item {
    align-items: flex-start;
    padding-top: $spacing-8;
    padding-bottom: $spacing-4;
    padding-right: $spacing-8;

    .actions {
      position: absolute;
      top: 16px;
      right: 12px;
      font-size: 24px;
    }
  }

  .icon-prefix {
    font-size: 32px;
    margin-right: $spacing-base;
    margin-top: $spacing-4;
  }

  .video-info {
    margin-left: 0;
    margin-top: $spacing-8;
  }

  :deep(.video-key-copy) {
    margin-right: auto;
  }

  .detail {
    .title {
      margin-bottom: $spacing-8;
    }

    .state-item {
      width: 80%;
    }
  }
}

@media screen and (max-width: $max-layout-sm) {
  .list-group-item {
    .detail {
      flex: 1 0 auto;
    }
  }
}
</style>
