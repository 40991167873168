import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "dragging-overlay-container"
}
const _hoisted_2 = { class: "dragging-overlay" }

import { useStore } from '@/store';
import { computed } from 'vue';
import MessageBox from '@/modules/shared/components/molecules/messageBox/MessageBox.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'VideoUploaderDraggableUI',
  setup(__props) {

const store = useStore();
const isDraggingVideoState = computed(() => store.getters.isDraggingVideoState);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_Transition, { name: "fade" }, {
      default: _withCtx(() => [
        (isDraggingVideoState.value)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createElementVNode("div", _hoisted_2, [
                _createVNode(MessageBox, {
                  iconClassName: "fas fa-cloud-arrow-up",
                  title: _ctx.$t('video.uploader.dropToUpload')
                }, null, 8, ["title"])
              ])
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ]))
}
}

})