import store from '@/store';
import project from '@/modules/shared/store/project';
import user from '@/modules/user/store/user';
import type { RouteRecordRaw } from 'vue-router';
import { projectVideoList } from '@/modules/projectVideoList';
import webhookList from '../store/webhookList';
import webhook from '../store/webhook';

export function createWebhookRoutes(): RouteRecordRaw[] {
  return [
    {
      name: 'webhooks',
      path: 'webhooks',
      beforeEnter: (to, from, next) => {
        if (!store.hasModule('webhookList')) {
          store.registerModule('webhookList', webhookList);
        }
        if (!store.hasModule('webhook')) {
          store.registerModule('webhook', webhook);
        }
        if (!store.hasModule('project')) {
          store.registerModule('project', project);
        }
        if (!store.hasModule('projectVideoList')) {
          store.registerModule('projectVideoList', projectVideoList);
        }
        if (!store.hasModule('user')) {
          store.registerModule('user', user);
        }
        next();
      },
      children: [
        {
          name: 'projectManageSettingWebhooks',
          path: '',
          component: () => import(/* webpackChunkName: "ProjectManageSetting" */ '../pages/ProjectManageWebhooks.vue'),
        },
        {
          name: 'manageWebhookDetails',
          path: ':id',
          component: () => import(/* webpackChunkName: "ManageWebhookDetails" */ '../pages/ProjectManageWebhookDetail.vue'),
        },
      ],
    },
  ];
}
