<template>
  <div :class="['field-container', className]">
    <FormLabel
      class="form-label"
      :title="title"
      :subtitle="subtitle"
      :characterCount="characterCount"
      :max="max"
      :min="min"
    />
    <slot></slot>
    <FormErrorMessage
      v-if="errorMessage && isShowErrorMessage"
      :errorMessage="errorMessage"
    />
    <FormHelpText
      v-if="helpText && !(errorMessage && isShowErrorMessage)"
      :helpText="helpText"
      :isHtml="isHelpTextHtml"
    />
  </div>
</template>

<script lang="ts" setup>
import FormLabel from '@/modules/shared/components/molecules/formLabel/FormLabel.vue';
import FormErrorMessage from '@/modules/shared/components/atoms/formErrorMessage/FormErrorMessage.vue';
import FormHelpText from '@/modules/shared/components/atoms/formHelpText/FormHelpText.vue';
import { toRefs } from 'vue';

interface FieldProps {
  isShowErrorMessage?: boolean;
  errorMessage?: string;
  title?: string;
  subtitle?: string;
  helpText?: string;
  isHelpTextHtml?: boolean;
  min?: string;
  max?: string;
  characterCount?: number;
  className?: string;
}

const props = withDefaults(defineProps<FieldProps>(), {
  isShowErrorMessage: false,
  isHelpTextHtml: false,
});
const {
  isShowErrorMessage,
  errorMessage,
  title,
  subtitle,
  helpText,
  isHelpTextHtml,
  min,
  max,
  characterCount,
  className,
} = toRefs(props);
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/global-variables.scss";
.form-label {
  margin-bottom: $spacing-8;
}
</style>
