<script setup lang="ts">
import isNil from 'lodash/isNil';
import {
  computed, ref, toRefs,
} from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';
import type { ProjectMember } from '@/modules/projectMember/types';
import ErrorMessage from '@/modules/shared/components/atoms/errorMessage/ErrorMessage.vue';
import { getQueryAsString } from '@/modules/shared/utils/query';
import { formatErrorObject } from '@/modules/shared/utils/errorFormatter';
import { setStateLoadingStatusByType } from '@/modules/shared/utils/stateManagement';
import InputCopy from '@/modules/shared/components/molecules/inputCopy/InputCopy.vue';
import { getNewInvitationUrl } from '@/modules/projectMember/services';
import type { StatusState } from '@/types/State.type';
import Modal from '@/modules/shared/components/organisms/modal/Modal.vue';
import Button from '@/modules/shared/components/atoms/button/Button.vue';

const props = defineProps<{
  member: ProjectMember | null;
  onCloseModal:() => void;
}>();

const {
  member,
  onCloseModal,
} = toRefs(props);

const { t } = useI18n();
const route = useRoute();

const invitationLink = ref<string>('');
const fetchInvitationLinkState = ref<StatusState>(setStateLoadingStatusByType({ type: 'idle' }));

const projectKey = computed(() => getQueryAsString(route.params, 'projectKey'));

async function fetchInvitationLink() {
  if (isNil(member.value) || !('account' in member.value) || isNil(projectKey.value)) {
    return;
  }

  fetchInvitationLinkState.value = setStateLoadingStatusByType({ type: 'loading' });

  try {
    const response = await getNewInvitationUrl(projectKey.value, {
      email: member.value.account.email,
      projectRole: member.value.projectRole,
    });

    invitationLink.value = response.data.updateUserLink;

    fetchInvitationLinkState.value = setStateLoadingStatusByType({ type: 'success' });
  } catch (error) {
    fetchInvitationLinkState.value = setStateLoadingStatusByType({
      type: 'error',
      error: formatErrorObject(error, 'Fetch Invitation Link'),
    });
  }
}

</script>

<template>
  <div v-if="!isNil(member) && ('account' in member)" class="modal-container">
    <Modal :title="t('project.manage.members.inviteLink.title')" @modal-close="onCloseModal">
      <template v-slot:body>
        <!-- <div v-if="fetchInvitationLinkState.status === 'loading'" class="loading-container">
          <i class="fas fa-spinner-third fa-spin" />
          <p>{{t('project.manage.members.inviteLink.loading')}}</p>
        </div> -->
        <div>
          <Button
            :onClick="fetchInvitationLink"
            isFullWidth
            :isSaving="fetchInvitationLinkState.status === 'loading'"
            v-if="fetchInvitationLinkState.status === 'idle' || fetchInvitationLinkState.status === 'loading'">
            <template v-slot:icon-prefix>
              <i class="fa fa-link-simple" />
            </template>
            {{ t('project.manage.members.inviteLink.button') }}
          </Button>
          <div v-if="fetchInvitationLinkState.status === 'success'">
            <InputCopy element-id="new-link" v-model="invitationLink" />
          </div>
          <div class="notes-container" v-if="fetchInvitationLinkState.status !== 'error'">
            <p><strong>{{ t('common:notes') }}</strong></p>
            <ul>
              <li>{{ t('project.manage.members.inviteLink.notes1', { email: member.account.email }) }}</li>
              <li>{{ t('project.manage.members.inviteLink.notes2') }}</li>
            </ul>
          </div>
        </div>
        <ErrorMessage v-if="fetchInvitationLinkState.status === 'error'" icon-class-name="fas fa-exclamation-triangle"
                      :title="t('response:generalError.title')" :description="t('response:retry')"
                      :status-code="fetchInvitationLinkState.error?.statusCode" />
      </template>
    </Modal>
  </div>
</template>

<style scoped lang="scss">
@import '~@/assets/scss/global-variables';

.notes-container {
  margin-top: $spacing-base;

  ul {
    margin-top: $spacing-8;
    padding-left: $spacing-base;
  }
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column wrap;

  .fa-spinner-third {
    font-size: 48px;
    margin-bottom: $spacing-base;
  }
}
</style>@/modules/shared/utils/stateManagement
