import type { RouteRecordRaw } from 'vue-router';
import store from '@/store';
import { user } from '@/modules/user';
import personalAccessToken from '../store';

export function createPersonalAccessTokenRoutes(): RouteRecordRaw[] {
  return [
    {
      path: 'me',
      name: 'me',
      redirect: { name: 'personal-access-token' },
      component: () => import(/* webpackChunkName: "manageAccountChrome" */ '../layouts/ManageAccountChrome.vue'),
      beforeEnter: (to, from, next) => {
        if (!store.hasModule('user')) {
          store.registerModule('user', user);
        }
        if (!store.hasModule('personalAccessToken')) {
          store.registerModule('personalAccessToken', personalAccessToken);
        }
        next();
      },
      children: [
        // {
        //   name: 'profile',
        //   path: 'profile',
        //   component: () => import(/* webpackChunkName: "manageAccountProfile" */ '@/modules/manageAccount/ManageAccountProfile.vue'),
        // },
        {
          name: 'personal-access-token',
          path: 'personal-access-token',
          component: () => import(/* webpackChunkName: "managePersonalAccessToken" */ '../pages/ManagePersonalAccessToken.vue'),
        },
      ],
    },
  ];
}
