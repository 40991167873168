/* eslint no-shadow: ["error", { "allow": ["state"] }] */
import type { ProjectState } from '@/modules/shared/types/project.type';
import type { ActionContext } from 'vuex';
import _ from 'lodash';
import {
  deleteVideoCaption, uploadNewVideoCaption,
} from '@/modules/videoCaption/services';
import i18n from '@/plugins/lang/i18n';
import type { UploadNewCaptionPayload } from 'src/modules/videoCaption';
import {
  getVideoProcessingJobs,
  deleteCustomImageEntryById,
  updateCustomFields, deleteVideoByKey, editVideos, loadVideoByKey,
} from '@/modules/videoDetail/services';

import {
  isVideoProject,
  isVideoProbeErrorFile,
  isVideoProbeError,
  isVideoProbeNotComplete,
} from '@/modules/videoDetail/utils/videoManagement';
import {
  setStateSavingStatusByType,
  setStateLoadingStatusByType,
} from '@/modules/shared/utils/stateManagement';

import { formatErrorObject } from '@/modules/shared/utils/errorFormatter';
import type { RootState } from '@/store/type';
import type {
  Video, VideoCaption, VideoState, VideoTranscode,
} from '@/modules/shared/types/video.type';
import type { VideoCustomImageField } from '@/modules/customImageField';
import type { VideoCustomField } from '@/modules/customField';
import type { LoadingState, SavingState } from '@/types/State.type';

type VideoActionContext = ActionContext<VideoState, RootState & { project?: ProjectState }>;

const { t } = i18n.global;

const getErrorMessageFromErrorStatus = (errorStatus: number) => {
  if (errorStatus === 500) {
    return { serverError: `${t('response:error500.title')} code : ${errorStatus}` };
  }
  if (errorStatus === 422) {
    return { serverError: t('response:error422.title') };
  }
  if (errorStatus === 404) {
    return { serverError: t('response:error404.title') };
  }
  if (errorStatus === 403) {
    return { serverError: t('response:error404.title') };
  }

  return { serverError: t('response:errorUnknown.title') };
};

const initialState = (): VideoState => ({
  loadCurrentVideoState: setStateLoadingStatusByType(),
  saveCurrentVideoState: setStateSavingStatusByType(),
  probeCurrentVideoError: {},
  currentVideo: null,
  transcodeResult: null,
  refreshTimer: null,
  refreshTimerSecond: 50000,
  showDeleteVideoModal: false,
  isUploadingNewVideoCaption: false,
  customFields: [],
  customImages: {},
});

const state = initialState();

const getters = {
  currentVideo: (state: VideoState) => state.currentVideo,
  videoUploadData: (state: VideoState, rootGetters: any) => {
    const video = rootGetters.getVideoFromVideosUpload(state.currentVideo);
    if (isVideoProbeNotComplete(video)) {
      return video;
    }
    return state.currentVideo;
  },
};

const mutations = {
  setCurrentVideo(state: VideoState, currentVideo: Video) {
    state.currentVideo = { ...state.currentVideo, ...currentVideo };
    if (state.saveCurrentVideoState.error) {
      if (!state.currentVideo.title) {
        state.saveCurrentVideoState.error = {
          ...state.saveCurrentVideoState.error,
          statusCode: 401,
          title: 'กรุณาระบุชื่อวิดีโอ',
        };
      } else {
        state.saveCurrentVideoState.error = {
          ...state.saveCurrentVideoState.error,
          title: '',
        };
      }
    }
  },
  setCustomImages(state: VideoState, data: VideoCustomImageField) {
    state.customImages = data;
  },
  setCustomFields(state: VideoState, data: VideoCustomField[]) {
    state.customFields = data;
  },
  setTranscodeResult(state: VideoState, transcodeResult: VideoTranscode[]) {
    state.transcodeResult = transcodeResult;
  },
  destroyCurrentVideo(state: VideoState) {
    Object.assign(state, initialState());
  },
  setLoadCurrentVideoStateMessage(state: VideoState, loadingState: LoadingState) {
    state.loadCurrentVideoState = setStateLoadingStatusByType(loadingState);
  },
  setSaveCurrentVideoStateMessage(state: VideoState, savingState: SavingState) {
    state.saveCurrentVideoState = setStateSavingStatusByType(savingState);
  },
  setProbeCurrentVideoError(state: VideoState, message: { errorStatus: string, errorCode: number }) {
    switch (message.errorStatus) {
      case 'probeError':
        state.probeCurrentVideoError.statusCode = message.errorCode;
        state.probeCurrentVideoError.iconClassName = 'fas fa-circle-exclamation';
        state.probeCurrentVideoError.title = t('video.upload.checkingFileError.title');
        state.probeCurrentVideoError.description = `${t('response:error404.helpText')}
          <a href="mailto:support@byteark.com" target="_top">support@byteark.com</a>`;
        break;
      case 'probeFileError':
        state.probeCurrentVideoError.statusCode = message.errorCode;
        state.probeCurrentVideoError.iconClassName = 'fas fa-triangle-exclamation';
        state.probeCurrentVideoError.title = t('video.upload.uploadedFileError.title');
        state.probeCurrentVideoError.description = t('video.upload.uploadedFileError.helpText');
        break;
      default:
        state.probeCurrentVideoError.statusCode = message.errorCode;
        state.probeCurrentVideoError.iconClassName = 'fas fa-circle-exclamation';
        state.probeCurrentVideoError.title = t('response:loadingFailed.title');
        state.probeCurrentVideoError.description = `${t('response:error404.helpText')}
          <a href="mailto:support@byteark.com" target="_top">support@byteark.com</a>`;
        state.probeCurrentVideoError.buttonTitle = t('response:backToHomepage');
        state.probeCurrentVideoError.buttonUrl = '/';
        break;
    }
  },
  toggleShowDeleteVideoModal(state: VideoState) {
    state.showDeleteVideoModal = !state.showDeleteVideoModal;
  },
  startUploadingVideoCaption(state: VideoState) {
    state.isUploadingNewVideoCaption = true;
  },
  finishUploadingVideoCaption(state: VideoState, { newCaption }: { newCaption: VideoCaption}) {
    state.isUploadingNewVideoCaption = false;
    if (newCaption && state.currentVideo) {
      state.currentVideo.vod.captions.push(newCaption);
    }
  },
  removeCaptionByKey(state: VideoState, { captionKey }: { captionKey: string}) {
    if (state.currentVideo) {
      state.currentVideo.vod.captions = state.currentVideo.vod.captions.filter(
        (caption) => caption.key !== captionKey,
      );
    }
  },
};

const actions = {
  async loadVideo({ commit, dispatch, rootState }: VideoActionContext, { videoKey, query = {}, silent = false }: { videoKey: string, query: { q?: string}, silent?: boolean }) {
    if (!silent) {
      commit('destroyCurrentVideo');
      commit('setLoadCurrentVideoStateMessage', { type: 'loading' });
    }
    try {
      const response = await loadVideoByKey(videoKey, query);
      const { data } = response;
      // Check if it's video of currentProject
      if (data && isVideoProject(data, rootState.project?.currentProject)) {
        commit('setCurrentVideo', data);
        commit('setCustomImages', data.simpleCustomImageFields);
        const probeErrorType = _.get(data, 'vod.source.probeErrorType', null);
        // set probeError message
        if (isVideoProbeError(data)) {
          const isProbeFileError = isVideoProbeErrorFile(data);
          if (isProbeFileError) {
            commit('setProbeCurrentVideoError', { errorStatus: 'probeFileError', errorCode: probeErrorType });
          } else {
            commit('setProbeCurrentVideoError', { errorStatus: 'probeError', errorCode: probeErrorType });
          }
        }
        await dispatch('loadTranscodeResult', videoKey);
        commit('setLoadCurrentVideoStateMessage', { type: 'success' });
      } else {
        commit('setLoadCurrentVideoStateMessage', {
          type: 'error',
          error: { statusCode: 404, message: t('response:error404.title', { item: 'Video' }) },
        });
      }
    } catch (error) {
      commit('setLoadCurrentVideoStateMessage', { type: 'error', error: formatErrorObject(error, 'video') });
    }
  },
  async loadTranscodeResult({ commit }: VideoActionContext, videoKey: string) {
    const response = await getVideoProcessingJobs(videoKey);
    commit('setTranscodeResult', response.data);
  },
  async updateVideo({ commit }: VideoActionContext, { videoKey, currentVideo, customFields = null }: { videoKey: string, currentVideo: Video, customFields: VideoCustomField[] | null}) {
    try {
      commit('setSaveCurrentVideoStateMessage', { type: 'saving' });
      const response = await editVideos(videoKey, currentVideo);
      if (customFields && !_.isEmpty(customFields)) {
        await updateCustomFields(videoKey, { customFields });
      }
      commit('setCurrentVideo', response.data);
      commit('setSaveCurrentVideoStateMessage', { type: 'success' });
    } catch (error: any) {
      const status = _.get(error, 'response.status', null);
      commit('setSaveCurrentVideoStateMessage', { type: 'error', error: { status, ...error.response.data, ...getErrorMessageFromErrorStatus(status) } });
    }
  },
  async deleteVideo({ commit }: VideoActionContext, videoKey: string) {
    try {
      commit('setSaveCurrentVideoStateMessage', { type: 'saving' });
      await deleteVideoByKey(videoKey);
      commit('setSaveCurrentVideoStateMessage', { type: 'success' });
      commit('destroyCurrentVideo');
    } catch (error: any) {
      const status = _.get(error, 'response.status', null);
      commit('setSaveCurrentVideoStateMessage', { type: 'error', error: { status, ...error.response.data, ...getErrorMessageFromErrorStatus(status) } });
    }
  },
  destroyCurrentVideo({ commit }: VideoActionContext) {
    commit('destroyCurrentVideo');
  },
  setCustomFields({ commit }: VideoActionContext, data: VideoCustomField[]) {
    commit('setCustomFields', data);
  },
  setCustomImages({ commit }: VideoActionContext, data: VideoCustomImageField) {
    commit('setCustomImages', data);
  },
  setCurrentVideo({ commit }: VideoActionContext, currentVideo: Video) {
    commit('setCurrentVideo', currentVideo);
  },
  toggleShowDeleteVideoModal({ commit }: VideoActionContext) {
    commit('toggleShowDeleteVideoModal');
  },
  async uploadNewCaptionForCurrentVideo({ commit, state }: VideoActionContext, { languageCode, file }: Omit<UploadNewCaptionPayload, 'customName'>) {
    const { currentVideo } = state;
    const requestPayload: UploadNewCaptionPayload = {
      languageCode,
      file,
      customName: languageCode === 'th' ? 'Thai (ไทย)' : 'English',
    };
    commit('startUploadingVideoCaption');
    try {
      if (currentVideo) {
        const response = await uploadNewVideoCaption(
          currentVideo.key,
          currentVideo.vod.revisionKey,
          requestPayload,
        );
        commit('finishUploadingVideoCaption', { newCaption: response.data });
      }
    } catch (err) {
      commit('finishUploadingVideoCaption');
    }
  },
  async deleteCaptionForCurrentVideo({ commit, state }: VideoActionContext, { captionKey }: { captionKey: string}) {
    const { currentVideo } = state;
    if (currentVideo) {
      await deleteVideoCaption(
        currentVideo.key,
        currentVideo.vod.revisionKey,
        captionKey,
      );
      commit('removeCaptionByKey', { captionKey });
    }
  },
  async deleteVideoCustomImageById({ state, commit }: VideoActionContext, { videoKey, customImageFieldId, customImageFieldName }: { videoKey: string, customImageFieldId: string, customImageFieldName: string}) {
    await deleteCustomImageEntryById(videoKey, customImageFieldId);
    const customImages: any = _.cloneDeep(state.customImages);
    customImages[customImageFieldName] = undefined;
    commit('setCustomImages', customImages);
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
