import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "management-page" }
const _hoisted_2 = {
  key: 0,
  class: "navigate"
}
const _hoisted_3 = {
  key: 0,
  class: "btn-current-path"
}
const _hoisted_4 = {
  class: "header-container",
  id: "header-container"
}
const _hoisted_5 = {
  key: 0,
  id: "management-page-header",
  class: "header"
}
const _hoisted_6 = {
  key: 0,
  class: "title-container"
}
const _hoisted_7 = {
  key: 0,
  class: "title"
}
const _hoisted_8 = {
  key: 2,
  class: "action"
}

import {
  onBeforeUnmount, onMounted, ref, toRefs,
} from 'vue';
import { useRouter } from 'vue-router';
import Button from '@/modules/shared/components/atoms/button/Button.vue';

interface ManagementPageProps {
  iconClassButtonBack?: string;
  title?: string | null;
  backButtonAction?: string | (() => void);
  backButtonLabel?: string;
  currentPathLabel?: string;
  primaryButtonLabel?: string;
  primaryButtonAction?: string | (() => (Promise<void> | void));
  /** @default 'primary' */
  primaryButtonClasses?: string;
  /** @default true */
  isShowHeader?: boolean;
  /** @default false */
  isShowInLineEdit?: boolean;
  /** @default false */
  isEnableStickyHeader?: boolean;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'ManagementPage',
  props: {
    iconClassButtonBack: {},
    title: {},
    backButtonAction: {},
    backButtonLabel: {},
    currentPathLabel: {},
    primaryButtonLabel: {},
    primaryButtonAction: {},
    primaryButtonClasses: { default: 'primary' },
    isShowHeader: { type: Boolean, default: true },
    isShowInLineEdit: { type: Boolean, default: false },
    isEnableStickyHeader: { type: Boolean, default: false }
  },
  setup(__props: any) {

const props = __props;

const {
  iconClassButtonBack,
  title,
  backButtonAction,
  backButtonLabel,
  currentPathLabel,
  primaryButtonLabel,
  primaryButtonAction,
  primaryButtonClasses,
  isShowHeader,
  isShowInLineEdit,
  isEnableStickyHeader,
} = toRefs(props);

const router = useRouter();

const isSaving = ref<boolean>(false);

function onClickBack(): void | null {
  if (typeof backButtonAction.value === 'function') {
    backButtonAction.value();
  } else if (typeof backButtonAction.value === 'string') {
    router.push(backButtonAction.value);
  }
  return null;
}

async function onClickButton(): Promise<void | null> {
  if (typeof primaryButtonAction.value === 'function') {
    isSaving.value = true;
    await primaryButtonAction.value();
    isSaving.value = false;
  } else if (typeof primaryButtonAction.value === 'string') {
    await router.push(primaryButtonAction.value);
  }

  return null;
}

function handleScrollPage(): void {
  const headerContainer = document.getElementById('header-container');
  const header = document.getElementById('management-page-header');
  const managementContent = document.getElementById('management-content');

  if (header && headerContainer && managementContent) {
    const rect = headerContainer.getBoundingClientRect();
    const { top } = rect;

    if (top <= 0) {
      header.classList.add('sticky-header');
      header.style.width = `${managementContent.clientWidth}px`;
    } else {
      header.classList.remove('sticky-header');
      header.style.width = 'auto';
    }
  }
}

onMounted(() => {
  if (isEnableStickyHeader.value) {
    window.addEventListener('scroll', handleScrollPage);
  }
});

onBeforeUnmount(() => {
  if (isEnableStickyHeader.value) {
    window.removeEventListener('scroll', handleScrollPage);
  }
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_unref(backButtonLabel) && onClickBack !== null)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(Button, {
            type: "button",
            buttonStyle: "link-secondary",
            buttonClasses: "btn-back",
            onClick: onClickBack
          }, {
            "icon-prefix": _withCtx(() => [
              (_unref(iconClassButtonBack))
                ? (_openBlock(), _createElementBlock("i", {
                    key: 0,
                    class: _normalizeClass(_unref(iconClassButtonBack))
                  }, null, 2))
                : _createCommentVNode("", true)
            ]),
            default: _withCtx(() => [
              _createTextVNode(" " + _toDisplayString(_unref(backButtonLabel)), 1)
            ]),
            _: 1
          }),
          (_unref(currentPathLabel))
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_unref(currentPathLabel)), 1))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_4, [
      (_unref(isShowHeader))
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            (!_unref(isShowInLineEdit))
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                  (_unref(title))
                    ? (_openBlock(), _createElementBlock("h3", _hoisted_7, _toDisplayString(_unref(title)), 1))
                    : _createCommentVNode("", true),
                  _renderSlot(_ctx.$slots, "edit-button")
                ]))
              : _createCommentVNode("", true),
            (_unref(isShowInLineEdit))
              ? _renderSlot(_ctx.$slots, "inline-edit", { key: 1 })
              : _createCommentVNode("", true),
            (_unref(primaryButtonLabel) && onClickButton !== null)
              ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                  _renderSlot(_ctx.$slots, "status-label", { class: "status-label mr-2" }),
                  _createVNode(Button, {
                    type: "button",
                    buttonClasses: _unref(primaryButtonClasses),
                    onClick: onClickButton,
                    isSaving: isSaving.value
                  }, {
                    content: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_unref(primaryButtonLabel)), 1)
                    ]),
                    _: 1
                  }, 8, ["buttonClasses", "isSaving"])
                ]))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true),
      _renderSlot(_ctx.$slots, "after-header-content")
    ]),
    _renderSlot(_ctx.$slots, "default")
  ]))
}
}

})