<template>
  <div class="checkbox-wrapper" @click.prevent="onUpdateValue">
    <div
      class="checkbox-ui-container"
      :class="{ checked: modelValue, disabled: disabled, 'indeterminate': isIndeterminate }"
      @click.stop="onUpdateValue"
    >
      <input type="checkbox" class="input" :disabled="disabled" :checked="modelValue" />
      <i v-if="isIndeterminate" class="fa fa-solid fa-minus dash"></i>
      <i v-else class="fa fa-solid fa-check checkmark"></i>
    </div>
    <div class="text-container" v-if="label || description">
      <div class="title" v-if="!!label">{{ label }}</div>
      <div class="description" v-if="!!description">{{ description }}</div>
      <slot name="subField" v-if="modelValue"></slot>
    </div>
  </div>
</template>
<script>
export default {
  emits: ['update:modelValue', 'update:indeterminate'],
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    isIndeterminate: {
      type: Boolean,
      default: false,
    },
    disabled: {
      default: false,
      type: Boolean,
    },
    label: {
      type: String,
    },
    description: {
      type: String,
    },
    onChange: {
      type: Function,
      default: () => {},
    },
  },
  methods: {
    onUpdateValue() {
      if (this.disabled) {
        return;
      }

      // if (this.isIndeterminate) this.$emit('update:indeterminate', !this.modelValue);
      // else this.$emit('update:modelValue', !this.modelValue);
      this.$emit('update:modelValue', !this.modelValue);
      this.onChange(!this.modelValue);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/global-variables.scss";
.checkbox-wrapper {
  display: flex;
  column-gap: $spacing-base;
}

.checkbox-ui-container {
  border: 2px solid $grey-300;
  border-radius: 2px;
  background: $white;
  width: 16px;
  height: 16px;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  margin-top: 1px;
  &.checked {
    border-color: transparent;
    background-color: $ci-primary;
    .checkmark {
      display: block;
    }
    .dash {
      display: block;
    }
    &.indeterminate {
      background-color: $grey-600;
    }
  }
  &.disabled {
    background: $grey-300;
    pointer-events: none;
    .checkmark {
      color: $grey-400;
    }
    .dash {
      color: $grey-400;
    }
  }
}

/* Hide the browser's default checkbox */
.input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* padding: 2px; */
  padding: 1px;
  display: none;
  /* font-size: 8px; */
  font-size: 10px;
  color: $grey-200;
}

.dash {
  position: absolute;
  top: -0.5px;
  left: 0.5px;
  right: 0;
  bottom: 0;
  padding: 2px;
  display: none;
  font-size: 8px;
  color: $grey-200;
}

.text-container {
  font-weight: $font-weight-base;
  font-size: $font-level-7;
  display: flex;
  flex: 1;
  flex-direction: column;
  row-gap: $spacing-4;

  .title {
    cursor: pointer;
    color: $grey-800;
    font-weight: $font-weight-bold;
    line-height: $line-height-base;
    // text-transform: capitalize;
    margin-top: 2px;
  }
  .description {
    color: $grey-600;
    line-height: $line-height-description;
  }
}
</style>
