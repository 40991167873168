import _ from 'lodash';
import i18n from '@/plugins/lang/i18n';

export const mapCountryCodesToObject = (countryCodes, countryList) => (
  _.map(countryCodes, (countryCodeItem) => _.find(countryList, ['countryCode', countryCodeItem]))
);

export const mapCountryObjectToCode = (countryObject) => (
  _.map(countryObject, (countryCodeItem) => countryCodeItem.countryCode)
);

export const isGeoblockCustomRule = (geoblockPolicy) => geoblockPolicy.type === 'customRule';

export const getCountriesName = (countryCodes, countryList) => {
  if (countryCodes && countryCodes[0] === 'XX') {
    return null;
  }

  const countryListArray = mapCountryCodesToObject(countryCodes, countryList);
  const countryListEN = _.map(countryListArray, 'countryNameEN');
  return countryListEN.join(', ');
};

export const getGeoblockPolicyCountryRule = (geoblockPolicy) => geoblockPolicy.appliedRule;

export const getGeoblockPolicyRuleName = (geoblockPolicy, currentGeoblockRule) => {
  if (geoblockPolicy.type === 'existingRule') {
    return currentGeoblockRule.name;
  }
  return i18n.global.t('video.geoBlock.allowAll');
};

export const getVideoPolicyFromDefaultProject = (project) => {
  if (project && project.geoblockRule) {
    return {
      type: 'existingRule',
      ruleId: project.geoblockRuleId,
    };
  }

  return {
    type: 'none',
    ruleId: null,
  };
};

export const getGeoblockRuleInformation = ({ geoblockRule, geoblockPolicy }) => {
  const allowCountryCodes = _.get(geoblockPolicy, 'appliedRule.allowCountryCodes', []);
  const denyCountryCodes = _.get(geoblockPolicy, 'appliedRule.denyCountryCodes', []);

  if (!geoblockPolicy) {
    return null;
  }

  if (geoblockPolicy.type === 'existingRule') {
    return geoblockRule;
  }

  return {
    name: i18n.global.t('video.geoBlock.customGeoBlocking'),
    allowCountryCodes,
    denyCountryCodes,
  };
};
