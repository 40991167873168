import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "text"
}
const _hoisted_2 = {
  key: 1,
  class: "sort-icon-container"
}
const _hoisted_3 = {
  key: 0,
  class: "fad fa-sort-up sort-icon"
}
const _hoisted_4 = {
  key: 1,
  class: "fad fa-sort-down"
}
const _hoisted_5 = {
  key: 2,
  class: "fas fa-sort sort-icon"
}


export default /*@__PURE__*/_defineComponent({
  __name: 'HeaderCell',
  props: {
  text: {
    type: String,
  },
  isShowSortButton: {
    type: Boolean,
  },
  sortDirection: {
    type: String,
    default: 'default',
  },
},
  setup(__props) {

// eslint-disable-next-line no-undef


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["header-cell cell", { 'is-enable-sort' : __props.isShowSortButton }])
  }, [
    (__props.text)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(__props.text), 1))
      : _createCommentVNode("", true),
    (__props.isShowSortButton)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (__props.sortDirection === 'desc')
            ? (_openBlock(), _createElementBlock("i", _hoisted_3))
            : (__props.sortDirection === 'asc')
              ? (_openBlock(), _createElementBlock("i", _hoisted_4))
              : (_openBlock(), _createElementBlock("i", _hoisted_5))
        ]))
      : _createCommentVNode("", true)
  ], 2))
}
}

})