import type { RouteRecordRaw } from 'vue-router';
import store from '@/store';
import { video } from '@/modules/videoDetail';
import customField from '../store';

export function createCustomFieldRoutes(): RouteRecordRaw[] {
  return [
    {
      path: 'custom-fields',
      component: () => import(/* webpackChunkName: "manageCustomFieldsChrome" */ '@/modules/customField/layouts/ManageCustomFieldsChrome.vue'),
      meta: { teamManagement: true },
      beforeEnter: (to, from, next) => {
        if (!store.hasModule('customField')) {
          store.registerModule('customField', customField);
        }
        if (!store.hasModule('video')) {
          store.registerModule('video', video);
        }
        next();
      },
      children: [
        {
          path: '',
          name: 'customFields',
          component: () => import(/* webpackChunkName: "manageCustomFields" */ '@/modules/customField/pages/ManageCustomFields.vue'),
        },
        {
          path: 'create',
          name: 'customFieldsCreate',
          components: {
            default: () => import(/* webpackChunkName: "manageCustomFields" */ '@/modules/customField/pages/ManageCustomFields.vue'),
            createCustomFields: () => import(/* webpackChunkName: "createCustomFields" */ '@/modules/customField/components/organisms/manageCustomFieldModal/ManageCustomFieldModal.vue'),
          },
        },
        {
          path: ':customFieldId',
          name: 'customFieldsEdit',
          components: {
            default: () => import(/* webpackChunkName: "manageCustomFields" */ '@/modules/customField/pages/ManageCustomFields.vue'),
            createCustomFields: () => import(/* webpackChunkName: "editCustomField" */ '@/modules/customField/components/organisms/manageCustomFieldModal/ManageCustomFieldModal.vue'),
          },
        },
        {
          path: ':customFieldId/delete',
          name: 'customFieldsDetail',
          components: {
            default: () => import(/* webpackChunkName: "manageCustomFields" */ '@/modules/customField/pages/ManageCustomFields.vue'),
            createCustomFields: () => import(/* webpackChunkName: "deleteCustomField" */ '@/modules/customField/components/organisms/deleteCustomFieldModal/DeleteCustomFieldModal.vue'),
          },
        },
      ],
    },
  ];
}
