<template>
  <div class="countries-container">
    <Badge
      v-for="country in countries"
      :key="country"
      :title="country"
    />
  </div>
</template>

<script>
import Badge from '@/modules/shared/components/atoms/badge/Badge.vue';

export default {
  components: {
    Badge,
  },
  props: {
    countries: {
      type: Array,
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/global-variables.scss';

.countries-container {
  display: flex;
  align-items: flex-start;
  flex-flow: row wrap;
  gap: 8px;

  .country {
    display: flex;
    align-items: center;
    font-size: $font-size-base;
    //padding: 6px 8px 4px;
    padding: 2px 8px;
    font-weight: $font-weight-base;
    color: $grey-800;
    background-color: $grey-200;
    border-radius: $border-radius-5;
  }

}
</style>
