import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import {
  computed, onMounted, ref, toRefs,
} from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';
import { Video } from '@/modules/shared/types/video.type';
import { loadVideosAPI } from '@/modules/projectVideoList/services';
import SelectOption from '@/modules/shared/components/atoms/selectOption/SelectOption.vue';
import { useStore } from '@/store';
import { getQueryAsString } from '@/modules/shared/utils/query';

interface VideoSelectorProps {
  modelValue: Video | null;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'VideoSelector',
  props: {
    modelValue: {}
  },
  emits: ["update:modelValue"],
  setup(__props: any, { emit: __emit }) {

const { t } = useI18n();
const route = useRoute();
const store = useStore();

const props = __props;
const { modelValue } = toRefs(props);

const emit = __emit;

const options = ref<Video[]>([]);

const projectKey = computed(() => getQueryAsString(route.params, 'projectKey'));
const projectVideoList = computed(() => store.state.projectVideoList.projectVideoList);

async function loadVideos() {
  try {
    const response = await loadVideosAPI({ projectKey: projectKey.value, limit: '100' });
    options.value = response.data.data;
    options.value = options.value.map((item) => ({
      ...item,
      subtitle: item.key,
    }));
  } catch (error) {
    console.error(error);
  }
}

function onChanged(value: Video) {
  emit('update:modelValue', value);
}

onMounted(() => {
  if (projectVideoList.value) {
    options.value = projectVideoList.value;
  } else {
    loadVideos();
  }
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(SelectOption, {
    placeholder: _unref(t)('webhook.test.video.placeholder'),
    options: options.value,
    modelValue: _unref(modelValue),
    label: "title",
    "onUpdate:modelValue": onChanged
  }, null, 8, ["placeholder", "options", "modelValue"]))
}
}

})