import qs from 'qs';
import { axiosAPI } from '@/plugins/axios';
import type { Filter } from '@/types/Axios.type';

export const loadPlaybackChannels = (filter?: Filter) => {
  const query = qs.stringify(filter, { addQueryPrefix: true, skipNulls: true });
  return axiosAPI.get(`/playback-channels${query}`);
};

export const loadPlaybackChannelDetail = (playbackChannelId: string) => axiosAPI.get(`/playback-channels/${playbackChannelId}`);
