/* eslint no-shadow: ["error", { "allow": ["state"] }] */
import { loadVideosAPI } from '@/modules/projectVideoList/services';
import { ProjectVideoListState } from '@/modules/projectVideoList/types/projectVideoList.type';
import { formatErrorObject } from '@/modules/shared/utils/errorFormatter';
import {
  setStateLoadingStatusByType,
} from '@/modules/shared/utils/stateManagement';
import { ActionContext } from 'vuex';
import { RootState } from '@/store/type';
import { Video } from '@/modules/shared/types/video.type';
import { LoadingState } from '@/types/State.type';

type ProjectVideoListActionContext = ActionContext<ProjectVideoListState, RootState>;

const initialState = (): ProjectVideoListState => ({
  projectVideoList: null,
  projectVideoTotal: 0,
  projectVideoListLastPage: 0,
  loadProjectVideoListState: setStateLoadingStatusByType(),
  pageScrollPosition: 0,
});

const state = initialState();

const mutations = {
  setProjectVideoList(state: ProjectVideoListState, videos: Video[]) {
    state.projectVideoList = videos;
  },
  setProjectVideoTotal(state: ProjectVideoListState, total: number) {
    state.projectVideoTotal = total;
  },
  appendProjectVideo(state: ProjectVideoListState, newVideo: Video) {
    if (state.projectVideoList) {
      state.projectVideoList = [newVideo, ...state.projectVideoList];
    } else {
      state.projectVideoList = [newVideo];
    }
  },
  setProjectVideoListLastPage(state: ProjectVideoListState, lastPage: number) {
    state.projectVideoListLastPage = lastPage;
  },
  destroyProjectVideoList(state: ProjectVideoListState) {
    Object.assign(state, initialState());
  },
  setLoadProjectVideoListState(state: ProjectVideoListState, loadingState: LoadingState) {
    state.loadProjectVideoListState = setStateLoadingStatusByType(loadingState);
  },
  setPageScrollPosition(state: ProjectVideoListState, position: number) {
    state.pageScrollPosition = position;
  },
};

const actions = {
  async loadProjectVideoList({ commit }: ProjectVideoListActionContext, { filter }: { filter: { q: string } }) {
    commit('setLoadProjectVideoListState', { type: 'loading' });
    try {
      const response = await loadVideosAPI(filter);
      commit('setProjectVideoList', response.data.data);
      commit('setProjectVideoTotal', response.data.total);
      commit('setProjectVideoListLastPage', response.data.lastPage);
      commit('setLoadProjectVideoListState', { type: 'success' });
    } catch (error) {
      commit('setLoadProjectVideoListState', { type: 'error', error: formatErrorObject(error, 'Videos') });
    }
  },
  destroyProjectVideoList({ commit }: ProjectVideoListActionContext) {
    commit('destroyProjectVideoList');
  },
  appendProjectVideo({ commit }: ProjectVideoListActionContext, video: Video) {
    commit('appendProjectVideo', video);
  },
  setPageScrollPosition({ commit }: ProjectVideoListActionContext, position: number) {
    commit('setPageScrollPosition', position);
  },
};

export default {
  state,
  actions,
  mutations,
};
