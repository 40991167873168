import { ActionContext } from 'vuex';
/* eslint-disable no-shadow */
import { formatErrorObject } from '@/modules/shared/utils/errorFormatter';
import { setStateLoadingStatusByType } from '@/modules/shared/utils/stateManagement';
import { CollectionType, CollectionTypeState } from '@/modules/collection';
import { LoadingState } from '@/types/State.type';
import { RootState } from '@/store/type';
import { getCollectionTypeById } from '../services';

type CollectionTypeContext = ActionContext<CollectionTypeState, RootState>

const initialState = (): CollectionTypeState => ({
  currentCollectionType: null,
  loadCurrentCollectionTypeState: setStateLoadingStatusByType(),
});

const state = initialState();

const mutations = {
  setCurrentCollectionType(state: CollectionTypeState, collectionType: CollectionType) {
    state.currentCollectionType = collectionType;
  },
  setLoadCurrentCollectionTypeState(state: CollectionTypeState, loadingState: LoadingState) {
    state.loadCurrentCollectionTypeState = setStateLoadingStatusByType(loadingState);
  },
  destroyCurrentCollectionType(state: CollectionTypeState) {
    Object.assign(state, initialState());
  },
};

const actions = {
  async loadCurrentCollectionType({ commit }: CollectionTypeContext, id: string) {
    try {
      commit('setLoadCurrentCollectionTypeState', { type: 'loading' });
      const response = await getCollectionTypeById(id);
      commit('setCurrentCollectionType', response.data);
      commit('setLoadCurrentCollectionTypeState', { type: 'success' });
    } catch (error) {
      commit('setLoadCurrentCollectionTypeState', { type: 'error', error: formatErrorObject(error, 'Collection Type') });
    }
  },
  destroyCurrentCollectionType({ commit }: CollectionTypeContext) {
    commit('destroyCurrentCollectionType');
  },
  updateCurrentCollectionType({ commit }: CollectionTypeContext, collectionType: CollectionType) {
    commit('setCurrentCollectionType', collectionType);
  },
  async reloadCurrentCollectionType({ state, dispatch }: CollectionTypeContext) {
    const typeId = state.currentCollectionType?.id;
    if (typeId) {
      await dispatch('loadCurrentCollectionType', typeId);
    }
  },
};

export default {
  state,
  mutations,
  actions,
};
