<template>
  <div class="container">
    <i class="fas fa-file-alt icon"></i>
    <div class="filename">{{ fileName }}</div>
    <div class="action">
      <Button buttonStyle="secondary" v-if="props.enableReupload">
        <label>
          {{ $t('video.subtitle.edit.file.change') }}
          <input
            type="file"
            @change="(event) => $emit('update:file', event.target.files[0])"
            :accept="props.accept.join(',')"
            class="file-input"
          />
        </label>
      </Button>
      <Button buttonStyle="secondary" v-if="props.enableDownload">
        <template v-slot:icon-prefix>
          <i class="fa fa-download" @click="$emit('downloadFile')"></i>
        </template>
      </Button>
    </div>
  </div>
</template>

<script setup>
import Button from '@/modules/shared/components/atoms/button/Button.vue';

// eslint-disable-next-line no-undef
const props = defineProps({
  enableReupload: {
    type: Boolean,
    default: () => false,
  },
  enableDownload: {
    type: Boolean,
    default: () => true,
  },
  fileName: {
    type: String,
    default: () => 'unknown file name',
  },
  accept: {
    type: Array,
  },
});

// eslint-disable-next-line no-undef
defineEmits(['update:file', 'downloadFile']);
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/global-variables.scss';

.container {
  background-color: $grey-100;
  border: 1px solid #e8eaee;
  border-radius: 5px;
  padding: $spacing-base;
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 4px;
}

.icon {
  font-size: 18px;
  color: $grey-800;
}
.filename {
  flex: 2;
  text-overflow: ellipsis;
  font-weight: $font-weight-bold;
  color: $grey-800;
  overflow: hidden;
}

.action {
  display:flex;
  justify-content: flex-end;
  gap: $spacing-8;
}

.file-input {
  display: none;
}
</style>
