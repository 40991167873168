import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withModifiers as _withModifiers, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, createSlots as _createSlots } from "vue"

const _hoisted_1 = {
  class: "not-editable-text",
  "data-test": "not-editable-text"
}
const _hoisted_2 = { class: "country-wrapper" }
const _hoisted_3 = {
  key: 0,
  class: "label",
  "data-test": "allow-all-label"
}
const _hoisted_4 = {
  key: 1,
  class: "allow-country-container country-list",
  "data-test": "allow-some-label"
}
const _hoisted_5 = {
  key: 0,
  class: "label"
}
const _hoisted_6 = {
  key: 1,
  class: "label",
  "data-test": "deny-all-label"
}
const _hoisted_7 = {
  key: 2,
  class: "badge-list"
}
const _hoisted_8 = {
  key: 2,
  class: "deny-country-container country-list",
  "data-test": "deny-some-label"
}
const _hoisted_9 = {
  key: 0,
  class: "label"
}
const _hoisted_10 = {
  key: 1,
  class: "badge-list"
}

import _ from 'lodash';
import ItemInformationCard from '@/modules/shared/components/atoms/itemInformationCard/ItemInformationCard.vue';
import Tooltip from '@/modules/shared/components/atoms/tooltip/Tooltip.vue';
import Button from '@/modules/shared/components/atoms/button/Button.vue';
import { computed, onMounted, toRefs } from 'vue';
import { useI18n } from 'vue-i18n';
import Badge from '@/modules/shared/components/atoms/badge/Badge.vue';
import { useStore } from '../../../store/hooks';
import { GeoblockRule } from '../../../types/Geoblock.type';

interface GeoblockRuleCardProps {
  geoblockRule: GeoblockRule | null | undefined;
  onClickEditButton?: () => void;
  onClickDuplicateButton?: (geoblockData: GeoblockRule) => void;
  isShowEditButton?: boolean;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'GeoblockRuleCard',
  props: {
    geoblockRule: {},
    onClickEditButton: { type: Function },
    onClickDuplicateButton: { type: Function },
    isShowEditButton: { type: Boolean }
  },
  setup(__props: any) {

const props = __props;
const {
  geoblockRule,
  onClickEditButton,
  onClickDuplicateButton,
  isShowEditButton,
} = toRefs(props);

const { t } = useI18n();
const store = useStore();

const countryList = computed(() => store.state.geoblockRuleList.countryList);

const isEmptyCountryList = computed(() => store.getters.isEmptyCountryList);

// computed
const allowCountryCodes = computed(() => _.get(geoblockRule.value, 'allowCountryCodes', []));
const denyCountryCodes = computed(() => _.get(geoblockRule.value, 'denyCountryCodes', []));
const hasAllowCountryCode = computed(() => allowCountryCodes.value?.length > 0);
const hasDenyCountryCode = computed(() => denyCountryCodes.value?.length > 0);
const isAllowAllCountries = computed(() => !hasAllowCountryCode.value && !hasDenyCountryCode.value);
const isGeoblockEditable = computed(() => !_.get(geoblockRule.value, 'isGlobalDefault', false));
const geoblockName = computed(() => {
  const ruleId = _.get(geoblockRule.value, 'id', null);
  if (!isAllowAllCountries.value && !ruleId) {
    return t('video.geoBlock.customGeoBlocking');
  }
  return _.get(geoblockRule.value, 'name', t('video.geoBlock.allowAll'));
});

// methods
const loadCountryList = () => {
  if (isEmptyCountryList.value) {
    store.dispatch('loadCountryList');
  }
};

const getCountryFullName = (countryCode: string) => {
  const country = _.find(countryList.value, (eachCountry) => eachCountry.countryCode === countryCode);
  return country ? country.countryNameEN : '';
};

// This method will be remove after we refactor allowCountryCodes conditions
const isDisallowAllCountries = (countryCodes: string[]) => {
  if (countryCodes && countryCodes[0] === 'XX') {
    return true;
  }
  return false;
};

onMounted(() => {
  loadCountryList();
});

return (_ctx: any,_cache: any) => {
  return (!_unref(_).isNil(_unref(geoblockRule)))
    ? (_openBlock(), _createBlock(ItemInformationCard, {
        key: 0,
        title: geoblockName.value
      }, _createSlots({
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, [
            (isAllowAllCountries.value)
              ? (_openBlock(), _createElementBlock("span", _hoisted_3, [
                  _cache[4] || (_cache[4] = _createElementVNode("i", { class: "fas fa-circle-check allow-icon" }, null, -1)),
                  _createTextVNode(_toDisplayString(_unref(t)('video.geoBlock.allowAll')), 1)
                ]))
              : _createCommentVNode("", true),
            (hasAllowCountryCode.value)
              ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                  (!isDisallowAllCountries(allowCountryCodes.value))
                    ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                        _cache[5] || (_cache[5] = _createElementVNode("i", { class: "fas fa-circle-check allow-icon" }, null, -1)),
                        _createTextVNode("Allowed (" + _toDisplayString(allowCountryCodes.value.length) + ") ", 1)
                      ]))
                    : (_openBlock(), _createElementBlock("div", _hoisted_6, _cache[6] || (_cache[6] = [
                        _createElementVNode("i", { class: "fas fa-ban deny-icon" }, null, -1),
                        _createTextVNode("All countries are blocked")
                      ]))),
                  (!isDisallowAllCountries(allowCountryCodes.value))
                    ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(allowCountryCodes.value, (country) => {
                          return (_openBlock(), _createBlock(Badge, {
                            title: getCountryFullName(country),
                            key: country
                          }, null, 8, ["title"]))
                        }), 128))
                      ]))
                    : _createCommentVNode("", true)
                ]))
              : _createCommentVNode("", true),
            (hasDenyCountryCode.value)
              ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                  (!isDisallowAllCountries(allowCountryCodes.value))
                    ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                        _cache[7] || (_cache[7] = _createElementVNode("i", { class: "fas fa-ban deny-icon" }, null, -1)),
                        _createTextVNode("Blocked (" + _toDisplayString(denyCountryCodes.value.length) + ") ", 1)
                      ]))
                    : _createCommentVNode("", true),
                  (!isDisallowAllCountries(allowCountryCodes.value))
                    ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(denyCountryCodes.value, (country) => {
                          return (_openBlock(), _createBlock(Badge, {
                            key: country,
                            title: getCountryFullName(country)
                          }, null, 8, ["title"]))
                        }), 128))
                      ]))
                    : _createCommentVNode("", true)
                ]))
              : _createCommentVNode("", true)
          ])
        ]),
        _: 2
      }, [
        (!isGeoblockEditable.value)
          ? {
              name: "subtitle",
              fn: _withCtx(() => [
                _createElementVNode("span", _hoisted_1, [
                  _cache[1] || (_cache[1] = _createElementVNode("i", { class: "fas fa-circle-info" }, null, -1)),
                  _createTextVNode(" " + _toDisplayString(_unref(t)('common:notEditable')), 1)
                ])
              ]),
              key: "0"
            }
          : undefined,
        (_unref(isShowEditButton))
          ? {
              name: "card-actions-stick-top",
              fn: _withCtx(() => [
                _createVNode(Tooltip, null, {
                  "tooltip-text": _withCtx(() => [
                    _createTextVNode(_toDisplayString(_unref(t)('common:button.duplicate')), 1)
                  ]),
                  default: _withCtx(() => [
                    _createVNode(Button, {
                      buttonStyle: "secondary",
                      type: "button",
                      "button-classes": "btn-duplicate",
                      onClick: _cache[0] || (_cache[0] = _withModifiers(() => {
            if (typeof _unref(onClickDuplicateButton) === 'function' && !_unref(_).isNil(_unref(geoblockRule))) {
              _unref(onClickDuplicateButton)(_unref(geoblockRule))
            }
          }, ["prevent"]))
                    }, {
                      "icon-prefix": _withCtx(() => _cache[2] || (_cache[2] = [
                        _createElementVNode("i", { class: "fas fa-clone" }, null, -1)
                      ])),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(Tooltip, null, {
                  "tooltip-text": _withCtx(() => [
                    _createTextVNode(_toDisplayString(_unref(t)('common:button.edit')), 1)
                  ]),
                  default: _withCtx(() => [
                    (isGeoblockEditable.value && !isDisallowAllCountries(allowCountryCodes.value))
                      ? (_openBlock(), _createBlock(Button, {
                          key: 0,
                          buttonStyle: "secondary",
                          buttonClasses: "btn-edit",
                          type: "button",
                          onClick: _unref(onClickEditButton)
                        }, {
                          "icon-prefix": _withCtx(() => _cache[3] || (_cache[3] = [
                            _createElementVNode("i", { class: "fas fa-pen" }, null, -1)
                          ])),
                          _: 1
                        }, 8, ["onClick"]))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                })
              ]),
              key: "1"
            }
          : undefined
      ]), 1032, ["title"]))
    : _createCommentVNode("", true)
}
}

})