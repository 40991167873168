<template>
  <Page>
    <div class="page-body-notfound">
      <div class="container">
        <ErrorMessage
          iconClassName="fas fa-circle-exclamation"
          :title="$t('response:error404.title', { item: $t('glossary:page') })"
          :description="$t('response:error404.helpText')"
          statusCode="404"
          :buttonUrl="`/${teamName}`"
          :buttonTitle="$t('response:backToHomepage')" />
      </div>
    </div>
  </Page>
</template>

<script>
import Page from '@/layouts/Page.vue';
import ErrorMessage from '@/modules/shared/components/atoms/errorMessage/ErrorMessage.vue';

export default {
  components: {
    Page,
    ErrorMessage,
  },
  computed: {
    teamName() {
      return this.$route.params.teamName;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/global-variables.scss';

:deep(.action-button) {
  margin-top: $spacing-base * 1.5;
}

.page-body-notfound {
  padding-top: 53px;
}
</style>
