<template>
  <div class="item-information-card">
    <div
      :class="{'with-subtitle': $slots['subtitle']}"
      class="header-container" v-if="title">
      <div class="title-container">
        <h5 class="title" data-test="item-information-card-title">{{title}}</h5>
        <div class="badge-container" v-if="$slots['badge']">
          <slot name="badge"></slot>
        </div>
        <div class="subtitle" v-if="$slots['subtitle']">
          <slot name="subtitle"></slot>
        </div>
      </div>
      <div class="card-actions-container" v-if="$slots['card-actions']">
        <slot name="card-actions"></slot>
      </div>
      <div class="card-actions-stick-top-container " v-if="$slots['card-actions-stick-top']">
        <slot name="card-actions-stick-top"></slot>
      </div>
    </div>
    <div class="card-body" v-if="!$slots['card-body-no-padding']">
      <slot></slot>
    </div>
    <div class="card-body-no-padding" v-if="$slots['card-body-no-padding']">
      <slot class="card-body-no-padding"></slot>
    </div>
    <div class="card-actions-container-mobile" v-if="$slots['card-actions']">
      <slot name="card-actions"></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
      required: false,
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/global-variables.scss';
@import '~@/assets/scss/breakpoint.scss';

.title {
  font-weight: $font-weight-base;
  color: $grey-800;
}

.item-information-card {
  background-color: $grey-100;
  border-radius: $border-radius-5;
}

.header-container {
  padding: $spacing-base * 1.5 $spacing-base * 1.5 0;
  display: flex;
  justify-content: space-between;

  .title-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    .title {
      margin-bottom: 0;
    }
    .badge-container {
      margin-left: $spacing-base;
    }
  }

  .card-actions-stick-top-container {
    display: flex;
    column-gap: $spacing-base;
  }
}

.card-body  {
  padding: $spacing-base * 1.5;
  ::v-slotted(.label) {
    color: $grey-600;
  }
}

.card-actions-container-mobile {
  display: none;
}

@media screen and (max-width: $max-layout-sm) {
  .card-actions-container-mobile {
    display: flex;
    justify-content: flex-end;
    padding: $spacing-base * 1.5;
  }

  .card-actions-container {
    display: none;
  }
}
</style>
