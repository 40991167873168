<template>
  <div
    class="badge"
    :class="[{'dark': theme === 'dark'}, badgeStyle, size]"
    :data-test="`${dataTest ? dataTest : `${title}-badge`}`"
  >
    <div class="icon-prefix-container" v-if="$slots['icon-prefix']">
      <slot name="icon-prefix" />
    </div>
    <slot name="before-title" />
    <span :class="['title', $slots['before-title'] ? 'with-margin' : '']">
      {{title}}
    </span>
    <div class="icon-suffix-container" v-if="$slots['icon-suffix']">
      <slot name="icon-suffix" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { toRefs } from 'vue';
import { BadgeProps } from '@/types/Badge.type';

const props = withDefaults(defineProps<BadgeProps>(), {
  theme: 'white',
  size: 'small',
  badgeStyle: 'secondary',
});

const {
  title,
  theme,
  size,
  badgeStyle,
  dataTest,
} = toRefs(props);
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/global-variables.scss';

.badge {
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  height: fit-content;
  font-weight: $font-weight-base;
  color: $grey-700;

  :slotted(strong) {
    font-weight: 500;
  }

  &.dark {
    color: $grey-100;
  }

  &.medium {
    font-size: $font-size-base;
    border-radius: $border-radius-5;
    padding: $spacing-4 $spacing-8;
    .icon-prefix-container{
      height: $font-size-base;
    }
  }

  &.small {
    font-size: $font-level-8;
    border-radius: $border-radius-3;
    padding: 0 $spacing-8;

    .icon-prefix-container {
      font-size: 8px;
      height: 10px;
    }
  }
}

.danger {
  color: $danger;
  background: $danger-pale;

  &.dark {
    background: $danger;
  }
}

.warning {
  color: $warning;
  background: $warning-pale;

  &.dark {
    background: $warning;
  }
}

.success {
  color: $success;
  background: #E9FBF2;

  &.dark {
    background: $success;
  }
}

.secondary {
  color: $grey-700;
  background: $grey-200;

  &.dark {
    background: $grey-600;
  }
}

.highlight {
  color: $ci-secondary-2;
  background: #F0E8FD;

  &.dark {
    background: $highlight;
  }
}

.info {
  color: $info;
  background: $info-pale;

  &.dark {
    background: $info;
  }
}

.action{
  color: $grey-500;
  background: $grey-200;
}

.icon-prefix-container {
  margin-right: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon-suffix-container {
  margin-left: 4px;
}

.with-margin {
  margin-left: $spacing-4;
}
</style>
