<template>
  <div>
    <transition name="fade">
      <div
        class="dragging-overlay-container"
        v-if="isDraggingVideoState">
        <div class="dragging-overlay">
          <MessageBox
            iconClassName="fas fa-cloud-arrow-up"
            :title="$t('video.uploader.dropToUpload')" />
        </div>
      </div>
    </transition>
  </div>
</template>

<script setup lang="ts">
import { useStore } from '@/store';
import { computed } from 'vue';
import MessageBox from '@/modules/shared/components/molecules/messageBox/MessageBox.vue';

const store = useStore();
const isDraggingVideoState = computed(() => store.getters.isDraggingVideoState);
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/global-variables.scss';
@import '~@/assets/scss/themes/helpers.scss';

.dragging-overlay-container {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: rgba(0, 0, 0, 0.7);

  z-index: 1051;
  pointer-events: none;

  .dragging-overlay {
    display: flex;
    color: white;

    .title {
      font-size: $font-size-base * 2.5;
      color: white;
    }

    .icon {
      color: white;
    }
  }
}
</style>
