import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createElementBlock as _createElementBlock, createSlots as _createSlots } from "vue"

const _hoisted_1 = { class: "btn-text-full" }
const _hoisted_2 = { class: "btn-text-short" }
const _hoisted_3 = {
  key: 0,
  class: "geo-blocking-link-container"
}

import _ from 'lodash';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';
import { useStore } from '@/modules/projectDefaultUploadSetting/store/hooks';
import type { GeoblockRule } from '@/modules/geoblock';
import { GeoblockRuleCard } from '@/modules/geoblock';
import Section from '@/modules/shared/components/organisms/section/Section.vue';
import Button from '@/modules/shared/components/atoms/button/Button.vue';
import ChangeGeoblockModal from '@/modules/projectDefaultUploadSetting/components/organisms/changeGeoblockModal/ChangeGeoblockModal.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'ProjectManageUploadSettingGeoblockSection',
  setup(__props) {

const isGeoblockModalShowing = ref<boolean>(false);

const { t } = useI18n();
const store = useStore();
const route = useRoute();

const teamName = computed(() => route.params.teamName);
const currentProject = computed(() => store.state.project.currentProject);
const geoblockRule = computed(() => store.state.project.currentProject?.geoblockRule);
const saveProjectState = computed(() => store.state.project.saveProjectState);

function onToggleGeoblockModalShowing() {
  isGeoblockModalShowing.value = !isGeoblockModalShowing.value;
}

async function onSubmit(selectedGeoblockRule: GeoblockRule | null | undefined) {
  if (_.isNil(currentProject.value) || _.isNil(selectedGeoblockRule)) {
    return;
  }

  const payload = {
    projectKey: currentProject.value.key,
    data: { ...currentProject.value, geoblockRuleId: selectedGeoblockRule.id },
  };
  await store.dispatch('editProject', payload);
  if (saveProjectState.value.status === 'success') {
    onToggleGeoblockModalShowing();
  }
}

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", null, [
    (isGeoblockModalShowing.value)
      ? (_openBlock(), _createBlock(ChangeGeoblockModal, {
          key: 0,
          saveState: saveProjectState.value,
          geoblockRule: geoblockRule.value,
          onClose: onToggleGeoblockModalShowing,
          onSubmit: onSubmit
        }, null, 8, ["saveState", "geoblockRule"]))
      : _createCommentVNode("", true),
    _createVNode(Section, {
      title: _unref(t)('project.manage.setting.geoblock.title')
    }, _createSlots({
      default: _withCtx(() => [
        _createVNode(_unref(GeoblockRuleCard), { geoblockRule: geoblockRule.value }, null, 8, ["geoblockRule"])
      ]),
      _: 2
    }, [
      (_ctx.$attrs.canUpdateProject)
        ? {
            name: "action",
            fn: _withCtx(() => [
              _createVNode(Button, {
                buttonStyle: "secondary",
                buttonClasses: "change-geoblock-button",
                type: "button",
                onClick: onToggleGeoblockModalShowing
              }, {
                content: _withCtx(() => [
                  _createElementVNode("span", _hoisted_1, _toDisplayString(_unref(t)('project.defaultSettings.geoblock.change')), 1),
                  _createElementVNode("span", _hoisted_2, _toDisplayString(_unref(t)('common:button.change')), 1)
                ]),
                _: 1
              })
            ]),
            key: "0"
          }
        : undefined,
      (_ctx.$attrs.isManager)
        ? {
            name: "section-footer-with-border",
            fn: _withCtx(() => [
              (geoblockRule.value)
                ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                    _createVNode(_component_router_link, {
                      class: "link",
                      to: `/${teamName.value}/manage/geo-blocking`
                    }, {
                      default: _withCtx(() => [
                        _cache[0] || (_cache[0] = _createElementVNode("i", { class: "fas fa-gear" }, null, -1)),
                        _createTextVNode(" " + _toDisplayString(_unref(t)('project.manage.geoblocking.goToSettings')), 1)
                      ]),
                      _: 1
                    }, 8, ["to"])
                  ]))
                : _createCommentVNode("", true)
            ]),
            key: "1"
          }
        : undefined
    ]), 1032, ["title"])
  ]))
}
}

})