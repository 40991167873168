<template>
  <Page
    :title="t('project.manage.setting.title')">
    <div>
      <ProjectVideoPlayerSection :isManager="isManagerOrAdmin" />
    </div>
    <ProjectBumperDisplaySection
      v-if="isFeatureEnabled"
      :isManager="isManagerOrAdmin"
    />
  </Page>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import { computed } from 'vue';
import Page from '@/layouts/Page.vue';
import ProjectVideoPlayerSection from '../organisms/projectVideoPlayerSection/ProjectVideoPlayerSection.vue';
import ProjectBumperDisplaySection from '../organisms/projectBumperDisplaySection/ProjectBumperDisplaySection.vue';
import { useStore } from '../store/hooks';

const { t } = useI18n();
const store = useStore();

const teamFeatures = computed(() => store.state.teamFeatures.teamFeatures);
const isManagerOrAdmin = computed(() => store.getters.isManagerOrAdmin);
const isFeatureEnabled = computed(() => teamFeatures.value?.bumper === true);
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/global-variables';

</style>
