<template>
  <Popper
    :arrow="withArrow"
    placement="bottom"
    class="dropdown"
    :offsetSkid="offsetSkid"
    :offsetDistance="(-3 + 10).toString()"
    zIndex="5"
    :onOpen:popper="onOpenDropdown"
    :onClose:popper="onCloseDropdown"
    :show="show"
    locked>
    <!-- Trigger Element -->
    <Button
      type="button"
      :dataTest="`${dataTest}-popper-button`"
      :buttonClasses="`btn-dropdown ${text ? '' : 'btn-icon'} ${buttonClassName ? buttonClassName : ''}`"
      :buttonStyle="buttonStyle"
    >
      <template v-slot:icon-prefix v-if="iconPrefixClassName">
        <i :class="iconPrefixClassName" />
      </template>
      <template v-slot:content v-if="buttonText">
        {{buttonText}}
      </template>
      <template v-slot:icon-suffix v-if="iconClassName">
        <i :class="iconClassName" />
      </template>
    </Button>
    <template v-slot:content>
      <slot></slot>
    </template>
  </Popper>
</template>

<script lang="ts" setup>
import { toRefs } from 'vue';
import Popper from 'vue3-popper';
import Button from '@/modules/shared/components/atoms/button/Button.vue';
import { ButtonStyle } from '@/types/Button.type';
import { Nullable } from '@/types/index.type';

export interface DropdownProps {
  text?: Nullable<string>;
  iconClassName?: Nullable<string>;
  iconPrefixClassName?: Nullable<string>;
  buttonClassName?: Nullable<string>;
  buttonStyle?: ButtonStyle;
  buttonText?: Nullable<string>;
  // menuAlignment?: 'left' | 'right';
  // TODO : Make style with large, small sizing..
  // size?: 'small' | 'medium' | 'large';
  dataTest?: string;
  withArrow?: boolean;
  offsetSkid?: string;
  onOpenDropdown?: () => void;
  onCloseDropdown?: () => void;
  show?: boolean;
}

const props = withDefaults(defineProps<DropdownProps>(), {
  buttonClassName: 'btn-outline-secondary',
  buttonStyle: 'text-secondary',
  dataTest: 'dropdown',
  iconClassName: 'fas fa-ellipsis-vertical',
  withArrow: true,
  offsetSkid: `${-50 + 10}`,
  show: undefined,
  // menuAlignment: 'left',
});

const {
  text,
  iconClassName,
  iconPrefixClassName,
  buttonClassName,
  buttonStyle,
  buttonText,
  // menuAlignment,
  dataTest,
  withArrow,
  onOpenDropdown,
  onCloseDropdown,
  show,
} = toRefs(props);
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/global-variables.scss';
  .dropdown {
    /* --popper-theme-background-color: #fff;
    --popper-theme-background-color-hover: #fff;
    --popper-theme-text-color: #212529;
    --popper-theme-border-radius: .25rem;
    --popper-theme-padding: .5rem 0;
    --popper-theme-box-shadow: 0 0 6px rgba(0, 0, 0, 0.15); */
  }
  :deep(.popper) {
    position: absolute;
    min-width: 10rem;
    padding: .5rem 0 !important;
    margin: .125rem 0 0 !important;
    font-size: 1rem;
    color: #212529 !important;
    background-color: #fff !important;
    border: none;
    border-radius: .25rem !important;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.15) !important;
  }
  :deep(.popper #arrow::before) {
    // arrow size config
    width: 0 !important;
    height: 0 !important;
    top: -5px !important;
    left: -5px !important;
    border: 10px solid black !important;
    border-color: transparent transparent #fff $white !important;
    transform: rotate(135deg) !important;
    box-shadow: -10px 10px 15px -5px rgb(0 0 0 / 30%) !important;
  }
  :deep(.popper:hover) {
    background-color: #fff;
  }

/* .dropdown-container {
  position: relative;
}

:deep(.icon-prefix) {
  margin-right: 0 !important;
}

:deep(.btn-dropdown) {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: $spacing-base * 0.375 $spacing-base !important;
}

i {
  width: 12px;
  margin-right: 6px;
}

:deep(.btn-icon) {
  padding: 0;
  width: 35px;
  height: 35px;

  i {
    width: auto;
    margin-right: 0;
  }
}

.menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  float: left;
  min-width: 10rem;
  padding: .5rem 0;
  margin: .125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: none;
  border-radius: .25rem;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.15);
}

.left {
  .menu {
    transform: translate3d(0px, 38px, 0px);
    top: 0px;
    left: 0px;
    will-change: transform;

    left: auto;
    bottom: auto;
    display: block;
  }
}

.right {
  .menu {
    transform: translate3d(-120px, 38px, 0px);
    top: 0px;
    left: 0px;
    will-change: transform;
    right: auto;
    bottom: auto;
    display: block;
  }

  .triangle {
    position: absolute;
    top: -29px;
    right: 0;
    width: 50px;
    height: 30px;
    overflow: hidden;
    z-index: 1;

    &::after {
      content: "";
      position: absolute;
      width: 20px;
      height: 20px;
      background: #FFF;
      transform: rotate(45deg);
      top: 20px;
      left: 17px;
      box-shadow: -1px -1px 10px -2px rgba(0, 0, 0, 0.3);
    }
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100vw;
  height: 100vh;
  background-color: transparent;
} */
</style>
