<template>
  <SectionCollapse
    v-if="player"
    :title="t('players.currentPlayer.title')"
    :description="t('players.currentPlayer.subTitle')">
    <HeadingWithIconGroup
      title=""
      subTitle=""
      class="player-section"
      iconClassName="fas fa-play mr-4">
      <router-link
        :to="`/${teamName}/manage/players/${player.id}`"
        class="player-section-wrapper">
        <div class="player-section-wrapper-row">
          <div class="player-information-wrapper">
            <div class="player-information">
              <div class="player-card-header">
                <h5 class="information-name">
                  {{player.name}}
                </h5>
                <!-- <Badge v-if="player.isDefault" :title="$t('glossary:default')" /> -->
              </div>
              <div class="last-updated">
                <span class="subtitle">
                  {{t('common:form.updatedAt')}}: {{formatDateTime(player.updatedAt)}}
                </span>
              </div>
            </div>
          </div>
        </div>
      </router-link>
    </HeadingWithIconGroup>
    <div class="link-container" v-if="$attrs.isManager">
      <router-link
        class="link"
        :to="`/${teamName}/manage/players/${player.id}`">
        <i class="fas fa-gear" /> {{t('project.manage.player.goToSettings')}}
      </router-link>
    </div>
  </SectionCollapse>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';
import { getQueryAsString } from '@/modules/shared/utils/query';
import { formatDateTime } from '@/modules/shared/utils/dateFormatter';
import HeadingWithIconGroup from '@/modules/shared/components/molecules/headingWithIconGroup/HeadingWithIconGroup.vue';
import SectionCollapse from '@/modules/shared/components/organisms/sectionCollapse/SectionCollapse.vue';
import { useStore } from '@/modules/projectSetting/store/hooks';

const { t } = useI18n();
const store = useStore();
const route = useRoute();

const player = computed(() => store.state.project.currentProject?.player);
const teamName = computed(() => getQueryAsString(route.params, 'teamName'));
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/global-variables';

.player-section {
  width: 100%;
  padding: 10px 0;
  margin-bottom: 0;
}

.information-name {
  margin-bottom: 0;
  color: $grey-700;
}

.player-section-wrapper {
  color: $body-color;

  .player-section-wrapper-row {
    display: flex;
    flex-wrap : wrap;

    .player-information-wrapper {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
}

.link-container {
  text-align: right;
  .link {
    color: $body-color;
  }
}

:deep(.heading-group-content--title) {
  display: none;
}

:deep(.title) {
  font-size: $font-size-base * 1.25 !important; // This is temporary.
}
</style>
