import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

import { useI18n } from 'vue-i18n';
import { computed } from 'vue';
import Page from '@/layouts/Page.vue';
import ProjectVideoPlayerSection from '../organisms/projectVideoPlayerSection/ProjectVideoPlayerSection.vue';
import ProjectBumperDisplaySection from '../organisms/projectBumperDisplaySection/ProjectBumperDisplaySection.vue';
import { useStore } from '../store/hooks';


export default /*@__PURE__*/_defineComponent({
  __name: 'ProjectManageSetting',
  setup(__props) {

const { t } = useI18n();
const store = useStore();

const teamFeatures = computed(() => store.state.teamFeatures.teamFeatures);
const isManagerOrAdmin = computed(() => store.getters.isManagerOrAdmin);
const isFeatureEnabled = computed(() => teamFeatures.value?.bumper === true);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(Page, {
    title: _unref(t)('project.manage.setting.title')
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", null, [
        _createVNode(ProjectVideoPlayerSection, { isManager: isManagerOrAdmin.value }, null, 8, ["isManager"])
      ]),
      (isFeatureEnabled.value)
        ? (_openBlock(), _createBlock(ProjectBumperDisplaySection, {
            key: 0,
            isManager: isManagerOrAdmin.value
          }, null, 8, ["isManager"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["title"]))
}
}

})