import type { CaptionDraft, EditingLine } from '@/modules/videoCaption/types';
import parseMilliseconds from 'parse-ms';

export function secondsToTimeString(seconds: number) {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const secs = Math.floor(seconds % 60);
  const millis = Math.floor((seconds % 1) * 1000);

  return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(secs).padStart(2, '0')}.${String(millis).padStart(3, '0')}`;
}

export function msToTimeString(ms: number) {
  const {
    hours,
    minutes,
    seconds,
    milliseconds,
  } = parseMilliseconds(ms);

  return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}.${String(milliseconds).padStart(3, '0')}`;
}

export function getDefaultLocalEditingLines(lineNo = 1, startTime = 0, endTime = 3000): EditingLine[] {
  return [{
    lineNo,
    text: '',
    startTime,
    endTime,
    startTimeError: false,
    endTimeError: false,
  }];
}

export function getCaptionDraftPayload(currentSubtitle: CaptionDraft | null, localEditingLines: EditingLine[]): CaptionDraft | null {
  if (!currentSubtitle) {
    return null;
  }

  return {
    ...currentSubtitle,
    captionContent: localEditingLines.map((line) => ({
      text: line.text,
      startTime: line.startTime / 1000, // CONVERT MS TO SECONDS
      endTime: line.endTime / 1000, // CONVERT MS TO SECONDS
    })),
  };
}

export function focusTheNewlyCreatedLine(index: number) {
  const textareaElement = document.getElementById(`subtitle-editor-textarea-${index}`);

  if (textareaElement) {
    textareaElement.focus();
  }
}
