import type { RouteRecordRaw } from 'vue-router';
import { collection } from '@/modules/collection';
import { collectionList } from '@/modules/collectionList';
import { geoblockRuleList } from '@/modules/geoblock';
import { projectVideoCaptionIssueList, projectVideoList } from '@/modules/projectVideoList';
import { video } from '@/modules/videoDetail';
import { customField } from '@/modules/customField';
import customImageUpload from '@/modules/customImageField/store/customImageUpload';
import customImageFieldList from '@/modules/customImageField/store/customImageFieldList';
import playbackChannelList from '@/modules/playbackChannel/store/playbackChannelList';
/** !! Import teamSetting from '@/modules/teamSetting' will break the whole web !! */
import teamSetting from '@/modules/teamSetting/store/index';
import videoUpload from '@/modules/videoUpload/store/videoUpload';
import videoUploaderDraggable from '@/modules/videoUpload/store/videoUploaderDraggable';
import store from '@/store';
import teamFeatures from '@/modules/shared/store/teamFeatures';
import { createVideoCaptionRoutes } from '@/modules/videoCaption';

export function createVideoRoutes(): RouteRecordRaw[] {
  return [
    {
      path: 'projects/:projectKey/videos/:videoKey',
      redirect: 'projects/:projectKey/videos/:videoKey/information',
      component: () => import(/* webpackChunkName: "VideoChrome" */ '../layouts/VideoChrome.vue'),
      beforeEnter: (to, from, next) => {
        if (!store.hasModule('video')) {
          store.registerModule('video', video);
        }
        if (!store.hasModule('videoUpload')) {
          store.registerModule('videoUpload', videoUpload);
        }
        if (!store.hasModule('projectVideoCaptionIssueList')) {
          store.registerModule('projectVideoCaptionIssueList', projectVideoCaptionIssueList);
        }
        if (!store.hasModule('projectVideoList')) {
          store.registerModule('projectVideoList', projectVideoList);
        }
        // for MoveToCollectionModal
        if (!store.hasModule('collection')) {
          store.registerModule('collection', collection);
        }
        if (!store.hasModule('collectionList')) {
          store.registerModule('collectionList', collectionList);
        }
        if (!store.hasModule('customField')) {
          store.registerModule('customField', customField);
        }
        if (!store.hasModule('videoUpload')) {
          store.registerModule('videoUpload', videoUpload);
        }
        if (!store.hasModule('geoblockRuleList')) {
          store.registerModule('geoblockRuleList', geoblockRuleList);
        }
        if (!store.hasModule('playbackChannelList')) {
          store.registerModule('playbackChannelList', playbackChannelList);
        }
        if (!store.hasModule('customImageUpload')) {
          store.registerModule('customImageUpload', customImageUpload);
        }
        if (!store.hasModule('customImageFieldList')) {
          store.registerModule('customImageFieldList', customImageFieldList);
        }
        if (!store.hasModule('videoUploaderDraggable')) {
          store.registerModule('videoUploaderDraggable', videoUploaderDraggable);
        }
        if (!store.hasModule('teamFeatures')) {
          store.registerModule('teamFeatures', teamFeatures);
        }
        if (!store.hasModule('teamSetting')) {
          store.registerModule('teamSetting', teamSetting);
        }
        next();
      },
      children: [
        {
          path: 'overview',
          component: () => import(/* webpackChunkName: "VideoOverviewTab" */ '../pages/VideoOverview.vue'),
        },
        {
          path: 'information',
          component: () => import(/* webpackChunkName: "VideoInformationTab" */ '../pages/VideoInformation.vue'),
        },
        {
          path: 'advanced',
          component: () => import(/* webpackChunkName: "VideoAdvancedTab" */ '../pages/VideoAdvanced.vue'),
        },
        {
          path: 'stats',
          component: () => import('../pages/VideoStats.vue'),
        },
        {
          path: 'geo-blocking',
          component: () => import(/* webpackChunkName: "VideoAdvancedTab" */ '../pages/VideoGeoblock.vue'),
        },
        {
          path: 'outputs',
          component: () => import(/* webpackChunkName: "VideoAdvancedTab" */ '../pages/VideoOutputs.vue'),
        },
        ...createVideoCaptionRoutes(),
        {
          path: 'custom-images',
          component: () => import(/* webpackChunkName: "VideoAdvancedTab" */ '../pages/VideoCustomImage.vue'),
        },
        {
          path: 'bumper',
          component: () => import(/* webpackChunkName: "VideoAdvancedTab" */ '../pages/VideoBumper.vue'),
        },
        // {
        //   path: 'bumper/manage',
        //   name: 'manageVideoBumber',
        //   component: () => import(/* webpackChunkName: "VideoAdvancedTab" */ '../pages/VideoBumperManagement.vue'),
        // },
        {
          path: 'exported-files',
          component: () => import(/* webpackChunkName: "VideoAdvancedTab" */ '../pages/VideoExport.vue'),
        },
      ],
    },
  ];
}
