import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "uploader-button-container" }

import Button from '@/modules/shared/components/atoms/button/Button.vue';
import { toRefs } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from '../../../store/hooks';

interface VideoUploaderButtonProps {
  isMultiple?: boolean;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'VideoUploaderButton',
  props: {
    isMultiple: { type: Boolean, default: true }
  },
  setup(__props: any) {

const props = __props;

const { isMultiple } = toRefs(props);
const store = useStore();
const { t } = useI18n();

async function fileBrowsed(e: Event) {
  if (!e.target || !('files' in e.target)) {
    return;
  }

  await store.dispatch('updateVideoFiles', e.target.files);

  if ('value' in e.target) {
    /** Reset selected files. */
    e.target.value = '';
  }
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_unref(isMultiple))
      ? (_openBlock(), _createElementBlock("input", {
          key: 0,
          type: "file",
          class: "input-file",
          ref: "inputfile",
          multiple: "",
          onChange: fileBrowsed
        }, null, 544))
      : _createCommentVNode("", true),
    (!_unref(isMultiple))
      ? (_openBlock(), _createElementBlock("input", {
          key: 1,
          type: "file",
          class: "input-file",
          ref: "inputfile",
          name: "file",
          onChange: fileBrowsed
        }, null, 544))
      : _createCommentVNode("", true),
    _createVNode(Button, {
      type: "button",
      size: "large",
      buttonStyle: "text-primary",
      buttonClasses: "btn-upload"
    }, {
      "icon-prefix": _withCtx(() => _cache[0] || (_cache[0] = [
        _createElementVNode("i", { class: "fas fa-cloud-arrow-up" }, null, -1)
      ])),
      content: _withCtx(() => [
        _createTextVNode(_toDisplayString(_unref(t)('video.uploader.uploadMore')), 1)
      ]),
      _: 1
    })
  ]))
}
}

})