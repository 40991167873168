<template>
  <div class="wrapper">
    <svg
      :height="radius * 2"
      :width="radius * 2"
    >
      <circle
        :stroke="backgroundStrokeColor"
        fill="transparent"
        :stroke-dasharray="circumference + ' ' + circumference"
        :style="100"
        :stroke-width="stroke"
        :r="normalizedRadius"
        :cx="radius"
        :cy="radius"
      />
      <circle
        :stroke="strokeColor"
        fill="transparent"
        :stroke-dasharray="circumference + ' ' + circumference"
        :style="{ strokeDashoffset }"
        :stroke-width="stroke"
        :r="normalizedRadius"
        :cx="radius"
        :cy="radius"
      />
    </svg>
  </div>
</template>

<script>
import {
  computed, ref,
} from 'vue';

export default {
  components: {
  },
  props: {
    radius: {
      type: Number,
    },
    progress: {
      type: Number,
    },
    stroke: {
      type: Number,
    },
    strokeColor: {
      type: String,
      default: '#9EA4A9',
    },
    backgroundStrokeColor: {
      type: String,
      default: 'white',
    },
  },
  setup(props) {
    const normalizedRadius = ref(props.radius - props.stroke);
    const circumference = ref(normalizedRadius.value * 2 * Math.PI);

    const strokeDashoffset = computed(() => circumference.value - (props.progress / 100) * circumference.value);
    return {
      normalizedRadius,
      circumference,
      strokeDashoffset,
    };
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  transform: rotate(-90deg);

}
</style>
