import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createSlots as _createSlots, createBlock as _createBlock, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = ["data-test"]
const _hoisted_2 = {
  key: 0,
  class: "icon-container"
}
const _hoisted_3 = { class: "text-container" }
const _hoisted_4 = {
  key: 0,
  class: "title",
  "data-test": "message-box-title"
}
const _hoisted_5 = {
  key: 1,
  class: "description",
  "data-test": "message-box-description"
}
const _hoisted_6 = {
  key: 1,
  class: "button-container",
  "data-test": "message-box-button-container"
}

import { toRefs } from 'vue';
import Button from '@/modules/shared/components/atoms/button/Button.vue';
import { MessageBoxProps } from './MessageBox.type';


export default /*@__PURE__*/_defineComponent({
  __name: 'MessageBox',
  props: {
    buttonPrefixIconClassName: {},
    buttonSuffixIconClassName: {},
    buttonStyle: {},
    buttonTitle: {},
    buttonUrl: {},
    description: {},
    height: {},
    iconClassName: {},
    onClickButton: {},
    title: {},
    dataTest: { default: 'message-box-container' }
  },
  setup(__props: any) {

const props = __props;

const {
  buttonPrefixIconClassName,
  buttonSuffixIconClassName,
  buttonStyle,
  buttonTitle,
  buttonUrl,
  description,
  height,
  iconClassName,
  title,
  onClickButton,
  dataTest,
} = toRefs(props);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["message-box-container", { 'big-layout' : _unref(iconClassName) || _ctx.$slots['icon'] }]),
    style: _normalizeStyle({ height: _unref(height) }),
    "data-test": _unref(dataTest)
  }, [
    (_unref(iconClassName) || _ctx.$slots['icon'])
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("i", {
            class: _normalizeClass(_unref(iconClassName)),
            "data-test": "message-box-icon"
          }, null, 2),
          (!_unref(iconClassName))
            ? _renderSlot(_ctx.$slots, "icon", { key: 0 })
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      (_unref(title) || _ctx.$slots['title'])
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createTextVNode(_toDisplayString(_unref(title)) + " ", 1),
            (!_unref(title))
              ? _renderSlot(_ctx.$slots, "title", { key: 0 })
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true),
      (_unref(description) || _ctx.$slots['description'])
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createTextVNode(_toDisplayString(_unref(description)) + " ", 1),
            (!_unref(description))
              ? _renderSlot(_ctx.$slots, "description", { key: 0 })
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true)
    ]),
    (_unref(buttonTitle) || _ctx.$slots['action'])
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          (_unref(buttonTitle))
            ? (_openBlock(), _createBlock(Button, {
                key: 0,
                "data-test": "message-box-button",
                buttonStyle: _unref(buttonStyle) || ((_unref(iconClassName) || _ctx.$slots['icon']) ? 'primary' : 'text-primary'),
                size: (_unref(iconClassName) || _ctx.$slots['icon'] || _unref(buttonPrefixIconClassName) || _unref(buttonSuffixIconClassName)) ? 'large' : 'small',
                onClick: _unref(onClickButton),
                url: _unref(buttonUrl) ?? ''
              }, _createSlots({
                default: _withCtx(() => [
                  _createTextVNode(" " + _toDisplayString(_unref(buttonTitle)), 1)
                ]),
                _: 2
              }, [
                (_unref(buttonPrefixIconClassName))
                  ? {
                      name: "icon-prefix",
                      fn: _withCtx(() => [
                        _createElementVNode("i", {
                          class: _normalizeClass(_unref(buttonPrefixIconClassName))
                        }, null, 2)
                      ]),
                      key: "0"
                    }
                  : undefined,
                (_unref(buttonSuffixIconClassName))
                  ? {
                      name: "icon-suffix",
                      fn: _withCtx(() => [
                        _createElementVNode("i", {
                          class: _normalizeClass(_unref(buttonSuffixIconClassName))
                        }, null, 2)
                      ]),
                      key: "1"
                    }
                  : undefined
              ]), 1032, ["buttonStyle", "size", "onClick", "url"]))
            : _createCommentVNode("", true),
          (_ctx.$slots['action'])
            ? _renderSlot(_ctx.$slots, "action", { key: 1 })
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ], 14, _hoisted_1))
}
}

})