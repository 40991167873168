import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "name"
}

import { StyleValue, toRefs } from 'vue';
import _ from 'lodash';
import { colorGeneratorFromString } from '@/modules/shared/utils/colorGenerator';
import type { User } from '@/modules/user';
import type { Nullable } from '@/types/index.type';

interface LogoProps {
  user: Nullable<User>;
  options?: StyleValue;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'Logo',
  props: {
    user: {},
    options: { type: [Boolean, null, String, Object, Array], default: () => ({
    width: '36px',
    height: '36px',
  }) }
  },
  setup(__props: any) {

const props = __props;

const { user, options } = toRefs(props);

function generateBackgroundColor() {
  if (!user.value) {
    return '';
  }

  return _.get(options.value, 'backgroundColor', colorGeneratorFromString(user.value.name));
}

function generateBackgroundImage() {
  const imageObject = _.get(user.value, 'displayImage.sizes.large.url', null);

  if (imageObject) {
    return `url(${imageObject})`;
  }

  return undefined;
}

return (_ctx: any,_cache: any) => {
  return (_unref(user))
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "logo",
        style: _normalizeStyle([
      _unref(options),
      { backgroundImage: generateBackgroundImage() },
      { backgroundColor: generateBackgroundColor() },
    ])
      }, [
        (_unref(user).name && !generateBackgroundImage())
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_unref(user).name.charAt(0)), 1))
          : _createCommentVNode("", true),
        _renderSlot(_ctx.$slots, "default", { class: "name" })
      ], 4))
    : _createCommentVNode("", true)
}
}

})