<template>
  <div class="form-input" :data-test="dataTest">
    <FormLabel class="form-label" :title="title" :subtitle="subtitle" />
    <PreLoaderSection v-if="isLoading" :options="{ height: 34 }" />
    <Input
      v-else
      :disabled="disabled"
      :inputType="inputType"
      :placeholder="placeholder"
      :min="min"
      :max="max"
      :onBlur="onBlur"
      :onChange="onChange"
      :modelValue="modelValue"
      :onKeyDown="onKeyDown"
      :onPressedEnter="onPressedEnter"
      :isShowErrorMessage="isShowErrorMessage"
      :prefixText="prefixText"
      :suffixText="suffixText"
      :maxWidth="maxWidth"
      :isShowCharacterCount="isShowCharacterCount"
      @focus="(value) => (isShowHelpText = value)"
      @update:modelValue="(value) => emit('update:modelValue', value)"
    />
    <FormErrorMessage v-if="errorMessage && isShowErrorMessage" :errorMessage="errorMessage" />
    <FormHelpText
      v-if="helpText && !(errorMessage && isShowErrorMessage) && isShowHelpText"
      :helpText="helpText"
    />
  </div>
</template>

<script lang="ts" setup>
import Input from '@/modules/shared/components/atoms/input/Input.vue';
import PreLoaderSection from '@/modules/shared/components/atoms/preLoaderSection/PreLoaderSection.vue';
import FormLabel from '@/modules/shared/components/molecules/formLabel/FormLabel.vue';
import FormHelpText from '@/modules/shared/components/atoms/formHelpText/FormHelpText.vue';
import FormErrorMessage from '@/modules/shared/components/atoms/formErrorMessage/FormErrorMessage.vue';
import { InputProps } from '@/types/Input.type';
import { ref, toRefs } from 'vue';

interface FormInputProps extends InputProps {
  isLoading?: boolean;
  errorMessage?: string | null;
  title?: string;
  subtitle?: string;
  helpText?: string;
  dataTest?: string;
}

const props = defineProps<FormInputProps>();
const {
  disabled,
  isLoading,
  inputType,
  placeholder,
  min,
  max,
  isShowErrorMessage,
  errorMessage,
  modelValue,
  title,
  subtitle,
  helpText,
  onBlur,
  onChange,
  onKeyDown,
  prefixText,
  suffixText,
  maxWidth,
  isShowCharacterCount,
  dataTest,
} = toRefs(props);

const emit = defineEmits<{
  'update:modelValue': [value: string];
}>();

const isShowHelpText = ref(false);
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/global-variables.scss';
.form-label {
  margin-bottom: $spacing-8;
}
</style>
