import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "section-row" }
const _hoisted_2 = { class: "section-content" }

import ProjectDangerZone
  from '@/modules/projectDetails/components/organisms/projectDangerZone/ProjectDangerZone.vue';
import type { ProjectMember } from '@/modules/projectMember/types';
import _ from 'lodash';
import { computed, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';
import Page from '@/layouts/Page.vue';
import Section from '@/modules/shared/components/organisms/section/Section.vue';
import TextEdit from '@/modules/shared/components/molecules/textEdit/TextEdit.vue';
import Alert from '@/modules/shared/components/atoms/alert/Alert.vue';
import MessageBox from '@/modules/shared/components/molecules/messageBox/MessageBox.vue';
import PreLoaderSection from '@/modules/shared/components/atoms/preLoaderSection/PreLoaderSection.vue';
import ProjectCreator from '../components/molecules/projectCreator/ProjectCreator.vue';
import { useStore } from '../store/hooks';

interface StateRefData {
  nameError: boolean;
}

interface ProjectRefData {
  id: string;
  name: string;
  key: string;
  description: string | null;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'ProjectDetails',
  setup(__props) {

const { t } = useI18n();
const store = useStore();
const route = useRoute();

const state = ref<StateRefData>({ nameError: false });
const project = ref<ProjectRefData>({
  id: '',
  name: '',
  key: '',
  description: '',
});
const createdAt = ref<string>('');
const creator = ref<ProjectMember | null>(null);

const currentProject = computed(() => store.state.project.currentProject);
const loadProjectManagementState = computed(() => store.state.project.loadProjectManagementState);
const saveProjectState = computed(() => store.state.project.saveProjectState);
const isManagerOrAdmin = computed(() => store.getters.isManagerOrAdmin);

const teamName = computed(() => route.params.teamName);
const projectKey = computed(() => route.params.projectKey);
const permission = computed(() => currentProject.value?.actions);
const canUpdateProject = computed(() => !_.isNil(permission.value) && permission.value.updateProject);
const isSaving = computed(() => saveProjectState.value.status === 'saving');
const isSavingError = computed(() => saveProjectState.value.status === 'error');
const isSavingSuccess = computed(() => saveProjectState.value.status === 'success');
const nameErrorMessage = computed(() => {
  if (state.value.nameError) {
    return t('response:minCharacter', {
      itemName: t('project.manage.name'),
      minCharacter: 3,
    });
  }
  return '';
});

function fillForm() {
  if (!currentProject.value) {
    return;
  }

  const {
    id,
    name,
    key,
    description,
  } = currentProject.value;

  project.value = {
    id,
    name,
    key,
    description,
  };

  creator.value = currentProject.value.creator;
  createdAt.value = currentProject.value.createdAt;
}

async function onSubmit() {
  const payload = {
    projectKey: projectKey.value,
    data: project.value,
  };
  await store.dispatch('editProject', payload);

  const timer = setTimeout(() => {
    store.commit('setUpdateProjectStateMessage', { type: 'idle' });
    clearTimeout(timer);
  }, 3000);
}

function isNameValid(value: string): boolean {
  if (value) {
    return value.length >= 3;
  }

  return project.value.name.length >= 3;
}

function onConfirmName(value: string) {
  if (isNameValid(value)) {
    project.value.name = value;
    onSubmit();
  } else {
    state.value.nameError = true;
  }
}

function onConfirmDescription(value: string) {
  project.value.description = value;
  onSubmit();
}

function checkNameValid(value: string) {
  if (value.length >= 3) {
    state.value.nameError = false;
  } else {
    state.value.nameError = true;
  }
}

onMounted(() => fillForm());

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(Page, {
    title: _unref(t)('projectInformation'),
    projectName: project.value?.name
  }, {
    default: _withCtx(() => [
      (isSavingError.value)
        ? (_openBlock(), _createBlock(Alert, {
            key: 0,
            priority: "danger",
            alertText: _unref(t)('response:generalError.title')
          }, null, 8, ["alertText"]))
        : _createCommentVNode("", true),
      (isSavingSuccess.value)
        ? (_openBlock(), _createBlock(Alert, {
            key: 1,
            alertText: _unref(t)('response:savedSuccessfully'),
            priority: "success"
          }, null, 8, ["alertText"]))
        : _createCommentVNode("", true),
      (canUpdateProject.value && loadProjectManagementState.value.status === 'success')
        ? (_openBlock(), _createBlock(Section, { key: 2 }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, [
                  _createVNode(TextEdit, {
                    label: "Project ID",
                    modelValue: project.value.id,
                    enableEdit: false
                  }, null, 8, ["modelValue"]),
                  _createVNode(TextEdit, {
                    label: _unref(t)('project.manage.key'),
                    modelValue: project.value.key,
                    enableEdit: false
                  }, null, 8, ["label", "modelValue"]),
                  _createVNode(TextEdit, {
                    label: _unref(t)('project.manage.name'),
                    modelValue: project.value.name,
                    errorMessage: nameErrorMessage.value,
                    onConfirm: onConfirmName,
                    "onUpdate:modelValue": checkNameValid,
                    enableEdit: !isSaving.value
                  }, null, 8, ["label", "modelValue", "errorMessage", "enableEdit"]),
                  _createVNode(TextEdit, {
                    label: _unref(t)('common:form.description'),
                    modelValue: project.value.description,
                    onConfirm: onConfirmDescription,
                    enableEdit: !isSaving.value
                  }, null, 8, ["label", "modelValue", "enableEdit"]),
                  (creator.value)
                    ? (_openBlock(), _createBlock(ProjectCreator, {
                        key: 0,
                        creator: creator.value,
                        createdAt: createdAt.value
                      }, null, 8, ["creator", "createdAt"]))
                    : _createCommentVNode("", true)
                ])
              ])
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (isManagerOrAdmin.value && canUpdateProject.value && loadProjectManagementState.value.status === 'success')
        ? (_openBlock(), _createBlock(ProjectDangerZone, { key: 3 }))
        : _createCommentVNode("", true),
      (!canUpdateProject.value)
        ? (_openBlock(), _createBlock(Section, { key: 4 }, {
            default: _withCtx(() => [
              _createVNode(MessageBox, {
                iconClassName: "fas fa-user-slash",
                title: _unref(t)('project.noPermission.title'),
                description: _unref(t)('project.noPermission.helpText'),
                buttonTitle: _unref(t)('video.noPermission.button'),
                buttonUrl: `/${teamName.value}/projects/${projectKey.value}`
              }, null, 8, ["title", "description", "buttonTitle", "buttonUrl"])
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (canUpdateProject.value && loadProjectManagementState.value.status === 'loading')
        ? (_openBlock(), _createBlock(PreLoaderSection, { key: 5 }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["title", "projectName"]))
}
}

})