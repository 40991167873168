import type { ActionContext } from 'vuex';
import type { Filter } from '@/types/Axios.type';
import type { RootState } from '@/store/type';
import type { BumperDisplay, BumperDisplayListState } from '@/modules/bumperDisplay/types';
import type { LoadingState } from '@/types/State.type';
import { setStateLoadingStatusByType } from '@/modules/shared/utils/stateManagement';
import { formatErrorObject } from '@/modules/shared/utils/errorFormatter';
import { loadBumperDisplays } from '../services';

type BumperDisplayListActionContext = ActionContext<BumperDisplayListState, RootState>;

const initialState = (): BumperDisplayListState => ({
  bumperDisplayList: [],
  loadBumperDisplayListState: setStateLoadingStatusByType(),
  lastPage: 1,
});

const state = initialState();

const mutations = {
  setBumperDisplayList(state: BumperDisplayListState, bumperDisplayList: BumperDisplay[]) {
    state.bumperDisplayList = bumperDisplayList;
  },
  setBumperDisplayListLastPage(state: BumperDisplayListState, page: number) {
    state.lastPage = page;
  },
  setLoadBumperDisplayListState(state: BumperDisplayListState, loadingState: LoadingState) {
    state.loadBumperDisplayListState = setStateLoadingStatusByType(loadingState);
  },
  clearBumperDisplayListState() {
    Object.assign(state, initialState());
  },
};

const actions = {
  async loadBumperDisplayList({ commit }: BumperDisplayListActionContext, filter: Filter) {
    try {
      commit('setLoadBumperDisplayListState', { type: 'loading' });
      const response = await loadBumperDisplays(filter);
      commit('setBumperDisplayList', response.data?.data);
      commit('setBumperDisplayListLastPage', response.data?.lastPage);
      commit('setLoadBumperDisplayListState', { type: 'success' });
    } catch (error) {
      commit('setLoadBumperDisplayListState', {
        type: 'error',
        error: formatErrorObject(error, 'BumperDisplay'),
      });
    }
  },
  clearBumperDisplayListState({ commit }: BumperDisplayListActionContext) {
    commit('clearBumperDisplayListState');
  },
};

export default {
  state,
  actions,
  mutations,
};
