<template>
  <Modal
    @modalClose="onMinimize"
    :primaryButtonText="t('video.upload.summaryModal.button')"
    :onClickPrimaryButton="onMinimize"
    :title
    :isShowSecondaryButton="false"
    size="large"
  >
    <template v-slot:body>
      <div class="upload-summary-modal-container">
        <Alert priority="info" :alertText="t('video.upload.summaryModal.description')" />
        <div class="list-container">
          <ExtendedVideoUploadProgressItem
            v-for="video in videosUploadByKey"
            :key="video.key"
            :video="video"
          />
        </div>
      </div>
    </template>
  </Modal>
</template>

<script setup lang="ts">
import _ from 'lodash';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import Alert from '@/modules/shared/components/atoms/alert/Alert.vue';
import Modal from '@/modules/shared/components/organisms/modal/Modal.vue';
import { getQueryAsString } from '@/modules/shared/utils/query';
import { isVideoProbeNotComplete } from '@/modules/videoDetail/utils/videoManagement';
import { useRoute, useRouter } from 'vue-router';
import ExtendedVideoUploadProgressItem from '../../molecules/extendedVideoUploadProgressItem/ExtendedVideoUploadProgressItem.vue';
import { useStore } from '../../../store/hooks';
import { getAmountOfUploadingVideos, getUploadVideoKeysByStatus } from '../../../utils';

const store = useStore();
const router = useRouter();
const route = useRoute();
const { t } = useI18n();

const teamName = computed(() => getQueryAsString(route.params, 'teamName'));
const projectKey = computed(() => getQueryAsString(route.params, 'projectKey'));

const videoUploadKeys = computed(() => store.state.videoUpload.videoUploadKeys);
const isDisplayProbeStatus = computed(() => store.state.videoUpload.isDisplayProbeStatus);
const videosUploadByKey = computed(() => store.state.videoUpload.videosUploadByKey);
const videoKeysGroupByState = computed(() => store.state.videoUpload.videoKeysGroupByState);
const isFirstTimeUploadSummaryMinimized = computed(() => store.state.videoUpload.isFirstTimeUploadSummaryMinimized);

const amountOfUploaded = computed(() => {
  const uploadedItemKeys = getUploadVideoKeysByStatus(videoKeysGroupByState.value, 'uploaded');
  if (uploadedItemKeys) {
    if (!isDisplayProbeStatus.value) {
      return uploadedItemKeys.length;
    }

    const probeComplete = _.filter(uploadedItemKeys, (videoKey) => {
      const video = videosUploadByKey.value[videoKey];
      if (!isVideoProbeNotComplete(video)) {
        return videoKey;
      }
      return false;
    });

    return probeComplete.length;
  }
  return 1;
});

const amountOfQueued = computed(() => {
  const queuedItems = getUploadVideoKeysByStatus(videoKeysGroupByState.value, 'queued');
  return queuedItems?.length ?? 0;
});

const amountOfUploading = computed(() => {
  const uploadingItems = getUploadVideoKeysByStatus(videoKeysGroupByState.value, 'uploading');
  return uploadingItems?.length ?? 0;
});

const title = computed(() => {
  const total = videoUploadKeys.value.length;

  if (getAmountOfUploadingVideos(amountOfQueued.value, amountOfUploading.value)) {
    return t(`video.uploading.current${amountOfUploading.value > 1 ? '_plural' : ''}`, { count: amountOfUploading.value });
  }

  if (amountOfUploaded.value !== total) {
    return t(`video.uploading.uploaded${amountOfUploaded.value > 1 ? '_plural' : ''}`, { count: amountOfUploaded.value, total });
  }

  return t(`video.uploading.done${amountOfUploaded.value > 1 ? '_plural' : ''}`, { count: amountOfUploaded.value });
});

function onMinimize() {
  store.commit('setIsUploadSummaryMinimized', true);
  if (!isFirstTimeUploadSummaryMinimized.value) {
    router.push(`/${teamName.value}/projects/${projectKey.value}`);

    /**
     * The traditional uploading popup on the bottom-right of the screen will be displayed
     * after this modal is minimized just at the first time.
     */
    store.commit('setIsFirstTimeUploadSummaryMinimized', true);
  }
}
</script>

<style scoped lang="scss">
@import '~@/assets/scss/global-variables.scss';
@import '~@/assets/scss/themes/helpers';

:deep(.alert-container) {
  margin-bottom: 0;
}

:deep(.alert-text) {
  font-weight: 500;
}

.list-container {
  @extend .scrollbar;
  /* USE PADDING TO LET TOOLTIPS VISIBLE */
  padding-top: $spacing-32;
  max-height: 50vh;
  overflow-x: hidden;
  overflow-y: auto;

  > :deep(div:not(:last-child)) {
    margin-bottom: $spacing-8;
  }
}
</style>
