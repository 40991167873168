import { playbackChannelList } from '@/modules/playbackChannel';
import type { RouteRecordRaw } from 'vue-router';
import store from '@/store';
import { teamSetting } from '@/modules/teamSetting';
import { user } from '@/modules/user';
import project from '@/modules/shared/store/project';
import { geoblockRuleList } from '@/modules/geoblock';

export function createTeamSettingRoutes(): RouteRecordRaw[] {
  return [
    {
      name: 'manageTeamSettings',
      path: 'team-settings',
      meta: { teamManagement: true },
      component: () => import(/* webpackChunkName: "ManageTeamSettings" */ '@/modules/teamSetting/pages/ManageTeamSettings.vue'),
      beforeEnter: (to, from, next) => {
        if (!store.hasModule('user')) {
          store.registerModule('user', user);
        }
        if (!store.hasModule('teamSetting')) {
          store.registerModule('teamSetting', teamSetting);
        }
        if (!store.hasModule('geoblockRuleList')) {
          store.registerModule('geoblockRuleList', geoblockRuleList);
        }
        if (!store.hasModule('playbackChannelList')) {
          store.registerModule('playbackChannelList', playbackChannelList);
        }
        if (!store.hasModule('project')) {
          store.registerModule('project', project);
        }
        next();
      },
    },
  ];
}
