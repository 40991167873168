import type { RouteRecordRaw } from 'vue-router';
import store from '@/store';
import { user } from '@/modules/user';
import captionProfileList from '../store';

export function createCaptionProfileRoutes(): RouteRecordRaw[] {
  return [
    {
      path: 'subtitle-profiles',
      component: () => import(/* webpackChunkName: "manageSubtitleProfileChrome" */ '../layouts/ManageCaptionProfileChrome.vue'),
      meta: { teamManagement: true },
      beforeEnter: (to, from, next) => {
        if (!store.hasModule('captionProfileList')) {
          store.registerModule('captionProfileList', captionProfileList);
        }
        if (!store.hasModule('user')) {
          store.registerModule('user', user);
        }
        next();
      },
      children: [
        {
          path: '',
          name: 'subtitleProfiles',
          component: () => import(/* webpackChunkName: "manageSubtitleProfiles" */ '../pages/ManageCaptionProfiles.vue'),
        },
      ],
    },
  ];
}
