import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "format-label label" }
const _hoisted_2 = {
  class: "format",
  "data-test": "preset-profile-format-type"
}
const _hoisted_3 = {
  class: "resolution-container",
  "data-test": "preset-profile-resolution-list"
}

import { toRefs } from 'vue';
import { useI18n } from 'vue-i18n';
import {
  getEnabledResolutions,
  isTrackEnableVideoEncryption,
} from '@/modules/preset/utils/presetManagement';
import Badge from '@/modules/shared/components/atoms/badge/Badge.vue';
import { DefaultVideoTrack, PresetProfile, VideoTrack } from '@/modules/preset/types';

interface PresetSummaryInfoProps {
  profile: PresetProfile | null | undefined;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'PresetSummaryInfo',
  props: {
    profile: {}
  },
  setup(__props: any) {

const props = __props;
const { profile } = toRefs(props);

const { t } = useI18n();

function getBadgeTitle(track: VideoTrack | DefaultVideoTrack): string {
  let result = track.name;

  if (isTrackEnableVideoEncryption(track)) {
    result = `${result} ${t('glossary:encrypted')}`;
  }

  return result;
}

return (_ctx: any,_cache: any) => {
  return (_unref(profile))
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(["preset-profile", { disabled: !_unref(profile).enabled }])
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createTextVNode(_toDisplayString(_unref(t)('common:videoFormat')) + ": ", 1),
          _createElementVNode("span", _hoisted_2, _toDisplayString(_unref(profile).formatType), 1)
        ]),
        _createElementVNode("div", _hoisted_3, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(getEnabledResolutions)(_unref(profile).videoTracks), (track) => {
            return (_openBlock(), _createBlock(Badge, {
              key: track.name,
              title: getBadgeTitle(track)
            }, null, 8, ["title"]))
          }), 128))
        ])
      ], 2))
    : _createCommentVNode("", true)
}
}

})