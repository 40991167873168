import qs from 'qs';
import { axiosAPI } from '@/plugins/axios';
import { Filter, ListApiResponse } from '@/types/Axios.type';
import { Watermark } from '@/modules/videoUpload/types/watermark';
import type {
  AudioTrack, Codec, Preset, PresetFormat, DefaultPreset, ResolutionItem,
} from '../types';

export function createPreset(payload: Preset | DefaultPreset) {
  return axiosAPI.post('presets', payload);
}

export function loadPresets(filter: Filter) {
  const query = qs.stringify(filter || null, { addQueryPrefix: true, skipNulls: true });
  return axiosAPI.get<ListApiResponse<Preset[]>>(`/presets${query || ''}`);
}

export function loadPresetDetail(presetId: string) {
  return axiosAPI.get<Preset>(`/presets/${presetId}`);
}

export function loadOverlayPresets() {
  return axiosAPI.get<ListApiResponse<Watermark[]>>('/overlay-presets');
}

export function updatePreset(presetId: string, payload: Preset | DefaultPreset) {
  return axiosAPI.put(`/presets/${presetId}`, payload);
}

export function updatePresetName(presetId: string, payload: { name: string }) {
  return axiosAPI.put(`/presets/${presetId}/name`, payload);
}

export function loadResolutions() {
  return axiosAPI.get<ResolutionItem[]>('/presets/resolutions');
}

export function loadAudioTracks() {
  return axiosAPI.get<AudioTrack[]>('/presets/audio-tracks');
}

export function loadCodecs() {
  return axiosAPI.get<Codec[]>('/presets/codecs');
}

export function loadFormats() {
  return axiosAPI.get<PresetFormat[]>('/presets/formats');
}
