<template>
  <Modal
    class="choose-bumper-modal"
    size="large"
    :primaryButtonText="primaryButtonText"
    :onClickPrimaryButton="onClickPrimaryButton"
    :isDisabledPrimaryButton="isDisabledPrimaryButton"
    :secondaryButtonText="secondaryButtonText"
    :onClickSecondaryButton="onClickSecondaryButton"
    @modalClose="onClose"
  >
    <template v-slot:body>
      <div v-if="!isSingleStep" class="step-container">
        <div class="step-item" :class="{ current: currentStep === 1 }">
          <div class="step-number">1</div>
          <div class="step-title">{{ t('bumperSchedule.chooseBumper.step.choose') }}</div>
        </div>
        <div class="step-item" :class="{ current: currentStep === 2 }">
          <div class="step-number">2</div>
          <div class="step-title">{{ t('bumperSchedule.chooseBumper.step.type') }}</div>
        </div>
      </div>
      <div v-if="currentStep === 1" class="content-container">
        <p class="section-title">
          {{ t('bumperSchedule.chooseBumper.title', { name: contentTitle }) }}
        </p>
        <div class="search-box-container">
          <Input
            v-model="searchTerm"
            :onInput="onSearch"
            :placeholder="t('common:form.searchBy')"
            isSearchInput
          />
        </div>
        <PreLoaderSection
          v-if="loadBumpersState?.status === 'loading'"
          :options="{ height: 350 }"
        />
        <template v-if="loadBumpersState?.status === 'success'">
          <div v-if="isContentEmpty && searchTerm" class="bumper-list">
            <EmptySearch :keyword="searchTerm" :onClearSearch="onClearSearch" />
          </div>
          <div v-else-if="isContentEmpty"></div>
          <div v-if="!isContentEmpty" class="bumper-list">
            <div>
              <Row
                vertical-align="center"
                class="bumper-item"
                @click="onClickBumper(bumper)"
                :class="{ selected: selectedBumper?.id === bumper.id }"
                v-for="bumper in bumpers"
                :key="bumper.id"
              >
                <Column width="86px">
                  <div class="video-image">
                    <ImageView :source="getVideoUploadCoverImage({
                      video: bumper.video,
                      currentUser
                    })" />
                    <div class="video-duration" v-if="bumper.video?.duration">
                      {{ getVideoDuration(bumper.video) }}
                    </div>
                  </div>
                </Column>
                <Column minWidth="150px">
                  <TextCell :text="bumper.name" class="bumper-title" />
                </Column>
                <Column width="80px">
                  <Badge
                    :title="bumper.visibility?.status"
                    :badgeStyle="getBadgeStyle(bumper)"
                    class="status-text"
                  />
                </Column>
                <Column width="160px">
                  <div class="bumper-date">
                    <div>
                      <p>
                        <span class="bumper-pad">{{ t('bumper.start.title') }}</span
                        >:
                        {{ bumper.visibilitySetting?.startPublishAt ? formatVideoDateTime(bumper.visibilitySetting?.startPublishAt) : '' }}
                      </p>
                      <p>
                        <span class="bumper-pad">{{ t('bumper.end.title') }}</span
                        >:
                        {{
                          bumper.visibilitySetting?.endPublishAt
                            ? formatVideoDateTime(bumper.visibilitySetting?.endPublishAt)
                            : '-'
                        }}
                      </p>
                    </div>
                  </div></Column
                >
                <Column width="100px" horizontal-align="right">
                  <Badge
                    v-if="calculateBumperPeriod(bumper)"
                    :title="calculateBumperPeriod(bumper)"
                    theme="dark"
                  /></Column>
              </Row>
            </div>
          </div>
        </template>
        <div></div>
        <Pagination :lastPage="bumperLastPage" v-if="bumperLastPage > 1" />
      </div>
      <template v-if="currentStep === 2 && selectedBumper">
        <div class="section">
          <p class="section-title">Bumper</p>
          <Row vertical-align="center" class="bumper-item no-hover-action">
            <Column width="86px">
              <div class="video-image">
                <ImageView :source="getVideoUploadCoverImage({
                  video: selectedBumper.video,
                  currentUser
                })" />
                <div class="video-duration" v-if="selectedBumper.video?.duration">
                  {{ getVideoDuration(selectedBumper.video) }}
                </div>
              </div>
            </Column>
            <Column minWidth="150px">
              <TextCell :text="selectedBumper.name" class="bumper-title" />
            </Column>
            <Column width="80px">
              <Badge
                :title="selectedBumper.visibility?.status"
                :badgeStyle="getBadgeStyle(selectedBumper)"
                class="status-text"
              />
            </Column>
            <Column width="160px">
              <div class="bumper-date">
                <div class="dates">
                  <p class="start-date">
                    <span class="bumper-pad">{{ t('bumper.start.title') }}</span
                    >:
                    {{ formatVideoDateTime(selectedBumper.visibilitySetting?.startPublishAt) }}
                  </p>
                  <p class="end-date">
                    <span class="bumper-pad">{{ t('bumper.end.title') }}</span
                    >:
                    {{
                      selectedBumper.visibilitySetting?.endPublishAt
                        ? formatVideoDateTime(selectedBumper.visibilitySetting?.endPublishAt)
                        : '-'
                    }}
                  </p>
                </div>
              </div>
            </Column>
            <Column width="100px" horizontal-align="right">
              <Badge v-if="calculateBumperPeriod(selectedBumper)" :title="calculateBumperPeriod(selectedBumper)" theme="dark"
              /></Column>
          </Row>
        </div>
        <div class="section">
          <p class="section-title">{{ t('bumperSchedule.chooseBumper.setAs') }}</p>
          <RadioButtonGroup
            :options="setAsOptions"
            :modelValue="selectedOption"
            :on-change="onClickRadio"
          />
        </div>
      </template>
    </template>
    <template v-slot:primary-btn-suffix v-if="primaryButtonText === t('common:button.next')">
      <i class="fas fa-chevron-right" />
    </template>
    <template v-slot:secondary-btn-prefix v-if="secondaryButtonText === t('common:button.back')">
      <i class="fas fa-chevron-left" />
    </template>
    <template v-slot:footer>
      <Button
        v-if="currentStep !== 1 && !isSingleStep"
        buttonStyle="text-secondary"
        @click="$emit('modalClose')"
      >
        {{ t('common:button.cancel') }}</Button
      >
    </template>
  </Modal>
</template>

<script lang="ts" setup>
import _ from 'lodash';
import { Bumper } from '@/modules/bumper/types';
import { BumperDisplayPosition, ChooseBumperModalSubmitPayload, SelectedContent } from '@/modules/bumperDisplay/types';
import {
  computed, onMounted, ref, toRefs, watch,
} from 'vue';
import Modal from '@/modules/shared/components/organisms/modal/Modal.vue';
import RadioButtonGroup from '@/modules/shared/components/molecules/radioButtonGroup/RadioButtonGroup.vue';
import { formatVideoDateTime } from '@/modules/shared/utils/dateFormatter';
import { getVideoUploadCoverImage, getVideoDuration } from '@/modules/videoDetail/utils/videoManagement';
import { useBumper } from '@/modules/bumper/utils';
import Button from '@/modules/shared/components/atoms/button/Button.vue';
import Input from '@/modules/shared/components/atoms/input/Input.vue';
import ImageView from '@/modules/shared/components/atoms/imageView/ImageView.vue';
import Row from '@/modules/shared/components/molecules/row/Row.vue';
import Column from '@/modules/shared/components/molecules/column/Column.vue';
import TextCell from '@/modules/shared/components/molecules/textCell/TextCell.vue';
import PreLoaderSection from '@/modules/shared/components/atoms/preLoaderSection/PreLoaderSection.vue';
import Pagination from '@/modules/shared/components/atoms/pagination/Pagination.vue';
import Badge from '@/modules/shared/components/atoms/badge/Badge.vue';
import EmptySearch from '@/modules/shared/components/molecules/emptySearch/EmptySearch.vue';
import { useI18n } from 'vue-i18n';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from '../../../store/hooks';

interface ChooseBumperModalProps {
  bumper?: Bumper | null;
  categoryName?: string;
  isAlreadyChooseCategory?: boolean;
  disabledCategories?: string[];
}

const props = withDefaults(defineProps<ChooseBumperModalProps>(), {
  isAlreadyChooseCategory: false,
  disabledCategories: () => [],
});

const {
  bumper, categoryName, isAlreadyChooseCategory, disabledCategories,
} = toRefs(props);

const emit = defineEmits<{
  modalClose: [];
  submit: [value: ChooseBumperModalSubmitPayload];
}>();

const { t } = useI18n();
const store = useStore();
const route = useRoute();
const router = useRouter();

interface SetAsOption {
  label: string;
  description: string;
  value: BumperDisplayPosition;
  disabled: boolean;
}

const currentStep = ref<number>(1);
const selectedBumper = ref<Bumper | null>(null);
const searchTerm = ref<string>('');
const selectedOption = ref<BumperDisplayPosition | null>(null);
const setAsOptions = ref<SetAsOption[]>([
  {
    label: 'Intro 1',
    description: disabledCategories.value.includes('intro1') ?
      t('bumperSchedule.position.changeToCustom', { position: 'Intro 1' })
      : `
          <li>${t('bumperSchedule.position.intro')}</li>
          <li>${t('bumperSchedule.position.nonAds')}</li>
          <li>${t('bumperSchedule.position.nonSkippable')}</li>
          `,
    value: 'intro1',
    disabled: false,
  },
  {
    label: 'Intro 2',
    description: disabledCategories.value.includes('intro2') ?
      t('bumperSchedule.position.changeToCustom', { position: 'Intro 2' })
      : `
          <li>${t('bumperSchedule.position.intro')}</li>
          <li>${t('bumperSchedule.position.ads')}</li>
          <li>${t('bumperSchedule.position.skippable')}</li>
      `,
    value: 'intro2',
    disabled: false,
  },
  {
    label: 'Outro 1',
    description: disabledCategories.value.includes('outro1') ?
      t('bumperSchedule.position.changeToCustom', { position: 'Outro 1' })
      : `
          <li>${t('bumperSchedule.position.outro')}</li>
          <li>${t('bumperSchedule.position.ads')}</li>
          <li>${t('bumperSchedule.position.skippable')}</li>
      `,
    value: 'outro1',
    disabled: false,
  },
  {
    label: 'Outro 2',
    description: disabledCategories.value.includes('outro2') ?
      t('bumperSchedule.position.changeToCustom', { position: 'Outro 2' })
      : `
          <li>${t('bumperSchedule.position.intro')}</li>
          <li>${t('bumperSchedule.position.nonAds')}</li>
          <li>${t('bumperSchedule.position.nonSkippable')}</li>
      `,
    value: 'outro2',
    disabled: false,
  },
]);

const currentUser = computed(() => store.state.user.currentUser);
const selectedContent = computed<SelectedContent>(() => store.state.bumperDisplay.selectedContent);
const loadBumpersState = computed(() => store.state.bumperList.loadBumperListState);
const saveBumperDisplayState = computed(() => store.state.bumperDisplay.saveBumperDisplayState);
const bumpers = computed<Bumper[]>(() => store.state.bumperList.bumperList);
const bumperLastPage = computed(() => store.state.bumperList.lastPage);
const query = computed(() => route.query);
const isSingleStep = computed(() => isAlreadyChooseCategory.value || bumper.value);

const isContentEmpty = computed(() => _.isEmpty(bumpers.value));
const contentTitle = computed(() => {
  let { content } = selectedContent.value;

  if (selectedContent.value.content?.content) {
    content = selectedContent.value.content.content;
  }

  return content.title || content.name;
});

const primaryButtonText = computed(() => {
  if (currentStep.value === 2 || isSingleStep.value) {
    return `${t('bumper.add')}`;
  }
  return t('common:button.next');
});

const secondaryButtonText = computed(() => {
  if (currentStep.value === 2) {
    if (isSingleStep.value) {
      return t('common:button.cancel');
    }
    return t('common:button.back');
  }
  return t('common:button.cancel');
});

const isDisabledPrimaryButton = computed(() => {
  if (currentStep.value === 1) {
    return !selectedBumper.value;
  }
  return !selectedBumper.value || !selectedOption.value;
});

function load(filter = {}) {
  store.dispatch('loadBumperList', { ...filter, status: 'active,scheduled,draft' });
}

function onClose() {
  emit('modalClose');
}

function onClickPrimaryButton() {
  if (currentStep.value === 1 && !isSingleStep.value) {
    currentStep.value = 2;
  } else if (selectedBumper.value && !_.isNil(selectedOption.value)) {
    emit('submit', {
      type: selectedOption.value ?? '',
      bumper: selectedBumper.value,
    });
  }
}

function onClickSecondaryButton() {
  if (currentStep.value !== 1 && !isSingleStep.value) {
    currentStep.value -= 1;
  } else {
    emit('modalClose');
  }
}

function onSearch() {
  router.push({ query: { q: searchTerm.value } });
}

function onClearSearch() {
  load();
  searchTerm.value = '';
}

function onClickBumper(selected: Bumper) {
  selectedBumper.value = selected;
}

function onClickRadio(selected: BumperDisplayPosition) {
  selectedOption.value = selected;
}

function calculateBumperPeriod(selected: Bumper | null): string {
  if (!selected) {
    return '';
  }

  const { calculatePeriod } = useBumper();
  const result = calculatePeriod(selected);

  if (result) {
    return `${result} ${t(`glossary:day${result > 1 ? '_plural' : ''}`)}`;
  }

  return '';
}

function getBadgeStyle(selected: Bumper) {
  const { getStatusBadgeStyle } = useBumper();
  return getStatusBadgeStyle(selected);
}

watch(saveBumperDisplayState, () => {
  if (saveBumperDisplayState.value.status === 'success') {
    router.push({ name: 'manageBumperDisplay' });
  }
}, { deep: true });

watch(disabledCategories, () => {
  setAsOptions.value = setAsOptions.value.map((option) => ({
    ...option,
    disabled: disabledCategories.value.includes(option.value),
  }));
}, { immediate: true });

watch(query, (value) => load(value), { deep: true });

onMounted(() => {
  load();

  if (bumper.value) {
    selectedBumper.value = bumper.value;
    currentStep.value = 2;
  }

  if (categoryName.value) {
    const found = setAsOptions.value.find((option) => option.label === categoryName.value);

    if (found) {
      selectedOption.value = found.value;
    }
  }
});
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/global-variables.scss';
@import '~@/assets/scss/breakpoint.scss';
@import '~@/assets/scss/themes/helpers.scss';

:deep(.modal-body) {
  gap: $spacing-32;
}

.content-container {
  width: 100%;
}

.search-box-container {
  margin-bottom: $spacing-base;
}

.video-image {
  cursor: pointer;
  position: relative;
  width: 100%;
  .video-duration {
    position: absolute;
    bottom: 0;
    right: 0;
    color: #ffffff;
    font-size: $font-level-7;
    background-color: rgba(0, 0, 0, 0.75);
    padding: $spacing-4 6px;
  }
}

.step-container {
  display: flex;
  width: 100%;
  text-align: center;
  justify-content: center;

  .step-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    &:not(:last-child) {
      &::after {
        display: block;
        //position: relative;
        //top: -3px;
        margin: 0px $spacing-12;
        content: '';
        width: 60px;
        height: 2px;
        background-color: $grey-400;
      }
    }
  }

  .step-number {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    border: 1px solid $grey-400;
    color: $grey-400;
    width: 24px;
    height: 24px;
    margin-right: $spacing-8;
  }

  .step-title {
    color: $grey-400;
    font-size: $font-level-6;
  }

  .current {
    .step-number {
      border-color: $ci-primary;
      background-color: $ci-primary;
      color: #fff;
    }

    .step-title {
      font-weight: $font-weight-bold;
      color: $grey-800;
    }
  }
}

.section {
  width: 100%;
}

.section-title {
  color: $grey-800;
  font-size: $font-level-6;
  font-weight: $font-weight-bold;
  margin-bottom: $spacing-base;
}

.bumper-list {
  height: 350px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  margin-bottom: $spacing-base;
}

.bumper-item {
  border: 1px solid $grey-200;
  background-color: #ffffff;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: $spacing-4;

  .bumper-title {
    font-weight: $font-weight-bold;
    color: $grey-800;
    margin-bottom: 0;
  }

  .status-text {
    text-transform: capitalize;
    .draft{
      color: $grey-400;
    }
  }

  .bumper-date {
    display: flex;
    justify-content: space-between;
    align-items: center;

    p {
      color: $grey-600;
      margin-bottom: 0;
    }

    .bumper-pad {
      display: inline-block;
    }
  }

  &:hover {
    background-color: $grey-200;
  }

  &.selected {
    border-color: $ci-primary;
    background-color: #e9f2fb;
  }

  &.no-hover-action {
    cursor: default;
    background-color: #fff;
    margin-bottom: 24px;
    pointer-events: none;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

:deep(.option-wrapper) {
  .description {
    display: block !important;
  }
}

.bumper-image {
  cursor: pointer;
  position: relative;
  flex: 0 0 102px;
  margin-right: 12px;

  &.small {
    flex: 0 0 86px;
  }

  .video-duration {
    position: absolute;
    bottom: 0;
    right: 0;
    color: $grey-200;
    font-size: $font-level-8;
    background-color: rgba(0, 0, 0, 0.75);
    padding: 4px 6px;
  }
}

.bumper-text-container {
  flex: 1 0 auto;
  .bumper-remark {
    margin-top: $spacing-4;
    font-size: $font-level-8;
  }
  .bumper-title {
    font-weight: $font-weight-bold;
    color: $grey-800;
    margin-bottom: 0;
  }
}

.radio-container {
  > :deep(div) {
    margin-bottom: 8px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  :deep(.title) {
    position: relative;
    width: fit-content;

    &::after {
      content: '\f05a';
      font-family: 'Font Awesome 5 Free';
      font-weight: 900;
      margin-left: 4px;
    }

    &:hover ~ .tooltip-text {
      visibility: visible;
    }
  }

  :deep(.option-description) {
    padding-inline-start: 0;
    padding-left: 16px;
    margin-top: 4px;
    margin-bottom: 0;
  }

  .tooltip-text {
    position: absolute;
    top: 42px;
    left: -20px;
    visibility: hidden;
    width: 208px;
    background-color: $grey-900;
    color: #fff;
    border-radius: 5px;
    padding: 8px 16px;
    z-index: 1;

    &.outro1,
    &.outro2 {
      left: -14px;
    }

    &::after {
      content: '';
      position: absolute;
      bottom: 100%;
      left: 50%;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: transparent transparent $grey-900 transparent;
    }
  }
}

@media screen and (max-width: $max-layout-xxl) {
  .content-container {
    overflow-y: auto;
  }
}
</style>
