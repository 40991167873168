<template>
  <div class="list-video-badword">
    <Alert
      v-if="loadProjectVideoCaptionIssueListState.status === 'success' && !isEmpty"
      :alertText="t('videoIssues.alert')"
      priority="info"
    />
    <Section v-if="loadProjectVideoCaptionIssueListState.status === 'success'">
      <DataTable
        :onSearchData="onSearchVideo"
        :searchInputValue="q"
        :searchInputPlaceholder="t('videos.searchBy')"
      >
        <template v-slot:table-header v-if="!isEmpty">
          <Row>
            <Column width="120px" />
            <Column minWidth="200px">
              <HeaderCell :text="t('videoIssues.table.videoInfo')" />
            </Column>
            <Column width="140px">
              <HeaderCell text="Video Key" />
            </Column>
            <Column>
              <HeaderCell :text="t('videoIssues.table.summary')" />
            </Column>
            <Column width="120px"></Column>
          </Row>
        </template>
        <template v-slot:table-content v-if="!isEmpty">
          <div class="video-list">
            <router-link
              :to="`/${teamName}/projects/${projectKey}/videos/${video.key}/subtitles`"
              v-for="video in videoList"
              :key="video.id"
            >
              <Row vertical-align="center">
                <Column width="120px">
                  <ImageView :source="video.coverImage?.size" imageSize="small" />
                </Column>
                <Column minWidth="200px">
                  <TextCell :primaryText="video.title" />
                </Column>
                <Column width="140px">
                  <TextCell :text="video.key" />
                </Column>
                <Column>
                  <div v-if="video.issue?.captionsWithBadWordIssueCount" class="subtitle-issue">
                    <span>
                      {{t('videoIssues.table.badWordsFound')}}
                      <span class="bad-word-count">
                        {{ video.issue?.captionsWithBadWordIssueCount }}
                        {{
                          video.issue?.captionsWithBadWordIssueCount > 1 ? 'Subtitles' : 'Subtitle'
                        }}
                        <i class="fa fa-warning" />
                      </span>
                    </span>
                  </div>
                </Column>
                <Column width="120px" isActionColumn>
                  <Button buttonStyle="text-secondary">
                    {{t('videoIssues.table.view')}}
                    <template v-slot:icon-suffix>
                      <i class="fa fa-chevron-right" />
                    </template>
                  </Button>
                </Column>
              </Row>
            </router-link>
          </div>
        </template>
        <template v-slot:table-footer v-if="lastPage > 1">
          <Pagination :lastPage="lastPage" :page="route.query.page || 1" />
        </template>
      </DataTable>
      <EmptySearch v-if="isEmptySearch" />
      <MessageBox
        iconClassName="fas fa-check-circle"
        :title="t('videoIssues.noIssue')"
        v-if="isEmpty && !isEmptySearch"
      />
    </Section>
    <Section v-if="loadProjectVideoCaptionIssueListState.status === 'error'">
      <ErrorMessage
        :statusCode="loadProjectVideoCaptionIssueListState.error?.statusCode"
        :title="loadProjectVideoCaptionIssueListState.error?.message"
      />
    </Section>
    <PreLoaderSection v-if="loadProjectVideoCaptionIssueListState.status === 'loading'" />
  </div>
</template>

<script setup lang="ts">
import { getQueryAsString } from '@/modules/shared/utils/query';
import { computed, watch, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute, useRouter } from 'vue-router';
import Section from '@/modules/shared/components/organisms/section/Section.vue';
import DataTable from '@/modules/shared/components/organisms/dataTable/DataTable.vue';
import Row from '@/modules/shared/components/molecules/row/Row.vue';
import Column from '@/modules/shared/components/molecules/column/Column.vue';
import HeaderCell from '@/modules/shared/components/molecules/headerCell/HeaderCell.vue';
import TextCell from '@/modules/shared/components/molecules/textCell/TextCell.vue';
import Button from '@/modules/shared/components/atoms/button/Button.vue';
import ImageView from '@/modules/shared/components/atoms/imageView/ImageView.vue';
import PreLoaderSection from '@/modules/shared/components/atoms/preLoaderSection/PreLoaderSection.vue';
import ErrorMessage from '@/modules/shared/components/atoms/errorMessage/ErrorMessage.vue';
import Pagination from '@/modules/shared/components/atoms/pagination/Pagination.vue';
import EmptySearch from '@/modules/shared/components/molecules/emptySearch/EmptySearch.vue';
import MessageBox from '@/modules/shared/components/molecules/messageBox/MessageBox.vue';
import Alert from '@/modules/shared/components/atoms/alert/Alert.vue';
import { useStore } from '../store/hooks';

const searchQuery = ref<{ q?: string; page?: number }>({});
const { t } = useI18n();
const store = useStore();
const route = useRoute();
const router = useRouter();

// store
const videoList = computed(
  () => store.state.projectVideoCaptionIssueList.projectVideoCaptionIssueList,
);
const loadProjectVideoCaptionIssueListState = computed(
  () => store.state.projectVideoCaptionIssueList.loadProjectVideoCaptionIssueListState,
);
const lastPage = computed(
  () => store.state.projectVideoCaptionIssueList.projectVideoCaptionIssueListLastPage,
);

const query = computed(() => route.query);
const teamName = computed(() => route.params.teamName);
const projectKey = computed(() => route.params.projectKey);
const q = computed(() => getQueryAsString(route.query, 'q') ?? '');
const isEmpty = computed(() => !(videoList.value && videoList.value.length));
const isEmptySearch = computed(() => isEmpty.value && query.value.q);

const onSearchVideo = (event: any) => {
  searchQuery.value.q = event.target.value;
  router.push({ query: searchQuery.value });
};

watch(
  query,
  () => {
    store.dispatch('loadProjectVideoCaptionIssueList', {
      ...query.value,
      projectKey: projectKey.value,
    });
  },
  { immediate: true },
);
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/global-variables.scss';
@import '~@/assets/scss/breakpoint.scss';

.subtitle-issue {
  display: flex;
  align-items: center;

  .bad-word-count {
    color: $warning;
    text-decoration: underline;

    .fa-warning {
      display: inline-block;
      margin-left: 0;
    }

    &:hover {
      color: $warning;
    }
  }
}

@media screen and (max-width: $max-layout-sm) {
  :deep(.table) {
    .table-header .row {
      > :not(:nth-child(2)) {
        display: none;
      }
    }
    .table-content {
      .column {
        width: calc(100% - 50px);
        padding-top: 0;
        padding-bottom: 0;
        margin-top: 0;
        margin-bottom: 0;
        min-height: unset;
        &:first-child {
          display: none;
        }
        &:nth-child(2) {
          padding-top: 0;
          padding-bottom: $spacing-12;
        }
        &:nth-child(n + 4) {
          display: none;
        }
      }
      .row {
        position: relative;
        padding: $spacing-12 0;
        &:not(:last-child) {
          border-bottom: 0.5px solid $grey-200;
        }
        .responsive-c-button {
          display: flex;
        }
      }
    }
    .row {
      flex-direction: column;
      align-items: flex-start;
    }
  }
}
</style>
