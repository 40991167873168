<template>
  <div
    :data-test="dataTest"
    class="dropdown-item"
    :disabled="disabled"
    :class="{disabled: disabled}"
    @click="onClickItem">
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    to: {
      type: String,
      default: '',
    },
    onClick: {
      type: Function,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    dataTest: {
      type: String,
      required: false,
    },
  },
  methods: {
    onClickItem(event) {
      if (this.disabled) return;

      if (this.onClick) {
        this.onClick();
        return;
      }
      if (this.to) {
        this.$router.push(this.to);
      }

      this.$emit('click', event);
    },
  },
};

</script>

<style lang="scss" scoped>
@import '~@/assets/scss/global-variables.scss';

.dropdown-item {
  display: block;
  width: 100%;
  padding: $spacing-base * 0.35 $spacing-base;
  clear: both;
  font-weight: $font-weight-base;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;

  cursor: pointer;

  font-size: $font-size-base;

  &:hover {
    background-color: #F4F6F6;
    cursor: pointer;
  }

  &.disabled {
    background: transparent;
    color: #cdd1d5;
    cursor: not-allowed;
  }
  :deep(.fas) {
    margin-right: $spacing-4;
  }
}
</style>
