import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  style: {"margin-top":"-1px"},
  class: "video-key",
  "data-test": "video-key"
}

import { ref, toRefs } from 'vue';
import { useI18n } from 'vue-i18n';
import Button from '@/modules/shared/components/atoms/button/Button.vue';
import Tooltip from '@/modules/shared/components/atoms/tooltip/Tooltip.vue';
import { copyValue } from '@/modules/shared/utils/copy';

interface VideoKeyCopyProps {
  videoKey: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'VideoKeyCopy',
  props: {
    videoKey: {}
  },
  setup(__props: any) {

const props = __props;
const { videoKey } = toRefs(props);
const { t } = useI18n();

const isVideoKeyCopied = ref<boolean>(false);

function onCopy() {
  copyValue(videoKey.value);
  isVideoKeyCopied.value = true;

  const timeout = setTimeout(() => {
    isVideoKeyCopied.value = false;
    clearTimeout(timeout);
  }, 1000);
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([{ hideTooltip: !isVideoKeyCopied.value, copy: true }, "video-key-copy"])
  }, [
    _createElementVNode("span", _hoisted_1, _toDisplayString(_unref(videoKey)), 1),
    _createVNode(Tooltip, null, {
      "tooltip-text": _withCtx(() => [
        _createTextVNode(_toDisplayString(isVideoKeyCopied.value ? _unref(t)('glossary:copied') : _unref(t)('glossary:copy')), 1)
      ]),
      default: _withCtx(() => [
        _createVNode(Button, {
          onClick: _withModifiers(onCopy, ["prevent"]),
          buttonClasses: "copyBtn",
          buttonStyle: "text-secondary",
          isSquare: ""
        }, {
          "icon-prefix": _withCtx(() => _cache[0] || (_cache[0] = [
            _createElementVNode("i", { class: "fal fa-clone icon" }, null, -1)
          ])),
          _: 1
        })
      ]),
      _: 1
    })
  ], 2))
}
}

})