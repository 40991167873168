import _ from 'lodash';
import type { Video } from '../types/video.type';

export function getVideoURL(video: Video): string {
  if (_.get(video, 'primaryPlaybackUrl.hls')) {
    return _.get(video, 'primaryPlaybackUrl.hls[0].url', '');
  }

  return _.get(video, 'primaryPlaybackUrl.mp4Playlist[0].url', '');
}

export function getEmbeddedCode(video: Video): string {
  if (video?.embeddedUrl) {
    return `<iframe src="${video.embeddedUrl}" width="560" height="320" frameborder="0" allowfullscreen></iframe>`;
  }

  return '';
}
