<template>
  <div class="step-container step-choose">
    <FormSelectOption
      :title="computedTitle"
      :modelValue="getSigningKeyById(selectedURLSigningKeyId)"
      :options="urlSigningKeyList"
      label="keyId"
      :placeholder="$t('signedURL.urlSigningKey.choose.title')"
      @update:modelValue="(newValue) => updateSelect(newValue.id)"
    />
    <div class="step-helptext" v-if="isButtonCanShow(computedSecondaryButtonTitle, computedSecondaryButtonAction)">
      <p>Don't have key you looking for ? </p>
      <Button
        type="button"
        buttonStyle="link-primary"
        buttonClasses="secondary-action-button"
        @click="computedSecondaryButtonAction">
        <template v-slot:content>
          {{ computedSecondaryButtonTitle }}
        </template>
      </Button>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapState } from 'vuex';
import { formatVideoDateTime } from '@/modules/shared/utils/dateFormatter';
import Button from '@/modules/shared/components/atoms/button/Button.vue';
import FormSelectOption from '@/modules/shared/components/molecules/formSelectOption/FormSelectOption.vue';

export default {
  props: {
    selectedURLSigningKeyId: {
      type: String,
      default: () => '',
    },
    urlSigningKeyList: {
      type: Array,
      default: () => [],
      required: true,
    },
    title: {
      type: String,
    },
    description: {
      type: String,
    },
    primaryButtonTitle: {
      type: String,
    },
    primaryButtonAction: {
      type: [Function, String],
    },
    secondaryButtonTitle: {
      type: String,
    },
    secondaryButtonAction: {
      type: [Function, String],
    },
    onClose: {
      type: Function,
      default: () => { },
    },
  },
  emits: ['update:selectedURLSigningKeyId'],
  computed: {
    ...mapState({
    }),
    teamName() {
      return this.$route.params.teamName;
    },
    isValid() {
      return this.selectedURLSigningKeyId;
    },
    computedTitle() {
      if (typeof this.title === 'string') {
        return this.title;
      }
      return 'URL Signing Key';
      // return this.$t('signedURL.urlSigningKey.choose.title');
    },
    computedPrimaryButtonTitle() {
      if (typeof this.primaryButtonTitle === 'string') {
        return this.primaryButtonTitle;
      }
      return this.$t('signedURL.urlSigningKey.choose.confirm');
    },
    computedSecondaryButtonTitle() {
      if (typeof this.secondaryButtonTitle === 'string') {
        return this.secondaryButtonTitle;
      }
      return this.$t('signedURL.urlSigningKey.create');
    },
    computedPrimaryButtonAction() {
      if (typeof this.primaryButtonAction === 'function') {
        return this.primaryButtonAction.bind(this);
      }
      return (() => {
        this.$router.push(this.primaryButtonAction);
      });
    },
    computedSecondaryButtonAction() {
      if (typeof this.secondaryButtonAction === 'function') {
        return this.secondaryButtonAction.bind(this);
      }
      if (this.secondaryButtonAction) {
        return (() => {
          this.$router.push(this.secondaryButtonAction);
        });
      }
      return (() => {
        this.$router.push(`/${this.teamName}/manage/url-signing-keys/create`);
      });
    },
    getKeyId() {
      const urlSigningKey = this.urlSigningKeyList.filter((ele) => ele.id === this.selectedURLSigningKeyId);
      if (urlSigningKey.length) {
        return urlSigningKey[0].keyId;
      }
      return this.urlSigningKeyList[0].keyId;
    },
  },
  components: {
    Button,
    FormSelectOption,
  },
  methods: {
    formatVideoDateTime,
    isButtonCanShow(title, action) {
      return title && action;
    },
    isTypeOfFunction(variable) {
      return typeof variable === 'function';
    },
    updateSelect(newValue) {
      this.$emit('update:selectedURLSigningKeyId', newValue);
    },
    getSigningKeyById(id) {
      return _.find(this.urlSigningKeyList, { id });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/global-variables.scss';
@import '~@/assets/scss/themes/helpers.scss';

.select-option-container {
  margin-bottom: $spacing-base;
}

.step-container {
  .icon {
    font-size: $font-size-base * 5;
    color: $grey-400;
    margin-bottom: $spacing-base;
  }

  .subtitle {
    font-size: $font-size-base * 1.125;
    padding: 0 $spacing-base * 0.5;
    margin-bottom: $spacing-base * 2;

    .select-sign-url {
      margin-top: $spacing-base * 1.5;
    }
  }
}
.step-helptext{
  display: flex;
  margin-top: $spacing-32;
  align-items: baseline;

  p {
    margin-right: $spacing-4;
  }
}
.button-container {
  margin-top: $spacing-base * 3;

  .button-wrapper {
    width: 100%;

    :deep(.primary-action-button) {
      margin-bottom: $spacing-base;
      margin-top: $spacing-base * 0.5;
    }
  }
}
</style>
