<template>
  <div class="section-item">
    <div class="section-item-header"
         :class="{ 'has-content' : !isUseToggle || (isUseToggle && isEnabled) }"
         v-if="title || description">
      <div class="title-container" :id="title">
        <h4 class="title" v-html="title"></h4>
        <div class="description" v-html="description" v-if="description"></div>
      </div>
      <div class="toggle-container" v-if="isUseToggle && !$slots['section-item-action']">
        <Toggle :modelValue="isEnabled" @update:modelValue="$emit('clickToggle', !isEnabled)" />
      </div>
      <div class="action" v-if="$slots['section-item-action']">
        <slot name="section-item-action" />
      </div>
    </div>
    <div class="section-item-body" v-if="!isUseToggle || (isUseToggle && isEnabled)">
      <slot />
    </div>
  </div>
</template>

<script>
import Toggle from '@/modules/shared/components/atoms/toggle/Toggle.vue';

export default {
  components: {
    Toggle,
  },
  emits: ['clickToggle'],
  props: {
    title: {
      type: String,
    },
    description: {
      type: String,
    },
    isUseToggle: {
      type: Boolean,
      default: false,
    },
    isEnabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/global-variables.scss";

.section-item-header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &.has-content {
    margin-bottom: $spacing-base;
  }
}

.title {
  font-size: $font-level-6;
  font-weight: $font-weight-bold;
  color: $grey-800;
}

.description {
  margin-top: $spacing-8;
}

.section-item-body {
  > :slotted(*):not(.modal-container, .sidebar-container) {
    margin-bottom: $spacing-24;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

</style>
