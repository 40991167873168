import _ from 'lodash';
import type { Bumper, UsedIn, VideoBumperContentsObject } from '@/modules/bumper/types';
import type { BumperDisplay } from '@/modules/bumperDisplay/types';

type CategorizedBumpers = Record<string, Array<Bumper>>;

export const useBumperDisplay = (bumperDisplayDetail: VideoBumperContentsObject | UsedIn | BumperDisplay | null) => {
  const summaryBumperContents = bumperDisplayDetail?.summaryBumperContents;

  function categorizeBumpers(): CategorizedBumpers {
    const positions: CategorizedBumpers = {
      intro1: [],
      intro2: [],
      outro1: [],
      outro2: [],
    };

    if (_.isNil(summaryBumperContents)) {
      return positions;
    }

    summaryBumperContents.forEach((item) => {
      const bumperData = item.bumper || {};
      const bumperId = bumperData?.id || item.bumperId;

      const bumperObject = {
        ...bumperData,
        id: bumperId,
        summaryBumperContents: item,
      };

      positions[item.positionName]?.push(bumperObject);
    });
    return positions;
  }

  const categorizedBumpers = categorizeBumpers();

  return {
    categorizedBumpers,
  };
};
