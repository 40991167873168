<template>
  <div class="heading-group-container">
    <div
      :style="overrideIconStyle"
      class="heading-group-icon">
      <i :class="iconClassName"></i>
    </div>
    <div class="heading-group-content">
      <div class="heading-group-content--title">
        <h4>{{ title }}</h4>
        <p>{{ subTitle }}</p>
      </div>
      <div class="heading-group-content--content">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    iconClassName: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    subTitle: {
      type: String,
      required: true,
    },
    overrideIconStyle: {
      type: Object,
      default: null,
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/global-variables.scss';
@import '~@/assets/scss/themes/helpers.scss';
@import '~@/assets/scss/breakpoint.scss';

.heading-group-container {
  display: flex;
  flex-flow: row;
  margin-bottom: 60px;
  .heading-group-icon {
    width: 64px;
    margin-right: $spacing-base * 0.5;
    i {
      font-size: 42px;
    }
    .fa-user-shield {
      margin-left: -6px;
    }
    .fa-cloud-arrow-up {
      margin-left: -6px;
    }
    .fa-video {
      margin-left: -2px;
    }
    .fa-code {
      margin-left: -5px;
    }
    .fa-key {
      margin-left: 6px;
    }
    .ml-6 {
      margin-left: -6px;
    }
    .mr-4 {
      margin-left: 4px;
      margin-right: -4px;
    }
  }
  .heading-group-content {
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    width: 90%;
    margin-top: -3px;
    .heading-group-content--title {
      h4 {
        color: #454545;
        font-size: 18px;
        margin-bottom: 0;
        text-transform: capitalize;
      }
      p {
        font-size: 14px;
        color: #555;
      }
    }
    .heading-group-content--content {
      position: relative;
    }
  }
}

@media screen and (max-width: $max-layout-sm) {
  .heading-group-container {
    .heading-group-icon {
      width: 32px;
      margin-right: $spacing-base * 0.25;
      i {
        font-size: 21px;
        margin-left: 0 !important;
        margin-right: $spacing-base / 2;
      }
    }

    .heading-group-content {
      overflow: hidden;
    }
  }
}
</style>
