import _ from 'lodash';
import i18n from '@/plugins/lang/i18n';
import type { ProjectMember } from '@/modules/projectMember';
import type { InputMember } from '@/modules/projectMember/types';
import type { User } from '@/modules/user';
import type { ObjectWithStringValue } from '@/types/Object.type';

export function getPrettyRoleName(role: string): string {
  let roleName = '';
  switch (role) {
    case 'admin':
      roleName = 'Admin';
      break;
    case 'project-manager':
      roleName = 'Project Manager';
      break;
    case 'videoUploader':
      roleName = 'Uploader';
      break;
    case 'uploader':
      roleName = 'Uploader';
      break;
    case 'viewer':
      roleName = 'Viewer';
      break;
    default:
      roleName = '-';
      break;
  }
  return roleName;
}

export function getRoleBadgeStyle(role: string): 'highlight' | 'info' | 'secondary' {
  switch (role) {
    case 'admin':
    case 'project-manager':
      return 'highlight';
    case 'videoUploader':
    case 'uploader':
      return 'info';
    case 'viewer':
      return 'secondary';
    default:
      return 'secondary';
  }
}

export const getByteArkAccountIdFromMember = (member: ProjectMember): string => (
  (member.account?.bytearkAccountUserId ?? '').toString()
);

export const isMemberInList = (memberList: ProjectMember[] | null, member: ProjectMember | InputMember): boolean => !!_.find(
  memberList,
  (item) => item.account?.bytearkAccountUserId
      === member.account?.bytearkAccountUserId,
);

export const mappingRoleToMember = (projectMembers: ProjectMember[] | null, bytearkAccountUserId: string) => {
  const projectMember = _.find(
    projectMembers,
    (member) => {
      const memberByteArkAccountUserId = getByteArkAccountIdFromMember(member);
      return memberByteArkAccountUserId === bytearkAccountUserId;
    },
  );

  if (projectMember) {
    return projectMember.projectRole;
  }

  return null;
};

export const mappingByteArkAccountToStreamAccount = (bytearkAccounts: User[], projectMembers: ProjectMember[] | null) => _.chain(bytearkAccounts)
  .map((bytearkAccount) => ({
    role: mappingRoleToMember(projectMembers, bytearkAccount.bytearkAccountUserId.toString()),
    account: bytearkAccount,
  }))
  .sortBy('role')
  .value();

export const getMembershipRolesOptions = (): ObjectWithStringValue[] => [
  {
    key: i18n.global.t('project.member.role.projectManager.title'),
    value: 'project-manager',
  },
  {
    key: i18n.global.t('project.member.role.uploader.title'),
    value: 'uploader',
  },
  {
    key: i18n.global.t('project.member.role.viewer.title'),
    value: 'viewer',
  },
];

export const getIfIsUserActivated = (member: ProjectMember | null): boolean => !_.isNil(member?.account?.activatedAt);
