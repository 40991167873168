import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "title" }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = { class: "button-container" }
const _hoisted_4 = {
  key: 0,
  class: "disabled-reason"
}

import { toRefs } from 'vue';
import type { Nullable } from '@/types/index.type';
import Button from '@/modules/shared/components/atoms/button/Button.vue';

interface DangerZoneCardProps {
  title: string;
  description: string;
  color?: 'danger' | 'warning';
  buttonIcon: string;
  buttonText: string;
  buttonDisabledReason?: Nullable<string>;
  onClickButton: () => void;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'DangerZoneCard',
  props: {
    title: {},
    description: {},
    color: { default: 'danger' },
    buttonIcon: {},
    buttonText: {},
    buttonDisabledReason: {},
    onClickButton: {}
  },
  setup(__props: any) {

const props = __props;

const {
  title,
  description,
  color,
  buttonIcon,
  buttonText,
  onClickButton,
} = toRefs(props);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['danger-zone-card', _unref(color)])
  }, [
    _createElementVNode("h3", _hoisted_1, _toDisplayString(_unref(title)), 1),
    _createElementVNode("p", {
      class: "description",
      innerHTML: _unref(description)
    }, null, 8, _hoisted_2),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(Button, {
        type: "button",
        onClick: _unref(onClickButton),
        buttonStyle: _unref(color),
        disabled: !!_ctx.buttonDisabledReason
      }, {
        "icon-prefix": _withCtx(() => [
          _createElementVNode("i", {
            class: _normalizeClass(_unref(buttonIcon))
          }, null, 2)
        ]),
        default: _withCtx(() => [
          _createTextVNode(" " + _toDisplayString(_unref(buttonText)), 1)
        ]),
        _: 1
      }, 8, ["onClick", "buttonStyle", "disabled"]),
      (_ctx.buttonDisabledReason)
        ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(_ctx.buttonDisabledReason), 1))
        : _createCommentVNode("", true)
    ])
  ], 2))
}
}

})