<template>
  <div class="step-container step-success">
    <h4 :class="{ 'computed-description': !computedDescription }">{{ computedTitle }}</h4>
    <p v-html="computedDescription" />
    <div class="formgroup-container">
      <FormInputCopy
        title="Key ID"
        :modelValue="urlSigningKey.keyId"
        elementId="keyId"
      />
      <FormInputCopy
        title="Key Secret"
        :modelValue="urlSigningKey.keySecret"
        elementId="keySecret"
      />
    </div>
    <div class="button-container">
      <div class="button-wrapper">
        <Button
          url="https://docs.byteark.com/th/stream/video-signed-url.html"
          isExternalUrl
          buttonStyle="link-primary"
          isFullWidth
          buttonClasses="guideline-link"
          target="_blank">
          <template v-slot:content>
            {{ $t('signedURL.seeGuideline') }}
          </template>
          <template v-slot:icon-suffix>
            <i class="fas fa-up-right-from-square ext-link-icon"></i>
          </template>
        </Button>
      </div>
      <div class="button-wrapper">
        <Button
          type="button"
          buttonStyle="text-secondary"
          isFullWidth
          buttonClasses="close-button"
          @click.stop="computedOnClose">
          <template v-slot:content>
            {{ $t('common:close') }}
          </template>
        </Button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import FormInputCopy from '@/modules/shared/components/molecules/formInputCopy/FormInputCopy.vue';
import Button from '@/modules/shared/components/atoms/button/Button.vue';

export default {
  props: {
    title: {
      type: String,
    },
    description: {
      type: String,
    },
    urlSigningKey: {
      type: Object,
      default: () => { },
    },
    onNexStep: {
      type: Function,
      default: () => { },
    },
    onClose: {
      type: [Function, String],
      default: () => { },
    },
  },
  computed: {
    ...mapState({
      urlSigningKeyList: (state) => state.urlSigningKeyList.urlSigningKeyList,
    }),
    computedTitle() {
      if (typeof this.title === 'string') {
        return this.title;
      }
      return this.$t('urlSigningKey.information.create');
    },
    computedDescription() {
      if (typeof this.description === 'string') {
        return this.description;
      }
      return null;
    },
    computedOnClose() {
      if (typeof this.onClose === 'function') {
        return this.onClose.bind(this);
      }
      return () => {
        this.$router.push(this.onClose);
      };
    },
  },
  components: {
    Button,
    FormInputCopy,
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/global-variables.scss";
@import "~@/assets/scss/themes/helpers.scss";

.step-container {
  .icon {
    font-size: $font-size-base * 5;
    color: $grey-400;
    margin-bottom: $spacing-base;
  }

  .subtitle {
    font-size: $font-size-base * 1.125;
    padding: 0 $spacing-base * 0.5;
    margin-bottom: $spacing-base * 2;
  }

  .formgroup-container {
    text-align: left;

    :deep(.form-input-copy) {
      margin-bottom: $spacing-24;
    }
  }

  .computed-description {
    margin-bottom: $spacing-base * 1.5;
  }
}

.button-container {
  width: 100%;
  display: flex;
  flex-direction: column;

  .button-wrapper {
    width: 100%;

    :deep(.guideline-link) {
      margin-top: $spacing-base * 0.5;
      margin-bottom: $spacing-base * 1.5;
    }

    :deep(.close-button) {
      text-transform: capitalize;
      margin-bottom: $spacing-base * 1.5;
    }
  }

  .ext-link-icon {
    font-size: $font-level-8;
  }
}
</style>
