import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx, resolveComponent as _resolveComponent, createBlock as _createBlock, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "loading-state"
}
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { class: "search-container" }
const _hoisted_4 = {
  key: 0,
  class: "list-container"
}
const _hoisted_5 = {
  key: 0,
  class: "filter-result"
}
const _hoisted_6 = { class: "title" }
const _hoisted_7 = { class: "card-body" }
const _hoisted_8 = { class: "description" }
const _hoisted_9 = { class: "detail" }
const _hoisted_10 = { class: "detail-item text-capitalize" }
const _hoisted_11 = {
  key: 0,
  class: "last-update"
}

import {
  computed, onMounted, ref, toRefs, watch,
} from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute, useRouter } from 'vue-router';
import type { Filter } from '@/types/Axios.type';
import type { Project } from '@/modules/shared/types/project.type';
import type { StatusState } from '@/types/State.type';
import { getQueryAsString } from '@/modules/shared/utils/query';
import _ from 'lodash';
import {
  formatDistance,
} from '@/modules/shared/utils/dateFormatter';
import { loadProjectsAPI } from '@/modules/shared/services/project';
import Card from '@/modules/shared/components/atoms/card/Card.vue';
import Modal from '@/modules/shared/components/organisms/modal/Modal.vue';
import ErrorMessage from '@/modules/shared/components/atoms/errorMessage/ErrorMessage.vue';
import Input from '@/modules/shared/components/atoms/input/Input.vue';
import MessageBox from '@/modules/shared/components/molecules/messageBox/MessageBox.vue';
import { setStateLoadingStatusByType } from '@/modules/shared/utils/stateManagement';
import { formatErrorObject } from '@/modules/shared/utils/errorFormatter';

interface ModalProps {
  onCloseModal: () => void;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'ChooseProjectForUploadModal',
  props: {
    onCloseModal: { type: Function }
  },
  setup(__props: any) {

const { t } = useI18n();
const route = useRoute();
const router = useRouter();

const props = __props;
const { onCloseModal } = toRefs(props);

const apiFilter: Filter = { size: '1000' };

const loadProjectsState = ref<StatusState>(setStateLoadingStatusByType());
const searchValue = ref<string>('');
const projects = ref<Project[]>([]);
const total = ref<number>(1);

const teamName = computed(() => getQueryAsString(route.params, 'teamName'));
const filterProjects = computed(() => _.filter(projects.value, (project) => project.name.includes(searchValue.value)));
const isError = computed(() => loadProjectsState.value.status === 'error');
const isEmptyProjects = computed(() => !filterProjects.value || filterProjects.value.length === 0);
const isEmptySearch = computed(() => isEmptyProjects.value && searchValue.value !== '');

async function load(filter: Filter) {
  try {
    loadProjectsState.value = setStateLoadingStatusByType({ type: 'loading' });
    const response = await loadProjectsAPI(filter);
    projects.value = response.data?.data;
    total.value = response.data?.total;
    loadProjectsState.value = setStateLoadingStatusByType({ type: 'success' });
  } catch (error) {
    loadProjectsState.value = setStateLoadingStatusByType({
      type: 'error',
      error: formatErrorObject(error, 'Project'),
    });
  }
}

function onClickPrimaryButton() {
  router.push(`/${teamName.value}/projects/create`);
}

function onSearch(event: FocusEvent) {
  if (event.target && event.target instanceof HTMLInputElement) {
    searchValue.value = event.target.value;
  }
}

watch(searchValue, () => load({ ...apiFilter, q: searchValue }));

onMounted(() => load(apiFilter));

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(Modal, {
    title: _unref(t)('video.uploader.chooseProject'),
    primaryButtonText: _unref(t)('project.newProject.title'),
    primaryButtonStyle: "text-primary",
    onClickPrimaryButton: onClickPrimaryButton,
    isShowSecondaryButton: false,
    onModalClose: _unref(onCloseModal)
  }, {
    body: _withCtx(() => [
      (loadProjectsState.value.status === 'loading')
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, _cache[0] || (_cache[0] = [
            _createElementVNode("i", { class: "fas fa-circle-notch fa-spin" }, null, -1)
          ])))
        : _createCommentVNode("", true),
      (loadProjectsState.value.status === 'success')
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createVNode(Input, {
                class: "form-control form-search",
                placeholder: _unref(t)('common:form.searchBy', {item: _unref(t)('glossary:project')}),
                onBlur: onSearch,
                modelValue: searchValue.value,
                isSearchInput: ""
              }, null, 8, ["placeholder", "modelValue"])
            ]),
            (!isEmptyProjects.value)
              ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                  (searchValue.value)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_unref(t)('project.searchResult')), 1))
                    : _createCommentVNode("", true),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(filterProjects.value, (project) => {
                    return (_openBlock(), _createBlock(_component_router_link, {
                      to: `/${teamName.value}/projects/${project.key}/upload`,
                      key: project.id,
                      class: "card-container"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(Card, null, {
                          "card-header": _withCtx(() => [
                            _createElementVNode("h1", _hoisted_6, _toDisplayString(project.name), 1)
                          ]),
                          "card-footer": _withCtx(() => [
                            _createElementVNode("div", _hoisted_9, [
                              _createElementVNode("div", _hoisted_10, _toDisplayString(total.value > 0 ? _unref(t)('video.count', { count: total.value }) : _unref(t)('video.noVideo')), 1)
                            ]),
                            (project.updatedAt)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_11, _toDisplayString(_unref(t)('common:time.latestUpdatedAt')) + " " + _toDisplayString(_unref(formatDistance)( new Date(project.updatedAt), new Date())) + " ago ", 1))
                              : _createCommentVNode("", true)
                          ]),
                          default: _withCtx(() => [
                            _createElementVNode("div", _hoisted_7, [
                              _createElementVNode("p", _hoisted_8, _toDisplayString(project.description), 1)
                            ])
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1032, ["to"]))
                  }), 128))
                ]))
              : _createCommentVNode("", true),
            (isEmptyProjects.value && isEmptySearch.value)
              ? (_openBlock(), _createBlock(MessageBox, {
                  key: 1,
                  iconClassName: "fas fa-box-open",
                  title: _unref(t)('project.notFound.title')
                }, {
                  description: _withCtx(() => [
                    _createElementVNode("p", null, [
                      _createTextVNode(_toDisplayString(_unref(t)('project.notFound.descLine1')), 1),
                      _cache[1] || (_cache[1] = _createElementVNode("br", null, null, -1)),
                      _createTextVNode(" " + _toDisplayString(_unref(t)('project.notFound.descLine2')), 1)
                    ])
                  ]),
                  _: 1
                }, 8, ["title"]))
              : _createCommentVNode("", true),
            (isError.value)
              ? (_openBlock(), _createBlock(ErrorMessage, {
                  key: 2,
                  title: loadProjectsState.value.error?.message,
                  statusCode: loadProjectsState.value.error?.statusCode
                }, null, 8, ["title", "statusCode"]))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["title", "primaryButtonText", "onModalClose"]))
}
}

})