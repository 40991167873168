<template>
  <div class="radio-btn-group-container">
    <div class="radio-btn-group-content">
      <div
        class="option-wrapper"
        :class="{'active-option': value === option.value, 'disabled': disabled || option.disabled}"
        @click.stop="!disabled && !option.disabled && onSelectOption(option.value)"
        v-for="(option, i) in options"
        :key="i"
        :id="`radio-button-${i}`"
        :data-test="`radio-button-${i}`"
      >
        <RadioButton
          :label="isTranslationText ? t(option.label) : option.label"
          :subLabel="option.subLabel"
          :description="isTranslationText ? t(option.description || '') : option.description"
          :warning="option.warning"
          :modelValue="modelValue === option.value"
          :disabled="disabled || option.disabled"
          :subFieldMt8="subFieldMt8"
          @update:modelValue="!disabled && !option.disabled && onSelectOption(option.value)"
        >
          <template v-slot:subField v-if="option.subFieldName">
            <slot :name="option.subFieldName"></slot>
          </template>
        </RadioButton>
      </div>
    </div>
    <div class="help-text" v-if="helpText">
      {{ helpText }}
    </div>
  </div>
</template>

<script lang="ts" setup>
import RadioButton from '@/modules/shared/components/atoms/radioButton/RadioButton.vue';
import cloneDeep from 'lodash/cloneDeep';
import { ref, toRefs } from 'vue';
import { useI18n } from 'vue-i18n';
import { type RadioButtonGroupOption, RadioButtonGroupProps } from './RadioButtonGroup.type';

const props = defineProps<RadioButtonGroupProps>();
const {
  modelValue,
  disabled,
  isTranslationText,
  helpText,
  options,
  onChange,
  subFieldMt8,
} = toRefs(props);

/** Value may be anything. */
const emit = defineEmits<{'update:modelValue': [value: RadioButtonGroupOption['value']]}>();

const { t } = useI18n();
const value = ref(cloneDeep(modelValue.value));

function onSelectOption(selected: RadioButtonGroupOption['value']): void {
  value.value = selected;

  if (typeof onChange?.value === 'function') {
    onChange.value(selected);
  }

  emit('update:modelValue', selected);
}
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/global-variables.scss";

.radio-btn-group-wrapper {
  display: flex;
  column-gap: $spacing-32;
}
.radio-btn-group-container {
  display: flex;
  flex-direction: column;
  row-gap: $spacing-8;
  flex: 1;
  border: 1px solid $grey-200;
  border-radius: $border-radius-5;
}
.radio-btn-group-content {
  display: flex;
  flex-direction: column;
  border-radius: $border-radius-5;
  background: $white;
  > :first-child {
    border-top-left-radius: $border-radius-5;
    border-top-right-radius: $border-radius-5;
  }
  > :last-child {
    border-bottom-left-radius: $border-radius-5;
    border-bottom-right-radius: $border-radius-5;
    border-bottom: 0 !important;
  }

  .option-wrapper {
    padding: $spacing-24 $spacing-base 20px;
    border-bottom: 1px solid $grey-200;
    cursor: pointer;

    &.active-option {
      // padding: $spacing-base;
      &:has(.sub-field-wrapper, .description) {
        padding: $spacing-24 $spacing-base 14px;
      }
    }

    &.disabled {
      cursor: not-allowed;
    }
  }
}
.icon-container {
  position: absolute;
  right: 16px;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  color: $grey-400;
}
.text-container {
  font-weight: $font-weight-base;
  font-size: $font-level-7;
  display: flex;
  flex-direction: column;
  row-gap: $spacing-8;
  .title {
    color: $grey-800;
    line-height: $line-height-base;
  }
  .help-text {
    color: $grey-600;
    line-height: $line-height-description;
  }
}
</style>
