<template>
  <div class="text-edit-group-container">
    <slot name="feedback"></slot>
    <label class="text-edit-label">{{ label }}</label>
    <div class="text-edit-container">
      <template v-if="!isEditing">
        <p v-if="localValue" class="text">{{ localValue }}</p>
        <Button
          v-if="enableEdit"
          @click="onClickEditButton"
          type="button"
          buttonStyle="text-secondary"
          buttonClasses="btn-edit">
          <template v-slot:icon-prefix>
            <i class="fas fa-pen"></i>
          </template>
        </Button>
      </template>
      <InlineEdit
        v-if="isEditing"
        :value="localValue"
        :onSubmit="onClickConfirmButton"
        :onClose="onClickCancelButton"
      />
      <div class="invalid-feedback" v-if="errorMessage">
        {{ errorMessage }}
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import Button from '@/modules/shared/components/atoms/button/Button.vue';
import InlineEdit from '@/modules/shared/components/molecules/inlineEdit/InlineEdit.vue';

export default {
  components: {
    Button,
    InlineEdit,
  },
  props: {
    modelValue: {
      type: [String, null],
      required: true,
    },
    enableEdit: {
      type: Boolean,
      required: false,
      default: true,
    },
    errorMessage: {
      type: String,
      required: false,
      default: () => '',
    },
    label: {
      type: String,
      required: true,
    },
  },
  emits: ['update:modelValue', 'confirm'],
  data() {
    return {
      initialValue: '',
      localValue: '',
      isEditing: false,
    };
  },
  computed: {
    shouldDisableInput() {
      if (this.localValue === this.initialValue) {
        return true;
      }
      if (this.errorMessage.length > 0) {
        return true;
      }
      return false;
    },
  },
  created() {
    this.initialValue = _.cloneDeep(this.modelValue);
    this.localValue = _.cloneDeep(this.modelValue);
  },
  watch: {
    modelValue() {
      this.initialValue = _.cloneDeep(this.modelValue);
      this.localValue = _.cloneDeep(this.modelValue);
    },
  },
  methods: {
    onClickEditButton() {
      this.isEditing = true;
    },
    onInput() {
      this.$emit('update:modelValue', this.localValue);
    },
    onClickConfirmButton(newValue) {
      if (this.errorMessage) {
        return;
      }
      this.localValue = newValue;
      this.$emit('confirm', this.localValue);
      this.isEditing = false;
    },
    onClickCancelButton() {
      this.localValue = this.modelValue;
      this.isEditing = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/global-variables.scss';

.text-edit-group-container {
  margin-bottom: $spacing-base * 1.25;
}

.text-edit-label {
  text-transform: capitalize;
  margin-bottom: 4px;
}

.text-edit-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;

  .text {
    color: #343A40;
    padding-top: $spacing-base * 0.375; // Prevents layout shifting after clicking an edit button
    padding-bottom: $spacing-base * 0.375; // Prevents layout shifting after clicking an edit button
    margin-bottom: 0;
    margin-right: $spacing-base;
    font-size: $font-size-base * 1.15;
  }

  .input-container {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .form-control {
      flex: 1;
    }
  }

  .invalid-feedback {
    display: block;
    text-transform: capitalize;
  }

  :deep(.btn-edit) {
    padding: 0;
    align-items: center;
  }

  :deep(.btn-confirm) {
    padding: 0.375rem 0.7rem;
    margin-left: 10px;
    margin-right: 10px;
  }

  :deep(.btn-cancel) {
    padding: 0.375rem 0.7rem;
  }
}
</style>
