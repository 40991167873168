<template>
  <Page
    :title="t('project.manage.members.title')"
    :projectName="currentProject?.name ?? ''"
  >
    <ProjectManageMemberToasts />
    <Alert v-if="updateSuccess" :alertText="t('response:savedSuccessfully')" priority="success" />
    <ProjectMembershipChangeRoleModal
      @success="onUpdateSuccess"
      :member="editMember"
      :onCloseModal="onCloseProjectManageMembershipChangeRoleModal"
      v-if="isProjectManageMembershipChangeRoleModalShowing"
    />
    <DeleteProjectMembershipModal
      @success="onUpdateSuccess"
      :member="deleteMember"
      :onCloseModal="onCloseProjectManageMembershipDeleteModal"
      v-if="isProjectManageMembershipDeleteModalShowing"
    />
    <AddProjectManageMembershipModal
      :memberships="memberships"
      :onSubmit="onSubmitMemberShips"
      :onCloseModal="onCloseProjectManageMembershipModal"
      :updateProjectMembershipsState="updateProjectMembershipsState"
      v-if="isProjectManageMembershipModalShowing"
    />
    <CreateInvitationLinkModal
      v-if="isShowCreateInvitationModal"
      :member="memberToCreateNewInvitation"
      :onCloseModal="() => isShowCreateInvitationModal = false"
    />
    <Section>
      <div class="user-container">
        <div class="membership-list" v-if="!isEmptyMemberShips">
          <DataTable
            v-if="!isEmptySearchResult"
            dataTest="project-manage-member-table"
            responsiveLayout="a"
            :onSearchData="onFilterUser"
            :searchInputPlaceholder="t('common:form.searchByMemberName')"
            :searchInputValue="q">
            <template v-slot:table-action>
              <Button
                type="button"
                buttonStyle="primary"
                dataTest="button-add-member"
                v-if="canAddProjectMemberships()"
                isFullWidth
                @click="onToggleProjectManageMembershipModal">
                <template v-slot:icon-prefix>
                  <i class="fas fa-plus mr-1" />
                </template>
                <template v-slot:content>
                  {{ t('project.manage.members.add') }}
                </template>
              </Button>
            </template>
            <template v-slot:table-header v-if="!isEmptySearchResult">
              <Row>
                <Column width="40px"></Column>
                <Column>
                  <HeaderCell :text="t('project.manage.member.table.header.name')" />
                </Column>
                <Column>
                  <HeaderCell :text="t('project.manage.member.table.header.email')" />
                </Column>
                <Column width="120px">
                  <HeaderCell :text="t('project.manage.member.table.header.role')" />
                </Column>
                <Column width="100px">
                  <HeaderCell :text="t('project.manage.member.table.header.date')" />
                </Column>
                <Column width="100px" class="inviter-column">
                  <HeaderCell :text="t('project.manage.member.table.header.inviter')" />
                </Column>
                <Column width="100px">
                  <HeaderCell :text="t('project.manage.member.table.header.note')" />
                </Column>
                <Column width="50px"></Column>
              </Row>
            </template>
            <template v-slot:table-content v-if="!isEmptySearchResult">
              <Row v-for="(member, index) in filterMemberships" :key="index" verticalAlign="center">
                <Column width="40px" :dataTest="`column-member-account-${index}`">
                  <Logo :user="member.account" />
                </Column>
                <Column isPrimaryColumn :dataTest="`column-member-name-${index}`">
                  <TextCell :text="member.account.name" />
                </Column>
                <Column :dataTest="`column-member-email-${index}`">
                  <TextCell :text="member.account.email" />
                </Column>
                <Column width="120px" :dataTest="`column-member-role-${index}`">
                  <Badge
                    :title="getPrettyRoleName(member.projectRole)"
                    size="small"
                    :badgeStyle="getRoleBadgeStyle(member.projectRole)"
                  />
                </Column>
                <Column width="100px" :dataTest="`column-member-added-at-${index}`">
                  <TextCell :text="member.addedAt ? transformDateToDisplay(member.addedAt) : '-'" />
                </Column>
                <Column width="100px" class="inviter-column" :dataTest="`column-member-added-by-${index}`">
                  <TextCell :text="member.addedBy?.name || '-'" />
                </Column>
                <Column width="100px" :dataTest="`column-member-note-${index}`">
                  <TextCell :text="getIfIsUserActivated(member) ? '-' : t('project.manage.member.waitForActivate')" />
                </Column>
                <Column width="50px" isActionColumn>
                  <Dropdown iconClassName="fas fa-ellipsis-vertical" v-if="member.account?.role !== 'admin'" :dataTest="`dropdown-${index}`">
                    <DropdownItem
                      dataTest="dropdown-invite-link"
                      :disabled="getIfIsUserActivated(member)"
                      :onClick="() => {
                        isShowCreateInvitationModal = true;
                        memberToCreateNewInvitation = member;
                      }"
                    >
                      {{ t('project.manage.members.inviteLink.button') }}
                    </DropdownItem>
                    <DropdownItem
                      dataTest="dropdown-edit-role"
                      :disabled="!canUpdateProjectMemberships(member)"
                      :onClick="() => (canUpdateProjectMemberships(member) ? onChangeMemberRole(member):null)"
                    >
                      {{ t('project.manage.member.editRole') }}
                    </DropdownItem>
                    <DropdownItem
                      dataTest="dropdown-remove-member"
                      :disabled="!canDeleteProjectMemberships(member)"
                      :onClick="() => (canDeleteProjectMemberships(member) ? onDeleteMember(member):null)"
                    >
                      {{ getIfIsUserActivated(member) ? t('project.manage.member.remove') : t('project.manage.member.removeNonMember') }}
                    </DropdownItem>
                  </Dropdown>
                </Column>
              </Row>
            </template>
          </DataTable>
          <EmptySearch
            v-if="isEmptySearchResult"
            :keyword="q"
            :onClearSearch="() => { q = '' }"
          />
        </div>
        <div v-if="isEmptyMemberShips">
          <MessageBox
            :title="t('project.manage.members.noMember')"
            :onClickButton="onToggleProjectManageMembershipModal"
            :buttonTitle="t('project.manage.members.add')"
            buttonPrefixIconClassName="fas fa-plus" />
        </div>
      </div>
    </Section>
  </Page>
</template>

<script lang="ts" setup>
import type { MembershipPayload, ProjectMember } from '@/modules/projectMember/types';
import _ from 'lodash';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';
import { transformDateToDisplay } from '@/modules/shared/utils/dateFormatter';
import ProjectManageMemberToasts from '@/modules/projectMember/components/molecules/projectManageMemberToasts/ProjectManageMemberToasts.vue';
import CreateInvitationLinkModal from '@/modules/projectMember/components/organisms/createInvitationLinkModal/CreateInvitationLinkModal.vue';
import type { ObjectWithStringValue } from '@/types/Object.type';
import { getQueryAsString } from '@/modules/shared/utils/query';
import Page from '@/layouts/Page.vue';
import Section from '@/modules/shared/components/organisms/section/Section.vue';
import MessageBox from '@/modules/shared/components/molecules/messageBox/MessageBox.vue';
import EmptySearch from '@/modules/shared/components/molecules/emptySearch/EmptySearch.vue';
import ProjectMembershipChangeRoleModal from '@/modules/projectMember/components/organisms/changeProjectAccessLevelModal/ChangeProjectAccessLevelModal.vue';
import DeleteProjectMembershipModal from '@/modules/projectMember/components/organisms/deleteProjectMemberModal/DeleteProjectMemberModal.vue';
import AddProjectManageMembershipModal from '@/modules/projectMember/components/organisms/addProjectMemberModal/AddProjectMemberModal.vue';
import Button from '@/modules/shared/components/atoms/button/Button.vue';
import Alert from '@/modules/shared/components/atoms/alert/Alert.vue';
import DataTable from '@/modules/shared/components/organisms/dataTable/DataTable.vue';
import Column from '@/modules/shared/components/molecules/column/Column.vue';
import Row from '@/modules/shared/components/molecules/row/Row.vue';
import HeaderCell from '@/modules/shared/components/molecules/headerCell/HeaderCell.vue';
import TextCell from '@/modules/shared/components/molecules/textCell/TextCell.vue';
import Logo from '@/modules/shared/components/atoms/logo/Logo.vue';
import Badge from '@/modules/shared/components/atoms/badge/Badge.vue';
import Dropdown from '@/modules/shared/components/atoms/dropdown/Dropdown.vue';
import DropdownItem from '@/modules/shared/components/atoms/dropdownItem/DropdownItem.vue';
import {
  getIfIsUserActivated,
  getMembershipRolesOptions,
  getPrettyRoleName,
  getRoleBadgeStyle,
} from '../utils';
import { useStore } from '../store/hooks';

const { t } = useI18n();
const store = useStore();
const route = useRoute();

const q = ref<string>('');
const isProjectManageMembershipModalShowing = ref<boolean>(false);
const deleteMember = ref<ProjectMember | null>(null);
const editMember = ref<ProjectMember | null>(null);
const memberToCreateNewInvitation = ref<ProjectMember | null>(null);
const updateSuccess = ref<boolean>(false);
const isShowCreateInvitationModal = ref<boolean>(false);

const projectKey = computed(() => getQueryAsString(route.params, 'projectKey'));
const currentProject = computed(() => store.state.project.currentProject);
const updateProjectMembershipsState = computed(() => store.state.projectMember.updateProjectMembershipsState);
const memberships = computed(() => currentProject.value?.memberships ?? []);

const projectPermission = computed(() => (
  currentProject.value?.actions ?? {
    updateMembership: false,
  }
));

const filterMemberships = computed<ProjectMember[]>(() => {
  const members = memberships.value ?? [];

  if (q.value.length === 0) {
    return members;
  }

  return members.filter((member) => {
    if (member && member.account) {
      return member.account.name.toLowerCase().includes(q.value.toLowerCase());
    }
    return false;
  });
});

const isEmptyMemberShips = computed(() => _.isEmpty(memberships.value));
const isEmptySearchResult = computed(() => _.isEmpty(filterMemberships.value));
const amountOfMember = computed(() => _.size(memberships.value));
const isOneAdminLeft = computed(() => {
  const amountOfAdmin = _.size(_.filter(memberships.value, { projectRole: 'project-manager' }));
  return amountOfAdmin > 1;
});

const isProjectManageMembershipDeleteModalShowing = computed(() => deleteMember.value);
const isProjectManageMembershipChangeRoleModalShowing = computed(() => editMember.value);

function onCloseProjectManageMembershipChangeRoleModal() {
  editMember.value = null;
}

function onChangeMemberRole(member: ProjectMember) {
  editMember.value = member;
}

function onCloseProjectManageMembershipDeleteModal() {
  deleteMember.value = null;
}

function onDeleteMember(member: ProjectMember) {
  deleteMember.value = member;
}

function onFilterUser(event: Event) {
  if (event.target && event.target instanceof HTMLInputElement) {
    q.value = event.target.value;
  }
}

function onCloseProjectManageMembershipModal() {
  isProjectManageMembershipModalShowing.value = false;
}

async function onSubmitMemberShips(payload: MembershipPayload[]) {
  await store.dispatch('inviteAndAddMembers', {
    projectKey: projectKey.value,
    memberships: payload,
  });
  onCloseProjectManageMembershipModal();
}

function onToggleProjectManageMembershipModal() {
  isProjectManageMembershipModalShowing.value = !isProjectManageMembershipModalShowing.value;
}

function getRoleByValue(value: string): ObjectWithStringValue | undefined {
  return _.find(getMembershipRolesOptions(), (role) => role.value === value);
}

function isManagerOfProject(member: ProjectMember): boolean {
  return member?.projectRole === getRoleByValue('project-manager')?.value ?? false;
}

function canUpdateProjectMemberships(member: ProjectMember) {
  if (isManagerOfProject(member)) {
    return isOneAdminLeft.value && projectPermission.value.updateMembership;
  }
  return projectPermission.value.updateMembership;
}

function canDeleteProjectMemberships(member: ProjectMember) {
  return canUpdateProjectMemberships(member) && amountOfMember.value > 1;
}

function canAddProjectMemberships() {
  return projectPermission.value.updateMembership;
}

function onUpdateSuccess() {
  updateSuccess.value = true;
  const timer = setTimeout(() => {
    store.commit('setUpdateProjectMembershipsState', { type: 'idle' });
    updateSuccess.value = false;
    clearTimeout(timer);
  }, 3000);
}
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/global-variables';
@import '~@/assets/scss/breakpoint';

.section-header {
  display: flex;
}
.search {
  width: 350px;
  max-width: 350px;
  min-width: 100px;
}
.search-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: $spacing-32;
}
.button-add-member {
  margin-left: auto;
}

.user-container {
  overflow: visible;
}

:deep(.modal-footer) {
  :deep(.button) {
    text-transform: capitalize;
  }
}

.alert {
  border-radius: 5px;
}

:deep(.search-input-container) {
  margin-top: 0;
}

@media screen and (max-width: $max-layout-xl) {
  .inviter-column {
    display: none;
  }
}

@media screen and (max-width: $max-layout-sm) {
  .section-header {
    padding: $spacing-base $spacing-base !important;
    padding-bottom: 0 !important;
    .search {
      width: 50%;
    }

    .button-add-member {
      margin-bottom: 1rem;
      margin-right: 5px;
    }
  }

  :deep(.submenu-item) {
    font-size: $font-size-base;
    padding: 0.6rem 2rem;
  }

  .section-body {
    padding: $spacing-base $spacing-base !important;
  }

  :deep(.section .section-header) {
    flex-direction: column;
  }

  :deep(.search-input-container) {
    margin-bottom: 1rem;

    .search {
      width: 100%;
      max-width: 100%;
    }
  }
}
</style>
