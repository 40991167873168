<template>
  <div class="action-button-desktop" data-test="action-button-desktop">
    <Button
      :dataTest="restoreAllVideosButton.dataTest"
      buttonStyle="primary"
      @click="onClickRestoreAllVideos"
      :disabled="restoreAllVideosButton.disabled">
      <template v-slot:icon-prefix>
        <i :class="restoreAllVideosButton.iconClassName"></i>
      </template>
      {{ restoreAllVideosButton.text }}
    </Button>
  </div>
  <div class="action-button-mobile" data-test="action-button-mobile">
    <Dropdown
      dataTest="action-button-dropdown"
      buttonStyle="text-secondary"
      buttonClassName="btn-secondary-outline"
      :withArrow="false"
      offsetSkid="-64"
    >
      <DropdownItem>
        <Button
          :dataTest="restoreAllVideosButton.dataTest"
          buttonStyle="text-secondary"
          @click="onClickRestoreAllVideos"
          :disabled="restoreAllVideosButton.disabled">
          <template v-slot:icon-prefix>
            <i :class="restoreAllVideosButton.iconClassName"></i>
          </template>
          {{ restoreAllVideosButton.text }}
        </Button>
      </DropdownItem>
    </Dropdown>
  </div>
</template>

<script setup lang="ts">
import DropdownItem from '@/modules/shared/components/atoms/dropdownItem/DropdownItem.vue';
import Dropdown from '@/modules/shared/components/atoms/dropdown/Dropdown.vue';
import Button from '@/modules/shared/components/atoms/button/Button.vue';
import { computed, toRefs } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from '@/modules/projectVideoList/store/hooks';

interface RestoreAllDeletedVideosProps {
  onClickRestoreAllVideos: () => void;
}

const props = withDefaults(defineProps<RestoreAllDeletedVideosProps>(), {
  onClickRestoreAllVideos: () => {},
});
const { onClickRestoreAllVideos } = toRefs(props);

const store = useStore();
const { t } = useI18n();

const projectDeletedVideoTotal = computed(() => store.state.projectDeletedVideoList.projectDeletedVideoTotal);
const restoreAllVideosButton = computed(() => ({
  iconClassName: 'fa-solid fa-clock-rotate-left',
  text: t('project.recentlyDeleted.restoreAll.title'),
  disabled: (projectDeletedVideoTotal?.value ?? 0) <= 0,
  dataTest: 'restore-all-videos-button',
}));

</script>

<style scoped lang="scss">
@import '~@/assets/scss/global-variables';
@import '~@/assets/scss/breakpoint';

.action-button-mobile {
  display: none;
}

.action-button-desktop {
  display: block;
}

@media screen and (max-width: $max-layout-md) {
  .action-button-desktop {
    display: none;
  }

  .action-button-mobile {
    display: block;
  }

  :deep(.dropdown-item) {
    padding: 0;
  }
}
</style>
