import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute, useRouter } from 'vue-router';
import Modal from '@/modules/shared/components/organisms/modal/Modal.vue';
import DataTable from '@/modules/shared/components/organisms/dataTable/DataTable.vue';
import Column from '@/modules/shared/components/molecules/column/Column.vue';
import Row from '@/modules/shared/components/molecules/row/Row.vue';
import HeaderCell from '@/modules/shared/components/molecules/headerCell/HeaderCell.vue';
import TextCell from '@/modules/shared/components/molecules/textCell/TextCell.vue';
import Button from '@/modules/shared/components/atoms/button/Button.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'SecureAndProtectContentModal',
  emits: ["close"],
  setup(__props, { emit: __emit }) {

const emit = __emit;

const { t } = useI18n();
const route = useRoute();
const router = useRouter();

const teamName = computed(() => route.params.teamName);

function onClickLink(routeName: string) {
  const routeData = router.resolve(routeName);
  window.open(routeData.href, '_blank');
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(Modal, {
    size: "large",
    title: _unref(t)('secureAndProtect.title'),
    isShowModalFooter: false,
    onModalClose: _cache[3] || (_cache[3] = () => emit('close'))
  }, {
    body: _withCtx(() => [
      _createVNode(DataTable, { responsiveLayout: "a" }, {
        "table-header": _withCtx(() => [
          _createVNode(Row, null, {
            default: _withCtx(() => [
              _createVNode(Column, null, {
                default: _withCtx(() => [
                  _createVNode(HeaderCell, {
                    text: _unref(t)('secureAndProtect.table.topic')
                  }, null, 8, ["text"])
                ]),
                _: 1
              }),
              _createVNode(Column, null, {
                default: _withCtx(() => [
                  _createVNode(HeaderCell, {
                    text: _unref(t)('secureAndProtect.table.condition')
                  }, null, 8, ["text"])
                ]),
                _: 1
              }),
              _createVNode(Column, null, {
                default: _withCtx(() => [
                  _createVNode(HeaderCell, {
                    text: _unref(t)('secureAndProtect.table.location')
                  }, null, 8, ["text"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        "table-content": _withCtx(() => [
          _createVNode(Row, null, {
            default: _withCtx(() => [
              _createVNode(Column, null, {
                default: _withCtx(() => [
                  _createVNode(TextCell, {
                    primaryText: _unref(t)('project.setup.secureAndProtect.allowedBlocked.title'),
                    subText: _unref(t)('project.setup.secureAndProtect.allowedBlocked.description')
                  }, null, 8, ["primaryText", "subText"])
                ]),
                _: 1
              }),
              _createVNode(Column, null, {
                default: _withCtx(() => [
                  _createVNode(TextCell, {
                    text: _unref(t)('project.setup.secureAndProtect.allowedBlocked.when')
                  }, null, 8, ["text"])
                ]),
                _: 1
              }),
              _createVNode(Column, null, {
                default: _withCtx(() => [
                  _createVNode(Button, {
                    buttonStyle: "link-primary",
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (onClickLink(`/${teamName.value}/manage/playback-channels`)))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_unref(t)('project.setup.secureAndProtect.allowedBlocked.where')), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(Row, null, {
            default: _withCtx(() => [
              _createVNode(Column, null, {
                default: _withCtx(() => [
                  _createVNode(TextCell, {
                    primaryText: _unref(t)('project.setup.secureAndProtect.signedURL.title'),
                    subText: _unref(t)('project.setup.secureAndProtect.signedURL.description')
                  }, null, 8, ["primaryText", "subText"])
                ]),
                _: 1
              }),
              _createVNode(Column, null, {
                default: _withCtx(() => [
                  _createVNode(Button, {
                    buttonStyle: "link-primary",
                    url: "https://docs.byteark.com/th/stream/",
                    isExternalUrl: ""
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_unref(t)('project.setup.secureAndProtect.signedURL.when')), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(Column, null, {
                default: _withCtx(() => [
                  _createVNode(Button, {
                    buttonStyle: "link-primary",
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (onClickLink(`/${teamName.value}/manage/playback-channels`)))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_unref(t)('project.setup.secureAndProtect.signedURL.where')), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(Row, null, {
            default: _withCtx(() => [
              _createVNode(Column, null, {
                default: _withCtx(() => [
                  _createVNode(TextCell, {
                    primaryText: _unref(t)('project.setup.secureAndProtect.clearKey.title'),
                    subText: _unref(t)('project.setup.secureAndProtect.clearKey.description')
                  }, null, 8, ["primaryText", "subText"])
                ]),
                _: 1
              }),
              _createVNode(Column, null, {
                default: _withCtx(() => [
                  _createVNode(Button, {
                    buttonStyle: "link-primary",
                    url: "https://docs.byteark.com/th/stream/",
                    isExternalUrl: ""
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_unref(t)('project.setup.secureAndProtect.clearKey.when')), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(Column, null, {
                default: _withCtx(() => [
                  _createVNode(Button, {
                    buttonStyle: "link-primary",
                    onClick: _cache[2] || (_cache[2] = ($event: any) => (onClickLink(`/${teamName.value}/manage/clear-key`)))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_unref(t)('project.setup.secureAndProtect.clearKey.where')), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["title"]))
}
}

})