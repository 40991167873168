import store from '@/store';
import project from '@/modules/shared/store/project';
import { playbackChannelList } from '@/modules/playbackChannel';
import teamFeatures from '@/modules/shared/store/teamFeatures';
import user from '@/modules/user/store/user';
import type { RouteRecordRaw } from 'vue-router';
import projectDefaultUploadSetting from '../store';

export function createProjectDefaultUploadSettingRoutes(): RouteRecordRaw[] {
  return [
    {
      name: 'defaults',
      path: 'defaults',
      component: () => import(/* webpackChunkName: "ProjectManageUploadSetting" */ '../pages/ProjectManageUploadSetting.vue'),
      beforeEnter: (to, from, next) => {
        if (!store.hasModule('project')) {
          store.registerModule('project', project);
        }
        if (!store.hasModule('projectDefaultUploadSetting')) {
          store.registerModule('projectDefaultUploadSetting', projectDefaultUploadSetting);
        }
        if (!store.hasModule('playbackChannelList')) {
          store.registerModule('playbackChannelList', playbackChannelList);
        }
        if (!store.hasModule('teamFeatures')) {
          store.registerModule('teamFeatures', teamFeatures);
        }
        if (!store.hasModule('user')) {
          store.registerModule('user', user);
        }
        next();
      },
    },
  ];
}
