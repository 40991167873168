import type { NavigationGuardWithThis, RouteRecordRaw } from 'vue-router';
import { user } from '@/modules/user';
import store from '@/store';
// import teamFeatures from '@/modules/shared/store/teamFeatures';
import preset from '../store/preset';
import presetList from '../store/presetList';
import resolutionItem from '../store/resolutionItem';

export function createPresetRoutes(): RouteRecordRaw[] {
  const beforeEnter: NavigationGuardWithThis<undefined> = (to, from, next) => {
    if (!store.hasModule('user')) {
      store.registerModule('user', user);
    }
    if (!store.hasModule('preset')) {
      store.registerModule('preset', preset);
    }
    if (!store.hasModule('presetList')) {
      store.registerModule('presetList', presetList);
    }
    if (!store.hasModule('resolutionItem')) {
      store.registerModule('resolutionItem', resolutionItem);
    }
    next();
  };

  return [
    {
      name: 'presets',
      path: 'presets',
      meta: { teamManagement: true },
      component: () => import(/* webpackChunkName: "managementOutputVideo" */ '@/modules/preset/pages/ManagePreset.vue'),
      beforeEnter,
    },
    {
      name: 'presetCreate',
      path: 'presets/create',
      meta: { teamManagement: true },
      component: () => import(/* webpackChunkName:"managePresetCreate" */ '@/modules/preset/pages/ManagePresetCreate.vue'),
      beforeEnter,
    },
    {
      name: 'presetDetail',
      path: 'presets/:presetId',
      meta: { teamManagement: true },
      component: () => import(/* webpackChunkName: "managePresetEdit" */ '@/modules/preset/pages/ManagePresetEdit.vue'),
      beforeEnter,
    },
  ];
}
