import TypeSimple from '@/assets/images/collectionType/type-simple.svg';
import TypeSeries from '@/assets/images/collectionType/type-series.svg';
import TypeCourse from '@/assets/images/collectionType/type-course.svg';
import TypeSeminar from '@/assets/images/collectionType/type-seminar.svg';
import type { CollectionVisibilityTextWithIcon } from '@/modules/collection/types';
import type { Nullable } from '@/types/index.type';

export const collectionTemplates = [
  {
    title: 'Simple Collection',
    description: 'collectionType.create.template.simple',
    imgUrl: TypeSimple,
    template: 'simple',
  },
  {
    title: 'Series/Movies',
    description: 'collectionType.create.template.series',
    imgUrl: TypeSeries,
    template: 'series/movies',
  },
  {
    title: 'Course',
    description: 'collectionType.create.template.course',
    imgUrl: TypeCourse,
    template: 'course',
  },
  {
    title: 'Seminar',
    description: 'collectionType.create.template.seminar',
    imgUrl: TypeSeminar,
    template: 'seminar',
  },
];

export const collectionVisibilities: CollectionVisibilityTextWithIcon[] = [
  {
    title: 'common:visibility.private.title',
    subtitle: 'common:visibility.private.subtitle',
    icon: 'far fa-eye-slash',
    value: 'private',
  },
  {
    title: 'common:visibility.unlisted.title',
    subtitle: 'common:visibility.unlisted.subtitle',
    icon: 'far fa-eye',
    value: 'unlisted',
  },
  {
    title: 'common:visibility.public.title',
    subtitle: 'common:visibility.public.subtitle',
    icon: 'fas fa-earth-asia',
    value: 'public',
  },
  {
    title: 'common:visibility.scheduled.title',
    subtitle: 'common:visibility.scheduled.subtitle',
    icon: 'far fa-calendar-days',
    value: 'schedule',
  },
];

export const getVisibilityObject = (type: Nullable<string>): CollectionVisibilityTextWithIcon | undefined => (
  collectionVisibilities.find((item) => item.value === type)
);
