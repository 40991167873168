/* eslint no-shadow: ["error", { "allow": ["state"] }] */
import _ from 'lodash';
import { formatErrorObject } from '@/modules/shared/utils/errorFormatter';
import {
  loadPlayerByIdAPI,
  updatePlayerByIdAPI,
  createPlayerAPI,
} from '@/modules/videoEmbedPlayer/services/api';
import { ActionContext } from 'vuex';
import { IPlayer, PlayerState } from '@/modules/videoEmbedPlayer/types/Player.type';
import { RootState } from '@/store/type';
import { LoadingState, SavingState } from '@/types/State.type';
import {
  setStateLoadingStatusByType,
  setStateSavingStatusByType,
} from '../../shared/utils/stateManagement';

type PlayerContext = ActionContext<PlayerState, RootState>

const state = {
  currentPlayer: null,
  loadCurrentPlayerState: setStateLoadingStatusByType(),
  saveCurrentPlayerState: setStateSavingStatusByType(),
  validateError: {
    name: false,
    bytearkLighthouse: false,
    bytearkAds: false,
    errorDisplaying: false,
  },
};

const initialState: PlayerState = _.cloneDeep(state);

const mutations = {
  setCurrentPlayer(state: PlayerState, player: IPlayer) {
    state.currentPlayer = { ...state.currentPlayer, ...player };
  },
  setLoadCurrentPlayerState(state: PlayerState, loadingState: LoadingState) {
    state.loadCurrentPlayerState = setStateLoadingStatusByType(loadingState);
  },
  setsaveCurrentPlayerState(state: PlayerState, savingState: SavingState) {
    state.saveCurrentPlayerState = setStateSavingStatusByType(savingState);
  },
  setValidateError(state: PlayerState, payload: any) {
    const { key, value } = payload;
    state.validateError[key] = value;
  },
  destroyCurrentPlayer(state: PlayerState) {
    Object.assign(state, initialState);
  },
};

const actions = {
  async loadPlayerById({ commit }: PlayerContext, playerId: string) {
    commit('setLoadCurrentPlayerState', { type: 'loading' });
    try {
      const response = await loadPlayerByIdAPI(playerId);
      commit('setCurrentPlayer', response.data);
      commit('setLoadCurrentPlayerState', { type: 'success' });
    } catch (error) {
      commit('setLoadCurrentPlayerState', { type: 'error', error: formatErrorObject(error, 'Player') });
    }
  },
  async createPlayer({ commit }: PlayerContext, payload: IPlayer) {
    commit('setsaveCurrentPlayerState', { type: 'loading' });
    try {
      const response = await createPlayerAPI(payload);
      commit('setCurrentPlayer', response.data);
      commit('setsaveCurrentPlayerState', { type: 'success' });
    } catch (error) {
      commit('setsaveCurrentPlayerState', { type: 'error', error: formatErrorObject(error, 'Player') });
    }
  },
  async updatePlayerById({ commit }: PlayerContext, payload: IPlayer) {
    commit('setsaveCurrentPlayerState', { type: 'loading' });
    try {
      const response = await updatePlayerByIdAPI(payload.id, payload);
      commit('setCurrentPlayer', response.data);
      commit('setsaveCurrentPlayerState', { type: 'success' });
    } catch (error) {
      commit('setsaveCurrentPlayerState', { type: 'error', error: formatErrorObject(error, 'Player') });
    }
  },
  setCurrentPlayer({ commit }: PlayerContext, player: IPlayer) {
    commit('setCurrentPlayer', player);
  },
  destroyCurrentPlayer({ commit }: PlayerContext) {
    commit('destroyCurrentPlayer');
  },
  setValidateError({ commit }: PlayerContext, payload: IPlayer) {
    commit('setValidateError', payload);
  },
};

export default {
  state,
  actions,
  mutations,
};
