<template>
  <Modal
    @modalClose="onCloseModal"
    :isShowModalFooter="false">
    <template v-slot:body>
      <div class="content-group">
        <StepURLSigningKeyInformation
          :urlSigningKey="urlSigningKey"
          :title="$t('urlSigningKey.information.title')"
          :description="computedDescription"
          :onClose="onCloseModal" />
      </div>
    </template>
  </Modal>
</template>

<script>
import Modal from '@/modules/shared/components/organisms/modal/Modal.vue';
import StepURLSigningKeyInformation from '../../molecules/stepURLSigningKeyInformation/StepURLSigningKeyInformation.vue';

export default {
  props: {
    urlSigningKey: {
      type: Object,
      default: () => {},
    },
    description: {
      type: String,
      default: '',
    },
    onCloseModal: {
      type: Function,
      default: () => {},
    },
  },
  components: {
    Modal,
    StepURLSigningKeyInformation,
  },
  computed: {
    computedDescription() {
      if (this.description) {
        return this.description;
      }
      return `${this.$t('urlSigningKey.information.useKey')} <strong>${this.$t('urlSigningKey.information.requireSignedURL')}<strong>${this.$t('urlSigningKey.information.seeDocument')}`;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/global-variables.scss';

.formgroup-container {
  text-align: left;
}

:deep(.modal-footer), :deep(.modal-header) {
  display: none;
}

/* :deep(.modal-body) {
  display: flex;
} */

.content-group {
  text-align: center;
  margin: 0 auto;
  padding: $spacing-base * 2 $spacing-base 0;
  min-height: 340px;

  .icon {
    font-size: $font-size-base * 5;
    color: $grey-400;
    margin-bottom: $spacing-base;
  }

  .subtitle {
    font-size: $font-size-base * 1.125;
    padding: 0 $spacing-base * 0.5;
    margin-bottom: $spacing-base * 2;
  }
}

.btn-close-container {
  position: absolute;
  top: $spacing-base;
  right: $spacing-base;
  font-size: $font-size-base * 1.5;
  cursor: pointer;
  z-index: 1;
}
</style>
