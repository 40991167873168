import axios from 'axios';
import getEnv from '@/modules/shared/utils/env';

const getBaseFlareURL = () => {
  const flareUrl = getEnv('VUE_APP_FLARE_BASE_URL');

  if (flareUrl !== '{{VUE_APP_FLARE_BASE_URL}}' && flareUrl) {
    return flareUrl;
  }

  // For local development
  // if (process.env.VUE_APP_FLARE_BASE_URL) {
  //   return process.env.VUE_APP_FLARE_BASE_URL;
  // }

  return 'https://flare.byteark-stream-develop-2.poring.arkcube.com';
};

const baseURL = process.env.VUE_APP_ORIGIN || window.location.origin;
const baseFlareURL = getBaseFlareURL();

const axiosAPI = axios.create({
  baseURL: `${baseURL}/api/v1`,
});

const axiosAPIv2 = axios.create({
  baseURL: `${baseURL}/api/v2`,
});

const axiosUploadAPI = axios.create({
  baseURL: `${baseURL}/api/upload/v1`,
});

const axiosKeyServerAPI = axios.create({
  baseURL: `${baseURL}/api/encoding/v1`,
});

const axiosAuthAPI = axios.create({
  baseURL: `${baseURL}/api/auth/v1`,
});

const axiosFlareAPI = axios.create({
  baseURL: `${baseFlareURL}/api/flare/v1`,
});

export {
  axiosAPI,
  axiosAPIv2,
  axiosUploadAPI,
  axiosAuthAPI,
  axiosKeyServerAPI,
  axiosFlareAPI,
};
