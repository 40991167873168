<template>
  <div class="section-file-container">
    <div class="upload-file-container"
         :class="{'dragover': isDraggingVideoState }"
         v-if="isEmptyVideoFiles">
      <div class="upload-file">
        <!-- multiple upload -->
        <input
          type="file"
          class="input-file"
          ref="inputfile"
          multiple
          @change="fileBrowsed"
          v-if="isMultiple" />
        <!-- single upload -->
        <input
          type="file"
          class="input-file"
          ref="inputfile"
          name="file"
          @change="fileBrowsed"
          v-if="!isMultiple" />
        <MessageBox
          class="empty-files"
          iconClassName="fas fa-cloud-arrow-up"
          :title="t(`video.uploader.dragOrClickToUpload${isMultiple ? '_plural' : ''}`)" />
      </div>
    </div>
    <div class="file-container" v-if="!isEmptyVideoFiles">
      <ul class="list-group" data-test="file-list-group">
        <li
          class="list-group-item"
          v-for="(video, index) in videoFilesError"
          :key="`error-${index}`"
          :data-test="`file-error-${index}`"
        >
          <div class="name" data-test="video-error-name">
            {{getVideoFileName(video)}}
          </div>
          <div class="error-container" v-if="video.error">
            <i class="fas fa-triangle-exclamation icon"></i>
            <div class="text-danger" data-test="video-error-message">
              {{getVideoFileError(video)}}
            </div>
          </div>
          <div class="action" @click="onRemoveFiles(video)" data-test="video-invalid-delete-button">
            <i class="far fa-trash-can"></i>
          </div>
        </li>
        <li
          class="list-group-item"
          v-for="(video, index) in videoFilesValid"
          :key="index"
          :data-test="`file-valid-${index}`"
        >
          <div class="name" data-test="valid-video-name">
            {{getVideoFileName(video)}}
          </div>
          <div class="size" data-test="valid-video-size">
            {{ getVideoFileSize(video) }}
          </div>
          <div class="action" @click="onRemoveFiles(video)" data-test="video-valid-delete-button">
            <i class="far fa-trash-can"></i>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, toRefs } from 'vue';
import { useI18n } from 'vue-i18n';
import {
  getVideoFileName,
  getVideoFileSize,
  getVideoFileError,
} from '@/modules/videoDetail/utils/videoFileManagement';
import MessageBox from '@/modules/shared/components/molecules/messageBox/MessageBox.vue';
import { useStore } from '../../../store/hooks';
import { VideoUploadFile } from '../../../types/videoUpload.type';

interface VideoUploaderBoxProps {
  isMultiple?: boolean;
}

const props = withDefaults(defineProps<VideoUploaderBoxProps>(), {
  isMultiple: true,
});

const { isMultiple } = toRefs(props);
const store = useStore();
const { t } = useI18n();

const isDraggingVideoState = computed(() => store.state.videoUpload.isDraggingVideoState);
const videoFilesValid = computed(() => store.getters.videoFilesValid);
const videoFilesError = computed(() => store.getters.videoFilesError);
const isEmptyVideoFiles = computed(() => store.getters.isEmptyVideoFiles);

async function fileBrowsed(e: Event) {
  if (!e.target || !('files' in e.target)) {
    return;
  }

  await store.dispatch('updateVideoFiles', e.target.files);

  if ('value' in e.target) {
    /** Reset selected files. */
    e.target.value = '';
  }
}

function onRemoveFiles(deleteVideoFile: VideoUploadFile) {
  store.dispatch('removeVideoFile', deleteVideoFile);
}
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/global-variables.scss';
@import '~@/assets/scss/breakpoint.scss';

.section-file-container {
  height: 100%;
}

.file-container {
  width: 100%;

  .list-group {
    border: none;
    padding: 0;
    overflow-x: auto;
    background: transparent;

    .list-group-item {
      display: flex;
      border-radius: 0;
      background: transparent;
      padding: 12px 20px;
      border-bottom: 1px solid rgba(0,0,0,.125);
      width: 100%;
      overflow-wrap: anywhere;
      .name {
        flex: 1 0 50%;
        color: $grey-700;
        font-weight: $font-weight-bold;
      }

      .error-container {
        color: $danger;
        display: flex;
        align-items: baseline;

        .icon {
          margin-right: $spacing-base * 0.5;
          font-size: 90%;
        }
      }

      .size {
        margin-left: auto;
        flex: 0 0 80px;
      }

      .action {
        flex: 0 0 30px;
        text-align: right;
        cursor: pointer;
      }

      &:first-child {
        border-top: 1px solid $grey-200;
      }

      &.error {
        background-color: $grey-200;
      }
    }
  }

  .file-upload {
    padding: $spacing-base;
  }
}

.upload-file-container {
  height: 100%;
  width: 100%;
  transition: all 0.2s ease-out;

  .upload-file {
    border-radius: 4px;
    border: 2px dashed $grey-400;
    width: 100%;
    height: 100%;
    display: flex;
    min-height: 360px;
    align-items: center;
    justify-content: center;
    position: relative;

    transition: all 0.25s ease-out;

    &:hover {
      border-color: $grey-600;
      background-color: $grey-200;
    }
  }

  .empty-files {
    z-index: 1;
    .title {
      font-size: $font-size-base * 1.5;
      font-weight: $font-weight-base;
      line-height: 1.125;
    }
  }

  &.dragover {
    opacity: 0.5;
  }
}

.input-file {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  z-index: 200;
  cursor: pointer;
  width: 100%;
}

@media screen and (max-width: $max-layout-sm) {
  .upload-file-container {
    padding: $spacing-base * 2;
  }

  .file-container {
     .list-group {
      .list-group-item {
        max-width: 100%;
        flex-direction: column;
        position: relative;
        overflow-wrap: anywhere;

        .name {
          flex: none;
          line-height: 1.2;
          padding-right: $spacing-base * 2;
          margin-bottom: $spacing-base * 0.5;
        }

        .size {
          flex: none;
          font-size: $font-level-8;
          margin-left: unset;
        }

        .action {
          position: absolute;
          top: 12px;
          right: $spacing-base;
        }
      }
     }
  }
}

</style>
