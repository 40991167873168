<template>
  <Modal
    size="medium"
    :title="t('project.defaultSettings.preset.change')"
    :primaryButtonText="t('common:button.save')"
    :secondaryButtonText="t('common:button.cancel')"
    :isDisabledPrimaryButton="saveProjectState.status === 'error' || saveProjectState.status === 'saving'"
    :onClickPrimaryButton="onSubmitPreset"
    :onClickSecondaryButton="onClose"
    @modalClose="onClose">
    <template v-slot:body>
      <div class="loading-container" v-if="loadPresetState.status === 'loading'">
        <i class="fas fa-circle-notch fa-spin fa-4x" />
      </div>
      <div class="options-container" v-if="loadPresetState.status === 'success'">
        <div class="form-radio" v-for="(preset, index) in presets" :key="index">
          <RadioButtonChoice
            :modelValue="isCurrentPreset(preset)"
            :data="preset.id"
            @update:data="onSelectedPreset">
            <div class="preset-name">
              {{preset.name}}
            </div>
            <div
              class="preset-profile-wrapper"
              v-for="profile in preset.profiles"
              :key="profile.id">
              <PresetSummaryInfo :profile="profile" />
            </div>
          </RadioButtonChoice>
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <div class="message-container">
        {{saveErrorCode}}
        <span v-if="saveProjectState.status === 'saving'"><i class="fas fa-spinner fa-spin" />{{t('common:button.saving')}}</span>
        <span class="text-danger" v-if="saveErrorCode === 404"><i class="fa fa-xmark" />{{t('response:error404.title', { item: 'project' })}}</span>
        <span class="text-danger" v-if="saveErrorCode === 422"><i class="fa fa-xmark" />{{t('response:error422.title')}}</span>
        <span class="text-danger" v-if="saveErrorCode === 500"><i class="fa fa-xmark" />{{t('response:error500.title')}}</span>
      </div>
    </template>
  </Modal>
</template>

<script lang="ts" setup>
import {
  computed, onMounted, ref, toRefs,
} from 'vue';
import { useI18n } from 'vue-i18n';
import _ from 'lodash';
import { useStore } from '@/store';
import { formatErrorObject } from '@/modules/shared/utils/errorFormatter';
import type { Filter } from '@/types/Axios.type';
import type { Preset } from '@/modules/preset';
import type { StatusState } from '@/types/State.type';
import { loadPresets } from '@/modules/preset';
import Modal from '@/modules/shared/components/organisms/modal/Modal.vue';
import RadioButtonChoice from '@/modules/shared/components/atoms/radioButtonChoice/RadioButtonChoice.vue';
import PresetSummaryInfo from '../../../../preset/components/molecules/presetSummaryInfo/PresetSummaryInfo.vue';

interface ChangePresetModalProps {
  presetData: Preset | null;
  onSubmit: (preset: Preset) => void;
  onClose: () => void;
}

const props = defineProps<ChangePresetModalProps>();
const {
  presetData,
  onSubmit,
  onClose,
} = toRefs(props);

const { t } = useI18n();
const store = useStore();

const presets = ref<Preset[]>([]);
const selectedPreset = ref<Preset | null>(null);
const loadPresetState = ref<StatusState>({
  status: 'idle',
  error: null,
});

const saveProjectState = computed(() => store.state.project.saveProjectState);
const saveErrorCode = computed(() => _.get(saveProjectState.value, 'error.status', null));

async function load(filter: Filter) {
  loadPresetState.value.status = 'loading';
  try {
    const response = await loadPresets(filter);
    presets.value = response.data.data;
    loadPresetState.value.status = 'success';
  } catch (error) {
    loadPresetState.value.error = formatErrorObject(error, 'Presets');
    loadPresetState.value.status = 'error';
  }
}

function isCurrentPreset(preset: Preset) {
  const currentPresetId = _.get(selectedPreset.value, 'id', null);
  return preset.id === currentPresetId;
}

function onSelectedPreset(presetId: string) {
  selectedPreset.value = _.find(presets.value, { id: presetId }) ?? null;
}

async function onSubmitPreset() {
  if (!_.isNil(selectedPreset.value)) {
    onSubmit.value(selectedPreset.value);
  }
}

onMounted(() => {
  load({ limit: '100' });
  selectedPreset.value = _.cloneDeep(presetData.value);
});
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/global-variables';

.loading-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.form-radio {
  margin-bottom: $spacing-base;
  .preset-name {
    color: $grey-700;
    margin-bottom: $spacing-base * 0.5;
    font-weight: $font-weight-bold;
  }
  .preset-profile-wrapper {
    margin-top: $spacing-base;
    margin-bottom: $spacing-8;
    .gray-badge {
      //padding: 6px 8px;
      //margin-top: $spacing-base * 0.5;
      //margin-right: $spacing-base * 0.5;
      padding: 2px 8px;
      margin-top: $spacing-8;
      margin-right: $spacing-8;
      font-size: $font-size-base;
      font-weight: $font-weight-base;
      color: $grey-800;
      background-color: $grey-200;
    }
  }
}

.label {
  text-transform: capitalize;
}

.message-container i {
  margin-right: $spacing-4;
}

:deep(.save-button){
  width: 100px;
  margin-left: 0 !important;
}

.format {
  text-transform: uppercase;
}

</style>
