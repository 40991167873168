import type { RouteRecordRaw } from 'vue-router';
import store from '@/store';
import { geoblockRuleList } from '@/modules/geoblock';
import { projectVideoList } from '@/modules/projectVideoList';
import { collectionList } from '@/modules/collectionList';
import { projectList } from '@/modules/projectList';
import videoUpload from '../store/videoUpload';
import videoUploaderDraggable from '../store/videoUploaderDraggable';

export default function createVideoUploadRoutes(): RouteRecordRaw[] {
  return [
    {
      name: 'videoUploaderChrome',
      path: 'projects/:projectKey/upload',
      component: () => import(/* webpackChunkName: "VideoUploaderChrome" */ '../layouts/VideoUploaderChrome.vue'),
      beforeEnter: (to, from, next) => {
        if (!store.hasModule('projectList')) {
          store.registerModule('projectList', projectList);
        }
        if (!store.hasModule('projectVideoList')) {
          store.registerModule('projectVideoList', projectVideoList);
        }
        if (!store.hasModule('videoUpload')) {
          store.registerModule('videoUpload', videoUpload);
        }
        if (!store.hasModule('geoblockRuleList')) {
          store.registerModule('geoblockRuleList', geoblockRuleList);
        }
        if (!store.hasModule('videoUploaderDraggable')) {
          store.registerModule('videoUploaderDraggable', videoUploaderDraggable);
        }
        if (!store.hasModule('collectionList')) {
          store.registerModule('collectionList', collectionList);
        }
        next();
      },
      children: [
        {
          name: 'upload',
          path: '',
          component: () => import('../pages/VideoUploader.vue'),
        },
      ],
    },
  ];
}
