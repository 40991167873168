<template>
  <Page
    :title="t('project.advanced.defaults.title')"
    :page-description="t('project.advanced.defaults.subTitle')"
    :projectName="currentProject?.name"
  >
    <div v-if="loadProjectManagementState.status === 'loading'">
      <div class="preloader-container">
        <PreLoaderSection :options="{ height: 200 }" />
      </div>
      <div class="preloader-container">
        <PreLoaderSection :options="{ height: 350 }"/>
      </div>
      <div class="preloader-container">
        <PreLoaderSection :options="{ height: 350 }" />
      </div>
    </div>
    <template v-if="loadProjectManagementState.status === 'success'">
      <ProjectManageUploadSettingPresetSection
        :canUpdateProject="canUpdateProject"
        :isManager="isManagerOrAdmin"
        id="preset" />
      <ProjectManageUploadSettingGeoblockSection
        :canUpdateProject="canUpdateProject"
        :isManager="isManagerOrAdmin"
        id="geoblocking" />
      <ProjectManageUploadSettingSubtitleSection
        :canUpdateProject="canUpdateProject"
        :isManager="isManagerOrAdmin"
        id="subtitle" />
      <ProjectManageUploadSettingPlaybackChannelSection
        v-if="!_.isNil(currentProject) && currentProject.playbackChannels"
        :canUpdateProject="canUpdateProject"
        :isManager="isManagerOrAdmin"
        id="playback"
      />
    </template>
  </Page>
</template>

<script lang="ts" setup>
import _ from 'lodash';
import { computed, onMounted, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';
import { useStore } from '@/modules/projectDefaultUploadSetting/store/hooks';
import Page from '@/layouts/Page.vue';
import PreLoaderSection from '@/modules/shared/components/atoms/preLoaderSection/PreLoaderSection.vue';
import ProjectManageUploadSettingPresetSection from '../components/organisms/projectManageUploadSettingPresetSection/ProjectManageUploadSettingPresetSection.vue';
import ProjectManageUploadSettingGeoblockSection from '../components/organisms/projectManageUploadSettingGeoblockSection/ProjectManageUploadSettingGeoblockSection.vue';
import ProjectManageUploadSettingPlaybackChannelSection from '../components/organisms/projectManageUploadSettingPlaybackChannelSection/ProjectManageUploadSettingPlaybackChannelSection.vue';
import ProjectManageUploadSettingSubtitleSection from '../components/organisms/projectManageUploadSettingSubtitleSection/ProjectManageUploadSettingSubtitleSection.vue';

const { t } = useI18n();
const store = useStore();
const route = useRoute();

const loadProjectManagementState = computed(() => store.state.project.loadProjectManagementState);
const currentProject = computed(() => store.state.project.currentProject);
const canUpdateProject = computed(() => currentProject.value?.actions?.updateProject ?? false);
const isManagerOrAdmin = computed(() => store.getters.isManagerOrAdmin);

const routeHash = computed(() => route.hash);

function onChangeRouteHash(newValue: string) {
  if (newValue === '#playback') {
    const element = document.getElementById('playback');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }
  if (newValue === '#geoblocking') {
    const element = document.getElementById('geoblocking');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }
  if (newValue === '#subtitle') {
    const element = document.getElementById('subtitle');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }
  if (newValue === '#preset') {
    // const element = document.getElementById('preset');
    // if (element) {
    //   element.scrollIntoView({ behavior: 'smooth', block: 'center' });
    // }

    /* Element scrollIntoView not working with preset. Use window.scrollTo instead  */
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }
}

watch(routeHash, onChangeRouteHash);

onMounted(() => {
  onChangeRouteHash(routeHash.value);
});
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/global-variables.scss';

.preloader-container {
  margin-bottom: $spacing-base;
}

:deep(.page .page-content .page-header .page-title-container .page-title) {
  max-width: 100%;
}

:deep(.form-group-content--input) {
  > div {
    margin: 10px 0;
  }
}
</style>
