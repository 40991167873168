import _ from 'lodash';
import { ResolutionItemErrors, ResolutionItemState } from '../types/resolutionItem.type';

const initialState: ResolutionItemState = {
  errors: {
    name: null,
    resolutionType: null,
    resolutionControl: {
      size: null,
      type: null,
    },
    frameRateControl: {
      maxFrameRate: null,
    },
    videoCodecOptions: {
      crf: null,
      targetBitRateKbps: null,
      maxBitRateKbps: null,
      qp: null,
    },
    audioTrack: {
      name: null,
    },
  },
};

const state = _.cloneDeep(initialState);

const mutations = {
  setResolutionItemErrorByFieldName(state: ResolutionItemState, payload: {
    fieldName: keyof ResolutionItemErrors;
    error: string | null;
  }) {
    const newErrors = _.cloneDeep(state.errors);
    _.set(newErrors, payload.fieldName, payload.error);
    state.errors = newErrors;
  },
  setResolutionItemNameError(state: ResolutionItemState, value: string | null) {
    state.errors.name = value;
  },
  setResolutionTypeError(state: ResolutionItemState, value: string | null) {
    state.errors.resolutionType = value;
  },
  setMaxFramerateError(state: ResolutionItemState, value: string | null) {
    state.errors.frameRateControl.maxFrameRate = value;
  },
  setVideoCrfError(state: ResolutionItemState, value: string | null) {
    state.errors.videoCodecOptions.crf = value;
  },
  setVideoTargetBitrateError(state: ResolutionItemState, value: string | null) {
    state.errors.videoCodecOptions.targetBitRateKbps = value;
  },
  setVideoMaxBitrateError(state: ResolutionItemState, value: string | null) {
    state.errors.videoCodecOptions.maxBitRateKbps = value;
  },
  setVideoQpError(state: ResolutionItemState, value: string | null) {
    state.errors.videoCodecOptions.qp = value;
  },
  resetResolutionItemErrorState(state: ResolutionItemState) {
    Object.assign(state, _.cloneDeep(initialState));
  },
};

const actions = {
};

export default {
  state,
  actions,
  mutations,
};
