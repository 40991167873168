<template>
  <div class="uploader-button-container">
    <!-- multiple upload -->
    <input type="file" class="input-file" ref="inputfile" multiple @change="fileBrowsed" v-if="isMultiple" />
    <!-- single upload -->
    <input type="file" class="input-file" ref="inputfile" name="file" @change="fileBrowsed" v-if="!isMultiple" />
    <Button type="button" size="large" buttonStyle="text-primary" buttonClasses="btn-upload">
      <template v-slot:icon-prefix>
        <i class="fas fa-cloud-arrow-up"></i>
      </template>
      <template v-slot:content>
        {{ t('video.uploader.uploadMore') }}
      </template>
    </Button>
  </div>
</template>

<script lang="ts" setup>
import Button from '@/modules/shared/components/atoms/button/Button.vue';
import { toRefs } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from '../../../store/hooks';

interface VideoUploaderButtonProps {
  isMultiple?: boolean;
}

const props = withDefaults(defineProps<VideoUploaderButtonProps>(), {
  isMultiple: true,
});

const { isMultiple } = toRefs(props);
const store = useStore();
const { t } = useI18n();

async function fileBrowsed(e: Event) {
  if (!e.target || !('files' in e.target)) {
    return;
  }

  await store.dispatch('updateVideoFiles', e.target.files);

  if ('value' in e.target) {
    /** Reset selected files. */
    e.target.value = '';
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/global-variables.scss';

.uploader-button-container {
  position: relative;
  cursor: pointer;
}

.input-file {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  z-index: 200;
  cursor: pointer;
  width: 100%;
}
</style>
