import store from '@/store';

const videoFileDraggable = {
  mounted(el, binding) {
    const { isMultiple } = binding.value || true;
    el.addEventListener('drop', (event) => {
      store.dispatch('onDropVideoFile', { files: event.dataTransfer.files, isMultiple });
      event.preventDefault();
    });
    el.addEventListener('dragover', (event) => {
      store.dispatch('onDragOverVideoFileState');
      event.preventDefault();
    });
    el.addEventListener('dragend', (event) => {
      store.dispatch('onDragEndVideoFileState');
      event.preventDefault();
    });
    el.addEventListener('dragleave', (event) => {
      store.dispatch('onDragEndVideoFileState');
      event.preventDefault();
    });
  },
};

export default videoFileDraggable;
