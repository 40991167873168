import {
  LoadBatchListFilter,
  VideoCreator,
  VideoImportBatch,
} from '@/modules/videoImport/types/VideoImportBatch.type';
import { axiosAPI } from '@/plugins/axios';
import { ListApiResponse } from '@/types/Axios.type';
import qs, { IStringifyOptions } from 'qs';
import _ from 'lodash';

const qsOption: IStringifyOptions = {
  addQueryPrefix: true,
  skipNulls: true,
  arrayFormat: 'comma',
  encode: false,
};

export const loadVideoImportBatchList = (filter: LoadBatchListFilter) => {
  const newFilter = _.omit(filter, 'projectKey');
  const query = qs.stringify({ ...newFilter }, qsOption);
  return axiosAPI.get<ListApiResponse<VideoImportBatch[]>>(
    `/projects/${filter.projectKey}/import-batches${query}`,
  );
};

export const loadVideoCreatorList = (projectKey: string) => axiosAPI.get<{ memberships: VideoCreator[] }>(`/projects/${projectKey}/memberships`);

export const loadVideoImportBatchListById = (id: string) => axiosAPI.get<ListApiResponse<VideoImportBatch>>(`/import-batches/${id}`);
