/* eslint-disable no-param-reassign */
import type { ActionContext } from 'vuex';
import _, { cloneDeep } from 'lodash';
import { formatErrorObject } from '@/modules/shared/utils/errorFormatter';
import { setStateSavingStatusByType } from '@/modules/shared/utils/stateManagement';
import type {
  DeleteMemberPayload,
  InviteAndAddMembersPayload,
  InviteMemberResponses, MembershipPayload,
  ProjectMember,
  ProjectMemberRootState,
  ProjectMemberState,
} from '@/modules/projectMember/types';
import type { SavingState } from '@/types/State.type';
import {
  deleteMember,
  editMemberRole,
  addProjectMembers,
  inviteUsersFromOutsideWorkspace,
} from '../services';

type ProjectMemberActionContext = ActionContext<ProjectMemberState, ProjectMemberRootState>;

interface SetInviteProjectMembershipsStateProps {
  savingState: SavingState;
  response: InviteMemberResponses | null;
}

const initialState = (): ProjectMemberState => ({
  inviteProjectMembershipsState: {
    ...setStateSavingStatusByType(),
    response: null,
  },
  updateProjectMembershipsState: setStateSavingStatusByType(),
  deleteProjectMembershipState: setStateSavingStatusByType(),
  editProjectMembershipState: setStateSavingStatusByType(),
});

const state = initialState();

const getters = {};

const mutations = {
  updateProjectMemberships(rootState: ProjectMemberRootState, newMemberships: ProjectMember[]) {
    if (rootState.project.currentProject) {
      rootState.project.currentProject.memberships = [...newMemberships];
    }
  },
  editProjectMembership(rootState: ProjectMemberRootState, editMembership: ProjectMember) {
    if (rootState.project.currentProject) {
      const editIndex = _.findIndex(rootState.project.currentProject.memberships,
        (membership) => membership.accountId === editMembership.accountId);
      rootState.project.currentProject.memberships[editIndex] = editMembership;

      // clone to new array
      rootState.project.currentProject.memberships = [...rootState.project.currentProject.memberships];
    }
  },
  deleteProjectMembership(rootState: ProjectMemberRootState, deleteMembershipId: string) {
    if (rootState.project.currentProject) {
      const deleteIndex = _.findIndex(rootState.project.currentProject.memberships,
        (membership) => membership.accountId === deleteMembershipId);

      rootState.project.currentProject.memberships.splice(deleteIndex, 1);

      rootState.project.currentProject.memberships = [...rootState.project.currentProject.memberships];
    }
  },
  setInviteProjectMembershipsState(state: ProjectMemberState, { savingState, response }: SetInviteProjectMembershipsStateProps) {
    const { status, error } = setStateSavingStatusByType(savingState);
    state.inviteProjectMembershipsState = {
      status,
      error,
      response,
    };
  },
  setUpdateProjectMembershipsState(state: ProjectMemberState, savingState: SavingState) {
    state.updateProjectMembershipsState = setStateSavingStatusByType(savingState);
  },
  setDeleteProjectMembershipState(state: ProjectMemberState, savingState: SavingState) {
    state.deleteProjectMembershipState = setStateSavingStatusByType(savingState);
  },
  setEditProjectMembershipState(state: ProjectMemberState, savingState: SavingState) {
    state.editProjectMembershipState = setStateSavingStatusByType(savingState);
  },
};

const actions = {
  async updateProjectMemberships({ commit }: ProjectMemberActionContext, payload: { projectKey: string, members: MembershipPayload[]}) {
    commit('setUpdateProjectMembershipsState', { type: 'saving' });
    try {
      const response = await addProjectMembers(payload.projectKey, { memberships: payload.members });
      commit('updateProjectMemberships', response.data.memberships, { root: true });
      commit('setUpdateProjectMembershipsState', { type: 'success' });
    } catch (error) {
      commit('setUpdateProjectMembershipsState', { type: 'error', error: formatErrorObject(error, 'Project') });
    }
  },
  async inviteAndAddMembers({ commit }: ProjectMemberActionContext, payload: InviteAndAddMembersPayload) {
    const members = cloneDeep(payload.memberships);
    const toBeInvited = cloneDeep(members.filter((member) => member.notAWorkspaceMember));
    const toBeAdded = cloneDeep(members.filter((member) => !member.notAWorkspaceMember));

    commit('setInviteProjectMembershipsState', { savingState: { type: 'saving' }, response: null });

    try {
      const [inviteRes, addRes] = await Promise.allSettled([
        toBeInvited.length ? inviteUsersFromOutsideWorkspace(payload.projectKey, { memberships: toBeInvited }) : undefined,
        toBeAdded.length ? addProjectMembers(payload.projectKey, { memberships: toBeAdded }) : undefined,
      ]);

      const areMembersInvitedToWorkspace = inviteRes.status === 'fulfilled';
      const areMembersAddedToProject = addRes.status === 'fulfilled';

      if (!areMembersInvitedToWorkspace && !areMembersAddedToProject) {
        /**
         * Perhaps there's something wrong on inviteUsersFromOutsideWorkspace API,
         * because it should always respond 200 even if it failed to invite.
         * */
        throw new Error();
      }

      commit('setInviteProjectMembershipsState', {
        savingState: { type: 'success' },
        response: [
          ...(inviteRes.status === 'fulfilled' && inviteRes.value && Array.isArray(inviteRes.value.data) ? inviteRes.value.data : []),
          ...(inviteRes.status === 'rejected' ? toBeInvited.map((member) => ({ status: 'failed', email: member.email })) : []),
          ...toBeAdded.map((member) => ({ status: areMembersAddedToProject ? 'added' : 'failed', email: member.email })),
        ],
      });
    } catch (error) {
      commit('setInviteProjectMembershipsState', {
        savingState: { type: 'error' },
        error: formatErrorObject(error, 'Project'),
      });
    }
  },
  async deleteProjectMembership({ commit }: ProjectMemberActionContext, payload: DeleteMemberPayload) {
    commit('setDeleteProjectMembershipState', { type: 'loading' });
    try {
      const deleteMembershipId = payload.accountId;
      await deleteMember(payload.projectKey, payload);
      commit('deleteProjectMembership', deleteMembershipId, { root: true });
      commit('setDeleteProjectMembershipState', { type: 'success' });
    } catch (error) {
      commit('setDeleteProjectMembershipState', { type: 'error', error: formatErrorObject(error, 'Project') });
    }
  },
  async editProjectMembership({ commit }: ProjectMemberActionContext, payload: { projectKey: string, accountId: string, role: { projectRole: string }}) {
    commit('setEditProjectMembershipState', { type: 'loading' });
    try {
      const response = await editMemberRole(payload.projectKey, payload);
      const { memberships } = response.data;
      const editMembership = _.find(memberships,
        (member) => member.accountId === payload.accountId);
      commit('editProjectMembership', editMembership, { root: true });
      commit('setEditProjectMembershipState', { type: 'success' });
    } catch (error) {
      commit('setEditProjectMembershipState', { type: 'error', error: formatErrorObject(error, 'Project') });
    }
  },
  clearEditProjectMembershipState({ commit }: ProjectMemberActionContext) {
    commit('setEditProjectMembershipState', { type: 'idle' });
  },
  clearDeleteProjectMembershipState({ commit }: ProjectMemberActionContext) {
    commit('setDeleteProjectMembershipState', { type: 'idle' });
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
