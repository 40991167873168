import store from '@/store';
import videoSources from '@/modules/videoSources/store/videoSources';

export default function createVideoSourcesRoutes() {
  return {
    name: 'videoSources',
    path: 'video-sources',
    component: () => import(/* webpackChunkName: "manageVideoSources" */ '../pages/ManageVideoSources.vue'),
    beforeEnter: (to: any, from: any, next: () => void) => {
      if (!store.hasModule('videoSources')) {
        store.registerModule('videoSources', videoSources);
      }
      next();
    },
    meta: { teamManagement: true },
    children: [],
  };
}
