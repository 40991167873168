import isEmpty from 'lodash/isEmpty';
import qs from 'qs';
import { axiosAPI } from '@/plugins/axios';
import { Country, GeoblockFilterProps, GeoblockRule } from '../types/Geoblock.type';

export const loadGeoblockRules = (filter?: GeoblockFilterProps) => {
  const query = qs.stringify(isEmpty(filter) ? { limit: '50' } : filter, { addQueryPrefix: false, skipNulls: true });
  return axiosAPI.get(`/geoblock-rules?${query}`);
};

export const loadGeoblockRuleById = (id: string) => axiosAPI.get<GeoblockRule>(`geoblock-rules/${id}`);
export const createGeoblockRule = (payload: GeoblockRule) => axiosAPI.post<GeoblockRule>('geoblock-rules', payload);
export const editGeoblockRule = (id: string, payload: GeoblockRule) => axiosAPI.put<GeoblockRule>(`geoblock-rules/${id}`, payload);
export const deleteGeoblockRule = (id: string) => axiosAPI.delete(`geoblock-rules/${id}`);
export const loadCountryList = () => axiosAPI.get<Country[]>('geoblock-rules/countries');
