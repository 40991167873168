<template>
  <!-- TODO: Use SectionCollapse -->
  <div>
    <ChangePresetModal
      v-if="togglePresetModal"
      :presetData="presetData"
      :saveState="saveProjectState"
      :onClose="onTogglePresetModal"
      :onSubmit="onChangePresetData" />
    <Section
      :title="t('project.manage.setting.preset.title')"
      v-if="loadProjectPresetState.status === 'success' && presetData">
      <template v-slot:action>
        <Button
          v-if="canUpdateProject"
          type="button"
          buttonStyle="secondary"
          buttonClasses="change-preset-button"
          @click="onTogglePresetModal"
        >
          <template v-slot:content>
            <span class="button-text-full">
              {{ t('teamSettings.preset.change') }}
            </span>
            <span class="button-text-short">
              {{ t('common:button.change') }}
            </span>
          </template>
        </Button>
      </template>
      <ItemInformationCard :title="presetData.name">
        <div
          class="preset-profile-wrapper"
          v-for="profile in presetData.profiles"
          :key="profile.id"
        >
          <PresetSummaryInfo :profile="profile" />
        </div>
      </ItemInformationCard>
      <template v-slot:section-footer-with-border v-if="isManager">
        <div class="preset-link" v-if="presetData">
          <router-link class="link" :to="`/${teamName}/manage/presets/${presetData.id}`">
            {{ t('project.manage.preset.goToSettings') }} <i class="fas fa-chevron-right" />
          </router-link>
        </div>
      </template>
    </Section>
    <PreLoaderSection
      v-if="loadProjectPresetState.status === 'loading'"
      :options="{ height: '300' }">
    </PreLoaderSection>
  </div>
</template>

<script lang="ts" setup>
import _ from 'lodash';
import {
  computed,
  onMounted,
  ref,
  useAttrs,
} from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';
import { useStore } from '@/modules/projectDefaultUploadSetting/store/hooks';
import type { Preset } from '@/modules/preset';
import type { StatusState } from '@/types/State.type';
import { loadPresetDetail } from '@/modules/preset';
import { setStateLoadingStatusByType } from '@/modules/shared/utils/stateManagement';
import { formatErrorObject } from '@/modules/shared/utils/errorFormatter';
import Section from '@/modules/shared/components/organisms/section/Section.vue';
import ItemInformationCard from '@/modules/shared/components/atoms/itemInformationCard/ItemInformationCard.vue';
import PresetSummaryInfo from '@/modules/preset/components/molecules/presetSummaryInfo/PresetSummaryInfo.vue';
import ChangePresetModal from '@/modules/projectDefaultUploadSetting/components/organisms/changePresetModal/ChangePresetModal.vue';
import Button from '@/modules/shared/components/atoms/button/Button.vue';
import PreLoaderSection from '@/modules/shared/components/atoms/preLoaderSection/PreLoaderSection.vue';

const { t } = useI18n();
const route = useRoute();

const { canUpdateProject, isManager } = useAttrs();

const togglePresetModal = ref(false);
const presetData = ref<Preset | null>(null);
const loadProjectPresetState = ref<StatusState>(setStateLoadingStatusByType());

// Store
const store = useStore();
const currentProject = computed(() => store.state.project.currentProject);
const saveProjectState = computed(() => store.state.project.saveProjectState);

// Computed
const teamName = computed(() => route.params.teamName);

// Methods
const onTogglePresetModal = () => {
  togglePresetModal.value = !togglePresetModal.value;
};
const loadProjectPreset = async (presetId: string) => {
  try {
    loadProjectPresetState.value = setStateLoadingStatusByType({ type: 'loading' });
    const response = await loadPresetDetail(presetId);
    presetData.value = response?.data;
    loadProjectPresetState.value = setStateLoadingStatusByType({ type: 'success' });
  } catch (error) {
    loadProjectPresetState.value = setStateLoadingStatusByType({ type: 'error', error: formatErrorObject(error, 'Preset') });
    // handle
  }
};
const onChangePresetData = async (selectedPreset: Preset) => {
  if (_.isNil(currentProject.value)) {
    return;
  }

  const payload = {
    projectKey: currentProject.value.key,
    data: { ...currentProject.value, presetId: selectedPreset.id },
  };
  await store.dispatch('editProject', payload);
  if (saveProjectState.value?.status === 'success') {
    onTogglePresetModal();
  }
  loadProjectPreset(currentProject.value?.presetId);
};

onMounted(() => {
  if (!_.isNil(currentProject.value)) {
    loadProjectPreset(currentProject.value?.presetId);
  }
});
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/global-variables.scss';
@import '~@/assets/scss/breakpoint.scss';

:deep(.section-prefetch) {
  margin-bottom: 16px;
}

:deep(.preset-profile) {
  margin-bottom: $spacing-base;
}

.button-text-short {
  display: none;
}

.fa-chevron-right {
  margin-left: $spacing-8;
}

@media screen and (max-width: $max-layout-sm) {
  .button-text-short {
    display: inline;
  }
  .button-text-full {
    display: none;
  }
}
</style>
