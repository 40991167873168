import { MultiDrm, WidevineJwtKey } from '@/modules/multiDrm/types';
import { formatErrorObject } from '@/modules/shared/utils/errorFormatter';
import { setStateLoadingStatusByType } from '@/modules/shared/utils/stateManagement';
import type { StatusState } from '@/types/State.type';
import { ActionContext } from 'vuex';
import { getAllMultiDrmConfigs, getWidevineJwtKeysList } from '@/modules/multiDrm/services';
import { RootState } from '@/store/type';
import { Video } from '@/modules/shared/types/video.type';
import { loadVideosAPI } from '@/modules/projectVideoList';

export interface MultiDrmState {
  multiDrmConfigData: MultiDrm | null;
  widevineJwtKeysData: WidevineJwtKey[];
  modelKVideoList: Video[];
  currentStep: number;
  isFairplayCertUploaded: boolean;
  isWidevineServiceRegistered: boolean;
  isWidevineJwtKeysUploaded: boolean;
  isMediaServiceRegistered: boolean;
  isPresetCreated: boolean;
  isPlaybackChannelCreated: boolean;
  loadMultiDrmConfigDataState: StatusState;
}

type MultiDrmActionContext = ActionContext<MultiDrmState, RootState>;

const initialState = (): MultiDrmState => ({
  multiDrmConfigData: null,
  widevineJwtKeysData: [],
  modelKVideoList: [],
  currentStep: 0,
  isFairplayCertUploaded: false,
  isWidevineServiceRegistered: false,
  isWidevineJwtKeysUploaded: false,
  isMediaServiceRegistered: false,
  isPresetCreated: false,
  isPlaybackChannelCreated: false,
  loadMultiDrmConfigDataState: setStateLoadingStatusByType(),
});

const state = initialState();

const getters = {
  isFairplayCertUploaded: (state: MultiDrmState) => (
    state.multiDrmConfigData?.fairplayKsmConfig.isCertUploaded && !!state.multiDrmConfigData?.fairplayKsmConfig.serviceName
  ),
  isWidevineServiceRegistered: (state: MultiDrmState) => (
    state.multiDrmConfigData?.widevineProxyConfig && !!state.multiDrmConfigData?.widevineProxyConfig.defaultPreviewDrmKeyPairId && !!state.multiDrmConfigData?.widevineProxyConfig.serviceName
  ),
  isMediaServiceRegistered: (state: MultiDrmState) => !!state.multiDrmConfigData?.mediaService.hostname,
};

const mutations = {
  setMultiDrmConfigData(state: MultiDrmState, multiDrm: MultiDrm) {
    state.multiDrmConfigData = multiDrm;
  },
  setWidevineJwtKeys(state: MultiDrmState, widevineJwtKeys: WidevineJwtKey[]) {
    state.widevineJwtKeysData = widevineJwtKeys;
  },
  setModelKVideoList(state: MultiDrmState, videoList: Video[]) {
    state.modelKVideoList = videoList;
  },
  setCurrentStep(state: MultiDrmState, currentStep: number) {
    state.currentStep = currentStep;
  },
  setIsFairplayCertUploaded(state: MultiDrmState, value: boolean) {
    state.isFairplayCertUploaded = value;
  },
  setIsWidevineServiceRegistered(state: MultiDrmState, value: boolean) {
    state.isWidevineServiceRegistered = value;
  },
  setIsWidevineJwtKeysUploaded(state: MultiDrmState, value: boolean) {
    state.isWidevineJwtKeysUploaded = value;
  },
  setIsMediaServiceRegistered(state: MultiDrmState, value: boolean) {
    state.isMediaServiceRegistered = value;
  },
  setIsPresetCreated(state: MultiDrmState, value: boolean) {
    state.isPresetCreated = value;
  },
  setIsPlaybackChannelCreated(state: MultiDrmState, value: boolean) {
    state.isPlaybackChannelCreated = value;
  },
  resetMultiDrmState(state: MultiDrmState) {
    Object.assign(state, initialState());
  },
};

const actions = {
  async loadMultiDrmConfig({ state, commit }: MultiDrmActionContext) {
    state.loadMultiDrmConfigDataState = setStateLoadingStatusByType({ type: 'loading' });

    try {
      const multiDrmConfigsResponse = await getAllMultiDrmConfigs();
      if (multiDrmConfigsResponse.data.data) {
        const multiDrmConfig = multiDrmConfigsResponse.data.data[0];

        let widevineJwtKeys:WidevineJwtKey[] = [];

        if (multiDrmConfig.fairplayKsmConfig.isCertUploaded && !!multiDrmConfig.fairplayKsmConfig.serviceName && multiDrmConfig.widevineProxyConfig) {
          const widevineJwtKeysResponse = await getWidevineJwtKeysList(multiDrmConfig.id);
          widevineJwtKeys = widevineJwtKeysResponse.data;
        }

        const loadVideoResponse = await loadVideosAPI({ limit: 10, vodDeliveryModel: 'model-k' });

        commit('setMultiDrmConfigData', multiDrmConfig);
        commit('setWidevineJwtKeys', widevineJwtKeys);
        commit('setModelKVideoList', loadVideoResponse.data.data);
      }

      state.loadMultiDrmConfigDataState = setStateLoadingStatusByType({ type: 'success' });
    } catch (error) {
      state.loadMultiDrmConfigDataState = setStateLoadingStatusByType({
        type: 'error',
        error: formatErrorObject(error, 'Multi-DRM Config'),
      });
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
