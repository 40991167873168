import store from '@/store';
import {
  urlSigningKey,
  urlSigningKeyList,
  ManageUrlSigningKeyChrome,
  CreateUrlSigningKeyModal,
} from '@/modules/urlSigningKey';
import type { RouteRecordRaw } from 'vue-router';
import playbackChannelList from '../store/playbackChannelList';
import playbackChannel from '../store/playbackChannel';

export function createPlaybackChannelRoutes(): RouteRecordRaw[] {
  return ([
    {
      path: 'allowed-blocked-website',
      name: 'allowedBlockedWebsite',
      component: () => import(/* webpackChunkName: "manageSecuritySetting" */ '../pages/ManageAccessControlPolicy.vue'),
      meta: { teamManagement: true },
      beforeEnter: (to, from, next) => {
        if (!store.hasModule('playbackChannelList')) {
          store.registerModule('playbackChannelList', playbackChannelList);
        }
        next();
      },
    },
    {
      path: 'signed-url',
      name: 'signedUrl',
      component: () => import(/* webpackChunkName: "manageSignedUrl" */ '../pages/ManageSignedUrl.vue'),
      meta: { teamManagement: true },
      beforeEnter: (to, from, next) => {
        if (!store.hasModule('playbackChannelList')) {
          store.registerModule('playbackChannelList', playbackChannelList);
        }
        if (!store.hasModule('urlSigningKeyList')) {
          store.registerModule('urlSigningKeyList', urlSigningKeyList);
        }
        next();
      },
    },
    {
      path: 'url-signing-keys',
      component: ManageUrlSigningKeyChrome,
      meta: { teamManagement: true },
      children: [
        {
          path: 'create',
          components: {
            default: () => import(/* webpackChunkName: "manageSignedUrl" */ '../pages/ManageSignedUrl.vue'),
            createURLSigningKey: CreateUrlSigningKeyModal,
          },
        },
      ],
      beforeEnter: (to, from, next) => {
        if (!store.hasModule('playbackChannelList')) {
          store.registerModule('playbackChannelList', playbackChannelList);
        }
        if (!store.hasModule('urlSigningKeyList')) {
          store.registerModule('urlSigningKeyList', urlSigningKeyList);
        }
        if (!store.hasModule('urlSigningKey')) {
          store.registerModule('urlSigningKey', urlSigningKeyList);
        }
        next();
      },
    },
    {
      path: 'playback-channels',
      component: () => import(/* webpackChunkName: "ManagePlaybackChannelChrome" */ '../layouts/ManagePlaybackChannelChrome.vue'),
      meta: { teamManagement: true },
      beforeEnter: (to, from, next) => {
        if (!store.hasModule('playbackChannelList')) {
          store.registerModule('playbackChannelList', playbackChannelList);
        }
        if (!store.hasModule('playbackChannel')) {
          store.registerModule('playbackChannel', playbackChannel);
        }
        if (!store.hasModule('urlSigningKey')) {
          store.registerModule('urlSigningKey', urlSigningKey);
        }
        if (!store.hasModule('urlSigningKeyList')) {
          store.registerModule('urlSigningKeyList', urlSigningKeyList);
        }
        next();
      },
      children: [
        {
          path: '',
          name: 'playbackChannels',
          component: () => import(/* webpackChunkName: "ManagePlaybackChannel" */ '../pages/ManagePlaybackChannel.vue'),
        },
        {
          path: 'create',
          name: 'createPlaybackChannel',
          components: {
            default: () => import(/* webpackChunkName: "ManagePlaybackChannel" */ '../pages/ManagePlaybackChannel.vue'),
            createPlaybackChannel: () => import(/* webpackChunkName: "createPlaybackChannel" */ '../components/organisms/createPlaybackChannelModal/CreatePlaybackChannelModal.vue'),
          },
        },
        {
          name: 'playbackChannelDetail',
          path: ':playbackChannelId',
          component: () => import(/* webpackChunkName: "ManagePlaybackChannelEdit" */ '../pages/ManagePlaybackChannelEdit.vue'),
        },
      ],
    },
  ]);
}
