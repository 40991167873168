import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "section-file-container" }
const _hoisted_2 = { class: "upload-file" }
const _hoisted_3 = {
  key: 1,
  class: "file-container"
}
const _hoisted_4 = {
  class: "list-group",
  "data-test": "file-list-group"
}
const _hoisted_5 = ["data-test"]
const _hoisted_6 = {
  class: "name",
  "data-test": "video-error-name"
}
const _hoisted_7 = {
  key: 0,
  class: "error-container"
}
const _hoisted_8 = {
  class: "text-danger",
  "data-test": "video-error-message"
}
const _hoisted_9 = ["onClick"]
const _hoisted_10 = ["data-test"]
const _hoisted_11 = {
  class: "name",
  "data-test": "valid-video-name"
}
const _hoisted_12 = {
  class: "size",
  "data-test": "valid-video-size"
}
const _hoisted_13 = ["onClick"]

import { computed, toRefs } from 'vue';
import { useI18n } from 'vue-i18n';
import {
  getVideoFileName,
  getVideoFileSize,
  getVideoFileError,
} from '@/modules/videoDetail/utils/videoFileManagement';
import MessageBox from '@/modules/shared/components/molecules/messageBox/MessageBox.vue';
import { useStore } from '../../../store/hooks';
import { VideoUploadFile } from '../../../types/videoUpload.type';

interface VideoUploaderBoxProps {
  isMultiple?: boolean;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'VideoUploaderBox',
  props: {
    isMultiple: { type: Boolean, default: true }
  },
  setup(__props: any) {

const props = __props;

const { isMultiple } = toRefs(props);
const store = useStore();
const { t } = useI18n();

const isDraggingVideoState = computed(() => store.state.videoUpload.isDraggingVideoState);
const videoFilesValid = computed(() => store.getters.videoFilesValid);
const videoFilesError = computed(() => store.getters.videoFilesError);
const isEmptyVideoFiles = computed(() => store.getters.isEmptyVideoFiles);

async function fileBrowsed(e: Event) {
  if (!e.target || !('files' in e.target)) {
    return;
  }

  await store.dispatch('updateVideoFiles', e.target.files);

  if ('value' in e.target) {
    /** Reset selected files. */
    e.target.value = '';
  }
}

function onRemoveFiles(deleteVideoFile: VideoUploadFile) {
  store.dispatch('removeVideoFile', deleteVideoFile);
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (isEmptyVideoFiles.value)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(["upload-file-container", {'dragover': isDraggingVideoState.value }])
        }, [
          _createElementVNode("div", _hoisted_2, [
            (_unref(isMultiple))
              ? (_openBlock(), _createElementBlock("input", {
                  key: 0,
                  type: "file",
                  class: "input-file",
                  ref: "inputfile",
                  multiple: "",
                  onChange: fileBrowsed
                }, null, 544))
              : _createCommentVNode("", true),
            (!_unref(isMultiple))
              ? (_openBlock(), _createElementBlock("input", {
                  key: 1,
                  type: "file",
                  class: "input-file",
                  ref: "inputfile",
                  name: "file",
                  onChange: fileBrowsed
                }, null, 544))
              : _createCommentVNode("", true),
            _createVNode(MessageBox, {
              class: "empty-files",
              iconClassName: "fas fa-cloud-arrow-up",
              title: _unref(t)(`video.uploader.dragOrClickToUpload${_unref(isMultiple) ? '_plural' : ''}`)
            }, null, 8, ["title"])
          ])
        ], 2))
      : _createCommentVNode("", true),
    (!isEmptyVideoFiles.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("ul", _hoisted_4, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(videoFilesError.value, (video, index) => {
              return (_openBlock(), _createElementBlock("li", {
                class: "list-group-item",
                key: `error-${index}`,
                "data-test": `file-error-${index}`
              }, [
                _createElementVNode("div", _hoisted_6, _toDisplayString(_unref(getVideoFileName)(video)), 1),
                (video.error)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                      _cache[0] || (_cache[0] = _createElementVNode("i", { class: "fas fa-triangle-exclamation icon" }, null, -1)),
                      _createElementVNode("div", _hoisted_8, _toDisplayString(_unref(getVideoFileError)(video)), 1)
                    ]))
                  : _createCommentVNode("", true),
                _createElementVNode("div", {
                  class: "action",
                  onClick: ($event: any) => (onRemoveFiles(video)),
                  "data-test": "video-invalid-delete-button"
                }, _cache[1] || (_cache[1] = [
                  _createElementVNode("i", { class: "far fa-trash-can" }, null, -1)
                ]), 8, _hoisted_9)
              ], 8, _hoisted_5))
            }), 128)),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(videoFilesValid.value, (video, index) => {
              return (_openBlock(), _createElementBlock("li", {
                class: "list-group-item",
                key: index,
                "data-test": `file-valid-${index}`
              }, [
                _createElementVNode("div", _hoisted_11, _toDisplayString(_unref(getVideoFileName)(video)), 1),
                _createElementVNode("div", _hoisted_12, _toDisplayString(_unref(getVideoFileSize)(video)), 1),
                _createElementVNode("div", {
                  class: "action",
                  onClick: ($event: any) => (onRemoveFiles(video)),
                  "data-test": "video-valid-delete-button"
                }, _cache[2] || (_cache[2] = [
                  _createElementVNode("i", { class: "far fa-trash-can" }, null, -1)
                ]), 8, _hoisted_13)
              ], 8, _hoisted_10))
            }), 128))
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})