<template>
  <div class="multiple-select-container">
    <multiselect
      :id="id ? id : undefined"
      ref="multiselect"
      :modelValue="modelValue"
      :options="options"
      :multiple="isMultiple"
      :placeholder="placeholder ? placeholder : $t('common:multiselect.tags.select')"
      :taggable="taggable"
      :label="label"
      :track-by="trackBy"
      :disabled="disabled"
      :searchable="searchable"
      @tag="addNewTag"
      @select="onSelected"
      @remove="onRemoved"
      @search-change="onSearchChange"
      @update:modelValue="(value) => $emit('update:modelValue', value)"
      :selectedLabel="selectedLabel ? selectedLabel : $t('common:multiselect.selected')"
      :selectLabel="selectLabel ? selectLabel : $t('common:multiselect.select')"
      :deselectLabel="deselectLabel ? deselectLabel : $t('common:multiselect.deselect')"
      :tagPlaceholder="tagPlaceholder ? tagPlaceholder : $t('common:multiselect.pressEnterToCreateTag')"
      open-direction="bottom">
      <template
        v-slot:option="props"
        v-if="$slots['option']">
        <slot name="option" v-bind:option="props.option"></slot>
      </template>
      <slot></slot>
      <template v-slot:noOptions>
        {{$t('common:multiselect.tags.new')}}
      </template>
    </multiselect>
  </div>
</template>

<script>
import _ from 'lodash';

export default {
  props: {
    modelValue: {
      type: Array,
      default: () => [],
    },
    id: {
      type: String,
    },
    options: {
      type: Array,
      default: () => [],
    },
    optionWidth: {
      type: String,
    },
    isMultiple: {
      type: Boolean,
      default: true,
    },
    placeholder: {
      type: String,
    },
    taggable: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: 'name',
    },
    selectedLabel: {
      type: String,
    },
    selectLabel: {
      type: String,
    },
    deselectLabel: {
      type: String,
    },
    tagPlaceholder: {
      type: String,
    },
    trackBy: {
      type: String,
      default: 'name',
    },
    addTag: {
      type: Function,
      default: () => {},
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    searchable: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['onChanged', 'onSearch', 'update:modelValue'],
  data() {
    return {
      contentBodyResizeObserver: null,
      selectResizeObserver: null,
      contentBodyElement: null,
    };
  },
  mounted() {
    this.adjustMultiSelectElementStyle();
    this.contentBodyElement = document.getElementById('content-body');
    if (this.contentBodyElement) {
      this.contentBodyResizeObserver = new ResizeObserver(this.onContentBodyResize);
      this.contentBodyResizeObserver.observe(this.contentBodyElement);
    }
    this.selectResizeObserver = new ResizeObserver(this.onSelectResize);
    this.selectResizeObserver.observe(this.$el);
  },
  beforeUnmount() {
    if (this.contentBodyResizeObserver) {
      this.contentBodyResizeObserver.unobserve(this.contentBodyElement);
    }
    if (this.selectResizeObserver) {
      this.selectResizeObserver.unobserve(this.$el);
    }
  },
  methods: {
    addNewTag(selectedOption) {
      const body = { name: selectedOption };
      this.addTag(body);
    },
    onSelected(selectedOption) {
      this.$emit('onChanged', [...this.modelValue, selectedOption]);
    },
    onRemoved(selectedOption) {
      const filterTags = _.filter(
        this.modelValue,
        (tag) => tag[this.trackBy] !== selectedOption[this.trackBy],
      );
      this.$emit('onChanged', filterTags);
    },
    onSearchChange(q) {
      this.$emit('onSearch', { q });
    },
    adjustMultiSelectElementStyle() {
      // Need to move icon into input area because Multiselect component postion has been set to position static.
      const inputArea = this.$el.getElementsByClassName('multiselect__tags')[0];
      const chevronIcon = this.$el.getElementsByClassName('multiselect__select')[0];

      if (inputArea && chevronIcon) {
        inputArea.appendChild(chevronIcon);
      }
    },
    onContentBodyResize() {
      const contentBody = document.getElementById('content-body');

      if (contentBody.clientHeight < contentBody.scrollHeight) {
        this.$el.style.position = 'relative';
      } else {
        this.$el.style.position = 'static';
      }
    },
    onSelectResize() {
      const multiselect = this.$el.getElementsByClassName('multiselect')[0];
      const options = this.$el.getElementsByClassName('multiselect__content-wrapper')[0];

      if (multiselect && options) {
        options.style.width = this.optionWidth || `${multiselect.clientWidth}px`;
      }

      if (options.style.width === 0) {
        options.style.width = '300px';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/global-variables.scss';

.multiselect {
  position: static;
}

.multiselect--active {
  :deep(.multiselect__input) {
    margin-top: $spacing-4;
  }
}

:deep(.multiselect__select) {
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: calc(100% - 2px);
  background: white;

  &::before {
    margin-top: 0;
    margin-right: $spacing-8;
    position: static;
  }
}

:deep(.multiselect__tags-wrap) {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

:deep(.multiselect__tags) {
  border-radius: $border-radius-3;
  padding: 0;
  padding: 8px 12px;
  position: relative;
}

:deep(.multiselect__option),
:deep(.multiselect__tag),
:deep(.multiselect__input) {
  font-size: $font-size-base;
}

:deep(.multiselect__option--highlight) {
  background: $ci-primary;
  &:after {
    background: $ci-primary;
  }
}

:deep(.multiselect__content-wrapper) {
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
  border-radius: $border-radius-3;
}

:deep(.multiselect__input),
:deep(.multiselect__placeholder) {
  font-size: $font-size-base;
  padding: 0;
  margin: 0;
  min-height: unset;
  line-height: unset;
}

:deep(.multiselect__tag) {
  color: #495057;
  background-color: #dee2e6;
  margin: 0;
  font-size: $font-size-base;
}

:deep(.multiselect__option--selected.multiselect__option--highlight) {
  background: $danger;
}

:deep(.multiselect__option--selected.multiselect__option--highlight::after) {
  background: $danger;
}

:deep(.multiselect__tag-icon::after) {
  color: $grey-500;
}

.option {
  max-width: 100%;
  white-space: initial;
}

.option__desc {
  max-width: 80%;
}

@media screen and (max-height: 620px) {
  :deep(.multiselect__content-wrapper) {
    height: 150px !important;
  }
}
</style>
