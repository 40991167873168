import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "radio-heading" }
const _hoisted_2 = {
  key: 0,
  class: "error-message"
}

import { getQueryAsString } from '@/modules/shared/utils/query';
import _ from 'lodash';
import { useI18n } from 'vue-i18n';
import { ref, computed, toRefs } from 'vue';
import { updateCaptionDraftStatus } from '@/modules/videoCaption/services';
import Modal from '@/modules/shared/components/organisms/modal/Modal.vue';
import RadioButtonGroup from '@/modules/shared/components/molecules/radioButtonGroup/RadioButtonGroup.vue';
import { useRoute } from 'vue-router';
import { useStore } from '../../../store/hooks';

interface ChangeVideoCaptionStatusModalProps {
  captionIndex: number;
  onClose: () => void;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'ChangeVideoCaptionStatusModal',
  props: {
    captionIndex: {},
    onClose: { type: Function }
  },
  setup(__props: any) {

const { t } = useI18n();
const store = useStore();

const props = __props;
const { captionIndex, onClose } = toRefs(props);

const route = useRoute();

const videoKey = computed(() => store.state.video.currentVideo?.key);
const caption = computed(() => store.state.captionList?.captionList?.[captionIndex.value]);
const currentSubtitlePage = computed(() => getQueryAsString(route.query, 'page') ?? '1');
const publishStatus = computed(() => caption.value?.publishStatus);
const options = computed(() => [
  {
    label: t('video.subtitle.status.draft'),
    value: 'draft',
  },
  {
    label: t('video.subtitle.status.publish'),
    value: 'published',
  },
]);

const selectedOption = ref<string>(publishStatus.value ?? 'draft');
const isSaving = ref<boolean>(false);
const error = ref<any>(null);

const onChange = (value: string) => {
  selectedOption.value = value;
};

const onSave = async () => {
  if (!caption.value) {
    return;
  }

  isSaving.value = true;

  try {
    if (publishStatus.value !== selectedOption.value) {
      await updateCaptionDraftStatus(caption.value.key, {
        ignoreSwapConstrain: true,
        publishStatus: selectedOption.value as 'published' | 'draft',
      });
      await store.dispatch('loadCaptionList', {
        videoKey: videoKey.value,
        page: currentSubtitlePage.value,
      });

      onClose.value();
    }
    isSaving.value = false;
  } catch (err) {
    error.value = err;
    isSaving.value = false;
  }
};

return (_ctx: any,_cache: any) => {
  return (caption.value)
    ? (_openBlock(), _createBlock(Modal, {
        key: 0,
        size: "small",
        title: _unref(t)('video.subtitle.modal.status.title'),
        primaryButtonText: _unref(t)('video.subtitle.modal.status.change'),
        primaryButtonStyle: "primary",
        onClickPrimaryButton: onSave,
        onClickSecondaryButton: _unref(onClose),
        isSaving: isSaving.value,
        onModalClose: _unref(onClose)
      }, {
        body: _withCtx(() => [
          _createElementVNode("div", null, [
            _createElementVNode("div", _hoisted_1, [
              _createTextVNode(_toDisplayString(_unref(t)('video.subtitle.status.choose')) + " ", 1),
              _createElementVNode("b", null, "'" + _toDisplayString(caption.value.customName) + "'", 1)
            ]),
            _createVNode(RadioButtonGroup, {
              modelValue: selectedOption.value,
              options: options.value,
              onChange: onChange
            }, null, 8, ["modelValue", "options"])
          ])
        ]),
        footer: _withCtx(() => [
          (error.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_unref(_).get(error.value, 'response.data.message')), 1))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["title", "primaryButtonText", "onClickSecondaryButton", "isSaving", "onModalClose"]))
    : _createCommentVNode("", true)
}
}

})