<template>
  <Modal
    :title="isCreateMode ? $t('presets.audioVariants.add') : $t('presets.audioVariants.edit')"
    :primaryButtonText="$t('common:button.save')"
    :isDisabledPrimaryButton="!isEnableSave"
    :onClickPrimaryButton="onConfirm"
    @modalClose="onClose">
    <template v-slot:body>
      <FormInput
        :title="$t('common:form.name')"
        :subtitle="$t('presets.audioVariants.name.helpText')"
        v-model="name" />
      <Field
        :title="$t('presets.audioVariants.languageCode.title')"
        :subtitle="$t('presets.audioVariants.languageCode.helpText')">
        <LanguageSelector
          v-if="languageList"
          @selected="(value) => onSelectLanguage(value)"
          :no-result-message="$t('presets.audioVariants.languageCode.empty')"
          :languageCode="languageCode" />
      </Field>
      <Field>
        <Checkbox
          v-model="isAudioDescription"
          id="audio-description"
          :label="$t('presets.audioVariants.audioDescription')"/>
      </Field>
    </template>
    <template v-slot:footer>
      <div v-if="!isCreateMode && enableDelete" class="delete-container">
        <span class="delete-text">{{ $t('presets.audioVariants.delete.title') }}</span>
        <Button type="button" buttonStyle="link-primary" buttonClasses="btn-delete" @click.stop="onDelete">
          <template v-slot:content>
            {{ $t('presets.audioVariants.delete.button') }}
          </template>
        </Button>
      </div>
    </template>
  </Modal>
</template>

<script>
import {
  onMounted,
  ref,
  watch,
  computed,
} from 'vue';
import Modal from '@/modules/shared/components/organisms/modal/Modal.vue';
import { LanguageSelector } from '@/modules/videoCaption';
import Button from '@/modules/shared/components/atoms/button/Button.vue';
import FormInput from '@/modules/shared/components/molecules/formInput/FormInput.vue';
import Field from '@/modules/shared/components/molecules/field/Field.vue';
import Checkbox from '@/modules/shared/components/atoms/checkbox/Checkbox.vue';
import { useStore } from '../../../store/hooks';

export default {
  props: {
    audioVariants: {
      type: Array,
      required: false,
    },
    editIndex: {
      type: Number,
      required: false,
    },
    enableDelete: {
      type: Boolean,
      required: false,
      default: () => true,
    },
  },
  components: {
    LanguageSelector,
    Modal,
    Button,
    Field,
    FormInput,
    Checkbox,
  },
  emits: ['close', 'confirm', 'delete', 'edit'],
  setup(props, { emit }) {
    const store = useStore();

    const currentVariant = ref(null);
    const name = ref('');
    const languageCode = ref(null);
    const languageCodeErrorMessage = ref('');
    const isAudioDescription = ref(false);
    const isEnableSave = ref(false);
    const isCreateMode = ref(props.editIndex < 0);
    const error = ref(null);

    const languageList = computed(() => store.state.languageList.languageList);

    const checkIfShouldEnableSave = () => {
      languageCodeErrorMessage.value = '';

      if (name.value.trim().length === 0) {
        isEnableSave.value = false;
        return;
      }

      if (languageCode.value.trim().length === 0) {
        isEnableSave.value = false;
        return;
      }

      if (languageCode.value.trim().length > 3) {
        isEnableSave.value = false;
        languageCodeErrorMessage.value = 'validation:preset.languageCode.error';
        return;
      }

      isEnableSave.value = true;
    };

    const onClose = () => {
      emit('close');
    };

    const onConfirm = () => {
      if (!isEnableSave.value) {
        return;
      }

      if (isCreateMode.value) {
        emit('confirm', {
          name: name.value,
          languageCode: languageCode.value,
          characteristic: isAudioDescription.value ? 'public.accessibility.describes-video' : '',
        });
      } else {
        emit('edit', {
          ...currentVariant.value,
          audioVariantKey: currentVariant.value?.key,
          name: name.value,
          languageCode: languageCode.value,
          characteristic: isAudioDescription.value ? 'public.accessibility.describes-video' : '',
        });
      }
    };

    const onDelete = () => {
      emit('delete');
    };

    const onSelectLanguage = (value) => {
      languageCode.value = value.code6392T;
    };

    watch(() => name.value, () => {
      checkIfShouldEnableSave();
    });

    watch(() => languageCode.value, () => {
      checkIfShouldEnableSave();
    });

    onMounted(() => {
      if (!isCreateMode.value) {
        currentVariant.value = props.audioVariants[props.editIndex];
        if (currentVariant.value) {
          name.value = currentVariant.value.name;
          languageCode.value = currentVariant.value.languageCode;
          isAudioDescription.value = currentVariant.value.characteristic || false;
        }
      } else {
        languageCode.value = '';
      }

      if (!languageList.value) {
        store.dispatch('loadLanguageList');
      }
    });

    return {
      error,
      isAudioDescription,
      isCreateMode,
      isEnableSave,
      languageCode,
      languageCodeErrorMessage,
      languageList,
      name,
      onConfirm,
      onClose,
      onDelete,
      onSelectLanguage,
    };
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/global-variables';
@import '~@/assets/scss/breakpoint';

:deep(.modal-body) {
  min-height: 400px;
}

.title {
  text-transform: capitalize;
}

.name {
  font-weight: $font-weight-bold;
  text-transform: capitalize;
}

.audio-description-group {
  margin-bottom: 0;
}

.delete-container {
  display: flex;
  align-items: center;
  :deep(.btn-delete) {
    margin-left: $spacing-8;
  }
}
</style>
