import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import MultiSelect from '@/modules/shared/components/atoms/multiSelect/MultiSelect.vue';
import { useStore } from '@/store';
import {
  onMounted,
  ref,
  watch,
  Ref,
  PropType,
  computed, WritableComputedRef, toRefs,
} from 'vue';

// Types
import { mapCountryCodesToObject } from '@/modules/geoblock';
import { Country } from '@/modules/shared/types/country.type';
import { useI18n } from 'vue-i18n';


export default /*@__PURE__*/_defineComponent({
  __name: 'CountryCodesSelector',
  props: {
  modelValue: {
    type: Array as PropType<string[]>,
  },
  disabledCountryCode: {
    type: Array as PropType<string[]>,
    required: false,
  },
  disabled: {
    type: Boolean,
  },
},
  emits: ["update:modelValue"],
  setup(__props, { emit: __emit }) {

const { t } = useI18n();
const props = __props;

const emits = __emit;
const { modelValue, disabledCountryCode, disabled } = toRefs(props);

const selectedCountriesData: Ref<string[]> = ref([]);

// store
const store = useStore();
const countryList = computed<Country[]>(() => store.state.geoblockRuleList.countryList);
const isEmptyCountryList = computed(() => store.getters.isEmptyCountryList);

// computed
const selectedCountries: WritableComputedRef<Country[]> = computed({
  get() {
    return mapCountryCodesToObject(selectedCountriesData.value, countryList.value);
  },
  set(newValue) {
    selectedCountriesData.value = newValue.map((item) => item.countryCode);
  },
});

const filteredCountryList = computed(() => {
  if (disabledCountryCode?.value && disabledCountryCode.value.length) {
    return countryList.value.filter((country) => !disabledCountryCode.value
      ?.includes(country?.countryCode ?? 'this country code is not available'));
  }
  return countryList.value;
});

// methods
const loadCountryList = async () => {
  if (isEmptyCountryList.value) {
    await store.dispatch('loadCountryList');
  }
};

watch(selectedCountriesData, () => {
  if (modelValue?.value !== selectedCountriesData.value) {
    emits('update:modelValue', selectedCountriesData.value);
  }
});

onMounted(() => {
  if (modelValue?.value) {
    selectedCountriesData.value = [...modelValue.value];
  }
  loadCountryList();
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(MultiSelect, {
    modelValue: selectedCountries.value,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((selectedCountries).value = $event)),
    options: filteredCountryList.value,
    trackBy: "countryCode",
    label: "countryNameEN",
    placeholder: _unref(t)('common:multiselect.country.select'),
    selectedLabel: _unref(t)('common:multiselect.selected'),
    disabled: _unref(disabled),
    id: "country-code",
    searchable: ""
  }, null, 8, ["modelValue", "options", "placeholder", "selectedLabel", "disabled"]))
}
}

})