<template>
  <div class="help-text">
    <span v-if="!isHtml">{{ helpText }}</span>
    <div v-else v-html="helpText" />
  </div>
</template>

<script lang="ts" setup>
import { toRefs } from 'vue';

interface FormHelpTextProps {
  helpText?: string;
  isHtml?: boolean;
}

const props = withDefaults(defineProps<FormHelpTextProps>(), {
  isHtml: false,
});
const { helpText, isHtml } = toRefs(props);
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/global-variables.scss";
.help-text {
  font-size: $font-level-8;
  color: $grey-600;
  margin-top: $spacing-4;

  :deep(strong) {
    font-weight: 500;
  }
}
</style>
