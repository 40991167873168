/* eslint no-shadow: ["error", { "allow": ["state", "getters"] }] */
/* eslint no-unused-vars: "off" */

import { ActionContext } from 'vuex';
import { RootState } from '@/store/type';
import { VideoUploaderDraggableState } from '../types/videoUpload.type';

type VideoUploaderDraggableAcionContext = ActionContext<VideoUploaderDraggableState, RootState>;

const initialState = (): VideoUploaderDraggableState => ({
  isDraggingVideoState: false,
});

const state = initialState();

const mutations = {
  setIsDraggingVideoState(state: VideoUploaderDraggableState, boolean: boolean) {
    state.isDraggingVideoState = boolean;
  },
};

const actions = {
  onDropVideoFile({ dispatch }: VideoUploaderDraggableAcionContext, { files, isMultiple }: { files: File[], isMultiple: boolean}) {
    if (!files) {
      return;
    }

    dispatch('onDragEndVideoFileState', true);

    if (isMultiple) {
      dispatch('updateVideoFiles', files);
    } else {
      dispatch('updateVideoSingleFile', files);
    }
  },
  onDragEndVideoFileState({ commit }: VideoUploaderDraggableAcionContext) {
    commit('setIsDraggingVideoState', false);
  },
  setIsDraggingVideoState({ commit }: VideoUploaderDraggableAcionContext) {
    commit('setIsDraggingVideoState', true);
  },
  onDragOverVideoFileState({ commit }: VideoUploaderDraggableAcionContext) {
    commit('setIsDraggingVideoState', true);
  },
};

export default {
  state,
  mutations,
  actions,
};
