import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Teleport as _Teleport, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, withModifiers as _withModifiers, normalizeClass as _normalizeClass, createSlots as _createSlots, Fragment as _Fragment } from "vue"

const _hoisted_1 = ["innerHTML"]
const _hoisted_2 = { class: "bumper-draggable-group" }
const _hoisted_3 = ["aria-label"]
const _hoisted_4 = { class: "title-container" }
const _hoisted_5 = { class: "source-option-container" }
const _hoisted_6 = { class: "source-option-title" }
const _hoisted_7 = { class: "mode-information" }
const _hoisted_8 = { class: "mode-list" }
const _hoisted_9 = { class: "disabled-notice" }
const _hoisted_10 = { class: "disabled-text" }
const _hoisted_11 = ["href"]
const _hoisted_12 = {
  key: 0,
  class: "fas fa-grip-vertical"
}
const _hoisted_13 = {
  key: 1,
  class: "fas fa-lock"
}
const _hoisted_14 = {
  key: 0,
  class: "video-container"
}
const _hoisted_15 = { class: "video-image" }
const _hoisted_16 = {
  key: 0,
  class: "video-duration"
}
const _hoisted_17 = { class: "ellipsis-menu" }
const _hoisted_18 = { class: "bumper-title" }
const _hoisted_19 = ["onClick"]
const _hoisted_20 = { class: "bumper-remark" }
const _hoisted_21 = { class: "bumper-title" }
const _hoisted_22 = ["onClick"]
const _hoisted_23 = { class: "bumper-remark" }
const _hoisted_24 = { class: "dates" }
const _hoisted_25 = { class: "start-date" }
const _hoisted_26 = { class: "bumper-pad" }
const _hoisted_27 = { class: "end-date" }
const _hoisted_28 = { class: "bumper-pad" }
const _hoisted_29 = {
  key: 0,
  class: "day-badge"
}
const _hoisted_30 = {
  key: 0,
  class: "empty-container"
}
const _hoisted_31 = { class: "empty-title-container" }
const _hoisted_32 = {
  key: 0,
  class: "empty-title"
}
const _hoisted_33 = {
  key: 1,
  class: "empty-title"
}
const _hoisted_34 = {
  key: 0,
  class: "empty-description-container"
}
const _hoisted_35 = {
  key: 0,
  class: "empty-description"
}
const _hoisted_36 = {
  key: 1,
  class: "empty-description"
}
const _hoisted_37 = {
  key: 1,
  class: "add-button"
}

import { loadVideoByKey } from '@/modules/videoDetail/services';
import _ from 'lodash';
import draggable from 'vuedraggable';
import {
  computed, ref, toRefs, watch,
} from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';
import { Bumper } from '@/modules/bumper/types';
import {
  BumperSource,
  ChooseBumperModalSubmitPayload,
  SummaryBumperContent,
} from '@/modules/bumperDisplay/types';
import { getQueryAsString } from '@/modules/shared/utils/query';
import ImageView from '@/modules/shared/components/atoms/imageView/ImageView.vue';
import { getVideoUploadCoverImage, getVideoDuration } from '@/modules/videoDetail/utils/videoManagement';
import { formatVideoDateTime } from '@/modules/shared/utils/dateFormatter';
import { useBumper } from '@/modules/bumper/utils';
import Modal from '@/modules/shared/components/organisms/modal/Modal.vue';
import ChooseBumperModal from '@/modules/bumperDisplay/components/organisms/chooseBumperModal/ChooseBumperModal.vue';
import Button from '@/modules/shared/components/atoms/button/Button.vue';
import DataTable from '@/modules/shared/components/organisms/dataTable/DataTable.vue';
import Row from '@/modules/shared/components/molecules/row/Row.vue';
import Column from '@/modules/shared/components/molecules/column/Column.vue';
import HeaderCell from '@/modules/shared/components/molecules/headerCell/HeaderCell.vue';
import SelectOption from '@/modules/shared/components/atoms/selectOption/SelectOption.vue';
import Dropdown from '@/modules/shared/components/atoms/dropdown/Dropdown.vue';
import Tooltip from '@/modules/shared/components/atoms/tooltip/Tooltip.vue';
import Alert from '@/modules/shared/components/atoms/alert/Alert.vue';
import Badge from '@/modules/shared/components/atoms/badge/Badge.vue';
import { useStore } from '../../../store/hooks';

interface BumperDraggableGroupProps {
  value: Array<Bumper> | null;
  name?: string;
  parentBumper?: SummaryBumperContent | null;
  inheritedBumpers?: Array<Bumper>;
}

interface SourceOption {
  name: string;
  sourceType: string;
}

interface onMoveEvent {
  newIndex: number;
  oldIndex:number;
  item: Record<string, string>;
  from: Record<string, string>;
  to: Record<string, string>;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'BumperDraggableGroup',
  props: {
    value: {},
    name: { default: 'Intro 1' },
    parentBumper: {},
    inheritedBumpers: {}
  },
  emits: ["update:modelValue", "add"],
  setup(__props: any, { emit: __emit }) {


const props = __props;

const {
  value,
  name,
  parentBumper,
  inheritedBumpers,
} = toRefs(props);

const emit = __emit;

const { t } = useI18n();
const store = useStore();
const route = useRoute();

const clonedValue = ref<Array<Bumper>>([]);
const customBumperData = ref<Array<Bumper>>([]);
const selectedItem = ref<Bumper | null>(null);
const selectedIndex = ref<number>(-1);
const isShowChooseBumperModal = ref<boolean>(false);
const isShowDeleteBumperModal = ref<boolean>(false);

const options: Array<SourceOption> = [
  { name: t('bumperSchedule.schedule.disabled.title'), sourceType: 'disabled' },
  { name: t('bumperSchedule.schedule.custom.title'), sourceType: 'custom' },
  { name: t('bumperSchedule.schedule.parent.title'), sourceType: 'parent' },
];

const selectedBumperOption = ref<SourceOption>(options[0]);

const bumperSources = computed<BumperSource[]>(() => store.state.bumperDisplay.bumperSources);
const showSaveErrorAlertBox = computed(() => store.state.bumperDisplay.showSaveErrorAlertBox);
const currentUser = computed(() => store.state.user.currentUser);
const hasParentBumper = computed(() => !_.isEmpty(parentBumper?.value));
const currentBumperSourceType = computed(() => selectedBumperOption.value?.sourceType);
const bumperSourceObject = computed(() => (
  bumperSources.value?.find((source) => source.positionName === name.value.toLowerCase().replace(/\s/g, ''))
));
const parentBumpersDisplaySource = computed(() => parentBumper?.value?.bumperDisplaySource);
const disabledBumperPosition = computed(() => {
  const disabledBumperList: string[] = [];
  if (bumperSources.value.length) {
    bumperSources.value.forEach((bumper) => {
      if (bumper.sourceType === 'parent' || bumper.sourceType === 'disabled') {
        disabledBumperList.push(bumper.positionName);
      }
    });
  }
  return disabledBumperList;
});

const teamName = computed(() => route.params.teamName);
const currentTab = computed(() => getQueryAsString(route.query, 'tab'));

function calculateBumperPeriod(bumper: Bumper) {
  const { calculatePeriod } = useBumper();
  const result = calculatePeriod(bumper);
  return result && `${result} ${t(`glossary:day${result > 1 ? '_plural' : ''}`)}`;
}

async function goToBumperContent({ video }: Bumper) {
  if (video?.project) {
    window.open(`/${teamName.value}/projects/${video?.project?.key}/videos/${video?.key}/information`, '_blank');
  } else if (video?.key) {
    try {
      const { data } = await loadVideoByKey(video?.key, { includes: 'project' });
      const projectKey = data.project?.key;
      window.open(`/${teamName.value}/projects/${projectKey}/videos/${video?.key}/information`, '_blank');
    } catch (err) {
      // console.log(err);
    }
  }
}

function onClickAddBumper() {
  emit('add');
}

function getConfirmDeleteText(bumper: Bumper | null): string {
  if (!bumper) {
    return '';
  }

  let text = t('bumperSchedule.bumper.delete.confirmText', { bumperName: bumper.name });
  text = text.replace(`“${bumper.name}”`, `<strong>“${bumper.name}”</strong>`);
  return text;
}

function onClickDelete(item: Bumper, index: number) {
  isShowDeleteBumperModal.value = true;
  selectedItem.value = item;
  selectedIndex.value = index;
}

function onClickEdit(item: Bumper, index: number) {
  isShowChooseBumperModal.value = true;
  selectedItem.value = item;
  selectedIndex.value = index;
}

// eslint-disable-next-line no-shadow
function updateDataToStore(type = name.value, value: Array<Partial<Bumper>> = [...clonedValue.value]) {
  if (type === 'Intro 1' || type === 'intro1') {
    store.commit('setDisplayIntro1', value);
  }
  if (type === 'Intro 2' || type === 'intro2') {
    store.commit('setDisplayIntro2', value);
  }
  if (type === 'Outro 1' || type === 'outro1') {
    store.commit('setDisplayOutro1', value);
  }
  if (type === 'Outro 2' || type === 'outro2') {
    store.commit('setDisplayOutro2', value);
  }
}

function onDeleteBumper() {
  clonedValue.value = _.cloneDeep(value.value ?? []);
  clonedValue.value.splice(selectedIndex.value, 1);
  selectedItem.value = null;
  selectedIndex.value = -1;
  isShowDeleteBumperModal.value = false;
  updateDataToStore();
}

function onCloseDeleteModal() {
  isShowDeleteBumperModal.value = false;
  selectedItem.value = null;
}

function onCloseBumperModal() {
  isShowChooseBumperModal.value = false;
  selectedItem.value = null;
}

function onEditBumper(submitValue: ChooseBumperModalSubmitPayload) {
  const { bumper, type } = submitValue;
  // remove from its current type
  onDeleteBumper();

  // // add to new type
  const newType = store.state.bumperDisplay[`${type}`];

  updateDataToStore(type, [...newType, bumper]);
  isShowChooseBumperModal.value = false;
}

function onMove(e: onMoveEvent) {
  const { newIndex, oldIndex } = e;
  // eslint-disable-next-line no-underscore-dangle
  const element = e.item._underlying_vm_;
  const fromCategory = e.from?.ariaLabel;
  const toCategory = e.to?.ariaLabel;
  store.dispatch('onMoveDisplay', {
    fromCategory,
    toCategory,
    element,
    newIndex,
    oldIndex,
  });
}

function onChangeBumperSourceType(sourceObject: SourceOption) {
  selectedBumperOption.value = sourceObject;
  const bumperSourceType = sourceObject.sourceType;

  if (bumperSourceType === 'disabled') {
    clonedValue.value = [];
  }
  if (bumperSourceType === 'custom' && inheritedBumpers?.value) {
    const currentBumperId = value.value?.map((bumper) => bumper.id);
    const parentBumperId = inheritedBumpers.value.map((bumper) => bumper.id);
    const isParentBumperData = _.isEqual(currentBumperId, parentBumperId) && !_.isEmpty(currentBumperId) && !_.isEmpty(parentBumperId);

    // To remove parent bumper when change to custom choice.
    if (!isParentBumperData) {
      clonedValue.value = !_.isNil(customBumperData.value) && !_.isEmpty(customBumperData.value) ? customBumperData.value : value.value ?? [];
    } else {
      clonedValue.value = [];
    }
  }
  if (bumperSourceType === 'parent') {
    if (hasParentBumper.value && inheritedBumpers?.value) {
      clonedValue.value = inheritedBumpers.value;
    } else {
      clonedValue.value = [];
    }
  }

  updateDataToStore();
}

function getBadgeStyle(bumper: Bumper) {
  const { getStatusBadgeStyle } = useBumper();
  return getStatusBadgeStyle(bumper);
}

watch(value, () => {
  clonedValue.value = _.cloneDeep(value.value ?? []);
  store.dispatch('setShowSaveErrorAlertBox', { positionName: name.value.toLowerCase().replace(/\s/g, ''), value: false });
}, { deep: true, immediate: true });

watch(bumperSourceObject, () => {
  if (bumperSourceObject.value) {
    selectedBumperOption.value = _.find(
      options,
      { sourceType: bumperSourceObject.value?.sourceType },
    ) ?? options[0];
  }
}, { deep: true, immediate: true });

watch(currentBumperSourceType, (newType, oldType) => {
  const payload = {
    positionName: name.value.toLowerCase().replace(/\s/g, ''),
    sourceType: newType,
  };
  store.dispatch('setBumperSourceByPositionName', payload);
  if (oldType === 'custom') {
    // save selected custom data
    customBumperData.value = _.cloneDeep(value.value ?? []);
  }
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
      (isShowDeleteBumperModal.value)
        ? (_openBlock(), _createBlock(Modal, {
            key: 0,
            onModalClose: onCloseDeleteModal,
            onClickPrimaryButton: onDeleteBumper,
            title: _unref(t)('bumperSchedule.bumper.delete.title'),
            primaryButtonStyle: "danger",
            primaryButtonText: _unref(t)('common:button.remove'),
            size: "small"
          }, {
            body: _withCtx(() => [
              _createElementVNode("div", {
                class: "delete-text",
                innerHTML: getConfirmDeleteText(selectedItem.value)
              }, null, 8, _hoisted_1)
            ]),
            _: 1
          }, 8, ["title", "primaryButtonText"]))
        : _createCommentVNode("", true),
      (isShowChooseBumperModal.value)
        ? (_openBlock(), _createBlock(ChooseBumperModal, {
            key: 1,
            onModalClose: onCloseBumperModal,
            bumper: selectedItem.value,
            categoryName: _unref(name),
            disabledCategories: disabledBumperPosition.value,
            onSubmit: _cache[0] || (_cache[0] = (submitValue) => onEditBumper(submitValue))
          }, null, 8, ["bumper", "categoryName", "disabledCategories"]))
        : _createCommentVNode("", true)
    ])),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        "aria-label": _unref(name),
        class: "bumper-group-title"
      }, [
        _createElementVNode("div", _hoisted_4, [
          _renderSlot(_ctx.$slots, "default")
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _cache[6] || (_cache[6] = _createTextVNode(" Mode: ")),
            _createVNode(Tooltip, null, {
              "tooltip-text": _withCtx(() => [
                _createElementVNode("div", _hoisted_7, [
                  _cache[3] || (_cache[3] = _createTextVNode(" Mode")),
                  _cache[4] || (_cache[4] = _createElementVNode("br", null, null, -1)),
                  _createElementVNode("ul", _hoisted_8, [
                    _createElementVNode("li", null, [
                      _createElementVNode("strong", null, _toDisplayString(_unref(t)('bumperSchedule.schedule.disabled.title')), 1),
                      _createTextVNode(" - " + _toDisplayString(_unref(t)('bumperSchedule.schedule.disabled.description')), 1)
                    ]),
                    _createElementVNode("li", null, [
                      _createElementVNode("strong", null, _toDisplayString(_unref(t)('bumperSchedule.schedule.custom.title')), 1),
                      _createTextVNode(" - " + _toDisplayString(_unref(t)('bumperSchedule.schedule.custom.description')), 1)
                    ]),
                    _createElementVNode("li", null, [
                      _createElementVNode("strong", null, _toDisplayString(_unref(t)('bumperSchedule.schedule.parent.title')), 1),
                      _createTextVNode(" - " + _toDisplayString(_unref(t)('bumperSchedule.schedule.parent.description')), 1)
                    ])
                  ])
                ])
              ]),
              default: _withCtx(() => [
                _cache[5] || (_cache[5] = _createElementVNode("i", { class: "fas fa-info-circle" }, null, -1))
              ]),
              _: 1
            })
          ]),
          _createVNode(SelectOption, {
            options: options,
            modelValue: selectedBumperOption.value,
            deselectLabel: "Current",
            "onUpdate:modelValue": onChangeBumperSourceType
          }, null, 8, ["modelValue"])
        ])
      ], 8, _hoisted_3),
      (showSaveErrorAlertBox.value[_unref(name).toLowerCase().replace(/\s/g, '')])
        ? (_openBlock(), _createBlock(Alert, {
            key: 0,
            priority: "danger",
            alertText: _unref(t)('bumperSchedule.schedule.error.empty.title'),
            description: _unref(t)('bumperSchedule.schedule.error.empty.description')
          }, null, 8, ["alertText", "description"]))
        : _createCommentVNode("", true),
      _createElementVNode("div", {
        class: _normalizeClass([{ hasParentBumper: 'disabled' }, "list-container"])
      }, [
        _createVNode(DataTable, null, _createSlots({
          "table-content": _withCtx(() => [
            (hasParentBumper.value && currentBumperSourceType.value === 'parent')
              ? (_openBlock(), _createBlock(Row, { key: 0 }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_9, [
                      _createElementVNode("div", _hoisted_10, [
                        _createTextVNode(_toDisplayString(_unref(t)('bumperSchedule.schedule.currentlyUsed', {position: _unref(name)})) + " ", 1),
                        _createElementVNode("a", {
                          href: `/${teamName.value}/manage/bumper-insertion/${_unref(parentBumper)?.bumperDisplaySourceId}`,
                          target: "_blank",
                          class: "external-link"
                        }, [
                          _createTextVNode(_toDisplayString(parentBumpersDisplaySource.value?.name) + " ", 1),
                          _cache[8] || (_cache[8] = _createElementVNode("i", { class: "fas fa-external-link-alt" }, null, -1))
                        ], 8, _hoisted_11)
                      ])
                    ])
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            _createVNode(_unref(draggable), {
              "aria-label": _unref(name),
              class: _normalizeClass([{ empty: !clonedValue.value?.length }, "list-item-container no-hover-action"]),
              modelValue: clonedValue.value,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((clonedValue).value = $event)),
              group: "section",
              onEnd: _cache[2] || (_cache[2] = (e) => onMove(e)),
              disabled: currentBumperSourceType.value !== 'custom',
              "item-key": "fieldName"
            }, {
              item: _withCtx(({element, index}) => [
                _createVNode(Row, { verticalAlign: "center" }, {
                  default: _withCtx(() => [
                    _createVNode(Column, {
                      width: "50px",
                      "horizontal-align": "center",
                      class: "order-column"
                    }, {
                      default: _withCtx(() => [
                        (currentBumperSourceType.value === 'custom')
                          ? (_openBlock(), _createElementBlock("i", _hoisted_12))
                          : _createCommentVNode("", true),
                        (currentBumperSourceType.value === 'parent')
                          ? (_openBlock(), _createElementBlock("i", _hoisted_13))
                          : _createCommentVNode("", true)
                      ]),
                      _: 1
                    }),
                    _createVNode(Column, {
                      class: "bumper-info-column",
                      verticalAlign: "center"
                    }, {
                      default: _withCtx(() => [
                        (element.video)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                              _createElementVNode("div", _hoisted_15, [
                                _createVNode(ImageView, {
                                  source: _unref(getVideoUploadCoverImage)({
                          video: element.video,
                          currentUser: currentUser.value
                        }),
                                  imageSize: "small"
                                }, null, 8, ["source"]),
                                (element.video?.duration)
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_16, _toDisplayString(_unref(getVideoDuration)(element.video)), 1))
                                  : _createCommentVNode("", true)
                              ]),
                              _createElementVNode("div", _hoisted_17, [
                                _createVNode(Dropdown, { iconClassName: "fas fa-ellipsis-v" })
                              ]),
                              _createElementVNode("div", _hoisted_18, [
                                _createElementVNode("div", {
                                  onClick: ($event: any) => (goToBumperContent(element)),
                                  target: "_blank"
                                }, _toDisplayString(element.name), 9, _hoisted_19),
                                _createElementVNode("div", _hoisted_20, _toDisplayString(element.remark), 1)
                              ])
                            ]))
                          : _createCommentVNode("", true)
                      ]),
                      _: 2
                    }, 1024),
                    _createVNode(Column, { class: "bumper-title-column" }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_21, [
                          _createElementVNode("div", {
                            onClick: ($event: any) => (goToBumperContent(element)),
                            target: "_blank"
                          }, _toDisplayString(element.name), 9, _hoisted_22),
                          _createElementVNode("div", _hoisted_23, _toDisplayString(element.remark), 1)
                        ])
                      ]),
                      _: 2
                    }, 1024),
                    _createVNode(Column, {
                      class: "status-column",
                      width: "90px",
                      verticalAlign: "center"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(Badge, {
                          size: "small",
                          badgeStyle: getBadgeStyle(element),
                          title: element.visibility?.status
                        }, null, 8, ["badgeStyle", "title"])
                      ]),
                      _: 2
                    }, 1024),
                    _createVNode(Column, { class: "date-column" }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_24, [
                          _createElementVNode("div", null, [
                            _createElementVNode("div", _hoisted_25, [
                              _createElementVNode("span", _hoisted_26, _toDisplayString(_unref(t)('bumper.start.title')), 1),
                              _createTextVNode(": " + _toDisplayString(element.visibilitySetting?.startPublishAt
                            ? _unref(formatVideoDateTime)(element.visibilitySetting?.startPublishAt)
                            : '-'), 1)
                            ]),
                            _createElementVNode("div", _hoisted_27, [
                              _createElementVNode("span", _hoisted_28, _toDisplayString(_unref(t)('bumper.end.title')), 1),
                              _createTextVNode(": " + _toDisplayString(element.visibilitySetting?.endPublishAt
                            ? _unref(formatVideoDateTime)(element.visibilitySetting?.endPublishAt)
                            : '-'), 1)
                            ])
                          ]),
                          (calculateBumperPeriod(element))
                            ? (_openBlock(), _createElementBlock("div", _hoisted_29, _toDisplayString(calculateBumperPeriod(element)), 1))
                            : _createCommentVNode("", true)
                        ])
                      ]),
                      _: 2
                    }, 1024),
                    _createVNode(Column, {
                      isActionColumn: "",
                      width: "120px",
                      "vertical-align": "center"
                    }, {
                      default: _withCtx(() => [
                        (currentBumperSourceType.value === 'custom')
                          ? (_openBlock(), _createBlock(Button, {
                              key: 0,
                              buttonStyle: "text-secondary",
                              onClick: _withModifiers(($event: any) => (onClickEdit(index, element)), ["stop"]),
                              "is-square": ""
                            }, {
                              default: _withCtx(() => _cache[9] || (_cache[9] = [
                                _createElementVNode("i", { class: "fas fa-pen" }, null, -1)
                              ])),
                              _: 2
                            }, 1032, ["onClick"]))
                          : _createCommentVNode("", true),
                        (currentBumperSourceType.value === 'custom')
                          ? (_openBlock(), _createBlock(Button, {
                              key: 1,
                              buttonStyle: "text-secondary",
                              onClick: _withModifiers(($event: any) => (onClickDelete(element, index)), ["stop"]),
                              "is-square": ""
                            }, {
                              default: _withCtx(() => _cache[10] || (_cache[10] = [
                                _createElementVNode("i", { class: "fas fa-trash-alt" }, null, -1)
                              ])),
                              _: 2
                            }, 1032, ["onClick"]))
                          : _createCommentVNode("", true)
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 2
                }, 1024)
              ]),
              footer: _withCtx(() => [
                (!clonedValue.value?.length)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_30, [
                      _createElementVNode("div", _hoisted_31, [
                        (currentBumperSourceType.value === 'parent')
                          ? (_openBlock(), _createElementBlock("p", _hoisted_32, _toDisplayString(_unref(t)('bumperSchedule.schedule.parent.empty')), 1))
                          : _createCommentVNode("", true),
                        (currentBumperSourceType.value !== 'parent')
                          ? (_openBlock(), _createElementBlock("p", _hoisted_33, _toDisplayString(_unref(t)('bumperSchedule.schedule.custom.empty')), 1))
                          : _createCommentVNode("", true)
                      ]),
                      (currentTab.value !== 'expired')
                        ? (_openBlock(), _createElementBlock("div", _hoisted_34, [
                            (currentBumperSourceType.value === 'custom')
                              ? (_openBlock(), _createElementBlock("p", _hoisted_35, [
                                  _createTextVNode(_toDisplayString(_unref(t)('bumperSchedule.position.pleaseAdd')) + " ", 1),
                                  _createVNode(Button, {
                                    onClick: onClickAddBumper,
                                    buttonStyle: "link-primary"
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(" + " + _toDisplayString(_unref(t)('bumper.add')), 1)
                                    ]),
                                    _: 1
                                  }),
                                  _createTextVNode(" " + _toDisplayString(_unref(t)('bumperSchedule.position.addTo', {position: _unref(name)})), 1)
                                ]))
                              : _createCommentVNode("", true),
                            (currentBumperSourceType.value !== 'custom')
                              ? (_openBlock(), _createElementBlock("p", _hoisted_36, _toDisplayString(_unref(t)('bumperSchedule.schedule.changeToCustom')), 1))
                              : _createCommentVNode("", true)
                          ]))
                        : _createCommentVNode("", true)
                    ]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["aria-label", "class", "modelValue", "disabled"])
          ]),
          _: 2
        }, [
          ((hasParentBumper.value && clonedValue.value.length) || clonedValue.value?.length)
            ? {
                name: "table-header",
                fn: _withCtx(() => [
                  _createVNode(Row, null, {
                    default: _withCtx(() => [
                      _createVNode(Column, {
                        width: "50px",
                        "horizontal-align": "center"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(HeaderCell, {
                            text: _unref(t)('common:order')
                          }, null, 8, ["text"])
                        ]),
                        _: 1
                      }),
                      _createVNode(Column, null, {
                        default: _withCtx(() => [
                          _createVNode(HeaderCell, {
                            text: _unref(name).split(' ')[0]
                          }, null, 8, ["text"])
                        ]),
                        _: 1
                      }),
                      _createVNode(Column, { width: "90px" }, {
                        default: _withCtx(() => [
                          _createVNode(HeaderCell, {
                            text: _unref(t)('common:form.status')
                          }, null, 8, ["text"])
                        ]),
                        _: 1
                      }),
                      _createVNode(Column, null, {
                        default: _withCtx(() => _cache[7] || (_cache[7] = [
                          _createTextVNode("   ")
                        ])),
                        _: 1
                      }),
                      _createVNode(Column, {
                        isActionColumn: "",
                        width: "120px"
                      })
                    ]),
                    _: 1
                  })
                ]),
                key: "0"
              }
            : undefined
        ]), 1024)
      ]),
      (clonedValue.value?.length && selectedBumperOption.value.sourceType === 'custom')
        ? (_openBlock(), _createElementBlock("div", _hoisted_37, [
            _createVNode(Button, {
              onClick: onClickAddBumper,
              buttonStyle: "secondary"
            }, {
              "icon-prefix": _withCtx(() => _cache[11] || (_cache[11] = [
                _createElementVNode("i", { class: "fas fa-plus" }, null, -1)
              ])),
              default: _withCtx(() => [
                _createTextVNode(" " + _toDisplayString(_unref(t)('bumper.add')) + " " + _toDisplayString(_unref(t)('bumperSchedule.position.addTo', {position: _unref(name)})), 1)
              ]),
              _: 1
            })
          ]))
        : _createCommentVNode("", true)
    ])
  ], 64))
}
}

})