import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createSlots as _createSlots, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

import ErrorToast from '@/modules/shared/components/molecules/toasts/ErrorToast.vue';
import {
  computed, onMounted, ref, toRefs,
} from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import isEmpty from 'lodash/isEmpty';
import { setStateLoadingStatusByType } from '@/modules/shared/utils/stateManagement';
import type { StatusState } from '@/types/State.type';
import Modal from '@/modules/shared/components/organisms/modal/Modal.vue';
import Button from '@/modules/shared/components/atoms/button/Button.vue';
import { formatErrorObject } from '@/modules/shared/utils/errorFormatter';
import { useStore } from '../../../store/hooks';
import ChooseWhatToDoWithVideo from '../../molecules/deleteProjectComponents/ChooseWhatToDoWithVideo.vue';
import ConfirmDeletingProject from '../../molecules/deleteProjectComponents/ConfirmDeletingProject.vue';
import type { DeleteProjectData } from '../../../types';
import { deleteProject, moveVideos } from '../../../services';

interface DeleteProjectModalProps {
  onClose: () => void;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'DeleteProjectModal',
  props: {
    onClose: { type: Function }
  },
  setup(__props: any) {

const props = __props;
const { onClose } = toRefs(props);

const store = useStore();
const router = useRouter();
const { t } = useI18n();

const step = ref<1 | 2>(1);
const confirmDeleteText = ref<string>('');
const deleteProjectData = ref<DeleteProjectData>({
  actionWithVideos: 'moveVideos',
  destinationProject: null,
});
const deleteProjectState = ref<StatusState>(setStateLoadingStatusByType());

const projectList = computed(() => store.state.projectList.projectList ?? []);
const projectName = computed(() => store.state.project.currentProject?.name ?? '');
const projectKey = computed(() => store.state.project.currentProject?.key ?? '');
const videoCount = computed(() => store.state.project.currentProject?.videoCount ?? 0);
const webhookList = computed(() => store.state.webhookList.webhookList);

const willMoveVideos = computed(() => deleteProjectData.value.actionWithVideos === 'moveVideos');
const destinationProjectKey = computed(() => deleteProjectData.value.destinationProject?.key ?? '');

const isDisabledPrimaryButton = computed(() => {
  if (deleteProjectState.value.status === 'loading') {
    return true;
  }

  if (step.value === 1 && deleteProjectData.value.actionWithVideos === 'moveVideos' && !deleteProjectData.value.destinationProject) {
    return true;
  }

  return step.value === 2 && confirmDeleteText.value !== `delete ${projectName.value}`;
});

const primaryButtonText = computed(() => {
  if (step.value === 1) {
    return t('common:button.next');
  }

  if (!videoCount.value) {
    return t('project.deleteProject.step2.button.deleteProjectNoVideos');
  }

  const tParams = { count: videoCount.value };

  if (deleteProjectData.value.actionWithVideos === 'moveVideos') {
    return t(`project.deleteProject.step2.button.deleteAndMoveVideos${videoCount.value > 1 ? '_plural' : ''}`, tParams);
  }

  return t(`project.deleteProject.step2.button.deleteProject${videoCount.value > 1 ? '_plural' : ''}`, tParams);
});

const errorDescription = computed(() => {
  const { error } = deleteProjectState.value;
  if (error?.statusCode === 409) {
    return t('response:deleteProjectError.delete-video-0001');
  }

  if (error) {
    return `${t('response:deleteProjectError.title')} (Error code: ${error.statusCode})`;
  }

  return '';
});

const isShowSecondaryButton = computed(() => {
  if (!videoCount.value) {
    return false;
  }

  return step.value === 2;
});

function onClickSecondaryButton() {
  if (!videoCount.value) {
    return undefined;
  }

  if (step.value === 2) {
    step.value = 1;
  }

  return undefined;
}

async function onConfirmDeleteProject() {
  if (isDisabledPrimaryButton.value) {
    return;
  }

  deleteProjectState.value = setStateLoadingStatusByType({ type: 'loading' });

  try {
    if (willMoveVideos.value) {
      await moveVideos({
        sourceProjectKey: projectKey.value,
        destinationProjectKey: destinationProjectKey.value,
      });
    }

    await deleteProject(projectKey.value);

    store.commit('setIsProjectDeleted', true);
    setTimeout(() => store.commit('setIsProjectDeleted', false), 5000);

    deleteProjectState.value = setStateLoadingStatusByType({ type: 'success' });
    await router.push({ name: 'projectList' });
  } catch (error) {
    console.error(error);
    deleteProjectState.value = setStateLoadingStatusByType({
      type: 'error',
      error: formatErrorObject(error, 'Delete Project'),
    });

    setTimeout(() => onClose.value(), 100);
  }
}

function onClickPrimaryButton() {
  if (step.value === 1) {
    step.value = 2;
  }

  onConfirmDeleteProject();
}

onMounted(() => {
  if (isEmpty(projectList.value)) {
    store.dispatch('loadProjectList');
  }

  if (isEmpty(webhookList.value)) {
    store.dispatch('loadWebhookList');
  }

  if (!videoCount.value) {
    step.value = 2;
    deleteProjectData.value = {
      actionWithVideos: 'deleteVideos',
      destinationProject: null,
    };
  }
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (deleteProjectState.value.status === 'error' && deleteProjectState.value.error)
      ? (_openBlock(), _createBlock(ErrorToast, {
          key: 0,
          title: _unref(t)('response:errorTitle'),
          description: errorDescription.value,
          duration: 10000
        }, null, 8, ["title", "description"]))
      : _createCommentVNode("", true),
    _createVNode(Modal, {
      title: _unref(t)('project.deleteProject.title'),
      onModalClose: _unref(onClose),
      size: "medium",
      primaryButtonText: primaryButtonText.value,
      onClickPrimaryButton: onClickPrimaryButton,
      onClickSecondaryButton: onClickSecondaryButton,
      isShowSecondaryButton: isShowSecondaryButton.value,
      secondaryButtonText: _unref(t)('common:button.back'),
      primaryButtonStyle: "danger",
      isDisabledPrimaryButton: isDisabledPrimaryButton.value
    }, _createSlots({
      body: _withCtx(() => [
        _withDirectives(_createVNode(ChooseWhatToDoWithVideo, {
          modelValue: deleteProjectData.value,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((deleteProjectData).value = $event))
        }, null, 8, ["modelValue"]), [
          [_vShow, step.value === 1]
        ]),
        _withDirectives(_createVNode(ConfirmDeletingProject, {
          modelValue: confirmDeleteText.value,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((confirmDeleteText).value = $event)),
          deleteProjectData: deleteProjectData.value,
          onConfirmDeleteProject: onConfirmDeleteProject
        }, null, 8, ["modelValue", "deleteProjectData"]), [
          [_vShow, step.value === 2]
        ])
      ]),
      _: 2
    }, [
      (step.value === 1)
        ? {
            name: "primary-btn-suffix",
            fn: _withCtx(() => [
              _cache[2] || (_cache[2] = _createElementVNode("i", { class: "far fa-chevron-right" }, null, -1))
            ]),
            key: "0"
          }
        : undefined,
      (step.value === 2)
        ? {
            name: "secondary-btn-prefix",
            fn: _withCtx(() => [
              _cache[3] || (_cache[3] = _createElementVNode("i", { class: "far fa-chevron-left" }, null, -1))
            ]),
            key: "1"
          }
        : undefined,
      (step.value === 2)
        ? {
            name: "footer",
            fn: _withCtx(() => [
              _createVNode(Button, {
                type: "button",
                buttonStyle: "text-secondary",
                onClick: _unref(onClose)
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_unref(t)('common:button.cancel')), 1)
                ]),
                _: 1
              }, 8, ["onClick"])
            ]),
            key: "2"
          }
        : undefined
    ]), 1032, ["title", "onModalClose", "primaryButtonText", "isShowSecondaryButton", "secondaryButtonText", "isDisabledPrimaryButton"])
  ], 64))
}
}

})