import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "content" }
const _hoisted_2 = { class: "file-field" }
const _hoisted_3 = {
  key: 0,
  class: "error-message"
}
const _hoisted_4 = { class: "save-status" }
const _hoisted_5 = {
  key: 0,
  class: "success"
}
const _hoisted_6 = {
  key: 1,
  class: "failed"
}

import type { CaptionDraft } from '@/modules/videoCaption/types';
import {
  computed,
  ref,
  watch,
} from 'vue';
import { useI18n } from 'vue-i18n';
import Field from '@/modules/shared/components/molecules/field/Field.vue';
import FormInput from '@/modules/shared/components/molecules/formInput/FormInput.vue';
import FileButton from '@/modules/shared/components/molecules/fileButton/FileButton.vue';
import FormSelectOption from '@/modules/shared/components/molecules/formSelectOption/FormSelectOption.vue';
import { setStateSavingStatusByType } from '@/modules/shared/utils/stateManagement';
import { formatErrorObject } from '@/modules/shared/utils/errorFormatter';
import SlideOut from '@/modules/shared/components/organisms/slideOut/SlideOut.vue';
import Input from '@/modules/shared/components/atoms/input/Input.vue';
import UserInfoItem from '@/modules/shared/components/atoms/userInfoItem/UserInfoItem.vue';
import LanguageSelector from '../../molecules/languageSelector/LanguageSelector.vue';
import { useStore } from '../../../store/hooks';
import {
  updateCaptionDraftName,
  updateCaptionDraftLanguage,
  updateCaptionDraftStatus,
  downloadCaptionFile,
  updateCaptionDraftFile,
} from '../../../services';

interface PublishStatus {
  name: string;
  status: CaptionDraft['publishStatus'];
}


export default /*@__PURE__*/_defineComponent({
  __name: 'EditVideoCaptionSidebar',
  props: {
  onClose: {
    type: Function,
    required: true,
    default: () => {},
  },
  captionIndex: {
    type: Number,
    required: true,
  },
},
  setup(__props) {

const store = useStore();
const { t } = useI18n();

const props = __props;

const caption = computed(() => {
  const { captionList = [] } = store.state.captionList;
  if (captionList?.length && props.captionIndex >= 0) {
    return captionList[props.captionIndex];
  }
  return null;
});

const key = computed(() => caption.value?.key);
const customName = computed(() => caption.value?.customName);
const publishStatus = computed(() => caption.value?.publishStatus);
const languageCode = computed(() => caption.value?.languageCode);
const updater = computed(() => caption.value?.updater);
const updatedAt = computed(() => caption.value?.updatedAt);
const isManual = computed(() => caption.value?.captionProcessingType?.type === 'manual');

const languageList = computed(() => store.state.languageList.languageList);
const languageDisplayName = computed(
  () => languageList.value.find((lang) => lang.code6392T === languageCode.value)?.displayName,
);

const name = ref(customName.value);
const selectedLanguage = ref('');
const selectedFile = ref<File | null>(null);

const statusOptions: PublishStatus[] = [
  { name: t('video.subtitle.status.draft'), status: 'draft' },
  { name: t('video.subtitle.status.publish'), status: 'published' },
];
const selectedStatus = ref<PublishStatus | undefined>(statusOptions.find((option) => option.status === publishStatus.value));

const onUpdateStatus = (value: PublishStatus) => {
  selectedStatus.value = value;
};

const onUpdateFile = (file: File) => {
  selectedFile.value = file;
};

const downloadFileError = ref();
const downloadFile = async () => {
  if (!key.value) {
    return;
  }

  const fileName = caption.value?.originalFilename || `${caption.value?.customName}.vtt`;
  try {
    const response = await downloadCaptionFile(key.value);
    const captionFile = new File([response.data], `${fileName}`, {
      type: 'text/vtt',
    });
    const fileURL = URL.createObjectURL(captionFile);

    // create dummy  <a></a> for file download
    const a = document.createElement('a');
    a.href = fileURL;
    a.download = `${fileName}`;

    a.click();
    URL.revokeObjectURL(fileURL);
  } catch (err) {
    downloadFileError.value = formatErrorObject(err, 'caption');
  }
};

const savingCaptionState = ref(setStateSavingStatusByType()); // saving || success || failed
const onSubmit = async () => {
  if (!key.value) {
    return;
  }

  const allRequests = [];
  const updatedFields = [];

  try {
    savingCaptionState.value = setStateSavingStatusByType({ type: 'saving' });
    // call API if the value is changed from original in props
    if (name.value && name.value !== caption.value?.customName) {
      allRequests.push(updateCaptionDraftName(key.value, name.value));
      updatedFields.push({ customName: name.value });
    }
    if (selectedLanguage.value && selectedLanguage.value !== caption.value?.languageCode) {
      allRequests.push(updateCaptionDraftLanguage(key.value, selectedLanguage.value));
      updatedFields.push({ languageCode: selectedLanguage.value });
    }
    if (selectedStatus.value && selectedStatus.value.status !== publishStatus.value) {
      allRequests.push(updateCaptionDraftStatus(key.value, {
        publishStatus: selectedStatus.value.status,
        ignoreSwapConstrain: true,
      }));
      updatedFields.push({ publishStatus: selectedStatus.value.status });
    }
    if (selectedFile.value) {
      allRequests.push(
        updateCaptionDraftFile(
          key.value,
          selectedFile.value,
        ),
      );
      updatedFields.push({ originalFilename: selectedFile.value?.name });
    }

    const responses = await Promise.all(allRequests);
    if (responses) {
      // update with latest response
      // cannot use responses from Promise.all as they're concurrent
      const updated = Object.assign({}, ...updatedFields);
      store.dispatch('updateCaptionByIndex', { index: props.captionIndex, payload: updated });
    }
    savingCaptionState.value = setStateSavingStatusByType({ type: 'success' });
  } catch (err) {
    savingCaptionState.value = setStateSavingStatusByType({
      type: 'error',
      error: formatErrorObject(err, 'Caption'),
    });
  }
};

watch(
  () => caption.value,
  () => {
    // clear old input ref when caption changes
    name.value = customName.value;
    selectedLanguage.value = languageCode.value || '';
    selectedStatus.value = statusOptions.find((option) => option.status === publishStatus.value);
    selectedFile.value = null;
  },
  { deep: true },
);

watch(
  () => key.value,
  () => {
    // clear saving state
    savingCaptionState.value = setStateSavingStatusByType();
  },
);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(SlideOut, {
    title: _unref(t)('video.subtitle.edit.title'),
    primaryButtonText: _unref(t)('common:button.save'),
    onClickPrimaryButton: onSubmit,
    isSaving: savingCaptionState.value.status === 'saving',
    secondaryButtonText: _unref(t)('common:button.cancel'),
    onClickSecondaryButton: __props.onClose,
    onSlideOutClose: _cache[2] || (_cache[2] = () => __props.onClose())
  }, {
    footer: _withCtx(() => [
      _createElementVNode("div", _hoisted_4, [
        (savingCaptionState.value.status === 'success')
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _cache[4] || (_cache[4] = _createElementVNode("i", { class: "fas fa-check" }, null, -1)),
              _createTextVNode(" " + _toDisplayString(_unref(t)('common:button.saved')), 1)
            ]))
          : _createCommentVNode("", true),
        (savingCaptionState.value.status === 'error')
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
              _cache[5] || (_cache[5] = _createElementVNode("i", { class: "fas fa-exclamation-triangle" }, null, -1)),
              _createTextVNode(" " + _toDisplayString(savingCaptionState.value.error?.message), 1)
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(FormInput, {
          title: _unref(t)('video.subtitle.displayName.title'),
          subtitle: _unref(t)('video.subtitle.displayName.subtitle'),
          modelValue: name.value || '',
          "onUpdate:modelValue": _cache[0] || (_cache[0] = (value) => (name.value = value))
        }, null, 8, ["title", "subtitle", "modelValue"]),
        _createVNode(Field, {
          title: _unref(t)('video.subtitle.language.title')
        }, {
          default: _withCtx(() => [
            (isManual.value)
              ? (_openBlock(), _createBlock(LanguageSelector, {
                  key: 0,
                  onSelected: _cache[1] || (_cache[1] = (value) => (selectedLanguage.value = value.code6392T)),
                  placeholder: _unref(t)('video.subtitle.language.select'),
                  languageCode: selectedLanguage.value || languageCode.value
                }, null, 8, ["placeholder", "languageCode"]))
              : (_openBlock(), _createBlock(Input, {
                  key: 1,
                  placeholder: languageDisplayName.value,
                  disabled: true,
                  modelValue: caption.value?.languageCode || ''
                }, null, 8, ["placeholder", "modelValue"]))
          ]),
          _: 1
        }, 8, ["title"]),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(Field, {
            title: _unref(t)('glossary:file'),
            helpText: !isManual.value ? `${_unref(t)('video.subtitle.edit.file.helpText')}` : ''
          }, {
            default: _withCtx(() => [
              _createVNode(FileButton, {
                enableReupload: isManual.value,
                fileName: selectedFile.value ? selectedFile.value.name
              : (caption.value?.originalFilename ? caption.value?.originalFilename : caption.value?.customName),
                "onUpdate:file": onUpdateFile,
                onDownloadFile: downloadFile,
                accept: ['.srt', '.vtt']
              }, null, 8, ["enableReupload", "fileName"])
            ]),
            _: 1
          }, 8, ["title", "helpText"]),
          (downloadFileError.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, "File Download Failed."))
            : _createCommentVNode("", true)
        ]),
        _createVNode(FormSelectOption, {
          title: _unref(t)('video.subtitle.status.title'),
          options: statusOptions,
          modelValue: selectedStatus.value,
          label: "name",
          selectLabel: " ",
          searchable: false,
          "onUpdate:modelValue": onUpdateStatus,
          class: "select-status",
          placeholder: _unref(t)('video.subtitle.status.select')
        }, null, 8, ["title", "modelValue", "placeholder"]),
        _cache[3] || (_cache[3] = _createElementVNode("div", { class: "divider" }, null, -1)),
        (updater.value && updatedAt.value)
          ? (_openBlock(), _createBlock(Field, {
              key: 0,
              title: _unref(t)('common:updatedBy')
            }, {
              default: _withCtx(() => [
                _createVNode(UserInfoItem, {
                  data: updater.value,
                  dateTime: updatedAt.value
                }, null, 8, ["data", "dateTime"])
              ]),
              _: 1
            }, 8, ["title"]))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }, 8, ["title", "primaryButtonText", "isSaving", "secondaryButtonText", "onClickSecondaryButton"]))
}
}

})