import type { BadWordListsItem, BadWordListsState } from '@/modules/badWordList/types';
import type { Filter } from '@/types/Axios.type';
import { ActionContext } from 'vuex';
import { setStateLoadingStatusByType } from '@/modules/shared/utils/stateManagement';
import { formatErrorObject } from '@/modules/shared/utils/errorFormatter';
import { RootState } from '@/store/type';
import { LoadingState } from '@/types/State.type';
import { getBadWordLists } from '../services';

type BadWordListsActionContext = ActionContext<BadWordListsState, RootState>;

const initialState = (): BadWordListsState => ({
  badWordLists: null,
  badWordListsLastPage: 1,
  loadBadWordListsState: setStateLoadingStatusByType(),
});

const state = initialState();

const mutations = {
  setBadWordLists(state: BadWordListsState, badWordLists: BadWordListsItem[]) {
    state.badWordLists = badWordLists;
  },
  setLoadBadWordListsState(state: BadWordListsState, loadingState: LoadingState) {
    state.loadBadWordListsState = setStateLoadingStatusByType(loadingState);
  },
  setBadWordListsLastPage(state: BadWordListsState, number: number) {
    state.badWordListsLastPage = number;
  },
  destroyBadWordLists(state: BadWordListsState) {
    Object.assign(state, initialState());
  },
};

const actions = {
  async loadBadWordLists({ commit }: BadWordListsActionContext, filter?: Filter) {
    commit('setLoadBadWordListsState', { type: 'loading' });
    try {
      const response = await getBadWordLists(filter);
      const { data, lastPage } = response.data;
      commit('setBadWordLists', data);
      commit('setBadWordListsLastPage', lastPage);
      commit('setLoadBadWordListsState', { type: 'success' });
    } catch (error) {
      commit('setLoadBadWordListsState', {
        type: 'error',
        error: formatErrorObject(error, 'Collection List'),
      });
    }
  },
  destroyCollectionList({ commit }: BadWordListsActionContext) {
    commit('destroyBadWordLists');
  },
};

export default {
  state,
  mutations,
  actions,
};
