<template>
  <ItemInformationCard v-if="!_.isNil(geoblockRule)" :title="geoblockName">
    <template v-slot:subtitle v-if="!isGeoblockEditable">
      <span class="not-editable-text" data-test="not-editable-text">
        <i class="fas fa-circle-info"></i>
        {{ t('common:notEditable') }}
      </span
      >
    </template>
    <template v-if="isShowEditButton" v-slot:card-actions-stick-top>
      <Tooltip>
        <Button
          buttonStyle="secondary"
          type="button"
          button-classes="btn-duplicate"
          @click.prevent="() => {
            if (typeof onClickDuplicateButton === 'function' && !_.isNil(geoblockRule)) {
              onClickDuplicateButton(geoblockRule)
            }
          }"
        >
          <template v-slot:icon-prefix>
            <i class="fas fa-clone"></i>
          </template>
        </Button>
        <template v-slot:tooltip-text>
          {{ t('common:button.duplicate') }}
        </template>
      </Tooltip>
      <Tooltip>
        <Button
          v-if="isGeoblockEditable && !isDisallowAllCountries(allowCountryCodes)"
          buttonStyle="secondary"
          buttonClasses="btn-edit"
          type="button"
          @click="onClickEditButton"
        >
          <template v-slot:icon-prefix>
            <i class="fas fa-pen" />
          </template>
        </Button>
        <template v-slot:tooltip-text>
          {{ t('common:button.edit') }}
        </template>
      </Tooltip>
    </template>
    <div class="country-wrapper">
      <!-- We will remove all isDisallowAllCountries method after we refactor allowCountryCodes conditions -->
      <span class="label" v-if="isAllowAllCountries" data-test="allow-all-label">
        <i class="fas fa-circle-check allow-icon" />{{ t('video.geoBlock.allowAll') }}</span
      >
      <div class="allow-country-container country-list" v-if="hasAllowCountryCode" data-test="allow-some-label">
        <div class="label" v-if="!isDisallowAllCountries(allowCountryCodes)">
          <i class="fas fa-circle-check allow-icon" />Allowed ({{ allowCountryCodes.length }})
        </div>
        <div class="label" data-test="deny-all-label" v-else><i class="fas fa-ban deny-icon" />All countries are blocked</div>
        <template v-if="!isDisallowAllCountries(allowCountryCodes)">
          <div class="badge-list">
            <Badge
              v-for="country in allowCountryCodes"
              :title="getCountryFullName(country)"
              :key="country" />
          </div>
        </template>
      </div>
      <div class="deny-country-container country-list" v-if="hasDenyCountryCode" data-test="deny-some-label">
        <div class="label" v-if="!isDisallowAllCountries(allowCountryCodes)">
          <i class="fas fa-ban deny-icon" />Blocked ({{ denyCountryCodes.length }})
        </div>
        <template v-if="!isDisallowAllCountries(allowCountryCodes)">
          <div class="badge-list">
            <Badge
              v-for="country in denyCountryCodes"
              :key="country"
              :title="getCountryFullName(country)" />
          </div>
        </template>
      </div>
    </div>
  </ItemInformationCard>
</template>

<script lang="ts" setup>
import _ from 'lodash';
import ItemInformationCard from '@/modules/shared/components/atoms/itemInformationCard/ItemInformationCard.vue';
import Tooltip from '@/modules/shared/components/atoms/tooltip/Tooltip.vue';
import Button from '@/modules/shared/components/atoms/button/Button.vue';
import { computed, onMounted, toRefs } from 'vue';
import { useI18n } from 'vue-i18n';
import Badge from '@/modules/shared/components/atoms/badge/Badge.vue';
import { useStore } from '../../../store/hooks';
import { GeoblockRule } from '../../../types/Geoblock.type';

interface GeoblockRuleCardProps {
  geoblockRule: GeoblockRule | null | undefined;
  onClickEditButton?: () => void;
  onClickDuplicateButton?: (geoblockData: GeoblockRule) => void;
  isShowEditButton?: boolean;
}

const props = defineProps<GeoblockRuleCardProps>();
const {
  geoblockRule,
  onClickEditButton,
  onClickDuplicateButton,
  isShowEditButton,
} = toRefs(props);

const { t } = useI18n();
const store = useStore();

const countryList = computed(() => store.state.geoblockRuleList.countryList);

const isEmptyCountryList = computed(() => store.getters.isEmptyCountryList);

// computed
const allowCountryCodes = computed(() => _.get(geoblockRule.value, 'allowCountryCodes', []));
const denyCountryCodes = computed(() => _.get(geoblockRule.value, 'denyCountryCodes', []));
const hasAllowCountryCode = computed(() => allowCountryCodes.value?.length > 0);
const hasDenyCountryCode = computed(() => denyCountryCodes.value?.length > 0);
const isAllowAllCountries = computed(() => !hasAllowCountryCode.value && !hasDenyCountryCode.value);
const isGeoblockEditable = computed(() => !_.get(geoblockRule.value, 'isGlobalDefault', false));
const geoblockName = computed(() => {
  const ruleId = _.get(geoblockRule.value, 'id', null);
  if (!isAllowAllCountries.value && !ruleId) {
    return t('video.geoBlock.customGeoBlocking');
  }
  return _.get(geoblockRule.value, 'name', t('video.geoBlock.allowAll'));
});

// methods
const loadCountryList = () => {
  if (isEmptyCountryList.value) {
    store.dispatch('loadCountryList');
  }
};

const getCountryFullName = (countryCode: string) => {
  const country = _.find(countryList.value, (eachCountry) => eachCountry.countryCode === countryCode);
  return country ? country.countryNameEN : '';
};

// This method will be remove after we refactor allowCountryCodes conditions
const isDisallowAllCountries = (countryCodes: string[]) => {
  if (countryCodes && countryCodes[0] === 'XX') {
    return true;
  }
  return false;
};

onMounted(() => {
  loadCountryList();
});
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/global-variables.scss';

/* :deep(.btn-edit) {
  margin-left: $spacing-base;
} */
.badge-list {
  display: flex;
  flex-wrap: wrap;
  margin-top: $spacing-8;

  > :not(:last-child) {
    margin-right: $spacing-8;
  }
  > * {
    margin-bottom: $spacing-4;
  }
}
.country-wrapper {
  .allow-icon {
    margin-right: 0.5rem;
    color: $success;
  }

  .deny-icon {
    margin-right: 0.5rem;
    color: $danger;
  }
}

.country-list {
  margin-top: $spacing-base;
  &:first-child {
    margin-top: 0;
  }
}

.not-editable-text {
  color: $grey-600;
}
</style>
