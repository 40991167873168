<template>
  <SelectOption
    v-if="captionProfileList"
    :modelValue="props.modelValue"
    :options="captionProfileList"
    :allow-empty="false"
    track-by="name"
    label="name"
    deselect-label=""
    :placeholder="$t('video.subtitleProfile.select.placeholder')"
    @update:modelValue="onOptionChange"
  >
    <template v-slot:singleLabel="props">
      <div class="option__desc capitalize">
        <span class="option__title option-title">{{ props.option.name }}</span>
        <span class="option__small option-subtitle">
          <div class="subtitle-row">
            <div class="label">{{$t('video.subtitleProfile.option.language')}}:&nbsp;</div>
            {{ props.option.transcribe?.language?.name }}
          </div>
          <div class="subtitle-row">
            <div class="label">{{$t('video.subtitleProfile.option.service')}}:&nbsp;</div>
            {{ getServiceName(props.option.transcribe?.services) }}
          </div>
          <div class="subtitle-row">
            <div class="label">{{$t('video.subtitleProfile.option.translate')}}:&nbsp;</div>
            {{joinLanguages(props.option.translate?.languages)}}
          </div>
        </span>
      </div>
    </template>
    <template v-slot:option="props">
      <div class="option__desc capitalize">
        <span class="option__title option-title">{{ props.option.name }}</span>
        <span class="option__small option-subtitle">
          <div class="subtitle-row">
            <div class="label">{{$t('video.subtitleProfile.option.language')}}:&nbsp;</div>
            {{ props.option.transcribe?.language?.name }}
          </div>
          <div class="subtitle-row">
            <div class="label">{{$t('video.subtitleProfile.option.service')}}:&nbsp;</div>
            {{ getServiceName(props.option.transcribe?.services) }}
          </div>
          <div class="subtitle-row">
            <div class="label">{{$t('video.subtitleProfile.option.translate')}}:&nbsp;</div>
            {{joinLanguages(props.option.translate?.languages)}}
          </div>
        </span>
      </div>
    </template>
  </SelectOption>
  <SelectOption v-if="!captionProfileList" :options="[]" :disabled="true" />
</template>

<script setup>
/* eslint-disable */
import {
  onMounted, onUnmounted, ref, computed,
} from 'vue';
import { serviceNameDict } from '@/modules/captionProfile/utils';
import SelectOption from '@/modules/shared/components/atoms/selectOption/SelectOption.vue';
import { useStore } from '../../../store/hooks';

const props = defineProps({
  modelValue: {
    type: Object,
    default: () => {},
  },
  options: {
    default: () => [],
  },
  onSelect: {
    type: Function,
    default: () => {},
  },
});

const store = useStore();

const captionProfileList = computed(() => store.state.captionProfileList.captionProfileList);

const emits = defineEmits(['update:modelValue']);

const onOptionChange = (newValue) => {
  emits('update:modelValue', newValue);
};

const getServiceName = (transcribeServices) => {
  let services = [];
  if (transcribeServices) {
    services = transcribeServices.map((service) => {
      if (service in serviceNameDict.transcribe) {
        return serviceNameDict.transcribe[service];
      }
      return '';
    });
  }

  return services.join(', ');
};

const joinLanguages = (languages) => {
  if (languages.length && languages.length > 0) {
    return languages.map((language) => language.name).join(', ');
  }
  return '-';
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/global-variables.scss';

.multiselect {
  position: static;
}

:deep(.multiselect), :deep(.multiselect__tags) {
  height: unset !important;
}

.option-title {
  font-size: $font-level-6;
  font-weight: $font-weight-bold;
}

.option-subtitle {
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin: 8px 0px 0;
  font-size: $font-level-7;
  text-transform: capitalize;
  .icon-box{
    width: $spacing-base;
    height: $spacing-base;
    text-align: center;
  }
  .label {
    // margin-left: $spacing-8;
    font-weight: $font-weight-base;
  }
  .subtitle-row {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-start;
    font-weight: $font-weight-bold;
  }
}

.multiselect--active {
  :deep(.multiselect__input) {
    margin-top: $spacing-4;
  }
}

:deep(.multiselect), :deep(.multiselect__tags) {
  min-height: 47px;
  height: auto;
}

:deep(.multiselect) {
  width: 100%;
  max-width: initial;
}

:deep(.multiselect__select) {
  z-index: 2;
  justify-content: flex-end;
  align-items: flex-start;
  padding-top: 18px;

  &::before {
    top: 16px;
    margin-right: $spacing-8;
  }
}

:deep(.multiselect__tags-wrap) {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

:deep(.multiselect__tags) {
  border-radius: $border-radius-3;
  padding: 0;
  padding: 12px;
  position: relative;
}

:deep(.multiselect__option),
:deep(.multiselect__tag),
:deep(.multiselect__input) {
  font-size: $font-size-base;
}

:deep(.multiselect__option--highlight) {
  background: $ci-primary;
  .option-title {
    color: $grey-100;
  }
  .option-subtitle {
    color: $grey-300;
  }
  &:after {
    background: $ci-primary;
  }
}

:deep(.multiselect__content-wrapper) {
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
  border-radius: $border-radius-3;
  overflow-x: hidden;
}

:deep(.multiselect__input),
:deep(.multiselect__placeholder) {
  font-size: $font-size-base;
  padding: 0;
  margin: 0;
  min-height: unset;
  line-height: unset;
}

:deep(.multiselect__tag) {
  color: #495057;
  background-color: #dee2e6;
  margin: 0;
  font-size: $font-size-base;
}

:deep(.multiselect__option--selected.multiselect__option--highlight) {
  background: $danger;
}

:deep(.multiselect__option--selected.multiselect__option--highlight::after) {
  background: $danger;
}

:deep(.multiselect__tag-icon::after) {
  color: $grey-500;
}

.option {
  max-width: 100%;
  white-space: initial;
}

.option__desc {
  max-width: 80%;
}

@media screen and (max-height: 620px) {
  :deep(.multiselect__content-wrapper) {
    height: 150px !important;
  }
}
</style>
