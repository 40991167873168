import { useStore as baseUseStore } from 'vuex';
import { storeKey } from '@/store';
import type { PresetListState, PresetState } from '../types';
import type { ResolutionItemState } from '../types/resolutionItem.type';

export const useStore = () => baseUseStore<{
  preset: PresetState;
  presetList: PresetListState;
  resolutionItem: ResolutionItemState;
}>(storeKey);
