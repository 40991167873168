import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "help-text" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = ["innerHTML"]

import { toRefs } from 'vue';

interface FormHelpTextProps {
  helpText?: string;
  isHtml?: boolean;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'FormHelpText',
  props: {
    helpText: {},
    isHtml: { type: Boolean, default: false }
  },
  setup(__props: any) {

const props = __props;
const { helpText, isHtml } = toRefs(props);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_unref(isHtml))
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_unref(helpText)), 1))
      : (_openBlock(), _createElementBlock("div", {
          key: 1,
          innerHTML: _unref(helpText)
        }, null, 8, _hoisted_3))
  ]))
}
}

})