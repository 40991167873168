/* eslint no-shadow: ["error", { "allow": ["state"] }] */
import { InjectionKey } from 'vue';
import { createStore, useStore as baseUseStore, Store } from 'vuex';

/* interface */
// import { RootState } from '@/store/type';

// Need to import because AppChrome using it
import { geoblockRuleList } from '@/modules/geoblock';
import global from './modules/global';
/* Management */
import languageList from './modules/languageList';
import { RootState } from './type';

const store = createStore<RootState>({
  modules: {
    global,
    languageList,
    geoblockRuleList,
  },
});

export const storeKey: InjectionKey<Store<RootState>> = Symbol('Store Key');

export function useStore<T>() {
  return baseUseStore<T>(storeKey);
}

export default store;
