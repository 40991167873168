const prettyFileSize = (size: number): string => {
  const kilobyte = 1024;
  const megabytes = kilobyte * 1024;
  const gigabytes = megabytes * 1024;

  if (size) {
    if (size > gigabytes) {
      return `${(size / gigabytes).toFixed(2)} GB`;
    }
    if (size > megabytes) {
      return `${(size / megabytes).toFixed(2)} MB`;
    }
    return `${(size / kilobyte).toFixed(2)} KB`;
  }
  return '0 KB';
};

const transformByteToBit = (size: number): string => {
  const sizeBytes = size * 8;
  const kilobytes = 1000;
  const megabytes = kilobytes * 1000;
  const gigabytes = megabytes * 1000;
  if (sizeBytes) {
    if (sizeBytes > gigabytes) {
      return `${(sizeBytes / gigabytes).toFixed(2)} Gbit`;
    }
    if (sizeBytes > megabytes) {
      return `${(sizeBytes / megabytes).toFixed(2)} Mbit`;
    }
    return `${(sizeBytes / kilobytes).toFixed(2)} kbit`;
  }
  return '0 kbit';
};

const prettyBitrate = (sizeBytes: number): string => {
  const kilobytes = 1000;
  const megabytes = kilobytes * 1000;
  const gigabytes = megabytes * 1000;
  if (sizeBytes) {
    if (sizeBytes > gigabytes) {
      return `${(sizeBytes / gigabytes).toFixed(2)} Gbps`;
    }
    if (sizeBytes > megabytes) {
      return `${(sizeBytes / megabytes).toFixed(2)} Mbps`;
    }
    return `${(sizeBytes / kilobytes).toFixed(2)} Kbps`;
  }
  return '0 Kbps';
};

const numberWithCommas = (number: number): string => number.toLocaleString('th-TH');

export {
  prettyFileSize,
  transformByteToBit,
  prettyBitrate,
  numberWithCommas,
};
