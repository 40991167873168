import { axiosAPI } from '@/plugins/axios';

export function createCaptionProfile(payload) {
  return axiosAPI.post('/auto-generate-caption-settings', payload);
}

export function updateCaptionProfile(profileKey, payload) {
  return axiosAPI.put(`/auto-generate-caption-settings/${profileKey}`, payload);
}

export function deleteCaptionProfile(profileKey) {
  // response 204
  return axiosAPI.delete(`/auto-generate-caption-settings/${profileKey}`);
}

export function getSupportedTranscribeLanguages() {
  return axiosAPI.get('/auto-generate-caption-settings/transcribe/support-languages');
}

export function getSupportedTranslateLanguages() {
  return axiosAPI.get('/auto-generate-caption-settings/translate/support-languages');
}
