import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "loading-container"
}
const _hoisted_2 = {
  key: 1,
  class: "content-container"
}
const _hoisted_3 = {
  key: 0,
  class: "options-container"
}
const _hoisted_4 = { class: "header" }
const _hoisted_5 = { class: "title" }
const _hoisted_6 = { class: "domain-container" }
const _hoisted_7 = { class: "label" }
const _hoisted_8 = {
  key: 0,
  class: "value"
}
const _hoisted_9 = {
  key: 1,
  class: "domains"
}
const _hoisted_10 = { class: "information-wrapper" }
const _hoisted_11 = { class: "signed-url" }
const _hoisted_12 = { class: "value" }
const _hoisted_13 = { class: "access-control-policy" }
const _hoisted_14 = { class: "value" }
const _hoisted_15 = { key: 0 }
const _hoisted_16 = { key: 1 }
const _hoisted_17 = {
  key: 0,
  class: "second-step-button-container"
}
const _hoisted_18 = { class: "message-container" }
const _hoisted_19 = { key: 0 }
const _hoisted_20 = {
  key: 1,
  class: "text-danger"
}
const _hoisted_21 = {
  key: 2,
  class: "text-danger"
}
const _hoisted_22 = {
  key: 3,
  class: "text-danger"
}

import _ from 'lodash';
import {
  computed, onMounted, ref, toRefs,
} from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from '@/modules/projectDefaultUploadSetting/store/hooks';
import type { PlaybackChannel } from '@/modules/playbackChannel';
import Modal from '@/modules/shared/components/organisms/modal/Modal.vue';
import RadioButtonChoice from '@/modules/shared/components/atoms/radioButtonChoice/RadioButtonChoice.vue';
import PlaybackChannelSummary from '@/modules/projectDefaultUploadSetting/components/organisms/playbackChannelSummary/PlaybackChannelSummary.vue';
import Badge from '@/modules/shared/components/atoms/badge/Badge.vue';

interface AddPlaybackChannelModalProps {
  onClose: () => void;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'AddProjectPlaybackChannelModal',
  props: {
    onClose: { type: Function }
  },
  setup(__props: any) {

const props = __props;
const { onClose } = toRefs(props);

const { t } = useI18n();
const store = useStore();

const selectedPlaybackChannel = ref<PlaybackChannel | null>(null);
const step = ref<number>(1);

const currentProject = computed(() => store.state.project.currentProject);
const saveProjectState = computed(() => store.state.project.saveProjectState);
const playbackChannelList = computed(() => store.state.playbackChannelList.playbackChannelList);
const loadPlaybackChannelListState = computed(() => store.state.playbackChannelList.loadPlaybackChannelListState);
const saveErrorCode = computed(() => _.get(saveProjectState.value, 'error.status', 500));

function isSelectedPlaybackChannel(playbackChannel: PlaybackChannel) {
  return selectedPlaybackChannel.value === playbackChannel;
}

function onSelectedPlaybackChannel(playbackChannel: PlaybackChannel) {
  if (selectedPlaybackChannel.value === playbackChannel) {
    selectedPlaybackChannel.value = null;
  } else {
    selectedPlaybackChannel.value = playbackChannel;
  }
}

function getIsRequiredSignUrl(playbackChannel: PlaybackChannel) {
  return _.get(playbackChannel, 'signedUrl.required', false);
}

function isAllowAllDomains(playbackChannel: PlaybackChannel) {
  const allowDomains = _.get(playbackChannel, 'accessControlPolicy.allowDomains', []);
  const denyDomains = _.get(playbackChannel, 'accessControlPolicy.denyDomains', []);
  return !allowDomains.length && !denyDomains.length;
}

function getPlaybackDomains(playbackChannel: PlaybackChannel) {
  return [playbackChannel.defaultPlaybackDomain, ..._.get(playbackChannel, 'playbackDomains', [])];
}

function isPlaybackChannelDisabled(playbackChannel: PlaybackChannel) {
  const projectPlaybackChannels = _.get(currentProject.value, 'playbackChannels', []);
  if (_.find(projectPlaybackChannels, { id: playbackChannel.id })) {
    return true;
  }
  return false;
}

function onClickNext() {
  step.value += 1;
}

function onClickBack() {
  step.value -= 1;
}

async function onAddPlaybackChannel(playbackChannelId: string) {
  await store.dispatch('addProjectPlaybackChannel', {
    projectKey: currentProject.value?.key,
    playbackChannelIds: [playbackChannelId],
  });

  if (saveProjectState.value.status === 'success') {
    onClose.value();
  }
}

const primaryButtonOnClick = computed(() => {
  if (step.value === 1) {
    return onClickNext;
  }

  if (!_.isNil(selectedPlaybackChannel.value) && !_.isNil(selectedPlaybackChannel.value?.id)) {
    return () => onAddPlaybackChannel(selectedPlaybackChannel.value!.id);
  }

  return undefined;
});

onMounted(() => store.dispatch('loadPlaybackChannelList'));

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(Modal, {
    key: step.value,
    title: _unref(t)('project.manage.setting.playbackChannel.add'),
    primaryButtonText: step.value === 1 ? _unref(t)('common:button.next') : 'Add playback channel',
    isDisabledPrimaryButton: step.value === 2 ? saveProjectState.value.status === 'error' || saveProjectState.value.status === 'saving' : false,
    onClickPrimaryButton: primaryButtonOnClick.value,
    secondaryButtonText: step.value === 2 ? _unref(t)('common:button.back') : '',
    onClickSecondaryButton: step.value === 2 ? onClickBack : undefined,
    onModalClose: _unref(onClose)
  }, {
    body: _withCtx(() => [
      (loadPlaybackChannelListState.value.status === 'loading')
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, _cache[0] || (_cache[0] = [
            _createElementVNode("i", { class: "fas fa-circle-notch fa-spin fa-4x" }, null, -1)
          ])))
        : _createCommentVNode("", true),
      (loadPlaybackChannelListState.value.status === 'success')
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            (step.value === 1)
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(playbackChannelList.value, (playbackChannel) => {
                    return (_openBlock(), _createElementBlock("div", {
                      class: _normalizeClass(["form-radio playback-channel", {'disabled': isPlaybackChannelDisabled(playbackChannel)}]),
                      key: playbackChannel.id
                    }, [
                      _createVNode(RadioButtonChoice, {
                        disabled: isPlaybackChannelDisabled(playbackChannel),
                        modelValue: isSelectedPlaybackChannel(playbackChannel),
                        data: playbackChannel,
                        "onUpdate:data": onSelectedPlaybackChannel
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_4, [
                            _createElementVNode("div", _hoisted_5, _toDisplayString(playbackChannel.name), 1),
                            (isPlaybackChannelDisabled(playbackChannel))
                              ? (_openBlock(), _createBlock(Badge, {
                                  key: 0,
                                  title: _unref(t)('glossary:selected')
                                }, null, 8, ["title"]))
                              : _createCommentVNode("", true)
                          ]),
                          _createElementVNode("div", _hoisted_6, [
                            _createElementVNode("span", _hoisted_7, _toDisplayString(_unref(t)('hostings.domain')) + " (" + _toDisplayString(getPlaybackDomains(playbackChannel).length) + ") ", 1),
                            (!getPlaybackDomains(playbackChannel).length)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_8, " - "))
                              : _createCommentVNode("", true),
                            (getPlaybackDomains(playbackChannel))
                              ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(getPlaybackDomains(playbackChannel), (domain, index) => {
                                    return (_openBlock(), _createElementBlock("div", {
                                      class: "value",
                                      key: index
                                    }, _toDisplayString(domain), 1))
                                  }), 128))
                                ]))
                              : _createCommentVNode("", true)
                          ]),
                          _createElementVNode("div", _hoisted_10, [
                            _createElementVNode("div", _hoisted_11, [
                              _cache[1] || (_cache[1] = _createElementVNode("span", { class: "label" }, " Signed URL ", -1)),
                              _createElementVNode("div", _hoisted_12, _toDisplayString(getIsRequiredSignUrl(playbackChannel) ? _unref(t)('project.setup.playbackChannel.signedURL.isEnabled') : _unref(t)('project.setup.playbackChannel.signedURL.isDisabled')), 1)
                            ]),
                            _createElementVNode("div", _hoisted_13, [
                              _cache[2] || (_cache[2] = _createElementVNode("span", { class: "label" }, " Allowed / Blocked Website ", -1)),
                              _createElementVNode("div", _hoisted_14, [
                                (isAllowAllDomains(playbackChannel))
                                  ? (_openBlock(), _createElementBlock("span", _hoisted_15, _toDisplayString(_unref(t)('accessControlPolicy.allowAllWebsite')), 1))
                                  : (_openBlock(), _createElementBlock("span", _hoisted_16, _toDisplayString(_unref(t)('accessControlPolicy.allowSomeWebsite')), 1))
                              ])
                            ])
                          ])
                        ]),
                        _: 2
                      }, 1032, ["disabled", "modelValue", "data"])
                    ], 2))
                  }), 128))
                ]))
              : _createCommentVNode("", true),
            (step.value === 2)
              ? (_openBlock(), _createBlock(PlaybackChannelSummary, {
                  key: 1,
                  selectedPlaybackChannel: selectedPlaybackChannel.value
                }, null, 8, ["selectedPlaybackChannel"]))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true)
    ]),
    footer: _withCtx(() => [
      (step.value === 2)
        ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
            _createElementVNode("div", _hoisted_18, [
              (saveProjectState.value.status === 'saving')
                ? (_openBlock(), _createElementBlock("span", _hoisted_19, [
                    _cache[3] || (_cache[3] = _createElementVNode("i", { class: "fas fa-spinner fa-spin" }, null, -1)),
                    _createTextVNode(_toDisplayString(_unref(t)('common:button.saving')), 1)
                  ]))
                : _createCommentVNode("", true),
              (saveErrorCode.value === 404)
                ? (_openBlock(), _createElementBlock("span", _hoisted_20, [
                    _cache[4] || (_cache[4] = _createElementVNode("i", { class: "fa fa-xmark" }, null, -1)),
                    _createTextVNode(_toDisplayString(_unref(t)('response:error404.title', { item: 'project'})), 1)
                  ]))
                : _createCommentVNode("", true),
              (saveErrorCode.value === 422)
                ? (_openBlock(), _createElementBlock("span", _hoisted_21, [
                    _cache[5] || (_cache[5] = _createElementVNode("i", { class: "fa fa-xmark" }, null, -1)),
                    _createTextVNode(_toDisplayString(_unref(t)('response:error422.title')), 1)
                  ]))
                : _createCommentVNode("", true),
              (saveErrorCode.value === 500)
                ? (_openBlock(), _createElementBlock("span", _hoisted_22, [
                    _cache[6] || (_cache[6] = _createElementVNode("i", { class: "fa fa-xmark" }, null, -1)),
                    _createTextVNode(_toDisplayString(_unref(t)('response:error500.title')), 1)
                  ]))
                : _createCommentVNode("", true)
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["title", "primaryButtonText", "isDisabledPrimaryButton", "onClickPrimaryButton", "secondaryButtonText", "onClickSecondaryButton", "onModalClose"]))
}
}

})