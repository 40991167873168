import { axiosAPI } from '@/plugins/axios';
import { Filter } from '@/types/Axios.type';
import qs from 'qs';
import { CreateOrUpdateCollectionCustomField, CreateOrUpdateCollectionCustomImageField, UpdateCollectionTypeOrder } from '../types';

export const getCollectionTypeList = () => axiosAPI.get('/collection-types');

export const getCollectionTypeById = (id: string) => axiosAPI.get(`/collection-types/${id}`);

export const updateCollectionTypeById = (id: string, payload: { name: string }) => axiosAPI.put(`/collection-types/${id}`, payload);

export const createCollectionType = (payload:{ name: string, template: string }) => axiosAPI.post('/collection-types', payload);

export const createCollectionCustomImageField = (payload: CreateOrUpdateCollectionCustomImageField) => (
  axiosAPI.post('/collection-custom-image-fields', payload)
);

export const getCollectionCustomImageField = (fieldId: string) => (
  axiosAPI.get(`/collection-custom-image-fields/${fieldId}`)
);

export const updateCollectionCustomImageField = (fieldId: string, payload: CreateOrUpdateCollectionCustomImageField) => (
  axiosAPI.put(`/collection-custom-image-fields/${fieldId}`, payload)
);

export const createCollectionCustomField = (payload: CreateOrUpdateCollectionCustomField) => (
  axiosAPI.post('/collection-custom-fields', payload)
);

export const getCollectionTypeFieldById = (id: string) => axiosAPI.get(`/collection-custom-fields/${id}`);
export const updateCollectionCustomField = (fieldId: string, payload: CreateOrUpdateCollectionCustomField) => (
  axiosAPI.put(`/collection-custom-fields/${fieldId}`, payload)
);

export const createCollectionTypeSection = (typeId: string, payload: { displayName: string }) => (
  axiosAPI.post(`/collection-types/${typeId}/custom-field-sections`, payload)
);

export const deleteCollectionCustomField = (fieldId: string, filter: Filter) => {
  const query = qs.stringify(filter, { addQueryPrefix: true, skipNulls: true });
  return axiosAPI.delete(`/collection-custom-fields/${fieldId}${query}`);
};

export const deleteCollectionTypeSection = (typeId: string, sectionId: string) => (
  axiosAPI.delete(`/collection-types/${typeId}/custom-field-sections/${sectionId}`)
);

export const updateCollectionTypeSectionName = (typeId: string, payload: { collectionTypeId: string | string[] }, sectionId: string) => (
  axiosAPI.put(`/collection-types/${typeId}/custom-field-sections/${sectionId}`, payload)
);

export const deleteCollectionCustomImageField = (fieldId: string, filter: Filter) => {
  const query = qs.stringify(filter, { addQueryPrefix: true, skipNulls: true });
  return axiosAPI.delete(`/collection-custom-image-fields/${fieldId}${query}`);
};

export const updateCollectionTypeOrder = (fieldId: string, payload: UpdateCollectionTypeOrder) => (
  axiosAPI.put(`/collection-types/${fieldId}/custom-field-sections/order`, payload)
);
