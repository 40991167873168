<template>
  <div
    class="message-box-container"
    :class="{ 'big-layout' : iconClassName || $slots['icon'] }"
    :style="{ height: height }"
    :data-test="dataTest"
  >
    <div class="icon-container" v-if="iconClassName || $slots['icon']">
      <i :class="iconClassName" data-test="message-box-icon" />
      <slot name="icon" v-if="!iconClassName" />
    </div>
    <div class="text-container">
      <div class="title" v-if="title || $slots['title']" data-test="message-box-title">
        {{ title }}
        <slot name="title" v-if="!title" />
      </div>
      <div class="description" v-if="description || $slots['description']" data-test="message-box-description">
        {{ description }}
        <slot name="description" v-if="!description" />
      </div>
    </div>
    <div class="button-container" v-if="buttonTitle || $slots['action']" data-test="message-box-button-container">
      <Button
        data-test="message-box-button"
        v-if="buttonTitle"
        :buttonStyle="buttonStyle || ((iconClassName || $slots['icon']) ? 'primary' : 'text-primary')"
        :size="(iconClassName || $slots['icon'] || buttonPrefixIconClassName || buttonSuffixIconClassName) ? 'large' : 'small'"
        @click="onClickButton"
        :url="buttonUrl ?? ''">
        <template v-slot:icon-prefix v-if="buttonPrefixIconClassName">
          <i :class="buttonPrefixIconClassName" />
        </template>
        <template v-slot:icon-suffix v-if="buttonSuffixIconClassName">
          <i :class="buttonSuffixIconClassName" />
        </template>
        {{buttonTitle}}
      </Button>
      <slot name="action" v-if="$slots['action']" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { toRefs } from 'vue';
import Button from '@/modules/shared/components/atoms/button/Button.vue';
import { MessageBoxProps } from './MessageBox.type';

const props = withDefaults(defineProps<MessageBoxProps>(), {
  dataTest: 'message-box-container',
});

const {
  buttonPrefixIconClassName,
  buttonSuffixIconClassName,
  buttonStyle,
  buttonTitle,
  buttonUrl,
  description,
  height,
  iconClassName,
  title,
  onClickButton,
  dataTest,
} = toRefs(props);
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/global-variables.scss';

.message-box-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: $spacing-32;
  min-height: 150px;

  &.big-layout {
    height: 300px;
  }
}

.icon-container {
  font-size: 48px;
  margin-bottom: $spacing-24;
}

.text-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  font-weight: $font-weight-bold;
  font-size: $font-level-5;
  text-align: center;
}

.description {
  margin-top: $spacing-8;
  text-align: center;
}

.button-container {
  margin-top: $spacing-base;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: $spacing-8;
}
</style>
