<template>
  <div>
    <p class="label">{{ t('project.deleteProject.step1.label') }}</p>
    <RadioButtonGroup v-model="selectedOptionValue" :options="whatToDoWithVideosOptions">
      <template v-slot:move-videos-sub-components>
        <p class="label">{{ t('project.moveVideos.modal.moveTo') }}</p>
        <SelectOption
          :disabled="loadProjectListState.status === 'loading'"
          :placeholder="t('project.moveVideos.modal.placeholder')"
          :options
          v-model="selectedProject"
          searchable
        />
      </template>
      <template v-slot:delete-videos-sub-components>
        <p class="description" v-html="t('project.deleteProject.step1.option2.description')" />
      </template>
    </RadioButtonGroup>
  </div>
</template>

<script setup lang="ts">
import {
  computed, ref, toRefs, watch,
} from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from '@/modules/projectDetails/store/hooks';
import SelectOption from '@/modules/shared/components/atoms/selectOption/SelectOption.vue';
import RadioButtonGroup from '@/modules/shared/components/molecules/radioButtonGroup/RadioButtonGroup.vue';
import type { Project } from '@/modules/shared/types/project.type';
import type { ActionWithVideos, DeleteProjectData } from '../../../types';

interface ComponentProps {
  modelValue: DeleteProjectData;
}

const emit = defineEmits<{
  'update:modelValue': [value: DeleteProjectData];
}>();

const { t } = useI18n();
const store = useStore();

const props = defineProps<ComponentProps>();
const { modelValue } = toRefs(props);

interface Option {
  label: string;
  value: ActionWithVideos;
  subFieldName: string;
}

const whatToDoWithVideosOptions = computed<Option[]>(() => [
  {
    label: t('project.deleteProject.step1.option1.title'),
    value: 'moveVideos',
    subFieldName: 'move-videos-sub-components',
  },
  {
    label: t('project.deleteProject.step1.option2.title'),
    value: 'deleteVideos',
    subFieldName: 'delete-videos-sub-components',
  },
]);

const selectedOptionValue = ref<ActionWithVideos>(modelValue.value.actionWithVideos);
const selectedProject = ref<Project | null>(modelValue.value.destinationProject);

const projectKey = computed(() => store.state.project.currentProject?.key ?? '');
const projectList = computed(() => store.state.projectList.projectList ?? []);
const options = computed(() => projectList.value?.filter((prj) => prj.key !== projectKey.value));
const loadProjectListState = computed(() => store.state.projectList.loadProjectListState);

watch(selectedOptionValue, (newValue) => emit('update:modelValue', {
  actionWithVideos: newValue,
  destinationProject: selectedProject.value,
}));

watch(selectedProject, (newValue) => emit('update:modelValue', {
  actionWithVideos: selectedOptionValue.value,
  destinationProject: newValue,
}));
</script>

<style scoped lang="scss">
@import '~@/assets/scss/global-variables';

:deep(.multiselect) {
  max-width: 100%;
}

.content-container {
  display: flex;
  flex-flow: column;
  gap: $spacing-24;
}

.label {
  margin-bottom: $spacing-4;
}

.description {
  color: $grey-600;
  font-size: $font-level-7;

  :deep(strong) {
    font-weight: 500;
  }
}
</style>
