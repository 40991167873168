import store from '@/store';
import { geoblockRuleList } from '@/modules/geoblock';
import { user } from '@/modules/user';
import { teamSetting } from '@/modules/teamSetting';
import type { RouteRecordRaw } from 'vue-router';
import projectCreate from '../store';

export default function createProjectCreateRoutes(): RouteRecordRaw[] {
  return [
    {
      name: 'createProject',
      path: 'projects/create',
      component: () => import(/* webpackChunkName: "projectCreate" */ '@/modules/projectCreate/pages/ProjectCreate.vue'),
      beforeEnter: (to, from, next) => {
        if (!store.hasModule('projectCreate')) {
          store.registerModule('projectCreate', projectCreate);
        }
        if (!store.hasModule('user')) {
          store.registerModule('user', user);
        }
        if (!store.hasModule('geoblockRuleList')) {
          store.registerModule('geoblockRuleList', geoblockRuleList);
        }
        if (!store.hasModule('teamSetting')) {
          store.registerModule('teamSetting', teamSetting);
        }
        next();
      },
    },
  ];
}
