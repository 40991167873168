<template>
  <Page
    :title="t('projectInformation')"
    :projectName="project?.name"
  >
    <Alert
      v-if="isSavingError"
      priority="danger"
      :alertText="t('response:generalError.title')"
    />
    <Alert
      v-if="isSavingSuccess"
      :alertText="t('response:savedSuccessfully')"
      priority="success"
    />
    <Section
      v-if="canUpdateProject && loadProjectManagementState.status === 'success'"
    >
      <div class="section-row">
        <div class="section-content">
          <TextEdit
            label="Project ID"
            :modelValue="project.id"
            :enableEdit="false" />
          <TextEdit
            :label="t('project.manage.key')"
            :modelValue="project.key"
            :enableEdit="false" />
          <TextEdit
            :label="t('project.manage.name')"
            :modelValue="project.name"
            :errorMessage="nameErrorMessage"
            @confirm="onConfirmName"
            @update:modelValue="checkNameValid"
            :enableEdit="!isSaving" />
          <TextEdit
            :label="t('common:form.description')"
            :modelValue="project.description"
            @confirm="onConfirmDescription"
            :enableEdit="!isSaving" />
          <ProjectCreator
            v-if="creator"
            :creator="creator"
            :createdAt="createdAt" />
        </div>
      </div>
    </Section>
    <ProjectDangerZone
      v-if="isManagerOrAdmin && canUpdateProject && loadProjectManagementState.status === 'success'"
    />
    <Section v-if="!canUpdateProject">
      <MessageBox
        iconClassName="fas fa-user-slash"
        :title="t('project.noPermission.title')"
        :description="t('project.noPermission.helpText')"
        :buttonTitle="t('video.noPermission.button')"
        :buttonUrl="`/${teamName}/projects/${projectKey}`" />
    </Section>
    <PreLoaderSection
      v-if="canUpdateProject && loadProjectManagementState.status === 'loading'"
    />
  </Page>
</template>

<script lang="ts" setup>
import ProjectDangerZone
  from '@/modules/projectDetails/components/organisms/projectDangerZone/ProjectDangerZone.vue';
import type { ProjectMember } from '@/modules/projectMember/types';
import _ from 'lodash';
import { computed, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';
import Page from '@/layouts/Page.vue';
import Section from '@/modules/shared/components/organisms/section/Section.vue';
import TextEdit from '@/modules/shared/components/molecules/textEdit/TextEdit.vue';
import Alert from '@/modules/shared/components/atoms/alert/Alert.vue';
import MessageBox from '@/modules/shared/components/molecules/messageBox/MessageBox.vue';
import PreLoaderSection from '@/modules/shared/components/atoms/preLoaderSection/PreLoaderSection.vue';
import ProjectCreator from '../components/molecules/projectCreator/ProjectCreator.vue';
import { useStore } from '../store/hooks';

const { t } = useI18n();
const store = useStore();
const route = useRoute();

interface StateRefData {
  nameError: boolean;
}

interface ProjectRefData {
  id: string;
  name: string;
  key: string;
  description: string | null;
}

const state = ref<StateRefData>({ nameError: false });
const project = ref<ProjectRefData>({
  id: '',
  name: '',
  key: '',
  description: '',
});
const createdAt = ref<string>('');
const creator = ref<ProjectMember | null>(null);

const currentProject = computed(() => store.state.project.currentProject);
const loadProjectManagementState = computed(() => store.state.project.loadProjectManagementState);
const saveProjectState = computed(() => store.state.project.saveProjectState);
const isManagerOrAdmin = computed(() => store.getters.isManagerOrAdmin);

const teamName = computed(() => route.params.teamName);
const projectKey = computed(() => route.params.projectKey);
const permission = computed(() => currentProject.value?.actions);
const canUpdateProject = computed(() => !_.isNil(permission.value) && permission.value.updateProject);
const isSaving = computed(() => saveProjectState.value.status === 'saving');
const isSavingError = computed(() => saveProjectState.value.status === 'error');
const isSavingSuccess = computed(() => saveProjectState.value.status === 'success');
const nameErrorMessage = computed(() => {
  if (state.value.nameError) {
    return t('response:minCharacter', {
      itemName: t('project.manage.name'),
      minCharacter: 3,
    });
  }
  return '';
});

function fillForm() {
  if (!currentProject.value) {
    return;
  }

  const {
    id,
    name,
    key,
    description,
  } = currentProject.value;

  project.value = {
    id,
    name,
    key,
    description,
  };

  creator.value = currentProject.value.creator;
  createdAt.value = currentProject.value.createdAt;
}

async function onSubmit() {
  const payload = {
    projectKey: projectKey.value,
    data: project.value,
  };
  await store.dispatch('editProject', payload);

  const timer = setTimeout(() => {
    store.commit('setUpdateProjectStateMessage', { type: 'idle' });
    clearTimeout(timer);
  }, 3000);
}

function isNameValid(value: string): boolean {
  if (value) {
    return value.length >= 3;
  }

  return project.value.name.length >= 3;
}

function onConfirmName(value: string) {
  if (isNameValid(value)) {
    project.value.name = value;
    onSubmit();
  } else {
    state.value.nameError = true;
  }
}

function onConfirmDescription(value: string) {
  project.value.description = value;
  onSubmit();
}

function checkNameValid(value: string) {
  if (value.length >= 3) {
    state.value.nameError = false;
  } else {
    state.value.nameError = true;
  }
}

onMounted(() => fillForm());
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/global-variables.scss';
@import '~@/assets/scss/breakpoint.scss';

:deep(.action-button){
  margin-top: $spacing-base * 1.5;
}

.alert {
  border-radius: 5px;
  .fas {
    margin-right: 8px;
  }
}

.btn-edit-container {
  margin-top: $spacing-base * 2;
  display: flex;
  margin-left: auto;
}

.section-page-error {
  background: none;
}

.btn:disabled {
  background-color: transparent;
}

.section-row {
  display: flex;
  flex-wrap: wrap;

  .section-content {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media screen and (min-width: $min-layout-md) {
  .section-row .section-content {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
}
</style>
