<template>
  <!-- TODO: Use SectionCollapse -->
  <div>
    <ChangeGeoblockModal
      v-if="isGeoblockModalShowing"
      :saveState="saveProjectState"
      :geoblockRule="geoblockRule"
      @close="onToggleGeoblockModalShowing"
      @submit="onSubmit"
    />
    <Section :title="t('project.manage.setting.geoblock.title')">
      <template v-slot:action v-if="$attrs.canUpdateProject">
        <Button
          buttonStyle="secondary"
          buttonClasses="change-geoblock-button"
          type="button"
          @click="onToggleGeoblockModalShowing"
        >
          <template v-slot:content>
            <span class="btn-text-full">
              {{ t('project.defaultSettings.geoblock.change') }}
            </span>
            <span class="btn-text-short">
              {{ t('common:button.change') }}
            </span>
          </template>
        </Button>
      </template>
      <GeoblockRuleCard :geoblockRule="geoblockRule" />
      <template v-slot:section-footer-with-border v-if="$attrs.isManager">
        <div class="geo-blocking-link-container" v-if="geoblockRule">
          <router-link class="link" :to="`/${teamName}/manage/geo-blocking`">
            <i class="fas fa-gear" /> {{ t('project.manage.geoblocking.goToSettings') }}
          </router-link>
        </div>
      </template>
    </Section>
  </div>
</template>

<script lang="ts" setup>
import _ from 'lodash';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';
import { useStore } from '@/modules/projectDefaultUploadSetting/store/hooks';
import type { GeoblockRule } from '@/modules/geoblock';
import { GeoblockRuleCard } from '@/modules/geoblock';
import Section from '@/modules/shared/components/organisms/section/Section.vue';
import Button from '@/modules/shared/components/atoms/button/Button.vue';
import ChangeGeoblockModal from '@/modules/projectDefaultUploadSetting/components/organisms/changeGeoblockModal/ChangeGeoblockModal.vue';

const isGeoblockModalShowing = ref<boolean>(false);

const { t } = useI18n();
const store = useStore();
const route = useRoute();

const teamName = computed(() => route.params.teamName);
const currentProject = computed(() => store.state.project.currentProject);
const geoblockRule = computed(() => store.state.project.currentProject?.geoblockRule);
const saveProjectState = computed(() => store.state.project.saveProjectState);

function onToggleGeoblockModalShowing() {
  isGeoblockModalShowing.value = !isGeoblockModalShowing.value;
}

async function onSubmit(selectedGeoblockRule: GeoblockRule | null | undefined) {
  if (_.isNil(currentProject.value) || _.isNil(selectedGeoblockRule)) {
    return;
  }

  const payload = {
    projectKey: currentProject.value.key,
    data: { ...currentProject.value, geoblockRuleId: selectedGeoblockRule.id },
  };
  await store.dispatch('editProject', payload);
  if (saveProjectState.value.status === 'success') {
    onToggleGeoblockModalShowing();
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/global-variables.scss';
@import '~@/assets/scss/breakpoint.scss';

.btn-text-short {
  display: none;
}

@media screen and (max-width: $max-layout-sm) {
  .btn-text-short {
    display: inline;
  }
  .btn-text-full {
    display: none;
  }
}
</style>
