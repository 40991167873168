import type { UrlSigningKey } from '@/modules/urlSigningKey';
import { axiosAPI } from '@/plugins/axios';

interface Domains {
  playbackDomains: string[];
}

export const createPlaybackChannel = (playbackChannelName: { name: string }) => axiosAPI.post('/playback-channels', playbackChannelName);
export const updatePlaybackChannelName = (playbackChannelId: string, playbackChannelName: { name: string }) => axiosAPI.put(`/playback-channels/${playbackChannelId}`, playbackChannelName);
export const addPlaybackDomain = (playbackChannelId: string, domains: Domains) => axiosAPI.post(`/playback-channels/${playbackChannelId}/playback-domains/add`, domains);
export const removePlaybackDomain = (playbackChannelId: string, domains: Domains) => axiosAPI.post(`/playback-channels/${playbackChannelId}/playback-domains/remove`, domains);

interface AccessControlPolicy {
  allowDomains: string[];
  denyDomains: string[];
}

export const updatePlaybackChannelAccessControlPolicy = (playbackChannelId: string, accessControlPolicy: AccessControlPolicy) => axiosAPI.put(`/playback-channels/${playbackChannelId}/allow-access`, accessControlPolicy);
export const addPlaybackChannelUrlSigningKey = (playbackChannelId: string, urlSigningKeyIds: { urlSigningKeyIds: string[] }) => axiosAPI.post(`/playback-channels/${playbackChannelId}/signed-url/url-signing-keys`, urlSigningKeyIds);
export const updatePlaybackChannelUrlSignedUrl = (playbackChannelId: string, signedUrl: UrlSigningKey) => axiosAPI.put(`/playback-channels/${playbackChannelId}/signed-url`, signedUrl);
export const removePlaybackChannelUrlSigningKey = (playbackChannelId: string, urlSigningKeyId: string) => axiosAPI.delete(`/playback-channels/${playbackChannelId}/signed-url/url-signing-keys/${urlSigningKeyId}`);
export const getPlaybackChannelDetail = (playbackChannelId: string) => axiosAPI.get(`/playback-channels/${playbackChannelId}`);
export const updatePlaybackChannelDefaultDrmModel = (playbackChannelId: string, newModel: string) => (
  axiosAPI.put(`/playback-channels/${playbackChannelId}/default-drm-model`, {
    defaultDrmModelForNonClassicDelivery: newModel,
  })
);
