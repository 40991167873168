import store from '@/store';
import project from '@/modules/shared/store/project';
import user from '@/modules/user/store/user';
import { teamSetting } from '@/modules/teamSetting';
import type { RouteRecordRaw } from 'vue-router';

export function createProjectSettingRoutes(): RouteRecordRaw[] {
  return [
    {
      name: 'settings',
      path: 'settings',
      component: () => import(/* webpackChunkName: "ProjectManageSetting" */ '@/modules/projectSetting/pages/ProjectManageSetting.vue'),
      beforeEnter: (to, from, next) => {
        if (!store.hasModule('project')) {
          store.registerModule('project', project);
        }
        if (!store.hasModule('teamSetting')) {
          store.registerModule('teamSetting', teamSetting);
        }
        if (!store.hasModule('user')) {
          store.registerModule('user', user);
        }
        next();
      },
    },
  ];
}
