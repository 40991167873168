import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "player-section-wrapper-row" }
const _hoisted_2 = { class: "player-information-wrapper" }
const _hoisted_3 = { class: "player-information" }
const _hoisted_4 = { class: "player-card-header" }
const _hoisted_5 = { class: "information-name" }
const _hoisted_6 = { class: "last-updated" }
const _hoisted_7 = { class: "subtitle" }
const _hoisted_8 = {
  key: 0,
  class: "link-container"
}

import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';
import { getQueryAsString } from '@/modules/shared/utils/query';
import { formatDateTime } from '@/modules/shared/utils/dateFormatter';
import HeadingWithIconGroup from '@/modules/shared/components/molecules/headingWithIconGroup/HeadingWithIconGroup.vue';
import SectionCollapse from '@/modules/shared/components/organisms/sectionCollapse/SectionCollapse.vue';
import { useStore } from '@/modules/projectSetting/store/hooks';


export default /*@__PURE__*/_defineComponent({
  __name: 'ProjectVideoPlayerSection',
  setup(__props) {

const { t } = useI18n();
const store = useStore();
const route = useRoute();

const player = computed(() => store.state.project.currentProject?.player);
const teamName = computed(() => getQueryAsString(route.params, 'teamName'));

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (player.value)
    ? (_openBlock(), _createBlock(SectionCollapse, {
        key: 0,
        title: _unref(t)('players.currentPlayer.title'),
        description: _unref(t)('players.currentPlayer.subTitle')
      }, {
        default: _withCtx(() => [
          _createVNode(HeadingWithIconGroup, {
            title: "",
            subTitle: "",
            class: "player-section",
            iconClassName: "fas fa-play mr-4"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_router_link, {
                to: `/${teamName.value}/manage/players/${player.value.id}`,
                class: "player-section-wrapper"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_1, [
                    _createElementVNode("div", _hoisted_2, [
                      _createElementVNode("div", _hoisted_3, [
                        _createElementVNode("div", _hoisted_4, [
                          _createElementVNode("h5", _hoisted_5, _toDisplayString(player.value.name), 1)
                        ]),
                        _createElementVNode("div", _hoisted_6, [
                          _createElementVNode("span", _hoisted_7, _toDisplayString(_unref(t)('common:form.updatedAt')) + ": " + _toDisplayString(_unref(formatDateTime)(player.value.updatedAt)), 1)
                        ])
                      ])
                    ])
                  ])
                ]),
                _: 1
              }, 8, ["to"])
            ]),
            _: 1
          }),
          (_ctx.$attrs.isManager)
            ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                _createVNode(_component_router_link, {
                  class: "link",
                  to: `/${teamName.value}/manage/players/${player.value.id}`
                }, {
                  default: _withCtx(() => [
                    _cache[0] || (_cache[0] = _createElementVNode("i", { class: "fas fa-gear" }, null, -1)),
                    _createTextVNode(" " + _toDisplayString(_unref(t)('project.manage.player.goToSettings')), 1)
                  ]),
                  _: 1
                }, 8, ["to"])
              ]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["title", "description"]))
    : _createCommentVNode("", true)
}
}

})