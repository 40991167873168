<template>
  <SetBumperDisplayModal
    v-if="isShowSetBumperDisplayModal"
    :id-to-edit="bumperDisplayId"
    :is-creating-from-parent="Boolean(summaryBumperContents?.length && !bumperDisplayId)"
    :onClose="onCloseSetBumperDisplayModal" />
  <Section id="bumperDisplay" class="video-caption-section" title="Current Bumper Insertion" v-if="loadBumperDisplayState.status !== 'loading'">
    <div class="bumper-schedule-information" v-if="!isEmpty && loadBumperDisplayState.status === 'success'">
      <Alert priority="warning" :alertText="$t('glossary:caution')" :description="$t('project.bumperSchedule.caution')" />
      <div class="information-box">
        <div class="title">Intro 1 :</div>
        <div class="bumper-schedule-list">
          <template v-if="intro1?.length">
            <div v-for="bumper in intro1" :key="bumper.id" class="bumper-schedule">
              {{bumper?.name}}
              <Badge
                size="small"
                :title="bumper.visibility?.status"
                :badgeStyle="getBadgeStyle(bumper)" />
            </div>
          </template>
          <div v-else class="bumper-empty-text">{{$t('project.bumperSchedule.position.empty')}}</div>
        </div>
      </div>
      <div class="information-box">
        <div class="title">Intro 2 :</div>
        <div class="bumper-schedule-list">
          <template v-if="intro2?.length">
            <div v-for="bumper in intro2" :key="bumper.id" class="bumper-schedule">
              {{bumper?.name}}
              <Badge
                size="small"
                :title="bumper.visibility?.status"
                :badgeStyle="getBadgeStyle(bumper)" />
            </div>
          </template>
          <div v-else class="bumper-empty-text">{{$t('project.bumperSchedule.position.empty')}}</div>
        </div>
      </div>
      <div class="information-box">
        <div class="title">Outro 1 :</div>
        <div class="bumper-schedule-list">
          <template v-if="outro1?.length">
            <div v-for="bumper in outro1" :key="bumper.id" class="bumper-schedule">
              {{bumper?.name}}
              <Badge
                size="small"
                :title="bumper.visibility?.status"
                :badgeStyle="getBadgeStyle(bumper)" />
            </div>
          </template>
          <div v-else class="bumper-empty-text">{{$t('project.bumperSchedule.position.empty')}}</div>
        </div>
      </div>
      <div class="information-box">
        <div class="title">Outro 2 :</div>
        <div class="bumper-schedule-list">
          <template v-if="outro2?.length">
            <div v-for="bumper in outro2" :key="bumper.id" class="bumper-schedule">
              {{bumper?.name}}
              <Badge
                size="small"
                :title="bumper.visibility?.status"
                :badgeStyle="getBadgeStyle(bumper)" />
            </div>
          </template>
          <div v-else class="bumper-empty-text">{{$t('project.bumperSchedule.position.empty')}}</div>
        </div>
      </div>
    </div>
    <MessageBox
      v-else
      :title="$t('video.bumperSchedule.empty.title')"
      :description="$t('project.bumperSchedule.empty.description')"
      :buttonTitle="canUpdateProject ? '+ ' + $t('bumperSchedule.empty.button'): null"
      :buttonUrl="`/${teamName}/manage/bumper-insertion/create?contentType=project&contentId=${currentProject.key}`"
      buttonSuffixIconClassName="fas fa-chevron-right"
    />
    <template v-slot:section-footer-with-border v-if="$attrs.isManager && !isEmpty">
      <Button
        buttonStyle="link-secondary"
        type="button"
        @click="onClickManageBumper"
        class="btn btn-manage-bumper-schedule">
        {{$t('project.bumperSchedule.manage.button')}}
        <template v-slot:icon-suffix>
          <i class="fa fa-chevron-right" />
        </template>
      </Button>
    </template>
  </Section>
  <PreLoaderSection v-if="loadBumperDisplayState.status === 'loading'" />
</template>

<script>
import { mapState } from 'vuex';
import { useBumperDisplay } from '@/modules/bumperDisplay/utils';
import { useBumper } from '@/modules/bumper/utils';
import Section from '@/modules/shared/components/organisms/section/Section.vue';
import SetBumperDisplayModal from '@/modules/bumperDisplay/components/organisms/setBumperDisplayModal/SetBumperDisplayModal.vue';
import MessageBox from '@/modules/shared/components/molecules/messageBox/MessageBox.vue';
import Alert from '@/modules/shared/components/atoms/alert/Alert.vue';
import Button from '@/modules/shared/components/atoms/button/Button.vue';
import Badge from '@/modules/shared/components/atoms/badge/Badge.vue';
import { loadProjectBumperDisplay } from '@/modules/bumperDisplay';
import { setStateLoadingStatusByType } from '@/modules/shared/utils/stateManagement';
import { formatErrorObject } from '@/modules/shared/utils/errorFormatter';
import PreLoaderSection from '@/modules/shared/components/atoms/preLoaderSection/PreLoaderSection.vue';

export default {
  inheritAttrs: false,
  components: {
    Section,
    SetBumperDisplayModal,
    MessageBox,
    Alert,
    Button,
    PreLoaderSection,
    Badge,
  },
  data() {
    return {
      intro1: [],
      intro2: [],
      outro1: [],
      outro2: [],
      isShowSetBumperDisplayModal: false,
      loadBumperDisplayState: setStateLoadingStatusByType(),
      bumperDisplay: null,
    };
  },
  computed: {
    ...mapState({
      currentProject: (state) => state.project.currentProject,
    }),
    bumperDisplayId() {
      return this.currentProject?.bumperDisplay?.id;
    },
    summaryBumperContents() {
      return this.bumperDisplay?.summaryBumperContents || [];
    },
    isEmpty() {
      return !this.bumperDisplayId;
    },
    teamName() {
      return this.$route.params.teamName;
    },
    projectKey() {
      return this.$route.params.projectKey;
    },
    canUpdateProject() {
      return this.$attrs?.canUpdateProject;
    },
  },
  created() {
    this.loadBumperDisplay();
  },
  methods: {
    async loadBumperDisplay() {
      try {
        this.loadBumperDisplayState = setStateLoadingStatusByType({ type: 'loading' });
        this.resetData();
        const response = await loadProjectBumperDisplay(this.currentProject?.key);
        this.bumperDisplay = response.data;
        this.mapBumperDisplayData();
        this.loadBumperDisplayState = setStateLoadingStatusByType({ type: 'success' });
      } catch (error) {
        this.loadBumperDisplayState = setStateLoadingStatusByType({ type: 'error', error: formatErrorObject(error, 'Bumper') });
      }
    },
    mapBumperDisplayData() {
      if (!this.summaryBumperContents?.length) {
        return;
      }

      const { categorizedBumpers } = useBumperDisplay(this.bumperDisplay);

      this.intro1 = categorizedBumpers.intro1;
      this.intro2 = categorizedBumpers.intro2;
      this.outro1 = categorizedBumpers.outro1;
      this.outro2 = categorizedBumpers.outro2;
    },
    getBadgeStyle(bumper) {
      const { getStatusBadgeStyle } = useBumper();

      return getStatusBadgeStyle(bumper);
    },
    onCloseSetBumperDisplayModal() {
      this.isShowSetBumperDisplayModal = false;
      this.loadBumperDisplay();
      this.$router.push({ query: null, hash: '#bumperDisplay' });
    },
    onClickManageBumper() {
      const { bumperDisplayId } = this;

      if (!bumperDisplayId) {
        this.$router.push(`/${this.teamName}/manage/bumper-insertion/create?contentType=project&contentId=${this.currentProject.key}&projectKey=${this.currentProject.key}`);
      } else {
        this.$router.push(`/${this.teamName}/manage/bumper-insertion/${this.bumperDisplayId}`);
      }
    },
    resetData() {
      this.intro1 = [];
      this.intro2 = [];
      this.outro1 = [];
      this.outro2 = [];
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/global-variables';
@import '~@/assets/scss/breakpoint';

.information-box {
  padding: $spacing-base;
  background: #F8F9FA;
  margin-bottom: $spacing-base;
  display: flex;
  color: $grey-800;
  font-weight: 500;
  border-radius: 5px;

  &:last-child {
    margin-bottom: 0;
  }

  .title {
    margin-right: $spacing-base;
  }

  .content:not(:first-child) {
    margin-top: $spacing-base / 2;
  }
}

.bumper-schedule {
  display: flex;
  align-items: center;
}

.bumper-schedule:not(:first-child) {
  margin-top: $spacing-4;
}

.bumper-empty-text {
  color: $grey-400;
  font-style: italic;
  font-weight: $font-weight-base;
}

:deep(.badge) {
  text-transform: capitalize;
  margin-left: $spacing-12;
}

:deep(.section-footer) {
  padding: 8px 0 !important;
}

:deep(.alert-description) {
  b {
    text-decoration: underline;
  }
}

.btn-manage-bumper-schedule {
  &:hover {
    text-decoration: underline;
  }
}
</style>
