<template>
  <div v-if="data" class="user-info-container">
    <p v-if="title" class="title">{{title}}</p>
    <div class="profile-item">
      <Logo
        class="logo"
        :user="data" />
      <div class="profile-text">
        <div v-if="data.name" class="username" data-test="user-info-item-name">{{data.name}}</div>
        <div v-if="dateTime" class="date" data-test="user-info-item-date">
          {{$t('common:form.on')}} {{formatVideoDateTime(dateTime)}}
        </div>
        <div v-else class="date" data-test="user-info-item-date">
          {{$t('common:form.on')}} {{formatVideoDateTime(data.updatedAt)}}
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { toRefs } from 'vue';
import Logo from '@/modules/shared/components/atoms/logo/Logo.vue';
import { formatVideoDateTime } from '@/modules/shared/utils/dateFormatter';
import type { Nullable } from '@/types/index.type';
import type { User } from '@/modules/user';

interface UserInfoItemProps {
  data: Nullable<User>;
  title?: string;
  dateTime?: string;
}

const props = defineProps<UserInfoItemProps>();
const { data, title, dateTime } = toRefs(props);
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/global-variables.scss';

.profile-item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.profile-text {
  margin-left: 8px;
}

.title {
  margin-bottom: 4px;
}
</style>
