import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "video-cover-image" }
const _hoisted_2 = {
  key: 0,
  class: "video-duration"
}
const _hoisted_3 = { class: "video-info" }
const _hoisted_4 = { class: "video-title" }
const _hoisted_5 = { class: "action-container" }
const _hoisted_6 = {
  key: 1,
  class: "recover-success"
}

import _ from 'lodash';
import {
  computed, ref, toRefs, watch,
} from 'vue';
import { useI18n } from 'vue-i18n';
import Column from '@/modules/shared/components/molecules/column/Column.vue';
import Row from '@/modules/shared/components/molecules/row/Row.vue';
import TextCell from '@/modules/shared/components/molecules/textCell/TextCell.vue';
import { differenceInDays, intervalToDuration } from 'date-fns';
import Tooltip from '@/modules/shared/components/atoms/tooltip/Tooltip.vue';
import ImageView from '@/modules/shared/components/atoms/imageView/ImageView.vue';
import Button from '@/modules/shared/components/atoms/button/Button.vue';
import SuccessToast from '@/modules/shared/components/molecules/toasts/SuccessToast.vue';
import {
  getVideoDuration,
  getVideoUploadCoverImage,
} from '@/modules/videoDetail/utils/videoManagement';
import type { DeletedVideo } from '@/modules/shared/types/video.type';
import { useStore } from '../../../store/hooks';
import ConfirmRecoverVideoModal from '../../organisms/confirmRecoverVideoModal/ConfirmRecoverVideoModal.vue';

const DEFAULT_COVER_IMAGE = 'https://i.imgur.com/NhBmeTI.png';

interface DeletedVideoItemProps {
  video: DeletedVideo;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'DeletedVideoItem',
  props: {
    video: {}
  },
  setup(__props: any) {

const props = __props;
const { video } = toRefs(props);

const { t } = useI18n();
const store = useStore();

const isRecoverModalShowing = ref<boolean>(false);
const isRecovered = ref<boolean>(false);
const recoveredProjectIds = computed(() => store.state.projectDeletedVideoList.recoveredProjectIds);
const currentProject = computed(() => store.state.project?.currentProject);
const restoreDeletedProjectSuccess = ref<boolean>(false);
const restoreToAnotherProjectSuccess = ref<boolean>(false);
const restoredProjectName = ref<string>('');
const restoredProjectVideoCount = ref<number>(0);
const restoredVideoName = ref<string>('');

const currentUser = computed(() => store.state.user.currentUser);

function onClickRecover() {
  if (isRecovered.value) {
    return;
  }
  isRecoverModalShowing.value = true;
}

function onCloseRecoverModal() {
  isRecoverModalShowing.value = false;
}

function isVideoDurationAvailable(): boolean {
  return !_.isEmpty(_.get(video, 'duration', null));
}

function onRecoverSuccess() {
  isRecovered.value = true;
}

function getDays(): string {
  const { deletedAt } = video.value;

  if (!deletedAt) {
    return 'N/A';
  }

  const { hours } = intervalToDuration({
    start: new Date(deletedAt),
    end: new Date(),
  });

  /** days from intervalToDuration() above is days in one month, not total days. */
  const days = Math.abs(differenceInDays(new Date(), new Date(deletedAt)));

  if (!_.isNil(days) && days >= 1) {
    return t('common:dayAgo', { count: days });
  }

  if (!_.isNil(days) && days === 0) {
    if (!_.isNil(hours) && hours < 1) {
      return t('common:lessThan1HourAgo');
    }

    return t('common:hourAgo', { count: hours });
  }

  return 'N/A';
}

function onRestoreDeletedProjectSuccess({ projectName, videoCount }: { projectName: string, videoCount: number }) {
  restoredProjectName.value = projectName;
  restoredProjectVideoCount.value = videoCount;
  restoreDeletedProjectSuccess.value = true;
}

function onRestoreToAnotherProjectSuccess({ projectName, videoName }: { projectName: string, videoName: string }) {
  restoredProjectName.value = projectName;
  restoredVideoName.value = videoName;
  restoreToAnotherProjectSuccess.value = true;
}

watch(recoveredProjectIds, () => {
  if (recoveredProjectIds.value.includes(video.value.projectId)) {
    isRecovered.value = true;
  }
});


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (restoreDeletedProjectSuccess.value)
      ? (_openBlock(), _createBlock(SuccessToast, {
          key: 0,
          duration: 10000,
          title: _unref(t)('project.deleteVideos.recover.successToast.title'),
          description: _unref(t)('project.deleteVideos.recover.currentProject.successToast.description', { projectName: restoredProjectName.value, count: restoredProjectVideoCount.value })
        }, null, 8, ["title", "description"]))
      : _createCommentVNode("", true),
    (restoreToAnotherProjectSuccess.value)
      ? (_openBlock(), _createBlock(SuccessToast, {
          key: 1,
          duration: 10000,
          title: _unref(t)('project.deleteVideos.recover.successToast.title'),
          description: _unref(t)('project.deleteVideos.recover.anotherProject.successToast.description', { projectName: restoredProjectName.value, videoName: restoredVideoName.value })
        }, null, 8, ["title", "description"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      onClick: onClickRecover,
      class: _normalizeClass([
      'video-item',
      { 'is-recovered': isRecovered.value },
      { 'no-hover-action': isRecovered.value },
    ])
    }, [
      (isRecoverModalShowing.value)
        ? (_openBlock(), _createBlock(ConfirmRecoverVideoModal, {
            key: 0,
            onSuccess: onRecoverSuccess,
            onRestoreDeletedProjectSuccess: onRestoreDeletedProjectSuccess,
            onRestoreToAnotherProjectSuccess: onRestoreToAnotherProjectSuccess,
            video: _unref(video),
            onCloseModal: onCloseRecoverModal
          }, null, 8, ["video"]))
        : _createCommentVNode("", true),
      _createVNode(Row, { "vertical-align": "center" }, {
        default: _withCtx(() => [
          _createVNode(Column, { width: "140px" }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                _createVNode(ImageView, {
                  source: _unref(getVideoUploadCoverImage)({
              video: _unref(video),
              defaultCoverImage: DEFAULT_COVER_IMAGE,
              currentUser: currentUser.value
            }),
                  imageSize: "small"
                }, null, 8, ["source"]),
                (isVideoDurationAvailable())
                  ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_unref(getVideoDuration)(_unref(video))), 1))
                  : _createCommentVNode("", true)
              ])
            ]),
            _: 1
          }),
          _createVNode(Column, {
            minWidth: "200px",
            isPrimaryColumn: ""
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("span", _hoisted_4, _toDisplayString(_unref(video).title), 1)
              ])
            ]),
            _: 1
          }),
          _createVNode(Column, { width: "200px" }, {
            default: _withCtx(() => [
              _createVNode(TextCell, {
                text: (_unref(video).project ?? currentProject.value)?.name
              }, null, 8, ["text"])
            ]),
            _: 1
          }),
          _createVNode(Column, { width: "200px" }, {
            default: _withCtx(() => [
              _createVNode(TextCell, {
                text: getDays()
              }, null, 8, ["text"])
            ]),
            _: 1
          }),
          _createVNode(Column, {
            width: "140px",
            isActionColumn: ""
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_5, [
                (!isRecovered.value)
                  ? (_openBlock(), _createBlock(Tooltip, {
                      key: 0,
                      class: "recover-button-container"
                    }, {
                      "tooltip-text": _withCtx(() => [
                        _createTextVNode(_toDisplayString(_unref(t)('common:recover')), 1)
                      ]),
                      default: _withCtx(() => [
                        _createVNode(Button, {
                          onClick: _cache[0] || (_cache[0] = ($event: any) => (onClickRecover())),
                          type: "button",
                          buttonStyle: "text-secondary"
                        }, {
                          "icon-prefix": _withCtx(() => _cache[1] || (_cache[1] = [
                            _createElementVNode("i", { class: "fas fa-clock-rotate-left" }, null, -1)
                          ])),
                          _: 1
                        })
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (isRecovered.value)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_6, [
                      _cache[2] || (_cache[2] = _createElementVNode("i", { class: "fas fa-check" }, null, -1)),
                      _createTextVNode(" " + _toDisplayString(_unref(t)('recentlyDeleted.success')), 1)
                    ]))
                  : _createCommentVNode("", true)
              ])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ], 2)
  ], 64))
}
}

})