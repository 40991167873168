import { axiosAPI } from '@/plugins/axios';
import qs from 'qs';
import { FilterPlayer, IPlayer } from '../types/Player.type';

export const loadPlayers = (filter: FilterPlayer) => {
  const query = qs.stringify(filter, { addQueryPrefix: true, skipNulls: true });
  if (query) {
    return axiosAPI.get(`/players${query}`);
  }
  return axiosAPI.get('/players');
};

export const loadPlayerByIdAPI = (playerId:string) => axiosAPI.get(`/players/${playerId}`);
export const createPlayerAPI = (payload: IPlayer) => axiosAPI.post('/players', payload);
export const updatePlayerByIdAPI = (playerId:string, payload: IPlayer) => axiosAPI.put(`/players/${playerId}`, payload);
