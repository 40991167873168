import type { Bumper } from '@/modules/bumper/types';
import { UsedIn } from '@/modules/bumper/types';
import filter from 'lodash/filter';
import get from 'lodash/get';

export const useBumper = () => {
  function calculatePeriod(bumper: Bumper): number | null {
    const { visibilitySetting } = bumper;
    if (!visibilitySetting) {
      return null;
    }
    const { startPublishAt, endPublishAt } = visibilitySetting;

    if (startPublishAt && endPublishAt) {
      const start = new Date(startPublishAt);
      const end = new Date(endPublishAt);
      const timeDiff = (end.getTime() - start.getTime()) / (1000 * 3600 * 24);
      return Math.round(timeDiff);
    }
    return null;
  }

  function getStatusBadgeStyle(bumper: Bumper): 'warning' | 'success' | 'danger' | 'secondary' {
    const { visibility } = bumper;

    switch (visibility.status) {
      case 'scheduled':
        return 'warning';
      case 'active':
        return 'success';
      case 'expired':
        return 'danger';
      default:
        return 'secondary';
    }
  }

  return {
    calculatePeriod,
    getStatusBadgeStyle,
  };
};

function getUsedInType(usedIn: UsedIn): string {
  return get<UsedIn, string, string>(usedIn, 'shouldAppearOnContent.type', '');
}

export function getUsedInContentType(usedInData: UsedIn[]): Array<string> {
  const types: Array<string> = [];

  usedInData.forEach((data) => {
    const type = getUsedInType(data);
    if (!types.includes(type)) {
      types.push(type);
    }
  });

  const sortedType = [];
  if (types.find((ele) => ele === 'project')) {
    sortedType.push('project');
  }
  if (types.find((ele) => ele === 'contentCollection')) {
    sortedType.push('contentCollection');
  }
  if (types.find((ele) => ele === 'video')) {
    sortedType.push('video');
  }
  return sortedType;
}

export function getUsedInContentGroupByType(usedInData: UsedIn[], type: string): Array<UsedIn> {
  return filter(usedInData, (data) => {
    const currentType = getUsedInType(data);
    return type === currentType;
  });
}

type BumperStatusTab = {title: string; key: string;};

export function getBumperStatusTabs(): BumperStatusTab[] {
  return [
    { title: 'Active + Scheduled', key: 'active,scheduled' },
    { title: 'Draft', key: 'draft' },
    { title: 'Expired', key: 'expired' },
    { title: 'All', key: 'all' },
  ];
}
