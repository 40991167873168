<template>
  <Section
    :title="getTitle()"
    :description="$t('urlSigningKey.description')">
    <div v-if="loadUrlSigningKeyListState.status === 'loading'">
      <div class="card-list">
        <div class="card-container card-container-border" v-for="n in 4" :key="n">
          <PreLoaderCard
            :options="{'height': '128px'}" />
        </div>
      </div>
    </div>
    <div v-if="loadUrlSigningKeyListState.status !== 'loading'">
      <UrlSigningKeyList
        v-if="loadUrlSigningKeyListState.status === 'success'" />
      <div v-if="loadUrlSigningKeyListState.status === 'error'">
        <ErrorMessage
          :statusCode="loadUrlSigningKeyListState.error?.statusCode"
          :title="loadUrlSigningKeyListState.error?.message" />
      </div>
    </div>
  </Section>
</template>

<script>
import { mapState } from 'vuex';
import ErrorMessage from '@/modules/shared/components/atoms/errorMessage/ErrorMessage.vue';
import Section from '@/modules/shared/components/organisms/section/Section.vue';
import PreLoaderCard from '@/modules/shared/components/atoms/preLoaderCard/PreLoaderCard.vue';
import UrlSigningKeyList from '../urlSigningKeyList/UrlSigningKeyList.vue';

export default {
  components: {
    Section,
    PreLoaderCard,
    ErrorMessage,
    UrlSigningKeyList,
  },
  computed: {
    ...mapState({
      urlSigningKeyList: (state) => state.urlSigningKeyList.urlSigningKeyList,
      loadUrlSigningKeyListState: (state) => state.urlSigningKeyList.loadUrlSigningKeyListState,
    }),
    buttonLabel() {
      if (window.innerWidth <= 576) {
        return this.$t('common:button.seeDetailShortText');
      }
      return this.$t('common:button.seeDetail');
    },
    teamName() {
      return this.$route.params.teamName;
    },
  },
  data() {
    return {
      filter: {
        limit: 100,
      },
    };
  },
  created() {
    this.load();
  },
  methods: {
    async load() {
      this.$store.dispatch('loadUrlSigningKeys', this.filter);
    },
    getTitle() {
      if (this.urlSigningKeyList && this.urlSigningKeyList.length) {
        return `URL Signing Keys (${this.urlSigningKeyList.length})`;
      }
      return 'URL Signing Keys (0)';
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/global-variables.scss';

.card-list {
  display: flex;
  flex-wrap: wrap;
}

.card-container-border {
  border: 1px solid $grey-300;
  border-radius: 4px;
}

.card-container {
  flex: 0 0 calc(50% - 8px);
  margin-right: $spacing-base * 0.75;
  margin-bottom: $spacing-base;
  text-decoration: none;

  &:nth-child(2n+0) {
    margin-right: 0;
  }
}

</style>
