<template>
  <Modal
    size="small"
    :title="t('video.delete.action', { videoName: currentVideoName} )"
    :primaryButtonText="t('common:button.delete')"
    primaryButtonStyle="danger"
    :isDisabledPrimaryButton="saveCurrentVideoState.status ==='saving'"
    :onClickPrimaryButton="deleteVideo"
    :isShowModalHeader="saveErrorCode !== 409"
    :isShowModalFooter="saveErrorCode !== 409"
    @modalClose="onClose">
    <template v-slot:body>
      <div v-if="saveErrorCode !== 409">{{t('video.delete.warning')}}</div>
      <div v-if="saveErrorCode !== 409">{{t('video.delete.description')}}</div>
      <div class="delete-video-warning-wrapper" v-if="saveErrorCode === 409">
        <i class="fa-solid fa-exclamation-triangle" />
        <div class="warning-title">
          Video '{{currentVideoName}}' is currently in use!
        </div>
        <div class="warning-description">
          This video is currently used as a video source in Bumper(s). You need to change video source or remove it first.
        </div>
        <Button
          buttonStyle="text-primary"
          @click.stop="onSeeAllRelatedBumpers">
          <template v-slot:content>
            See all related Bumpers
          </template>
          <template v-slot:icon-suffix>
            <i class="fa-solid fa-arrow-right"></i>
          </template>
        </Button>
      </div>
    </template>
    <template v-slot:footer>
      <span class="text-danger" v-if="saveErrorCode === 404"><i class="fa fa-xmark mr-1" />{{t('response:error404.title', { item: 'project'})}}</span>
      <span class="text-danger" v-if="saveErrorCode === 422"><i class="fa fa-xmark mr-1" />{{t('response:error422.title')}}</span>
      <span class="text-danger" v-if="saveErrorCode === 500"><i class="fa fa-xmark mr-1" />{{t('response:error500.title')}}</span>
    </template>
  </Modal>
</template>

<script lang="ts" setup>
import _ from 'lodash';
import { computed, toRefs } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from '@/store';
import { getQueryAsString } from '@/modules/shared/utils/query';
import Modal from '@/modules/shared/components/organisms/modal/Modal.vue';
import Button from '@/modules/shared/components/atoms/button/Button.vue';
import { Video } from '@/modules/shared/types/video.type';

interface DeleteVideoModalProps {
  onClose: () => void;
  // Pass an object on VideoItem.vue for deleting outside VideoDetail
  videoObject?: Video | null;
}

const props = defineProps<DeleteVideoModalProps>();
const { onClose, videoObject } = toRefs(props);

const { t } = useI18n();
const store = useStore();
const route = useRoute();
const router = useRouter();

const currentVideo = computed(() => store.state.video.currentVideo);
const teamName = computed(() => getQueryAsString(route.params, 'teamName'));
const projectKey = computed(() => getQueryAsString(route.params, 'projectKey'));
const videoKey = computed(() => getQueryAsString(route.params, 'videoKey') ?? currentVideo.value?.key);
const saveCurrentVideoState = computed(() => store.state.video.saveCurrentVideoState);
const saveErrorCode = computed<number | null>(() => {
  if (saveCurrentVideoState.value.status === 'error') {
    return _.get(saveCurrentVideoState.value, 'error.status', 500);
  }
  return null;
});

const currentVideoName = computed(() => {
  if (videoObject.value) {
    return _.get(videoObject.value, 'title', '');
  }
  return _.get(currentVideo.value, 'title', '');
});

async function deleteVideo() {
  let videoKeyInThisFunction = '';

  if (videoObject.value) {
    videoKeyInThisFunction = _.get(videoObject.value, 'key', '');
  } else if (videoKey.value) {
    videoKeyInThisFunction = videoKey.value;
  }

  await store.dispatch('deleteVideo', videoKeyInThisFunction);
  if (saveErrorCode.value) {
    return;
  }
  if (videoObject.value) {
    // If deleting on SectionVideoList, reload the list.
    const filter = { projectKey: projectKey.value };
    await store.dispatch('loadProjectVideoList', { filter });
  } else {
    // If deleting inside VideoDetail, go back to SectionVideoList.
    await router.push(`/${teamName.value}/projects/${projectKey.value}`);
  }
}

function onSeeAllRelatedBumpers() {
  router.push({
    path: `/${teamName.value}/manage/bumpers`,
    query: { q: videoKey.value },
  });
}
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/global-variables.scss';

.title {
  overflow: hidden;
  text-overflow: ellipsis;
}

.delete-video-warning-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}
:deep(.fa-exclamation-triangle) {
  color: $warning;
  margin: $spacing-24 0;
  font-size: $font-level-1;
}
.warning-title {
  color: $grey-600;
  font-size: $font-level-4;
  font-weight: $font-weight-bold;
  margin-bottom: $spacing-8;
  text-align: center;
}
.warning-description {
  line-height: $line-height-description;
  margin-bottom: $spacing-base;
  text-align: center;
}

.page .page-header .modal-header .title {
  color: $body-color;
}
</style>
