import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "upload-summary-modal-container" }
const _hoisted_2 = { class: "list-container" }

import _ from 'lodash';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import Alert from '@/modules/shared/components/atoms/alert/Alert.vue';
import Modal from '@/modules/shared/components/organisms/modal/Modal.vue';
import { getQueryAsString } from '@/modules/shared/utils/query';
import { isVideoProbeNotComplete } from '@/modules/videoDetail/utils/videoManagement';
import { useRoute, useRouter } from 'vue-router';
import ExtendedVideoUploadProgressItem from '../../molecules/extendedVideoUploadProgressItem/ExtendedVideoUploadProgressItem.vue';
import { useStore } from '../../../store/hooks';
import { getAmountOfUploadingVideos, getUploadVideoKeysByStatus } from '../../../utils';


export default /*@__PURE__*/_defineComponent({
  __name: 'UploadSummaryModal',
  setup(__props) {

const store = useStore();
const router = useRouter();
const route = useRoute();
const { t } = useI18n();

const teamName = computed(() => getQueryAsString(route.params, 'teamName'));
const projectKey = computed(() => getQueryAsString(route.params, 'projectKey'));

const videoUploadKeys = computed(() => store.state.videoUpload.videoUploadKeys);
const isDisplayProbeStatus = computed(() => store.state.videoUpload.isDisplayProbeStatus);
const videosUploadByKey = computed(() => store.state.videoUpload.videosUploadByKey);
const videoKeysGroupByState = computed(() => store.state.videoUpload.videoKeysGroupByState);
const isFirstTimeUploadSummaryMinimized = computed(() => store.state.videoUpload.isFirstTimeUploadSummaryMinimized);

const amountOfUploaded = computed(() => {
  const uploadedItemKeys = getUploadVideoKeysByStatus(videoKeysGroupByState.value, 'uploaded');
  if (uploadedItemKeys) {
    if (!isDisplayProbeStatus.value) {
      return uploadedItemKeys.length;
    }

    const probeComplete = _.filter(uploadedItemKeys, (videoKey) => {
      const video = videosUploadByKey.value[videoKey];
      if (!isVideoProbeNotComplete(video)) {
        return videoKey;
      }
      return false;
    });

    return probeComplete.length;
  }
  return 1;
});

const amountOfQueued = computed(() => {
  const queuedItems = getUploadVideoKeysByStatus(videoKeysGroupByState.value, 'queued');
  return queuedItems?.length ?? 0;
});

const amountOfUploading = computed(() => {
  const uploadingItems = getUploadVideoKeysByStatus(videoKeysGroupByState.value, 'uploading');
  return uploadingItems?.length ?? 0;
});

const title = computed(() => {
  const total = videoUploadKeys.value.length;

  if (getAmountOfUploadingVideos(amountOfQueued.value, amountOfUploading.value)) {
    return t(`video.uploading.current${amountOfUploading.value > 1 ? '_plural' : ''}`, { count: amountOfUploading.value });
  }

  if (amountOfUploaded.value !== total) {
    return t(`video.uploading.uploaded${amountOfUploaded.value > 1 ? '_plural' : ''}`, { count: amountOfUploaded.value, total });
  }

  return t(`video.uploading.done${amountOfUploaded.value > 1 ? '_plural' : ''}`, { count: amountOfUploaded.value });
});

function onMinimize() {
  store.commit('setIsUploadSummaryMinimized', true);
  if (!isFirstTimeUploadSummaryMinimized.value) {
    router.push(`/${teamName.value}/projects/${projectKey.value}`);

    /**
     * The traditional uploading popup on the bottom-right of the screen will be displayed
     * after this modal is minimized just at the first time.
     */
    store.commit('setIsFirstTimeUploadSummaryMinimized', true);
  }
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(Modal, {
    onModalClose: onMinimize,
    primaryButtonText: _unref(t)('video.upload.summaryModal.button'),
    onClickPrimaryButton: onMinimize,
    title: title.value,
    isShowSecondaryButton: false,
    size: "large"
  }, {
    body: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(Alert, {
          priority: "info",
          alertText: _unref(t)('video.upload.summaryModal.description')
        }, null, 8, ["alertText"]),
        _createElementVNode("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(videosUploadByKey.value, (video) => {
            return (_openBlock(), _createBlock(ExtendedVideoUploadProgressItem, {
              key: video.key,
              video: video
            }, null, 8, ["video"]))
          }), 128))
        ])
      ])
    ]),
    _: 1
  }, 8, ["primaryButtonText", "title"]))
}
}

})