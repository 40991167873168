<template>
  <div class="radio-btn-wrapper">
    <div
      class="checkbox-ui-container"
      :class="{ checked: modelValue, disabled: disabled }"
      @click="onUpdateValue"
    >
      <input type="radio" class="input" :disabled="disabled" :checked="modelValue" />
      <span class="checkmark" />
    </div>
    <div class="text-container">
      <div class="title">{{ label }}
        {{ " " }}<span v-if="subLabel" class="sublabel">({{ subLabel }})</span>
      </div>
      <div class="description" v-if="description && modelValue" v-html="description"></div>
      <div class="description" v-if="warning">
        <div class="warning-icon">
          <i class="fas fa-info-circle" /></div>
        {{ warning }}
      </div>
      <div
        v-if="$slots['subField'] && modelValue"
        :class="{
          'sub-field-wrapper': true,
          'sub-field-wrapper-mt-8': subFieldMt8,
        }" >
        <slot  name="subField" v-if="modelValue"></slot>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  emits: ['update:modelValue'],
  props: {
    disabled: {
      default: false,
      type: Boolean,
    },
    label: {
      type: String,
      default: '',
      required: false,
    },
    subLabel: {
      type: String,
    },
    description: {
      type: String,
      default: '',
    },
    warning: {
      type: String,
    },
    modelValue: {
      type: Boolean,
      default: false,
    },
    onChange: {
      type: Function,
      default: () => {},
    },
    subFieldMt8: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onUpdateValue() {
      this.$emit('update:modelValue', !this.modelValue);
      this.onChange(!this.modelValue);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/global-variables.scss";

.radio-btn-wrapper {
  display: flex;
  column-gap: $spacing-base;
}
.checkbox-ui-container {
  background: $white;
  border: 2px solid $grey-300;
  width: 16px;
  height: 16px;
  position: relative;
  border-radius: 999px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &.checked {
    border-color: transparent;
    background-color: $ci-primary;
    .checkmark {
      display: block;
    }
  }
  &.disabled {
    background: $grey-300;
    pointer-events: none;
    .checkmark {
      background: $grey-400;
    }
  }
}

/* Hide the browser's default checkbox */
.input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  width: 6px;
  height: 6px;
  background-color: $grey-200;
  border-radius: 6px;
  display: none;
}

.text-container {
  font-weight: $font-weight-base;
  font-size: $font-level-7;
  display: flex;
  flex-direction: column;
  // row-gap: $spacing-8;
  flex: 1;
  .title {
    color: $grey-800;
    line-height: $line-height-base;
    // text-transform: capitalize;
    font-weight: $font-weight-bold;
    display: flex;
    align-items: baseline;
  }

  .sublabel{
    color: $grey-600;
    margin-left: 4px;
    font-weight: 400;
  }
  .description {
    color: $grey-600;
    line-height: $line-height-description;
    margin-top: $spacing-8;
    display: flex;
    align-items: center;
  }

  .warning-icon{
    display: flex;
    width: 12px;
    height: 12px;
    font-size: 12px;
    justify-content: center;
    align-items: center;
    color: $grey-400;
    margin-right: $spacing-4;
  }
}

.sub-field-wrapper {
  margin-top: $spacing-base;
}

.sub-field-wrapper-mt-8 {
  margin-top: $spacing-8;
}
</style>
