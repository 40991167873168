<template>
  <div>
    <AddProjectPlaybackChannelModal
      v-if="isAddProjectPlaybackChannelModalShowing"
      :onClose="onToggleAddProjectPlaybackChannelModal" />
    <AccessControlPolicyModal
      v-if="isAccessControlPolicyModalShowing"
      :data="selectedAccessControlPolicy"
      @close="onCloseAccessControlPolicyModal" />
    <SecureAndProtectContentModal
      v-if="isInfoModalShowing"
      :highlightRowNumber="-1"
      @close="onToggleInfoModal" />
    <Section :title="t('project.manage.setting.playbackChannel.title')">
      <div
        class="playback-channel"
        v-for="playbackChannel in playbackChannels"
        :key="playbackChannel.id">
        <ItemInformationCard :title="playbackChannel.name">
          <template v-slot:badge v-if="playbackChannel.id === primaryPlaybackChannelId">
            <Badge
              title="Primary"
              theme="dark"
              badgeStyle="info"
            />
          </template>
          <template v-slot:card-actions v-if="canUpdateProject">
            <Button buttonStyle="text-secondary" buttonClasses="btn-delete" type="button"
                    v-if="playbackChannel.id !== primaryPlaybackChannelId"
                    @click="onDeletePlaybackChannel(playbackChannel.id)">
              <template v-slot:content>
                {{ t('common:button.delete') }}
              </template>
            </Button>
            <Button buttonStyle="secondary" buttonClasses="setting-primary-button" type="button"
                    @click="onClickSetPrimaryPlaybackChannel(playbackChannel.id)"
                    :disabled="playbackChannel.id === primaryPlaybackChannelId">
              <template v-slot:content>
                {{ t('project.manage.setting.playbackChannel.setToPrimary') }}
              </template>
            </Button>
          </template>
          <div class="domain-container">
            <span class="label">
              {{ t('hostings.domain') }} ({{ getPlaybackDomains(playbackChannel).length }})
            </span>
            <div class="domains" v-if="getPlaybackDomains(playbackChannel)">
              <div class="value" v-for="(domain, index) in getPlaybackDomains(playbackChannel)" :key="index">
                {{ domain }}
              </div>
            </div>
          </div>
          <div class="information-wrapper">
            <div class="signed-url">
              <span class="label">
                Signed URL
                <i class="fa fa-circle-info ml-2" @click="onToggleInfoModal" />
              </span>
              <div class="value">
                {{
                  getSignedUrlValue(playbackChannel)
                    ? t('project.setup.playbackChannel.signedURL.isEnabled')
                    : t('project.setup.playbackChannel.signedURL.isDisabled')
                }}
              </div>
            </div>
            <div class="access-control-policy">
              <span class="label">
                Allowed / Blocked Website
                <i class="fa fa-circle-info ml-2" @click="onToggleInfoModal" />
              </span>
              <div class="value">
                <span v-if="isAllowAllDomains(playbackChannel)">{{
                  t('accessControlPolicy.allowAllWebsite')
                }}</span>
                <Button v-else buttonStyle="link-primary" type="button"
                        @click="onClickViewAccessControlPolicy(playbackChannel)">
                  <template v-slot:content>
                    {{ t('accessControlPolicy.allowSomeWebsite') }}
                  </template>
                </Button>
              </div>
            </div>
          </div>
        </ItemInformationCard>
      </div>
      <Button
        v-if="canUpdateProject"
        buttonClasses="btn-add-playback-channel"
        buttonStyle="text-primary"
        isFullWidth
        type="button"
        @click="onToggleAddProjectPlaybackChannelModal">
        <template v-slot:icon-prefix>
          <i class="fas fa-plus mr-1"></i>
        </template>
        <template v-slot:content>
          {{ t('project.manage.setting.playbackChannel.add') }}
        </template>
      </Button>
      <template v-slot:section-footer-with-border v-if="$attrs.isManager">
        <div class="playback-channel-link" v-if="playbackChannels">
          <router-link class="link" :to="`/${teamName}/manage/playback-channels`">
            <i class="fas fa-gear" /> {{ t('project.manage.playback.goToSettings') }}
          </router-link>
        </div>
      </template>
    </Section>
  </div>
</template>

<script lang="ts" setup>
import type { AccessControlPolicy } from '@/modules/playbackChannel/types/playbackChannel.type';
import _ from 'lodash';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';
import { useStore } from '@/modules/projectDefaultUploadSetting/store/hooks';
import type { PlaybackChannel } from '@/modules/playbackChannel';
import SecureAndProtectContentModal from '@/modules/projectCreate/components/organisms/secureAndProtectContentModal/SecureAndProtectContentModal.vue';
import { AccessControlPolicyModal } from '@/modules/playbackChannel';
import Section from '@/modules/shared/components/organisms/section/Section.vue';
import ItemInformationCard from '@/modules/shared/components/atoms/itemInformationCard/ItemInformationCard.vue';
import Button from '@/modules/shared/components/atoms/button/Button.vue';
import Badge from '@/modules/shared/components/atoms/badge/Badge.vue';
import AddProjectPlaybackChannelModal from '../addProjectPlaybackChannelModal/AddProjectPlaybackChannelModal.vue';

const { t } = useI18n();
const store = useStore();
const route = useRoute();

const isInfoModalShowing = ref(false);
const selectedAccessControlPolicy = ref<AccessControlPolicy | null>(null);
const isAccessControlPolicyModalShowing = ref(false);
const isAddProjectPlaybackChannelModalShowing = ref(false);

const teamName = computed(() => route.params.teamName);
const currentProject = computed(() => store.state.project.currentProject);
const playbackChannels = computed(() => store.state.project.currentProject?.playbackChannels);
const primaryPlaybackChannelId = computed(() => store.state.project.currentProject?.primaryPlaybackChannelId);
const canUpdateProject = computed(() => currentProject.value?.actions?.updateProject);

function getSignedUrlValue(playbackChannel: PlaybackChannel) {
  return _.get(playbackChannel, 'signedUrl.required', false);
}

function isAllowAllDomains(playbackChannel: PlaybackChannel) {
  const allowDomains = _.get(playbackChannel, 'accessControlPolicy.allowDomains', []);
  const denyDomains = _.get(playbackChannel, 'accessControlPolicy.denyDomains', []);
  return !allowDomains.length && !denyDomains.length;
}

function getPlaybackDomains(playbackChannel: PlaybackChannel) {
  return [
    playbackChannel.defaultPlaybackDomain,
    ..._.get(playbackChannel, 'playbackDomains', []),
  ];
}

function onToggleInfoModal() {
  isInfoModalShowing.value = !isInfoModalShowing.value;
}

function onClickViewAccessControlPolicy(playbackChannel: PlaybackChannel) {
  selectedAccessControlPolicy.value = playbackChannel.accessControlPolicy;
  isAccessControlPolicyModalShowing.value = true;
}

function onCloseAccessControlPolicyModal() {
  isAccessControlPolicyModalShowing.value = false;
}

function onClickSetPrimaryPlaybackChannel(id: string) {
  if (_.isNil(currentProject.value)) {
    return;
  }

  store.dispatch('setProjectPrimaryPlaybackChannel', {
    projectKey: currentProject.value.key,
    primaryPlaybackChannelId: id,
  });
}

function onToggleAddProjectPlaybackChannelModal() {
  isAddProjectPlaybackChannelModalShowing.value = !isAddProjectPlaybackChannelModalShowing.value;
}

function onDeletePlaybackChannel(playbackChannelId: string) {
  if (_.isNil(currentProject.value)) {
    return;
  }

  store.dispatch('deleteProjectPlaybackChannel', {
    projectKey: currentProject.value.key,
    playbackChannelIds: [playbackChannelId],
  });
}

</script>

<style lang="scss" scoped>
@import '~@/assets/scss/global-variables.scss';
@import '~@/assets/scss/breakpoint.scss';

.playback-channel {
  margin-bottom: $spacing-base;

  :deep(.title-container) {
    flex-direction: row;
    align-items: center;
  }

  .information-wrapper {
    display: flex;
    margin-top: $spacing-base * 1.25;
    .signed-url {
      flex: 1 0 40%;
    }

    .access-control-policy {
      flex: 1 0 auto;
    }
  }

  .value {
    margin-top: $spacing-base * 0.25;
    color: #343A40;
  }

  .btn-link {
    padding: 0;
  }

  .fa-circle-info {
    cursor: pointer;
  }
}

.primary-badge {
  padding: 6px 8px;
  font-size: $font-level-8;
  font-weight: $font-weight-base;
  color: #fff;
  background: $ci-primary;
  border-radius: 0.25rem;
}

.gray-badge {
  padding: 2px 8px;
  margin-top: $spacing-8;
  margin-right: $spacing-8;
  font-size: $font-size-base;
  font-weight: $font-weight-base;
  color: $grey-800;
  background-color: $grey-200;
}

.button-text-short {
  display: none;
}

:deep(.setting-primary-button) {
  width: 160px;
}

:deep(.card-actions-container) {
  .btn-delete {
    padding: 0.375rem 1rem;
    margin-right: $spacing-base;
  }
}

:deep(.btn-add-playback-channel){
  margin-top: $spacing-24;
}

@media screen and (max-width: $max-layout-sm) {
  .playback-channel {
    .information-wrapper {
      flex-direction: column;

      .signed-url {
        flex: 1%;
        margin-bottom: $spacing-base * 1.25;
      }

      .access-control-policy {
        flex: 1;
      }
    }
  }

  .button-text-short {
    display: inline;
  }

  .button-text-full {
    display: none;
  }
}
</style>
