<template>
  <div class="image-view-container"
       :class="[imageClass, customClass]"
       :style="getImageAspectRatio()">
    <div class="image-wrapper">
      <img
        v-if="imageSource"
        :src="imageSource" />
      <div class="empty-container" v-if="!imageSource">
        <div v-if="isUseEmptyText">
          <div class="detail" v-if="!$slots['message']">{{$t('common:coverImage.notSet')}}</div>
          <slot name="message" class="detail" v-if="$slots['message']"></slot>
        </div>
        <img
          v-else
          src="@/assets/images/default-image-dark.png" />
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';

export default {
  props: {
    source: {
      type: [Object, String, Array],
    },
    defaultSource: {
      type: Object,
      default: () => {},
    },
    aspectRatio: {
      type: String,
      default: '16:9', // 16:9, 9:16, 4:3, 2:1, 1.91:1, 1:1, original
    },
    imageSize: {
      type: String,
      default: 'medium',
      validator: (value) => ([
        'default',
        'large',
        'medium',
        'small',
      ].indexOf(value) !== -1),
    },
    emptyMessageText: {
      type: String,
    },
    customClass: {
      type: String,
      default: '',
    },
    isUseEmptyText: {
      type: Boolean,
    },
  },
  computed: {
    imageSource() {
      if (this.source) {
        if (typeof this.source === 'string') {
          return this.source;
        }

        if (typeof this.source === 'object') {
          const imageObject = this.getImageBySize(this.source, this.imageSize);
          if (imageObject) {
            return imageObject;
          }

          return this.defaultSource;
        }
      }

      if (this.defaultSource) {
        return this.defaultSource;
      }
      return null;
    },
    imageClass() {
      let customClass = '';

      if (!this.imageSource) {
        customClass = `${customClass} image-empty`;
      }

      if (this.isOriginalSize()) {
        customClass = `${customClass} image-original`;
      }

      return customClass;
    },
  },
  methods: {
    getImageBySize(imageObject, size) {
      return _.get(imageObject, size, null);
    },
    isOriginalSize() {
      return this.aspectRatio && this.aspectRatio === 'original';
    },
    getImageAspectRatio() {
      if (this.aspectRatio && this.aspectRatio !== 'original') {
        const aspectRatio = this.aspectRatio.split(/[:]/);
        if (aspectRatio[0] && aspectRatio[1]) {
          const width = parseInt(aspectRatio[0], 10);
          const height = parseInt(aspectRatio[1], 10);
          return {
            paddingTop: `${(height / width) * 100}%`,
          };
        }
      }
      return '';
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/global-variables.scss';

.image-view-container {
  width: 100%;
  position: relative;
  overflow: hidden;

  &:not(.image-original) , &.image-empty.image-original {
    padding-top: 56.25%;

    .image-wrapper {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;

      img {
        width: 100%;
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
      }
    }
  }

  &.image-original {
    img {
      width: 100%;
    }
  }
}

.image-empty {
  background-color: $grey-700;
  position: relative;

  .empty-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    color: $grey-400;

    text-align: center;

    font-size: $font-level-8;
    font-family: $font-family-base;
  }
}

</style>
