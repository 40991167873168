import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "selected-playback-channel-information" }
const _hoisted_2 = { class: "title" }
const _hoisted_3 = { class: "information-group-wrapper" }
const _hoisted_4 = { class: "domain-container information-group" }
const _hoisted_5 = { class: "label" }
const _hoisted_6 = {
  key: 0,
  class: "value"
}
const _hoisted_7 = {
  key: 1,
  class: "domains"
}
const _hoisted_8 = { class: "signed-url information-group" }
const _hoisted_9 = { class: "value" }
const _hoisted_10 = { class: "access-control-policy information-group" }
const _hoisted_11 = { class: "value" }
const _hoisted_12 = { key: 0 }
const _hoisted_13 = { key: 1 }
const _hoisted_14 = { class: "collapse-wrapper" }
const _hoisted_15 = {
  key: 0,
  class: "access-control-policy-collapse"
}
const _hoisted_16 = { class: "label" }
const _hoisted_17 = {
  key: 0,
  class: "collapse-body"
}
const _hoisted_18 = {
  key: 1,
  class: "access-control-policy-collapse"
}
const _hoisted_19 = { class: "label" }
const _hoisted_20 = {
  key: 0,
  class: "collapse-body"
}

import _ from 'lodash';
import { ref, toRefs } from 'vue';
import type { PlaybackChannel } from '@/modules/playbackChannel';

interface PlaybackChannelSummaryProps {
  selectedPlaybackChannel: PlaybackChannel | null | undefined;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'PlaybackChannelSummary',
  props: {
    selectedPlaybackChannel: {}
  },
  setup(__props: any) {

const props = __props;
const { selectedPlaybackChannel } = toRefs(props);

const isAllowDomainCollapseShowing = ref<boolean>(true);
const isBlockDomainCollapseShowing = ref<boolean>(true);

function isAllowAllDomains(playbackChannel: PlaybackChannel | null | undefined) {
  const allowDomains = _.get(playbackChannel, 'accessControlPolicy.allowDomains', []);
  const denyDomains = _.get(playbackChannel, 'accessControlPolicy.denyDomains', []);
  return !allowDomains.length && !denyDomains.length;
}

function getPlaybackDomains(playbackChannel: PlaybackChannel | null | undefined): string[] {
  if (_.isNil(playbackChannel)) {
    return [];
  }

  return [playbackChannel.defaultPlaybackDomain, ..._.get(playbackChannel, 'playbackDomains', [])];
}

function getSignedUrlValue(playbackChannel: PlaybackChannel | null | undefined) {
  return _.get(playbackChannel, 'signedUrl.required', false);
}

function getAllowDomains(playbackChannel: PlaybackChannel | null | undefined) {
  return _.get(playbackChannel, 'accessControlPolicy.allowDomains', []);
}

function getDenyDomains(playbackChannel: PlaybackChannel | null | undefined) {
  return _.get(playbackChannel, 'accessControlPolicy.denyDomains', []);
}

function onClickAllowDomainCollapse() {
  isAllowDomainCollapseShowing.value = !isAllowDomainCollapseShowing.value;
}

function onClickBlockDomainCollapse() {
  isBlockDomainCollapseShowing.value = !isBlockDomainCollapseShowing.value;
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h5", _hoisted_2, _toDisplayString(_unref(selectedPlaybackChannel)?.name ?? ''), 1),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.$t('hostings.domain')) + " (" + _toDisplayString(getPlaybackDomains(_unref(selectedPlaybackChannel)).length) + ") ", 1),
        (!getPlaybackDomains(_unref(selectedPlaybackChannel)).length)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, " - "))
          : _createCommentVNode("", true),
        (getPlaybackDomains(_unref(selectedPlaybackChannel)))
          ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(getPlaybackDomains(_unref(selectedPlaybackChannel)), (domain, index) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: "value",
                  key: index
                }, _toDisplayString(domain), 1))
              }), 128))
            ]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_8, [
        _cache[0] || (_cache[0] = _createElementVNode("span", { class: "label" }, " Signed URL ", -1)),
        _createElementVNode("div", _hoisted_9, _toDisplayString(getSignedUrlValue(_unref(selectedPlaybackChannel)) ? _ctx.$t('domains.signedUrl.enabled') : _ctx.$t('domains.signedUrl.disabled')), 1)
      ]),
      _createElementVNode("div", _hoisted_10, [
        _cache[1] || (_cache[1] = _createElementVNode("span", { class: "label" }, " Allowed / Blocked Website ", -1)),
        _createElementVNode("div", _hoisted_11, [
          (isAllowAllDomains(_unref(selectedPlaybackChannel)))
            ? (_openBlock(), _createElementBlock("span", _hoisted_12, _toDisplayString(_ctx.$t('accessControlPolicy.allowAllWebsite')), 1))
            : (_openBlock(), _createElementBlock("span", _hoisted_13, _toDisplayString(_ctx.$t('accessControlPolicy.allowSomeWebsite')), 1))
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_14, [
      (getAllowDomains(_unref(selectedPlaybackChannel)).length)
        ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
            _createElementVNode("div", {
              class: _normalizeClass(["collapse-header", {'showing' : isAllowDomainCollapseShowing.value }]),
              onClick: onClickAllowDomainCollapse
            }, [
              _createElementVNode("span", _hoisted_16, [
                _cache[2] || (_cache[2] = _createElementVNode("i", { class: "fa fa-circle-check text-success" }, null, -1)),
                _createTextVNode(_toDisplayString(_ctx.$t('accessControlPolicy.allowWebsite')), 1)
              ]),
              _cache[3] || (_cache[3] = _createElementVNode("i", { class: "fas fa-chevron-down chevron-icon" }, null, -1))
            ], 2),
            (isAllowDomainCollapseShowing.value)
              ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(getAllowDomains(_unref(selectedPlaybackChannel)), (domain) => {
                    return (_openBlock(), _createElementBlock("div", {
                      class: "domain",
                      key: domain
                    }, [
                      _createElementVNode("span", null, [
                        _cache[4] || (_cache[4] = _createElementVNode("i", { class: "fas fa-earth-asia" }, null, -1)),
                        _createTextVNode(_toDisplayString(domain), 1)
                      ])
                    ]))
                  }), 128))
                ]))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true),
      (getDenyDomains(_unref(selectedPlaybackChannel)).length)
        ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
            _createElementVNode("div", {
              class: _normalizeClass(["collapse-header", {'showing' : isBlockDomainCollapseShowing.value }]),
              onClick: onClickBlockDomainCollapse
            }, [
              _createElementVNode("span", _hoisted_19, [
                _cache[5] || (_cache[5] = _createElementVNode("i", { class: "fa fa-ban text-danger" }, null, -1)),
                _createTextVNode(_toDisplayString(_ctx.$t('accessControlPolicy.blockWebsite')), 1)
              ]),
              _cache[6] || (_cache[6] = _createElementVNode("i", { class: "fas fa-chevron-down chevron-icon" }, null, -1))
            ], 2),
            (isBlockDomainCollapseShowing.value && getDenyDomains.length)
              ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(getDenyDomains(_unref(selectedPlaybackChannel)), (domain) => {
                    return (_openBlock(), _createElementBlock("div", {
                      class: "domain",
                      key: domain
                    }, [
                      _createElementVNode("span", null, [
                        _cache[7] || (_cache[7] = _createElementVNode("i", { class: "fas fa-earth-asia" }, null, -1)),
                        _createTextVNode(_toDisplayString(domain), 1)
                      ])
                    ]))
                  }), 128))
                ]))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}
}

})