/* eslint no-shadow: ["error", { "allow": ["state"] }] */
import { updateUserLanguage } from '@/modules/user/services';
import _ from 'lodash';
import { ActionContext } from 'vuex';
import { RootState } from '@/store/type';
import { User, UserAction, UserState } from '@/modules/user';
import posthog from 'posthog-js';

type UserActionContext = ActionContext<UserState, RootState>

const initialState = (): UserState => ({
  isLoading: false,
  currentUser: null,
  language: 'th',
  actions: {
    createProject: true,
  },
});

const state = initialState();

const getters = {
  isLoading: (state: UserState) => state.isLoading,
  currentUser: (state: UserState) => state.currentUser,
  currentUserLang: (state: UserState) => state.currentUser?.lang,
  isManagerOrAdmin: (state: UserState) => state.currentUser?.role === 'admin' || state.currentUser?.role === 'manager',
  actions: (state: UserState) => state.actions,
  language: (state: UserState) => state.language,
};

const mutations = {
  setIsCurrentUserLoading(state: UserState, isLoading: boolean) {
    state.isLoading = isLoading;
  },
  setCurrentUser(state: UserState, currentUser: User) {
    state.currentUser = _.cloneDeep(currentUser);
    posthog.identify(
      currentUser?.bytearkAccountUserId.toString(), // Replace 'distinct_id' with your user's unique identifier
      { name: currentUser?.name, email: currentUser?.email },
      {
        teamNameSpace: currentUser?.team?.namespace,
        userDisplayName: currentUser?.name,
      },
    );
    posthog.group('team', currentUser?.team?.namespace ?? '', {
      namespace: currentUser?.team?.namespace,
      name: currentUser?.team?.name,
      id: currentUser?.team?.id,
    });
  },
  setActions(state: UserState, actions: UserAction) {
    state.actions = _.cloneDeep(actions);
  },
  destroyCurrentUser(state: UserState) {
    state.currentUser = null;
    posthog.reset();
  },
  setLanguage(state: UserState, lang: string) {
    state.language = lang;
  },
};

const actions = {
  setCurrentUser({ commit }: UserActionContext, currentUser: User) {
    commit('setCurrentUser', currentUser);
    commit('setLanguage', currentUser.lang);
  },
  destroyCurrentUser({ commit }: UserActionContext) {
    commit('destroyCurrentUser');
  },
  async updateCurrentUserLanguage({ commit }: UserActionContext, lang: string) {
    commit('setLanguage', lang);
    const response = await updateUserLanguage({ lang });
    commit('setCurrentUser', response.data);
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
