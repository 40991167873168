<template>
  <Row
    :key="`${caption.languageCode}${caption.customName}${index}`"
    @click="onClickRow"
    verticalAlign="top"
    :cursorPointer="canUseSubtitleEditor"
    :class="{
      disabled: isSubtitleProcessing,
    }"
  >
    <Column minWidth="110px">
      <TextCell :primaryText="caption.customName" :sub-text="captionType" />
    </Column>
    <Column width="66px">
      <TextCell :text="caption.languageCode" />
    </Column>
    <Column width="150px">
      <button
        type="button"
        class="btn-status"
        @click="(event) => !readonly ? $emit('onOpenStatusModal',event): null"
        v-if="isSubtitleReady"
      >
        <div class="status-text">
          <i class="fas fa-file" v-if="caption?.publishStatus === 'draft'"></i>
          <i class="fas fa-globe-americas" v-else></i>
          {{
            caption?.publishStatus === 'draft'
              ? t('video.subtitle.status.draft')
              : t('video.subtitle.status.published')
          }}
        </div>
        <i class="fas fa-pen"></i>
      </button>
      <div v-else-if="isSubtitleProcessing">{{t('video.subtitle.status.processing')}}...</div>
      <div v-else-if="isSubtitleFailed" class="error">{{t('video.subtitle.status.processingFailed')}}</div>
    </Column>
    <Column width="100px">
      <TextCell
        v-if="caption.updatedAt"
        className="updated-at"
        :primaryText="transformDateToDisplay(caption.updatedAt)"
        :subText="formatLongTime(caption.updatedAt)"
      />
    </Column>
    <Column v-if="false" width="80px" horizontal-align="right">
      <TextCell
        v-if="badWordCount === 0"
        :text="isSubtitleReady ? '0' : '-'"
        class="text-align-end"
      />
      <div
        v-else-if="isSubtitleReady && hasBadWord"
        class="bad-word-count"
        @click="(event) => $emit('onOpenBadwordListModal', event)"
        :title="t('video.subtitle.table.badWords.altText')"
      >
        <i class="fas fa-exclamation-triangle warning-icon"></i>
        <b>{{ badWordCount }}</b>
      </div>
    </Column>
    <Column class="action-container" minWidth="70px" style="margin-right: 8px">
      <Tooltip v-if="!isSubtitleFailed">
        <i :class="['fas', 'fa-money-check-edit', 'open-se-button', { disabled: !canUseSubtitleEditor }]" />
        <template v-slot:tooltip-text>
          {{ canUseSubtitleEditor ? t('videoSubtitle.captionItem.editor') : t('videoSubtitle.editor.notAvailable') }}
        </template>
      </Tooltip>
      <Dropdown
        v-if="(isSubtitleReady && !readonly)"
        icon-class-name="fas fa-ellipsis-v"
        @click="onClickDropdown">
        <DropdownItem :onClick="() => $emit('onOpenEditSidebar', index)">
          <div class="icon-box">
            <i class="fas fa-pen"></i>
          </div>
          {{t('videoSubtitle.captionItem.dropdown.settings')}}
        </DropdownItem>
        <DropdownItem :onClick="() => $emit('downloadFile')">
          <div class="icon-box">
            <i class="fas fa-download"></i>
          </div>
          {{t('common:download')}}
        </DropdownItem>
        <DropdownItem v-if="isSubtitleReady" :onClick="() => $emit('onOpenTranslateCaptionModal')">
          <div class="icon-box">
            <i class="fas fa-language"></i>
          </div>
          {{t('video.subtitle.translate')}}
        </DropdownItem>
        <DropdownItem :onClick="() => $emit('onOpenDeleteCaptionModal')">
          <div class="icon-box">
            <i class="fas fa-trash-alt"></i>
          </div>
          {{t('common:button.delete')}}
        </DropdownItem>
      </Dropdown>
      <Button
        v-if="isSubtitleFailed"
        @click="$emit('onOpenDeleteCaptionModal')"
        buttonStyle="text-secondary"
        isSquare
      >
        <i class="fas fa-trash-alt"></i>
      </Button>
    </Column>
  </Row>
</template>

<script setup>
import {
  isVideoCompleted,
  isVideoPartiallyCompleted, isVideoUploadFailed,
} from '@/modules/videoDetail/utils/videoManagement';
import {
  computed,
} from 'vue';
import { useI18n } from 'vue-i18n';
import {
  formatLongTime,
  transformDateToDisplay,
} from '@/modules/shared/utils/dateFormatter';
import { serviceNameDict } from '@/modules/captionProfile/utils';
import Button from '@/modules/shared/components/atoms/button/Button.vue';
import Row from '@/modules/shared/components/molecules/row/Row.vue';
import Column from '@/modules/shared/components/molecules/column/Column.vue';
import TextCell from '@/modules/shared/components/molecules/textCell/TextCell.vue';
import Dropdown from '@/modules/shared/components/atoms/dropdown/Dropdown.vue';
import DropdownItem from '@/modules/shared/components/atoms/dropdownItem/DropdownItem.vue';
import Tooltip from '@/modules/shared/components/atoms/tooltip/Tooltip.vue';
import { useStore } from '../../../store/hooks';

const { t } = useI18n();
const store = useStore();

const props = defineProps({
  index: {
    type: Number,
    required: true,
  },
  readonly: {
    type: Boolean,
    default: false,
  },
});

defineEmits([
  'onOpenEditSidebar',
  'onOpenBadwordListModal',
  'onOpenTranslateCaptionModal',
  'onOpenDeleteCaptionModal',
  'onOpenStatusModal',
  'downloadFile',
]);

const caption = computed(() => store.state.captionList?.captionList[props.index]);
const video = computed(() => store.state.video?.currentVideo);
const processingStatus = computed(() => caption.value?.processingStatus);
// ready or has no processingStatus field at all (older versions)
const isSubtitleReady = computed(() => processingStatus.value === 'ready' || !processingStatus.value);
const isSubtitleProcessing = computed(() => processingStatus.value === 'processing');
const isSubtitleFailed = computed(() => processingStatus.value === 'error');
const canUseSubtitleEditor = computed(() => (
  (isVideoPartiallyCompleted(video.value) || isVideoCompleted(video.value))
  && !isVideoUploadFailed(video.value) && isSubtitleReady.value
));

const captionType = computed(() => {
  if (caption.value.captionProcessingType) {
    const { type, service } = caption.value.captionProcessingType;

    if (type === 'transcribe' || type === 'translate') {
      return serviceNameDict[type][service];
    }

    if (type === 'manual') {
      return 'Type Manually';
    }
  }
  return 'Manual Upload';
});

const badWordCount = computed(() => {
  if (caption.value?.captionProofreading) {
    return caption.value.captionProofreading.flaggedData?.length;
  }
  // in case some captions have no proofreading field
  return 0;
});
const hasBadWord = computed(() => badWordCount.value > 0);

const onClickDropdown = (event) => {
  event.stopPropagation();
};

function onClickRow() {
  if (!caption.value || !isSubtitleReady.value) {
    return;
  }

  if (!canUseSubtitleEditor.value) {
    // alert(t('videoSubtitle.editor.notAvailable'));
    return;
  }

  /**
   * Subtitle ประเภท Auto-generated จะต้อง Duplicate เสมอไม่ว่าจะเผยแพร่แล้วหรือไม่
   */
  if (caption.value.captionProcessingType.service === 'autosub' || caption.value.publishStatus === 'published') {
    store.commit('setCurrentDuplicateTarget', caption.value);
  } else if (isSubtitleReady.value && !props.readonly) {
    store.commit('setCurrentSubtitle', caption.value);
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/global-variables';
.bad-word-header {
  font-size: $font-level-8;
  font-weight: $font-weight-bold;
  /* padding: $spacing-8 0; */
  text-transform: capitalize;
  background: $grey-100;
  text-align: right;
  width: 100%;

  :deep(.fas) {
    margin-left: $spacing-4;
    color: $grey-400;
  }
}

:deep(.dropdown-item) {
  color: $grey-800;
  margin-right: 0px;
}

.icon-box {
  width: 16px;
  height: 16px;
  font-size: 12px;
  display: inline-block;
  text-align: right;
}
:deep(.btn-add-subtitle) {
  margin: auto;
  margin-top: $spacing-base * 1.5;
}
// :deep(.btn-clear-search) {
//   margin: auto;
//   margin-top: $spacing-base * 1.5;
// }
.date-text {
  white-space: nowrap;
}

.badwordHighlight {
  background-color: #fef9f1;
}

.processing {
  opacity: 0.3;
  cursor: not-allowed;
}

.btn-status {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;
  border: 1px solid #ebedef;
  border-radius: 3px;
  cursor: pointer;
  width: 100%;
  gap: 4px;
  padding: 8px;
  margin-right: 8px;

  .status-text {
    display: flex;
    gap: 4px;
    align-items: center;
    text-transform: capitalize;
    color: $grey-800;
    font-size: 14px;
  }

  .fa-pen {
    color: $grey-600;
  }
}

.error {
  color: $danger;
}

.bad-word-count {
  display: flex;
  justify-content: flex-end;
  width: fit-content;
  gap: 8px;
  align-items: center;
  border-bottom: 1px solid $warning;
}

.warning-icon {
  color: $warning;
  font-size: $font-level-8;
}

.action-container {
  justify-content: flex-end !important;

  :deep(.btn-outline-secondary) {
    position: relative;
    top: -6px;
  }

  .tooltip {
    transition: color 150ms ease;

    &:hover {
      color: $grey-800;
    }
  }
}

:deep(.updated-at) {
  .primary-text {
    font-weight: 400;
  }
}

.open-se-button {
  &.disabled {
    opacity: 0.5;
  }
}
</style>
