import type { RouteRecordRaw } from 'vue-router';
import { createBumperDisplayRoutes } from '@/modules/bumperDisplay';
import { createCaptionProfileRoutes } from '@/modules/captionProfile';
import { createCustomFieldRoutes } from '@/modules/customField/router';
import { createGeoblockRoutes } from '@/modules/geoblock';
import { createClearKeyRoutes } from '@/modules/clearKey';
import { createPlaybackChannelRoutes } from '@/modules/playbackChannel/router';
import { createCollectionTypeRoutes } from '@/modules/collectionType';
import { createPresetRoutes } from '@/modules/preset';
import { createTeamSettingRoutes } from '@/modules/teamSetting';
import { createVideoEmbedPlayerRoutes } from '@/modules/videoEmbedPlayer';
import { createCustomImageFieldRoutes } from '@/modules/customImageField';
import { createBadWordListRoutes } from '@/modules/badWordList';
import { createFormUploadRoutes } from '@/modules/formUpload';
import { createBumperRoutes } from '@/modules/bumper';
import { createMultiDrmRoutes } from '@/modules/multiDrm';
import { videoUpload } from '@/modules/videoUpload';
import store from '@/store';
import createVideoSourcesRoutes from '@/modules/videoSources/router';

export function createManagementRoutes(): RouteRecordRaw {
  return {
    path: 'manage',
    component: () => import(/* webpackChunkName: "managementChrome" */ '@/layouts/ManageChrome.vue'),
    redirect: { name: 'geoBlocking' },
    beforeEnter: (to, from, next) => {
      if (!store.hasModule('videoUpload')) {
        store.registerModule('videoUpload', videoUpload);
      }
      next();
    },
    children: [
      ...createGeoblockRoutes(),
      ...createClearKeyRoutes(),
      ...createPlaybackChannelRoutes(),
      ...createCollectionTypeRoutes(),
      ...createVideoEmbedPlayerRoutes(),
      ...createCustomImageFieldRoutes(),
      ...createCaptionProfileRoutes(),
      ...createBadWordListRoutes(),
      ...createPresetRoutes(),
      ...createCustomFieldRoutes(),
      ...createFormUploadRoutes(),
      ...createTeamSettingRoutes(),
      ...createBumperRoutes(),
      ...createBumperDisplayRoutes(),
      createVideoSourcesRoutes(),
      ...createMultiDrmRoutes(),
    ],
  };
}
