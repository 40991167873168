<template>
  <RadioButtonGroup
    :modelValue="selectedMethod"
    :defalutValue="selectedMethod"
    :options="recoverVideoMethodOptions"
    :disabled="disabled"
    @update:modelValue="onChangeChoice"
  >
    <template v-slot:option1Sub>
      <p class="description">
        {{t('project.deleteVideos.recover.restoreCurrent.description_1')}}
        <span class="highlight-name">{{projectName}}</span>
        {{t('project.deleteVideos.recover.restoreCurrent.description_2')}}
        <router-link
          :to="`/${teamName}/projects/deleted`"
          class="deleted-project"
        >
          {{t('project.deleteVideos.recover.restoreCurrent.description_3')}}
        </router-link>{{t('project.deleteVideos.recover.restoreCurrent.description_4')}}
      </p>
    </template>
    <template v-slot:option2Sub>
      <p class="description">{{t('project.deleteVideos.recover.restoreAnother.description')}}</p>
      <SelectOption
        class="select-project-dropdown"
        label="name"
        v-model="selectedProject"
        :placeholder="t('project.deleteVideos.recover.restoreAnother.placeholder')"
        :options="projectListDropdownOptions"
        :maxWidth="'initial'"
      />

    </template>
  </RadioButtonGroup>
</template>

<script setup lang="ts">
import { useStore } from '@/modules/projectVideoList/store/hooks';
import RadioButtonGroup from '@/modules/shared/components/molecules/radioButtonGroup/RadioButtonGroup.vue';
import SelectOption from '@/modules/shared/components/atoms/selectOption/SelectOption.vue';
import {
  computed,
  ref, toRefs, watch,
} from 'vue';
import { useI18n } from 'vue-i18n';
import { Project } from '@/modules/shared/types/project.type';
import { useRoute } from 'vue-router';

const { t } = useI18n();

interface RecoverVideoMethodSelectorProps {
  selectedMethod: string;
  onChangeSelectedMethod: (choice: string) => void;
  onSelectRestoreProject: (project: Project | null) => void;
  deletedProject: Project | null;
  disabled?: boolean;
}

const recoverVideoMethodOptions = [
  {
    label: t('project.deleteVideos.recover.restoreCurrent.title'),
    value: 'restoreToCurrent',
    subFieldName: 'option1Sub',
  },
  {
    label: t('project.deleteVideos.recover.restoreAnother.title'),
    value: 'restoreToAnother',
    subFieldName: 'option2Sub',
  },
];

const store = useStore();
const route = useRoute();

const props = withDefaults(defineProps<RecoverVideoMethodSelectorProps>(), {
  selectedMethod: 'restoreToCurrent',
  onChangeSelectedMethod: () => {},
  selectedProject: null,
  deletedProject: null,
  disabled: false,
});

const {
  selectedMethod,
  onChangeSelectedMethod,
  onSelectRestoreProject,
  deletedProject,
  disabled,
} = toRefs(props);

const selectedProject = ref<Project | null>(null);
const projectList = computed(() => store.state.projectList.projectList);
const projectName = computed(() => deletedProject.value?.name);
const teamName = computed(() => route.params.teamName);

const projectListDropdownOptions = computed(() => {
  if (!projectList.value) return [];
  return projectList.value;
});

const onChangeChoice = (choice: string) => {
  onChangeSelectedMethod.value(choice);
};

watch(selectedProject, (newSelectedProject) => {
  onSelectRestoreProject.value(newSelectedProject);
});

</script>

<style lang="scss" scoped>
@import '~@/assets/scss/global-variables.scss';
@import '~@/assets/scss/themes/components/form-group.scss';

.radio-container > div:not(:last-child) {
  margin-bottom: 16px;
}

.select-option-container {
  margin-top: 8px;
}
:deep(.sub-field-wrapper) {
  > div:not(:last-child) {
    margin-bottom: $spacing-base;
  }
}
.option-title {
  margin-bottom: $spacing-8;
  color: #6C757D;
}

.select-project-dropdown {
  margin-top: $spacing-8;
}

.description {
  color: $grey-600;
}

.highlight-name {
  font-weight: $font-weight-bold;
}

.deleted-project {
  color: $ci-primary;
}
</style>
