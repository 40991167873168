<template>
  <div
    class="alert-container"
    :class="{
      info: priority === 'info',
      warning: priority === 'warning',
      success: priority === 'success',
      danger: priority === 'danger',
    }"
  >
    <i
      v-if="priority === 'warning' || priority === 'danger'"
      class="fas fa-triangle-exclamation"
    ></i>
    <i v-if="priority === 'success'" class="fas fa-circle-check"></i>
    <i v-if="priority === 'info'" class="fas fa-circle-info"></i>
    <span class="alert-text" v-if="alertText" v-html="alertText" /> <span class="alert-description" v-if="description" v-html="' - ' + description" />
    <slot/>
  </div>
</template>

<script>
export default {
  props: {
    alertText: {
      type: String,
      default: '',
      required: true,
    },
    description: {
      type: String,
      default: '',
    },
    priority: {
      type: String,
      default: '',
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/global-variables.scss";

.alert-text {
  font-weight: bold;
}

.alert-container {
  padding: $spacing-12;
  margin-bottom: $spacing-base;
  border: 1px solid transparent;
  border-radius: $border-radius-5;

  font-size: $font-level-7;
  font-weight: $font-weight-base;
  color: $grey-800;
  display: flex;
  column-gap: 8.8px;

  i {
    margin-top: 4px;
  }

  &.info {
    background-color: #f3f7fb;
    border-color: $info;
    i {
      color: $info;
    }
  }

  &.success {
    background-color: #f2fdf7;
    border-color: $success;
    i {
      color: $success;
    }
  }

  &.danger {
    background-color: #fdf2f2;
    border-color: $danger;
    i {
      color: $danger;
    }
  }
  &.warning {
    background-color: #fef9f1;
    border-color: $warning;
    i {
      color: $warning;
    }
  }
}
</style>
