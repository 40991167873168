import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, createSlots as _createSlots, resolveComponent as _resolveComponent } from "vue"

const _hoisted_1 = { class: "domain-container" }
const _hoisted_2 = { class: "label" }
const _hoisted_3 = {
  key: 0,
  class: "domains"
}
const _hoisted_4 = { class: "information-wrapper" }
const _hoisted_5 = { class: "signed-url" }
const _hoisted_6 = { class: "value" }
const _hoisted_7 = { class: "access-control-policy" }
const _hoisted_8 = { class: "value" }
const _hoisted_9 = { key: 0 }
const _hoisted_10 = {
  key: 0,
  class: "playback-channel-link"
}

import type { AccessControlPolicy } from '@/modules/playbackChannel/types/playbackChannel.type';
import _ from 'lodash';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';
import { useStore } from '@/modules/projectDefaultUploadSetting/store/hooks';
import type { PlaybackChannel } from '@/modules/playbackChannel';
import SecureAndProtectContentModal from '@/modules/projectCreate/components/organisms/secureAndProtectContentModal/SecureAndProtectContentModal.vue';
import { AccessControlPolicyModal } from '@/modules/playbackChannel';
import Section from '@/modules/shared/components/organisms/section/Section.vue';
import ItemInformationCard from '@/modules/shared/components/atoms/itemInformationCard/ItemInformationCard.vue';
import Button from '@/modules/shared/components/atoms/button/Button.vue';
import Badge from '@/modules/shared/components/atoms/badge/Badge.vue';
import AddProjectPlaybackChannelModal from '../addProjectPlaybackChannelModal/AddProjectPlaybackChannelModal.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'ProjectManageUploadSettingPlaybackChannelSection',
  setup(__props) {

const { t } = useI18n();
const store = useStore();
const route = useRoute();

const isInfoModalShowing = ref(false);
const selectedAccessControlPolicy = ref<AccessControlPolicy | null>(null);
const isAccessControlPolicyModalShowing = ref(false);
const isAddProjectPlaybackChannelModalShowing = ref(false);

const teamName = computed(() => route.params.teamName);
const currentProject = computed(() => store.state.project.currentProject);
const playbackChannels = computed(() => store.state.project.currentProject?.playbackChannels);
const primaryPlaybackChannelId = computed(() => store.state.project.currentProject?.primaryPlaybackChannelId);
const canUpdateProject = computed(() => currentProject.value?.actions?.updateProject);

function getSignedUrlValue(playbackChannel: PlaybackChannel) {
  return _.get(playbackChannel, 'signedUrl.required', false);
}

function isAllowAllDomains(playbackChannel: PlaybackChannel) {
  const allowDomains = _.get(playbackChannel, 'accessControlPolicy.allowDomains', []);
  const denyDomains = _.get(playbackChannel, 'accessControlPolicy.denyDomains', []);
  return !allowDomains.length && !denyDomains.length;
}

function getPlaybackDomains(playbackChannel: PlaybackChannel) {
  return [
    playbackChannel.defaultPlaybackDomain,
    ..._.get(playbackChannel, 'playbackDomains', []),
  ];
}

function onToggleInfoModal() {
  isInfoModalShowing.value = !isInfoModalShowing.value;
}

function onClickViewAccessControlPolicy(playbackChannel: PlaybackChannel) {
  selectedAccessControlPolicy.value = playbackChannel.accessControlPolicy;
  isAccessControlPolicyModalShowing.value = true;
}

function onCloseAccessControlPolicyModal() {
  isAccessControlPolicyModalShowing.value = false;
}

function onClickSetPrimaryPlaybackChannel(id: string) {
  if (_.isNil(currentProject.value)) {
    return;
  }

  store.dispatch('setProjectPrimaryPlaybackChannel', {
    projectKey: currentProject.value.key,
    primaryPlaybackChannelId: id,
  });
}

function onToggleAddProjectPlaybackChannelModal() {
  isAddProjectPlaybackChannelModalShowing.value = !isAddProjectPlaybackChannelModalShowing.value;
}

function onDeletePlaybackChannel(playbackChannelId: string) {
  if (_.isNil(currentProject.value)) {
    return;
  }

  store.dispatch('deleteProjectPlaybackChannel', {
    projectKey: currentProject.value.key,
    playbackChannelIds: [playbackChannelId],
  });
}


return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", null, [
    (isAddProjectPlaybackChannelModalShowing.value)
      ? (_openBlock(), _createBlock(AddProjectPlaybackChannelModal, {
          key: 0,
          onClose: onToggleAddProjectPlaybackChannelModal
        }))
      : _createCommentVNode("", true),
    (isAccessControlPolicyModalShowing.value)
      ? (_openBlock(), _createBlock(_unref(AccessControlPolicyModal), {
          key: 1,
          data: selectedAccessControlPolicy.value,
          onClose: onCloseAccessControlPolicyModal
        }, null, 8, ["data"]))
      : _createCommentVNode("", true),
    (isInfoModalShowing.value)
      ? (_openBlock(), _createBlock(SecureAndProtectContentModal, {
          key: 2,
          highlightRowNumber: -1,
          onClose: onToggleInfoModal
        }))
      : _createCommentVNode("", true),
    _createVNode(Section, {
      title: _unref(t)('project.manage.setting.playbackChannel.title')
    }, _createSlots({
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(playbackChannels.value, (playbackChannel) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "playback-channel",
            key: playbackChannel.id
          }, [
            _createVNode(ItemInformationCard, {
              title: playbackChannel.name
            }, _createSlots({
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_1, [
                  _createElementVNode("span", _hoisted_2, _toDisplayString(_unref(t)('hostings.domain')) + " (" + _toDisplayString(getPlaybackDomains(playbackChannel).length) + ") ", 1),
                  (getPlaybackDomains(playbackChannel))
                    ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(getPlaybackDomains(playbackChannel), (domain, index) => {
                          return (_openBlock(), _createElementBlock("div", {
                            class: "value",
                            key: index
                          }, _toDisplayString(domain), 1))
                        }), 128))
                      ]))
                    : _createCommentVNode("", true)
                ]),
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("div", _hoisted_5, [
                    _createElementVNode("span", { class: "label" }, [
                      _cache[0] || (_cache[0] = _createTextVNode(" Signed URL ")),
                      _createElementVNode("i", {
                        class: "fa fa-circle-info ml-2",
                        onClick: onToggleInfoModal
                      })
                    ]),
                    _createElementVNode("div", _hoisted_6, _toDisplayString(getSignedUrlValue(playbackChannel)
                    ? _unref(t)('project.setup.playbackChannel.signedURL.isEnabled')
                    : _unref(t)('project.setup.playbackChannel.signedURL.isDisabled')), 1)
                  ]),
                  _createElementVNode("div", _hoisted_7, [
                    _createElementVNode("span", { class: "label" }, [
                      _cache[1] || (_cache[1] = _createTextVNode(" Allowed / Blocked Website ")),
                      _createElementVNode("i", {
                        class: "fa fa-circle-info ml-2",
                        onClick: onToggleInfoModal
                      })
                    ]),
                    _createElementVNode("div", _hoisted_8, [
                      (isAllowAllDomains(playbackChannel))
                        ? (_openBlock(), _createElementBlock("span", _hoisted_9, _toDisplayString(_unref(t)('accessControlPolicy.allowAllWebsite')), 1))
                        : (_openBlock(), _createBlock(Button, {
                            key: 1,
                            buttonStyle: "link-primary",
                            type: "button",
                            onClick: ($event: any) => (onClickViewAccessControlPolicy(playbackChannel))
                          }, {
                            content: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_unref(t)('accessControlPolicy.allowSomeWebsite')), 1)
                            ]),
                            _: 2
                          }, 1032, ["onClick"]))
                    ])
                  ])
                ])
              ]),
              _: 2
            }, [
              (playbackChannel.id === primaryPlaybackChannelId.value)
                ? {
                    name: "badge",
                    fn: _withCtx(() => [
                      _createVNode(Badge, {
                        title: "Primary",
                        theme: "dark",
                        badgeStyle: "info"
                      })
                    ]),
                    key: "0"
                  }
                : undefined,
              (canUpdateProject.value)
                ? {
                    name: "card-actions",
                    fn: _withCtx(() => [
                      (playbackChannel.id !== primaryPlaybackChannelId.value)
                        ? (_openBlock(), _createBlock(Button, {
                            key: 0,
                            buttonStyle: "text-secondary",
                            buttonClasses: "btn-delete",
                            type: "button",
                            onClick: ($event: any) => (onDeletePlaybackChannel(playbackChannel.id))
                          }, {
                            content: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_unref(t)('common:button.delete')), 1)
                            ]),
                            _: 2
                          }, 1032, ["onClick"]))
                        : _createCommentVNode("", true),
                      _createVNode(Button, {
                        buttonStyle: "secondary",
                        buttonClasses: "setting-primary-button",
                        type: "button",
                        onClick: ($event: any) => (onClickSetPrimaryPlaybackChannel(playbackChannel.id)),
                        disabled: playbackChannel.id === primaryPlaybackChannelId.value
                      }, {
                        content: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_unref(t)('project.manage.setting.playbackChannel.setToPrimary')), 1)
                        ]),
                        _: 2
                      }, 1032, ["onClick", "disabled"])
                    ]),
                    key: "1"
                  }
                : undefined
            ]), 1032, ["title"])
          ]))
        }), 128)),
        (canUpdateProject.value)
          ? (_openBlock(), _createBlock(Button, {
              key: 0,
              buttonClasses: "btn-add-playback-channel",
              buttonStyle: "text-primary",
              isFullWidth: "",
              type: "button",
              onClick: onToggleAddProjectPlaybackChannelModal
            }, {
              "icon-prefix": _withCtx(() => _cache[2] || (_cache[2] = [
                _createElementVNode("i", { class: "fas fa-plus mr-1" }, null, -1)
              ])),
              content: _withCtx(() => [
                _createTextVNode(_toDisplayString(_unref(t)('project.manage.setting.playbackChannel.add')), 1)
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ]),
      _: 2
    }, [
      (_ctx.$attrs.isManager)
        ? {
            name: "section-footer-with-border",
            fn: _withCtx(() => [
              (playbackChannels.value)
                ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                    _createVNode(_component_router_link, {
                      class: "link",
                      to: `/${teamName.value}/manage/playback-channels`
                    }, {
                      default: _withCtx(() => [
                        _cache[3] || (_cache[3] = _createElementVNode("i", { class: "fas fa-gear" }, null, -1)),
                        _createTextVNode(" " + _toDisplayString(_unref(t)('project.manage.playback.goToSettings')), 1)
                      ]),
                      _: 1
                    }, 8, ["to"])
                  ]))
                : _createCommentVNode("", true)
            ]),
            key: "0"
          }
        : undefined
    ]), 1032, ["title"])
  ]))
}
}

})