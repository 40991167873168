import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, withKeys as _withKeys, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "loading-container"
}
const _hoisted_2 = {
  key: 2,
  class: "options-container"
}
const _hoisted_3 = { class: "geoblock-name" }
const _hoisted_4 = {
  key: 0,
  class: "geoblock-country-wrapper"
}
const _hoisted_5 = {
  key: 0,
  class: "allow-country-container country-list"
}
const _hoisted_6 = {
  key: 0,
  class: "label"
}
const _hoisted_7 = {
  key: 1,
  class: "label"
}
const _hoisted_8 = {
  key: 2,
  class: "badge-container"
}
const _hoisted_9 = {
  key: 1,
  class: "deny-country-container country-list"
}
const _hoisted_10 = { class: "label" }
const _hoisted_11 = { class: "badge-container" }
const _hoisted_12 = {
  key: 1,
  class: "geoblock-country-wrapper"
}
const _hoisted_13 = { class: "message-container" }
const _hoisted_14 = { key: 0 }
const _hoisted_15 = {
  key: 1,
  class: "text-danger"
}
const _hoisted_16 = {
  key: 2,
  class: "text-danger"
}
const _hoisted_17 = {
  key: 3,
  class: "text-danger"
}

import _ from 'lodash';
import {
  computed,
  onMounted,
  ref,
  toRefs,
} from 'vue';
import { useStore } from '@/store';
import Modal from '@/modules/shared/components/organisms/modal/Modal.vue';
import RadioButtonChoice from '@/modules/shared/components/atoms/radioButtonChoice/RadioButtonChoice.vue';
import Input from '@/modules/shared/components/atoms/input/Input.vue';
import Badge from '@/modules/shared/components/atoms/badge/Badge.vue';
import MessageBox from '@/modules/shared/components/molecules/messageBox/MessageBox.vue';
import { GeoblockRule } from '@/modules/geoblock';
import { useI18n } from 'vue-i18n';

interface ChangeGeoblockModalProps {
  geoblockRule: GeoblockRule | null | undefined;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'ChangeGeoblockModal',
  props: {
    geoblockRule: {}
  },
  emits: ["close", "submit"],
  setup(__props: any, { emit: __emit }) {

/* eslint-disable no-shadow */
const props = __props;
const { geoblockRule } = toRefs(props);

const emit = __emit;

const { t } = useI18n();
const store = useStore();

// data
const searchKeyword = ref<string>('');
const selectedGeoblockRule = ref<GeoblockRule | null | undefined>(_.cloneDeep(geoblockRule.value));

// store
const geoblockRules = computed(() => store.state.geoblockRuleList.geoblockRules);
const loadGeoblockRulesState = computed(() => store.state.geoblockRuleList.loadGeoblockRulesState);
const saveProjectState = computed(() => store.state.project.saveProjectState);
const saveErrorCode = computed(() => _.get(saveProjectState.value, 'error.statusCode', 0));
const isValidToSaving = computed(() => !(saveErrorCode.value || saveProjectState.value?.status === 'saving') && geoblockRules.value?.length);

// methods
const onSearch = () => {
  store.dispatch('loadGeoblockRules', { limit: 100, q: searchKeyword.value });
};
const isCurrentGeoblockRule = (geoblockRule: GeoblockRule): boolean => {
  const curentGeoblockId = _.get(selectedGeoblockRule.value, 'id', null);
  return geoblockRule.id === curentGeoblockId;
};
const getAllowCountryCodes = (geoblockRule: GeoblockRule) => _.get(geoblockRule, 'allowCountryCodes', []);
const getDenyCountryCodes = (geoblockRule: GeoblockRule) => _.get(geoblockRule, 'denyCountryCodes', []);
const isAllowAllCountries = (geoblockRule: GeoblockRule) => !getAllowCountryCodes(geoblockRule).length && !getDenyCountryCodes(geoblockRule).length;
const onSelectedGeoblock = (geoblockRuleId: string) => {
  selectedGeoblockRule.value = _.find(geoblockRules.value, { id: geoblockRuleId });
};
const onSubmitGeoblock = () => {
  emit('submit', selectedGeoblockRule.value);
};
// This method will be remove after we refactor allowCountryCodes conditions
const isDisallowAllCountries = (countryCodes: string[]) => {
  if (countryCodes && countryCodes[0] === 'XX') {
    return true;
  }
  return false;
};

onMounted(() => {
  store.dispatch('loadGeoblockRules', { limit: '100' });
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(Modal, {
    size: "medium",
    title: _unref(t)('project.defaultSettings.geoblock.change'),
    primaryButtonText: _unref(t)('common:button.save'),
    secondaryButtonText: _unref(t)('common:button.cancel'),
    isDisabledPrimaryButton: !isValidToSaving.value,
    onClickPrimaryButton: onSubmitGeoblock,
    onClickSecondaryButton: () => _ctx.$emit('close'),
    onModalClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('close')))
  }, {
    body: _withCtx(() => [
      _createVNode(Input, {
        type: "text",
        onKeydown: _withKeys(onSearch, ["enter"]),
        class: "form-control search-input",
        placeholder: _unref(t)('project.manage.setting.geoblock.search'),
        modelValue: searchKeyword.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((searchKeyword).value = $event)),
        onSearch: onSearch,
        isSearchInput: ""
      }, null, 8, ["placeholder", "modelValue"]),
      (loadGeoblockRulesState.value.status === 'loading')
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, _cache[2] || (_cache[2] = [
            _createElementVNode("i", { class: "fas fa-circle-notch fa-spin fa-4x" }, null, -1)
          ])))
        : _createCommentVNode("", true),
      (searchKeyword.value && !geoblockRules.value?.length && loadGeoblockRulesState.value.status ==='success')
        ? (_openBlock(), _createBlock(MessageBox, {
            key: 1,
            title: _unref(t)('geoBlock.notFound')
          }, null, 8, ["title"]))
        : _createCommentVNode("", true),
      (loadGeoblockRulesState.value.status === 'success' && geoblockRules.value?.length)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(geoblockRules.value, (geoblock, index) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "form-radio",
                key: index
              }, [
                _createVNode(RadioButtonChoice, {
                  modelValue: isCurrentGeoblockRule(geoblock),
                  data: geoblock.id,
                  "onUpdate:data": onSelectedGeoblock
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_3, [
                      _createTextVNode(_toDisplayString(geoblock.name) + " ", 1),
                      (isAllowAllCountries(geoblock) && geoblock.isGlobalDefault)
                        ? (_openBlock(), _createBlock(Badge, {
                            key: 0,
                            title: _unref(t)('glossary:default'),
                            size: "small"
                          }, null, 8, ["title"]))
                        : _createCommentVNode("", true)
                    ]),
                    (!isAllowAllCountries(geoblock))
                      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                          (getAllowCountryCodes(geoblock).length)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                                (!isDisallowAllCountries(geoblock.allowCountryCodes))
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_6, "Allowed (" + _toDisplayString(getAllowCountryCodes(geoblock).length) + ")", 1))
                                  : (_openBlock(), _createElementBlock("div", _hoisted_7, "All countries are blocked")),
                                (!isDisallowAllCountries(geoblock.allowCountryCodes))
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(getAllowCountryCodes(geoblock), (country, index) => {
                                        return (_openBlock(), _createBlock(Badge, {
                                          key: index,
                                          title: country
                                        }, null, 8, ["title"]))
                                      }), 128))
                                    ]))
                                  : _createCommentVNode("", true)
                              ]))
                            : _createCommentVNode("", true),
                          (getDenyCountryCodes(geoblock).length)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                                _createElementVNode("div", _hoisted_10, "Blocked (" + _toDisplayString(getDenyCountryCodes(geoblock).length) + ")", 1),
                                _createElementVNode("div", _hoisted_11, [
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(getDenyCountryCodes(geoblock), (country, index) => {
                                    return (_openBlock(), _createBlock(Badge, {
                                      key: index,
                                      title: country
                                    }, null, 8, ["title"]))
                                  }), 128))
                                ])
                              ]))
                            : _createCommentVNode("", true)
                        ]))
                      : (_openBlock(), _createElementBlock("div", _hoisted_12, _cache[3] || (_cache[3] = [
                          _createElementVNode("div", { class: "label" }, "All countries are allowed", -1)
                        ])))
                  ]),
                  _: 2
                }, 1032, ["modelValue", "data"])
              ]))
            }), 128))
          ]))
        : _createCommentVNode("", true)
    ]),
    footer: _withCtx(() => [
      _createElementVNode("div", _hoisted_13, [
        (saveProjectState.value.status === 'saving')
          ? (_openBlock(), _createElementBlock("span", _hoisted_14, [
              _cache[4] || (_cache[4] = _createElementVNode("i", { class: "fas fa-spinner fa-spin" }, null, -1)),
              _createTextVNode(_toDisplayString(_unref(t)('common:button.saving')), 1)
            ]))
          : _createCommentVNode("", true),
        (saveProjectState.value.status === 'error' && saveErrorCode.value === 404)
          ? (_openBlock(), _createElementBlock("span", _hoisted_15, [
              _cache[5] || (_cache[5] = _createElementVNode("i", { class: "fas fa-xmark" }, null, -1)),
              _createTextVNode(_toDisplayString(_unref(t)('response:error404.title', { item: 'project'})), 1)
            ]))
          : _createCommentVNode("", true),
        (saveProjectState.value.status === 'error' && saveErrorCode.value === 422)
          ? (_openBlock(), _createElementBlock("span", _hoisted_16, [
              _cache[6] || (_cache[6] = _createElementVNode("i", { class: "fas fa-xmark" }, null, -1)),
              _createTextVNode(_toDisplayString(_unref(t)('response:error422.title')), 1)
            ]))
          : _createCommentVNode("", true),
        (saveProjectState.value.status === 'error' && saveErrorCode.value === 500)
          ? (_openBlock(), _createElementBlock("span", _hoisted_17, [
              _cache[7] || (_cache[7] = _createElementVNode("i", { class: "fas fa-xmark" }, null, -1)),
              _createTextVNode(_toDisplayString(_unref(t)('response:error500.title')), 1)
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }, 8, ["title", "primaryButtonText", "secondaryButtonText", "isDisabledPrimaryButton", "onClickSecondaryButton"]))
}
}

})