import qs from 'qs';
import _ from 'lodash';
import { axiosAPI } from '@/plugins/axios';
import { AddVideoSourcePayload, EditVideoSourcePayload, VideoSource } from '@/modules/videoSources/types/VideoSources.type';

export const loadListS3Connections = (name?: string) => {
  const query = qs.stringify(name ? { q: name } : null, { addQueryPrefix: false, skipNulls: true });
  return axiosAPI.get(`/external-storage-for-import-videos?${query}`);
};
export const loadListS3ConnectionById = (storageId: string) => axiosAPI.get<VideoSource>(`/external-storage-for-import-videos/${storageId}`);
export const createS3Connection = (payload: AddVideoSourcePayload) => axiosAPI.post('/external-storage-for-import-videos', payload);
export const editS3Connection = (storageId: string, payload: EditVideoSourcePayload) => axiosAPI.put(`/external-storage-for-import-videos/${storageId}`, _.omit(payload, 'id'));
export const deleteS3Connection = (storageId: string) => axiosAPI.delete(`/external-storage-for-import-videos/${storageId}`);
