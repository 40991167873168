import store from '@/store';
import type { RouteRecordRaw } from 'vue-router';
import geoblockRuleList from '../store/geoblockRuleList';

export default function createGeoblockRoutes(): RouteRecordRaw[] {
  return [{
    path: 'geo-blocking',
    component: () => import(/* webpackChunkName: "manageGeoblockRulesChrome" */ '../layouts/ManageGeoblockRuleChrome.vue'),
    meta: { teamManagement: true },
    beforeEnter: (to, from, next) => {
      if (!store.hasModule('geoblockRuleList')) {
        store.registerModule('geoblockRuleList', geoblockRuleList);
      }
      next();
    },
    children: [
      {
        path: '',
        name: 'geoBlocking',
        component: () => import(/* webpackChunkName: "manageGeoblockRulesList" */ '../pages/ManageGeoblock.vue'),
      },
      {
        path: 'create',
        name: 'createGeoblockRule',
        components: {
          default: () => import(/* webpackChunkName: "manageGeoblockRuleList" */ '../pages/ManageGeoblock.vue'),
          createGeoblockRule: () => import(/* webpackChunkName: "createGeoblockRule" */ '../components/organisms/createGeoblockRuleModal/CreateGeoblockRuleModal.vue'),
        },
      },
      {
        path: ':geoblockRuleId',
        name: 'editGeoblockRule',
        components: {
          default: () => import(/* webpackChunkName: "manageGeoblockRuleList" */ '../pages/ManageGeoblock.vue'),
          editGeoblockRule: () => import(/* webpackChunkName: "editGeoblockRule" */ '../components/organisms/editGeoblockRuleModal/EditGeoblockRuleModal.vue'),
        },
      },
    ],
  }];
}
