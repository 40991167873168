<template>
  <div
    class="column"
    :data-test="dataTest"
    :style="[{ width: width, minWidth: minWidth || width }, style]"
    :class="[
      {
        'full-width' : !width,
        'action-column' : isActionColumn,
        'primary-column': isPrimaryColumn
      }, `${horizontalAlignClassName}`,
      `vertical-align-${verticalAlign}`,
    ]">
    <slot></slot>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';

const props = defineProps({
  // 100px, 100%
  width: {
    type: String,
  },
  minWidth: {
    type: String,
  },
  isActionColumn: {
    type: Boolean,
    default: false,
  },
  isPrimaryColumn: {
    type: Boolean,
    default: false,
  },
  horizontalAlign: {
    type: String,
    default: 'left',
  },
  verticalAlign: {
    type: String,
    default: 'top',
  },
  dataTest: {
    type: String,
    required: false,
  },
  style: {
    type: String,
    required: false,
  },
});

const horizontalAlignClassName = computed(() => {
  if (props.isActionColumn) {
    return 'horizontal-align-right';
  }
  return `horizontal-align-${props.horizontalAlign}`;
});
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/global-variables.scss';

.column {
  overflow: hidden;
  display: flex;
  height: fit-content;
  min-height: 36px;
  padding-top: $spacing-8;
  margin: $spacing-8 0 $spacing-12 $spacing-base;
  white-space: normal;

  > :deep(*) {
    white-space: normal;
    max-width: 100%;
  }

  &.action-column {
    padding-top: 0;
    align-items: flex-end;
    justify-content: flex-end;
    /* :deep(button) {
      min-height: 36px;
    } */
    padding-right: $spacing-12;
  }

  > :not(:last-child) {
    margin-right: $spacing-12 !important;
  }

  &.primary-column {
    flex-shrink: 0;
  }
  &.vertical-align-top {
    align-items: flex-start;
  }
  &.vertical-align-center {
    align-items: center;
  }
  &.vertical-align-bottom {
    align-items: flex-end;
  }
  &.horizontal-align-left {
    justify-content: flex-start;
  }
  &.horizontal-align-center {
    justify-content: center;
  }
  &.horizontal-align-right {
    justify-content: flex-end;
  }
}

.full-width {
  flex: 1;
}

</style>
