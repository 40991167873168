<template>
  <div class="geoblock-rule">
    <slot name="geoblock-title"></slot>
    <h5 class="title" v-if="!slot['geoblock-title']">{{geoblockRule?.name ?? ''}}</h5>
    <div class="rules" v-if="!isAllowAllCountries()">
      <div class="rule-item" v-if="!isAllowCountryCodesEmpty">
        <div class="rule-item-content">
          <!-- We will remove all isDisallowAllCountries method after we refactor allowCountryCodes conditions -->
          <div class="key" v-if="!isDisallowAllCountries(allowCountryCodes)" data-test="allow-some-label">
            Allowed ({{allowCountryCodes.length}})
          </div>
          <div class="key" v-else data-test="deny-all-label">
            All countries are blocked
          </div>
          <CountryBadge v-if="!isDisallowAllCountries(allowCountryCodes)" :countries="geoblockRuleAllowCountry" />
        </div>
      </div>
      <div class="rule-item" v-if="!isDenyCountryCodesEmpty">
        <div class="rule-item-content">
          <div class="key" data-test="deny-some-label">
            Blocked ({{denyCountryCodes.length}})
          </div>
          <CountryBadge v-if="!isDisallowAllCountries(allowCountryCodes)" :countries="geoblockRuleDenyCountry" />
        </div>
      </div>
    </div>
    <div class="rules" v-if="isAllowAllCountries()" data-test="allow-all-label">
      <div class="rule-item">{{t('geoBlock.allowAll.subTitle')}}</div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import _ from 'lodash';
import {
  computed, onMounted, toRefs, VNode,
} from 'vue';
import { useI18n } from 'vue-i18n';
import CountryBadge from '@/modules/shared/components/molecules/countryBadges/CountryBadges.vue';
import type { Nullable } from '@/types/index.type';
import type { GeoblockRule, GeoblockRuleInformation } from '../../../types/Geoblock.type';
import { useStore } from '../../../store/hooks';

interface GeoblockRuleInformationProps {
  geoblockRule?: Nullable<GeoblockRule | GeoblockRuleInformation>;
}

const props = defineProps<GeoblockRuleInformationProps>();
const { geoblockRule } = toRefs(props);

const slot = defineSlots<{'geoblock-title'?:() => VNode}>();

const { t } = useI18n();
const store = useStore();

const countryList = computed(() => store.state.geoblockRuleList.countryList);
const allowCountryCodes = computed(() => _.get(geoblockRule.value, 'allowCountryCodes', []));
const denyCountryCodes = computed(() => _.get(geoblockRule.value, 'denyCountryCodes', []));
const isAllowCountryCodesEmpty = computed(() => _.isEmpty(allowCountryCodes.value));
const isDenyCountryCodesEmpty = computed(() => _.isEmpty(denyCountryCodes.value));

function getCountryFullName(countryCode: string) {
  const country = _.find(countryList.value, { countryCode });
  return _.get(country, 'countryNameEN', '');
}

const geoblockRuleAllowCountry = computed(() => _.map(allowCountryCodes.value, getCountryFullName));
const geoblockRuleDenyCountry = computed(() => _.map(denyCountryCodes.value, getCountryFullName));

function isAllowAllCountries() {
  if (isAllowCountryCodesEmpty.value && isDenyCountryCodesEmpty.value) {
    return true;
  }
  return false;
}

// This method will be remove after we refactor allowCountryCodes conditions
function isDisallowAllCountries(value: string[]) {
  if (value && value[0] === 'XX') {
    return true;
  }
  return false;
}

onMounted(() => {
  if (!countryList.value?.length) {
    store.dispatch('loadCountryList');
  }
});
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/global-variables.scss';

.geoblock-rule {
  color: $grey-600;
  .title {
    margin-bottom: $spacing-base * 0.25;
    font-size: $font-level-5;
    font-weight: $font-weight-base;
    color: $grey-800;
    text-transform: capitalize;
  }
}

.rules {
  margin-top: $spacing-base * 0.75;
}

.rule-item {
  display: flex;
  margin-top: $spacing-base * 0.5;
  margin-bottom: $spacing-base;

  .rule-item-content {
    display: flex;
    flex-direction: column;
    .key {
      margin-right: $spacing-base * 0.5;
      margin-bottom: $spacing-base * 0.5;
      // text-transform: uppercase;
      //font-weight: $font-weight-bold;
    }
    .value {
      color: $bg-navy;
    }
  }

  &:last-child {
    margin-bottom: 0;
  }
}
</style>
