import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "label" }
const _hoisted_2 = { class: "label" }
const _hoisted_3 = ["innerHTML"]

import {
  computed, ref, toRefs, watch,
} from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from '@/modules/projectDetails/store/hooks';
import SelectOption from '@/modules/shared/components/atoms/selectOption/SelectOption.vue';
import RadioButtonGroup from '@/modules/shared/components/molecules/radioButtonGroup/RadioButtonGroup.vue';
import type { Project } from '@/modules/shared/types/project.type';
import type { ActionWithVideos, DeleteProjectData } from '../../../types';

interface ComponentProps {
  modelValue: DeleteProjectData;
}

interface Option {
  label: string;
  value: ActionWithVideos;
  subFieldName: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'ChooseWhatToDoWithVideo',
  props: {
    modelValue: {}
  },
  emits: ["update:modelValue"],
  setup(__props: any, { emit: __emit }) {

const emit = __emit;

const { t } = useI18n();
const store = useStore();

const props = __props;
const { modelValue } = toRefs(props);

const whatToDoWithVideosOptions = computed<Option[]>(() => [
  {
    label: t('project.deleteProject.step1.option1.title'),
    value: 'moveVideos',
    subFieldName: 'move-videos-sub-components',
  },
  {
    label: t('project.deleteProject.step1.option2.title'),
    value: 'deleteVideos',
    subFieldName: 'delete-videos-sub-components',
  },
]);

const selectedOptionValue = ref<ActionWithVideos>(modelValue.value.actionWithVideos);
const selectedProject = ref<Project | null>(modelValue.value.destinationProject);

const projectKey = computed(() => store.state.project.currentProject?.key ?? '');
const projectList = computed(() => store.state.projectList.projectList ?? []);
const options = computed(() => projectList.value?.filter((prj) => prj.key !== projectKey.value));
const loadProjectListState = computed(() => store.state.projectList.loadProjectListState);

watch(selectedOptionValue, (newValue) => emit('update:modelValue', {
  actionWithVideos: newValue,
  destinationProject: selectedProject.value,
}));

watch(selectedProject, (newValue) => emit('update:modelValue', {
  actionWithVideos: selectedOptionValue.value,
  destinationProject: newValue,
}));

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("p", _hoisted_1, _toDisplayString(_unref(t)('project.deleteProject.step1.label')), 1),
    _createVNode(RadioButtonGroup, {
      modelValue: selectedOptionValue.value,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((selectedOptionValue).value = $event)),
      options: whatToDoWithVideosOptions.value
    }, {
      "move-videos-sub-components": _withCtx(() => [
        _createElementVNode("p", _hoisted_2, _toDisplayString(_unref(t)('project.moveVideos.modal.moveTo')), 1),
        _createVNode(SelectOption, {
          disabled: loadProjectListState.value.status === 'loading',
          placeholder: _unref(t)('project.moveVideos.modal.placeholder'),
          options: options.value,
          modelValue: selectedProject.value,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((selectedProject).value = $event)),
          searchable: ""
        }, null, 8, ["disabled", "placeholder", "options", "modelValue"])
      ]),
      "delete-videos-sub-components": _withCtx(() => [
        _createElementVNode("p", {
          class: "description",
          innerHTML: _unref(t)('project.deleteProject.step1.option2.description')
        }, null, 8, _hoisted_3)
      ]),
      _: 1
    }, 8, ["modelValue", "options"])
  ]))
}
}

})