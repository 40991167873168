import type { ActionContext } from 'vuex';
import { formatErrorObject } from '@/modules/shared/utils/errorFormatter';
import { setStateLoadingStatusByType } from '@/modules/shared/utils/stateManagement';
import type { Collection } from '@/modules/collection/types';
import type { LoadingState } from '@/types/State.type';
import type { RootState } from '@/store/type';
import type { Filter } from '@/types/Axios.type';
import { getCollections } from '../services';
import type { CollectionListState } from '../types';

type CollectionListActionContext = ActionContext<CollectionListState, RootState>;

const initialState = (): CollectionListState => ({
  collectionList: null,
  collectionListTotal: 0,
  collectionListLastPage: 1,
  loadCollectionListState: setStateLoadingStatusByType(),
});

const state = initialState();

const mutations = {
  setCollectionList(state: CollectionListState, collectionList: Collection[]) {
    state.collectionList = collectionList;
  },
  setCollectionListTotal(state: CollectionListState, total: number) {
    state.collectionListTotal = total;
  },
  setLoadCollectionListState(state: CollectionListState, loadingState: LoadingState) {
    state.loadCollectionListState = setStateLoadingStatusByType(loadingState);
  },
  setCollectionListLastPage(state: CollectionListState, number: number) {
    state.collectionListLastPage = number;
  },
  destroyCollectionList(state: CollectionListState) {
    Object.assign(state, initialState());
  },
};

const actions = {
  async loadCollectionList(
    { commit }: CollectionListActionContext,
    { projectKey, filter }: { projectKey: string; filter: Filter },
  ) {
    try {
      commit('setLoadCollectionListState', { type: 'loading' });
      const response = await getCollections(projectKey, filter);
      const { data, lastPage, total } = response.data;
      commit('setCollectionList', data);
      commit('setCollectionListTotal', total);
      commit('setCollectionListLastPage', lastPage);
      commit('setLoadCollectionListState', { type: 'success' });
    } catch (error) {
      commit('setLoadCollectionListState', {
        type: 'error',
        error: formatErrorObject(error, 'Collection List'),
      });
    }
  },
  destroyCollectionList({ commit }: CollectionListActionContext) {
    commit('destroyCollectionList');
  },
};

export default {
  state,
  mutations,
  actions,
};
