/* eslint-disable no-shadow */
import type { ActionContext } from 'vuex';
import { setStateLoadingStatusByType } from '@/modules/shared/utils/stateManagement';
import { formatErrorObject } from '@/modules/shared/utils/errorFormatter';
import type { RootState } from '@/store/type';
import type { LoadingState } from '@/types/State.type';
import type { CustomFieldListState, CustomFieldSection } from '../types';
import { getCustomFieldSections } from '../services';

type CustomFieldListActionContext = ActionContext<CustomFieldListState, RootState>;

const initialState = (): CustomFieldListState => ({
  customFieldList: null,
  loadCustomFieldListState: setStateLoadingStatusByType(),
});

const state = initialState();

const mutations = {
  setCustomFieldList(state: CustomFieldListState, customFieldList: CustomFieldSection[]) {
    state.customFieldList = customFieldList;
  },
  setLoadCustomFieldListState(state: CustomFieldListState, loadingState: LoadingState) {
    state.loadCustomFieldListState = setStateLoadingStatusByType(loadingState);
  },
  destroyCustomFieldList(state: CustomFieldListState) {
    Object.assign(state, initialState());
  },
};

const actions = {
  async loadCustomFieldList({ commit }: CustomFieldListActionContext) {
    try {
      commit('setLoadCustomFieldListState', { type: 'loading' });
      const response = await getCustomFieldSections();
      commit('setCustomFieldList', response.data);
      commit('setLoadCustomFieldListState', { type: 'success' });
    } catch (error) {
      commit('setLoadCustomFieldListState', { type: 'error', error: formatErrorObject(error, 'Custom Field') });
    }
  },
  loadCustomFieldListPromise({ commit }: CustomFieldListActionContext) {
    commit('setLoadCustomFieldListState', { type: 'loading' });

    return new Promise((resolve, reject) => {
      getCustomFieldSections()
        .then((response) => {
          commit('setCustomFieldList', response.data);
          commit('setLoadCustomFieldListState', { type: 'success' });
          resolve(response);
        })
        .catch((error) => {
          commit('setLoadCustomFieldListState', { type: 'error', error: formatErrorObject(error, 'Custom Field') });
          reject(error);
        });
    });
  },
  destroyCustomFieldList({ commit }: CustomFieldListActionContext) {
    commit('destroyCustomFieldList');
  },
};

export default {
  state,
  mutations,
  actions,
};
