<template>
  <SlideOut
    :title="$t('common:filter')"
    titleIconClassName="fa fa-filter"
    :isShowModalFooter="false"
    @slideOutClose="onClose"
    dataTest="filter-slide-out"
  >
    <FormMultiSelect
      :title="$t('common:tags')"
      :modelValue="selectedTags"
      :options="options"
      :searchable="true"
      :taggable="true"
      @onSearch="onSearchTag"
      @onChanged="onChangeTags"
    />
    <Button
      buttonStyle="text-primary"
      buttonClasses="clear-search-button"
      @click="clearSearch">
      <template v-slot:content>
        {{ $t('common:search.resultEmpty.buttonTitle') }}
      </template>
    </Button>
    <div class="filter-container" v-if="toggleDropdown">
      <div class="tag-container">
        <div v-for="tag in selectedTags || []" :key="tag.index" class="tag">
          <span>{{ tag.name }}</span>
          <i class="fa fa-xmark remove-button" @click="onRemoveTag(tag.id)" />
        </div>
        <span class="empty-text" v-if="!selectedTags.length">{{ $t('common:noSelectedTag') }}</span>
      </div>
    </div>
  </SlideOut>
</template>

<script>

import { loadVideoTags } from '@/modules/videoDetail/services';
import _ from 'lodash';
import Button from '@/modules/shared/components/atoms/button/Button.vue';
import SlideOut from '@/modules/shared/components/organisms/slideOut/SlideOut.vue';
import FormMultiSelect from '@/modules/shared/components/molecules/formMultiSelect/FormMultiSelect.vue';

export default {
  props: {
    onClose: {
      type: Function,
      default: () => { },
    },
  },
  components: {
    Button,
    SlideOut,
    FormMultiSelect,
  },
  data() {
    return {
      options: [],
      selectedTags: [],
      toggleDropdown: false,
      state: {
        error: null,
      },
    };
  },
  async created() {
    await this.loadTags();
    this.getSelectedTags();
  },
  computed: {
    projectKey() {
      return this.$route.params.projectKey;
    },
  },
  methods: {
    onSearchTag(query) {
      this.loadTags(query.q);
    },
    async loadTags(q) {
      try {
        const response = await loadVideoTags({ projectKey: this.projectKey, q });
        this.options = response.data?.data;
      } catch (error) {
        this.state.errors.loadTags = error;
      }
    },
    onChangeTags(tags) {
      this.selectedTags = tags;
      this.pushQueryParam();
    },
    onToggleDropdown() {
      this.toggleDropdown = !this.toggleDropdown;
    },
    onRemoveTag(tagId) {
      _.remove(this.selectedTags, { id: tagId });
      this.pushQueryParam();
    },
    pushQueryParam() {
      const tagIds = this.selectedTags?.map((tag) => tag.id);
      this.$router.push({ query: { tagIds: tagIds.join(',') } });
    },
    getSelectedTags() {
      let { tagIds } = this.$route.query;
      if (tagIds && tagIds.length > 0) {
        tagIds = tagIds.split(',');

        const selected = [];
        // preserve user selected order
        tagIds.forEach((id) => {
          const tag = this.options?.find((item) => item.id === id);
          if (tag) {
            selected?.push(tag);
          }
        });

        this.selectedTags = selected;
      }
    },
    clearSearch() {
      this.$router.push({ query: { tagIds: '' } });
      this.selectedTags = [];
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/global-variables.scss';

:deep(.clear-search-button) {
  margin-left: auto;
  margin-top: $spacing-base * 1.5;
}
</style>
