import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, withKeys as _withKeys, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "error-message"
}

import Field from '@/modules/shared/components/molecules/field/Field.vue';
import { createCaptionDraftTypeManually } from '@/modules/videoCaption/services';
import {
  computed, ref, toRefs,
} from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import type { Language } from '@/types/Language.type';
import type { StatusState } from '@/types/State.type';
import Modal from '@/modules/shared/components/organisms/modal/Modal.vue';
import Button from '@/modules/shared/components/atoms/button/Button.vue';
import Input from '@/modules/shared/components/atoms/input/Input.vue';
import { setStateSavingStatusByType } from '@/modules/shared/utils/stateManagement';
import { formatErrorObject } from '@/modules/shared/utils/errorFormatter';
import { useStore } from '../../../store/hooks';
import LanguageSelector from '../../molecules/languageSelector/LanguageSelector.vue';

interface TypeManuallyVideoCaptionModalProps {
  onClose: () => void;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'TypeManuallyVideoCaptionModal',
  props: {
    onClose: { type: Function }
  },
  emits: ['toInit'],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const { onClose } = toRefs(props);
const emit = __emit;

const store = useStore();
const route = useRoute();
const router = useRouter();
const { t } = useI18n();

const customName = ref<string>('');
const createCaptionState = ref<StatusState>(setStateSavingStatusByType());

const filter = computed(() => route.query);
const currentVideo = computed(() => store.state.video.currentVideo);
const languageList = computed(() => store.state.languageList.languageList);

function getDefaultSubtitleCode() {
  if (Array.isArray(languageList.value) && languageList.value[0]) {
    return languageList.value[0].code6392T;
  }

  return '';
}

const languageCode = ref<string>(getDefaultSubtitleCode());
const isEnableSave = computed(() => customName.value && languageCode.value && createCaptionState.value.status !== 'loading');

function onSelectLanguageCode(value: Language) {
  languageCode.value = value.code6392T;
}

async function onSubmit() {
  if (!isEnableSave.value) {
    return;
  }

  createCaptionState.value = setStateSavingStatusByType({ type: 'saving' });

  const videoKey = currentVideo.value?.key;
  const revisionKey = currentVideo.value?.vod?.revisionKey;

  if (!videoKey || !revisionKey) {
    return;
  }

  try {
    await createCaptionDraftTypeManually(videoKey, {
      publishStatusSetting: 'draft',
      autoGenerateCaptionSettingId: null,
      audioVariantKey: null,
      captionDrafts: [
        {
          languageCode: languageCode.value,
          customName: customName.value,
          originalFilename: null,
          captionProcessingType: {
            type: 'manual',
            service: null,
          },
        },
      ],
    });

    await store.dispatch('reloadCaptionList', {
      ...(filter.value || {}),
      videoKey: currentVideo.value.key,
    });

    createCaptionState.value = setStateSavingStatusByType({ type: 'success' });

    const { lastPage } = store.state.captionList;
    if (Number(filter.value.page) !== Number(lastPage)) {
      await router.push({ path: route.path, query: { page: lastPage } });
    }
    onClose.value();
  } catch (error) {
    createCaptionState.value = setStateSavingStatusByType({
      type: 'error',
      error: formatErrorObject(error, 'Caption'),
    });
  }
}

function onClickSecondaryButton() {
  emit('toInit');
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(Modal, {
    title: _unref(t)('video.subtitle.modal.add.typeManually.title'),
    class: "set-subtitle-name-modal",
    primaryButtonText: _unref(t)('common:button.add'),
    isDisabledPrimaryButton: !isEnableSave.value,
    isSaving: createCaptionState.value.status === 'saving',
    onClickPrimaryButton: onSubmit,
    secondaryButtonText: _unref(t)('common:button.back'),
    onClickSecondaryButton: onClickSecondaryButton,
    onModalClose: _unref(onClose)
  }, {
    body: _withCtx(() => [
      _createVNode(Field, { title: "Display Name" }, {
        default: _withCtx(() => [
          _createVNode(Input, {
            type: "text",
            class: "form-control",
            modelValue: customName.value,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((customName).value = $event)),
            onKeydown: _withKeys(onSubmit, ["enter"]),
            placeholder: _unref(t)('video.subtitle.displayName.placeholder')
          }, null, 8, ["modelValue", "placeholder"])
        ]),
        _: 1
      }),
      _createVNode(Field, {
        title: _unref(t)('caption.upload.language')
      }, {
        default: _withCtx(() => [
          _createVNode(LanguageSelector, {
            onSelected: onSelectLanguageCode,
            value: languageCode.value,
            languageCode: languageCode.value,
            placeholder: _unref(t)('video.subtitle.language.select')
          }, null, 8, ["value", "languageCode", "placeholder"])
        ]),
        _: 1
      }, 8, ["title"])
    ]),
    "secondary-btn-prefix": _withCtx(() => _cache[1] || (_cache[1] = [
      _createElementVNode("i", { class: "fas fa-chevron-left" }, null, -1)
    ])),
    footer: _withCtx(() => [
      (createCaptionState.value.status !== 'error')
        ? (_openBlock(), _createBlock(Button, {
            key: 0,
            onClick: _unref(onClose),
            type: "button",
            buttonStyle: "text-secondary"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_unref(t)('common:button.cancel')), 1)
            ]),
            _: 1
          }, 8, ["onClick"]))
        : _createCommentVNode("", true),
      (createCaptionState.value.status === 'error')
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(createCaptionState.value.error?.message), 1))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["title", "primaryButtonText", "isDisabledPrimaryButton", "isSaving", "secondaryButtonText", "onModalClose"]))
}
}

})