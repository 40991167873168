import type { Collection } from '@/modules/collection/types';
import { axiosAPI } from '@/plugins/axios';
import { qsOption } from '@/services/constants';
import type { Filter, ListApiResponse } from '@/types/Axios.type';
import qs from 'qs';

export function getCollections(projectKey: string, filter: Filter) {
  const query = qs.stringify(filter, qsOption);
  return axiosAPI.get<ListApiResponse<Collection[]>>(`/projects/${projectKey}/collections${query}`);
}

export function createCollection(projectKey: string, payload: Partial<Collection>) {
  return axiosAPI.post<Collection>(`/projects/${projectKey}/collections`, payload);
}
