<script lang="ts">
import { defineComponent, PropType } from 'vue';

// To match vue-toast-notification's prop.
type FunctionType = () => any;

export default defineComponent({
  name: 'SuccessToast',
  props: {
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: false,
      default: '',
    },
    duration: {
      type: Number,
      required: false,
      default: 3000,
    },
    dismissible: {
      type: Boolean,
      required: false,
      default: true,
    },
    onDismiss: {
      type: Function as PropType<FunctionType>,
      required: false,
    },
    onClick: {
      type: Function as PropType<FunctionType>,
      required: false,
    },
    pauseOnHover: {
      type: Boolean,
      required: false,
      default: true,
    },
    isWhiteSpacePreLine: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  mounted() {
    this.$toast.open({
      message: `
        <div class="toast-content-container">
          <i class="fas fa-check-circle"></i>
          <div class="message">
            <p class="title">${this.title}</p>
            <p class="description ${this.isWhiteSpacePreLine && 'white-space-pre-line'}">${this.description}</p>
          </div>
        </div>
      `,
      type: 'success',
      duration: this.duration,
      dismissible: this.dismissible,
      onDismiss: this.onDismiss,
      onClick: this.onClick,
      pauseOnHover: this.pauseOnHover,
    });
  },
});

// Change styles at 'src/assets/scss/themes/components/toast.scss'
</script>
