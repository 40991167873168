import { createI18n } from 'vue-i18n';
import app from './json/app.json';
import common from './json/common.json';
import glossary from './json/glossary.json';
import validation from './json/validation.json';
import response from './json/response.json';

/**
 * Sakorn's Note:
 * 'any' is used here because the depth of the translation key could vary and is not certain.
 * e.g. there might be the 'project.manage.member.table.header.title.a.b.c.d.and.so.on....' key in the future.
 * */
type TranslationValue = Record<string, any> | string;

interface SheetData {
  en: {[key: string]: TranslationValue};
  th: {[key: string]: TranslationValue};
}

const jsonGroupedByCategories: Array<{ name: string; json: SheetData }> = [
  { name: 'common', json: common },
  { name: 'glossary', json: glossary },
  { name: 'validation', json: validation },
  { name: 'response', json: response },
];

const messages = jsonGroupedByCategories.reduce<SheetData>((previousValue, currentValue) => {
  const { en, th } = currentValue.json;

  const newEn = { ...previousValue.en };
  const newTh = { ...previousValue.th };

  Object.keys(en).forEach((key) => {
    newEn[`${currentValue.name}:${key}`] = en[key];
  });

  Object.keys(th).forEach((key) => {
    newTh[`${currentValue.name}:${key}`] = th[key];
  });

  return {
    en: newEn,
    th: newTh,
  };
}, app);

const vueI18n = createI18n<SheetData, 'en' | 'th'>({
  globalInjection: true,
  legacy: false, // legacy: false for Vue 3's Composition API
  locale: 'th',
  fallbackLocale: 'en',
  messages,
});

export default vueI18n;
