import { axiosAPI } from '@/plugins/axios';

interface MoveVideosProps {
  sourceProjectKey: string;
  destinationProjectKey: string;
}

export const moveVideos = ({ sourceProjectKey, destinationProjectKey }: MoveVideosProps) => (
  axiosAPI.patch(`/projects/${sourceProjectKey}/move-entire-videos-to-another-project`, { destinationProjectKey })
);

export const deleteAllVideos = (projectKey: string) => (
  axiosAPI.delete(`/projects/${projectKey}/entire-videos`)
);

export const deleteProject = (projectKey: string) => (
  axiosAPI.delete(`/projects/${projectKey}`)
);

export const restoreVideoToProjectByKey = (projectKey: string, videoKey: string) => (
  axiosAPI.put(`/deleted-videos/restore/${videoKey}`, { projectKey })
);
