import type { ProjectDefaultUploadSettingState } from '@/modules/projectDefaultUploadSetting/types';
import { useStore as baseUseStore } from 'vuex';
import { storeKey } from '@/store';
import type { ProjectState } from '@/modules/shared/types/project.type';
import type { UserState } from '@/modules/user';
import type { PlaybackChannelListState } from '@/modules/playbackChannel';
import type { TeamFeaturesState } from '@/modules/shared/types/teamFeatures.type';

export const useStore = () => baseUseStore<{
  project: ProjectState;
  projectDefaultUploadSetting: ProjectDefaultUploadSettingState;
  playbackChannelList: PlaybackChannelListState;
  teamFeatures: TeamFeaturesState;
  user: UserState;
}>(storeKey);
