/* eslint no-shadow: ["error", { "allow": ["state"] }] */
import { loadUrlSigningKeysAPI } from '@/modules/urlSigningKey/services/api';
import _ from 'lodash';

import { formatErrorObject } from '@/modules/shared/utils/errorFormatter';

import {
  setStateLoadingStatusByType,
} from '@/modules/shared/utils/stateManagement';
import { ActionContext } from 'vuex';
import { RootState } from '@/store/type';
import { LoadingState } from '@/types/State.type';
import { UrlSigningKey, UrlSigningKeyListState } from '../types/UrlSigningKey.type';

type UrlSigningKeyListActionContext = ActionContext<UrlSigningKeyListState, RootState>;

const initialState = (): UrlSigningKeyListState => ({
  urlSigningKeyList: null,
  lastPage: 0,
  loadUrlSigningKeyListState: setStateLoadingStatusByType(),
});

const state = initialState();

const getters = {
  isEmptyUrlSigningKeyList: (state: UrlSigningKeyListState) => _.isEmpty(state.urlSigningKeyList),
};

const mutations = {
  setUrlSigningKeyList(state: UrlSigningKeyListState, urlSigningKeyList: UrlSigningKey[]) {
    state.urlSigningKeyList = urlSigningKeyList;
  },
  setLoadUrlSigningKeyListState(state: UrlSigningKeyListState, loadingState: LoadingState) {
    state.loadUrlSigningKeyListState = setStateLoadingStatusByType(loadingState);
  },
  setUrlSigningKeyListLastPage(state: UrlSigningKeyListState, lastPage: number) {
    state.lastPage = lastPage;
  },
};

const actions = {
  async loadUrlSigningKeys({ commit }: UrlSigningKeyListActionContext, filter: { q: string}) {
    commit('setLoadUrlSigningKeyListState', { type: 'loading' });
    try {
      const response = await loadUrlSigningKeysAPI(filter);
      commit('setUrlSigningKeyList', response.data.data);
      commit('setUrlSigningKeyListLastPage', response.data.lastPage);
      commit('setLoadUrlSigningKeyListState', { type: 'success' });
    } catch (error) {
      commit('setLoadUrlSigningKeyListState', { type: 'error', error: formatErrorObject(error, 'Player') });
    }
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
