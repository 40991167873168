import isNil from 'lodash/isNil';
import { LocationQuery } from 'vue-router';

export function getQueryAsString(query: LocationQuery, key: string): string | null {
  if (isNil(query)) {
    return null;
  }

  const value = query[key];

  if (isNil(value)) {
    return null;
  }

  if (Array.isArray(value)) {
    return value[0];
  }

  return value;
}
