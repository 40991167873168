import type { ActionContext } from 'vuex';
import { formatErrorObject } from '@/modules/shared/utils/errorFormatter';
import { setStateLoadingStatusByType, setStateSavingStatusByType } from '@/modules/shared/utils/stateManagement';
import { getCollectionTypeById } from '@/modules/collectionType/services';
import type {
  Collection,
  CollectionCustomFieldSection,
  CollectionCustomImageField,
  CollectionState,
  CollectionType,
  CollectionVisibilitySettings,
} from '@/modules/collection/types';
import type { RootState } from '@/store/type';
import type { LoadingState, SavingState } from '@/types/State.type';
import type { CustomField } from '@/modules/customField';
import type { Video } from '@/modules/shared/types/video.type';
import {
  addVideosToCollectionItemList,
  deleteVideosToCollectionItemList,
  getCollectionById,
  updateCollectionCustomField,
  updateCollectionCustomImages,
  updateCollectionListItemsOrder,
  updateCollection,
} from '../services';

type CollectionActionContext = ActionContext<CollectionState, RootState>;

const initialState = (): CollectionState => ({
  collectionTypeName: '',
  collectionVisibilitySetting: null,
  customFieldSections: [],
  collectionCustomImageFields: [],
  currentCollection: null,
  collectionVideoList: [],
  loadCurrentCollectionState: setStateLoadingStatusByType(),
  saveCurrentCollectionState: setStateSavingStatusByType(),
});

const state = initialState();

const mutations = {
  setCurrentCollection(state: CollectionState, collection: Collection) {
    state.currentCollection = collection;
  },
  setCollectionVideoList(state: CollectionState, collectionVideoList: Video[]) {
    state.collectionVideoList = collectionVideoList;
  },
  setLoadCurrentCollectionState(state: CollectionState, loadingState: LoadingState) {
    state.loadCurrentCollectionState = setStateLoadingStatusByType(loadingState);
  },
  setSaveCurrentCollectionState(state: CollectionState, savingState: SavingState) {
    state.saveCurrentCollectionState = setStateSavingStatusByType(savingState);
  },
  setCustomFieldSections(state: CollectionState, collectionCustomFieldSections: CollectionCustomFieldSection[]) {
    state.customFieldSections = collectionCustomFieldSections;
  },
  setCollectionCustomImageFields(state: CollectionState, collectionCustomImageFields: CollectionCustomImageField[]) {
    state.collectionCustomImageFields = collectionCustomImageFields;
  },
  setCollectionTypeName(state: CollectionState, collectionTypeName: string) {
    state.collectionTypeName = collectionTypeName;
  },
  setCollectionVisibilitySetting(state: CollectionState, collectionVisibilitySetting: CollectionVisibilitySettings) {
    state.collectionVisibilitySetting = collectionVisibilitySetting;
  },
  destroyCurrentCollection(state: CollectionState) {
    Object.assign(state, initialState());
  },
};

const actions = {
  async loadCurrentCollection({ commit }: CollectionActionContext, { projectKey, id }: { projectKey: string; id: string, filter: object }) {
    try {
      commit('setLoadCurrentCollectionState', { type: 'loading' });

      const { data: collectionData }: { data: Collection } = await getCollectionById(projectKey, id);
      const typeResponse = await getCollectionTypeById(collectionData.collectionTypeId);

      if (typeResponse?.data) {
        const typeResponseData: CollectionType = typeResponse.data;
        const { name, collectionCustomFieldSections, collectionCustomImageFields } = typeResponseData;
        commit('setCustomFieldSections', collectionCustomFieldSections);
        commit('setCollectionCustomImageFields', collectionCustomImageFields);
        commit('setCollectionTypeName', name);
      }

      commit('setCurrentCollection', collectionData);
      commit('setCollectionVideoList', collectionData?.itemLists?.find((item) => item.isDefault)?.videos || []);
      commit('setCollectionVisibilitySetting', collectionData.visibilitySetting);
      commit('setLoadCurrentCollectionState', { type: 'success' });
    } catch (error) {
      commit('setLoadCurrentCollectionState', { type: 'error', error: formatErrorObject(error, 'Collection') });
    }
  },
  async saveCurrentCollection({ state, commit }: CollectionActionContext, payload: { projectKey: string, data: Partial<Collection> }) {
    if (!state.currentCollection) {
      return;
    }

    try {
      commit('setSaveCurrentCollectionState', { type: 'saving' });
      const { data: collectionData }: { data: Collection } = await updateCollection(payload.projectKey, state.currentCollection?.id, { ...state.currentCollection, ...(payload?.data || {}) });
      // Api didn't response customimage defaultUrl
      // commit('setCurrentCollection', { ...state.currentCollection, ...collectionData });
      commit('setCollectionVisibilitySetting', collectionData.visibilitySetting);
      commit('setSaveCurrentCollectionState', { type: 'success' });
      setTimeout(() => {
        commit('setSaveCurrentCollectionState', { type: 'idle' });
      }, 3000);
    } catch (error) {
      commit('setSaveCurrentCollectionState', { type: 'error', error: formatErrorObject(error, 'Collection') });
    }
  },
  async addVideosToCollection({ state, commit }: CollectionActionContext, payload: { projectKey: string, itemListId: string, videoIds: string[] }) {
    if (!state.currentCollection) {
      return;
    }

    try {
      commit('setSaveCurrentCollectionState', { type: 'saving' });
      const response = await addVideosToCollectionItemList(
        payload.projectKey, state.currentCollection.id, payload.itemListId, { videoIds: payload.videoIds },
      );
      commit('setCollectionVideoList', response.data.videos);
      commit('setSaveCurrentCollectionState', { type: 'success' });
      setTimeout(() => {
        commit('setSaveCurrentCollectionState', { type: 'idle' });
      }, 3000);
    } catch (error) {
      commit('setSaveCurrentCollectionState', { type: 'error', error: formatErrorObject(error, 'Collection') });
    }
  },
  async removeVideoFromCollection({ state, commit }: CollectionActionContext, payload: { projectKey: string, itemListId: string, videoId: string }) {
    if (!state.currentCollection) {
      return;
    }

    try {
      commit('setSaveCurrentCollectionState', { type: 'saving' });
      await deleteVideosToCollectionItemList(payload.projectKey, state.currentCollection.id, payload.itemListId, { videoIds: [payload.videoId] });
      const filterVideoList = state.collectionVideoList.filter((video) => video.id !== payload.videoId);
      commit('setCollectionVideoList', filterVideoList);
      commit('setSaveCurrentCollectionState', { type: 'success' });
      setTimeout(() => {
        commit('setSaveCurrentCollectionState', { type: 'idle' });
      }, 3000);
    } catch (error) {
      commit('setSaveCurrentCollectionState', { type: 'error', error: formatErrorObject(error, 'Collection') });
    }
  },
  async updateCollectionVideoList({ state, commit }: CollectionActionContext, payload: { projectKey: string, itemListId: string, videoIds: string[] }) {
    if (!state.currentCollection) {
      return;
    }

    try {
      commit('setSaveCurrentCollectionState', { type: 'saving' });
      await updateCollectionListItemsOrder(payload.projectKey, state.currentCollection.id, payload.itemListId, { videoIds: payload.videoIds });
      // Api didn't response new videoList TT.
      // commit('setCollectionVideoList', collectionVideoList);
      commit('setSaveCurrentCollectionState', { type: 'success' });
      setTimeout(() => {
        commit('setSaveCurrentCollectionState', { type: 'idle' });
      }, 3000);
    } catch (error) {
      commit('setSaveCurrentCollectionState', { type: 'error', error: formatErrorObject(error, 'Collection') });
    }
  },
  async saveCurrentCollectionCustomField({ commit, state }: CollectionActionContext, payload: { projectKey: string, collectionId: string, customFields: CustomField[] }) {
    if (!state.currentCollection) {
      return;
    }

    try {
      commit('setSaveCurrentCollectionState', { type: 'saving' });
      await updateCollectionCustomField(payload.projectKey, state.currentCollection.id, { customFields: payload?.customFields });
      commit('setSaveCurrentCollectionState', { type: 'success' });
      setTimeout(() => {
        commit('setSaveCurrentCollectionState', { type: 'idle' });
      }, 3000);
    } catch (error) {
      commit('setSaveCurrentCollectionState', { type: 'error', error: formatErrorObject(error, 'Collection') });
    }
  },
  async saveCurrentCollectionCustomImageField({ state, commit }: CollectionActionContext, payload: { projectKey: string, customImageFields: CollectionCustomImageField[] }) {
    if (!state.currentCollection) {
      return;
    }

    try {
      commit('setSaveCurrentCollectionState', { type: 'saving' });
      await updateCollectionCustomImages(payload.projectKey, state.currentCollection.id, { customFields: payload?.customImageFields });
      commit('setSaveCurrentCollectionState', { type: 'success' });
      setTimeout(() => {
        commit('setSaveCurrentCollectionState', { type: 'idle' });
      }, 3000);
    } catch (error) {
      commit('setSaveCurrentCollectionState', { type: 'error', error: formatErrorObject(error, 'Collection') });
    }
  },
  destroyCurrentCollection({ commit }: CollectionActionContext) {
    commit('destroyCurrentCollection');
  },
  async reloadCurrentCollection({ commit }: CollectionActionContext, projectKey: string) {
    if (state.currentCollection) {
      const { id } = state.currentCollection;
      try {
        commit('setLoadCurrentCollectionState', { type: 'loading' });
        const response = await getCollectionById(projectKey, id);
        commit('setCurrentCollection', response.data);
        commit('setLoadCurrentCollectionState', { type: 'success' });
      } catch (error) {
        commit('setLoadCurrentCollectionState', { type: 'error', error: formatErrorObject(error, 'Collection') });
      }
    }
  },
};

export default {
  state,
  mutations,
  actions,
};
