import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { computed, reactive, toRefs } from 'vue';
import { useI18n } from 'vue-i18n';
import RadioButtonGroup from '@/modules/shared/components/molecules/radioButtonGroup/RadioButtonGroup.vue';
import Field from '@/modules/shared/components/molecules/field/Field.vue';

interface CaptionStatusRadioGroupProps {
  modelValue: string;
  onChange: () => void;
  isDisableRadioButton?: boolean;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'CaptionStatusRadioGroup',
  props: {
    modelValue: {},
    onChange: {},
    isDisableRadioButton: { type: Boolean, default: false }
  },
  setup(__props: any) {

const props = __props;
const {
  modelValue,
  onChange,
  isDisableRadioButton,
} = toRefs(props);

const { t } = useI18n();

const statusOptions = reactive([
  {
    label: computed(() => t('video.subtitle.status.setting.draft.title')),
    description: computed(() => t('video.subtitle.status.setting.draft.description')),
    value: 'draft',
  },
  {
    label: computed(() => t('video.subtitle.status.setting.publish.title')),
    description: computed(() => t('video.subtitle.status.setting.publish.description')),
    value: 'publish',
  },
  // {
  //   label: computed(() => t('video.subtitle.status.setting.publishIfNoBadWord.title')),
  //   description: computed(() => t('video.subtitle.status.setting.publishIfNoBadWord.description')),
  //   value: 'publishIfNoBadWord',
  // },
]);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(Field, {
    title: _unref(t)('video.subtitle.status.setting.choose')
  }, {
    default: _withCtx(() => [
      _createVNode(RadioButtonGroup, {
        options: statusOptions,
        modelValue: _unref(modelValue),
        onChange: _unref(onChange),
        disabled: _unref(isDisableRadioButton)
      }, null, 8, ["options", "modelValue", "onChange", "disabled"])
    ]),
    _: 1
  }, 8, ["title"]))
}
}

})