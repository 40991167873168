import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "geoblock-rule-loading" }
const _hoisted_2 = { class: "option" }
const _hoisted_3 = { class: "option-title" }
const _hoisted_4 = { class: "option" }
const _hoisted_5 = { class: "option-title" }

import _ from 'lodash';
import {
  computed,
  ref,
  watch,
  toRefs,
} from 'vue';
import { useI18n } from 'vue-i18n';
import { Nullable } from '@/types/index.type';
import CountryCodesSelector from '@/modules/shared/components/molecules/countryCodesSelector/CountryCodesSelector.vue';
import {
  GeoblockRuleSelector,
  GeoblockRule,
  GeoblockPolicy,
  AppliedRule, BaseGeoblockRule,
  getGeoblockPolicyCountryRule,
} from '@/modules/geoblock';
import type { BaseGeoblockPolicy } from '@/modules/geoblock/types/Geoblock.type';
import { isGeoblockRule } from '@/modules/shared/utils/typeGuard';
import PreLoaderSection from '@/modules/shared/components/atoms/preLoaderSection/PreLoaderSection.vue';
import Field from '@/modules/shared/components/molecules/field/Field.vue';
import RadioButtonGroup from '@/modules/shared/components/molecules/radioButtonGroup/RadioButtonGroup.vue';
import { StatusState } from '@/types/State.type';

interface UploadVideoGeoblockRuleSettingProps {
  geoblockRule?: Nullable<GeoblockRule>;
  defaultGeoblockRule?: Nullable<GeoblockRule | BaseGeoblockRule>;
  geoblockPolicy?: Nullable<GeoblockPolicy | BaseGeoblockPolicy>;
  loadGeoblockRuleState: StatusState;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'VideoGeoblockRuleOptionField',
  props: {
    geoblockRule: {},
    defaultGeoblockRule: {},
    geoblockPolicy: {},
    loadGeoblockRuleState: {}
  },
  emits: ["update:geoblockPolicy"],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const {
  geoblockRule,
  defaultGeoblockRule,
  geoblockPolicy,
  loadGeoblockRuleState,
} = toRefs(props);

const emit = __emit;

const { t } = useI18n();

const geoblockRuleOptions = [
  {
    label: t('video.geoBlock.chooseFromExistingRule'),
    value: 'existingRule',
    subFieldName: 'option1Sub',
  },
  {
    label: t('video.geoBlock.customGeoBlocking'),
    value: 'customRule',
    subFieldName: 'option2Sub',
  },
];

// data
const selectedRuleChoice = ref<string>('existingRule');

// computed
const geoblockPolicyCountryRule = computed(() => getGeoblockPolicyCountryRule(geoblockPolicy.value));
const geoblockRuleData = computed(() => geoblockRule.value || defaultGeoblockRule.value);

// methods
const setSelectedChoice = () => {
  if (!_.isNil(geoblockPolicy.value)) {
    selectedRuleChoice.value = geoblockPolicy.value.type;
  }
};

const mapGeoblockPolicyToValue = (newPolicy: GeoblockPolicy | BaseGeoblockPolicy) => {
  switch (newPolicy.type) {
    case 'existingRule':
      return {
        ...newPolicy,
      };
    case 'customRule':
      return {
        ...newPolicy,
        name: 'Custom Rule',
        ruleId: null,
      };
    default:
      return {
        ...newPolicy,
        ruleId: null,
      };
  }
};

const onUpdatedGeoblockPolicy = (policy: GeoblockPolicy | BaseGeoblockPolicy) => {
  emit('update:geoblockPolicy', mapGeoblockPolicyToValue(policy));
};

const onChangeGeoblockRuleChoice = (policy: GeoblockPolicy | BaseGeoblockPolicy, selectedChoice: string) => {
  // Clear geoblock policy value when chang choice
  if (selectedChoice !== selectedRuleChoice.value) {
    selectedRuleChoice.value = selectedChoice;
    if (selectedChoice === 'customRule') {
      onUpdatedGeoblockPolicy({
        ...policy,
        ruleId: null,
        name: 'Custom Rule',
        appliedRule: { allowCountryCodes: [], denyCountryCodes: [] },
        type: 'customRule',
      });
    } else if (isGeoblockRule(geoblockRuleData.value)) {
      onUpdatedGeoblockPolicy({
        type: 'existingRule',
        ruleId: geoblockRuleData.value.id,
        name: geoblockRuleData.value.name,
        appliedRule: {
          allowCountryCodes: geoblockRuleData.value.allowCountryCodes,
          denyCountryCodes: geoblockRuleData.value.denyCountryCodes,
        },
      });
    }
  }
};

const onChangeCustomRule = (customRule: AppliedRule): void => {
  if (!geoblockPolicy.value) {
    return;
  }

  let geoblockAppliedRule: AppliedRule = {
    allowCountryCodes: [],
    denyCountryCodes: [],
  };

  if (typeof geoblockPolicy.value === 'object' && 'appliedRule' in geoblockPolicy.value) {
    geoblockAppliedRule = geoblockPolicy.value.appliedRule;
  }

  const object: GeoblockPolicy | BaseGeoblockPolicy = {
    ...geoblockPolicy.value,
    type: 'customRule',
    appliedRule: {
      ...geoblockAppliedRule,
      ...customRule,
    },
  };

  onUpdatedGeoblockPolicy(object);
};

const onChangeExistingRule = (rule: GeoblockRule) => {
  onUpdatedGeoblockPolicy({
    ruleId: rule.id,
    name: rule.name,
    appliedRule: {
      allowCountryCodes: rule.allowCountryCodes,
      denyCountryCodes: rule.denyCountryCodes,
    },
    type: 'existingRule',
  });
};

watch(() => props.geoblockPolicy, () => {
  setSelectedChoice();
}, { immediate: true });

return (_ctx: any,_cache: any) => {
  return (_unref(geoblockPolicy))
    ? (_openBlock(), _createBlock(Field, { key: 0 }, {
        default: _withCtx(() => [
          _createVNode(RadioButtonGroup, {
            modelValue: selectedRuleChoice.value,
            defaultValue: selectedRuleChoice.value,
            options: geoblockRuleOptions,
            onChange: _cache[2] || (_cache[2] = (newValue) => onChangeGeoblockRuleChoice({ ..._unref(geoblockPolicy), type: newValue }, newValue))
          }, {
            option1Sub: _withCtx(() => [
              _withDirectives(_createElementVNode("div", _hoisted_1, [
                _createVNode(PreLoaderSection, { options: { height: 50 } })
              ], 512), [
                [_vShow, _unref(loadGeoblockRuleState).status === 'loading']
              ]),
              _withDirectives(_createVNode(_unref(GeoblockRuleSelector), {
                modelValue: geoblockRuleData.value,
                "onUpdate:modelValue": onChangeExistingRule
              }, null, 8, ["modelValue"]), [
                [_vShow, _unref(loadGeoblockRuleState).status === 'success']
              ])
            ]),
            option2Sub: _withCtx(() => [
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t('video.geoBlock.allowedCountries')), 1),
                _createVNode(CountryCodesSelector, {
                  modelValue: geoblockPolicyCountryRule.value?.allowCountryCodes,
                  disabledCountryCode: geoblockPolicyCountryRule.value?.denyCountryCodes,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = (value) => onChangeCustomRule({ allowCountryCodes: value }))
                }, null, 8, ["modelValue", "disabledCountryCode"])
              ]),
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t('video.geoBlock.disallowedCountries')), 1),
                _createVNode(CountryCodesSelector, {
                  modelValue: geoblockPolicyCountryRule.value?.denyCountryCodes,
                  disabledCountryCode: geoblockPolicyCountryRule.value?.allowCountryCodes,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = (value) => onChangeCustomRule({ denyCountryCodes: value }))
                }, null, 8, ["modelValue", "disabledCountryCode"])
              ])
            ]),
            _: 1
          }, 8, ["modelValue", "defaultValue"])
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}
}

})