import type { RouteRecordRaw } from 'vue-router';
import store from '@/store';
import project from '@/modules/shared/store/project';
import { projectVideoList } from '@/modules/projectVideoList';
import { projectList } from '@/modules/projectList';
import { collectionTypeList } from '@/modules/collectionType';
import collectionList from '../store';

export function createCollectionListRoutes(): RouteRecordRaw[] {
  return [
    {
      name: 'collectionListChrome',
      path: 'projects/:projectKey/collections',
      component: () => import(/* webpackChunkName: "CollectionListChrome" */ '../layout/CollectionListChrome.vue'),
      beforeEnter: (to, from, next) => {
        if (!store.hasModule('projectList')) {
          store.registerModule('projectList', projectList);
        }
        if (!store.hasModule('projectVideoList')) {
          store.registerModule('projectVideoList', projectVideoList);
        }
        if (!store.hasModule('collectionList')) {
          store.registerModule('collectionList', collectionList);
        }
        if (!store.hasModule('collectionTypeList')) {
          store.registerModule('collectionTypeList', collectionTypeList);
        }
        if (!store.hasModule('project')) {
          store.registerModule('project', project);
        }
        next();
      },
      children: [
        {
          name: 'projectCollections',
          path: '',
          component: () => import(/* webpackChunkName: "CollectionList" */ '@/modules/collectionList/pages/CollectionList.vue'),
        },
      ],
    },
  ];
}
