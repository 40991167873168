import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createBlock as _createBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "highlight-user" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { class: "highlight-user" }

import { getIfIsUserActivated } from '@/modules/projectMember/utils';
import _ from 'lodash';
import { computed, toRefs } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';
import { getQueryAsString } from '@/modules/shared/utils/query';
import Modal from '@/modules/shared/components/organisms/modal/Modal.vue';
import ErrorMessage from '@/modules/shared/components/atoms/errorMessage/ErrorMessage.vue';
import type { ProjectMember } from '@/modules/projectMember/types';
import { useStore } from '../../../store/hooks';

interface DeleteProjectMembershipModalProps {
  member: ProjectMember | null;
  onCloseModal: () => void;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'DeleteProjectMemberModal',
  props: {
    member: {},
    onCloseModal: { type: Function }
  },
  emits: ["success"],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const { member, onCloseModal } = toRefs(props);

const emit = __emit;

const { t } = useI18n();
const store = useStore();
const route = useRoute();

const projectKey = computed(() => getQueryAsString(route.params, 'projectKey'));
const deleteProjectMembershipState = computed(() => store.state.projectMember.deleteProjectMembershipState);
const deleteProjectMembershipErrorCode = computed(() => {
  if (deleteProjectMembershipState.value.error) {
    return _.get(deleteProjectMembershipState.value, 'error.response.status', '');
  }
  return '';
});

function getMemberName() {
  return _.get(member.value, 'account.name', null);
}

async function onDeleteMemberShip() {
  if (_.isNil(member.value)) {
    return;
  }

  const { accountId } = member.value;
  await store.dispatch('deleteProjectMembership', {
    projectKey: projectKey.value,
    accountId,
  });
  emit('success');
  onCloseModal.value();
}

async function onClose() {
  await store.dispatch('clearDeleteProjectMembershipState');
  onCloseModal.value();
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(Modal, {
    size: "small",
    title: _unref(t)(`project.manage.member.${_unref(getIfIsUserActivated)(_unref(member)) ? 'remove' : 'removeNonMember'}`),
    "primary-button-text": _unref(getIfIsUserActivated)(_unref(member)) ? _unref(t)('project.manage.member.remove') : _unref(t)('project.manage.member.cancelInvitation.button'),
    "secondary-button-text": _unref(getIfIsUserActivated)(_unref(member)) ? _unref(t)('common:button.cancel') : _unref(t)('common:close'),
    primaryButtonStyle: "danger",
    onClickPrimaryButton: onDeleteMemberShip,
    onClickSecondaryButton: onClose,
    onModalClose: onClose
  }, {
    body: _withCtx(() => [
      (_unref(getIfIsUserActivated)(_unref(member)))
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            (!deleteProjectMembershipState.value.error)
              ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                  _createTextVNode(_toDisplayString(_unref(t)('project.manage.members.remove.title')) + ": ", 1),
                  _createElementVNode("span", _hoisted_2, _toDisplayString(getMemberName()) + ".", 1)
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("p", null, _toDisplayString(_unref(t)('project.manage.members.remove.description')), 1)
          ], 64))
        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            (!deleteProjectMembershipState.value.error)
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  _createElementVNode("p", null, [
                    _createTextVNode(_toDisplayString(_unref(t)('project.manage.member.cancelInvitation.description1')) + " ", 1),
                    _createElementVNode("span", _hoisted_4, _toDisplayString(getMemberName()), 1),
                    _createTextVNode(" " + _toDisplayString(_unref(t)('project.manage.member.cancelInvitation.description2')), 1)
                  ])
                ]))
              : _createCommentVNode("", true)
          ], 64)),
      (deleteProjectMembershipState.value.error)
        ? (_openBlock(), _createBlock(ErrorMessage, {
            key: 2,
            title: _unref(t)('project.manage.members.remove.error'),
            description: _unref(t)('response:retry'),
            statusCode: deleteProjectMembershipErrorCode.value
          }, null, 8, ["title", "description", "statusCode"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["title", "primary-button-text", "secondary-button-text"]))
}
}

})