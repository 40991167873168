/* eslint-disable no-shadow */
import _ from 'lodash';
import {
  setStateLoadingStatusByType,
} from '@/modules/shared/utils/stateManagement';
import { formatErrorObject } from '@/modules/shared/utils/errorFormatter';
import { ActionContext } from 'vuex';
import { RootState } from '@/store/type';
import { Webhook, WebhookListState } from '@/modules/webhook/types/Webhook.type';
import { LoadingState } from '@/types/State.type';
import { loadWebhooks, updateWebhookStatusById } from '../services';

type WebhookListActionContext = ActionContext<WebhookListState, RootState>

const initialState = (): WebhookListState => ({
  webhookList: [],
  webhookListLastPage: null,
  loadWebhookListState: setStateLoadingStatusByType(),
});

const state = initialState();

const mutations = {
  setWebhookList(state: WebhookListState, webhooks: Webhook[]) {
    state.webhookList = webhooks;
  },
  setWebhookListLastPage(state: WebhookListState, page: number) {
    state.webhookListLastPage = page;
  },
  setWebhookListItemById(state: WebhookListState, { id, data }: { id: string, data: Webhook}) {
    const index = _.findIndex(state.webhookList, { _id: id });
    if (index >= 0) {
      state.webhookList[index] = data;
    }
  },
  removeWebhookListItemById(state: WebhookListState, id: string) {
    _.remove(state.webhookList, { _id: id });
  },
  setLoadWebhookListState(state: WebhookListState, loadingState: LoadingState) {
    state.loadWebhookListState = setStateLoadingStatusByType(loadingState);
  },
  destroyWebhookListData() {
    Object.assign(state, initialState());
  },
};

const actions = {
  async loadWebhookList({ commit }: WebhookListActionContext, query: { groupName: string, teamNamespace: string, q: string }) {
    try {
      commit('destroyWebhookListData');
      commit('setLoadWebhookListState', { type: 'loading' });
      const response = await loadWebhooks(query);
      commit('setWebhookList', response.data.data);
      commit('setWebhookListLastPage', response.data.lastPage);
      commit('setLoadWebhookListState', { type: 'success' });
    } catch (error) {
      commit('setLoadWebhookListState', { type: 'error', error: formatErrorObject(error, 'Webhook List') });
    }
  },
  async updateWebhookItemStatus({ commit }: WebhookListActionContext, { id, status }: { id: string, status: boolean }) {
    try {
      const response = await updateWebhookStatusById(id, status);
      if (response.data) {
        commit('setWebhookListItemById', { id, data: response.data });
      }
    } catch (error) {
      commit('setLoadWebhookListState', { type: 'error', error: formatErrorObject(error, 'Webhook List') });
    }
  },
  setWebhookList({ commit }: WebhookListActionContext, webhookList: Webhook[]) {
    commit('setWebhookList', webhookList);
  },
  setWebhookListItemById({ commit }: WebhookListActionContext, { id, data }: { id: string, data: Webhook}) {
    commit('setWebhookListItemById', { id, data });
  },
  destroyWebhookListData({ commit }: WebhookListActionContext) {
    commit('destroyWebhookListData');
  },
  removeWebhookListItemById({ commit }: WebhookListActionContext, id: string) {
    commit('removeWebhookListItemById', id);
  },
};

export default {
  state,
  actions,
  mutations,
};
