<template>
  <Modal
    size="large"
    title="Allowed / Blocked Website"
    @modalClose="() => emit('close')"
    v-if="!_.isNil(data)"
  >
    <template v-slot:body>
      <SectionCollapse
        :title="`<i class='fas fa-circle-check icon-allow'></i> Allowed Website (${data.allowDomains.length})`">
        <ul
          v-if="data.allowDomains && data.allowDomains.length"
          class="website-list">
          <li
            class="website"
            v-for="(website, index) in data.allowDomains"
            :key="index">
            <i class="fas fa-earth-asia"></i> {{website}}
          </li>
        </ul>
        <p v-else>{{t('project.setup.playbackChannel.acp.emptyList')}}</p>
      </SectionCollapse>
      <SectionCollapse
        :title="`<i class='fas fa-ban icon-deny'></i> Blocked Website (${data.denyDomains.length})`">
        <ul
          v-if="data.denyDomains && data.denyDomains.length"
          class="website-list">
          <li
            class="website"
            v-for="(website, index) in data.denyDomains"
            :key="index">
            <i class="fas fa-earth-asia"></i> {{website}}
          </li>
        </ul>
        <p v-else>{{t('project.setup.playbackChannel.acp.emptyList')}}</p>
      </SectionCollapse>
    </template>
  </Modal>
</template>

<script lang="ts" setup>
import type { AccessControlPolicy } from '@/modules/playbackChannel/types/playbackChannel.type';
import _ from 'lodash';
import { toRefs } from 'vue';
import Modal from '@/modules/shared/components/organisms/modal/Modal.vue';
import SectionCollapse from '@/modules/shared/components/organisms/sectionCollapse/SectionCollapse.vue';
import { useI18n } from 'vue-i18n';

interface AccessControlPolicyModalProps {
  data: AccessControlPolicy | null | undefined;
}

const props = defineProps<AccessControlPolicyModalProps>();
const { data } = toRefs(props);

const emit = defineEmits<{close: []}>();

const { t } = useI18n();
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/global-variables.scss';

:deep(.modal-content) {
  background-color: #F8F9FA;
}

:deep(.modal-footer) {
  display: none;
}

:deep(.icon-allow) {
  font-size: $spacing-base * 1.2;
  margin-right: 8px;
  color: #27AE60;
}

:deep(.icon-deny) {
  font-size: $spacing-base * 1.2;
  margin-right: 8px;
  color: #EB5757;
}

.website-list {
  list-style: none;
  padding: 0;
  .website {
    color: #6A7383;
    .fas {
      margin-right: 8px;
    }
  }
}
</style>
