<template>
  <div :class="{ hideTooltip: !isVideoKeyCopied, copy: true }" class="video-key-copy">
    <span style="margin-top: -1px" class="video-key" data-test="video-key"> {{ videoKey }}</span>
    <Tooltip>
      <template v-slot:tooltip-text>{{ isVideoKeyCopied ? t('glossary:copied') : t('glossary:copy') }} </template>
      <Button
        @click.prevent="onCopy"
        buttonClasses="copyBtn"
        buttonStyle="text-secondary"
        isSquare
      >
        <template v-slot:icon-prefix>
          <i class="fal fa-clone icon"></i>
        </template>
      </Button>
    </Tooltip>
  </div>
</template>

<script setup lang="ts">
import { ref, toRefs } from 'vue';
import { useI18n } from 'vue-i18n';
import Button from '@/modules/shared/components/atoms/button/Button.vue';
import Tooltip from '@/modules/shared/components/atoms/tooltip/Tooltip.vue';
import { copyValue } from '@/modules/shared/utils/copy';

interface VideoKeyCopyProps {
  videoKey: string;
}

const props = defineProps<VideoKeyCopyProps>();
const { videoKey } = toRefs(props);
const { t } = useI18n();

const isVideoKeyCopied = ref<boolean>(false);

function onCopy() {
  copyValue(videoKey.value);
  isVideoKeyCopied.value = true;

  const timeout = setTimeout(() => {
    isVideoKeyCopied.value = false;
    clearTimeout(timeout);
  }, 1000);
}
</script>

<style scoped lang="scss">
@import '~@/assets/scss/global-variables.scss';

.video-key-copy {
  display: flex;
  align-items: center;
  gap: $spacing-8;

  .hideTooltip {
    :deep(.tooltip .popper) {
      display: none;
    }
  }

  :deep(.button) {
    width: 12px;
    height: 12px;
    padding: 0;
  }
}
</style>
