<template>
  <div class="app-chrome">
    <router-view />
    <UploadSummaryModal v-if="!isUploadSummaryMinimized" />
    <transition name="slide">
      <VideoUploadProgressPopup v-if="isUploadingVideos && isFirstTimeUploadSummaryMinimized" />
    </transition>
  </div>
</template>

<script lang="ts" setup>
import _ from 'lodash';
import { computed, onMounted } from 'vue';
import { useStore } from '@/store';
import { VideoUploadProgressPopup, UploadSummaryModal, type VideoUploadState } from '@/modules/videoUpload';

const store = useStore<{videoUpload: VideoUploadState}>();

const videoUploadKeys = computed(() => store.state.videoUpload.videoUploadKeys);
const videoKeysGroupByState = computed(() => store.state.videoUpload.videoUploadKeys);
const isUploadSummaryMinimized = computed(() => store.state.videoUpload.isUploadSummaryMinimized);
const isFirstTimeUploadSummaryMinimized = computed(() => store.state.videoUpload.isFirstTimeUploadSummaryMinimized);
const isUploadingVideos = computed(() => _.get(videoUploadKeys, 'value', []).length > 0);

function getUploadVideoByStatus(statusKey: string) {
  return _.get(videoKeysGroupByState.value, statusKey, []);
}

function handleBeforeCloseBrowser(event: Event) {
  const uploadingItems = getUploadVideoByStatus('uploading');
  if (uploadingItems && uploadingItems.length > 0) {
    // Cancel the event as stated by the standard.
    event.preventDefault();
    // Older browsers supported custom message
    // eslint-disable-next-line no-param-reassign
    event.returnValue = true;
    // alert('onbeforeunload');
    return 'onbeforeunload';
  }

  return undefined;
}

onMounted(() => {
  window.addEventListener('beforeunload', handleBeforeCloseBrowser);
  window.addEventListener('unload', handleBeforeCloseBrowser);
});
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/global-variables.scss';
</style>
