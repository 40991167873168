<template>
  <SelectOption
    ref="multiselect"
    :options="options"
    :label="label"
    track-by="code6392T"
    :placeholder="placeholder || 'Select Language'"
    selectLabel=""
    deselectLabel=""
    @update:modelValue="(selected) => onSelect(selected)"
    :onSearchChange="(searchValue) => onSearchChange(searchValue)"
    @close="onBlur"
    :allowEmpty="false"
    v-model="clonedValue"
    :taggable="false"
    :open-direction="openDirection"
    preserveSearch
    searchable
  >
    <!-- <template v-if="placeholder" v-slot:placeholder>
      {{placeholder}}
    </template> -->
    <template v-if="noResultMessage" v-slot:noResult>
      {{$t('video.subtitle.language.notFound')}}
    </template>
  </SelectOption>
</template>

<script setup>
import {
  computed,
  onMounted,
  ref,
  watch,
} from 'vue';
import SelectOption from '@/modules/shared/components/atoms/selectOption/SelectOption.vue';
import { useStore } from '../../../../videoDetail/store/hooks';

// eslint-disable-next-line no-undef
const props = defineProps({
  languageCode: {
    type: [String, null],
    required: true,
  },
  label: {
    type: String, // 'displayName' || 'code6392T'
    required: false,
    default: () => 'displayName',
    validator(value) {
      return value === 'displayName' || value === 'code6392T';
    },
  },
  placeholder: {
    type: String,
    required: false,
    default: () => '',
  },
  noResultMessage: {
    type: String,
    required: false,
    default: () => '',
  },
  optionWidth: {
    type: String,
  },
  openDirection: {
    type: String,
    default: 'bottom',
  },
});
// eslint-disable-next-line no-undef
const emits = defineEmits(['selected']);

const store = useStore();
const options = computed(() => store.state.languageList.languageList);
const searchValue = ref('');
const languageCode = computed(() => props.languageCode);

const clonedValue = ref({
  code6392T: languageCode.value,
  displayName: options.value?.find((item) => item.code6392T === languageCode.value)?.displayName,
});

const onSelect = (value) => {
  emits('selected', value);
};

const onSearchChange = (value) => {
  searchValue.value = value;
};

const onBlur = () => {
  if (searchValue.value) {
    const target = props.options.find((option) => option.code6392T === searchValue.value);
    if (target) {
      clonedValue.value = target;
      emits('selected', target);
    }
  }
};

watch(languageCode, () => {
  clonedValue.value = {
    code6392T: languageCode.value,
    displayName: options.value?.find((item) => item.code6392T === languageCode.value)?.displayName,
  };
});

onMounted(() => {
  if (_.isEmpty(options)) {
    store.dispatch('loadLanguageList');
  }
});
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/global-variables';

/* .select-option-wrapper {
  position: static !important;
} */

:deep(.multiselect__option) {
  white-space: unset;
}

@media screen and (max-height: 620px) {
  :deep(.multiselect__content-wrapper) {
    height: 150px !important;
  }
}

</style>
