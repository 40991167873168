<template>
  <!-- <div class="card-prefetch" :class="customClass">
    <div class="animated-background" :style="{'height': options.height }">
      <div class="background-masker header-top"></div>
      <div class="background-masker header-bottom"></div>
      <div class="background-masker header-right"></div>
      <div class="background-masker content-bottom" :style="{'height': calContentBottomHeight(options.height) }"></div>
    </div>
  </div> -->
  <Card>
    <template v-slot:card-header>
      <PreloaderBar />
    </template>
    <div class="preloader-body"></div>
    <template v-slot:card-footer>
      <PreloaderBar width="100%" />
    </template>
  </Card>
</template>

<script>
import Card from '@/modules/shared/components/atoms/card/Card.vue';
import PreloaderBar from '../preloaderBar/PreloaderBar.vue';

export default {
  components: { Card, PreloaderBar },
  props: {
    options: {
      type: Object,
      default: () => ({ height: '150px' }),
    },
    customClass: {
      type: String,
      default: '', // two-columns
    },
  },
  methods: {
    unitPixel(value) {
      return `${value}px`;
    },
    calContentBottomHeight(height) {
      if (height) {
        return this.unitPixel(height / 2);
      }
      return '100px';
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/global-variables.scss';

.preloader-body{
  height: 60px;
  margin-bottom: 40px;
}

.card-prefetch{
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.2s ease-out;
  height: 100%;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.09);

  border: none;
  color: $grey-600;
  background: $white;
  display: flex;
  flex-direction: column;
}

.card-prefetch-stats {
  height: 115px;
}

@keyframes placeHolderShimmer {
  0%{ background-position: -50% 0; }
  100%{ background-position: 50% 0; }
}

.animated-background {
  width: 100%;
  height: 39px;
  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-name: placeHolderShimmer;
  background: #f6f7f8;
  background: linear-gradient(to right, #efefef, #eaeaea, #efefef);
  background-size: 50% 104px;
  position: relative;
}

.background-masker {
  background: #fff;
  position: absolute;
}

.card-prefetch-small-stats {
  padding: 0;
  background: none;
  height: 50px;
  padding-top: $spacing-base;
  .background-masker {
    background: none;
  }
}

/* Every thing below this is just positioning */

.background-masker{
  height: 50px;
}

.background-masker.header-top {
  top: 0;
  left: 0;
  right: 0;
  height: $spacing-base * 0.5;
}

.background-masker.header-bottom {
  top: $spacing-base * 2;
  left: 0;
  right: 0;
  height: 20px;
}

.background-masker.header-right {
  top: -10px;
  left: 50%;
  right: 0;
  height: 60px;
}

.background-masker.content-split {
  top: 70px;
  left: 35%;
  right: 0;
  width: 32px;
  height: 440px;
}

.background-masker.content-bottom {
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 50px;
}

</style>
