import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "danger-zone-container" }
const _hoisted_2 = { class: "card-list" }
const _hoisted_3 = { class: "danger-zone-container" }
const _hoisted_4 = { class: "card-list" }

import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from '../../../store/hooks';
import DangerZoneCard from '../../molecules/dangerZoneCard/DangerZoneCard.vue';
import MoveVideosModal from '../moveVideosModal/MoveVideosModal.vue';
import DeleteProjectModal from '../deleteProjectModal/DeleteProjectModal.vue';
import DeleteVideosModal from '../deleteVideosModal/DeleteVideosModal.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'ProjectDangerZone',
  setup(__props) {

const { t } = useI18n();
const store = useStore();

const isShowMoveVideosModal = ref<boolean>(false);
const isShowDeleteVideosModal = ref<boolean>(false);
const isShowDeleteProjectModal = ref<boolean>(false);

const videoCount = computed(() => store.state.project.currentProject?.videoCount ?? 0);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (isShowMoveVideosModal.value)
      ? (_openBlock(), _createBlock(MoveVideosModal, {
          key: 0,
          onClose: () => isShowMoveVideosModal.value = false
        }, null, 8, ["onClose"]))
      : _createCommentVNode("", true),
    (isShowDeleteVideosModal.value)
      ? (_openBlock(), _createBlock(DeleteVideosModal, {
          key: 1,
          onClose: () => isShowDeleteVideosModal.value = false
        }, null, 8, ["onClose"]))
      : _createCommentVNode("", true),
    (isShowDeleteProjectModal.value)
      ? (_openBlock(), _createBlock(DeleteProjectModal, {
          key: 2,
          onClose: () => isShowDeleteProjectModal.value = false
        }, null, 8, ["onClose"]))
      : _createCommentVNode("", true),
    _createElementVNode("section", _hoisted_1, [
      _cache[0] || (_cache[0] = _createElementVNode("div", { class: "title-container transfer-title" }, [
        _createElementVNode("i", { class: "fa-solid fa-arrow-right-arrow-left" }),
        _createElementVNode("h2", { class: "title" }, "Transfer")
      ], -1)),
      _createElementVNode("div", _hoisted_2, [
        _createVNode(DangerZoneCard, {
          title: _unref(t)('project.moveVideos.title'),
          description: _unref(t)('project.moveVideos.description'),
          buttonText: _unref(t)('project.moveVideos.button'),
          color: "warning",
          buttonIcon: "fas fa-arrow-turn-right",
          onClickButton: () => isShowMoveVideosModal.value = true,
          buttonDisabledReason: !videoCount.value ? _unref(t)('project.moveVideos.disabledReason') : null
        }, null, 8, ["title", "description", "buttonText", "onClickButton", "buttonDisabledReason"])
      ])
    ]),
    _createElementVNode("section", _hoisted_3, [
      _cache[1] || (_cache[1] = _createElementVNode("div", { class: "title-container" }, [
        _createElementVNode("i", { class: "fas fa-exclamation-triangle" }),
        _createElementVNode("h2", { class: "title" }, "DANGER ZONE")
      ], -1)),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(DangerZoneCard, {
          title: _unref(t)('project.deleteVideos.title'),
          description: _unref(t)('project.deleteVideos.description'),
          buttonText: _unref(t)('project.deleteVideos.button'),
          buttonIcon: "fas fa-trash-alt",
          onClickButton: () => isShowDeleteVideosModal.value = true,
          buttonDisabledReason: !videoCount.value ? _unref(t)('project.deleteVideos.disabledReason') : null
        }, null, 8, ["title", "description", "buttonText", "onClickButton", "buttonDisabledReason"]),
        _createVNode(DangerZoneCard, {
          title: _unref(t)('project.deleteProject.title'),
          description: _unref(t)('project.deleteProject.description'),
          buttonText: _unref(t)('project.deleteProject.button'),
          buttonIcon: "fas fa-trash-alt",
          onClickButton: () => isShowDeleteProjectModal.value = true
        }, null, 8, ["title", "description", "buttonText", "onClickButton"])
      ])
    ])
  ], 64))
}
}

})