import { axiosAPI } from '@/plugins/axios';
import type {
  AddMemberPayload,
  DeleteMemberPayload,
  EditMemberRolePayload,
  NewInvitationUrlPayload,
} from '@/modules/projectMember/types';

export function loadTeamMembers() {
  return axiosAPI.get('/myteam');
}

export function addProjectMembers(projectKey: string, payload: AddMemberPayload) {
  return axiosAPI.post(`/projects/${projectKey}/memberships`, payload);
}

export function inviteUsersFromOutsideWorkspace(projectKey: string, payload: AddMemberPayload) {
  return axiosAPI.post(`/projects/${projectKey}/invites`, payload);
}

export function getNewInvitationUrl(projectKey: string, payload: NewInvitationUrlPayload) {
  return axiosAPI.post(`/projects/${projectKey}/manual-invite`, payload);
}

export function editMemberRole(projectKey: string, payload: EditMemberRolePayload) {
  return axiosAPI.put(`projects/${projectKey}/memberships/${payload.accountId}/role`, payload.role);
}

export function deleteMember(projectKey: string, payload: DeleteMemberPayload) {
  return axiosAPI.delete(`projects/${projectKey}/memberships/${payload.accountId}`);
}
