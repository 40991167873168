<template>
  <Modal
    :title="`${t('video.subtitle.modal.badWord.title')} ‘${caption?.customName}’`"
    :isShowPrimaryButton="false"
    :secondaryButtonText="$t('common:close')"
    @modalClose="(onClose as () => void)"
  >
    <template v-slot:body>
      <div class="warning-text">
        {{ $t('video.subtitle.modal.badWord.description.found') }}
        <strong>{{ badWordList.length }}</strong>
        {{ $t('video.subtitle.modal.badWord.description.sentence') }}
      </div>
      <DataTable>
        <template v-slot:table-header>
          <Row>
            <Column width="30%">
              <HeaderCell :text="$t('video.subtitle.modal.badWord.table.time')" />
            </Column>
            <Column>
              <HeaderCell :text="$t('video.subtitle.modal.badWord.table.subtitleText')" />
            </Column>
          </Row>
        </template>
        <template v-slot:table-content>
          <Row v-for="(badWord, index) in badWordList" :key="index">
            <Column width="30%">
              <TextCell
                :text="`${transformSecondsToDuration(
                  badWord.startTime
                )} - ${transformSecondsToDuration(badWord.endTime)}`"
              />
            </Column>
            <Column>
              <TextCell :text="badWord.originalText" class="bad-word" />
            </Column>
          </Row>
        </template>
      </DataTable>
    </template>
  </Modal>
</template>
<script setup lang="ts">
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import Modal from '@/modules/shared/components/organisms/modal/Modal.vue';
import DataTable from '@/modules/shared/components/organisms/dataTable/DataTable.vue';
import Row from '@/modules/shared/components/molecules/row/Row.vue';
import Column from '@/modules/shared/components/molecules/column/Column.vue';
import HeaderCell from '@/modules/shared/components/molecules/headerCell/HeaderCell.vue';
import TextCell from '@/modules/shared/components/molecules/textCell/TextCell.vue';
import { transformSecondsToDuration } from '@/modules/shared/utils/dateFormatter';

const { t } = useI18n();

const props = defineProps({
  caption: {
    type: Object,
    default: () => {},
  },
  onClose: {
    type: Function,
    default: () => {},
  },
});

const badWordList = computed(() => props.caption.captionProofreading?.flaggedData);
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/global-variables';

:deep(.table-content) {
  background-color: white;
}

:deep(.warning-text) {
  margin-bottom: $spacing-24 !important;
  color: $grey-800;
}

:deep(button) {
  text-transform: capitalize;
}

.bad-word {
  color: $grey-800;
}
</style>
