import qs from 'qs';
import { axiosAuthAPI } from '@/plugins/axios';
import type { Filter } from '@/types/Axios.type';
import type { CreateFormPayload, FormUpload } from '../types';

export function loadForms(filter?: Filter) {
  const query = qs.stringify(filter, { addQueryPrefix: true, skipNulls: true });
  return axiosAuthAPI.get(`/apps/forms${query}`);
}

export const loadFormByKey = (formKey: string) => axiosAuthAPI.get(`/apps/forms/${formKey}`);
export const createForm = (formData: CreateFormPayload) => axiosAuthAPI.post('/apps/forms', formData);
export const updateForm = (formKey: string, formData: FormUpload) => axiosAuthAPI.put(`/apps/forms/${formKey}`, formData);
export const resetFormSecret = (formKey: string) => axiosAuthAPI.post(`/apps/forms/${formKey}/actions/secret`);
export const deleteFormByKey = (formKey: string) => axiosAuthAPI.delete(`/apps/forms/${formKey}`);
