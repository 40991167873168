<template>
  <div class="toggle-wrapper" :class="{ 'prefix-labels': isPrefixLabels}">
    <label class="toggle-container" tabindex="0" role="checkbox">
      <input
        type="checkbox"
        class="toggle-input"
        :name="name"
        :checked="modelValue"
        :disabled="disabled"
        tabindex="-1"
        @change.stop="toggle"
      />
      <div
        class="toggle-ui"
        :class="{
          toggled: toggled,
          disabled: disabled,
          'with-labels': labels,
          'is-small': isSmall,
        }"
      >
        <div class="toggle-button" :class="{ 'is-small': isSmall }"></div>
      </div>
    </label>
    <template v-if="labels">
      <span class="toggle-label" v-if="toggled">
        {{ labels.on }}
      </span>
      <span class="toggle-label" v-else>
        {{ labels.off }}
      </span>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: Boolean,
      required: false,
      default: false,
    },
    name: {
      type: String,
      required: false,
      default: () => 'toggle',
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    labels: {
      type: Object,
      required: false,
      default: undefined,
    },
    isPrefixLabels: {
      type: Boolean,
      default: false,
    },
    isSmall: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modelValue'],
  watch: {
    modelValue(value) {
      this.toggled = !!value;
    },
  },
  data() {
    return {
      toggled: false,
    };
  },
  created() {
    this.toggled = !!this.modelValue;
  },
  methods: {
    toggle() {
      const toggled = !this.toggled;
      this.toggled = toggled;
      this.$emit('update:modelValue', toggled);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/global-variables.scss";
.toggle-wrapper {
  display: flex;
  column-gap: $spacing-base;
  align-items: center;

  &.prefix-labels {
    flex-direction: row-reverse;
    justify-content: flex-end;
  }
}
.toggle-container {
  display: inline-block;
  position: relative;
  vertical-align: middle;
  user-select: none;
  font-size: 10px;
  cursor: pointer;

  .toggle-input {
    opacity: 0;
    position: absolute;
    width: 1px;
    height: 1px;
  }

  .toggle-ui {
    display: flex;
    align-items: center;
    position: relative;
    box-sizing: border-box;
    outline: 0;
    margin: 0;
    user-select: none;
    width: 36px;
    height: 20px;
    background-color: $grey-400;
    border-radius: 20px;
    transition: background-color 300ms;

    &.is-small {
      width: 35px;
      height: 20px;
    }

    .toggle-button {
      display: block;
      overflow: hidden;
      border-radius: 100%;
      z-index: 2;

      margin: 2px 2px;
      transform: translateX(0);
      width: 16px;
      height: 16px;
      background-color: $white;
      transition: transform 300ms;

      &.is-small {
        width: 14px;
        height: 14px;
        margin-left: 2px;
      }
    }

    &.toggled {
      background-color: $ci-primary;
      .toggle-button {
        transform: translateX(16px);
        &.is-small {
          transform: translateX(17px);
        }
      }
    }

    &.with-labels {
      /* width: 50px; */
      &.toggled {
        .toggle-button {
          transform: translateX(16px);
        }
      }
    }
    &.disabled {
      background-color: $grey-300;
      .toggle-button {
        background-color: $grey-400;
      }
    }
  }

  &.disabled {
    cursor: not-allowed;
  }
}
.toggle-label {
  top: 5px;
  z-index: 1;
  color: $grey-800;
  font-weight: $font-weight-base;
  font-size: $font-size-base;
}
</style>
